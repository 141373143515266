<div *ngIf="showInputs" class="inputs flex w-100 mb-2" [style.gap]="gap">
    <date-range-input label="Fra" [autofocus]="true" [date]="fromDate" (dateChange)="setFromDate($event)">
    </date-range-input>

    <date-range-input label="Til" [date]="toDate" (dateChange)="setToDate($event)"> </date-range-input>
</div>

<div class="flex w-100" [style.gap]="gap">
    <mat-calendar
        class="rounded"
        [class.border]="showInputs"
        [maxDate]="toDate"
        [selected]="dateRange || fromDate"
        (selectedChange)="setFromDate($event)"
    >
    </mat-calendar>

    <mat-calendar
        class="rounded"
        [class.border]="showInputs"
        [minDate]="fromDate"
        [selected]="dateRange || toDate"
        (selectedChange)="setToDate($event)"
    >
    </mat-calendar>
</div>
