<section role="dialog" class="uni-modal">
    <header>Hent fil fra innboks</header>

    <article>
        <span *ngIf="files?.length === 0">Ingen filer i innboks</span>

        <section *ngIf="files?.length" class="flex h-100">
            <section class="list-container flex-column overflow-hidden">
                <section class="header flex align-center border-bottom">
                    <rig-checkbox
                        *ngIf="multiselect"
                        class="check-all"
                        [(ngModel)]="checkAll"
                        (change)="onCheckAllChange()"
                    >
                        Velg alle
                    </rig-checkbox>

                    <select [(ngModel)]="sortDirection" (change)="sort()">
                        <option value="desc">Nyeste først</option>
                        <option value="asc">Eldste først</option>
                    </select>

                    <button
                        class="icon-button comment-toggle-button"
                        (click)="toggleCommentsHidden()"
                        matTooltip="Vis/skjul kommentarer på filene"
                        matTooltipPosition="above"
                    >
                        <i *ngIf="!commentsHidden" class="material-icons-outlined" aria-label="Comments visible"
                            >message</i
                        >
                        <i *ngIf="commentsHidden" class="material-icons-outlined" aria-label="Comments hidden"
                            >speaker_notes_off</i
                        >
                    </button>
                </section>

                <section #fileList class="file-list custom-scrollbar" tabindex="0" (keydown)="onKeyDown($event)">
                    <section
                        class="file-list-item pointer border-bottom"
                        *ngFor="let file of files; let idx = index"
                        [class.selected]="selectedFile?.ID === file.ID"
                        (click)="setSelectedFile(idx)"
                    >
                        <section class="flex align-center">
                            <section class="flex-1 flex align-center gap-1 overflow-hidden">
                                <rig-checkbox
                                    tabindex="-1"
                                    *ngIf="multiselect"
                                    [(ngModel)]="file._checked"
                                    (change)="onFileCheckedChange()"
                                    (click)="$event.stopPropagation()"
                                >
                                </rig-checkbox>

                                <span class="ellipsis">{{ file.Name }}</span>
                            </section>
                            <section class="source border">{{ file._source || 'E-post' }}</section>
                        </section>

                        <section
                            *ngIf="file.Description && !commentsHidden"
                            [title]="file.Description"
                            class="description"
                            [class.pad-left]="multiselect"
                        >
                            {{ file._shortDescription }}
                        </section>
                    </section>
                </section>
            </section>

            <section class="preview">
                <uni-image
                    class="h-100"
                    *ngIf="selectedFile"
                    [singleImage]="true"
                    [readonly]="true"
                    [fileIDs]="previewFileID && [previewFileID]"
                    (fileListReady)="scrollbar?.update()"
                >
                </uni-image>
            </section>
        </section>
    </article>

    <footer>
        <label class="upload">
            <input type="file" (change)="uploadFile($event)" />

            <button class="tertiary c2a">
                <i class="material-icons">cloud_upload</i>
                Last opp dokument
            </button>
        </label>

        <button class="secondary" (click)="onClose.emit()">Lukk</button>
        <button (click)="emitAndClose()" [disabled]="!files?.length" class="c2a" style="min-width: 8rem">
            {{ buttonLabel }}
        </button>
    </footer>
</section>
