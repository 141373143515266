import { take, map } from 'rxjs/operators';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Customer, FieldType, Supplier } from '@uni-entities';
import { UniAddressModal, UniEmailModal, UniModalService, UniPhoneModal } from '@uni-framework/uni-modal';
import { SupplierService } from '@app/services/accounting/supplierService';
import { BrRegService } from '@app/services/common/brRegService';
import { ErrorService } from '@app/services/common/errorService';
import { NumberSeriesService } from '@app/services/common/numberSeriesService';
import { AddressService } from '@app/services/sales/addressService';
import { CustomerService } from '@app/services/sales/customerService';

@Component({
    selector: 'customer-and-supplier-form',
    templateUrl: './customer-and-supplier-form.html',
    styleUrls: ['./customer-and-supplier-form.sass'],
})
export class CustomerAndSupplierForm {
    @Input() entity: Supplier | Customer;
    @Input() entityType: 'supplier' | 'customer';
    @Output() entityChange = new EventEmitter<{ entity: Customer | Supplier; isValid: boolean; autosave?: boolean }>();

    externalLookupOptions = this.brRegService.getLookupConfig();
    formFields = [];
    existsOrgNumber = false;
    entityTypeText!: string;
    constructor(
        private brRegService: BrRegService,
        private modalService: UniModalService,
        private addressService: AddressService,
        private numberSeriesService: NumberSeriesService,
        private customerService: CustomerService,
        private supplierService: SupplierService,
        private errorService: ErrorService,
    ) {}

    onExternalSearchSelected(value) {
        this.entity = this.brRegService.mapBrRegDataToSupplier(value, <Supplier>{});
        this.onChange();
    }

    ngOnChanges() {
        this.entityTypeText = this.entityType === 'supplier' ? 'leverandør' : 'kunde';
        this.getFormFields().subscribe((fields) => (this.formFields = fields));
    }

    onChange() {
        const orgNumber = this.entity.OrgNumber;
        const isValid = true;
        if (orgNumber) {
            const orgNumber$ =
                this.entityType === 'supplier'
                    ? this.supplierService.getSuppliers(orgNumber)
                    : this.customerService.getCustomers(orgNumber);
            orgNumber$.subscribe((response) => {
                this.existsOrgNumber = !!response?.Data.length;
                if (this.existsOrgNumber) {
                    this.entityChange.emit({
                        entity: this.entity,
                        isValid: false,
                    });
                    return;
                }
                this.entityChange.emit({
                    entity: this.entity,
                    isValid: !this.existsOrgNumber,
                });
            }, this.errorService.handle);
        }
        this.entityChange.emit({
            entity: this.entity,
            isValid: isValid,
        });
    }
    selectEntity() {
        const entity$ =
            this.entityType === 'supplier'
                ? this.supplierService.GetAll(`filter=contains(OrgNumber,'${this.entity.OrgNumber}')`, [
                      'Info',
                      'Info.InvoiceAddress',
                      'Info.DefaultPhone',
                      'Info.DefaultEmail',
                  ])
                : this.customerService.GetAll(`filter=contains(OrgNumber,'${this.entity.OrgNumber}')`, [
                      'Info',
                      'Info.InvoiceAddress',
                      'Info.DefaultPhone',
                      'Info.DefaultEmail',
                  ]);
        entity$.subscribe((response) => {
            this.entity = response[0];
            this.entityChange.emit({
                entity: this.entity,
                isValid: true,
                autosave: true,
            });
        });
    }
    private getFormFields() {
        const fields = [
            {
                Property: 'Info.Name',
                Label: 'Navn',
            },
            {
                Property: 'Info.InvoiceAddress',
                FieldType: FieldType.MULTIVALUE,
                Label: 'Fakturaadresse',
                Options: {
                    listProperty: 'Info.Addresses',
                    displayValue: 'AddressLine1',
                    linkProperty: 'ID',
                    storeResultInProperty: 'Info.InvoiceAddress',
                    storeIdInProperty: 'Info.InvoiceAddressID',
                    editor: (value) => {
                        const modal = this.modalService.open(UniAddressModal, {
                            data: value || {},
                            header: 'Fakturaadresse',
                        });

                        return modal.onClose.pipe(take(1)).toPromise();
                    },
                    display: (address) => this.addressService.displayAddress(address),
                },
            },
            {
                Property: 'Info.DefaultPhone',
                FieldType: FieldType.MULTIVALUE,
                Label: 'Telefonnummer',
                Classes: 'half-width',
                Options: {
                    listProperty: 'Info.Phones',
                    displayValue: 'Number',
                    linkProperty: 'ID',
                    storeResultInProperty: 'Info.DefaultPhone',
                    storeIdInProperty: 'Info.DefaultPhoneID',
                    editor: (value) => {
                        const modal = this.modalService.open(UniPhoneModal, { data: value || {} });
                        return modal.onClose.pipe(take(1)).toPromise();
                    },
                },
            },
            {
                Property: 'Info.DefaultEmail',
                FieldType: FieldType.MULTIVALUE,
                Label: 'E-postadresse',
                Classes: 'half-width',
                Options: {
                    listProperty: 'Info.Emails',
                    displayValue: 'EmailAddress',
                    linkProperty: 'ID',
                    storeResultInProperty: 'Info.DefaultEmail',
                    storeIdInProperty: 'Info.DefaultEmailID',
                    editor: (value) => {
                        const modal = this.modalService.open(UniEmailModal, { data: value || {} });
                        return modal.onClose.pipe(take(1)).toPromise();
                    },
                },
            },
            {
                Property: 'OrgNumber',
                Label: 'Organisasjonsnummer',
            },
        ];
        return this.addNumberSeriesField(fields);
    }

    addNumberSeriesField(formFields) {
        const entityType = this.entityType.charAt(0).toUpperCase() + this.entityType.slice(1);
        return this.numberSeriesService.getNamedNumberSeries(`${entityType} Account number series`).pipe(
            map((response) => {
                const numberSeries = this.numberSeriesService.setNumberSeriesDisplayName(response);
                const numberSerie = numberSeries.find((x) => x.Name === 'Customer number series');
                if (numberSerie) {
                    this.entity = Object.assign(new Customer(), this.entity, {
                        SubAccountNumberSeriesID: numberSerie.ID,
                    });
                }
                if (numberSeries.length > 1) {
                    return [
                        ...formFields,
                        {
                            Property: 'SubAccountNumberSeriesID',
                            FieldType: FieldType.DROPDOWN,
                            Label: 'Nummerserie',
                            Options: {
                                source: numberSeries,
                                valueProperty: 'ID',
                                displayProperty: 'DisplayName',
                                debounceTime: 200,
                            },
                        },
                    ];
                } else {
                    return formFields;
                }
            }),
        );
    }
}
