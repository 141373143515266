<section
    class="resize-handle"
    (mousedown)="onMouseDown($event)"
    (touchstart)="onTouchStart($event)"
    (dblclick)="reset()"
>
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 33">
        <rect x=".035" y=".672" width="3.837" height="32" rx="1.919" fill="currentColor" />
        <rect x="5.872" y=".672" width="3.837" height="32" rx="1.919" fill="currentColor" />
    </svg>
</section>

<section class="slot-content">
    <ng-content></ng-content>
</section>
