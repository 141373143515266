/*
    DO NOT EDIT THIS FILE!
    It's auto generated by "npm run illustration-metadata", and any changes made here
    will be overwritten by the script the next time someone runs it.
*/
export const AVAILABLE_ILLUSTRATIONS = {
    base: [
        '.DS_Store',
        'all_done',
        'attachment',
        'attention',
        'building',
        'checkmark',
        'clock',
        'completed',
        'confetti',
        'contract',
        'documentation',
        'due_date',
        'email',
        'empty_box',
        'empty_state',
        'error',
        'finish_line',
        'hours',
        'invoice',
        'laptop',
        'lightbulb',
        'mobile_otp',
        'payment_period',
        'pending',
        'people',
        'person',
        'quantity',
        'recover',
        'reports',
        'security',
        'selection',
        'start',
        'success',
        'thumb_up',
        'thumbs_up',
        'todo',
        'wait',
        'warning',
        'welcome',
    ],
    dnb: [
        'all_done',
        'attachment',
        'attention',
        'building',
        'checkmark',
        'clock',
        'completed',
        'confetti',
        'contract',
        'documentation',
        'due_date',
        'email',
        'empty_box',
        'empty_state',
        'error',
        'finish_line',
        'hours',
        'invoice',
        'laptop',
        'lightbulb',
        'mobile_otp',
        'payment_period',
        'pending',
        'people',
        'person',
        'quantity',
        'recover',
        'reports',
        'security',
        'selection',
        'start',
        'success',
        'thumb_up',
        'thumbs_up',
        'todo',
        'wait',
        'warning',
        'welcome',
    ],
    eika: [
        'all_done',
        'attachment',
        'attention',
        'building',
        'checkmark',
        'clock',
        'completed',
        'confetti',
        'contract',
        'documentation',
        'due_date',
        'email',
        'empty_box',
        'empty_state',
        'error',
        'finish_line',
        'hours',
        'invoice',
        'laptop',
        'lightbulb',
        'mobile_otp',
        'payment_period',
        'pending',
        'people',
        'person',
        'quantity',
        'recover',
        'reports',
        'security',
        'selection',
        'start',
        'success',
        'thumb_up',
        'thumbs_up',
        'todo',
        'wait',
        'warning',
        'welcome',
    ],
    sb1: [
        'all_done',
        'attachment',
        'attention',
        'building',
        'checkmark',
        'clock',
        'completed',
        'confetti',
        'contract',
        'documentation',
        'due_date',
        'email',
        'empty_box',
        'empty_state',
        'error',
        'finish_line',
        'hours',
        'invoice',
        'laptop',
        'lightbulb',
        'mobile_otp',
        'payment_period',
        'pending',
        'people',
        'person',
        'quantity',
        'recover',
        'reports',
        'security',
        'selection',
        'start',
        'success',
        'thumb_up',
        'thumbs_up',
        'todo',
        'wait',
        'warning',
        'welcome',
    ],
} as const;
