<section role="dialog" class="uni-modal">
    <section *ngIf="busy" class="modal-spinner">
        <mat-spinner class="c2a"></mat-spinner>
    </section>
    <article>
        <img src="config/dist/theme/assets/ext02-cancel-integration.svg" alt="Avslutt bankintegrasjon illustrasjon" />

        <h1>{{ options.header }}</h1>

        <span id="message" [innerHTML]="options.message">{{ options.message }}</span>

        <section *ngIf="!cancelKID">
            <!-- If all accounts are selected -->
            <ag-grid-wrapper *ngIf="!bankAccount" [(resource)]="allBankAccountsWithIntergration" [config]="tableConfig">
            </ag-grid-wrapper>

            <section class="selectionSection">
                <!-- Only one account is selected -->
                <section *ngIf="bankAccount" class="integrationSelection">
                    <span *ngIf="bankAccount?.BankAccountSettings?.HasStatements">
                        <rig-checkbox [(ngModel)]="cancelStatements" [disabled]="deleteAccount">
                            Kontotransaksjoner/Bankavstemming
                        </rig-checkbox>
                        <br />
                    </span>
                    <span *ngIf="bankAccount?.BankAccountSettings?.HasIncoming">
                        <rig-checkbox [(ngModel)]="cancelIngoing" [disabled]="deleteAccount">
                            Innbetaling/KID
                        </rig-checkbox>
                        <br />
                    </span>
                    <span *ngIf="bankAccount?.BankAccountSettings?.HasOutgoing">
                        <rig-checkbox [(ngModel)]="cancelOutgoing" [disabled]="deleteAccount">
                            Utbetaling
                        </rig-checkbox>
                    </span>
                </section>

                <label class="uni-label">
                    <span>E-postadresse for kvittering</span>
                    <input type="text" [(ngModel)]="mailForReceipt" />
                </label>
            </section>
        </section>
    </article>

    <footer class="center">
        <button class="secondary" (click)="onClose.emit()" *ngIf="!hasMultipleKidAgreements">Avbryt</button>
        <button class="c2a" (click)="confirmCancelation()" *ngIf="!hasMultipleKidAgreements">
            {{ options.buttonLabels.accept }}
        </button>
        <button class="c2a" (click)="onClose.emit()" *ngIf="hasMultipleKidAgreements">
            {{ options.buttonLabels.accept }}
        </button>
    </footer>
</section>
