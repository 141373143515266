import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApprovalStatus, ApprovalType, User } from '@uni-entities';

// PARENT
export interface ApprovalDetailItem {
    ID?: number;
    name?: string;
    steps: ApprovalDetailItemStep[];
    busyWith?: { [T in ActionIdentifier]: boolean };
}

// CHILDREN
export interface ApprovalDetailItemStep {
    limit?: number;
    approvalType: ApprovalType;
    stepNumber: number;
    users: User[] | ApprovalDetailItemUser[];
}

export interface ApprovalDetailItemUser {
    displayName: string;
    ID: number;
    approvalStatusCode: ApprovalStatus;
    updatedAt: Date;
}

// ACTIONS AND CHANGES
type ActionIdentifier = number;
export interface ApprovalDetailItemAction {
    actionIdentifier: ActionIdentifier;
    icon: string;
    iconType: string;
}

export interface ApprovalDetailItemChange {
    actionIdentifier: ActionIdentifier;
    item: ApprovalDetailItem;
}

@Component({
    selector: 'approval-detail',
    templateUrl: './approval-detail.html',
    styleUrls: ['./approval-detail.sass'],
    styles: [':host { width: 100%; }'],
}) // style :host, have full width when inserted in nested components.
export class ApprovalDetail implements OnInit {
    @Input() item: ApprovalDetailItem;
    @Input() itemActions: ApprovalDetailItemAction[];
    @Input() compactView: boolean;
    @Output() action = new EventEmitter<ApprovalDetailItemChange>();

    constructor() {}

    approvalStatus = ApprovalStatus;
    approvalMode: boolean;
    omitSteps: boolean;

    ngOnInit(): void {
        this.item = this.item ?? { name: 'No Item Found', steps: [] };
        this.approvalMode =
            this.item.steps?.some((step) => step.users.some((u) => u?.approvalStatusCode in this.approvalStatus)) ??
            false;

        if (!!this.item?.name) {
            const name = this.item.name;
            this.item.name = name.charAt(0).toUpperCase() + name.slice(1);
        }

        if (this.approvalMode && this.item?.steps?.length <= 1) {
            this.omitSteps = true;
        }
    }

    emitChange(actionIdentifier: number, item: ApprovalDetailItem) {
        this.item.busyWith ??= {};
        this.item.busyWith[actionIdentifier] = true;
        const action: ApprovalDetailItemChange = {
            actionIdentifier: actionIdentifier,
            item: item,
        };
        this.action.emit(action);
    }
}
