import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LibraryImportsModule } from '@app/library-imports.module';
import { CMSSlideIn } from './slidein/slidein';
import { CMSLink } from './link/link';
import { CMSWrapper } from './cms-wrapper';
import { CMSList } from './list/list';
import { CMSText } from './text/text';
import { CMSIllustration } from './illustration/illustration';
import { CMSIllustrationV2 } from './illustration/illustration-v2';
import { DeprecatedIllustrationModule } from '@uni-framework/ui/illustration-deprecated/illustration-deprecated';

@NgModule({
    imports: [LibraryImportsModule, CommonModule, DeprecatedIllustrationModule],
    declarations: [CMSWrapper, CMSList, CMSLink, CMSText, CMSIllustration, CMSIllustrationV2, CMSSlideIn],
    exports: [CMSWrapper, CMSList, CMSLink, CMSText, CMSSlideIn, CMSIllustration, CMSIllustrationV2],
})
export class CMSModule {}
