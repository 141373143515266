import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IModalOptions, IUniModal } from '@uni-framework/uni-modal/interfaces';
import { UniFieldLayout, FieldType } from '@uni-framework/ui/uniform';
import { Email } from '@uni-entities';
import { EmailService } from '@app/services/common/emailService';

@Component({
    selector: 'uni-email-modal',
    template: `
        <section role="dialog" class="uni-modal">
            <header>{{ options.header || 'E-post' }}</header>
            <article>
                <uni-field
                    *ngIf="field"
                    uniAutoFocus
                    [formConfig]="{ autofocus: true }"
                    [field]="field"
                    [model]="email"
                >
                </uni-field>
            </article>

            <footer>
                <button class="secondary" (click)="close(false)">Avbryt</button>

                <button class="c2a" (click)="close(true)">Ok</button>
            </footer>
        </section>
    `,
})
export class UniEmailModal implements IUniModal {
    @Input() options: IModalOptions = {};
    @Output() onClose = new EventEmitter();

    email: Email;
    field: UniFieldLayout;
    initialEmail: Email;

    constructor(private emailService: EmailService) {}

    ngOnInit() {
        this.initialEmail = this.options.data || {};
        this.email = { ...this.initialEmail };
        this.field = this.getEmailField();
    }

    close(emitValue?: boolean) {
        if (emitValue) {
            // Since multivalue currently depends on memory references we need to
            // map the updated values to the initial object and return that,
            // instead of returning the edited one.
            Object.keys(this.email).forEach((key) => {
                this.initialEmail[key] = this.email[key];
            });
            this.onClose.emit(this.initialEmail);
        } else {
            this.onClose.emit(null);
        }
    }

    private getEmailField(): UniFieldLayout {
        return <UniFieldLayout>{
            EntityType: 'Email',
            Property: 'EmailAddress',
            FieldType: FieldType.EMAIL,
            Label: 'E-postadresse',
            Validations: [
                (value: string, fieldLayout: UniFieldLayout) =>
                    this.emailService.emailUniFormValidation(value, fieldLayout),
            ],
        };
    }
}
