<section role="dialog" class="uni-modal" style="width: 42rem">
    <header>Påkrevde dimensjoner</header>
    <article>
        <uni-form
            [config]="{ autofocus: true }"
            [model]="model"
            [fields]="fields"
            (changeEvent)="onChange($event)"
        ></uni-form>
    </article>
    <footer>
        <button (click)="close()" class="secondary">Avbryt</button>
        <button (click)="save()" class="c2a" [disabled]="!isModelComplete() && !checkingBankAccounts">Lagre</button>
    </footer>
</section>
