<ul class="flex-column gap-2">
    <li *ngFor="let item of list?.list" class="flex gap-2">
        <i class="material-icons" *ngIf="list.icon"> {{ list.icon }} </i>
        <span>
            {{ item.text }}
            <a *ngIf="item.url" [href]="item.url" [target]="item?.isExternal ? '_blank' : '_self'">
                {{ item.linkText || 'Les mer' }}
            </a>
        </span>
    </li>
</ul>
