import {
    Component,
    Input,
    Output,
    ElementRef,
    EventEmitter,
    ChangeDetectionStrategy,
    SimpleChanges,
    OnChanges,
    ChangeDetectorRef,
    ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { UniFieldLayout } from '../../interfaces';
import { BaseControl } from '../baseControl';
import { NumberFormat } from '@app/services/common/numberFormatService';
import { CompanySettingsService } from '@app/services/common/companySettingsService';
import { get, isNumber, set } from 'lodash-es';
import { CompanySettings } from '@uni-entities';

export interface INumberOptions {
    format?: string;
    decimalLength?: number;
    thousandSeparator?: string;
    decimalSeparator?: string;
    allowZero?: boolean;
}
@Component({
    selector: 'uni-numeric-input',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './numeric.html',
    styleUrls: ['./numeric.sass'],
})
export class UniNumericInput extends BaseControl implements OnChanges {
    @ViewChild('inputElement') inputElement: ElementRef<HTMLInputElement>;

    @Input() public field: UniFieldLayout;
    @Input() public model: any;
    @Input() public control: UntypedFormControl;
    @Input() public asideGuid: string;

    @Output() public readyEvent: EventEmitter<UniNumericInput> = new EventEmitter<UniNumericInput>(true);
    @Output() public changeEvent: EventEmitter<SimpleChanges> = new EventEmitter<SimpleChanges>();
    @Output() public inputEvent: EventEmitter<SimpleChanges> = new EventEmitter<SimpleChanges>();
    @Output() public focusEvent: EventEmitter<UniNumericInput> = new EventEmitter<UniNumericInput>(true);

    prefix: string;

    private lastControlValue: string;
    private options: INumberOptions;

    private settings: CompanySettings;
    private settingsDecimalLength: number;

    constructor(
        private numberFormatter: NumberFormat,
        private companySettingsService: CompanySettingsService,
        private cdr: ChangeDetectorRef,
    ) {
        super();
        this.companySettingsService.getCompanySettings(['BaseCurrencyCode']).subscribe((settings) => {
            this.settings = settings;
            this.settingsDecimalLength = settings.ShowNumberOfDecimals;
            this.initOptions();
        });
    }

    public ngOnChanges(changes) {
        this.createControl();
        this.lastControlValue = this.control.value;

        if (this.controlSubscription) {
            this.controlSubscription.unsubscribe();
        }

        this.controlSubscription = this.control.valueChanges.subscribe((value) => {
            const parsed = this._parseValue(value);
            if (this.control.dirty) {
                this.emitInstantChange(this.lastControlValue, parsed, !isNaN(parsed));
            }
        });

        if (changes.field && this.field) {
            this.initOptions();
        }

        // Update FormControl with formatted value unless the input is currently focused
        if (!this.inputElement?.nativeElement || document.activeElement !== this.inputElement.nativeElement) {
            this.control.setValue(this.format(this.control.value));
        }
    }

    public focus() {
        if (this.inputElement?.nativeElement) {
            this.inputElement.nativeElement.focus();
            this.inputElement.nativeElement.select();
        }
        return this;
    }

    public blurHandler() {
        if (this.control.dirty) {
            const previousValue = get(this.model, this.field.Property);
            const newValue = this._parseValue(this.control.value);

            set(this.model, this.field.Property, newValue);
            this.emitChange(previousValue, newValue);

            this.control.setValue(this.format(newValue));
            this.control.markAsPristine();
        }
    }

    public focusHandler() {
        this.focusEvent.emit(this);
    }

    private initOptions() {
        const fieldOptions = (this.field && this.field.Options) || {};

        const options = {
            format: fieldOptions.format,
            thousandSeparator: fieldOptions.thousandSeparator || ' ',
            decimalSeparator: fieldOptions.decimalSeparator || ',',
            decimalLength: fieldOptions.decimalLength || 0,
            allowZero: fieldOptions.allowZero || false,
        };

        if (this.settingsDecimalLength && options.format === 'money') {
            options.decimalLength = this.settingsDecimalLength;
        }

        if (fieldOptions.prefix) {
            if (fieldOptions.prefix === '$currency') {
                this.prefix = this.settings?.BaseCurrencyCode?.Code || '';
                this.cdr.markForCheck();
            } else {
                this.prefix = fieldOptions.prefix;
            }
        }

        this.options = options;
    }

    private format(value: number): string {
        if (!value || this.options.format === 'none') {
            if (this.options.allowZero) {
                return value === 0 ? '0' : undefined;
            }
            return;
        }

        const decimals = value.toString().split('.')[1];
        let formatNumberOfDecimals = this.options.decimalLength;
        let formatThousandSeparator = this.options.thousandSeparator;

        if (this.options.format === 'money') {
            // On format 'money' we dont want to force fewer decimals, only fill with 0s if
            // number of decimals is less than the defined number in companySettings.
            // This is to avoid confusion with sums etc that could arise if the actual number
            // contained more decimals than our format settings would display.
            if (decimals && decimals.length > this.options.decimalLength) {
                formatNumberOfDecimals = decimals.length;
            }
        }

        if (this.options.format === 'integer') {
            formatThousandSeparator = '';
            formatNumberOfDecimals = 0;
        }

        const parsed = this._parseValue((value || '').toString());
        let formatted = this.numberFormatter.asNumber(parsed, {
            decimalLength: formatNumberOfDecimals,
            // thousandSeparator: this.options.thousandSeparator,
            thousandSeparator: formatThousandSeparator,
        });

        if (this.options.format === 'percent') {
            formatted = formatted + '%';
        }

        return formatted;
    }

    private _parseValue(value): number {
        if (isNumber(value)) {
            return value;
        }

        if (value === null || value === undefined) {
            return null;
        }

        if (this.options.thousandSeparator) {
            value = value.replace(this.options.thousandSeparator, '');
        }

        value = value.replace(' ', '');
        value = value.replace(',', '.');

        let parsed = parseFloat(value);
        return isNaN(parsed) ? null : parsed;
    }
}
