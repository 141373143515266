import { Component, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { ReInvoicingService } from '@app/services/accounting/ReInvoicingService';
import { SupplierInvoiceService } from '@app/services/accounting/supplierInvoiceService';
import { ErrorService } from '@app/services/common/errorService';
import { StatisticsService } from '@app/services/common/statisticsService';
import { CustomerInvoiceService } from '@app/services/sales/customerInvoiceService';
import { CustomerOrderService } from '@app/services/sales/customerOrderService';
import { IModalOptions, IUniModal } from '@uni-framework/uni-modal/interfaces';
import { forkJoin, of } from 'rxjs';
import { theme } from 'src/themes/theme';

@Component({
    selector: 'tracelink-link-modal',
    templateUrl: './tracelink-link-modal.html',
    styleUrls: ['./tracelink-link-modal.sass'],
})
export class TracelinkLinkModal implements IUniModal {
    options: IModalOptions = {};
    onClose = new EventEmitter();

    busy = true;
    items = [];
    emptyDataImageUrl = theme.widgets?.empty_state_illustration || 'themes/empty_state.svg';
    detailsURL = '';
    sourceDestinationURL = '';
    sourceEntityText = '';

    constructor(
        private errorService: ErrorService,
        private statisticsService: StatisticsService,
        private reinvoicingService: ReInvoicingService,
        private customerInvoiceService: CustomerInvoiceService,
        private customerOrderService: CustomerOrderService,
        private supplierInvoiceService: SupplierInvoiceService,
        private router: Router,
    ) {}

    public ngOnInit() {
        this.detailsURL = '/accounting/bill-handling/' + this.options?.data?.ID;

        if (this.options?.data?.model === 'SupplierInvoice') {
            this.reinvoicingService.getTransferredData(this.options?.data?.ID).subscribe((transferedEntities) => {
                const obs = [of([]), of([])];

                if (transferedEntities?.CustomerOrderIDs?.length) {
                    const filterString =
                        this.getStatisticsQuery(null, 'CustomerOrder') +
                        '&filter=ID eq ' +
                        transferedEntities?.CustomerOrderIDs.join(' or ID eq ');
                    obs[0] = this.statisticsService.GetAllUnwrapped(filterString);
                }

                if (transferedEntities?.CustomerInvoiceIDs?.length) {
                    const filterString =
                        this.getStatisticsQuery(null, 'CustomerInvoice') +
                        '&filter=ID eq ' +
                        transferedEntities?.CustomerInvoiceIDs.join(' or ID eq ');
                    obs[1] = this.statisticsService.GetAllUnwrapped(filterString);
                }

                forkJoin(obs).subscribe(([orders, invoices]) => {
                    this.items = orders
                        .map((order) => {
                            order._url = `/sales/orders/${order.ID}`;
                            order._linkText = `Ordrenr. ${order.Number}`;
                            order._statusCodeText = this.customerOrderService.getStatusText(order.StatusCode);
                            return order;
                        })
                        .concat(
                            invoices.map((invoice) => {
                                invoice._url = `/sales/invoices/${invoice.ID}`;
                                invoice._linkText = `Fakturanr. ${invoice.Number}`;
                                invoice._statusCodeText = this.customerInvoiceService.getStatusText(invoice.StatusCode);
                                return invoice;
                            }),
                        );
                    this.busy = false;
                });
            });

            this.setSourceTextAndUrl(this.options?.data?.model, this.options?.data?.sourceObject);
        } else {
            this.statisticsService
                .GetAllUnwrapped(this.getStatisticsQuery(this.options?.data?.ID, this.options.data?.model))
                .subscribe(
                    (response) => {
                        this.items = response.map((supplierInvoice) => {
                            supplierInvoice._url = `/accounting/supplier-invoice/${this.options?.data?.ID}`;
                            supplierInvoice._linkText = `ACCOUNTING.SUPPLIER_INVOICE.NUMBER~${supplierInvoice.InvoiceNumber || 'N/A'}`;
                            supplierInvoice._statusCodeText = this.supplierInvoiceService.getStatusText(
                                supplierInvoice.StatusCode,
                            );
                            return supplierInvoice;
                        });
                        this.busy = false;
                    },
                    (err) => this.errorService.handle(err),
                );
        }
    }

    onNavigate(url) {
        this.onClose.emit();
        this.router.navigateByUrl(url);
    }

    setSourceTextAndUrl(model: string, entity) {
        if (model === 'SupplierInvoice') {
            this.sourceEntityText = `ACCOUNTING.SUPPLIER_INVOICE.NUMBER~${entity.SupplierInvoiceInvoiceNumber || entity.InvoiceNumber || 'N/A'}`;
            this.sourceDestinationURL = `/accounting/supplier-invoice/${this.options?.data?.ID}`;
        }
    }

    getStatisticsQuery(ID: number, model: string): string {
        if (model === 'CustomerInvoice') {
            return `model=CustomerInvoice&select=ID as ID,InvoiceNumber as Number,TaxInclusiveAmountCurrency as Sum,StatusCode as StatusCode,Info.Name as Name&expand=Customer.Info`;
        } else if (model === 'CustomerOrder') {
            return `model=CustomerOrder&select=ID as ID,OrderNumber as Number,TaxInclusiveAmountCurrency as Sum,StatusCode as StatusCode,Info.Name as Name&expand=Customer.Info`;
        } else {
            return `model=SupplierInvoice&select=ID as ID,InvoiceNumber as InvoiceNumber,TaxInclusiveAmountCurrency as Sum,StatusCode as StatusCode,Info.Name as Name&expand=Supplier.Info&distinct=true&filter=ID eq ${ID}`;
        }
    }
}
