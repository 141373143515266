import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { rigDate } from '@app/components/common/utils/rig-date';

export interface DateRangePickerChangeEvent {
    fromDate: Date;
    toDate: Date;
    fromDateString: string;
    toDateString: string;
}

export interface DateRangeButton {
    label: string;
    fromDate: Date;
    toDate: Date;
}

const DATE_FORMAT = 'YYYY-MM-DD';

@Component({
    selector: 'date-range-picker',
    templateUrl: './date-range-picker.html',
    styleUrls: ['./date-range-picker.sass'],
})
export class DateRangePicker implements OnChanges {
    @Input() from: Date | string;
    @Input() to: Date | string;
    @Input() periodOnly = false;
    @Input() allowEmpty = false;
    @Input() useSimpleLayout = false;
    @Input() buttons: DateRangeButton[];

    @Output() change = new EventEmitter<DateRangePickerChangeEvent>();

    fromDate: Date;
    toDate: Date;

    inputLabel: string;
    selectionText: string;

    ngOnInit() {
        this.setDates(this.from, this.to, false);
    }

    ngOnChanges(changes) {
        if (changes.from || changes.to) {
            this.setDates(this.from, this.to, false);
        }
    }

    setDates(from: Date | string, to: Date | string, emitEvent = false) {
        if (!from && !this.allowEmpty) {
            from = new Date();
        }

        if (!to && !this.allowEmpty) {
            to = new Date();
        }

        this.fromDate = from && rigDate(from, DATE_FORMAT).toDate();
        this.toDate = to && rigDate(to, DATE_FORMAT).toDate();

        this.updateSelectionText();
        this.updateInputLabel();

        if (emitEvent) {
            this.change.emit({
                fromDate: this.fromDate,
                fromDateString: this.fromDate && rigDate(this.fromDate).format(DATE_FORMAT),
                toDate: this.toDate,
                toDateString: this.toDate && rigDate(this.toDate).format(DATE_FORMAT),
            });
        }
    }

    private updateSelectionText() {
        if (this.fromDate && this.toDate) {
            if (this.periodOnly) {
                const diff = rigDate(this.toDate).diff(this.fromDate, 'month') + 1;
                this.selectionText = diff + (diff === 1 ? ' måned' : ' måneder');
            } else {
                const diff = rigDate(this.toDate).diff(this.fromDate, 'day') + 1;
                this.selectionText = diff + (diff === 1 ? ' dag' : ' dager');
            }
        } else {
            this.selectionText = '';
        }
    }

    private updateInputLabel() {
        const format = (date) => rigDate(date).format('DD.MM.YY');

        if (this.fromDate && !this.toDate) {
            this.inputLabel = `Fra og med ${format(this.fromDate)}`;
        } else if (this.toDate && !this.fromDate) {
            this.inputLabel = `Til og med ${format(this.toDate)}`;
        } else if (this.fromDate && this.toDate) {
            this.inputLabel = `${format(this.fromDate)} - ${format(this.toDate)}`;
        } else {
            this.inputLabel = '';
        }
    }
}
