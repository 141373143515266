import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable, BehaviorSubject, Subject, of, forkJoin } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { GridApi, IDatasource, IGetRowsParams } from 'ag-grid-community';
import { cloneDeep, get } from 'lodash-es';
import { rigDate } from '@app/components/common/utils/rig-date';

import { UniTableColumn, UniTableColumnType } from '../../unitable/config/unitableColumn';
import { UniTableConfig, QuickFilter } from '../../unitable/config/unitableConfig';
import { ITableFilter, IExpressionFilterValue } from '../interfaces';
import { StatisticsService } from '@app/services/common/statisticsService';
import { TableUtils, FilterState } from './table-utils';
import { TableGroupingService } from './table-grouping-service';

@Injectable()
export class TableDataService {
    private config: UniTableConfig;
    private columns: UniTableColumn[];
    private gridApi: GridApi;
    private hasRemoteLookup: boolean;
    private dataSource: IDatasource;

    sumRow$: BehaviorSubject<any[]> = new BehaviorSubject(undefined);
    totalRowCount$: BehaviorSubject<number> = new BehaviorSubject(0);
    loadedRowCount: number;

    basicSearchFilters: ITableFilter[];
    advancedSearchFilters: ITableFilter[];
    quickFilters: QuickFilter[];

    filterString: string;

    columnSumResolver: (params: HttpParams) => Observable<{ [field: string]: number }>;

    // Only maintained for local data grids!
    private originalData: any[];
    private viewData: any[];

    public localDataChange$: Subject<any[]> = new Subject();

    // Only maintained for inifinite scroll tables!
    private rowCountOnRemote: number;

    constructor(
        private groupingService: TableGroupingService,
        private statisticsService: StatisticsService,
        private utils: TableUtils,
    ) {}

    public initialize(
        gridApi: GridApi,
        config: UniTableConfig,
        columns: UniTableColumn[],
        resource,
        quickFilters: QuickFilter[],
    ) {
        this.gridApi = gridApi;

        const oldConfig = cloneDeep(this.config);
        const configFiltersChanged = this.didConfigFiltersChange(config);
        const configChanged = !this.config || this.config.configStoreKey !== config.configStoreKey;

        this.config = config;
        this.columns = columns;

        if (!this.config.editable) {
            const sortModel = this.utils.getSortModel(this.config.configStoreKey);
            if (sortModel) {
                this.gridApi.getColumn(sortModel.colId)?.setSort(sortModel.sort as any);
            }
        }

        if (configChanged) {
            this.filterString = undefined;
            const filterState = this.utils.getFilterState(config) || <FilterState>{};

            let searchBoxFilters = [];
            if (filterState.searchText) {
                const columns = this.columns.filter((col) => col.visible);
                searchBoxFilters = this.utils.getFiltersFromSearchText(filterState.searchText, columns);
            }

            if (!filterState.advancedSearchFilters?.length && config.filters) {
                filterState.advancedSearchFilters = config.filters;
            }

            this.setFilters(filterState.advancedSearchFilters || [], searchBoxFilters, quickFilters, false);
        } else if (configFiltersChanged) {
            this.filterString = undefined;

            let advancedFilters = this.advancedSearchFilters || [];

            // If the old config had preset filters we need to remove those
            if (oldConfig?.filters) {
                advancedFilters = advancedFilters.filter((filter) => {
                    return !oldConfig.filters.some((oldFilter) => {
                        return (
                            oldFilter.field === filter.field &&
                            oldFilter.operator === filter.operator &&
                            oldFilter.value === filter.value
                        );
                    });
                });
            }

            // Add preset filters from new config
            if (config.filters) {
                advancedFilters.push(...config.filters);
            }

            this.setFilters(advancedFilters, this.basicSearchFilters || [], config.quickFilters, false);
        }

        if (Array.isArray(resource)) {
            let rows = resource || [];
            if (this.config.dataMapper) {
                rows = this.config.dataMapper(rows);
            }

            this.originalData = this.setMetadata(rows);

            // Don't filter locally when ticker grouping is on!
            const filteredData = this.config.filterLocalData
                ? this.filterLocalData(this.originalData)
                : this.originalData;
            this.loadedRowCount = filteredData.length;
            this.totalRowCount$.next(this.loadedRowCount);

            if (this.config.groupingEnabled) {
                this.groupingService.init(this.gridApi, this.config, this.columns, filteredData);
            } else {
                // REVISIT: check if setting rowData to [] first is necessary
                // this.gridApi.setGridOption('rowData', []);
                this.gridApi.setGridOption('rowData', filteredData);

                this.gridApi.forEachNode((node) => {
                    if (node.data && node.data['_rowSelected']) {
                        node.setSelected(node.data['_rowSelected']);
                    }
                });
                const lastRow = filteredData.length && filteredData[filteredData.length - 1];
                if (this.config.autoAddNewRow && this.config.editable && (!lastRow || !lastRow['_isEmpty'])) {
                    setTimeout(() => this.addRow());
                }
            }
        } else {
            this.hasRemoteLookup = true;
            this.loadedRowCount = 0;

            this.dataSource = this.getRemoteDatasource(resource);
            this.gridApi.setGridOption('datasource', this.dataSource);
        }

        // Get columns sums if working with local data.
        // Remote data column sums is handled in dataSource
        if (Array.isArray(resource)) {
            const sumColumns = this.columns.filter((col) => col.isSumColumn || col.aggFunc);
            if (sumColumns.length) {
                this.getLocalDataColumnSums(sumColumns, resource);
            }
        }
    }

    private resetRemoteDatasource() {
        if (this.hasRemoteLookup && this.dataSource) {
            this.loadedRowCount = 0;
            this.gridApi.setGridOption('datasource', this.dataSource);
            this.gridApi.showLoadingOverlay();
        }
    }

    private didConfigFiltersChange(config: UniTableConfig) {
        let filtersChanged = false;
        let quickFiltersChanged = false;

        const oldFilters = (this.config && this.config.filters) || [];
        const newFilters = (config && config.filters) || [];

        const oldQuickFilters = this.config?.quickFilters || [];
        const newQuickFilters = config?.quickFilters || [];

        if (oldFilters.length !== newFilters.length) {
            filtersChanged = true;
        } else {
            // Check if there exists a filter in the new config that didn't exist  in the old.
            filtersChanged = newFilters.some((filter) => {
                return !oldFilters.some((oldFilter) => {
                    return (
                        oldFilter.field === filter.field &&
                        oldFilter.operator === filter.operator &&
                        oldFilter.value === filter.value
                    );
                });
            });
        }

        if (oldQuickFilters.length !== newQuickFilters.length) {
            quickFiltersChanged = true;
        } else {
            quickFiltersChanged = newQuickFilters.some((filter) => {
                return !oldQuickFilters.some((f) => f.field === filter.field);
            });
        }

        return filtersChanged || quickFiltersChanged;
    }

    private setMetadata(data: any[], startIndex: number = 0): any[] {
        if (!data || !data.length) {
            return [];
        }

        data.forEach((row, index) => {
            row['_originalIndex'] = startIndex + index;
            row['_guid'] = this.getGuid();
        });

        return data;
    }

    private getRemoteDatasource(resource: (urlParam: HttpParams) => any): IDatasource {
        this.loadedRowCount = 0;
        return {
            getRows: (params: IGetRowsParams) => {
                let urlParams = new HttpParams();
                urlParams = urlParams.set('skip', params.startRow.toString());
                urlParams = urlParams.set('top', (params.endRow - params.startRow).toString());

                // Filtering
                if (this.filterString) {
                    urlParams = urlParams.set('filter', this.filterString);
                }

                // Sorting
                let orderby;
                if (params.sortModel && params.sortModel.length) {
                    const sortModel = params.sortModel[0];
                    const column = this.columns.find((col) => col.field === sortModel.colId);

                    if (column.visible) {
                        const sortFields = column?.sortFields || sortModel.colId;

                        if (Array.isArray(sortFields)) {
                            orderby = sortFields.map((field) => `${field} ${sortModel.sort}`).join(',');
                        } else {
                            orderby = `${sortFields as string} ${sortModel.sort}`;
                        }
                    }
                }

                if (orderby) {
                    urlParams = urlParams.set('orderby', orderby);
                }

                const requests = [resource(urlParams)];

                if (params.startRow === 0) {
                    requests.push(this.loadColumnSums(urlParams));
                }

                forkJoin(requests as any).subscribe(
                    ([res, sumRow]: [any, any]) => {
                        let data = res.body || res || [];

                        // unwrap statistics response
                        if (data?.Data) {
                            data = data.Data;
                        }

                        let rows = data.Data ? data.Data : data; // unwrap statistics
                        if (this.config.dataMapper) {
                            data = this.config.dataMapper(data);
                        }

                        data = this.setMetadata(data, this.loadedRowCount);
                        this.loadedRowCount = params.startRow + data.length;

                        // Only set row count on first chunk load (skip=0)
                        if (params.startRow === 0 || !this.rowCountOnRemote) {
                            let rowCount = Number(res.headers?.get('count'));

                            /*
                                If we're unable to get row count from the server, we set it to undefined
                                until a page returns less than <pagesize> amount of items.
                                At that point we know we've reached the end, and can set rowCountOnRemote
                                which prevents the table from trying to load more data.
                            */
                            if (!rowCount) {
                                const isLastPage = data.length < params.endRow - params.startRow;
                                rowCount = isLastPage ? params.startRow + data.length : undefined;
                            }

                            this.totalRowCount$.next(rowCount);
                            this.rowCountOnRemote = rowCount;
                        }

                        if (sumRow) {
                            this.setSumRow(sumRow);
                        }

                        params.successCallback(data, this.rowCountOnRemote);
                        this.gridApi.hideOverlay();
                    },
                    (err) => {
                        console.error(err);
                        params.failCallback();
                    },
                );

                if (params.startRow === 0) {
                    if (!this.config.customRowSelection) {
                        this.gridApi.deselectAll();
                    }
                }
            },
        };
    }

    public refreshData() {
        if (this.gridApi) {
            this.loadedRowCount = 0;

            if (this.hasRemoteLookup) {
                // this.gridApi.refreshInfiniteCache();
                this.resetRemoteDatasource();
            } else {
                if (!this.config.customRowSelection && !this.config.editable) {
                    this.gridApi.deselectAll();
                }

                this.viewData = this.filterLocalData(this.originalData);
                if (!this.config.groupingEnabled) {
                    // REVISIT: check if setting rowData to [] first is necessary
                    // this.gridApi.setGridOption('rowData', []);
                    this.gridApi.setGridOption('rowData', this.viewData);
                }

                this.localDataChange$.next(this.originalData);

                // Re-calculate sum row if we have sum columns
                setTimeout(() => {
                    const sumColumns = this.columns.filter((col) => col.isSumColumn || col.aggFunc);
                    if (sumColumns.length) {
                        this.getLocalDataColumnSums(sumColumns, this.originalData);
                    }
                });
            }
        }
    }

    public clearEmptyRows() {
        this.originalData = this.originalData.filter((row) => !row['_isEmpty']);
        this.refreshData();
    }

    public getViewData() {
        return this.viewData;
    }

    public getTableData(filterEmpty?: boolean) {
        if (!this.originalData) {
            return;
        }
        return filterEmpty ? this.originalData.filter((row) => !row._isEmpty) : this.originalData;
    }

    private getGuid(): string {
        // eslint-disable-next-line
        return ('' + 1e7 + -1e3 + -4e3 + -8e3 + -1e11).replace(/1|0/g, function () {
            return (0 | (Math.random() * 16)).toString(16);
        });
    }

    public deleteRow(row) {
        const originalIndex = row['_originalIndex'];

        if (row.ID) {
            this.originalData[originalIndex].Deleted = true;
            this.originalData[originalIndex]['_isDirty'] = true;
        } else {
            this.originalData.splice(originalIndex, 1);
            this.originalData.forEach((r, index) => (r['_originalIndex'] = index));
        }

        if (this.originalData.length) {
            this.refreshData();
        } else if (this.config.autoAddNewRow) {
            this.addRow();
        }
    }

    public updateRow(originalIndex, row) {
        if (this.hasRemoteLookup) {
            console.warn('Updating rows not supported with remote lookup');
            return;
        }

        row['_isEmpty'] = false;
        this.originalData[originalIndex] = row;
        this.refreshData();
    }

    public addRow(rowData?: any) {
        if (this.hasRemoteLookup) {
            console.warn('Adding rows not supported with remote lookup');
            return;
        }

        let newRow = rowData;

        if (newRow) {
            newRow['_isEmpty'] = false;
        } else {
            newRow = this.config && this.config.defaultRowData ? this.config.defaultRowData : {};

            newRow['_isEmpty'] = true;
        }

        newRow = cloneDeep(newRow); // avoid multiple new rows having the same reference
        newRow['Deleted'] = false;
        newRow['_originalIndex'] = this.originalData.length;
        newRow['_guid'] = this.getGuid();

        this.originalData.push(newRow);
        this.refreshData();
    }

    public filterLocalData(originalData: any[]): any[] {
        if (!originalData || !originalData.length) {
            return [];
        }

        let data = cloneDeep(originalData).filter((row) => !row.Deleted || this.config.showDeletedRows);

        // Quick filters
        if (this.quickFilters?.length) {
            const filters: ITableFilter[] = [];
            this.quickFilters.forEach((quickFilter) => {
                if (quickFilter.filterGenerator) {
                    const generatedFilter = quickFilter.filterGenerator(quickFilter.value);
                    if (generatedFilter && typeof generatedFilter !== 'string') {
                        filters.push(generatedFilter);
                    }
                } else if (quickFilter.operator && quickFilter.value) {
                    filters.push({
                        field: quickFilter.field,
                        operator: quickFilter.operator,
                        value: quickFilter.value,
                        isDate: quickFilter.type === 'date',
                    });
                }
            });

            filters.filter((f) => !!f.field && !!f.operator && (f.operator === 'NOT_SET' || !!f.value));
            if (filters.length) {
                data = data.filter((item) => {
                    return filters.every((filter) => {
                        return this.checkFilter(filter, item);
                    });
                });
            }
        }

        if (this.advancedSearchFilters && this.advancedSearchFilters.length) {
            const filterGroups = [];
            const ungroupedFilters = [];

            this.advancedSearchFilters.forEach((f) => {
                if (f.group > 0) {
                    filterGroups[f.group] = filterGroups[f.group] || [];
                    filterGroups[f.group].push(f);
                } else {
                    ungroupedFilters.push(f);
                }
            });

            data = data.filter((item) => {
                const ungroupedCheck = this.checkFilterGroup(ungroupedFilters, item, 'and'); // ungroupedFilters.every(filter => this.checkFilter(filter, item));
                const groupsCheck = filterGroups.every((group) => {
                    return this.checkFilterGroup(group, item, 'or');
                });

                return ungroupedCheck && groupsCheck;
            });
        }

        // Basic filters (separator = or)
        if (this.basicSearchFilters && this.basicSearchFilters.length) {
            data = data.filter((item) => {
                return this.basicSearchFilters.some((filter) => {
                    return this.checkFilter(filter, item);
                });
            });
        }

        return data;
    }

    private checkFilterGroup(filters: ITableFilter[], item: any, defaultLogicalOperator: 'and' | 'or') {
        if (!filters?.length) return true;

        let filterGroupMatched = this.checkFilter(filters[0], item);

        filters.slice(1).forEach((filter) => {
            const filterMatched = this.checkFilter(filter, item);
            const logicalOperator = filter.logicalOperator || defaultLogicalOperator;
            filterGroupMatched =
                logicalOperator === 'and' ? filterGroupMatched && filterMatched : filterGroupMatched || filterMatched;
        });

        return filterGroupMatched;
    }

    public checkFilter(filter: ITableFilter, item): boolean {
        const query = this.getFilterValueFromFilter(filter, this.config.expressionFilterValues).toLowerCase();
        const value: string = (get(item, filter.field) || '').toString().toLowerCase();

        if (filter.operator === 'NOT_SET') {
            return !value;
        }

        if (!query || !query.length) {
            return true;
        }

        if (filter.isDate) {
            return this.checkDateFilter(query, value, filter.operator);
        }

        const isNumber = (val) => !isNaN(val) && isFinite(val);

        switch (filter.operator) {
            case 'contains':
                return value.includes(query);
            case 'not contains':
                return !value.includes(query);
            case 'startswith':
                return value.startsWith(query);
            case 'endswith':
                return value.endsWith(query);
            case 'eq':
                return value === query;
            case 'ne':
                return value !== query;
            case 'gt':
                if (!isNumber(value) || !isNumber(query)) {
                    return false;
                }
                return parseInt(value, 10) > parseInt(query, 10);
            case 'lt':
                if (!isNumber(value) || !isNumber(query)) {
                    return false;
                }
                return parseInt(value, 10) < parseInt(query, 10);
            case 'ge':
                if (!isNumber(value) || !isNumber(query)) {
                    return false;
                }
                return parseInt(value, 10) >= parseInt(query, 10);
            case 'le':
                if (!isNumber(value) || !isNumber(query)) {
                    return false;
                }
                return parseInt(value, 10) <= parseInt(query, 10);
            case 'NOT_SET':
                return !value;
        }
    }

    private checkDateFilter(query: string, value: string, operator) {
        const queryMoment = rigDate(query);
        const valueMoment = rigDate(value);

        if (!valueMoment.isValid()) {
            return false;
        }

        switch (operator) {
            case 'contains':
                return valueMoment.format('DD.MM.YYYY').includes(query);
            case 'startswith':
                return valueMoment.format('DD.MM.YYYY').startsWith(query);
            case 'endswith':
                return valueMoment.format('DD.MM.YYYY').endsWith(query);
            case 'eq':
                return queryMoment.format('DD.MM.YYYY') === valueMoment.format('DD.MM.YYYY');
            case 'ne':
                return queryMoment.format('DD.MM.YYYY') !== valueMoment.format('DD.MM.YYYY');
            case 'gt':
                return valueMoment.isAfter(queryMoment.add(1, 'days'));
            case 'ge':
                return valueMoment.isAfter(queryMoment);
            case 'lt':
                return valueMoment.isBefore(queryMoment);
            case 'le':
                return valueMoment.isBefore(queryMoment.add(1, 'days'));
        }
    }

    public setFilters(
        advancedSearchFilters: ITableFilter[] = [],
        basicSearchFilters: ITableFilter[] = [],
        quickFilters: QuickFilter[] = [],
        refreshTableData: boolean = true,
    ): any {
        this.resetRemoteDatasource();

        // Dont use filters that are missing field or operator.
        // This generally means the user is not done creating them yet
        advancedSearchFilters = advancedSearchFilters.filter((f) => !!f.field && !!f.operator);
        basicSearchFilters = basicSearchFilters.filter((f) => !!f.field && !!f.operator);

        // Make sure date filters are correctly marked (for date autocompletion)
        advancedSearchFilters = advancedSearchFilters.map((filter) => {
            const col = this.columns.find((c) => c.field === filter.field);

            filter.isDate =
                !!col && (col.type === UniTableColumnType.DateTime || col.type === UniTableColumnType.LocalDate);

            return filter;
        });

        this.advancedSearchFilters = advancedSearchFilters;
        this.basicSearchFilters = basicSearchFilters;
        this.quickFilters = quickFilters;

        let filterStrings: string[] = [];
        if (this.basicSearchFilters?.length) {
            filterStrings.push(this.getFilterString(this.basicSearchFilters, this.config.expressionFilterValues, 'or'));
        }

        if (this.advancedSearchFilters?.length) {
            filterStrings.push(this.getFilterString(this.advancedSearchFilters, this.config.expressionFilterValues));
        }

        if (this.quickFilters?.length) {
            const filters: ITableFilter[] = [];
            const quickFilterStrings = [];

            this.quickFilters.forEach((quickFilter) => {
                if (quickFilter.filterGenerator) {
                    const generatedFilter = quickFilter.filterGenerator(quickFilter.value);

                    if (generatedFilter) {
                        if (typeof generatedFilter === 'string') {
                            quickFilterStrings.push(generatedFilter);
                        } else {
                            filters.push(generatedFilter);
                        }
                    }
                } else if (quickFilter.operator && quickFilter.value) {
                    filters.push({
                        field: quickFilter.field,
                        operator: quickFilter.operator,
                        value: quickFilter.value,
                        isDate: quickFilter.type === 'date',
                    });
                }
            });

            filters.filter((f) => !!f.field && !!f.operator && (f.operator === 'NOT_SET' || !!f.value));
            if (filters.length) {
                quickFilterStrings.push(this.getFilterString(filters, this.config.expressionFilterValues));
            }

            if (quickFilterStrings.length) {
                filterStrings.push(quickFilterStrings.join(' and '));
            }
        }

        filterStrings = filterStrings.filter((f) => !!f);
        this.filterString = filterStrings.join(' and ');

        if (refreshTableData) {
            this.refreshData();
        } else {
            return this.filterString;
        }
    }

    public removeFilter(field: string): void {
        if (!this.advancedSearchFilters) {
            return;
        }

        const advancedFilters = this.advancedSearchFilters.filter((f) => f.field !== field);
        this.setFilters(advancedFilters, this.basicSearchFilters, this.quickFilters);
    }

    private buildFilterString(filter: ITableFilter, filterValue) {
        const buildString = (field: string) => {
            if (filter.operator === 'NOT_SET') {
                return `isnull(${field},'') eq ''`;
            } else if (filter.operator === 'not contains') {
                return `( not contains(${field},'${filterValue}') )`;
            } else if (
                filter.operator === 'contains' ||
                filter.operator === 'startswith' ||
                filter.operator === 'endswith'
            ) {
                return `${filter.operator}(${field},'${filterValue}')`;
            } else {
                if (filter.isDate) {
                    return this.getDateFilterString(filter, filterValue);
                } else {
                    return `${this.getFilterField(field, filter.operator)} ${filter.operator} '${filterValue}'`;
                }
            }
        };

        const column = this.columns.find((col) => col.displayField === filter.field || col.field === filter.field);
        if (column?.additionalFilterFields?.length) {
            const filterStrings = [filter.field, ...column.additionalFilterFields].map((field) => buildString(field));

            return `( ${filterStrings.join(' or ')} )`;
        } else {
            return buildString(filter.field);
        }
    }

    public getFilterString(
        filters: ITableFilter[],
        expressionFilterValues: IExpressionFilterValue[],
        separator = 'and',
    ): string {
        if (!filters || !filters.length) {
            return '';
        }

        const filterGroups: ITableFilter[][] = [];
        filters.forEach((filter) => {
            const group = filter.group || 0;
            filterGroups[group] ??= [];
            filterGroups[group].push(filter);
        });

        let filterString = '';

        filterGroups.forEach((group, index) => {
            let groupString = '';

            group.forEach((filter, index) => {
                const filterValue = this.getFilterValueFromFilter(filter, expressionFilterValues);

                if (filterValue || filter.operator === 'NOT_SET') {
                    const logicalOperator = filter.logicalOperator || separator;
                    if (index > 0 && groupString.length) {
                        groupString += ` ${logicalOperator} `;
                    }

                    groupString += this.buildFilterString(filter, filterValue);
                }
            });

            if (groupString.length) {
                const logicalOperator = group[0].logicalOperator || separator;
                if (index > 0 && filterString.length) {
                    filterString += ` ${logicalOperator} `;
                }

                filterString += `( ${groupString} )`;
            }
        });

        if (filterString !== '') {
            filterString = `( ${filterString} )`;
        }
        return filterString;
    }

    private getDateFilterString(filter, filterValue) {
        const date = rigDate(filterValue, 'YYYY-MM-DD').format('YYYY-MM-DD');
        const nextDay = rigDate(filterValue, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD');

        if (filter.operator === 'eq') {
            return `(${filter.field} ge '${date}' and ${filter.field} lt '${nextDay}')`;
        } else if (filter.operator === 'ne') {
            return `(${filter.field} lt '${date}' or ${filter.field} ge '${nextDay}')`;
        } else if (filter.operator === 'le') {
            return `(${filter.field} lt '${nextDay}')`;
        } else {
            return `(${filter.field} ${filter.operator} '${date}')`;
        }
    }

    private getFilterField(field, operator) {
        if (operator === 'ne' && field.toLocaleLowerCase().includes('statuscode')) {
            return `isnull(${field},0)`;
        } else {
            return field;
        }
    }

    private formatDateFilter(value) {
        let filterDate;

        // Try parsing date strings with norwegian format first
        if (typeof value === 'string') {
            filterDate = rigDate(value, 'DD.MM.YYYY');
        }

        // If the above failed, or the filter value is a date object
        // try parsing it without a specified format
        if (!filterDate || !filterDate.isValid()) {
            filterDate = rigDate(value);
        }

        if (filterDate.isValid()) {
            return filterDate.format('YYYY-MM-DD');
        }
    }

    private getFilterValueFromFilter(filter: ITableFilter, expressionFilterValues: IExpressionFilterValue[]): string {
        let filterValue = (filter.value ?? '').toString();

        if (filter.isDate && filter.operator !== 'NOT_SET') {
            filterValue = this.formatDateFilter(filter.value);
        }

        const col = this.columns.find((c) => c.field === filter.field);

        if ([UniTableColumnType.Number, UniTableColumnType.Money, UniTableColumnType.Percent].includes(col?.type)) {
            filterValue = filterValue.replace(',', '.').replace(/[^\d.-]/g, '');
        }

        // If expressionfiltervalues are defined, e.g. ":currentuserid",
        // check if any of the defined filters should inject the expressionfiltervalue
        if (filterValue.toString().startsWith(':')) {
            const expressionFilterValue = expressionFilterValues.find((efv) => ':' + efv.expression === filterValue);

            if (expressionFilterValue) {
                filterValue = expressionFilterValue.value || '';
            } else {
                console.error('No ExpressionFilterValue defined for filterexpression ' + filterValue);
            }
        }

        return filterValue.toString();
    }

    private getLocalDataColumnSums(sumColumns: UniTableColumn[], data: any[]) {
        const sumRow: any = {};

        sumColumns.forEach((col, index) => {
            // TODO: filteredData
            sumRow[col.alias || col.field] = col.aggFunc
                ? col.aggFunc(data)
                : data.reduce((sum, row) => {
                      return (sum += parseFloat(get(row, col.alias || col.field, 0)) || 0);
                  }, 0);
        });

        this.setSumRow(sumRow);
    }

    private generateColumnSumRequest() {
        const sumColumns = this.columns?.filter((col) => col.isSumColumn);

        if (!this.config.entityType || !sumColumns?.length) {
            return;
        }

        const sumSelects = [];
        sumColumns.forEach((col, index) => {
            col['_selectAlias'] = 'sum' + index;
            sumSelects.push(`sum(${col.field}) as ${col['_selectAlias']}`);
        });

        let odata = `model=${this.config.entityType}&select=${sumSelects.join(',')}`;

        if (this.filterString) {
            odata += `&filter=${this.filterString}`;
        }

        return this.statisticsService.GetAll(odata);
    }

    private loadColumnSums(params: HttpParams) {
        let request: Observable<any>;

        if (this.columnSumResolver) {
            request = this.columnSumResolver(params);
        } else {
            request = this.generateColumnSumRequest();
        }

        if (request) {
            return request.pipe(
                catchError((err) => {
                    console.error(err);
                    return of([]);
                }),
                map((res) => (Array.isArray(res) ? res[0] : res)),
            );
        } else {
            return of(undefined);
        }
    }

    private setSumRow(row: any) {
        if (row) {
            row['_isSumRow'] = true;
            this.gridApi.setGridOption('pinnedBottomRowData', row ? [row] : undefined);
            this.sumRow$.next(row ? [row] : undefined);
        }
    }
}
