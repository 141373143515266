<input
    *ngIf="control"
    type="email"
    autocomplete="off"
    [attr.aria-describedby]="asideGuid"
    [formControl]="control"
    [readonly]="readOnly$ | async"
    [placeholder]="field?.Placeholder || ''"
    (blur)="blurHandler()"
    (focus)="focusHandler()"
    [title]="control?.value || ''"
    [name]="field?.Property"
/>
<ng-content></ng-content>
