import { ChangeDetectionStrategy, Component, ComponentRef, ViewChild, ViewContainerRef } from '@angular/core';
import { UniTableColumn } from '@uni-framework/ui/unitable';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
    selector: 'cell-with-component',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `<ng-container #componentContainer></ng-container>`,
    styles: `
        :host {
            display: flex;
            justify-content: flex-start;
        }
    `,
})
export class CellWithComponent {
    @ViewChild('componentContainer', { read: ViewContainerRef, static: true }) container: ViewContainerRef;

    componentRef: ComponentRef<any>;

    agInit(params: ICellRendererParams): void {
        const col: UniTableColumn = params['columnConfig'];

        if (col.cellComponent) {
            this.componentRef = this.container.createComponent(col.cellComponent);
            this.componentRef.instance.row = params.node?.data || {};
            this.componentRef.instance.column = col;
        }
    }

    ngOnDestroy() {
        this.componentRef.destroy();
    }

    refresh(): boolean {
        return false;
    }
}
