import { Injectable } from '@angular/core';

import { Observable, of as observableOf } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';

import { UniHttp } from '@uni-framework/core/http/http';
import { BizHttp } from '@uni-framework/core/http/BizHttp';
import { UserRole } from '@uni-entities';
import { RequestMethod } from '@uni-framework/core/http';

@Injectable({ providedIn: 'root' })
export class UserRoleService extends BizHttp<UserRole> {
    constructor(http: UniHttp) {
        super(http);

        this.relativeURL = UserRole.RelativeUrl;
        this.entityType = UserRole.EntityType;
        this.defaultExpand = ['SharedRole'];

        this.cacheSettings.timeout = 600000; // 10 min
    }

    getRolesByUserID(id: number): Observable<UserRole[]> {
        return super.GetAll('filter=UserID eq ' + id);
    }

    bulkUpdate(addRoles: Partial<UserRole>[], removeRoles?: Partial<UserRole>[]) {
        let addRequest = observableOf(true);
        if (addRoles?.length) {
            addRequest = this.ActionWithBody(null, addRoles, 'bulk-insert-roles', RequestMethod.Post);
        }

        let deleteRequest = observableOf(true);
        if (removeRoles?.length) {
            const removeRolesIds = removeRoles.map((role) => role.ID).join(',');
            deleteRequest = this.DeleteAction(null, 'bulk-delete-roles', `userRoleIds=${removeRolesIds}`);
        }

        return addRequest.pipe(switchMap(() => deleteRequest));
    }

    hasAdminRole(userID: number): Observable<boolean> {
        return this.getRolesByUserID(userID).pipe(
            catchError(() => observableOf([])),
            map((userRoles) => {
                return userRoles?.some((role) => {
                    return role.SharedRoleName === 'Administrator';
                });
            }),
        );
    }

    hasOnlyAdminRole(userID: number): Observable<boolean> {
        return this.getRolesByUserID(userID).pipe(
            catchError(() => observableOf([])),
            map((userRoles) => {
                return userRoles?.length === 1 && userRoles[0].SharedRoleName === 'Administrator';
            }),
        );
    }
}
