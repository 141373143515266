import { HttpClient } from '@angular/common/http';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ISelectConfig } from '@uni-framework/ui/uni-select/select';
import { QuickFilter } from '@uni-framework/ui/unitable';
import { Subject } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

@Component({
    selector: 'quick-filters',
    templateUrl: './quick-filters.html',
    styleUrls: ['./quick-filters.sass'],
})
export class QuickFilters {
    @Input() quickFilters: QuickFilter[];
    @Output() quickFiltersChange = new EventEmitter<QuickFilter[]>();

    changeEventDebouncer$ = new Subject();

    selectConfig: ISelectConfig = {
        valueProperty: 'value',
        displayProperty: 'label',
        searchable: false,
    };

    constructor(private httpClient: HttpClient) {
        this.changeEventDebouncer$.pipe(debounceTime(250)).subscribe(() => {
            this.quickFiltersChange.emit(this.quickFilters);
        });
    }

    ngOnChanges() {
        this.quickFilters?.forEach((filter) => {
            if (filter.optionsEndpoint) {
                filter.optionsResolver = () =>
                    this.httpClient.get<any>(filter.optionsEndpoint).pipe(map((res) => res?.Data ?? res));
            }
        });
    }

    ngOnDestroy() {
        this.changeEventDebouncer$.complete();
    }
}
