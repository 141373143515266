<section class="help">
    <article class="help-icon">
        <i class="material-icons">support</i>
    </article>
    <article class="help-content">
        <span class="help-text-header">
            {{ title }}
            <a *ngIf="!showHelpText" (click)="toggleHelpText($event)">Vis mer</a>
        </span>
        <span class="help-text" *ngIf="showHelpText">
            {{ helpText }}
            <a (click)="toggleHelpText($event)">Vis mindre</a>
        </span>
    </article>
</section>
