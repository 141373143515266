import { map, switchMap, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BizHttp } from '../../../framework/core/http/BizHttp';
import { NumberSeries } from '../../unientities';
import { UniHttp } from '../../../framework/core/http/http';
import { Observable, forkJoin, of } from 'rxjs';
import { StatisticsService } from '@app/services/common/statisticsService';
import { ConfirmActions, IModalOptions, UniModalService } from '@uni-framework/uni-modal';
import { ToastService, ToastType } from '@uni-framework/uniToast/toastService';
import { AccountService } from '../accounting/accountService';
import { NumberSeriesTypeService } from './numberSeriesTypeService';
import { AuthService } from '@app/authService';

const MAXNUMBER = 2147483647;

@Injectable({ providedIn: 'root' })
export class NumberSeriesService extends BizHttp<NumberSeries> {
    public suggestions: any[] = [
        {
            Name: 'JournalEntry supplierinvoice number series type',
            DisplayName: 'Faktura bilag (innkjøp)',
            _Task: 'SupplierInvoice',
            _Register: 'Bilag',
            NextNumber: null,
            _FromNumber: 60000,
            _ToNumber: 69999,
            _NextNumber: 60000,
            UseNumbersFromNumberSeriesID: null,
        },
        {
            Name: 'JournalEntry salary number series type',
            DisplayName: 'Lønnsbilag',
            _Task: 'Salary',
            _Register: 'Bilag',
            NextNumber: null,
            _FromNumber: 70000,
            _ToNumber: 79999,
            _NextNumber: 70000,
            UseNumbersFromNumberSeriesID: null,
        },
        {
            Name: 'JournalEntry bank number series type',
            DisplayName: 'Bank',
            _Task: 'Bank',
            _Register: 'Bilag',
            NextNumber: null,
            _FromNumber: 80000,
            _ToNumber: 89999,
            _NextNumber: 80000,
            UseNumbersFromNumberSeriesID: null,
        },
        {
            Name: 'JournalEntry vatreport number series type',
            DisplayName: 'MVA',
            _Task: 'VatReport',
            _Register: 'Bilag',
            NextNumber: null,
            _FromNumber: 90000,
            _ToNumber: 99999,
            _NextNumber: 90000,
            UseNumbersFromNumberSeriesID: null,
        },
        {
            Name: 'JournalEntry invoice number series type',
            DisplayName: 'Faktura bilag (salg)',
            _Task: 'CustomerInvoice',
            _Register: 'Bilag',
            NextNumber: null,
            _FromNumber: 100000,
            _ToNumber: MAXNUMBER,
            _NextNumber: 100000,
            UseNumbersFromNumberSeriesID: null,
        },
    ];

    public asinvoicenumber: any[] = [
        { ID: false, DisplayName: 'Nei' },
        { ID: true, DisplayName: 'Ja' },
    ];

    public registers: any[] = [
        { EntityType: 'JournalEntry', DisplayName: 'Bilag', Sale: false, EmployeeLedger: false },
        { EntityType: 'Customer', DisplayName: 'Kunde', Sale: false, EmployeeLedger: false },
        { EntityType: 'Supplier', DisplayName: 'Leverandør', Sale: false, EmployeeLedger: false },
        { EntityType: 'Supplier', DisplayName: 'Ansatt', Sale: false, EmployeeLedger: true },
        { EntityType: 'CustomerInvoice', DisplayName: 'Faktura', Sale: true, EmployeeLedger: false },
        { EntityType: 'CustomerOrder', DisplayName: 'Ordre', Sale: true, EmployeeLedger: false },
        { EntityType: 'CustomerQuote', DisplayName: 'Tilbud', Sale: true, EmployeeLedger: false },
        { EntityType: 'CustomerInvoiceReminder', DisplayName: 'Purring', Sale: true, EmployeeLedger: false },
        { EntityType: 'Project', DisplayName: 'Prosjekt', Sale: false, EmployeeLedger: false },
        { EntityType: 'Department', DisplayName: 'Avdeling', Sale: false, EmployeeLedger: false },
    ];

    numberSeries: NumberSeries[] = [];
    employeeNumberSeries: number = 2910;
    employeeLedgerNumberSeries: NumberSeries;

    constructor(
        http: UniHttp,
        private statisticsService: StatisticsService,
        private toastService: ToastService,
        private accountService: AccountService,
        private numberSeriesTypeService: NumberSeriesTypeService,
        private modalService: UniModalService,
        private autService: AuthService,
    ) {
        super(http);

        this.relativeURL = NumberSeries.RelativeUrl;
        this.entityType = NumberSeries.EntityType;
        this.DefaultOrderBy = null;
        this.autService.companyChange.subscribe(() => (this.employeeLedgerNumberSeries = undefined));
    }

    loadNumberSeries(filter?: string): Observable<NumberSeries[]> {
        if (this.numberSeries?.length) {
            return of(this.numberSeries);
        } else {
            return this.getNumberSeriesList(filter).pipe(
                switchMap((numberSeries) => {
                    this.numberSeries = numberSeries;
                    return of(numberSeries);
                }),
            );
        }
    }

    getEmployeesNumberSeriesRange() {
        return this.statisticsService
            .GetAllUnwrapped(
                `model=numberseries` +
                    `&select=FromNumber as FromNumber,ToNumber as ToNumber` +
                    `&expand=NumberSeriesType` +
                    `&filter=NumberSeriesType.entitytype eq 'supplier' and NumberSeriesType.isemployeeledger eq 1`,
            )
            .pipe(
                map((result: any) => {
                    if (!result || result.length === 0) {
                        return {
                            FromNumber: -1,
                            ToNumber: -1,
                        };
                    }
                    return {
                        FromNumber: result[0].FromNumber,
                        ToNumber: result[0].ToNumber,
                    };
                }),
            );
    }

    getCustomerOrSupplierNumberSeries() {
        return this.loadNumberSeries().pipe(
            switchMap((numberSeries) => {
                return of(
                    numberSeries.filter(
                        (ns) =>
                            ns.NumberSeriesType.EntityType === 'Supplier' ||
                            ns.NumberSeriesType.EntityType === 'Customer',
                    ),
                );
            }),
        );
    }

    getEmployeeNumberSeries() {
        return this.loadNumberSeries().pipe(
            switchMap((numberSeries) => {
                let empledgerSeries = numberSeries.filter(
                    (ns) =>
                        ns.NumberSeriesType.EntityType === 'Supplier' &&
                        ns.NumberSeriesType.IsEmployeeLedger &&
                        ns.IsDefaultForTask,
                );
                if (empledgerSeries.length == 1) return of(empledgerSeries);
                empledgerSeries = numberSeries.filter(
                    (ns) => ns.NumberSeriesType.EntityType === 'Supplier' && ns.NumberSeriesType.IsEmployeeLedger,
                );
                if (empledgerSeries.length == 1) {
                    //Has one serie, but not DefaultOrderBy
                    const empledgerSerie = empledgerSeries[0];
                    empledgerSerie.IsDefaultForTask = true;
                    return this.Put(empledgerSerie.ID, empledgerSerie).pipe(map((res) => [res]));
                }
                return of([]);
            }),
        );
    }

    getInvoiceNumberSeries() {
        return this.loadNumberSeries().pipe(
            switchMap((numberSeries) => {
                const invoiceNumberSeries = numberSeries.filter(
                    (ns) => ns.NumberSeriesType?.EntityType === 'CustomerInvoice' && !ns.Disabled && !ns.Deleted,
                );

                if (!invoiceNumberSeries?.length) {
                    return of(null);
                }

                let response = invoiceNumberSeries.find((ns) => ns.IsDefaultForTask);

                return of(response || invoiceNumberSeries[0]);
            }),
        );
    }

    getNamedNumberSeries(name: string) {
        return this.loadNumberSeries().pipe(
            switchMap((numberSeries) => {
                const matchedNumberSeries = numberSeries.filter(
                    (ns) => !ns.Deleted && !ns.Disabled && !ns.Empty && ns.NumberSeriesType?.Name === name,
                );
                return of(matchedNumberSeries);
            }),
        );
    }

    public getNumberSeriesList(filter: string = null) {
        return this.http
            .asGET()
            .usingBusinessDomain()
            .withEndPoint(
                `number-series?hateoas=false&orderby=AccountYear desc,NumberSeriesTaskID,FromNumber&expand=NumberSeriesType,NumberSeriesTask,MainAccount${filter ? '&filter=' + filter : ''}`,
            )
            .send()
            .pipe(map((response) => response.body));
    }

    public getActiveNumberSeries(entityType: string, year: number): Observable<any> {
        return this.http
            .asGET()
            .usingBusinessDomain()
            .withEndPoint(`${this.relativeURL}?action=get-active-numberseries&entityType=${entityType}&year=${year}`)
            .send()
            .pipe(map((response) => response.body));
    }

    public getAvailableNumbersInNumberSeries(numberSeriesID: number): Observable<string[]> {
        return this.http
            .asGET()
            .usingBusinessDomain()
            .withEndPoint(
                `${this.relativeURL}?action=get-available-numbers-in-numberseries&numberSeriesID=${numberSeriesID}`,
            )
            .send()
            .pipe(
                map((response) => response.body),
                map((numberIntervalsStrings) => numberIntervalsStrings.map((x) => x.replace(',', ' - '))),
            );
    }

    public findAndSetNextNumber(numberSeriesID: number): Observable<any> {
        return this.http
            .asPUT()
            .usingBusinessDomain()
            .withEndPoint(`${this.relativeURL}?action=reset-numberseries-next-number&numberSeriesID=${numberSeriesID}`)
            .send();
    }

    public getSelectConfig(ID: number, numberSeries: any[], numberSerieName: string, selectedItem?: any): any {
        return numberSeries && numberSeries.length > 1 && ID === 0
            ? {
                  items: numberSeries,
                  selectedItem: selectedItem || numberSeries.find((x) => x.Name === numberSerieName),
                  label: 'Nummerserie',
              }
            : null;
    }

    public deleteNotUsedSeries(numberSeriesID: number): Observable<any> {
        return this.http
            .asPUT()
            .usingBusinessDomain()
            .withEndPoint(`${this.relativeURL}?action=delete-unused-numberseries&numberSeriesID=${numberSeriesID}`)
            .send()
            .pipe(map((response) => response.body));
    }

    public setNumberSeriesDisplayName(series: Array<NumberSeries>) {
        series.forEach((serie) => {
            serie['_DisplayName'] = serie.DisplayName;
        });
        return series;
    }

    invalidateCache() {
        this.numberSeries = [];
        super.invalidateCache();
    }

    getOrCreateEmployeeLedgerNumberSeries(numberserieMsg?: string): Observable<NumberSeries> {
        if (this.employeeLedgerNumberSeries) {
            return of(this.employeeLedgerNumberSeries);
        }

        return this.getEmployeeLedgerNumberSeries().pipe(
            switchMap((res) => {
                if (res) {
                    return of(res);
                }

                // Create number series
                return this.getNumberSeries().pipe(
                    switchMap((res) => this.findFreeNumberseries(res, numberserieMsg)),
                    switchMap((range) => {
                        if (range) {
                            return this.createEmployeeLedgerNumberSeries(range[0], range[1]);
                        } else {
                            return of(null);
                        }
                    }),
                );
            }),
            tap((res) => (this.employeeLedgerNumberSeries = res)),
        );
    }

    private getEmployeeLedgerNumberSeries(): Observable<any> {
        const numberSeries =
            `model=numberseries&select=ID as ID,FromNumber as FromNumber,ToNumber as ToNumber,mainaccount.accountname as AccountName,` +
            `mainaccount.accountnumber as AccountNumber,NumberSeriesTypeID as NumberSeriesTypeID,Name as Name,IsDefaultForTask as IsDefaultForTask` +
            `&filter=numberseriestype.entitytype eq 'supplier' and numberseriestype.isemployeeledger eq 1` +
            `&join=&expand=numberseriestype,mainaccount`;

        return this.statisticsService.GetAllUnwrapped(numberSeries).pipe(
            switchMap((res) => {
                if (!res.length) {
                    return of(null);
                }

                const defaultSeries = res.find((s) => s.IsDefaultForTask);
                if (defaultSeries) {
                    return of(defaultSeries);
                } else if (res.length === 1) {
                    // If there's only one series we can set it as standard
                    res[0].IsDefaultForTask = true;
                    return this.Put(res[0].ID, res[0]);
                } else {
                    // If there are more than one the user will have to decide for themselves
                    this.toastService.addToast(
                        'Nummerserie finnes',
                        ToastType.bad,
                        20,
                        'Nummerserie for ansattreskontro finnes, men ingen er standard. Gå til nummerserie og korriger',
                    );
                    return of(null);
                }
            }),
        );
    }

    private getNumberSeries(): Observable<any[]> {
        const numberSeries =
            `model=numberseries&select=fromnumber,tonumber,mainaccount.accountname,mainaccount.accountnumber,` +
            `NumberSeriesTypeID as NumberSeriesTypeID,Name as Name` +
            `&filter=numberseriestype.entitytype eq 'customer' or numberseriestype.entitytype eq 'supplier'` +
            `&join=&expand=numberseriestype,mainaccount&top=&orderby=fromnumber`;
        return this.statisticsService.GetAllUnwrapped(numberSeries);
    }

    private findFreeNumberseries(numberseries: any[], numberserieMsg?: string): Observable<number[] | undefined> {
        if (!numberseries?.length) {
            return of(undefined);
        }

        let suggestedStart = 0;
        let suggestedEnd = 0;
        let suggestionFree: boolean = false;
        let failedToFindMatch = false;
        const suggestions: number[][] = [
            [50000, 59999],
            [70000, 79999],
            [90000, 99999],
        ];

        for (const suggestion of suggestions) {
            suggestionFree = true;
            suggestedStart = suggestion[0];
            suggestedEnd = suggestion[1];
            for (let i = 0; i < numberseries.length; i++) {
                if (
                    (suggestedStart >= numberseries[i].NumberSeriesFromNumber &&
                        suggestedStart <= numberseries[i].NumberSeriesToNumber) ||
                    (suggestedEnd >= numberseries[i].NumberSeriesFromNumber &&
                        suggestedEnd <= numberseries[i].NumberSeriesToNumber)
                ) {
                    suggestionFree = false;
                    break;
                }
            }
            if (suggestionFree) {
                break;
            }
        }
        if (!suggestionFree) {
            failedToFindMatch = true;
        }

        const data: IModalOptions = {
            header: 'Ny nummerserie for ansatte',
            message: numberserieMsg
                ? numberserieMsg
                : `Systemet foreslår å opprette nummerserien ` +
                  `<strong>Ansattreskontro: ${suggestedStart} - ${suggestedEnd}</strong> <br/> ` +
                  `Trykk 'Fortsett' for å opprette serie. ` +
                  `Om du ønsker å definere egen serie, gå til Innstillinger - Nummerserier. `,
            buttonLabels: {
                accept: 'Fortsett',
                cancel: 'Avbryt',
            },
        };

        if (failedToFindMatch) {
            (data.message =
                `Systemet klarte dessverre ikke å finne en ledig serie som tilfredsstilte kravene. ` +
                `Om du ønsker å sette egen serie, gå til Innstillinger - Nummerserier.  ` +
                `Husk å koble på rett konto, og at PostPost på den kontoen må være aktivert.`),
                (data.buttonLabels.accept = undefined);
        }

        // Show the user the systems findings and ask before moving on
        return this.modalService.confirm(data).onClose.pipe(
            switchMap((res: ConfirmActions) => {
                if (res === ConfirmActions.ACCEPT) {
                    // Get account for set at MainAccount on numberseries + need to check/update UsePostPost later
                    return of([suggestedStart, suggestedEnd]);
                }
                return of(undefined);
            }),
        );
    }

    private createEmployeeLedgerNumberSeries(start: number, end: number): Observable<any> {
        return forkJoin([
            this.accountService.GetAll(`filter=AccountNumber eq ${this.employeeNumberSeries}`),
            this.statisticsService.GetAllUnwrapped(
                `model=numberseriestype&select=ID as id` + `&filter=entitytype eq 'supplier' and isemployeeledger eq 1`,
            ),
        ]).pipe(
            switchMap(([accounts, numberSeriesType]) => {
                if (accounts && accounts.length && numberSeriesType && numberSeriesType.length) {
                    const account = accounts[0];
                    const numberSeriesTypeID = numberSeriesType[0].id;
                    const body = {
                        ID: numberSeriesTypeID,
                        Series: [
                            {
                                FromNumber: start,
                                NextNumber: start,
                                ToNumber: end,
                                Name: 'Employee Ledger number series',
                                DisplayName: 'Ansattreskontro',
                                MainAccountID: account.ID,
                                IsDefaultForTask: true,
                                NumberSeriesTypeID: numberSeriesTypeID,
                                _createguid: this.numberSeriesTypeService.getNewGuid(),
                            },
                        ],
                    };
                    // Create the new number series
                    return this.numberSeriesTypeService.Put(numberSeriesTypeID, body).pipe(
                        switchMap(() => this.getEmployeeLedgerNumberSeries()),
                        tap(() => {
                            this.toastService.addToast(
                                'Nummerserie opprettet',
                                ToastType.good,
                                10,
                                'Nummerserie for ansattreskontro opprettet',
                            );

                            // Check if the account has active UsePostPost, if not, update the account..
                            if (!account.UsePostPost) {
                                account.UsePostPost = true;
                                this.accountService.Put(account.ID, account);
                            }
                        }),
                    );
                } else {
                    if (!numberSeriesType?.length) {
                        this.toastService.addToast(
                            'Nummerserie kan ikke opprettes',
                            ToastType.bad,
                            10,
                            `Mangler nummer serie oppgave Ansatt`,
                        );
                    } else {
                        this.toastService.addToast(
                            'Nummerserie kan ikke opprettes',
                            ToastType.bad,
                            10,
                            `Mangler konto ${this.employeeNumberSeries} for nummerserie`,
                        );
                    }
                    return of(undefined);
                }
            }),
        );
    }
}
