import {
    Component,
    Input,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Output,
    EventEmitter,
    ViewChild,
} from '@angular/core';
import { RigWizardTabs } from './wizard-tabs/wizard-tabs';

export interface WizardStep {
    label: string;
    value: any;
}

@Component({
    selector: 'rig-wizard',
    templateUrl: './wizard.html',
    styleUrls: ['./wizard.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RigWizard {
    @Input() steps: WizardStep[];
    @Input() showStepsAsTabs: boolean = false;
    @Input() activeStepValue: string;

    @Output() activeStepValueChange = new EventEmitter();
    @ViewChild(RigWizardTabs) tabs: RigWizardTabs;

    activeIndex: number;

    constructor(private changeDetector: ChangeDetectorRef) {}

    ngOnChanges() {
        this.activeIndex = this.steps?.findIndex((step) => step.value === this.activeStepValue) ?? 0;
    }

    onActiveIndexChange(index: number) {
        this.activeIndex = index;
        this.activeStepValueChange.emit(this.steps[index].value);
        this.changeDetector.markForCheck();
    }
}
