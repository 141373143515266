<ng-container *ngIf="config">
    <section
        class="input-with-button"
        (click)="toggle()"
        (clickOutside)="close()"
        (keydown.tab)="$event.stopPropagation()"
    >
        <input
            type="text"
            #valueInput
            class="select-input"
            [value]="displayValue"
            [placeholder]="placeholder || config?.placeholder || ''"
            [attr.aria-readonly]="readonly"
            [tabindex]="readonly ? '-1' : '0'"
            (keydown)="onKeyDown($event)"
            (keydown.delete)="clear($event)"
            (keydown.space)="$event.preventDefault(); toggle()"
            readonly
        />

        <button type="button" tabIndex="-1" style="pointer-events: none" [disabled]="readonly">
            <i class="material-icons">expand_more</i>
        </button>
    </section>

    <input-dropdown-menu [input]="valueInput" [visible]="expanded" [maxHeight]="config?.dropdownMaxHeight">
        <ng-template>
            <section class="select-search" *ngIf="searchable">
                <input
                    #searchInput
                    (keydown)="onKeyDown($event)"
                    type="search"
                    [placeholder]="'Filtrer elementer'"
                    [formControl]="searchControl"
                    tabindex="-1"
                />
            </section>

            <uni-select-item-list
                [items]="visibleItems"
                [itemGroups]="itemGroups"
                [value]="selectedItem"
                [search]="filterString"
                [config]="config"
                (itemSelected)="confirmSelection($event)"
                (close)="close(true)"
            >
            </uni-select-item-list>
        </ng-template>
    </input-dropdown-menu>
</ng-container>
