import { Component, Input } from '@angular/core';

@Component({
    selector: 'cms-illustration',
    template: `<uni-illustration-deprecated [path]="path"></uni-illustration-deprecated>`,
    styleUrls: ['./illustration.sass'],
})
export class CMSIllustration {
    @Input() item: any;
    path: string = '';
    sharedIllustations = [
        'astronaut_pointing_finger',
        'astronaut_business',
        'astronaut_start',
        'astronaut_complete',
        'astronaut_happy',
    ];

    constructor() {}

    ngOnInit() {
        // Because of a somewhat messy illustration placement system, we have to check
        // for uni illustrations and point them to the shared folder. WIP on illustrations!
        this.path = this.sharedIllustations.includes(this.item.illustration)
            ? this.item.illustration
                ? `config/themes/shared/${this.item.illustration}.svg`
                : ''
            : `config/dist/theme/assets/${this.item.illustration}.svg`;
    }
}
