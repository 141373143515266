import { Injectable } from '@angular/core';
import { UniTableColumn, UniTableColumnType, UniTableColumnSortMode } from './config/unitableColumn';
import { rigDate } from '@app/components/common/utils/rig-date';
import { BrowserStorageService } from '@uni-framework/core/browserStorageService';
import { List, Map } from 'immutable';

interface IColumnSetupMap {
    [key: string]: UniTableColumn[];
}

const CONFIG_STORAGE_KEY: string = 'uniTable_column_configs';

@Injectable()
export class UniTableUtils {
    private columnSetupMap: IColumnSetupMap = {};

    constructor(private browserStorage: BrowserStorageService) {
        try {
            this.columnSetupMap = browserStorage.getItem(CONFIG_STORAGE_KEY) || {};
        } catch (e) {
            console.log('Error trying to get column setup or filters (unitableUtils constructor)');
            console.log(e);
            this.columnSetupMap = {};
        }
    }

    public makeColumnsImmutable(columns: UniTableColumn[]): Immutable.List<any> {
        let immutableColumns = List();
        columns.forEach((col) => {
            // REVISIT: do we really need a list of maps?
            // Maybe remove the map? Added benefit of not having to do column.get('..')
            const map = Map(col);
            immutableColumns = immutableColumns.push(map);
        });

        return immutableColumns;
    }

    public getColumnSetup(key: string): UniTableColumn[] {
        return this.columnSetupMap[key];
    }

    public saveColumnSetup(key: string, columns: UniTableColumn[]): void {
        if (!key || !columns) {
            return;
        }

        // Since storage can't hold functions/classes we shouldn't save those parts of a column config
        // Because of this we only save the fields we can edit in the column modal
        let safeToSave = [];
        columns.forEach((col: UniTableColumn) => {
            safeToSave.push({
                field: col.field,
                visible: col.visible,
                jumpToColumn: col.jumpToColumn,
                _originalField: col['_originalField'],
                sumFunction: col.sumFunction,
                alias: col.alias,
                index: col.index,
            });
        });

        try {
            this.columnSetupMap[key] = safeToSave;
            this.browserStorage.setItem(CONFIG_STORAGE_KEY, this.columnSetupMap);
        } catch (e) {
            console.log(e);
        }
    }

    public removeColumnSetup(key: string): void {
        if (!key) {
            return;
        }

        try {
            delete this.columnSetupMap[key];
            this.browserStorage.setItem(CONFIG_STORAGE_KEY, this.columnSetupMap);
        } catch (e) {
            console.log(e);
        }
    }

    /**
     * Gets the initial value for an editor based on field value
     * @param {any} filters
     * @param {any} column
     *
     * @returns {string} init value
     */
    public getInitValue(rowModel: Immutable.Map<any, any>, column): string {
        if (!rowModel) {
            return '';
        }
        let columnType = column.get('type');
        let field = column.get('displayField') || column.get('field');
        let initValue;

        if (column.get('template')) {
            initValue = column.get('template')(rowModel.toJS()) || '';
        } else {
            initValue = rowModel.getIn(field.split('.')) || '';
        }

        if (columnType === UniTableColumnType.LocalDate && initValue) {
            const date = initValue.toDate ? initValue.toDate() : initValue;
            initValue = rigDate(date).isValid() ? rigDate(date).format('L') : '';
        }

        if (
            columnType === UniTableColumnType.Number ||
            columnType === UniTableColumnType.Money ||
            columnType === UniTableColumnType.Percent
        ) {
            let format = column.get('numberFormat');
            initValue = initValue.toString().replace('.', format.decimalSeparator);
        }

        return initValue || '';
    }

    /**
     * Returns the array sorted by specified field.
     * Subsequent calls with the same field toggles asc/desc/nosort.
     *
     * @param   {string} field
     * @param   {string} direction
     * @param   {number} type
     * @param   {number} mode
     * @param   {Immutable.List} data
     * @returns {Immutable.List} data param sorted by field
     */
    public sort(
        field: string,
        direction: number,
        type: number,
        mode: number,
        data: Immutable.List<any>,
    ): Immutable.List<any> {
        if (direction === 0) {
            return data;
        }

        return data
            .sort((a, b) => {
                let fst = a.getIn(field.split('.')) || '';
                let snd = b.getIn(field.split('.')) || '';

                //Different sorting for different types of data
                switch (type) {
                    case UniTableColumnType.LocalDate:
                    case UniTableColumnType.DateTime:
                        fst = (fst ? rigDate(fst) : rigDate()).unix();
                        snd = (snd ? rigDate(snd) : rigDate()).unix();
                        break;
                    case UniTableColumnType.Money:
                    case UniTableColumnType.Number:
                    case UniTableColumnType.Percent:
                        if (mode === UniTableColumnSortMode.Absolute) {
                            fst = Math.abs(fst);
                            snd = Math.abs(snd);
                        }
                        break;
                    default:
                        fst = fst.toString().toLowerCase();
                        snd = snd.toString().toLowerCase();
                        break;
                }

                if (fst === snd) {
                    return 0;
                } else if (fst > snd) {
                    return 1 * direction;
                } else if (fst < snd) {
                    return -1 * direction;
                }
            })
            .toList();
    }

    public getFirstFocusableRow(rows: HTMLTableRowElement[], startIndex?: number): HTMLTableRowElement {
        for (let i = startIndex || 0; i < rows.length; i++) {
            if (rows[i].getAttribute('aria-readonly') !== 'true') {
                return rows[i];
            }
        }

        return;
    }

    public getLastFocusableRow(rows: HTMLTableRowElement[], startIndex?: number): HTMLTableRowElement {
        for (let i = startIndex || rows.length - 1; i >= 0; i--) {
            if (rows[i].getAttribute('aria-readonly') !== 'true') {
                return rows[i];
            }
        }

        return;
    }

    public getFirstFocusableCell(row: HTMLTableRowElement, startIndex?: number): HTMLTableCellElement {
        const cells = row.cells || [];
        for (let i = startIndex || 0; i < cells.length; i++) {
            if (cells[i].tabIndex >= 0) {
                return cells[i];
            }
        }

        return;
    }

    public getLastFocusableCell(
        row: HTMLTableRowElement,
        tableColumns,
        config,
        startIndex?: number,
    ): HTMLTableCellElement {
        const cells = row.cells || [];
        for (let i = startIndex || cells.length - 1; i >= 0; i--) {
            const columnConfigIndex: number = config.multiRowSelect ? i - 1 : i;
            const column = tableColumns.get(columnConfigIndex);

            if (cells[i].tabIndex >= 0 && column.get('visible')) {
                return cells[i];
            }
        }

        return;
    }

    // Helpers
    private isNumber(value: any): boolean {
        return !isNaN(value) && isFinite(value);
    }
}
