<section class="external-lookup">
    <autocomplete [options]="externalLookupOptions" (valueChange)="onExternalSearchSelected($event)"> </autocomplete>
</section>

<uni-form
    [config]="{ autofocus: true, showLabelAbove: true }"
    [fields]="formFields"
    [model]="entity"
    (changeEvent)="onChange()"
></uni-form>
<uni-alert-component [isOpen]="existsOrgNumber" [type]="'warn'" [icon]="'warning'">
    Det finnes allerede en {{ entityTypeText }} med dette organisasjonsnummeret. Ønsker du å bruke eksistrerende
    {{ entityTypeText }} istedenfor å opprette ny?
    <button class="tertiary c2a" (click)="selectEntity()">Bruke eksistrerende {{ entityTypeText }}</button>
</uni-alert-component>
<section class="alert info">
    <i class="material-icons">info</i>
    <span *ngIf="entityType === 'customer'"
        >Du kan legge til mer informasjon om kunden senere under menyvalget Salg -> Kunder.</span
    >
    <span *ngIf="entityType === 'supplier'"
        >Du kan legge til mer informasjon om leverandøren senere under menyvalget Regnskap -> Leverandør.</span
    >
</section>
