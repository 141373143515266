import { EventEmitter, Injectable } from '@angular/core';
import { Observable, forkJoin, of, from, BehaviorSubject } from 'rxjs';
import { switchMap, catchError, map, tap, finalize } from 'rxjs/operators';
import { BizHttp } from '../../../framework/core/http/BizHttp';
import { UniHttp } from '../../../framework/core/http/http';
import { StimulsoftReportWrapper } from '../../../framework/wrappers/reporting/reportWrapper';
import { ErrorService } from '../common/errorService';
import { ReportDefinition, ReportDefinitionParameter, ReportDefinitionDataSource, LocalDate } from '../../unientities';
import { environment } from 'src/environments/environment';
import { StatisticsService } from '@app/services/common/statisticsService';

@Injectable({ providedIn: 'root' })
export class ReportService extends BizHttp<string> {
    public reportLayoutName: string;
    private report: Report;
    private target: any;
    private format: string;
    private parentModalIsClosed: boolean;

    progress$: BehaviorSubject<number> = new BehaviorSubject(0);

    constructor(
        http: UniHttp,
        private errorService: ErrorService,
        private reportGenerator: StimulsoftReportWrapper,
        private statisticsService: StatisticsService,
    ) {
        super(http);
        this.relativeURL = 'report';
        this.entityType = null;
        this.DefaultOrderBy = null;
    }

    getDataSources(reportDefinitionID) {
        return this.http
            .asGET()
            .usingBusinessDomain()
            .withEndPoint(`report-definition-data-sources?filter=reportdefinitionid eq ${reportDefinitionID}`)
            .send()
            .pipe(map((res) => res.body));
    }

    getReportTemplate(reportID: number) {
        return this.http
            .asGET()
            .usingRootDomain()
            .withEndPoint(
                `${this.relativeURL}/${reportID}` + (this.reportLayoutName ? `?layout=${this.reportLayoutName}` : ''),
            )
            .send()
            .pipe(map((response) => response.body));
    }

    public generateReportFormat(format: string, report: ReportDefinition, doneHandler: (msg: string) => void = null) {
        this.format = format;
        this.report = <Report>report;
        this.target = null;

        this.generateReport(doneHandler);
    }

    private generateReport(doneHandler: (msg: string) => void = null) {
        forkJoin([this.generateReportObservable(), this.getDataSourcesObservable()]).subscribe((res) => {
            this.onDataFetched(this.report.dataSources, doneHandler);
        });
    }

    private renderReport() {
        return new Promise((resolve, reject) => {
            if (this.parentModalIsClosed) {
                reject();
            }
            this.reportGenerator.renderReport(
                this.report.templateJson,
                this.report.dataSources,
                this.report.parameters,
                this.report.localization,
                resolve,
            );
        });
    }

    public getDistributions(entity: string) {
        return this.http
            .asGET()
            .usingBusinessDomain()
            .withEndPoint(
                `distributions?filter=StatusCode eq 30001 and EntityType eq '${entity}'&expand=Elements,Elements.ElementType`,
            )
            .send()
            .pipe(map((res) => res.body));
    }

    public getDistributions2Types(entity1: string, entity2: string) {
        return this.http
            .asGET()
            .usingBusinessDomain()
            .withEndPoint(
                `distributions?filter=StatusCode eq 30001 and (EntityType eq '${entity1}' or EntityType eq '${entity2}')`,
            )
            .send()
            .pipe(map((res) => res.body));
    }

    public distribute(id, entityType) {
        return this.http
            .asPUT()
            .usingBusinessDomain()
            .withEndPoint(`distributions?action=distribute&id=${id}&entityType=${entityType}`)
            .send()
            .pipe(map((res) => res.body));
    }

    public distributeWithType(id, entityType, distributionType) {
        const endpoint =
            `distributions?action=distribute-with-type` +
            `&id=${id}&distributiontype=${distributionType}&entityType=${entityType}`;

        return this.http
            .asPUT()
            .usingBusinessDomain()
            .withEndPoint(endpoint)
            .send()
            .pipe(map((res) => res.body));
    }

    public distributeWithTypeAndBody(id, entityType, distrubtionType, body) {
        return this.http
            .asPUT()
            .usingBusinessDomain()
            .withEndPoint(
                `distributions?action=distribute-with-type-and-parameters&id=${id}&distributiontype=${distrubtionType}&entityType=${entityType}`,
            )
            .withBody(body)
            .send()
            .pipe(map((res) => res.body));
    }

    public distributeWithDate(id: number, entityType: string, distributeDate: LocalDate) {
        const endpoint = `distributions?action=distribute-with-date&id=${id}&entityType=${entityType}&distributeDate=${distributeDate}`;

        return this.http
            .asPUT()
            .usingBusinessDomain()
            .withEndPoint(endpoint)
            .send()
            .pipe(map((res) => res.body));
    }

    public startReportProcess(reportDefinition, target: any, closeEmitter: EventEmitter<boolean>) {
        this.parentModalIsClosed = false;
        this.format = 'html';
        this.report = <Report>reportDefinition;
        this.target = target;
        const s = closeEmitter.subscribe(() => {
            this.parentModalIsClosed = true;
            s.unsubscribe();
        });

        return forkJoin([
            this.generateReportObservable().pipe(
                tap(() => {
                    this.progress$.next(this.progress$.value + 25);
                }),
            ),
            this.getDataSourcesObservable().pipe(
                tap(() => {
                    this.progress$.next(this.progress$.value + 25);
                }),
            ),
            from(this.reportGenerator.loadLibraries()).pipe(tap(() => this.progress$.next(this.progress$.value + 25))),
        ]).pipe(
            switchMap(() => this.renderReport()),
            switchMap((renderedReport) => {
                this.progress$.next(90);
                const continousPreview = this.getMaxRowCount(this.report) < 250;
                return this.renderHtml(renderedReport, continousPreview);
            }),
            finalize(() => this.progress$.next(0)),
        );
    }

    private generateReportObservable() {
        // Add logo url to report
        this.addLogoUrl();

        return this.getReportTemplate(this.report.ID).pipe(
            map((template) => {
                this.report.templateJson = template;
            }),
        );
    }

    private getDataSourcesObservable(): Observable<any> {
        // REVISIT: quickfix for getting report data client side
        // instead of the api having to worry about it.
        // This function (the entire service tbh) should be refactored.
        return this.getDataSources(this.report.ID).pipe(
            switchMap((ds) => {
                const datasources = ds || [];
                const params = this.report.parameters || [];

                const companyKey = this.report['companyKey'];
                if (companyKey) {
                    this.http.appendHeaders({ CompanyKey: companyKey });
                }

                const getData = (name, endpoint) => {
                    return this.http
                        .asGET()
                        .usingEmptyDomain()
                        .withEndPoint(endpoint)
                        .send(undefined, undefined, !companyKey)
                        .pipe(
                            catchError((err) => {
                                console.error(err);
                                return of([]);
                            }),
                            map((res) => {
                                return {
                                    name: name,
                                    data: res.body ? res.body : res,
                                };
                            }),
                        );
                };

                const requests = datasources.map((datasource) => {
                    let endpoint = datasource.DataSourceUrl;
                    params.forEach((param) => {
                        if (endpoint.includes(`{${param.Name}}`)) {
                            const safeValue = param.value === undefined ? '' : param.value;
                            endpoint = endpoint.split(`{${param.Name}}`).join(safeValue);
                        }
                    });

                    return getData(datasource.Name, endpoint);
                });

                return forkJoin(requests).pipe(
                    map((response: any[]) => {
                        const data: any = {};
                        (response || []).forEach((obj: any) => {
                            data[obj.name] = obj.data;
                        });

                        this.report.dataSources = data;
                        if (!this.report.localization) {
                            this.getLocalizationOverride();
                        }
                    }),
                    catchError((err, obs) => this.errorService.handleRxCatch(err, obs)),
                );
            }),
        );
    }

    private renderHtml(report, continousPreview = true) {
        return new Promise((resolve, reject) => {
            if (!report) {
                reject();
            }
            if (this.parentModalIsClosed) {
                reject();
            }
            this.reportGenerator.renderHtml(report, resolve, continousPreview);
        });
    }

    private getCustomerLocalizationOverride(entity: string) {
        if (this.report.dataSources[entity] && this.report.dataSources[entity][0]) {
            let obs;

            if (entity === 'CustomerInvoice' && this.report.Name === 'Purring') {
                const customerNumber = this.report.dataSources[entity][0].CustomerCustomerNumber;
                obs = this.statisticsService.GetAllUnwrapped(
                    `model=Customer&select=Localization as Localization&filter=CustomerNumber eq ${customerNumber}`,
                );
            } else {
                const customerID = this.report.dataSources[entity][0].CustomerID;
                if (!customerID) {
                    return;
                }
                obs = this.statisticsService.GetAllUnwrapped(
                    `model=Customer&select=Localization as Localization&filter=ID eq ${customerID}`,
                );
            }

            obs.subscribe((res) => {
                if (res[0].Localization) {
                    this.report.localization = res[0].Localization;
                }
            });
        }
    }

    private getLocalizationOverride() {
        // Override localization from CompanySettings?
        if (this.report.dataSources['CompanySettings'] && this.report.dataSources['CompanySettings'][0]) {
            if (this.report.dataSources['CompanySettings'][0].Localization) {
                this.report.localization = this.report.dataSources['CompanySettings'][0].Localization;
            }
        }
        // Override localization from Customer?
        ['CustomerInvoice', 'CustomerOrder', 'CustomerQuote'].forEach((entity) => {
            this.getCustomerLocalizationOverride(entity);
        });
    }

    public onDataFetched(dataSources: any, doneHandler: (msg: string) => void = null) {
        // uncomment this line to get the actual JSON being sent to the report - quite usefull when developing reports..
        // console.log('DATA: ', JSON.stringify(dataSources));

        if (this.target) {
            this.reportGenerator.showReport(
                this.report.templateJson,
                dataSources,
                this.report.parameters,
                this.report.localization,
                this.target,
            );
            if (doneHandler) {
                doneHandler('');
            }
        } else {
            this.reportGenerator.printReport(
                this.report.templateJson,
                dataSources,
                this.report.parameters,
                this.format,
                this.report.localization,
            );
        }
    }

    private addLogoUrl() {
        if (this.report.parameters) {
            const logoKeyParam = <ReportParameter>{};
            logoKeyParam.Name = 'LogoUrl';
            logoKeyParam.value =
                environment.BASE_URL_FILES +
                '/api/public/image/?key=' +
                this.http.authService.getCompanyKey() +
                '&id=logo';
            this.report.parameters.push(logoKeyParam);
        }
    }

    private getMaxRowCount(report: { dataSources: Array<any> }): number {
        let max = 0;
        if (report && report.dataSources) {
            Object.keys(report.dataSources).forEach((key) => {
                let src = report.dataSources[key];
                if (src.Data) {
                    src = src.Data;
                }
                if (src && src.length) {
                    const rowCount = src.length;
                    if (rowCount > max) {
                        max = rowCount;
                    }
                }
            });
        }
        return max;
    }
}

export interface ReportParameter extends ReportDefinitionParameter {
    value: string;
}

export interface Report extends ReportDefinition {
    parameters: ReportParameter[];
    dataSources: ReportDefinitionDataSource[];
    templateJson: string;
    localization: string;
}
