<section role="dialog" class="uni-modal">
    <header>
        <i class="material-icons">info</i>
        For å kjøpe produkter må du ha rollen "Administrator"
    </header>
    <article>
        <section *ngIf="busy" class="modal-spinner">
            <mat-spinner class="c2a"></mat-spinner>
        </section>

        <table class="uni-html-table">
            <thead>
                <tr>
                    <th>Administrator</th>
                    <th>Epost</th>
                    <th>Telefon</th>
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let admin of administrators">
                    <td>{{ admin.DisplayName }}</td>
                    <td>
                        <a href="mailto:{{ admin.Email }}">{{ admin.Email }}</a>
                    </td>
                    <td>{{ admin.PhoneNumber }}</td>
                </tr>
            </tbody>
        </table>
    </article>

    <footer class="center">
        <button class="c2a" (click)="onClose.emit()">Lukk</button>
    </footer>
</section>
