<section class="uni-modal">
    <header>Endringer i administrator rollen</header>

    <article id="missing-products-dialog-body">
        <section class="alert warn">
            <i class="material-icons">info</i>
            <section>Administrator rollen har ikke lenger automatisk tilgang til produkter</section>
        </section>

        <p>Du kan aktivere tilganger til produkter under brukerinnstillinger.</p>
    </article>

    <footer>
        <button class="secondary pull-left" (click)="closeModal()">Lukk</button>
        <button class="secondary" (click)="setDontAskAgainFlag()">Ikke vis dette igjen</button>
        <button class="c2a" (click)="redirectToUserSettingsPage()">Gå til brukerinnstillinger</button>
    </footer>
</section>
