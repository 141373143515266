import {
    Component,
    EventEmitter,
    Input,
    Output,
    ElementRef,
    HostListener,
    QueryList,
    ChangeDetectorRef,
    ChangeDetectionStrategy,
    ContentChildren,
    Optional,
} from '@angular/core';
import { generateId } from '@app/components/common/utils/utils';
import { Subscription } from 'rxjs';

@Component({
    selector: 'listbox-item',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `<ng-content></ng-content>`,
    host: {
        '[id]': 'id',
        '[attr.aria-selected]': 'selected',
        '[class.selected]': 'selected',
        '[class.focus]': 'focused',
    },
})
export class ListboxItem {
    @Input() value: any;

    id = generateId('listbox-item');
    focused: boolean;
    selected: boolean;

    constructor(private elementRef: ElementRef<HTMLElement>) {}

    setFocused(focused: boolean) {
        this.focused = focused;
        if (this.focused) {
            this.elementRef?.nativeElement?.scrollIntoView({ block: 'nearest' });
        }
    }

    setSelected(selected: boolean) {
        this.selected = selected;
    }
}

@Component({
    selector: 'listbox',
    styleUrls: ['./listbox.sass'],
    template: `<ng-content></ng-content>`,
    host: {
        tabindex: '0',
        '[attr.aria-label]': 'label',
    },
})
export class Listbox {
    @ContentChildren(ListboxItem, { descendants: true }) private items: QueryList<ListboxItem>;

    @Input() label: string;

    @Input() value: any;
    @Output() valueChange = new EventEmitter();

    queryListSubscription: Subscription;

    focusIndex: number;
    activeDescendant: string;

    constructor(private cdr: ChangeDetectorRef) {}

    ngAfterViewInit() {
        setTimeout(() => {
            this.updateSelectedState();

            // ViewChildren change
            this.queryListSubscription = this.items.changes.subscribe(() => {
                this.updateSelectedState();
            });
        });
    }

    ngOnChanges(changes) {
        if (changes.value) {
            this.updateSelectedState();
        }
    }

    ngOnDestroy() {
        this.queryListSubscription?.unsubscribe();
    }

    updateSelectedState() {
        const selectedItem = this.getSelectedItem();

        this.focusIndex = 0;
        this.activeDescendant = undefined;

        this.items?.forEach((item, index) => {
            if (item === selectedItem) {
                this.focusIndex = index;
                this.activeDescendant = item.id;
            }

            item.setSelected(item === selectedItem);
            item.setFocused(item === selectedItem);
        });

        this.cdr.markForCheck();
    }

    private getSelectedItem() {
        return this.value && this.items?.find((item) => item.value === this.value);
    }

    @HostListener('keydown', ['$event'])
    onKeydown(event: KeyboardEvent) {
        if (!this.items?.length) return;

        if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
            event.preventDefault();

            let index = (this.focusIndex || 0) + (event.key === 'ArrowUp' ? -1 : 1);

            if (index >= this.items.length - 1) {
                index = 0;
            }

            if (index < 0) {
                index = this.items.length - 1;
            }

            this.focusItem(this.items.get(index));
        }

        if (event.key === 'Enter' && this.focusIndex >= 0) {
            const item = this.items?.get(this.focusIndex);
            this.selectItem(item);
        }
    }

    @HostListener('click', ['$event'])
    onClick(event: MouseEvent) {
        const id = (event.target as HTMLElement)?.id;
        if (id) {
            const item = this.items?.find((item) => item.id === id);
            this.selectItem(item);
        }
    }

    selectItem(item: ListboxItem) {
        if (!item) return;

        this.value = item.value;
        this.valueChange.emit(this.value);
        this.updateSelectedState();
    }

    focusItem(itemToFocus: ListboxItem) {
        if (!itemToFocus) return;

        this.items?.forEach((item, index) => {
            if (item === itemToFocus) {
                this.focusIndex = index;
                this.activeDescendant = item.id;
                item.setFocused(true);
            } else {
                item.setFocused(false);
            }
        });
    }
}
