import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { StatisticsService } from './statisticsService';
import { ErrorService } from './errorService';
import { UserService } from './userService';
import { User, StatusCodeSharing } from '../../unientities';
import { CustomerInvoiceService } from '../sales/customerInvoiceService';
import { CustomerOrderService } from '../sales/customerOrderService';
import { CustomerQuoteService } from '../sales/customerQuoteService';
import { CustomerInvoiceItemService } from '../sales/customerInvoiceItemService';
import { CustomerOrderItemService } from '../sales/customerOrderItemService';
import { RecurringInvoiceService } from '../sales/recurringInvoiceService';
import { PaymentService } from '../accounting/paymentService';
import { PaymentBatchService } from '../accounting/paymentBatchService';
import { SupplierInvoiceService } from '../accounting/supplierInvoiceService';
import { JournalEntryLineService } from '../accounting/journalEntryLineService';
import { ProjectService } from './projectService';
import { ReInvoicingService } from '../accounting/ReInvoicingService';

export interface StatusHistoryItem {
    statusText: string;
    date: Date;
    user: string;
}

@Injectable({ providedIn: 'root' })
export class StatusService {
    private statusDictionary: { [StatusCode: number]: { name: string; entityType: string } };

    constructor(
        private statisticsService: StatisticsService,
        private userService: UserService,
        private errorService: ErrorService,
        private customerInvoiceService: CustomerInvoiceService,
        private customerOrderService: CustomerOrderService,
        private customerQuoteService: CustomerQuoteService,
        private customerInvoiceItemService: CustomerInvoiceItemService,
        private customerOrderItemService: CustomerOrderItemService,
        private recurringInvoiceService: RecurringInvoiceService,
        private paymentService: PaymentService,
        private paymentBatchService: PaymentBatchService,
        private supplierInvoiceService: SupplierInvoiceService,
        private journalEntryLineService: JournalEntryLineService,
        private reinvoicingService: ReInvoicingService,
        private projectService: ProjectService,
    ) {}

    public getStatusText(statusCode: number): string {
        if (this.statusDictionary) {
            const status = this.statusDictionary[statusCode];
            return status ? status.name : '';
        }

        return null;
    }

    public getStatusCodesForEntity(entityType: string): Array<StatusCode> {
        const statusCodes: Array<StatusCode> = [];
        if (this.statusDictionary) {
            Object.keys(this.statusDictionary).forEach((x) => {
                const status = this.statusDictionary[x];
                if (status.entityType === entityType) {
                    statusCodes.push({
                        statusCode: +x,
                        name: status.name,
                        entityType: status.entityType,
                    });
                }
            });
        }
        return statusCodes;
    }

    public loadStatusCache(): Promise<any> {
        return new Promise((resolve, reject) => {
            if (this.statusDictionary) {
                resolve(true);
            } else {
                this.statisticsService
                    .GetAll(
                        'model=Status&select=StatusCode as StatusCode,Description as Description,EntityType as EntityType',
                    )
                    .subscribe(
                        (data) => {
                            if (data.Data) {
                                this.statusDictionary = {};
                                data.Data.forEach((item) => {
                                    let name;
                                    switch (item.EntityType) {
                                        case 'CustomerInvoice':
                                            name = this.customerInvoiceService.getStatusText(item.StatusCode, 0);
                                            break;
                                        case 'CustomerOrder':
                                            name = this.customerOrderService.getStatusText(item.StatusCode);
                                            break;
                                        case 'CustomerQuote':
                                            name = this.customerQuoteService.getStatusText(item.StatusCode);
                                            break;
                                        case 'CustomerInvoiceItem':
                                            name = this.customerInvoiceItemService.getStatusText(item.StatusCode, 0);
                                            break;
                                        case 'CustomerOrderItem':
                                            name = this.customerOrderItemService.getStatusText(item.StatusCode);
                                            break;
                                        case 'RecurringInvoice':
                                            name = this.recurringInvoiceService.getStatusText(item.StatusCode);
                                            break;
                                        case 'Payment':
                                            name = this.paymentService.getStatusText(item.StatusCode);
                                            break;
                                        case 'PaymentBatch':
                                            name = this.paymentBatchService.getStatusText(item.StatusCode, false);
                                            break;
                                        case 'Sharing':
                                            name = this.getSharingStatusText(item.StatusCode);
                                            break;
                                        case 'JournalEntryLine':
                                            name = this.journalEntryLineService.getStatusText(item.StatusCode);
                                            break;
                                        case 'SupplierInvoice':
                                            name = this.supplierInvoiceService.getStatusText(item.StatusCode);
                                            if (!name) return;
                                            break;
                                        case 'ReInvoice':
                                            name = this.reinvoicingService.getStatusText(item.StatusCode);
                                            break;
                                        case 'Project':
                                            name = this.projectService.getStatusText(item.StatusCode);
                                            break;
                                        // TODO: Add when Quote Item status flow is implemented in back-end
                                        // case 'CustomerQuoteItem':
                                        //     name = this.customerQuoteItemService.getStatusText(item.StatusCode);
                                        //     break;
                                    }

                                    if (!name) {
                                        name = item.Description;
                                    }

                                    this.statusDictionary[item.StatusCode] = {
                                        name: name,
                                        entityType: item.EntityType,
                                    };
                                });

                                resolve(true);
                            } else {
                                reject('Could not get statuses from API');
                            }
                        },
                        (err) => this.errorService.handle(err),
                    );
            }
        });
    }

    public getSharingStatusText(statusCode: number): string {
        switch (statusCode) {
            case StatusCodeSharing.Cancelled:
                return 'Avbrutt';
            case StatusCodeSharing.Pending:
                return 'I kø';
            case StatusCodeSharing.Completed:
                return 'Fullført';
            case StatusCodeSharing.Failed:
                return 'Feilet';
            default:
                return 'Behandles';
        }
    }

    public getStatusLogEntries(entityType: string, entityID: number): Observable<StatusHistoryItem[]> {
        return forkJoin([
            this.statisticsService.GetAllUnwrapped(
                // get systemuser also
                `model=User&select=DisplayName as DisplayName,globalidentity as GlobalIdentity, username as UserName, email as Email`,
            ),
            this.getEntityCreationInfo(entityType, entityID),
            this.statisticsService.GetAllUnwrapped(
                `model=StatusLog&filter=EntityType eq '${entityType}' and EntityID eq ${entityID} and ToStatus gt 0` +
                    `&select=StatusLog.CreatedAt as CreatedAt,StatusLog.CreatedBy as CreatedBy,StatusLog.FromStatus as FromStatus,` +
                    `ToStatus as ToStatus,StatusLog.EntityID as StatusLogEntityID,StatusLog.EntityType as StatusLogEntityType`,
            ),
            this.loadStatusCache(),
        ]).pipe(
            map(([users, entityCreationInfo, statusLog, _]) => {
                const items: StatusHistoryItem[] = [];

                if (entityCreationInfo) {
                    const createdAt = entityCreationInfo.CreatedAt;
                    const user = users?.find((u) => u.GlobalIdentity === entityCreationInfo.CreatedBy);
                    items.push({
                        statusText: 'Opprettet',
                        date: createdAt && new Date(createdAt),
                        user: user?.DisplayName || user?.UserName || user?.Email,
                    });
                }

                statusLog?.forEach((item) => {
                    const user = users.find((x) => x.GlobalIdentity === item.CreatedBy);
                    items.push({
                        statusText: this.getStatusText(item.ToStatus),
                        date: item.CreatedAt && new Date(item.CreatedAt),
                        user: user?.DisplayName || user?.UserName || user?.Email,
                    });
                });

                return items;
            }),
        );
    }

    private getEntityCreationInfo(entityType: string, entityID: number) {
        const odata = `model=${entityType}&filter=ID eq ${entityID}&select=CreatedAt as CreatedAt,CreatedBy as CreatedBy`;
        return this.statisticsService.GetAllUnwrapped(odata).pipe(
            catchError((err) => {
                console.error(err);
                return of([]);
            }),
            map((res) => res && res[0]),
        );
    }
}

interface StatusCode {
    statusCode: number;
    name: string;
    entityType: string;
}
