import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LibraryImportsModule } from '@app/library-imports.module';
import { UniIconModule } from '../icon/uni-icon';
import { MonthPickerModule } from '../month-picker/month-picker';
import { DropdownMenuModule } from '../dropdown-menu/dropdown-menu';
import { ListboxModule } from '../listbox/listbox.module';

import { DateRangePicker } from './date-range-picker';
import { DateRangePresetOptions } from './preset-options/date-range-preset-options';
import { DateRangeCalendars } from './calendars/date-range-calendars';
import { DateRangeMonthPicker } from './month-picker/date-range-month-picker';
import { DateRangeInput } from './input/date-range-input';
import { DateRangeDropdownSimple } from './dropdown-simple/date-range-dropdown-simple';
import { DateRangeDropdown } from './dropdown/date-range-dropdown';

@NgModule({
    imports: [CommonModule, UniIconModule, MonthPickerModule, LibraryImportsModule, DropdownMenuModule, ListboxModule],
    declarations: [
        DateRangePicker,
        DateRangeDropdown,
        DateRangeDropdownSimple,
        DateRangeInput,
        DateRangeCalendars,
        DateRangePresetOptions,
        DateRangeMonthPicker,
    ],
    exports: [DateRangePicker],
})
export class DateRangePickerModule {}
