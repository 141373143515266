import { Component, Input, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { Address, Country } from '@uni-entities';
import { UniFieldLayout, FieldType } from '@uni-framework/ui/uniform';
import { IModalOptions, IUniModal } from '@uni-framework/uni-modal/interfaces';
import { CountryService } from '@app/services/common/countryService';
import { ErrorService } from '@app/services/common/errorService';
import { PostalCodeService } from '@app/services/common/postalCodeService';

@Component({
    selector: 'uni-address-modal',
    template: `
        <section role="dialog" class="uni-modal">
            <header>{{ options.header || 'Adresse' }}</header>
            <article>
                <uni-form
                    #form
                    [config]="formConfig$"
                    [fields]="formFields$"
                    [model]="formModel$"
                    (changeEvent)="formChange($event)"
                >
                </uni-form>
            </article>

            <footer>
                <button
                    class="secondary"
                    (click)="close(false)"
                    (keydown.shift.tab)="$event.preventDefault(); form?.focus()"
                >
                    Avbryt
                </button>

                <button class="c2a" (click)="close(true)" (keydown.tab)="$event.preventDefault()">Ok</button>
            </footer>
        </section>
    `,
})
export class UniAddressModal implements IUniModal {
    @Input() options: IModalOptions = {};
    @Output() onClose = new EventEmitter();

    formConfig$ = new BehaviorSubject({ autofocus: true });
    formModel$ = new BehaviorSubject(null);
    formFields$ = new BehaviorSubject([]);

    initialAddress: Address;

    constructor(
        private countryService: CountryService,
        private postalCodeService: PostalCodeService,
        private errorService: ErrorService,
    ) {}

    public ngOnInit() {
        const address = this.options.data || {};
        const fields = this.getFormFields();

        if (address._initValue && fields[0] && !address[fields[0].Property]) {
            address[fields[0].Property] = address._initValue;
        }

        this.initialAddress = address;
        this.formModel$.next(Object.assign({}, address));
        this.formFields$.next(fields);
    }

    ngOnDestroy() {
        this.formConfig$.complete();
        this.formModel$.complete();
        this.formFields$.complete();
    }

    formChange(changes) {
        if (changes['PostalCode'] && changes['PostalCode'].currentValue) {
            const address = this.formModel$.getValue();
            this.postalCodeService.GetAll(`filter=Code eq ${address.PostalCode}&top=1`).subscribe(
                (res) => {
                    address.City = res.length ? res[0].City : '';
                    this.formModel$.next(address);
                },
                (err) => this.errorService.handle(err),
            );
        }
    }

    close(emitValue?: boolean) {
        if (emitValue) {
            // Since multivalue currently depends on memory references we need to
            // map the updated values to the initial object and return that,
            // instead of returning the edited one.
            const address = this.formModel$.getValue();
            Object.keys(address).forEach((key) => {
                this.initialAddress[key] = address[key];
            });

            this.onClose.emit(this.initialAddress);
        } else {
            this.onClose.emit(null);
        }
    }

    private getFormFields(): UniFieldLayout[] {
        const fields = [
            <any>{
                EntityType: 'Address',
                Property: 'AddressLine1',
                Label: 'Adresselinje 1',
                MaxLength: 255,
            },
            <any>{
                EntityType: 'Address',
                Property: 'AddressLine2',
                Label: 'Adresselinje 2',
                MaxLength: 255,
            },
            <any>{
                EntityType: 'Address',
                Property: 'AddressLine3',
                Label: 'Adresselinje 3',
                MaxLength: 255,
            },
            <any>{
                EntityType: 'Address',
                Property: 'PostalCode',
                Label: 'Postnr.',
                MaxLength: 10,
            },
            <any>{
                EntityType: 'Address',
                Property: 'City',
                Label: 'Poststed',
                MaxLength: 100,
            },
            <any>{
                EntityType: 'Address',
                Property: 'Country',
                FieldType: FieldType.AUTOCOMPLETE,
                Label: 'Land',
                Options: {
                    search: (query) => {
                        const filter = query && query.length ? `filter=startswith(Name,'${query}')&top=20` : 'top=20';

                        return this.countryService.GetAll(filter);
                    },
                    events: {
                        select: (model: Address, selectedItem: Country) => {
                            model.Country = selectedItem?.Name || null;
                            model.CountryCode = selectedItem?.CountryCode || null;
                        },
                    },
                    getDefaultData: () => {
                        const model = this.formModel$.value;
                        if (model) {
                            return of([
                                {
                                    Name: model.Address,
                                    CountryCode: model.CountryCode,
                                },
                            ]);
                        }
                    },

                    valueProperty: 'Name',
                    displayProperty: 'Name',
                },
            },
        ];

        return fields;
    }
}
