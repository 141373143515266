import { Injectable } from '@angular/core';
import { UniHttp } from '../../../framework/core/http/http';
import { BehaviorSubject, Observable } from 'rxjs';
import {
    BillingData,
    ElsaCompanyLicense,
    ElsaCustomer,
    ElsaUserLicense,
    ElsaCustomerProspect,
    SoftRigAgencyRequest,
} from '@app/models';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

export interface CustomerContactInfo {
    ContactPerson?: string;
    ContactPhone?: string;
    ContactEmail?: string;
}

@Injectable({ providedIn: 'root' })
export class ElsaCustomersService {
    ELSA_SERVER_URL = environment.ELSA_SERVER_URL;
    customer$ = new BehaviorSubject<ElsaCustomer>(null);

    constructor(
        private uniHttp: UniHttp,
        private http: HttpClient,
    ) {}

    getByContractID(contractID: number, expand?: string): Observable<ElsaCustomer> {
        let endpoint = `/api/elsa/contracts/${contractID}/customers`;
        if (expand) {
            endpoint += '?expand=' + expand;
        }
        return this.uniHttp
            .asGET()
            .usingEmptyDomain()
            .withEndPoint(endpoint)
            .send()
            .pipe(map((res) => res.body));
    }

    loadByContractID(contractID: number) {
        let endpoint = `/api/elsa/contracts/${contractID}/customers?expand=contracts,managers,customerroamingusers`;

        return this.uniHttp
            .asGET()
            .usingEmptyDomain()
            .withEndPoint(endpoint)
            .send()
            .pipe(map((res) => res.body))
            .pipe(map((customer) => this.customer$.next(customer)));
    }

    getAll(expand?: string): Observable<ElsaCustomer[]> {
        let endpoint = '/api/elsa/customers';
        if (expand) {
            endpoint += '?expand=' + expand;
        }

        return this.uniHttp
            .asGET()
            .usingEmptyDomain()
            .withEndPoint(endpoint)
            .send()
            .pipe(map((res) => res.body));
    }

    getAllManaged(userIdentity: string): Observable<ElsaCustomer[]> {
        let endpoint = '/api/customers?';
        const expand = `$expand=contracts($select=contracttype,id,name)`;
        const select = '&$select=name,id,customertype';
        const filter = `&$filter=managers/any(m: m/user/identity eq ${userIdentity})`;
        endpoint += expand + select + filter;
        return this.uniHttp
            .asGET()
            .usingElsaDomain()
            .withEndPoint(endpoint)
            .send()
            .pipe(map((res) => res.body));
    }

    getAllCompaniesByCustomerID(
        id: number,
        filter: string,
        expandContract = false,
        orderby?: string,
        select?: string,
    ): Observable<ElsaCompanyLicense[]> {
        filter += `${filter ? ' and ' : ''}contract/customerid eq ${id}`;
        select = `$select=${select ? select : 'id,companyname,companykey,startdate,enddate,statuscode,orgnumber'}`;

        const expand = expandContract ? '$expand=contract($select=id,contracttype,name)' : '';
        return this.http.get<ElsaCompanyLicense[]>(
            this.ELSA_SERVER_URL +
                '/api/' +
                `companylicenses?${filter ? `$filter=${encodeURIComponent(filter)}&` : ''}${expand}&${select}&$orderby=${orderby ? `${orderby}` : 'id desc'}`,
        );
    }

    getCustomerBillingEstimate(
        customerID: number,
        year: number,
        month: number,
        orderNumber: number,
        orderDate: string,
        reference: string,
        settlement: boolean,
    ): Observable<BillingData[]> {
        var endpoint =
            `/api/billing/customer/${customerID}` +
            `?year=${year}` +
            `&month=${month}` +
            `&tags=true` +
            `&settlement=${settlement}` +
            `&reference=${reference}`;
        if (orderNumber != null && orderDate != null) {
            endpoint = endpoint + `&orderNumber=${orderNumber}&orderDate=${orderDate}`;
        }

        return this.http.get<BillingData[]>(this.ELSA_SERVER_URL + endpoint);
    }

    put(customer: ElsaCustomer) {
        return this.uniHttp
            .asPUT()
            .usingElsaDomain()
            .withEndPoint(`/api/customers/${customer.ID}`)
            .withBody(customer)
            .send();
    }

    addAdmin(customerID: number, email: string) {
        return this.uniHttp
            .asPOST()
            .usingElsaDomain()
            .withEndPoint(`/api/customers/${customerID}/customer-access`)
            .withBody(email)
            .send()
            .pipe(map((res) => res.body));
    }

    removeAdmin(customerID: number, id: number) {
        return this.uniHttp
            .asDELETE()
            .usingElsaDomain()
            .withEndPoint(`/api/customers/${customerID}/customer-access/${id}`)
            .send()
            .pipe(map((res) => res.body));
    }

    addRoamingUser(customerID: number, email: string) {
        return this.uniHttp
            .asPOST()
            .usingElsaDomain()
            .withEndPoint(`/api/customers/${customerID}/roaming-user`)
            .withBody(email)
            .send()
            .pipe(map((res) => res.body));
    }

    removeRoamingUser(customerID: number, id: number) {
        return this.uniHttp
            .asDELETE()
            .usingElsaDomain()
            .withEndPoint(`/api/customers/${customerID}/roaming-user/${id}`)
            .send()
            .pipe(map((res) => res.body));
    }

    addSupportUserToCompany(companyID: number, email: string) {
        const endpoint = `/api/companylicenses/${companyID}/grant-access-to-company`;
        return this.uniHttp.asPOST().usingElsaDomain().withEndPoint(endpoint).withBody(email).send();
    }

    checkSupportUserExists(search: string) {
        return this.uniHttp
            .asGET()
            .usingElsaDomain()
            .withEndPoint(`/api/supportwhitelist/isSupportUser?search=${encodeURIComponent(search)}`)
            .send()
            .pipe(map((res) => res.body));
    }

    getCustomerProspect(prospectID: number, customerID: number, select?: string) {
        let endpoint = `/api/customerprospects/${prospectID}/customer/${customerID}`;
        if (select) {
            endpoint += `?$select=${select}`;
        }
        return this.uniHttp
            .asGET()
            .usingElsaDomain()
            .withEndPoint(endpoint)
            .send()
            .pipe(map((res) => res.body));
    }

    editCustomerContactInfo(customerID: number, customerContactInfo: CustomerContactInfo) {
        return this.uniHttp
            .asPUT()
            .usingEmptyDomain()
            .withEndPoint(`/api/elsa/customers/${customerID}`)
            .withBody(customerContactInfo)
            .send();
    }

    getAllDistinctUsersOnCustomer(customerID: number, top: number, skip: number, filter?: string) {
        const select = `$select=id,useridentity,username,email`;
        const endpoint = `/api/customers/${customerID}/userlicenses?${select}${filter ? `&$filter=${filter}` : ''}&$top=${top}&$skip=${skip}`;

        return this.uniHttp
            .asGET()
            .usingElsaDomain()
            .withEndPoint(endpoint)
            .send()
            .pipe(map((res) => res.body));
    }

    getAllMatchingUserLicenses(customerID: number, userIdentity: string, top: number, skip: number, filter?: string) {
        filter += `${filter ? ' and ' : ''}companylicense/contract/customerid eq ${customerID} and useridentity eq ${userIdentity}`;
        const expand = `$expand=companylicense($expand=contract($select=name,id);$select=companyname,id)`;
        const select = `$select=id,useridentity,username,email,userlicensetype,statuscode`;
        const endpoint = `/api/userlicenses?$filter=${filter}&${expand}&${select}&$top=${top}&$skip=${skip}`;
        return this.uniHttp
            .asGET()
            .usingElsaDomain()
            .withEndPoint(endpoint)
            .send()
            .pipe(map((res) => res.body));
    }

    deactivateUserOnCustomer(customerID: number, userIdentity: string) {
        return this.uniHttp
            .asPUT()
            .usingEmptyDomain()
            .withEndPoint(`/api/elsa/customers/${customerID}/deactivate-user`)
            .withBody(userIdentity)
            .send();
    }

    deactivateUserLicense(userID: number) {
        return this.uniHttp.asDELETE().usingElsaDomain().withEndPoint(`/api/userlicenses/${userID}`).send();
    }

    getUserLicenseOnCompany(userIdentity: string): Observable<ElsaUserLicense> {
        return this.uniHttp
            .asGET()
            .usingEmptyDomain()
            .withEndPoint(`/api/elsa/userlicenses/${userIdentity}`)
            .send()
            .pipe(map((res) => res.body));
    }

    getUserLicensesOnCompany(): Observable<ElsaUserLicense[]> {
        return this.uniHttp
            .asGET()
            .usingEmptyDomain()
            .withEndPoint('/api/elsa/userlicenses')
            .send()
            .pipe(map((res) => res.body));
    }

    getCompanyProspects(): Observable<ElsaCustomerProspect[]> {
        const filter = `$filter=statuscode eq 'Invited' or statuscode eq 'Cancelled'`;
        const expand = `$expand=details`;
        return this.uniHttp
            .asGET()
            .usingElsaDomain()
            .withEndPoint(`/api/customerprospects/company-prospects?${filter}&${expand}`)
            .send()
            .pipe(map((res) => res.body));
    }

    getSoftrigAgencyProspects(): Observable<SoftRigAgencyRequest[]> {
        return this.uniHttp
            .asGET()
            .usingElsaDomain()
            .withEndPoint(`/api/customerprospects/softrig-agency-prospects`)
            .send()
            .pipe(map((res) => res.body));
    }

    sendAgencyInvite(body: ElsaCustomerProspect) {
        return this.uniHttp
            .asPOST()
            .usingElsaDomain()
            .withEndPoint(`/api/customerprospects/send-agency-invite`)
            .withBody(body)
            .send()
            .pipe(map((res) => res.body));
    }

    cancelAgencyInvite(prospectID: number) {
        return this.uniHttp
            .asPUT()
            .usingElsaDomain()
            .withEndPoint(`/api/customerprospects/cancel-agency-invite/${prospectID}`)
            .send();
    }

    cancelSoftrigAgencyInvite(agencyCompanyKey: string) {
        return this.uniHttp
            .asDELETE()
            .usingElsaDomain()
            .withEndPoint(`/api/customerprospects/cancel-softrig-agency-invite/${agencyCompanyKey}`)
            .send();
    }
}
