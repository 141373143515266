import { Component, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { NewAccountModalV2 } from '@app/components/common/modals/new-account-modal-v2/new-account-modal-v2';
import { IUniModal, UniModalService } from '@uni-framework/uni-modal';
import { get } from 'lodash-es';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccountSearchDropdown } from './account-search-dropdown/account-search-dropdown';
import { AccountSearchStore } from './account-search.store';

@Component({
    selector: 'table-account-search',
    template: `
        <section class="input-with-button" (clickOutside)="onClickOutside()">
            <input type="text" #inputElement [formControl]="inputControl" (keydown)="onKeydown($event)" />

            <button
                tabindex="-1"
                type="button"
                (click)="$event.stopPropagation(); store.toggleOpen(); inputElement.focus()"
            >
                <i *ngIf="!(store.loading$ | async)" class="material-icons">search</i>
                <mat-spinner *ngIf="store.loading$ | async" diameter="18"></mat-spinner>
            </button>
        </section>

        <account-search-dropdown
            [inputElement]="inputElement"
            (accountClick)="onAccountSelected($event)"
            (createAccount)="createAccount()"
            (keyDown)="onKeydown($event)"
        >
        </account-search-dropdown>
    `,
    providers: [AccountSearchStore],
})
export class TableAccountSearch {
    @ViewChild(AccountSearchDropdown) dropdown: AccountSearchDropdown;
    @ViewChild('inputElement') inputElement: ElementRef;

    createAccountModalRef: IUniModal;
    account;

    // Set by the TableEditor component
    inputControl: UntypedFormControl;
    column;

    getDisplayValue: (account) => string;

    constructor(
        public store: AccountSearchStore,
        private modalService: UniModalService,
    ) {}

    ngOnInit() {
        this.store.init(this.inputControl);

        this.getDisplayValue = (account) => {
            const property = this.column.get('field');
            const template = this.column.get('template');

            const fakeRow = { [property]: account };

            if (template) {
                return template(fakeRow);
            } else {
                return get(fakeRow, this.column.get('displayField'));
            }
        };
    }

    onAccountSelected(account) {
        this.account = account;
        this.inputControl.setValue(this.getDisplayValue(account), { emitEvent: false });
        this.inputElement.nativeElement?.focus();

        this.store.open$.next(false);
    }

    onClickOutside() {
        this.store.setFocusIndex(-1);
    }

    getValue(): any | Observable<any> {
        if (this.createAccountModalRef) {
            return this.createAccountModalRef.onClose;
        }

        if (this.store.loading$.value && this.inputControl.value) {
            return this.store.lookupAndSelectBestMatch(this.inputControl.value);
        }

        if (this.account && !this.account.Visible) {
            return this.store
                .onHiddenAccountSelected(this.account)
                .pipe(map((updatedAccount) => updatedAccount || this.account));
        }

        return this.account;
    }

    createAccount() {
        this.createAccountModalRef = this.modalService.open(NewAccountModalV2, {
            data: {
                entityType: 'account',
                hideEntityTypeSelector: true,
                text: this.inputControl.value || '',
            },
        });

        this.createAccountModalRef.onClose.subscribe({
            next: (account) => this.onAccountSelected(account),
            complete: () => (this.createAccountModalRef = undefined),
        });
    }

    onKeydown(event: KeyboardEvent) {
        switch (event.key) {
            case 'ArrowDown':
                event.preventDefault();
                if (event.altKey && !this.store.open$.value) {
                    event.stopPropagation();
                    this.store.toggleOpen();
                    return;
                }

                this.dropdown?.focusNext();
                break;
            case 'ArrowUp':
                event.preventDefault();
                this.dropdown?.focusPrevious();
                break;
            case ' ': // space
                event.preventDefault();
                this.store.toggleOpen();
                break;
            case 'Enter':
            case 'Tab':
                const index = this.store.focusIndex$.value;
                const accounts = this.store.accounts$.value;
                if (index >= 0 && accounts[index]) {
                    this.onAccountSelected(accounts[index]);
                } else if (this.inputControl.dirty && !this.inputControl.value) {
                    this.onAccountSelected(null);
                }
                break;
        }
    }

    private close() {
        this.store.open$.next(false);
    }
}
