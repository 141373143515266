import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UniHttp } from '../../../framework/core/http/http';
import {
    CustomerInvoice,
    StatusCodeCustomerInvoice,
    LocalDate,
    InvoicePaymentData,
    StatusCodeCustomerInvoiceReminder,
    JournalEntry,
} from '../../unientities';
import { ToastService, ToastType } from '../../../framework/uniToast/toastService';
import { ITickerActionOverride } from '../../services/common/uniTickerService';
import { UniModalService } from '../../../framework/uni-modal/modalService';
import { TofEmailModal } from '@uni-framework/uni-modal/modals/tof-email-modal/tof-email-modal';
import { UniRegisterPaymentModal } from '../../../framework/uni-modal/modals/registerPaymentModal';
import { BizHttp, RequestMethod } from '@uni-framework/core/http';
import { forkJoin, Observable, of } from 'rxjs';
import { ErrorService } from '../common/errorService';
import { ConfirmActions } from '@uni-framework/uni-modal/interfaces';
import { ReportTypeEnum } from '@app/models/reportTypeEnum';
import { InvoiceTypes } from '@app/models/sales/invoiceTypes';
import { StatisticsService } from '../common/statisticsService';
import { FileService } from '../common/fileService';
import { rigDate } from '@app/components/common/utils/rig-date';
import { catchError, map, publishReplay, refCount } from 'rxjs/operators';
import { cloneDeep } from 'lodash-es';

export enum ExternalStatusCode {
    Registered = 42601,
    PartlyCompleted = 42602,
    Completed = 42603,
    Reminded = 42604,
    Objection = 42605,
}

@Injectable({ providedIn: 'root' })
export class CustomerInvoiceService extends BizHttp<CustomerInvoice> {
    // TODO: To be retrieved from database schema shared.Status instead?
    public statusTypes: Array<any> = [
        { Code: StatusCodeCustomerInvoice.Draft, Text: 'Kladd' },
        { Code: StatusCodeCustomerInvoice.Invoiced, Text: 'Fakturert' },
        // { Code: StatusCodeCustomerInvoice.Reminded, Text: 'Purret'}, // TODO: Add when available from backend
        { Code: StatusCodeCustomerInvoice.PartlyPaid, Text: 'Delbetalt' },
        { Code: StatusCodeCustomerInvoice.Sold, Text: 'Solgt' },
        { Code: StatusCodeCustomerInvoice.Paid, Text: 'Betalt' },
        { Code: StatusCodeCustomerInvoice.Credited, Text: 'Kreditert' },
        { Code: StatusCodeCustomerInvoice.PartlyCredited, Text: 'Delkreditert' },
    ];

    public statusTypesCredit: Array<any> = [
        { Code: StatusCodeCustomerInvoice.Draft, Text: 'Kladd(Kreditnota)' },
        { Code: StatusCodeCustomerInvoice.Invoiced, Text: 'Fakturert(Kreditnota)' },
        { Code: StatusCodeCustomerInvoice.PartlyPaid, Text: 'Delbetalt(Kreditnota)' },
        { Code: StatusCodeCustomerInvoice.Paid, Text: 'Betalt(Kreditnota)' },
        { Code: StatusCodeCustomerInvoice.Credited, Text: 'Kreditert(Kreditnota)' },
        { Code: StatusCodeCustomerInvoice.PartlyCredited, Text: 'Delkreditert(Kreditnota)' },
    ];

    public externalStatusTypes: Array<any> = [
        { Code: ExternalStatusCode.Registered, Text: 'Registrert' },
        { Code: ExternalStatusCode.PartlyCompleted, Text: 'Del-fullført' },
        { Code: ExternalStatusCode.Completed, Text: 'Fullført' },
        { Code: ExternalStatusCode.Reminded, Text: 'Betalingsoppfølging' },
        { Code: ExternalStatusCode.Objection, Text: 'Kundemotsigelse' },
    ];

    public actionOverrides: Array<ITickerActionOverride> = [
        {
            Code: 'invoice_registerpayment',
            ExecuteActionHandler: (selectedRows) => this.onRegisterPayment(selectedRows),
            CheckActionIsDisabled: (selectedRow) => this.onCheckRegisterPaymentDisabled(selectedRow),
        },
        {
            Code: 'invoice_createcreditnote',
            CheckActionIsDisabled: (selectedRow) => this.onCheckCreateCreditNoteDisabled(selectedRow),
            ExecuteActionHandler: (selectedRows) => this.onCreateCreditNote(selectedRows),
        },
        {
            Code: 'invoice_creditcreditnote',
            CheckActionIsDisabled: (selectedRow) => this.onCheckCreditCreditNoteDisabled(selectedRow),
        },
        {
            Code: 'invoice_sendemail',
            ExecuteActionHandler: (selectedRows) => this.onSendEmail(selectedRows),
        },
        {
            Code: 'invoice_delete',
            CheckActionIsDisabled: (selectedRow) =>
                selectedRow.CustomerInvoiceStatusCode !== StatusCodeCustomerInvoice.Draft,
            ExecuteActionHandler: (selectedRows) => this.deleteInvoices(selectedRows),
        },
        {
            Code: 'invoice_batchinvoice',
            ExecuteActionHandler: (selectedRows) => this.onBatchInvoice(selectedRows),
        },
        {
            Code: 'mass_invoices',
            ExecuteActionHandler: () => this.showMassInvoices(),
        },
    ];

    constructor(
        http: UniHttp,
        private errorService: ErrorService,
        private router: Router,
        private toastService: ToastService,
        private modalService: UniModalService,
        private fileService: FileService,
        private statisticsService: StatisticsService,
    ) {
        super(http);
        this.relativeURL = CustomerInvoice.RelativeUrl;
        this.entityType = CustomerInvoice.EntityType;
    }

    public showMassInvoices(): Promise<any> {
        return this.router.navigateByUrl('sales/invoices?show=massinvoice');
    }

    public onCheckRegisterPaymentDisabled(selectedRow: any): boolean {
        const approvedStatusCodesForAction = [
            StatusCodeCustomerInvoice.Invoiced,
            StatusCodeCustomerInvoice.PartlyPaid,
            StatusCodeCustomerInvoice.Credited,
            StatusCodeCustomerInvoice.PartlyCredited,
        ];

        if (
            selectedRow?.CustomerInvoiceInvoiceType !== InvoiceTypes.CreditNote &&
            approvedStatusCodesForAction.includes(selectedRow?.CustomerInvoiceStatusCode)
        ) {
            return false;
        }
        return true;
    }

    openOrderLines(invoice: CustomerInvoice) {
        return this.http
            .asPUT()
            .usingBusinessDomain()
            .withEndPoint('invoices?action=reset-orderitems-from-creditnote')
            .withBody(invoice)
            .send()
            .pipe(map((response) => response.body));
    }

    public onRegisterPayment(selectedRows: Array<any>): Promise<void> {
        const row = selectedRows[0];

        if (!row) {
            return Promise.resolve();
        }

        return new Promise((resolve, reject) => {
            // get invoice from API - the data from the ticker may only be partial
            this.Get(row.ID, ['CurrencyCode', 'CustomerInvoiceReminders']).subscribe((invoice) => {
                const title =
                    `Register betaling: Faktura ${invoice.InvoiceNumber || ''} - ` + `${invoice.CustomerName || ''}`;

                const reminders = invoice.CustomerInvoiceReminders || [];
                let amount = invoice.RestAmount || 0;
                let amountCurrency = invoice.RestAmountCurrency || 0;

                reminders.forEach((reminder) => {
                    if (reminder.StatusCode < StatusCodeCustomerInvoiceReminder.Paid) {
                        amount += reminder.RestAmount;
                        amountCurrency += reminder.RestAmountCurrency;
                    }
                });

                const paymentData = <InvoicePaymentData>{
                    Amount: amount,
                    AmountCurrency: amountCurrency,
                    BankChargeAmount: 0,
                    CurrencyCodeID: invoice.CurrencyCodeID,
                    CurrencyExchangeRate: 0,
                    PaymentDate: new LocalDate(new Date()),
                    AgioAccountID: null,
                    BankChargeAccountID: 0,
                    AgioAmount: 0,
                    PaymentID: null,
                    DimensionsID: null,
                };

                const modal = this.modalService.open(UniRegisterPaymentModal, {
                    data: paymentData,
                    header: title,
                    modalConfig: {
                        currencyExchangeRate: invoice.CurrencyExchangeRate,
                        entityName: 'CustomerInvoice',
                        currencyCode: invoice.CurrencyCode.Code,
                    },
                });

                modal.onClose.subscribe((payment) => {
                    if (!payment) {
                        resolve();
                        return;
                    }

                    this.ActionWithBody(invoice.ID, payment, 'payInvoice').subscribe(
                        (res) => {
                            this.toastService.addToast(
                                'Faktura er betalt. Bilagsnummer: ' + res.JournalEntryNumber,
                                ToastType.good,
                                5,
                            );
                            resolve();
                        },
                        (err) => {
                            this.errorService.handle(err);
                            resolve();
                        },
                    );
                });
            });
        });
    }

    getPaymentsForInvoice(id: number) {
        return this.http
            .asGET()
            .usingBusinessDomain()
            .withEndPoint(`invoices/${id}?action=get-payments`)
            .send()
            .pipe(map((res) => res.body));
    }

    getInvoice(id: number): Observable<CustomerInvoice> {
        return this.http
            .asGET()
            .usingBusinessDomain()
            .withEndPoint(`invoices/${id}`)
            .send()
            .pipe(map((res) => res.body));
    }

    public getFileList(id: number) {
        return this.fileService.getFilesOnEntity('CustomerInvoice', id);
    }

    public payInvoiceWithNumberSeriesID(id: number, payment: InvoicePaymentData, numberSeriesID: number) {
        return this.ActionWithBody(
            id,
            payment,
            'pay-invoice-with-number-series-id',
            RequestMethod.Put,
            numberSeriesID != null ? 'numberseriesid=' + numberSeriesID : null,
        );
    }

    public payInvoices(
        data: { id: number; payment: InvoicePaymentData; numberSeriesID: number }[],
    ): Observable<JournalEntry[]> {
        if (!data.length) {
            return of([]);
        }

        return forkJoin(data.map((d) => this.payInvoiceWithNumberSeriesID(d.id, d.payment, d.numberSeriesID)));
    }

    public removeAccrual(ID: number) {
        return this.PutAction(ID, 'remove-accrual');
    }

    public onCheckCreateCreditNoteDisabled(selectedRow: any): boolean {
        const rowModel = selectedRow;

        if (rowModel.CustomerInvoiceInvoiceType === 1) {
            return true;
        }

        if (
            rowModel.CustomerInvoiceStatusCode === StatusCodeCustomerInvoice.Invoiced ||
            rowModel.CustomerInvoiceStatusCode === StatusCodeCustomerInvoice.PartlyPaid ||
            rowModel.CustomerInvoiceStatusCode === StatusCodeCustomerInvoice.Paid
        ) {
            return false;
        } else {
            return true;
        }
    }

    public onCreateCreditNote(selectedRows: Array<any>): Promise<void> {
        const rowModel = selectedRows[0];

        return new Promise((resolve, reject) => {
            this.createCreditNoteFromInvoice(rowModel.ID).subscribe(
                (data) => {
                    resolve();

                    setTimeout(() => {
                        this.router.navigateByUrl('/sales/invoices/' + data.ID);
                    });
                },
                (err) => {
                    this.errorService.handle(err);
                    reject(err);
                },
            );
        });
    }

    public onCheckCreditCreditNoteDisabled(selectedRow: any): boolean {
        const rowModel = selectedRow;

        if (rowModel.CustomerInvoiceTaxInclusiveAmount === 0 || rowModel.CustomerInvoiceInvoiceType === 0) {
            // Must have saved at minimum 1 item related to the invoice
            return true;
        }

        return !rowModel._links.transitions.invoice;
    }

    private deleteInvoices(selectedRows: Array<any>): Promise<void> {
        const invoice = selectedRows[0];
        return new Promise((resolve, reject) => {
            this.modalService
                .confirm({
                    header: 'Slette faktura',
                    message: 'Vil du slette denne fakturaen?',
                    buttonLabels: {
                        accept: 'Slett',
                        cancel: 'Avbryt',
                    },
                })
                .onClose.subscribe((answer) => {
                    if (answer === ConfirmActions.ACCEPT) {
                        this.Remove(invoice.ID, null).subscribe(
                            () => resolve(),
                            (err) => {
                                this.errorService.handle(err);
                                resolve();
                            },
                        );
                    } else {
                        resolve();
                    }
                });
        });
    }

    onSendEmail(selectedRows: Array<any>): Promise<any> {
        const invoice = selectedRows[0];
        this.modalService.open(TofEmailModal, {
            data: {
                entity: invoice,
                entityType: 'CustomerInvoice',
                reportType: ReportTypeEnum.INVOICE,
            },
        });

        return Promise.resolve();
    }

    public onBatchInvoice(selectedRows: Array<any>): Promise<any> {
        const invoice = selectedRows[0];
        return this.router.navigateByUrl(`sales/invoices/${invoice.ID};batchwizard=true`);
    }

    public createInvoiceJournalEntryDraftAction(id: number): Observable<any> {
        super.invalidateCache();
        return this.http
            .asPUT()
            .usingBusinessDomain()
            .withEndPoint(this.relativeURL + `/${id}?action=create-invoice-journalentrydraft`)
            .send()
            .pipe(map((response) => response.body));
    }

    public setPrintStatus(invoiceId: number, printStatus: string): Observable<any> {
        return super.PutAction(
            invoiceId,
            'set-customer-invoice-printstatus',
            'ID=' + invoiceId + '&printStatus=' + printStatus,
        );
    }

    public matchInvoicesManual(customerInvoiceIDs: number[], paymentID: number): Observable<any> {
        return this.http
            .asPUT()
            .usingBusinessDomain()
            .withBody(customerInvoiceIDs)
            .withEndPoint(this.relativeURL + '?action=match-invoices-manual&paymentID=' + paymentID)
            .send()
            .pipe(map((response) => response.body));
    }

    public createCreditNoteFromInvoice(currentInvoiceID: number): Observable<any> {
        return super.PutAction(currentInvoiceID, 'create-credit-draft-invoice');
    }

    public getStatusText(statusCode: number, invoiceType: number = 0): string {
        const dict = invoiceType === 0 ? this.statusTypes : this.statusTypesCredit;
        const statusType = dict.find((x) => x.Code === statusCode);
        return statusType ? statusType.Text : '';
    }

    public getExternalStatusText(externalStatusCode: number): string {
        const statusType = this.externalStatusTypes.find((x) => x.Code === externalStatusCode);
        return statusType ? statusType.Text : externalStatusCode;
    }

    public requestInvoiceSalesOffers(invoiceId: number): Observable<CustomerInvoice> {
        return super.Action(invoiceId, 'request-offer', null, RequestMethod.Put);
    }

    getInvoicedWidgetData() {
        // Invoiced sums
        const fromDate = rigDate().subtract(11, 'month').startOf('month').format('YYYYMMDD');
        const toDate = rigDate().endOf('month').format('YYYYMMDD');

        const invoicedFilter = [
            `InvoiceType eq 0 and StatusCode ne '42001'`,
            `add(TaxInclusiveAmount, CreditedAmount) ne '0'`,
            `InvoiceDate ge '${fromDate}'`,
            `InvoiceDate le '${toDate}'`,
        ].join(' and ');

        const invoicedQuery =
            `model=CustomerInvoice&filter=${invoicedFilter}&range=Periode` +
            `&select=month(invoicedate) as Periode,sum(TaxInclusiveAmount) as TaxInclusiveAmount,sum(RestAmount) as RestAmount`;

        // Overdue sum
        const overdueFilter = [
            `CustomerInvoice.RestAmount ne '0'`,
            `CustomerInvoice.PaymentDueDate lt '${rigDate().format('YYYYMMDD')}'`,
            `add(TaxInclusiveAmount, CreditedAmount) ne 0`,
            `(CustomerInvoice.StatusCode eq 42002 or CustomerInvoice.StatusCode eq 42003)`,
        ].join(' and ');

        const overdueQuery = `model=CustomerInvoice&filter=${overdueFilter}&select=sum(CustomerInvoice.RestAmount) as Sum`;

        const cacheKey = this.hashFnv32a('invoiced-widget-data');
        let request = this.getFromCache(cacheKey);
        if (!request) {
            request = forkJoin([
                this.statisticsService.GetAllUnwrapped(invoicedQuery),
                this.statisticsService.GetAllUnwrapped(overdueQuery),
            ]).pipe(publishReplay(1), refCount());

            this.storeInCache(cacheKey, request);
        }

        return request.pipe(map((res) => cloneDeep(res)));
    }

    getUnpaidPerCustomerWidgetData() {
        /*
            The reason we're expanding Customer.Info is because the CustomerName field on an
            invoice doesn't update when changing a customer's name. This could potentially cause
            incorrect sums if we selected CustomerName instead of using the expanded info object.
        */
        const customerSumsQuery =
            `model=CustomerInvoice` +
            `&select=Info.Name as Name,sum(RestAmount) as RestAmount` +
            `&filter=StatusCode gt 42001` +
            `&expand=Customer.Info` +
            `&range=CustomerID` +
            `&orderby=sum(RestAmount) desc` +
            `&top=4&distinct=false`;

        const unpaidSumQuery = 'model=CustomerInvoice&select=sum(RestAmount) as RestAmount&filter=StatusCode gt 42001';

        const cacheKey = this.hashFnv32a('unpaid-per-customer-widget-data');
        let request = this.getFromCache(cacheKey);
        if (!request) {
            request = forkJoin([
                this.statisticsService.GetAllUnwrapped(customerSumsQuery),
                this.statisticsService.GetAllUnwrapped(unpaidSumQuery),
            ]).pipe(publishReplay(1), refCount());

            this.storeInCache(cacheKey, request);
        }

        return request.pipe(map((res) => cloneDeep(res)));
    }

    getUnpaidWidgetOverviewData() {
        const today = rigDate().format('YYYY-MM-DD');
        const daysAgo = (days: number) => rigDate().subtract(days, 'd').format('YYYY-MM-DD');
        const dueDateNullFilter = '1999-01-01';

        const select = [
            `sum(casewhen(DueDate ge '${today}' and FinancialDate le '${today}' or (isnull(DueDate, '${dueDateNullFilter}') eq '${dueDateNullFilter}' and FinancialDate ge '${today}'),RestAmount,0) ) as notOverdue`,
            `sum(casewhen(DueDate ge '${daysAgo(30)}' and DueDate lt '${today}' or isnull(DueDate, '${dueDateNullFilter}') eq '${dueDateNullFilter}' and FinancialDate ge '${daysAgo(30)}' and FinancialDate lt '${today}',RestAmount,0) ) as thirtyDays`,
            `sum(casewhen(DueDate ge '${daysAgo(60)}' and DueDate le '${daysAgo(31)}' or isnull(DueDate, '${dueDateNullFilter}') eq '${dueDateNullFilter}' and FinancialDate ge '${daysAgo(60)}' and FinancialDate le '${daysAgo(31)}',RestAmount,0) ) as sixtyDays`,
            `sum(casewhen(DueDate lt '${daysAgo(60)}' or isnull(DueDate, '${dueDateNullFilter}') eq '${dueDateNullFilter}' and FinancialDate lt '${daysAgo(60)}',RestAmount,0) ) as overSixtyDays`,
        ].join(',');

        const filter = 'isnull(SubAccount.CustomerID,0) gt 0';
        const query = `model=JournalEntryLine&select=${select}&filter=${filter}&expand=SubAccount`;

        const cacheKey = this.hashFnv32a(query);
        let request = this.getFromCache(cacheKey);
        if (!request) {
            request = this.statisticsService.GetAllUnwrapped(query).pipe(
                map((res) => res && res[0]),
                catchError((err) => {
                    console.error(err);
                    return of({
                        notOverdue: 0,
                        thirtyDays: 0,
                        sixtyDays: 0,
                        overSixtyDays: 0,
                    });
                }),
                publishReplay(1),
                refCount(),
            );

            this.storeInCache(cacheKey, request);
        }

        return request.pipe(map((res) => cloneDeep(res)));
    }

    getUnpaidWidgetOverdueInvoices() {
        const today = rigDate().format('YYYYMMDD');
        const query =
            `model=CustomerInvoice` +
            `&select=ID as ID,PaymentDueDate as PaymentDueDate,CustomerName as CustomerName,InvoiceNumber as InvoiceNumber,RestAmount as RestAmount,StatusCode as StatusCode` +
            `&filter=PaymentDueDate le ${today} and RestAmount ne 0 and StatusCode ne 42001` +
            `&expand=Customer&orderby=PaymentDueDate&top=25`;

        const cacheKey = this.hashFnv32a(query);
        let request = this.getFromCache(cacheKey);
        if (!request) {
            request = this.statisticsService.GetAllUnwrapped(query).pipe(
                catchError((err) => {
                    console.error(err);
                    return of([]);
                }),
                publishReplay(1),
                refCount(),
            );

            this.storeInCache(cacheKey, request);
        }

        return request.pipe(map((res) => cloneDeep(res)));
    }

    getTopTenCustomersWidgetData(year: number) {
        /*
            Even though the model is Customer we use the invoice service cache
            because the relevant invalidation happens on on invoice changes.
        */
        const selects = [
            `sum(casewhen(CustomerInvoice.InvoiceDate ge ${year}0101 and CustomerInvoice.InvoiceDate lt ${year + 1}0101,CustomerInvoice.TaxExclusiveAmount,0)) as SumThisYearWithoutMva`,
            `sum(casewhen(CustomerInvoice.InvoiceDate ge ${year - 1}0101 and CustomerInvoice.InvoiceDate lt ${year}0101,CustomerInvoice.TaxExclusiveAmount,0)) as SumLastYearWithoutMva`,
            `sum(casewhen(CustomerInvoice.InvoiceDate ge ${year}0101 and CustomerInvoice.InvoiceDate lt ${year + 1}0101,CustomerInvoice.TaxInclusiveAmount,0)) as SumThisYear`,
            `sum(casewhen(CustomerInvoice.InvoiceDate ge ${year - 1}0101 and CustomerInvoice.InvoiceDate lt ${year}0101,CustomerInvoice.TaxInclusiveAmount,0)) as SumLastYear`,
            `sum(CustomerInvoice.RestAmount) as SumUnpaid`,
            `ID as ID`,
            `CustomerNumber as CustomerNumber`,
            `Info.Name as Name`,
        ];

        const query =
            `model=Customer` +
            `&select=${selects.join(',')}` +
            `&filter=CustomerInvoice.StatusCode gt 42001` +
            `&orderby=sum(casewhen(CustomerInvoice.InvoiceDate ge ${year}0101,CustomerInvoice.TaxInclusiveAmount,0)) desc` +
            `&join=Customer.ID eq CustomerInvoice.CustomerID` +
            `&expand=Info&top=10&distinct=false`;

        const cacheKey = this.hashFnv32a(query);
        let request = this.getFromCache(cacheKey);
        if (!request) {
            request = this.statisticsService.GetAllUnwrapped(query).pipe(publishReplay(1), refCount());

            this.storeInCache(cacheKey, request);
        }

        return request.pipe(map((res) => cloneDeep(res)));
    }
}
