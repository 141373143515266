import { UniTableColumn, UniTableColumnType, UniTableColumnSortMode } from './unitableColumn';
import { IContextMenuItem } from '../unitable';
import { ITableFilter } from '../../ag-grid/interfaces';
import { Observable } from 'rxjs';

export interface IDeleteButton {
    deleteHandler: (rowModel?: any) => any;
    disableOnReadonlyRows?: boolean;
}

export interface ISortInfo {
    field: string;
    direction: number;
    type: UniTableColumnType;
    mode: UniTableColumnSortMode;
}

export interface IEditorData {
    column: UniTableColumn;
    rowModel: any;
    initValue: string;
    initAsDirty: boolean;
    cancel: boolean;
}

export interface QuickFilter {
    field: string;
    operator?: string;
    value?: any;
    label?: string;
    type?: string;
    filterGenerator?: (value) => ITableFilter | string;
    ignoreColumnVisibility?: boolean;
    width?: string;
    options?: { label: string; value: any }[];
    optionsResolver?: () => Observable<any[]>;
    optionsEndpoint?: string;
}

export interface TableButton {
    label: string;
    icon?: string;
    action: () => void;
    class?: string;
}

export interface EditButtonOptions {
    action: (row) => any;
    hidden?: (row) => boolean;
}

export interface ExpressionFilterValue {
    expression: string;
    value: string;
}

interface GroupingConfig {
    defaultExpandedState?: boolean;
    staticGroupByColumn?: UniTableColumn;
}

export interface IUniTableConfig {
    configStoreKey?: string;
    entityType?: string;
    columns: UniTableColumn[];
    editable?: boolean;
    searchable?: boolean;
    pageable?: boolean;
    pageSize?: number;
    rowHeight?: number;
    multiRowSelect?: boolean;
    multiRowSelectDefaultValue?: boolean;
    multiRowSelectOnRowClick?: boolean;
    customRowSelection?: {
        isRowSelected?: (rowModel) => boolean;
        onSelectionChange?: (event: { allRowsUnchecked?: boolean; changes: Array<{ row; selected: boolean }> }) => void;
    };
    columnMenuVisible?: boolean;
    advancedColumnMenu?: boolean;
    changeCallback?: (event: IRowChangeEvent) => any | Promise<any>;
    dataMapper?: (data) => Array<any>;
    autoAddNewRow?: boolean;
    allowGroupFilter?: boolean;
    sortable?: boolean;
    defaultRowData?: Object;
    conditionalRowCls?: (rowModel: any) => string;
    contextMenu?: {
        items: IContextMenuItem[];
        showDropdownOnSingleItem: boolean;
        disableOnReadonlyRows: boolean;
    };
    deleteButton: boolean | IDeleteButton;
    deleteButtonUseBinIcon: boolean;
    editButton: EditButtonOptions;
    disableDeleteOnReadonly?: boolean;
    filters?: ITableFilter[];
    expressionFilterValues?: ExpressionFilterValue[];
    isRowReadOnly?: (rowModel) => boolean;
    isRowSelectable?: (rowModel) => boolean;
    // isRowSelected?: (rowModel) => boolean;
    onRowSelectionChange?: (changes?: { row; selected: boolean }[]) => void;
    defaultOrderBy?: ISortInfo;
    autoScrollIfNewCellCloseToBottom?: boolean;
    beforeEdit?: (editorData: IEditorData) => IEditorData;
    insertRowHandler?: (index: number) => void;
    rowDraggable?: boolean;
    autofocus?: boolean;
    showTotalRowCount?: boolean;
    virtualScroll?: boolean;
    hideRowCount?: boolean;
    quickFilters?: QuickFilter[];
    buttons?: TableButton[];
    rightAlignButtons?: boolean;
    noRowsMessage?: string;
    showDeletedRows?: boolean;
    useUrlState?: boolean;

    filterLocalData?: boolean;

    groupingEnabled?: boolean;
    groupingConfig?: GroupingConfig;
}

export interface IRowChangeEvent {
    field: string;
    rowModel: any;
    originalIndex: number;
    newValue: any;
    triggeredByOtherEvent?: boolean;
    copyEvent?: boolean;
}

export class UniTableConfig implements IUniTableConfig {
    public configStoreKey: string;
    public entityType: string;
    public columns: UniTableColumn[];
    public editable: boolean;
    public searchable: boolean;
    public pageable: boolean;
    public pageSize: number;
    public rowHeight: number;
    public cacheBlockSize: number;
    public autoAddNewRow: boolean;
    public allowGroupFilter: boolean;
    public sortable: boolean;
    public rowDraggable: boolean;
    public multiRowSelect: boolean;
    public multiRowSelectDefaultValue: boolean;
    public multiRowSelectOnRowClick: boolean;
    public customRowSelection: {
        isRowSelected?: (rowModel) => boolean;
        onSelectionChange?: (event: { allRowsUnchecked?: boolean; changes: Array<{ row; selected: boolean }> }) => void;
    };

    public columnMenuVisible: boolean;
    public advancedColumnMenu: boolean;
    public autoScrollIfNewCellCloseToBottom: boolean;
    public deleteButton: boolean | IDeleteButton;
    public deleteButtonUseBinIcon: boolean;
    public editButton: EditButtonOptions;
    public disableDeleteOnReadonly: boolean;
    public conditionalRowCls: (rowModel: any) => string;
    public contextMenu: {
        items: IContextMenuItem[];
        showDropdownOnSingleItem: boolean;
        disableOnReadonlyRows: boolean;
    };
    public copyFromCellAbove: boolean;
    public insertRowHandler?: (index: number) => void;

    public filters: ITableFilter[];
    public expressionFilterValues: ExpressionFilterValue[];
    public changeCallback: (event: IRowChangeEvent) => any;
    public dataMapper: (data) => Array<any>;
    public defaultRowData: Object;
    public isRowReadOnly: (rowModel) => boolean;
    public isRowSelectable: (rowModel) => boolean;
    // public isRowSelected: (rowModel) => boolean;

    public defaultOrderBy: ISortInfo;

    public beforeEdit: (event: IEditorData) => IEditorData;
    public autofocus: boolean;
    public autoselectFirstRow: boolean;
    public showTotalRowCount: boolean;
    public virtualScroll: boolean;
    public hideRowCount: boolean;
    public quickFilters: QuickFilter[];
    public buttons: TableButton[];
    public rightAlignButtons: boolean;
    public noRowsMessage: string;
    public showDeletedRows?: boolean;
    public useUrlState?: boolean;

    public groupingEnabled: boolean;
    public groupingConfig: GroupingConfig;
    public filterLocalData = true;

    public useInfobannerWhenMoreThenSelectedRowsExists: boolean;

    /**
     * @constructor
     * @param tableName Unique name for the table. This is used as key when saving column setup.
     * @param editable
     * @param pageable
     * @param pageSize
     */
    constructor(configStoreKey: string, editable?: boolean, pageable?: boolean, pageSize?: number) {
        this.configStoreKey = configStoreKey;
        this.editable = editable !== undefined ? editable : true;
        this.pageable = pageable !== undefined ? pageable : true;
        this.columnMenuVisible = true;
        this.pageSize = pageSize || 20;
        this.cacheBlockSize = 50;
        this.autoAddNewRow = true;
        this.allowGroupFilter = false;
        this.sortable = true;
        this.multiRowSelect = false;
        this.multiRowSelectDefaultValue = false;
        this.deleteButton = false;
        this.autoScrollIfNewCellCloseToBottom = false;
        this.contextMenu = { items: [], disableOnReadonlyRows: false, showDropdownOnSingleItem: true };
        this.columns = [];
        this.expressionFilterValues = [];

        this.copyFromCellAbove = true;
    }

    public setUseUrlState(useUrlState: boolean) {
        this.useUrlState = useUrlState;
        return this;
    }

    public setAutofocus(autofocus: boolean) {
        this.autofocus = autofocus;
        return this;
    }

    public setShowDeletedRows(show: boolean) {
        this.showDeletedRows = show;
        return this;
    }

    public setAutoselectFirstRow(autoselect: boolean) {
        this.autoselectFirstRow = autoselect;
        return this;
    }

    public setEntityType(entityType: string) {
        this.entityType = entityType;
        return this;
    }

    public setEditable(editable: boolean) {
        this.editable = editable;
        return this;
    }

    public setColumnMenuVisible(columnMenuVisible: boolean, advancedColumnMenu: boolean = false) {
        this.columnMenuVisible = columnMenuVisible;
        this.advancedColumnMenu = advancedColumnMenu;
        return this;
    }

    public setSearchable(searchable: boolean) {
        this.searchable = searchable;
        return this;
    }

    public setSortable(sortable: boolean) {
        this.sortable = sortable;
        return this;
    }

    public setRowDraggable(draggable: boolean) {
        this.rowDraggable = draggable;
        return this;
    }

    public setPageable(pageable: boolean) {
        this.pageable = pageable;
        return this;
    }

    public setPageSize(pageSize: number) {
        this.pageSize = pageSize || 20;
        return this;
    }

    public setRowHeight(rowHeight: number) {
        this.rowHeight = rowHeight || 50;
        return this;
    }

    public setCacheBlockSize(cacheBlockSize: number) {
        this.cacheBlockSize = cacheBlockSize || 50;
        return this;
    }

    public setAutoAddNewRow(autoAddNewRow: boolean) {
        this.autoAddNewRow = autoAddNewRow;
        return this;
    }

    public setAllowGroupFilter(allowGroupFilter: boolean) {
        this.allowGroupFilter = allowGroupFilter;
        return this;
    }

    public setDefaultRowData(defaultRowData: Object) {
        this.defaultRowData = defaultRowData;
        return this;
    }

    public setChangeCallback(changeCallback: (event) => any) {
        this.changeCallback = changeCallback;
        return this;
    }

    public setDataMapper(dataMapper: (data) => Array<any>) {
        this.dataMapper = dataMapper;
        return this;
    }

    public setMultiRowSelect(
        multirowSelect: boolean,
        multiRowSelectDefaultValue?: boolean,
        selectOnRowClick?: boolean,
    ) {
        this.multiRowSelect = multirowSelect;
        this.multiRowSelectDefaultValue = multiRowSelectDefaultValue || false;
        this.multiRowSelectOnRowClick = selectOnRowClick || false;
        return this;
    }

    public setCustomRowSelection(config: {
        isRowSelected?: (rowModel) => boolean;
        onSelectionChange?: (event: { allRowsUnchecked?: boolean; changes: Array<{ row; selected: boolean }> }) => void;
    }) {
        this.customRowSelection = config;
        return this;
    }

    public setAutoScrollIfNewCellCloseToBottom(autoscroll: boolean) {
        this.autoScrollIfNewCellCloseToBottom = autoscroll;
        return this;
    }

    public setConfigStoreKey(key: string): UniTableConfig {
        this.configStoreKey = key;
        return this;
    }

    public setColumns(columns: UniTableColumn[]) {
        this.columns = columns;
        return this;
    }

    public setContextMenu(
        items: IContextMenuItem[],
        showDropdownOnSingleItem: boolean = true,
        disableOnReadonlyRows: boolean = false,
    ) {
        this.contextMenu = {
            items: items,
            disableOnReadonlyRows: disableOnReadonlyRows,
            showDropdownOnSingleItem: showDropdownOnSingleItem,
        };

        return this;
    }

    public setDeleteButton(deleteButton: boolean | IDeleteButton, disableOnReadonly?: boolean, useBinIcon = true) {
        this.deleteButton = deleteButton;
        this.disableDeleteOnReadonly = disableOnReadonly;
        this.deleteButtonUseBinIcon = useBinIcon;
        return this;
    }

    public setEditButton(options: EditButtonOptions) {
        this.editButton = options;
        return this;
    }

    public setExpressionFilterValues(expressionFilterValues: Array<ExpressionFilterValue>) {
        this.expressionFilterValues = expressionFilterValues;
        return this;
    }

    public setFilters(filters: ITableFilter[]) {
        this.filters = filters;
        return this;
    }

    public setIsRowReadOnly(isRowReadOnly: (rowModel) => boolean) {
        this.isRowReadOnly = isRowReadOnly;
        return this;
    }

    public setIsRowSelectable(isRowSelectable: (rowModel) => boolean) {
        this.isRowSelectable = isRowSelectable;
        return this;
    }

    // public setIsRowSelected(isRowSelected: (rowModel) => boolean) {
    //     this.isRowSelected = isRowSelected;
    //     return this;
    // }

    public setDefaultOrderBy(field: string, direction: number, mode: number = UniTableColumnSortMode.Normal) {
        this.defaultOrderBy = {
            field: field,
            direction: direction,
            type: UniTableColumnType.Text,
            mode: mode,
        };
        return this;
    }

    public setBeforeEdit(beforeEdit: (editorData: IEditorData) => IEditorData) {
        this.beforeEdit = beforeEdit;
        return this;
    }

    public setConditionalRowCls(conditionalRowCls: (rowModel: any) => string) {
        this.conditionalRowCls = conditionalRowCls;
        return this;
    }

    public setCopyFromCellAbove(copyFromCellAbove: boolean) {
        this.copyFromCellAbove = copyFromCellAbove;
        return this;
    }

    public setInsertRowHandler(handler: (index: number) => void) {
        this.insertRowHandler = handler;
        return this;
    }

    public setShowTotalRowCount(show: boolean) {
        this.showTotalRowCount = show;
        return this;
    }

    public setVirtualScroll(virtualScroll: boolean) {
        this.virtualScroll = virtualScroll;
        return this;
    }

    public setHideRowCount(hideRowCount: boolean) {
        this.hideRowCount = hideRowCount;
        return this;
    }

    setNoRowsMessage(message: string) {
        this.noRowsMessage = message;
        return this;
    }

    setQuickFilters(quickFilters: QuickFilter[]) {
        this.quickFilters = quickFilters;
        return this;
    }

    setButtons(buttons: TableButton[], alignRight?: boolean) {
        this.buttons = buttons;
        this.rightAlignButtons = alignRight;
        return this;
    }

    suppressLocalDataFiltering() {
        this.filterLocalData = false;
        return this;
    }

    enableGrouping(config?: GroupingConfig) {
        this.groupingEnabled = true;
        this.groupingConfig = config;
        return this;
    }
}
