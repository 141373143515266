import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import { APP_METADATA } from 'src/environments/metadata';
import { ErrorService } from '@app/services/common/errorService';
declare var Stimulsoft;

const STIMULSOFT_KEY =
    '6vJhGtLLLz2GNviWmUTrhSqnOItdDwjBylQzQcAOiHmekcWuYNoh+JI8nedowBpCzrs4Lyx2mym36z1d4tm7+YJw6g' +
    'eCH4KjrXCk0DFUu1eOvTHRTEn9FoCDW8Q/tEVpDxz1PxupCTPxiGIvAMISx5GVP3C5QIUwA0mhJXf/do7UqknL2Wwo' +
    '7h1rr/UZScQznG66yjUhPsS2dOLNY3/eGm/7oHV0LLfwocP71LcPUy46vd2l9au7X9Zo0LabEsYhXDiliHxtXFGsYk' +
    'hszqTIPwtMQ/STTUvNEG9PkfVl50pJ21c+XTMwSssSeQVihsWHCBO7QnTqI/XH97PgEChkpluBxspruhmLLq/Yrh5E' +
    'SRZKJZ1Pt6iB5zrmg6mDJfZ+PGRkHps+HwJUSgr9KEEHQ+fykrglo+0IHiurxZkAhUMIn23RGYTXyzwc4bQsYrQW2H' +
    'WEGg1JSSO24F5E2dKcYIkPF80C2xVNsG3GUMMzFMX0f/SeesJLvBrrVnpj1vePGbFLMGMLaRuYRbTYB7/3FyjX0I0b' +
    'n08oE4e00aBe8ZCf7mgleuPZGAGc0XejasdqjkkrX1/zT9RHRzaaPkQHZpKjLbgoEWAKX87MD7jTLRFjKq9gjsplbb' +
    '9RSZMoenzgC10LKQdZH6sUVDiOk4wRWpROxmJIyNpOLFI30naILw/jvXNmdbKI/mpt/r39ytA2NrdRdtcSa/9Vwa4B' +
    'HouEYKQiVi6MuUmQBy9PTsxUFyLp0eAjMg0a2pG8oCTBOol81XiPZPrERIH39eg5WjbJD3Ih9XRcZ9+sEO9SS3ROKo' +
    'A=';

@Injectable()
export class StimulsoftReportWrapper {
    private stimulsoftScriptsLoaded: { [key: string]: boolean } = {};
    private viewer;
    constructor(private errorService: ErrorService) {}

    private loadStimulsoftScript(scriptName: string) {
        if (this.stimulsoftScriptsLoaded[scriptName]) {
            return Promise.resolve();
        } else {
            return new Promise<void>((resolve) => {
                const script: any = document.createElement('script');
                script.type = 'text/javascript';
                script.src = 'assets/stimulsoft/stimulsoft.' + scriptName + '.js?v=' + APP_METADATA.APP_VERSION;

                if (script.readyState) {
                    // IE
                    script.onreadystatechange = () => {
                        if (script.readyState === 'loaded' || script.readyState === 'complete') {
                            script.onreadystatechange = null;
                            this.stimulsoftScriptsLoaded[scriptName] = true;
                            resolve();
                        }
                    };
                } else {
                    // Reasonable browsers
                    script.onload = () => {
                        this.stimulsoftScriptsLoaded[scriptName] = true;
                        resolve();
                    };
                }

                script.onerror = (error) => this.errorService.handle(error);
                document.getElementsByTagName('head')[0].appendChild(script);
            });
        }
    }

    public loadLibraries() {
        return this.loadStimulsoft()
            .then(() => this.loadStimulsoftViewer())
            .then(() => {
                Stimulsoft.Base.StiLicense.key = STIMULSOFT_KEY;
            })
            .then(() =>
                Stimulsoft.Base.Localization.StiLocalization.setLocalizationFile('assets/stimulsoft/nb-NO.xml'),
            );
    }

    private loadStimulsoft(): Promise<any> {
        return this.loadStimulsoftScript('reports');
    }

    private loadStimulsoftViewer(): Promise<any> {
        return this.loadStimulsoftScript('viewer');
    }

    public renderReport(template, data, parameters, localization, resolver) {
        const report = new Stimulsoft.Report.StiReport();
        report.load(template);

        // remove connections specified in the template file
        // report.dictionary.databases.clear();
        // add variables based on parameters
        if (parameters) {
            for (let i = 0; i < parameters.length; i++) {
                let reportParam = report.dictionary.variables.getByName(parameters[i].Name);
                if (reportParam) {
                    reportParam.value = parameters[i].value;
                }
            }
        }

        const dataset = new Stimulsoft.System.Data.DataSet('Data');
        dataset.readJson(data);
        report.regData('Data', 'Data', dataset);
        if (localization && localization !== 'no') {
            try {
                report.localizeReport(localization);
            } catch (e) {
                console.log(`Stimulsoft.localizeReport error for '${localization}' ${e}`);
            }
        }
        report.renderAsync(() => {
            resolver(report);
        });
    }

    public renderHtml(report, resolver, continous = true) {
        let runPromise = false;
        const options = this.viewer?.options ?? new Stimulsoft.Viewer.StiViewerOptions();
        options.appearance.theme = Stimulsoft.Viewer.StiViewerTheme.Office2003;
        options.toolbar.visible = true;
        options.toolbar.viewMode = continous ? Stimulsoft.Viewer.StiWebViewMode.Continuous : 0;
        if (!this.viewer) {
            this.viewer = new Stimulsoft.Viewer.StiViewer(options, 'StiViewer', false);
        }

        this.viewer.onShowReport = () => {
            if (resolver && !runPromise) {
                resolver(true);
                runPromise = true;
            }
        };
        this.viewer.report = report;
        this.viewer.showProcessIndicator();
        this.viewer.renderHtml('reportContainer');

        const printMenu = this.viewer.jsObject.controls.menus.printMenu.firstChild;
        const printWithPreviewOption = printMenu.children[1];
        const printWithoutPreviewOption = printMenu.children[2];
        printMenu.removeChild(printWithPreviewOption);
        printMenu.removeChild(printWithoutPreviewOption);
    }

    public showReport(template: string, reportData: Object, parameters: Array<any>, localization: string, caller: any) {
        if (!template || !reportData || !caller) {
            return;
        }
        this.loadStimulsoft().then(() =>
            this.loadStimulsoftViewer().then(() => {
                Stimulsoft.Base.StiLicense.key = STIMULSOFT_KEY;
                this.renderReport(template, reportData, parameters, localization, (report) => {
                    this.renderHtml(report, null);
                });
            }),
        );
    }

    public printReport(
        template: string,
        reportData: Object,
        parameters: Array<any>,
        format: string,
        localization: string,
    ): Promise<string> {
        return new Promise((resolve, reject) => {
            if (!template || !reportData) {
                reject();
            } else {
                return this.loadStimulsoft().then(() => {
                    Stimulsoft.Base.StiFontCollection.addOpentypeFontFile(
                        'assets/SourceSansPro-Regular.ttf',
                        'Source Sans Pro',
                    );

                    this.renderReport(template, reportData, parameters, localization, (report) => {
                        let mimetype: string;

                        if (report) {
                            var settings, service;

                            switch (format) {
                                case 'html':
                                    mimetype = 'text/html';
                                    settings = new Stimulsoft.Report.Export.StiHtmlExportSettings();
                                    service = new Stimulsoft.Report.Export.StiHtmlExportService();
                                    settings.htmlType = Stimulsoft.Report.StiHtmlType.Html5;
                                    break;
                                case 'doc':
                                    mimetype = 'application/doc';
                                    settings = new Stimulsoft.Report.Export.StiWord2007ExportSettings();
                                    service = new Stimulsoft.Report.Export.StiWord2007ExportService();
                                    break;
                                case 'xls':
                                    mimetype = 'application/xls';
                                    settings = new Stimulsoft.Report.Export.StiExcelExportSettings(null);
                                    service = new Stimulsoft.Report.Export.StiExcel2007ExportService();
                                    break;
                                case 'csv':
                                    mimetype = 'application/csv';
                                    settings = new Stimulsoft.Report.Export.StiCsvExportSettings();
                                    service = new Stimulsoft.Report.Export.StiCsvExportService();
                                    break;
                                default:
                                    mimetype = 'application/pdf';
                                    settings = new Stimulsoft.Report.Export.StiPdfExportSettings();
                                    service = new Stimulsoft.Report.Export.StiPdfExportService();
                                    service.useUnicode = true;
                                    break;
                            }

                            const fileName =
                                !report.reportAlias || !report.reportAlias.length
                                    ? report.reportName
                                    : report.reportAlias;

                            var data: any;

                            // Export
                            if (format === 'html') {
                                const textWriter = new Stimulsoft.System.IO.TextWriter();
                                const htmlTextWriter = new Stimulsoft.Report.Export.StiHtmlTextWriter(textWriter);
                                service.exportTo(report, htmlTextWriter, settings);
                                data = textWriter.getStringBuilder().toString();
                            } else {
                                const stream = new Stimulsoft.System.IO.MemoryStream();
                                service.exportTo(report, stream, settings);
                                data = stream.toArray();
                            }

                            if (format == 'html') {
                                let blob = new Blob([data], { type: mimetype });
                                saveAs(blob, fileName + '.' + format);
                            } else {
                                let blob = new Blob([new Int8Array(data)], { type: mimetype });
                                saveAs(blob, fileName + '.' + format);
                            }
                        }
                    });
                });
            }
        });
    }
}
