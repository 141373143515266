<section role="dialog" class="uni-modal">
    <rig-wizard [steps]="steps" [activeStepValue]="steps[currentStep].value">
        <section *ngIf="busy" class="modal-spinner">
            <mat-spinner class="c2a"></mat-spinner>
        </section>

        <section *ngIf="activeStep === stepValues.FirstVatPeriod">
            <h1>Overgang til momspliktig</h1>

            <div class="flex gap-4 mb-4">
                <p>
                    Du har nå gått over til å bli en avgiftspliktig bedrift hvor du må levere MVA-melding i faste
                    intervaller til myndighetene.

                    <br /><br />

                    Systemet trenger å vite når ditt firma skal sende inn sin første mva-melding.
                </p>

                <uni-illustration name="payment_period" style="width: 27rem" class="ml-4"></uni-illustration>
            </div>

            <uni-form [config]="{ showLabelAbove: true }" [fields]="fields$" [model]="accountSettings$"> </uni-form>
        </section>

        <section *ngIf="activeStep === stepValues.UpdateAccounts">
            <h1>Oppdater kontoplan</h1>

            <p>
                For at innrapporteringen skal bli korrekt sjekker vi om du må oppdatere kontoplanen for å få tilgang til
                alle MVA kontoene. Dette gjør systemet om du gir oss tillatelse.
            </p>
            <p>
                Systemet vil oppdatere hvilke kontoer som er synlig og sette standard salgskonto
                <br />
                (3000 - Salgsinntekt handelsvare, avgiftspliktig, høy sats)
            </p>

            <rig-checkbox [(ngModel)]="updateAccounts">La systemet oppdatere kontoplanen</rig-checkbox>
        </section>

        <section *ngIf="activeStep === stepValues.UpdateProducts">
            <h1>Oppdater produkter</h1>

            <p>
                Hovedbokskonto på dine produkter må endres til avgiftspliktig (mva) konto. (3000 - Salgsinntekt
                handelsvare, avgiftspliktig, høy sats)
            </p>
            <p>
                Dette kan systemet gjøre atuomatisk for deg. Systemet vil kun endre produkter som har samme
                hovedbokskonto som nåværende standard salgskonto (3000 - Salgsinntekt handelsvare, avgiftspliktig, høy
                sats)
            </p>
            <rig-checkbox [(ngModel)]="updateProducts">La systemet oppdatere produktene dine</rig-checkbox>
        </section>

        <section *ngIf="activeStep === stepValues.PostInvoicing">
            <h1>Etterfakturering av mva</h1>

            <p>
                Om du selger avgiftspliktige varer eller tjenester, må du nå etterfakturere mervediavgift for det du har
                fakturert som overstiger 50 000,00.
            </p>

            <strong>Den enkleste måten å gjøre dette på er:</strong>
            <ol>
                <li>
                    Kreditere fakturaen (eller fakturaene om du har sendt ut flere i mellomtiden) som overstiger
                    omsetningsgrensa.
                </li>
                <li>Det er viktig at kreditnotaen har samme dato som den orginale fakturaen.</li>
                <li>Lag ny(e) faktura som inkluderer MVA, også med samme dato som den originale fakturaen(e).</li>
            </ol>

            <p>
                Nå sender du både kreditnota og den nye fakturaen til kunden. Om kunden allerede har betalt den orginale
                fakturaen, kan du be om at de bare betaler inn merverdiavgiften denne gangen.
            </p>

            @if (options?.modalConfig?.link1) {
                <a [href]="options.modalConfig.link1.url" target="_blank">
                    {{ options.modalConfig.link1?.text }}
                    <i class="material-icons">open_in_new</i>
                </a>
            }
        </section>

        <section *ngIf="activeStep === stepValues.Retrospective">
            <h1>Tilbakegående avgiftsoppgjør</h1>

            <p>
                Når selskapet er mva-registrert i Brønnøysund, kan du føre mva-fradrag for kostnadene.

                <br /><br />
                Du kan i tillegg føre mva-fradrag for alle kostnader selskapet har hatt fra stiftelsen og frem til
                mva-registreringen. Dette kalles et tilbakegående avgiftsoppgjør.

                <br /><br />
                Korrigeringen og fradraget skal sendes inn som en tilleggsoppgave for den terminen selskapet ble
                mva-registrert fra.
            </p>

            <div class="flex-column gap-2">
                @if (options?.modalConfig?.link2) {
                    <a [href]="options.modalConfig.link2.url" target="_blank">
                        {{ options.modalConfig.link2.text }}
                        <i class="material-icons">open_in_new</i>
                    </a>
                }

                @if (options?.modalConfig?.link3) {
                    <a [href]="options.modalConfig.link3.url" target="_blank">
                        {{ options.modalConfig.link3.text }}
                        <i class="material-icons">open_in_new</i>
                    </a>
                }
            </div>

            <p>
                For å gjøre dette i systemet lager du et regnskapsbilag i den første terminen du er mva-pliktig og
                bokfører den mva du har plikt på.
            </p>
        </section>

        <rig-wizard-footer>
            <button class="secondary pull-left" (click)="cancel()">Avbryt</button>

            <button *ngIf="activeStep !== firstStep" class="secondary" (click)="previous()">Tilbake</button>

            <button class="c2a" *ngIf="activeStep !== lastStep" (click)="next()">Neste</button>

            <button class="c2a" *ngIf="activeStep === lastStep" (click)="done()">Fullfør</button>
        </rig-wizard-footer>
    </rig-wizard>
</section>
