import { ChangeDetectionStrategy, Component, ContentChildren, HostBinding, Input, QueryList } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UniRadioButton } from './radio-button';
import { generateId } from '@app/components/common/utils/utils';

@Component({
    selector: 'uni-radio-group',
    styleUrls: ['./radio-group.sass'],
    template: `<ng-content></ng-content>`,
    host: { role: 'radiogroup' },
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: UniRadioGroup, multi: true }],
})
export class UniRadioGroup implements ControlValueAccessor {
    @ContentChildren(UniRadioButton, { descendants: true }) radioButtons: QueryList<UniRadioButton>;

    @Input() disabled: boolean;

    /*
        Used by radio-button.ts to name the inputs.
        This is necessary to avoid focus issues if multiple
        radio groups are used on the same page.
    */
    name = generateId('radio-group');

    value: any;

    ngOnChanges(changes) {
        if (changes.disabled) {
            this.setDisabledState(this.disabled);
        }
    }

    onButtonSelected(value: any) {
        this.value = value;
        this.setButtonStateFromValue();
        this.updateNgModel?.(this.value);
    }

    setButtonStateFromValue() {
        this.radioButtons?.forEach((button) => {
            button.setChecked(button.value === this.value);
        });
    }

    // The following functions are called by the Angular forms API
    // when a ngModel or FormControl is used with the component.
    // Do not rename or remove anything here unless you know why you're doing it.
    private updateNgModel: (value: any) => void;

    registerOnChange(fn) {
        this.updateNgModel = fn;
    }

    writeValue(value: any | any[]) {
        this.value = value;
        this.setButtonStateFromValue();
    }

    registerOnTouched() {}

    setDisabledState(disabled: boolean): void {
        this.disabled = disabled;
        this.radioButtons?.forEach((button) => button.setDisabled(disabled));
    }
    // end of form api functions
}
