<textarea
    *ngIf="control"
    [maxlength]="field?.MaxLength || 1000000"
    [attr.aria-describedby]="asideGuid"
    [formControl]="control"
    [readonly]="readOnly$ | async"
    [cols]="field?.Options?.cols || 100"
    [rows]="field?.Options?.rows || 10"
    [placeholder]="field?.Placeholder || ''"
    [ngClass]="field?.Options?.class || ''"
    (blur)="blurHandler($event)"
    (focus)="focusHandler()"
    [title]="control?.value || ''"
    (keydown.enter)="$event.stopPropagation()"
></textarea>
<ng-content></ng-content>
