import { Component, EventEmitter, OnInit } from '@angular/core';
import { StatisticsService } from '@app/services/common/statisticsService';
import { AutocompleteOptions } from '@uni-framework/ui/autocomplete/autocomplete';
import { IModalOptions, IUniModal } from '@uni-framework/uni-modal';
import { Observable } from 'rxjs';
interface IMunicipal {
    MunicipalityNo: string;
    MunicipalityName: string;
    ZoneID: number;
    ZoneName: string;
}

@Component({
    selector: 'uni-agazone-search-modal',
    templateUrl: './agazone-search-modal.component.html',
    styleUrls: ['./agazone-search-modal.component.sass'],
})
export class AGAZoneSearchModalComponent implements IUniModal {
    onClose: EventEmitter<number> = new EventEmitter();
    options?: IModalOptions;
    forceCloseValueResolver?: () => any = () => null;
    selectedMunicipal: IMunicipal;

    autocompleteOptions: AutocompleteOptions = {
        autofocus: true,
        lookup: (query) => this.search(query),
        displayFunction: (item: IMunicipal) => `${item.MunicipalityName} - ${item.ZoneName}`,
        resultTableColumns: [
            {
                header: 'Kommunenr',
                field: 'MunicipalityNo',
            },
            {
                header: 'Kommunenavn',
                field: 'MunicipalityName',
            },
            {
                header: 'Sone',
                field: 'ZoneName',
            },
        ],
        placeholder: 'Søk etter kommune',
    };

    constructor(private statisticsService: StatisticsService) {}

    close(municipality?: IMunicipal) {
        this.onClose.next(municipality?.ZoneID);
    }

    select(municipal: IMunicipal) {
        this.selectedMunicipal = municipal;
    }

    search(query: string): Observable<IMunicipal[]> {
        return this.statisticsService.GetAllUnwrapped(
            `select=MunicipalityNo as MunicipalityNo,ZoneID as ZoneID,AGAZone.ZoneName as ZoneName,Municipal.MunicipalityName as MunicipalityName` +
                `&model=MunicipalAGAZone` +
                `&join=MunicipalAGAZone.MunicipalityNo eq Municipal.MunicipalityNo as Municipal and MunicipalAGAZone.ZoneID eq AGAZone.ID as AGAZone` +
                `&filter=Municipal.Retired eq 0 and ( ${this.createFilter(query)} )` +
                `&top=50`,
        );
    }

    private createFilter(query: string) {
        const parts = query.split('-').map((part) => part.trim());
        return parts
            .map(
                (part) =>
                    `( startswith(MunicipalityNo,'${part}') or contains(Municipal.MunicipalityName,'${part}') or contains(AGAZone.ZoneName,'${part}') )`,
            )
            .join(` and `);
    }
}
