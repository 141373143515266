import { ChangeDetectionStrategy, Component, inject, input } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { CMSBannerData, CMSService } from '@app/services/cms/cms-service';
import { DesignSystemModule } from '@uni-framework/ui/design-system/design-system.module';
import { AnalyticsEvent, AnalyticsService } from '@app/services/analytics-services/analytics.service';

@Component({
    selector: 'custom-cms-banner',
    templateUrl: './banner-ad.html',
    styleUrls: ['./banner-ad.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [DesignSystemModule, CommonModule],
    animations: [
        trigger('slideIn', [
            transition(':enter', [
                style({ transform: 'translateY(-120%)', opacity: 0 }),
                animate('400ms ease-out', style({ transform: 'translateY(0)', opacity: 1 })),
            ]),
            transition(':leave', [animate('400ms ease', style({ transform: 'translateY(-120%)', opacity: 0 }))]),
        ]),
    ],
})
export class CMSBanner {
    banner = input.required<CMSBannerData>();
    analytics = inject(AnalyticsService);

    constructor(
        private router: Router,
        public cms: CMSService,
    ) {}

    ngAfterViewInit() {
        this.analytics.trackCMSEvents(AnalyticsEvent.CMS_BANNER_SHOWN, this.banner()._id);
    }

    goToLink() {
        if (this.banner().buttonText) return;

        if (this.banner().isExternal) {
            window.open(this.banner().url, '_blank');
        } else {
            this.router.navigateByUrl(this.banner().url);
        }

        this.analytics.trackCMSEvents(AnalyticsEvent.CMS_BANNER_CLICKED, this.banner()._id);
    }

    close() {
        this.analytics.trackCMSEvents(AnalyticsEvent.CMS_BANNER_CLOSED, this.banner()._id);
        this.cms.closeBanner();
    }
}
