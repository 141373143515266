import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { rigDate } from '@app/components/common/utils/rig-date';
import { StatusHistoryItem, StatusService } from '@app/services/common/statusService';

@Injectable()
export class StatusHistoryService {
    entity: any;
    statusHistory: StatusHistoryItem[];

    constructor(private statusService: StatusService) {}

    loadStatusHistory(entityType: string, entity: any, forceReload = false): Observable<StatusHistoryItem[]> {
        if (!entity?.ID) {
            this.statusHistory = undefined;
            this.entity = undefined;
            return of([]);
        }

        if (!forceReload && entity?.ID === this.entity?.ID) {
            const oldChangeDate = this.entity?.UpdatedAt || this.entity?.CreatedAt;
            const newChangeDate = entity?.UpdatedAt || entity.CreatedAt;

            const shouldReload = !oldChangeDate || rigDate(oldChangeDate).isBefore(rigDate(newChangeDate));
            return shouldReload ? this.getStatusHistory(entityType, entity) : of(this.statusHistory);
        } else {
            return this.getStatusHistory(entityType, entity);
        }
    }

    private getStatusHistory(entityType: string, entity: any) {
        return this.statusService.getStatusLogEntries(entityType, entity.ID).pipe(
            tap((history) => {
                this.entity = entity;
                this.statusHistory = history;
            }),
        );
    }
}
