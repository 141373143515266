import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BizHttp } from '../../../framework/core/http/BizHttp';
import {
    Bank,
    BankRule,
    BankAccount,
    BankIntegrationAgreement,
    StatusCodeBankIntegrationAgreement,
    BankAccountSettings,
} from '../../unientities';
import { UniHttp } from '../../../framework/core/http/http';
import { Observable } from 'rxjs';
import { BankData } from '@app/models';
import { StatisticsService } from '../common/statisticsService';
import { theme, THEMES } from 'src/themes/theme';
import { BankAgreementServiceProvider } from '@app/models/autobank-models';
import { rigDate } from '@app/components/common/utils/rig-date';

@Injectable({ providedIn: 'root' })
export class BankService extends BizHttp<Bank> {
    BANK_ACCOUNT_TYPES = [
        { label: 'Drift', value: 'company', suggestion: 1920 },
        { label: 'Skatt', value: 'tax', suggestion: 1950 },
        { label: 'Lønn', value: 'salary', suggestion: null },
        // { label: 'Kreditt', value: 'credit', suggestion: 1920 },
        // { label: 'Utenlandsbetaling', value: 'foreign', suggestion: 1920 }
    ];

    constructor(
        http: UniHttp,
        private statisticsService: StatisticsService,
    ) {
        super(http);

        this.relativeURL = Bank.RelativeUrl;

        this.entityType = Bank.EntityType;

        this.DefaultOrderBy = null;
    }

    public validateIBANUpsertBank(iban: string): Observable<BankData> {
        return super.GetAction(null, `verify-iban-upsert-bank&iban=${iban}`);
    }

    public validateIBAN(iban: string): Observable<BankData> {
        return super.GetAction(null, `verify-iban&iban=${iban}`);
    }

    public getIBANUpsertBank(bankAccountNumber: string): Observable<BankData> {
        return super.GetAction(null, `get-iban-upsert-bank&bankaccountnumber=${bankAccountNumber}`);
    }

    public getIBANFromAccountNumberLookup(bankAccountNumber: string): Observable<String> {
        return super.GetAction(null, `get-iban-from-accountnumber-lookup&bankaccountnumber=${bankAccountNumber}`);
    }

    public getCompanyBankAccount(endpoint: string) {
        return this.http
            .asGET()
            .usingBusinessDomain()
            .withEndPoint(endpoint)
            .send()
            .pipe(map((res) => res.body));
    }

    public postCompanyBankAccount(body) {
        return this.http.asPOST().withBody(body).usingBusinessDomain().withEndPoint('companybankaccounts').send();
    }

    public putCompanyBankAccount(body) {
        return this.http
            .asPUT()
            .withBody(body)
            .usingBusinessDomain()
            .withEndPoint('companybankaccounts/' + body.ID)
            .send();
    }

    public deleteCompanyBankAccount(id: number) {
        return this.http
            .asDELETE()
            .usingBusinessDomain()
            .withEndPoint('companybankaccounts/' + id)
            .send();
    }

    public createAutobankAgreement(agreementDetails: any) {
        return this.http
            .asPOST()
            .withBody(agreementDetails)
            .usingBusinessDomain()
            .withEndPoint('bank-agreements?action=create-integration')
            .send()
            .pipe(map((res) => res.body));
    }

    public getBalanceAgreement() {
        return this.http
            .asGET()
            .usingBusinessDomain()
            .withEndPoint(`bank-agreements?filter=IsBankBalance eq true&orderby=ServiceProvider desc`)
            .send()
            .pipe(map((res) => res.body));
    }

    public getDirectBankAgreement(serviceProvider: number) {
        return this.http
            .asGET()
            .usingBusinessDomain()
            .withEndPoint(`bank-agreements?action=get-direct-bank-agreement&serviceprovider=${serviceProvider}`)
            .send()
            .pipe(map((res) => res.body));
    }

    public setBankIntegrationChangeAgreement(hasPendingOrder: boolean, agreement?: BankIntegrationAgreement) {
        return this.http
            .asPOST()
            .usingBusinessDomain()
            .withEndPoint(`bank-agreements?action=order-bank-integration-change&hasPendingOrder=${hasPendingOrder}`)
            .withBody(agreement)
            .send()
            .pipe(map((res) => res.body));
    }

    public updateAutobankAgreementStatus(id: any, password: string) {
        return this.http
            .asPUT()
            .withBody(password)
            .usingBusinessDomain()
            .withEndPoint(`bank-agreements/${id}?action=update-status`)
            .send()
            .pipe(map((res) => res.body));
    }

    public updateAutobankAgreement(id: any, payload: any, notifyIntegration: boolean = false) {
        return this.http
            .asPUT()
            .withBody(payload)
            .usingBusinessDomain()
            .withEndPoint(`bank-agreements/${id}?action=update-bank-properties&notifyIntegration=${notifyIntegration}`)
            .send()
            .pipe(map((res) => res.body));
    }

    public validateAutobankPassword(password: string) {
        return this.http
            .asPOST()
            .withBody(password)
            .usingBusinessDomain()
            .withEndPoint('bank-agreements?action=validate-password')
            .send()
            .pipe(map((res) => res.body));
    }

    public mapBankIntegrationValues(
        bankAccount: BankAccount,
        agreements: BankIntegrationAgreement[] = null,
    ): BankAccount {
        const aritmaBankAgreement = agreements?.find(
            (ag) =>
                ag?.BankAccount?.BankID === bankAccount.BankID &&
                ag.ServiceProvider === BankAgreementServiceProvider.ZdataV3,
        );
        if (aritmaBankAgreement?.StatusCode === StatusCodeBankIntegrationAgreement.Active) {
            bankAccount['BankAccountSettings'] = bankAccount['BankAccountSettings'] || new BankAccountSettings();
            bankAccount['BankAccountSettings']['HasStatements'] = aritmaBankAgreement.IsBankBalance;
            bankAccount['BankAccountSettings']['HasOutgoing'] = aritmaBankAgreement.IsOutgoing;
            bankAccount['BankAccountSettings']['HasIncoming'] = aritmaBankAgreement.IsInbound;
        } else if (bankAccount?.BankAccountSettings?.IntegrationSettings) {
            let bit = (+bankAccount.BankAccountSettings.IntegrationSettings).toString(2);

            //a bank account can have multiple features activated. To prevent the need of creating a new bool for each new feature,
            // we us boolean flags:
            //00000000 means nothing
            //00000001 means statements are activated
            //00000010 means outgoing payments are activated
            //00000100 means incoming payements are activated
            //of course this could be a combination
            //00000101 means both statements and incoming payments are activated
            switch (bit.length) {
                case 1: {
                    bit = '00' + bit;
                    break;
                }
                case 2: {
                    bit = '0' + bit;
                    break;
                }
            }

            if (+bit.substr(0, 1) > 0) {
                bankAccount['BankAccountSettings']['HasStatements'] = true;
            }
            if (+bit.substr(1, 1) > 0) {
                bankAccount['BankAccountSettings']['HasOutgoing'] = true;
            }
            if (+bit.substr(2, 1) > 0) {
                bankAccount['BankAccountSettings']['HasIncoming'] = true;
            }
        }

        return bankAccount;
    }

    public getAllRules() {
        return this.http
            .asGET()
            .usingBusinessDomain()
            .withEndPoint('bankrules?orderby=Priority&expand=Account')
            .send()
            .pipe(map((response) => response.body));
    }

    public saveRule(rule: BankRule) {
        const request = rule.ID ? this.http.asPUT() : this.http.asPOST();
        const endpoint = `bankrules/${rule.ID ? rule.ID : ''}`;
        return request
            .usingBusinessDomain()
            .withEndPoint(endpoint)
            .withBody(rule)
            .send()
            .pipe(map((response) => response.body));
    }

    public getBankAccountsForReconciliation(periodFrom?: any, periodTo?: any): Observable<any> {
        const toDate = rigDate(periodTo).format('YYYY-MM-DD');
        const fromDate = rigDate(periodFrom).format('YYYY-MM-DD');
        return this.http
            .asGET()
            .usingBusinessDomain()
            .withEndPoint(
                `bankstatements?action=get-open-posts-count&periodFrom=${periodFrom}&periodTo=${periodTo}&stringPeriodTo=${toDate}`,
            )
            .send()
            .pipe(map((response) => response.body));
    }

    public getMonthlyReconciliationData(accountID: number) {
        return this.http
            .asGET()
            .usingBusinessDomain()
            .withEndPoint(`bankstatements?accountid=${accountID}&action=account-status-monthly`)
            .send()
            .pipe(map((response) => response.body));
    }

    public getIncommingAccountBalance(accountID) {
        return this.http
            .asGET()
            .usingBusinessDomain()
            .withEndPoint(`bankstatements?action=account-balance&accountid=${accountID}`)
            .send()
            .pipe(map((response) => response.body));
    }

    public getBankStatementListData() {
        return this.http
            .asGET()
            .usingStatisticsDomain()
            .withEndPoint(
                '?model=BankStatement&select=FromDate as FromDate,ToDate as ToDate,ID as ID,count(entry.ID) as count,' +
                    'Amount as Amount,Account.AccountName as AccountName,Account.AccountNumber as AccountNumber,StatusCode as StatusCode' +
                    '&join=BankStatement.ID eq BankStatementEntry.BankStatementID as Entry&Expand=Account,&top=50',
            )
            .send()
            .pipe(map((response) => response.body));
    }

    public bankStatementActions(id: number, action: string) {
        return this.http
            .asPOST()
            .usingBusinessDomain()
            .withEndPoint(`bankstatements/${id}?action=${action}`)
            .send()
            .pipe(map((response) => response.body));
    }

    public deleteBankStatement(id: number) {
        return this.http
            .asDELETE()
            .usingBusinessDomain()
            .withEndPoint(`bankstatements/${id}`)
            .send()
            .pipe(map((response) => response.body));
    }

    public getBankStatementEntriesOnStatement(bankstatementID: number) {
        return this.http
            .asGET()
            .usingStatisticsDomain()
            .withEndPoint(
                `?model=BankStatementEntry&select=AmountCurrency as AmountCurrency,BookingDate as BookingDate,` +
                    `CurrencyCode as CurrencyCode,Description as Description,ID as ID,OpenAmountCurrency as OpenAmountCurrency,` +
                    `StatusCode as StatusCode&filter=BankStatementID eq ${bankstatementID}`,
            )
            .send()
            .pipe(
                map((response) => response.body),
                map((response) => response.Data),
            );
    }

    public createInitialAgreement(payload: any, companykey?: string) {
        if (companykey) {
            this.http.withHeader('CompanyKey', companykey);
        }

        return this.http
            .asPOST()
            .usingBusinessDomain()
            .withEndPoint('/bank-agreements?action=create-initial-company-and-bank-accounts-agreement')
            .withBody(payload)
            .send({}, null, !companykey?.length)
            .pipe(map((response) => response.body));
    }

    public createInitialFileAgreement(companykey?: string) {
        if (companykey) {
            this.http.withHeader('CompanyKey', companykey);
        }

        const agreementDetails = {
            DisplayName: 'Bruno Autobank',
            Phone: '',
            Email: '',
            Bank: '',
            Orgnr: '',
            BankAccountID: 0,
            BankAcceptance: true,
            IsInbound: true,
            IsOutgoing: true,
            IsBankBalance: true,
            BankApproval: true,
            IsBankStatement: true,
            Password: '',
            _confirmPassword: '',
            BankAccountNumber: 0,
            ServiceProvider: BankAgreementServiceProvider.Bruno,
        };

        return this.http
            .asPOST()
            .usingBusinessDomain()
            .withEndPoint('/bank-agreements?action=create-initial-company-and-bank-accounts-agreement')
            .withBody(agreementDetails)
            .send({}, null, !companykey?.length)
            .pipe(map((response) => response.body));
    }

    public getAndStoreBankAccounts() {
        return this.http
            .asPOST()
            .usingBusinessDomain()
            .withEndPoint('/bankaccounts?action=autoprovision')
            .send()
            .pipe(map((response) => response.body));
    }

    public orderPreApprovedBankPayments(bankID: number, cancel: boolean) {
        return this.http
            .asPOST()
            .usingBusinessDomain()
            .withEndPoint(
                '/bank-agreements?action=order-preapprovedbankpayments&bankID=' + bankID + '&cancel=' + cancel,
            )
            .send()
            .pipe(map((response) => response.body));
    }

    public getDefaultServiceProvider() {
        return this.http
            .asGET()
            .usingBusinessDomain()
            .withEndPoint('/bank-agreements?action=get-default-service-provider')
            .send()
            .pipe(map((response) => response.body));
    }

    public updateBankIntegrationAgreement(agreementID: number, agreement: any) {
        return this.http
            .asPUT()
            .usingBusinessDomain()
            .withEndPoint(`bank-agreements/${agreementID}`)
            .withBody(agreement)
            .send()
            .pipe(map((response) => response.body));
    }

    updateBankIntegrationAgreementStatusCode(body: {
        ServiceID: string;
        StatusCode: StatusCodeBankIntegrationAgreement;
    }) {
        return this.http
            .asPUT()
            .usingBusinessDomain()
            .withEndPoint(`bank-agreements?action=update-service`)
            .withBody(body)
            .send()
            .pipe(map((response) => response.body));
    }

    deleteAgreement(agreement: BankIntegrationAgreement) {
        return this.http
            .asDELETE()
            .usingBusinessDomain()
            .withEndPoint('/bank-agreements/' + agreement.ID)
            .send();
    }

    cancelBankAccountIntegration(bankAccountId: number, intergrationSettings: number, emailForReceipt: string) {
        return this.http
            .asPUT()
            .usingBusinessDomain()
            .withEndPoint(
                `/bank-agreements?action=delete-bankagreements&` +
                    `bankAccountID=${bankAccountId}&integrationSettings=${intergrationSettings}&emailAddress=${emailForReceipt}`,
            )
            .send()
            .pipe(map((response) => response.body));
    }

    cancelAllBankAccountIntegrations(emailForReceipt: string) {
        return this.http
            .asPUT()
            .usingBusinessDomain()
            .withEndPoint(`/bank-agreements?action=delete-all-bankagreements&emailAddress=${emailForReceipt}`)
            .send()
            .pipe(map((response) => response.body));
    }
}
