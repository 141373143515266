import { Component, Input } from '@angular/core';

@Component({
    selector: 'uni-help',
    templateUrl: './help.component.html',
    styleUrls: ['./help.component.sass'],
})
export class UniHelpComponent {
    @Input() title: string;
    @Input() helpText: string;

    showHelpText: boolean = false;

    constructor() {}

    toggleHelpText(event) {
        this.showHelpText = !this.showHelpText;
    }
}
