import { Component, Input, Output, EventEmitter, ViewChild, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime, take, tap, catchError, switchMap, map } from 'rxjs/operators';
import { Subscription, BehaviorSubject, Observable, of, forkJoin } from 'rxjs';
import { KeyCodes } from '@app/services/common/keyCodes';
import { get } from 'lodash-es';
import { MODAL_VISIBLE } from '@uni-framework/uni-modal/modal-visible';
import { ErrorService } from '@app/services/common/errorService';

export type ItemGroup = {
    label: string;
    lookup: (query: string, filterCheckboxValues?: boolean[]) => any[] | Observable<any[]>;
};

export interface GroupedAutocompleteOptions {
    displayFunction: (item) => string;
    optionGroups: ItemGroup[];
    avatarName: (item) => string;
    placeholder?: string;
    showClearButton?: boolean;
    canClearValue?: boolean;
    clearInputOnSelect?: boolean;
    autofocus?: boolean;
    openSearchOnClick?: boolean;
    debounceTime?: number;
    editHandler?: (item) => Observable<any>;
    createHandler?: (value?) => Observable<any>;
    createLabel?: string;
    showFilterCheckboxes?: boolean;
    filterCheckboxes?: {
        label: string;
        value: boolean;
    }[];
    experimentalMultiSelect?: boolean;
}

@Component({
    selector: 'grouped-autocomplete',
    templateUrl: './grouped-autocomplete.html',
    styleUrls: ['./grouped-autocomplete.sass'],
})
export class GroupedAutocomplete {
    @ViewChild('inputElement', { static: true }) inputElement: ElementRef<HTMLInputElement>;
    @ViewChildren('optionGroup') groups: QueryList<any>;

    @Input() readonly: boolean;
    @Input() options: GroupedAutocompleteOptions;
    @Input() value: any;
    @Input() initSearchValue: string;
    @Input() includeAvatar: boolean = false;
    @Output() valueChange = new EventEmitter();
    @Output() onClose = new EventEmitter();

    searchControl = new UntypedFormControl('');
    controlSubscription: Subscription;

    lookupResults: any[] = [];
    focusIndex = -1;
    focusGroupIndex = 0;
    isExpanded$ = new BehaviorSubject<boolean>(false);
    loading$ = new BehaviorSubject<boolean>(false);

    selectedItems = [];
    isAllSelected: boolean;

    constructor(private errorService: ErrorService) {}

    ngOnInit() {
        this.controlSubscription = this.searchControl.valueChanges
            .pipe(
                tap(() => {
                    this.loading$.next(true);
                    this.isExpanded$.next(true);
                }),
                debounceTime(this.options?.debounceTime ?? 200),
                switchMap((query: string) =>
                    forkJoin(this.options.optionGroups.map((group) => this.lookupFunction(group.lookup, query))).pipe(
                        map((items) => ({ query, items })),
                    ),
                ),
            )
            .subscribe(({ query, items }) => {
                this.focusIndex = query && items.length ? 0 : -1;
                this.setLookupResults(items);
                this.loading$.next(false);
            });

        if (this.initSearchValue) {
            this.searchControl.setValue(this.initSearchValue);
        }
    }

    ngOnDestroy() {
        this.controlSubscription.unsubscribe();
        this.isExpanded$.complete();
        this.loading$.complete();
    }

    ngOnChanges(changes) {
        if (changes['value']) {
            this.updateControlValue();
        }

        if (changes['options'] && this.options?.autofocus && !MODAL_VISIBLE) {
            setTimeout(() => this.focus());
        }
    }

    onItemClick(item) {
        if (this.options?.experimentalMultiSelect) {
            this.toggleItemSelected(item);
            this.isAllSelected = !this.lookupResults.some((x) => !x['_selected']);
            this.inputElement?.nativeElement?.focus();
        } else {
            this.select(item, true);
        }
    }

    selectAll() {
        this.isAllSelected = !this.isAllSelected;
        this.lookupResults = this.lookupResults.map((x) => {
            x['_selected'] = this.isAllSelected;
            return x;
        });
        this.selectedItems = [...this.lookupResults];
    }

    private toggleItemSelected(item) {
        if (item['_selected']) {
            item['_selected'] = false;
            this.selectedItems = this.selectedItems.filter((i) => i.ID !== item.ID);
        } else {
            item['_selected'] = true;
            this.selectedItems.push(item);
        }
        const index = this.lookupResults.findIndex((r) => r.ID === item.ID);
        if (index >= 0) {
            this.lookupResults[index] = { ...item };
        }
    }

    performLookup(query: string) {
        this.loading$.next(true);
        this.isExpanded$.next(true);
        forkJoin(this.options.optionGroups.map((group) => this.lookupFunction(group.lookup, query))).subscribe(
            (items) => {
                this.focusIndex = query && items.length ? 0 : -1;
                this.setLookupResults(items);
                this.loading$.next(false);
            },
        );
    }

    private setLookupResults(items) {
        if (this.options?.experimentalMultiSelect) {
            this.lookupResults = items.map((item) => {
                item['_selected'] = this.selectedItems.some((i) => i.ID === item.ID);
                return item;
            });
            this.selectedItems = this.selectedItems.filter((selectedItem) =>
                items.some((i) => i.ID === selectedItem.ID),
            );
        } else {
            this.lookupResults = [];

            items.forEach((item, index) => {
                if (item.length) {
                    this.lookupResults.push({ label: this.options.optionGroups[index].label, items: item });
                }
            });
        }
    }

    private lookupFunction(lookup, query: string): Observable<any[]> {
        const filterCheckboxValues = this.options.filterCheckboxes?.map((checkbox) => checkbox.value);

        const lookupResult = lookup(query, filterCheckboxValues) || [];
        const res$ = Array.isArray(lookupResult) ? of(lookupResult) : lookupResult;
        return res$.pipe(
            take(1),
            catchError((err) => {
                this.errorService.handle(err);
                return of([]);
            }),
        );
    }

    focus() {
        if (this.inputElement && this.inputElement.nativeElement) {
            this.inputElement.nativeElement.focus();
        }
    }

    onClick() {
        if (this.options.openSearchOnClick) {
            this.toggle();
        }
    }

    onClearButtonClick(event: MouseEvent) {
        event.stopPropagation();
        event.preventDefault();
        this.select(null);
    }

    toggle() {
        this.isExpanded$.next(!this.isExpanded$.value);
        if (this.isExpanded$.value) {
            this.performLookup('');
        }
    }

    close() {
        if (this.options?.experimentalMultiSelect) {
            this.valueChange.emit(this.selectedItems);
            this.isAllSelected = false;
            this.lookupResults = this.lookupResults.map((x) => (x['_selected'] = false));
        }

        this.isExpanded$.next(false);
        this.selectedItems = [];
        this.updateControlValue();
        this.onClose.emit();
    }

    getDisplayValue(item) {
        if (this.options.displayFunction) {
            return this.options.displayFunction(item);
        }
    }

    getAvatarName(item) {
        if (this.options.avatarName) {
            return this.options.avatarName(item);
        }
    }

    getResultCellValue(item, col) {
        return col.template ? col.template(item) : get(item, col.field);
    }

    private updateControlValue() {
        if (this.options.clearInputOnSelect) {
            this.searchControl.setValue('', { emitEvent: false });
            return;
        }

        let controlValue = '';
        if (this.value) {
            if (this.options.displayFunction) {
                controlValue = this.options.displayFunction(this.value);
            }
        }

        this.searchControl.setValue(controlValue, { emitEvent: false });
    }

    select(value, refocus?: boolean) {
        // Check specifically for false so canClearValue defaults to true
        if (value || this.options.canClearValue !== false) {
            this.value = value || null;
            this.valueChange.emit(this.value);
        }

        this.focusIndex = -1;
        this.updateControlValue();
        this.isExpanded$.next(false);
        if (refocus) {
            try {
                this.inputElement.nativeElement.focus();
            } catch (e) {}
        }
    }

    onF3Key(event: KeyboardEvent) {
        event.preventDefault();
        this.create();
    }

    create() {
        if (this.options && this.options.createHandler) {
            this.options.createHandler(this.searchControl.value).subscribe((newEntity) => {
                if (newEntity) {
                    this.select(newEntity);
                }
            });

            this.isExpanded$.next(false);
        }
    }

    edit(item) {
        if (this.options && this.options.editHandler && item.EmployeeNumber) {
            this.options.editHandler(item).subscribe((newEntity) => {
                if (newEntity) {
                    this.select(newEntity);
                }
            });

            this.isExpanded$.next(false);
        }
    }

    onFocus() {
        this.inputElement?.nativeElement?.select();
    }

    onClickOutside() {
        if (this.isExpanded$.value) {
            const shouldClearValue =
                this.options.canClearValue !== false && this.searchControl.dirty && !this.searchControl.value;

            if (shouldClearValue && !this.options?.experimentalMultiSelect) {
                this.select(null, false);
            } else {
                this.close();
            }
        }
    }

    onKeyDown(event: KeyboardEvent) {
        const key = event.which || event.keyCode;

        switch (key) {
            case KeyCodes.DOWN_ARROW:
                event.preventDefault();
                if (this.isExpanded$.value) {
                    if (this.focusIndex < this.lookupResults[this.focusGroupIndex].items.length - 1) {
                        this.focusIndex++;
                        this.scrollToListItem();
                    } else if (this.focusGroupIndex < this.lookupResults.length - 1) {
                        this.focusGroupIndex++;
                        this.focusIndex = 0;
                        this.scrollToListItem();
                    }
                } else {
                    this.performLookup('');
                }
                break;
            case KeyCodes.UP_ARROW:
                event.preventDefault();
                if (this.focusIndex > 0 || (this.options?.experimentalMultiSelect && this.focusIndex > -1)) {
                    this.focusIndex--;
                    this.scrollToListItem();
                } else if (this.focusGroupIndex > 0 || (this.options?.experimentalMultiSelect && this.focusIndex > 0)) {
                    this.focusGroupIndex--;
                    this.focusIndex = this.lookupResults[this.focusGroupIndex].items.length - 1;
                }
                break;
            case KeyCodes.ENTER:
                if (!this.isExpanded$.value) {
                    return;
                }

                if (this.options.experimentalMultiSelect) {
                    if (this.focusGroupIndex >= 0) {
                        this.onItemClick(this.lookupResults[this.focusGroupIndex].items[this.focusIndex]);
                    } else if (this.focusGroupIndex === -1) {
                        this.selectAll();
                    }
                } else {
                    this.handleTabOrEnter(key);
                }
                break;
            case KeyCodes.TAB:
                if (!this.isExpanded$.value) {
                    return;
                }

                if (this.options?.experimentalMultiSelect) {
                    this.valueChange.emit(this.selectedItems);
                    this.selectedItems = [];
                    this.isExpanded$.next(false);
                } else {
                    this.handleTabOrEnter(key);
                }
                break;
            case KeyCodes.ESCAPE:
                if (this.isExpanded$.value) {
                    event.stopPropagation();
                    this.close();
                }
                break;
            case KeyCodes.SPACE:
                if (!this.searchControl.value) {
                    event.preventDefault();
                    this.performLookup('');
                }
                break;
        }
    }

    private handleTabOrEnter(key: number) {
        if (this.loading$.value && this.searchControl.value) {
            // User tried selecting while the search was still in progress
            this.selectBestMatch(this.searchControl.value);
            this.isExpanded$.next(false);
        } else {
            if (this.focusGroupIndex >= 0 && this.focusIndex >= 0) {
                this.select(this.lookupResults[this.focusGroupIndex].items[this.focusIndex], key === KeyCodes.ENTER);
            } else {
                const shouldClearValue =
                    this.options.canClearValue !== false && this.searchControl.dirty && !this.searchControl.value;

                if (shouldClearValue) {
                    this.select(null, false);
                } else {
                    this.updateControlValue();
                    this.isExpanded$.next(false);
                }
            }
        }
    }

    scrollToListItem() {
        if (this.focusIndex < 0) {
            return;
        }
        try {
            const items: HTMLElement[] = this.groups.toArray()[this.focusGroupIndex].nativeElement.children;

            if (this.focusGroupIndex === 0 && this.focusIndex === 0) {
                const row = items[0];
                row.scrollIntoView({ block: 'start' });
                return;
            }

            const row = items[this.focusIndex + 1];
            row.scrollIntoView({ block: 'nearest' });
        } catch (e) {
            console.error(e);
        }
    }

    private selectBestMatch(query: string) {
        forkJoin(
            this.options.optionGroups.map((group) =>
                this.lookupFunction(group.lookup, query).subscribe((items) => {
                    let bestMatch;
                    let bestMatchCount = 0;
                    (items || []).forEach((item) => {
                        const displayValue = this.getDisplayValue(item);
                        if (displayValue) {
                            const matchCount = this.getNumberOfMatchingCharacters(displayValue, query);
                            if (matchCount > bestMatchCount) {
                                bestMatch = item;
                                bestMatchCount = matchCount;
                            }
                        }
                    });

                    if (bestMatch) {
                        this.select(bestMatch, false);
                    } else if (items.length) {
                        this.select(items[0], false);
                    } else {
                        this.updateControlValue();
                    }
                }),
            ),
        );
    }

    private getNumberOfMatchingCharacters(s1: string, s2: string) {
        s1 = s1?.toLowerCase();
        s2 = s2?.toLowerCase();

        const startIndex = s1.indexOf(s2);
        if (startIndex >= 0) {
            s1 = s1.slice(startIndex);
            for (let i = 0; i < s2.length; i++) {
                if (s1[i] !== s2[i]) {
                    return i;
                }
            }

            return s2.length;
        } else {
            return 0;
        }
    }
}
