<section role="dialog" class="uni-modal small">
    <header>Nytt prosjekt</header>

    <article>
        <section *ngIf="busy" class="modal-spinner">
            <mat-spinner class="c2a"></mat-spinner>
        </section>

        <uni-form [config]="{ showLabelAbove: true, autoFocus: true }" [fields]="formFields$" [model]="formModel$">
        </uni-form>
        <small *ngIf="errorMessage"> {{ errorMessage }} </small>
    </article>

    <footer>
        <button class="secondary" (click)="close()">Avbryt</button>
        <button class="c2a" (click)="save()">Lagre</button>
    </footer>
</section>
