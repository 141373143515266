import { Component, Input, Output, EventEmitter, HostBinding, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { IStatus, STATUSTRACK_STATES } from '../statustrack';
import { rigDate } from '@app/components/common/utils/rig-date';
import { ErrorService } from '@app/services/common/errorService';
import { StatusHistoryItem, StatusService } from '@app/services/common/statusService';

@Component({
    selector: 'statustrack-status',
    templateUrl: './statustrack-status.html',
    styleUrls: ['./statustrack-status.sass'],
})
export class StatustrackStatus {
    @Input() status: IStatus;
    @Input() singleStatus: boolean;
    @Input() entityType: string;
    @Input() entityID: number;
    @Output() subStatusClick = new EventEmitter();

    @HostBinding('class') get hostClass() {
        if (this.status) {
            return this.status.class || this.status.state;
        }
    }

    STATES = STATUSTRACK_STATES;
    isActive: boolean;
    hasDetailsSection: boolean;
    classString: string;

    hasSubStatuses: boolean;
    showStatusHistory: boolean;

    statusHistoryLoaded: boolean;
    statusHistory: StatusHistoryItem[];

    constructor(
        private statusService: StatusService,
        private errorService: ErrorService,
        private router: Router,
        private cdr: ChangeDetectorRef,
    ) {}

    ngOnChanges(changes) {
        if (changes['status'] && this.status) {
            this.isActive = this.status.state === STATUSTRACK_STATES.Active;
        }

        this.hasSubStatuses = this.status.substatusList && this.status.substatusList.length > 0;
        this.showStatusHistory = (this.singleStatus || this.isActive) && !!this.entityType && !!this.entityID;

        const classes = [];
        if (this.status && (this.status.class || this.status.state)) {
            classes.push(this.status.class || this.status.state);
        }

        if (this.hasSubStatuses) {
            classes.push('has-substatus');
        }

        if (this.singleStatus) {
            classes.push('single-status');
        }

        this.classString = classes.join(' ');
    }

    formatTime(datetime, formatDateTime?: string) {
        if (datetime) {
            return rigDate(datetime).format(formatDateTime ? formatDateTime : 'lll');
        }
    }

    loadStatusHistory() {
        if (this.entityType && this.entityID && !this.statusHistoryLoaded) {
            this.statusService.getStatusLogEntries(this.entityType, this.entityID).subscribe(
                (statuschanges) => {
                    this.statusHistoryLoaded = true;
                    this.statusHistory = statuschanges;
                    this.cdr.markForCheck();
                },
                (err) => this.errorService.handle(err),
            );
        }
    }

    goTojournalEntry(payment) {
        const journalEntryData = payment.JournalEntryNumber.split('-');

        if (journalEntryData.length === 2) {
            this.router.navigateByUrl(
                `/accounting/transquery?JournalEntryNumber=${journalEntryData[0]}&AccountYear=${journalEntryData[1]}`,
            );
        } else {
            this.router.navigateByUrl(`/accounting/transquery?JournalEntryNumber=${journalEntryData[0]}`);
        }
    }
}
