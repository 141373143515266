import {
    Component,
    ComponentFactoryResolver,
    ViewChild,
    ViewContainerRef,
    Type,
    ComponentRef,
    ElementRef,
    EmbeddedViewRef,
} from '@angular/core';
import { IUniModal, IModalOptions } from './interfaces';
import { Observable } from 'rxjs';
import { CdkDragEnd } from '@angular/cdk/drag-drop';
import { UniModalService } from './modalService';

@Component({
    selector: 'modal-wrapper',
    template: `
        <section
            tabindex="-1"
            class="modal-wrapper"
            #wrapper
            cdkTrapFocus
            cdkDrag
            [cdkDragDisabled]="!dragAndDropEnabled"
            (cdkDragEnded)="onDragEnd($event)"
        >
            <section [class.hidden]="!dragAndDropEnabled" #dragHandle cdkDragHandle class="modal-drag-handle"></section>
            <ng-container #modalSlot></ng-container>
        </section>
    `,
})
export class ModalWrapper {
    @ViewChild('modalSlot', { static: true, read: ViewContainerRef }) modalSlot: ViewContainerRef;
    @ViewChild('dragHandle', { static: true }) dragHandle: ElementRef<HTMLElement>;
    @ViewChild('wrapper') modalWrapper: ElementRef<HTMLElement>;

    componentRef: ComponentRef<IUniModal>;
    intersectionObserver: IntersectionObserver;
    dragHandleIsOnScreen = true;
    dragAndDropEnabled: boolean;

    previouslyFocusedElement: HTMLElement;

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private elementRef: ElementRef,
    ) {
        this.previouslyFocusedElement = this.getCurrentActiveElement();
    }

    ngAfterViewInit() {
        setTimeout(() => {
            // Set focus to wrapper element if focus is currently outside the dialog
            const wrapperElement = this.modalWrapper?.nativeElement;
            if (wrapperElement && !wrapperElement.contains(document.activeElement)) {
                wrapperElement.focus();
            }
        });
    }

    ngOnDestroy() {
        this.componentRef?.destroy();
        this.intersectionObserver?.disconnect();
        this.restoreFocus();
    }

    onDragEnd(event: CdkDragEnd) {
        if (!this.dragHandleIsOnScreen) {
            event.source.reset();
        }
    }

    loadModal(args: {
        component: Type<IUniModal>;
        options: IModalOptions;
        onClose: () => void;
        modalService: UniModalService;
    }) {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(args.component);

        this.modalSlot.clear();
        this.componentRef = this.modalSlot.createComponent(componentFactory);

        this.componentRef.instance['options'] = args.options || {};
        this.componentRef.instance['modalService'] = args.modalService;
        this.componentRef.instance.onClose.subscribe(() => {
            setTimeout(() => {
                this.componentRef.instance.onClose.complete();
            });

            this.componentRef.destroy();
            args.onClose();
        });

        const modalRootNode = (this.componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
        const dialogElement = modalRootNode.querySelector('.uni-modal');
        if (dialogElement) {
            if (args.options.size) {
                dialogElement.classList.add(args.options.size);
            }
            dialogElement.setAttribute('open', 'true');

            // Add close button
            if (!args.options?.hideCloseButton && !dialogElement.querySelector('.close-button')) {
                const closeBtn = document.createElement('i');
                closeBtn.classList.add('material-icons', 'close-button');
                closeBtn.setAttribute('role', 'button');
                closeBtn.innerText = 'close';
                closeBtn.onclick = () => this.close();

                const header = dialogElement.querySelector('header');
                if (header) {
                    header.appendChild(closeBtn);
                } else {
                    closeBtn.classList.add('btn-outside-header');
                    dialogElement.appendChild(closeBtn);
                }
            }

            // Add classes from options
            if (args.options?.class) {
                dialogElement.classList.add(...args.options.class.split(' '));
            }
        }

        if (!args.options?.disableDragAndDrop) {
            this.dragAndDropEnabled = true;
            this.watchDragHandleVisibility();
        }

        return this.componentRef;
    }

    close() {
        const modal = this.componentRef?.instance;
        const options = modal?.options || {};

        const closeFn = () => {
            if (modal.forceCloseValueResolver) {
                modal.onClose.emit(modal.forceCloseValueResolver());
            } else if (options.hasOwnProperty('cancelValue')) {
                modal.onClose.emit(options.cancelValue);
            } else {
                modal.onClose.emit(null);
            }
        };

        if (modal.canDeactivate) {
            const canClose = modal.canDeactivate();
            if (canClose instanceof Observable) {
                canClose.subscribe((allowed) => {
                    if (allowed) {
                        closeFn();
                    }
                });
            } else if (canClose) {
                closeFn();
            }
        } else {
            closeFn();
        }
    }

    private watchDragHandleVisibility() {
        this.intersectionObserver = new IntersectionObserver((entries) => {
            if (entries && entries[0]) {
                this.dragHandleIsOnScreen = entries[0].isIntersecting;
            }
        });

        this.intersectionObserver.observe(this.dragHandle.nativeElement);
    }

    private getCurrentActiveElement(): HTMLElement {
        let activeElement = document.activeElement as HTMLElement;

        while (activeElement && activeElement.shadowRoot) {
            const activeElementInShadowDom = activeElement.shadowRoot.activeElement as HTMLElement;
            if (activeElementInShadowDom === activeElement) {
                break;
            } else {
                activeElement = activeElementInShadowDom;
            }
        }

        return activeElement;
    }

    private restoreFocus() {
        const previousElement = this.previouslyFocusedElement;

        if (previousElement && typeof previousElement.focus === 'function') {
            previousElement.focus();
        }
    }
}
