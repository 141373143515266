import { Component, Input, Output, EventEmitter, ViewChild, SimpleChanges } from '@angular/core';
import { BaseControl } from '@uni-framework/ui/uniform/controls/baseControl';
import { UniFieldLayout } from '@uni-framework/ui/uniform/interfaces/uni-field-layout.interface';
import { get, set } from 'lodash-es';
import { LocalDate } from '@uni-entities';
import { MonthPicker } from '@uni-framework/ui/month-picker/month-picker';
import { rigDate } from '@app/components/common/utils/rig-date';

@Component({
    selector: 'month-picker-input',
    template: `
        <month-picker
            style="width: 100%"
            [readonly]="field?.ReadOnly"
            [value]="currentDate"
            (valueChange)="onChange($event)"
        >
        </month-picker>
    `,
})
export class MonthPickerInput extends BaseControl {
    @ViewChild(MonthPicker) monthPicker: MonthPicker;

    @Input() field: UniFieldLayout;
    @Input() model: any;
    @Input() asideGuid: string;

    @Output() readyEvent = new EventEmitter<MonthPickerInput>();
    @Output() changeEvent = new EventEmitter<SimpleChanges>();
    @Output() inputEvent = new EventEmitter<SimpleChanges>();
    @Output() focusEvent = new EventEmitter<MonthPickerInput>();

    options: any;
    previousValue: LocalDate;
    currentDate: Date;

    ngAfterViewInit() {
        this.readyEvent.emit(this);
    }

    ngOnChanges() {
        if (this.field) {
            this.options = this.field.Options || {};
            this.createControl();

            let value = get(this.model, this.field.Property, '');

            if (!(value instanceof LocalDate)) {
                value = value ? new LocalDate(value) : null;
            }

            this.previousValue = value;
            this.currentDate = value?.toDate();
        }
    }

    onChange(date: Date) {
        if (date && this.options?.startOfMonth) {
            date = rigDate(date).startOf('month').toDate();
        }

        if (date && this.options?.endOfMonth) {
            date = rigDate(date).endOf('month').toDate();
        }

        const value = date ? new LocalDate(date) : null;

        set(this.model, this.field.Property, value);
        this.emitChange(this.previousValue, value);
        this.emitInstantChange(this.previousValue, value);
    }

    focus() {
        this.monthPicker?.focus();
    }
}
