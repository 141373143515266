import { Injectable, ComponentRef, Type, inject } from '@angular/core';
import { ComponentPortal } from '@angular/cdk/portal';
import { Overlay } from '@angular/cdk/overlay';
import { ConfirmModal } from './modals/confirm/confirm-modal';
import { InputModal, InputModalOptions } from './modals/input-modal';
import { ConfirmActions, ConfirmModalOptions, IModalOptions, IUniModal } from '@uni-framework/uni-modal/interfaces';
import { CommentModalComponent } from '@uni-framework/uni-modal/modals/comment-modal/comment-modal.component';
import { ModalWrapper } from './modal-wrapper';
import { Subject } from 'rxjs';
import { AuthService } from '@app/authService';
import { takeUntil } from 'rxjs/operators';
import { setModalVisible } from './modal-visible';
import { AnalyticsEvent, AnalyticsService } from '@app/services/analytics-services/analytics.service';

@Injectable()
export class UniModalService {
    private nextModalID = 1;
    private openModalRefs: { id: number; wrapper: ModalWrapper }[] = [];
    private onDestroy$: Subject<boolean> = new Subject();

    analytics = inject(AnalyticsService);

    constructor(
        private overlay: Overlay,
        private authService: AuthService,
    ) {
        this.authService.token$.pipe(takeUntil(this.onDestroy$)).subscribe((authenticated) => {
            if (!authenticated && this.openModalRefs?.length) {
                this.closeAllOpenModals();
            }
        });
    }

    ngOnDestroy() {
        this.onDestroy$.next(undefined);
        this.onDestroy$.complete();
    }

    open(modal: Type<IUniModal>, options: IModalOptions = {}): IUniModal {
        const componentRef = this.createModal(modal, options);
        this.analytics.trackModalEvent(
            AnalyticsEvent.MODAL_OPENED_EVENT,
            componentRef.location.nativeElement['tagName'],
        );
        return componentRef.instance;
    }

    hasOpenModals() {
        return this.openModalRefs?.length;
    }

    closeAllOpenModals() {
        setTimeout(() => {
            try {
                this.openModalRefs?.forEach((ref) => ref.wrapper?.close());
            } catch (e) {
                console.error(e);
            }

            this.openModalRefs = [];
        });
    }

    public openRejectChangesModal(): IUniModal {
        return this.confirm({
            header: 'Ulagrede endringer',
            message: 'Du har ulagrede endringer. Forkaste?',
            buttonLabels: {
                reject: 'Forkast',
                cancel: 'Avbryt',
            },
            closeOnClickOutside: false,
            closeOnEscape: false,
        });
    }

    public openUnsavedChangesModal(acceptButtonText: string = 'Lagre'): IUniModal {
        return this.confirm({
            header: 'Ulagrede endringer',
            message: 'Du har ulagrede endringer. Ønsker du å lagre?',
            buttonLabels: {
                accept: acceptButtonText,
                reject: 'Forkast',
                cancel: 'Avbryt',
            },
            closeOnClickOutside: false,
            closeOnEscape: false,
        });
    }

    public confirm(options: ConfirmModalOptions = {}) {
        if (!options.hasOwnProperty('cancelValue')) {
            options.cancelValue = ConfirmActions.CANCEL;
        }

        options.closeOnClickOutside = options.closeOnClickOutside || false;
        options.closeOnEscape = options.closeOnEscape || false;

        const componentRef = this.createModal(ConfirmModal, options);
        return componentRef.instance;
    }

    showInputModal(options: InputModalOptions) {
        return this.createModal(InputModal, options).instance;
    }

    public openCommentModal(options: IModalOptions) {
        return this.open(CommentModalComponent, options);
    }

    private createModal(modal: Type<IUniModal>, options: IModalOptions): ComponentRef<IUniModal> {
        const position = this.overlay.position().global().centerHorizontally().centerVertically();

        const overlayRef = this.overlay.create({
            positionStrategy: position,
            hasBackdrop: true,
            backdropClass: 'uni-modal-backdrop',
            scrollStrategy: this.overlay.scrollStrategies.block(),
        });

        const portal = new ComponentPortal(ModalWrapper);
        const modalWrapper = overlayRef.attach(portal);

        const modalID = this.nextModalID++;
        this.openModalRefs.push({
            id: modalID,
            wrapper: modalWrapper.instance,
        });

        setModalVisible(true);

        const modalRef = modalWrapper.instance.loadModal({
            component: modal,
            options: options,
            modalService: this,
            onClose: () => {
                modalWrapper?.destroy();

                if (portal.isAttached) {
                    portal?.detach();
                }

                overlayRef?.detach();
                overlayRef?.dispose();

                this.openModalRefs = this.openModalRefs.filter((x) => x.id !== modalID);
                setModalVisible(!this.openModalRefs?.length);
            },
        });

        if (options?.closeOnEscape !== false) {
            overlayRef.keydownEvents().subscribe((event: KeyboardEvent) => {
                if (event.key === 'Escape') {
                    modalWrapper.instance.close();
                }
            });
        }

        if (options?.closeOnClickOutside !== false) {
            overlayRef.backdropClick().subscribe(() => {
                // Don't close the dialog if there is an open input dropdown (e.g autocomplete search result).
                // Instead allow the click event to close the dropdown, and only close the dialog if the user clicks again.
                const inputDropdownOpen = !!document.querySelector('.cdk-overlay-container input-dropdown-menu');
                if (!inputDropdownOpen) {
                    modalWrapper.instance.close();
                }
            });
        }

        return modalRef;
    }
}
