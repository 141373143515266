import { Component, EventEmitter } from '@angular/core';
import { IModalOptions } from '@uni-framework/uni-modal/interfaces';
import { BankIntegrationAgreement } from '@uni-entities';
import { AuthService } from '@app/authService';

@Component({
    selector: 'bruno-bank-onboarding-modal',
    templateUrl: './bruno-bank-onboarding-modal.html',
    styleUrls: ['./bruno-bank-onboarding-modal.sass'],
})
export class BrunoBankOnboardingModal {
    options: IModalOptions = {};
    onClose = new EventEmitter();
    agreement: BankIntegrationAgreement;

    constructor(private authService: AuthService) {}

    ngOnInit() {
        this.agreement = this?.options?.data;
    }

    openExternalOnboarding() {
        let url =
            'https://www.dnb.no/bedrift/dagligbank/betaling/tjenestebutikken?erp=DNBRegnskap&kontoinfoval=true&utbetalingerval=true';
        window.open(url, '_blank');

        this.onClose.emit(this.agreement || true);
    }
}
