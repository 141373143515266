<article class="collapsable" [ngClass]="{ '-is-open': isOpen }">
    <h4 *ngIf="config?.Sectionheader" (click)="toggle()">{{ config?.Sectionheader }}</h4>
    <div class="collapsable-content">
        <!-- Not Fieldset -->
        <ng-template ngFor let-item [ngForOf]="groupedFields | noFieldset" let-i="index">
            <uni-field
                *ngIf="!item?.Hidden"
                [field]="item"
                [model]="model"
                [formConfig]="formConfig"
                (readyEvent)="onReadyHandler($event)"
                (changeEvent)="onChangeHandler($event)"
                (inputEvent)="onInputHandler($event)"
                (focusEvent)="onFocusHandler($event)"
                (moveForwardEvent)="moveForwardEvent.emit($event)"
                (errorEvent)="onError($event)"
            >
            </uni-field>
        </ng-template>
        <!-- Fieldset -->
        <ng-template ngFor let-fieldset [ngForOf]="groupedFields | fieldsetIndexes" let-i="index">
            <fieldset [ngClass]="{ small: (groupedFields | fieldsetIndexes).length > 1 }">
                <legend *ngIf="groupedFields | getLegend: fieldset">{{ groupedFields | getLegend: fieldset }}</legend>
                <section class="fieldset-column-container">
                    <ng-template ngFor let-column [ngForOf]="groupedFields | columnIndexes: fieldset" let-j="index2">
                        <section class="fieldset-column">
                            <ng-template
                                ngFor
                                let-item
                                [ngForOf]="groupedFields | byFieldset: fieldset : column"
                                let-k="index3"
                            >
                                <uni-field
                                    *ngIf="!item?.Hidden"
                                    [field]="item"
                                    [model]="model"
                                    [formConfig]="formConfig"
                                    (readyEvent)="onReadyHandler($event)"
                                    (changeEvent)="onChangeHandler($event)"
                                    (inputEvent)="onInputHandler($event)"
                                    (focusEvent)="onFocusHandler($event)"
                                    (moveForwardEvent)="moveForwardEvent.emit($event)"
                                    (errorEvent)="onError($event)"
                                >
                                </uni-field>
                            </ng-template>
                        </section>
                    </ng-template>
                </section>
            </fieldset>
        </ng-template>
    </div>
</article>
