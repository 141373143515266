import { Component, ChangeDetectionStrategy, ChangeDetectorRef, Input } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ErrorService } from '@app/services/common/errorService';
import { IllustrationName } from '../design-system/illustration/illustration';

export enum SummaryTileType {
    MONEY = 1,
    DATE = 2,
    TEXT = 3,
    BUTTON = 4,
    DROPDOWN = 5,
    NUMBER = 6,
}

export interface SummaryShortCut {
    label: string;
    title?: string;
    link?: string;
    params?: any;
    target?: string;
    class?: string;
    icon?: string;
    onClick?: (item?) => any;
    dropdownOptions?: any[];
}

export interface SummaryOptions {
    lookupFunction?: (params?: HttpParams) => Observable<any>;
    data?: any;
    tiles: SummaryTile[];
    shortcuts?: SummaryShortCut[];
    containerClass?: string;
    hideOnNoData?: boolean;
}

export interface SummaryTile {
    field: string;
    header?: string;
    title?: string;
    buttonText?: string;
    type?: SummaryTileType;
    onClick?: (item?) => any;
    class?: string;
    icon?: string;
    options?: any;
    suffix?: string;
}

@Component({
    selector: 'summary-card',
    templateUrl: './summary-component.html',
    styleUrls: ['./summary-component.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SummaryComponent {
    @Input() options: SummaryOptions;
    @Input() transparent: boolean = false;
    @Input() illustration: IllustrationName;

    @Input()
    busy: boolean;

    @Input()
    height: string = '10rem';

    tiles: SummaryTile[] = [];
    data: any = {};
    missingDataSource = false;
    rowClass = '';

    constructor(
        private errorService: ErrorService,
        private cdr: ChangeDetectorRef,
    ) {}

    ngOnChanges(change) {
        if (change['options']) {
            if (this.options?.data) {
                this.data = Object.assign(this.options.data);
                this.afterDataInit();
            } else if (this.options?.lookupFunction) {
                this.fetchData();
            }
        }
    }

    afterDataInit() {
        this.tiles = [...this.options.tiles.slice(0, 8)];
        this.rowClass = this.getRowClass();
        this.busy = false;
        this.cdr.markForCheck();
    }

    refreshData() {
        this.fetchData();
    }

    private fetchData() {
        /*
            The idea here was that the user can also pass inn a function for data fetching,
            so this component will be easier to use with ticker if nessassary later..
        */
        this.busy = true;
        this.options?.lookupFunction().subscribe({
            next: (data) => {
                this.data = Object.assign(data);
                this.afterDataInit();
            },
            error: (err) => {
                this.errorService.handle(err);
                this.missingDataSource = true;
                this.busy = false;
            },
        });
    }

    private getRowClass() {
        const columns = this.tiles.length > 6 ? 4 : this.tiles.length % 3 === 0 ? 3 : 2;
        const rows = this.tiles.length > 3 ? 2 : 1;
        return `cr${columns}${rows}`;
    }
}
