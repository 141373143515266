import { UnitableAutocomplete } from '../controls/table-autocomplete';
import { UnitableTypeahead } from '../controls/typeahead';
import { UnitableTextInput } from '../controls/text';
import { UnitableNumberInput } from '../controls/number';
import { UnitableDateTimepicker } from '../controls/dateTimePicker/dateTimePicker';
import { UnitableSelect } from '../controls/select';
import { LocalDatePicker } from '../controls/localDatePicker/LocalDatePicker';
import { Observable } from 'rxjs';
import { Type } from '@angular/core';
import { UnitableButton } from '../controls/table-button';

/*
    Dont make changes to this unless you know what you're doing!
    UniQuery uses the numerical values when saving configs,
    on the backend, so altering these will cause the wrong
    column types to be used in UniQuery...
    <insert facepalm gif>

    Adding stuff to the end of the enum is fine, just dont alter existing numbers.
*/
export enum UniTableColumnType {
    Text = 1,
    Number = 2,
    DateTime = 3,
    Lookup = 4,
    Custom = 5,
    Select = 6,
    Money = 7,
    Percent = 8,
    LocalDate = 9,
    Boolean = 10,
    Typeahead = 12,
    Link = 13,
    Status = 14,
    // Checkbox = 15,
    Attachment = 16,
    Button = 17,
    // Icon = 18,
    BankAccount = 19,
    NumberTypeahead = 20,
}

export enum UniTableColumnSortMode {
    Normal = 0,
    Absolute = 1,
}

export interface INumberFormat {
    thousandSeparator?: string;
    decimalSeparator?: string;
    decimalLength?: number;
    roundDecimals?: boolean;
    prefix?: string;
    postfix?: string;
    showZero?: boolean;
}

export interface IColumnTooltip {
    text: string;
    type: 'info' | 'good' | 'warn' | 'bad';
    alignment?: 'left' | 'right' | 'center';
    size?: number;
    class?: string;
}

export interface ColumnButton {
    label: string;
    class?: string;
    width?: string;
    action: (rowModel) => void | Observable<any>;
}

export interface ColumnIcon {
    icon: string;
    tooltip?: string | Type<any>;
    counter?: number;
    class?: string;
    materialIconsClassOverride?: string;
    counterClass?: string;
    color?: string;
    size?: number;
}

export interface FilterSelectConfig {
    options: any[];
    displayField: string;
    valueField: string;
}

export type TableStatusMap = {
    [Key in number | string]:
        | string
        | {
              label: string;
              tooltip?: (row) => string;
              type?: 'success' | 'warning' | 'error' | 'deactivated' | 'pending' | 'processing' | 'info';
          };
};

export interface CellComponentEventHandlers {
    [name: string]: (data?: any) => void;
}

export class UniTableColumn {
    type: UniTableColumnType;

    header: string;
    field: string;
    displayField: string;
    alias: string;
    path: string;
    sumFunction: string;
    index: number;
    editable: boolean | ((rowModel) => boolean);
    visible: boolean;
    conditionalCls: (rowModel: any) => string;
    cls: string;
    headerCls: string;
    headerIcon: ColumnIcon;

    hasLink: (rowMdoel) => boolean;
    linkResolver: (rowModel) => string;
    linkClick: (rowModel) => void;
    tooltipResolver: (rowModel) => IColumnTooltip;
    buttonResolver: (rowModel) => ColumnButton;
    iconResolver: (rowModel) => ColumnIcon[];

    template: (rowModel: any) => string;
    cellComponent: Type<any>;
    cellComponentEventHandlers: CellComponentEventHandlers;

    format: string;
    numberFormat: INumberFormat;
    alignment: string;
    options: any;
    editor: any;
    width: number | string;
    minWidth: number;
    maxWidth: number;
    sortable = true;
    sortMode: UniTableColumnSortMode;
    sortFields: string | string[];
    isSumColumn: boolean;
    markedRowsSumCol: boolean;
    aggFunc: (items: any[]) => number;

    searchInputFilteringEnabled = true;
    advancedFilteringEnabled = true;

    filterOperator: string;
    filterSelectConfig: FilterSelectConfig;
    additionalFilterFields: string[];

    skipOnEnterKeyNavigation: boolean;
    jumpToColumn: string;
    onCellClick: (rowModel) => void;
    maxLength: number;
    resizeable: boolean = true;
    enablePivot?: boolean;
    placeholder: string | ((row) => string);
    statusMap: TableStatusMap;
    featurePermission: string;
    cellTitleResolver: (data) => string;

    commentIndicator: { entityType: string; idField: string };

    constructor(
        field?: string,
        header?: string,
        type?: UniTableColumnType,
        editable: boolean | ((rowModel) => boolean) = true,
    ) {
        this.header = header || '';
        this.field = field || '';
        this.editable = editable;
        this.visible = true;
        this.filterOperator = 'contains';
        this.skipOnEnterKeyNavigation = false;
        this.sortMode = UniTableColumnSortMode.Normal;

        this.cls = '';
        this.headerCls = '';

        this.setType(type || UniTableColumnType.Text);
    }

    public setHeader(header: string) {
        this.header = header;
        return this;
    }

    public setCellTitleResolver(resolver: (data) => string) {
        this.cellTitleResolver = resolver;
        return this;
    }

    public setEditable(editable: boolean | ((rowModel) => boolean)) {
        this.editable = editable;
        return this;
    }

    public setWidth(width: number | string, resizeable?: boolean) {
        this.width = width;

        if (typeof resizeable === 'boolean') {
            this.resizeable = resizeable;
        }

        return this;
    }

    public setMinWidth(width: number) {
        this.minWidth = width;
        return this;
    }

    public setMaxWidth(width: number) {
        this.maxWidth = width;
        return this;
    }

    public setResizeable(resizeable: boolean) {
        this.resizeable = resizeable;
        return this;
    }

    public setField(field: string) {
        this.field = field;
        return this;
    }

    public setAlias(alias: string) {
        this.alias = alias;
        return this;
    }

    public setDisplayField(displayField: string) {
        this.displayField = displayField;
        return this;
    }

    public setType(type: UniTableColumnType) {
        this.type = type;

        switch (type) {
            case UniTableColumnType.Lookup:
                this.editor = UnitableAutocomplete;
                break;

            case UniTableColumnType.Typeahead:
                this.editor = UnitableTypeahead;
                break;

            case UniTableColumnType.NumberTypeahead:
                this.editor = UnitableTypeahead;
                this.filterOperator = 'eq';
                this.setAlignment('right');
                this.numberFormat = {
                    thousandSeparator: ' ',
                    decimalSeparator: ',',
                    decimalLength: 2,
                };
                break;

            case UniTableColumnType.Text:
                this.editor = UnitableTextInput;
                break;

            case UniTableColumnType.Number:
            case UniTableColumnType.Money:
            case UniTableColumnType.Percent:
                this.editor = UnitableNumberInput;
                this.filterOperator = 'eq';
                this.setAlignment('right');
                this.numberFormat = {
                    thousandSeparator: ' ',
                    decimalSeparator: ',',
                    decimalLength: type === UniTableColumnType.Money ? 2 : undefined,
                    postfix: type === UniTableColumnType.Percent ? '%' : undefined,
                };
                break;

            case UniTableColumnType.DateTime:
                this.editor = UnitableDateTimepicker;
                break;

            case UniTableColumnType.Select:
                this.editor = UnitableSelect;
                break;

            case UniTableColumnType.LocalDate:
                this.editor = LocalDatePicker;
                break;

            case UniTableColumnType.Button:
                this.editor = UnitableButton;
                break;

            case UniTableColumnType.Link:
                this.editable = false;
                break;

            case UniTableColumnType.Status:
                this.resizeable = false;
                this.editable = false;
                this.filterOperator = 'eq';
                break;

            case UniTableColumnType.Boolean:
                this.filterSelectConfig = {
                    displayField: 'label',
                    valueField: 'value',
                    options: [
                        { label: 'Ja', value: true },
                        { label: 'Nei', value: false },
                    ],
                };
                break;

            case UniTableColumnType.Attachment:
                this.setCls('attachment-column');
                this.setWidth('160px', false);
                break;
        }

        if (type === UniTableColumnType.LocalDate || type === UniTableColumnType.DateTime) {
            this.setMinWidth(110);
        }

        return this;
    }

    public setVisible(visible: boolean) {
        this.visible = visible;
        return this;
    }

    public setConditionalCls(conditionalCls: (rowModel: any) => string) {
        // Hack for making column work with both uni-table and ag-grid
        this.conditionalCls = (param) => {
            const row = param.data ? param.data : param;
            return ' ' + conditionalCls(row) + this.cls;
        };

        return this;
    }

    public setCls(cls: string) {
        this.cls += ' ' + cls;
        return this;
    }

    public setHeaderCls(headerCls: string) {
        this.headerCls += ' ' + headerCls;
        return this;
    }

    public setTemplate(template: (rowModel: any) => string) {
        this.template = template;
        return this;
    }

    public setCellComponent(component: Type<any>, eventHandlers?: CellComponentEventHandlers) {
        this.cellComponent = component;
        this.cellComponentEventHandlers = eventHandlers;
        return this;
    }

    public setTooltipResolver(tooltipResolver: (rowModel) => IColumnTooltip) {
        this.tooltipResolver = tooltipResolver;
        return this;
    }

    public setButtonResolver(buttonResolver: (rowModel) => ColumnButton) {
        this.buttonResolver = buttonResolver;
        return this;
    }

    public setIconResolver(iconResolver: (rowModel) => ColumnIcon[]) {
        this.iconResolver = iconResolver;
        return this;
    }

    public setHeaderIcon(icon: ColumnIcon) {
        this.headerIcon = icon;
        return this;
    }

    public setHasLink(check: (rowModel) => boolean) {
        this.hasLink = check;
        return this;
    }

    public setLinkResolver(linkResolver: (rowModel) => string) {
        this.linkResolver = linkResolver;
        this.cls += ' link-cell';
        return this;
    }

    public setLinkClick(clickHandler: (rowModel) => void) {
        this.linkClick = clickHandler;
        this.cls += ' link-cell';
        return this;
    }

    public setOptions(options: any) {
        this.options = options;
        return this;
    }

    public setEditor(editor: any) {
        this.editor = editor;
        return this;
    }

    public setFormat(format: string) {
        this.format = format;
        return this;
    }

    public setNumberFormat(formatOptions: INumberFormat) {
        this.numberFormat = formatOptions;
        return this;
    }

    public setAlignment(alignment: 'left' | 'right' | 'center') {
        this.alignment = alignment;
        return this;
    }

    public disableFiltering() {
        this.searchInputFilteringEnabled = false;
        this.advancedFilteringEnabled = false;
        return this;
    }

    public disableSearchInputFiltering() {
        this.searchInputFilteringEnabled = false;
        return this;
    }

    public disableAdvancedFiltering() {
        this.advancedFilteringEnabled = false;
        return this;
    }

    public setAdditionalFilterFields(fields: string[]) {
        this.additionalFilterFields = fields;
        return this;
    }

    public setFilterOperator(operator: string) {
        this.filterOperator = operator;
        return this;
    }

    public setFilterSelectConfig(config: FilterSelectConfig) {
        this.filterSelectConfig = config;
        return this;
    }

    public setSkipOnEnterKeyNavigation(skip: boolean) {
        this.skipOnEnterKeyNavigation = skip;
        return this;
    }

    public setJumpToColumn(field: string) {
        this.jumpToColumn = field;
        return this;
    }

    public setSortable(sortable: boolean) {
        this.sortable = sortable;
        return this;
    }

    public setSortMode(sortMode: UniTableColumnSortMode) {
        this.sortMode = sortMode;
        return this;
    }

    public setSortField(field: string | string[]) {
        this.sortFields = field;
        return this;
    }

    public setOnCellClick(handler: (rowModel) => void) {
        this.onCellClick = handler;
        return this;
    }

    public setIsSumColumn(isSumColumn: boolean) {
        this.isSumColumn = isSumColumn;
        return this;
    }

    public setAggFunc(aggFunc: (data: any[]) => number) {
        this.aggFunc = aggFunc;
        return this;
    }

    public setMaxLength(maxLength: number) {
        this.maxLength = maxLength;
        return this;
    }

    setPlaceholder(placeholder: string | ((row) => string)) {
        this.placeholder = placeholder;
        return this;
    }

    setStatusMap(statusMap: TableStatusMap) {
        this.statusMap = statusMap;

        const filterOptions = Object.keys(statusMap).map((key) => {
            const status = statusMap[key];
            const label = typeof status === 'string' ? status : status.label;
            return { label, value: key };
        });

        this.filterSelectConfig = {
            options: filterOptions,
            valueField: 'value',
            displayField: 'label',
        };

        return this;
    }

    setFeaturePermission(permission: string) {
        this.featurePermission = permission;
        return this;
    }

    setCommentIndicator(entityType: string, idField?: string) {
        this.commentIndicator = {
            entityType,
            idField: idField || 'ID',
        };

        return this;
    }
}
