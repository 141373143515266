import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { THEMES, theme } from 'src/themes/theme';
import { AVAILABLE_ILLUSTRATIONS } from './illustration-metadata';

const illustration_base_path = '/assets/illustrations';

// Exports a type that resolves to a union of the available illustration names in the base directory
export type IllustrationName = (typeof AVAILABLE_ILLUSTRATIONS.base)[number];

@Component({
    standalone: true,
    selector: 'uni-illustration',
    template: `<img [src]="path()" [alt]="name()" />`,
    styles: `
        :host {
            display: block;
            aspect-ratio: 1 / 1;
            width: 200px;
        }

        img {
            max-height: 100%;
            max-width: 100%;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UniIllustration {
    name = input.required<IllustrationName>();

    path = computed(() => {
        const name = this.name();
        const themeData = this.getThemeData();

        let filePath = name + '.svg';

        if (themeData?.illustrations.includes(name)) {
            filePath = `${themeData.folder}/${name}.svg`;
        }

        return `${illustration_base_path}/${filePath}`;
    });

    private getThemeData(): { folder: string; illustrations: readonly IllustrationName[] } {
        // TODO: implement for all themes
        switch (theme.theme) {
            case THEMES.SR:
                return { folder: 'sb1', illustrations: AVAILABLE_ILLUSTRATIONS.sb1 };
            case THEMES.EXT02:
                return { folder: 'dnb', illustrations: AVAILABLE_ILLUSTRATIONS.dnb };
            case THEMES.EIKA:
                return { folder: 'eika', illustrations: AVAILABLE_ILLUSTRATIONS.eika || [] };
        }
    }
}
