import { Component, Input } from '@angular/core';

@Component({
    selector: 'uni-tooltip',
    template: `<uni-icon tabindex="0" icon="help_circle" svgSize="20" [matTooltip]="text" [ngClass]="type" />`,
    styleUrls: ['./tooltip.sass'],
})
export class UniTooltip {
    @Input() public type: 'info' | 'warn' | 'error' = 'info';
    @Input() public alignment: 'top' | 'bottom' | 'left' | 'right';
    @Input() public text: string;
}
