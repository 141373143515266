<section role="dialog" class="uni-modal">
    <header>{{ step === 1 ? 'Velkommen til ' + appName : 'Du er i mål!' }}</header>

    <article *ngIf="step === 1">
        <section *ngIf="busy" class="modal-spinner">
            <mat-spinner class="c2a"></mat-spinner>
        </section>

        <section *ngIf="!busy" class="image-wrapper">
            <img src="/config/themes/shared/jumping_celebration.svg" alt="Illustration of people jumping" />
        </section>

        <section *ngIf="!busy" class="agreement-text">
            {{ product.Label }} ber om tilgang til dine regnskapsdata, slik at de kan starte arbeidet med ditt regnskap
            som avtalt. Du kan når som helst fjerne tilgangen, dette gjør du fra markedsplassen.
        </section>
    </article>

    <article *ngIf="step === 2">
        <section class="sub-title">{{ product.Label }} blir informert om tilgangen du har gitt.</section>

        <section class="image-wrapper">
            <img src="/config/themes/shared/jumping_high_five.svg" alt="Illustration of people high fiving" />
        </section>

        <section class="agreement-text">
            <p>
                <i class="material-icons">info</i>
                Merk følgende
            </p>
            Ditt regnskapsbyrå går nå i gang med å sette opp systemet, og/eller importere data fra ditt tidligere
            regnskapssystem. Du må avklare med dem, når det er klart for deg å starte arbeidet i {{ appName }}.
        </section>
    </article>

    <footer class="center">
        <button *ngIf="step === 1" class="c2a" (click)="accept()">
            {{ agreement?.AcceptButtonLabel || 'Gi tilgang og del regnskapsdata' }}
        </button>

        <button *ngIf="step === 1" class="tertiary c2a" (click)="onClose.emit()">
            {{ agreement?.RejectButtonLabel || 'Ikke nå, spør meg senere' }}
        </button>

        <button *ngIf="step === 2" class="c2a done-button" (click)="onClose.emit()">Ok, ta meg til forsiden</button>
    </footer>
</section>
