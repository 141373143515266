import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BizHttp } from '../../../framework/core/http/BizHttp';
import { UniHttp } from '../../../framework/core/http/http';
import { AGAZone } from '../../unientities';

@Injectable({ providedIn: 'root' })
export class AgaZoneService extends BizHttp<AGAZone> {
    constructor(http: UniHttp) {
        super(http);
        this.relativeURL = AGAZone.RelativeUrl;
        this.entityType = AGAZone.EntityType;
        this.DefaultOrderBy = null;
    }

    private cachedZones: AGAZone[];

    public getAgaRules() {
        return this.GetAll('action=get-agasectors');
    }

    getAgaZones(): Observable<AGAZone[]> {
        if (this.cachedZones) {
            return of(this.cachedZones);
        }
        return super.GetAll('');
    }
}
