import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { rigDate } from '@app/components/common/utils/rig-date';

@Component({
    selector: 'date-range-dropdown',
    templateUrl: './date-range-dropdown.html',
    styleUrls: ['./date-range-dropdown.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateRangeDropdown {
    @Input() triggerButton: HTMLButtonElement;

    @Input() fromDate: Date;
    @Input() toDate: Date;
    @Input() periodOnly: boolean;
    @Input() selectionText: string;

    @Output() change = new EventEmitter<{ from: Date; to: Date }>();

    emitChange(from: Date, to: Date) {
        this.change.emit({ from, to });
    }

    onFromMonthChange() {
        this.fromDate = this.fromDate && rigDate(this.fromDate).startOf('month').toDate();
        if (this.fromDate && rigDate(this.toDate).isBefore(this.fromDate)) {
            this.toDate = rigDate(this.fromDate).endOf('month').toDate();
        }

        this.emitChange(this.fromDate, this.toDate);
    }

    onToMonthChange() {
        this.toDate = this.toDate && rigDate(this.toDate).endOf('month').toDate();
        if (this.toDate && rigDate(this.fromDate).isAfter(this.toDate)) {
            this.fromDate = rigDate(this.toDate).startOf('month').toDate();
        }

        this.emitChange(this.fromDate, this.toDate);
    }
}
