<section class="uni-modal">
    <header>Opprett ny {{ entityLabel }}</header>

    <article>
        <mat-radio-group
            *ngIf="!hideEntityTypeSelector"
            [value]="entityType"
            (change)="setEntityType('', $event.value)"
            class="horizontal"
        >
            <mat-radio-button *ngIf="hasPermissions" value="account">Hovedbokskonto</mat-radio-button>
            <mat-radio-button value="customer">Kunde</mat-radio-button>
            <mat-radio-button value="supplier">Leverandør</mat-radio-button>
        </mat-radio-group>

        <general-ledger-account-form
            *ngIf="entityType === 'account'"
            [entity]="entity"
            (entityChange)="onEntityChange($event)"
        >
        </general-ledger-account-form>

        <customer-and-supplier-form
            *ngIf="entityType === 'customer' || entityType === 'supplier'"
            [entityType]="entityType"
            [entity]="entity"
            (entityChange)="onEntityChange($event)"
        >
        </customer-and-supplier-form>
    </article>

    <footer>
        <button class="secondary" (click)="onClose.emit()">Avbryt</button>
        <button class="c2a" (click)="save()" [disabled]="!isValid" [attr.aria-busy]="busy">
            Opprett {{ entityLabel }}
        </button>
    </footer>
</section>
