<section role="dialog" class="uni-modal">
    <section style="height: 15rem" *ngIf="!initialized">
        <section *ngIf="busy" class="modal-spinner">
            <mat-spinner class="c2a"></mat-spinner>
        </section>
    </section>
    <rig-wizard [steps]="steps" [activeStepValue]="currentStep.value" *ngIf="initialized">
        <h1 class="wizard-header">Slik setter du opp autobank</h1>
        <article class="step" *ngIf="currentStep.value === 0">
            <h2 class="step-header">
                Velkommen til vår veiviser for <br />
                autobank
            </h2>

            <section class="mt-1 mb-4">
                <p>
                    For å komme i gang med autobank trenger vi informasjon fra dere for å koble opp mot deres bank. Du
                    trenger bare en avtale per bank, selv om du har flere bankkontoer. Banker som kan velges mellom
                    hentes fra bankkontoer satt opp i firmainnstillinger.
                </p>
                <p>
                    Oppsettet mot de ulike bankene varierer, og for å sikre best mulig automasjon kan vi kunne trenge
                    ekstra informasjon. I slike tilfeller vil dere bli kontaktet av <strong>{{ bankName }}</strong
                    >.
                </p>
                <span *ngIf="agreements.length">
                    Du har allerede aktive avtaler med følgende bank{{ usedBanks.length > 1 ? 'er' : '' }}: <br />
                    <strong *ngFor="let bank of usedBanks; let i = index">
                        {{ bank }}{{ i < usedBanks.length - 1 ? ', ' : '' }}
                    </strong>
                </span>
            </section>
        </article>

        <article class="step" *ngIf="currentStep.value === 1">
            <!-- ADD COMPANY SELECTION HERE WHEN THAT IS AVAILABLE! -->
            <h2 class="step-header">Firma</h2>
            <p class="mt-1 mb-4">
                Ny autobankavtale for klienten du står på: <br /><strong class="d-block mt-2">{{
                    companySettings?.CompanyName
                }}</strong>
            </p>
        </article>

        <article *ngIf="currentStep.value === 2" class="step">
            <h2 class="step-header">Avtalevilkår</h2>
            <section class="mt-1 mb-4">
                <ng-container *ngIf="bankAgreementUrl">
                    <object [data]="bankAgreementUrl" type="application/pdf">
                        <a [href]="bankAgreementUrl">Avtalevilkår</a>
                    </object>
                    <br />
                    <rig-checkbox class="mt-2" [(ngModel)]="hasReadAgreement"> Godta vilkår og avtaler </rig-checkbox>
                </ng-container>
                <p *ngIf="!bankAgreementUrl">Fant ingen avtalevilkår. Kontakt systemansvarlig.</p>
            </section>
        </article>

        <article class="step" *ngIf="currentStep.value === 3">
            <h2 class="step-header">Godkjenning av betalinger</h2>
            <section class="mt-1 mb-4">
                <p>
                    Her velger du metoden for å godkjenne betalinger: velger du regnskapsgodkjente betalinger kan du
                    forhåndsgodkjenne betalingene, ellers må du ettergodkjenne betalingene i nettbanken.
                </p>
                <uni-radio-group [(ngModel)]="agreementDetails.BankApproval">
                    <uni-radio-button [value]="false" (change)="onRGBChange($event)"
                        >Regnskapsgodkjente betalinger</uni-radio-button
                    >
                    <uni-radio-button [value]="true" (change)="onRGBChange($event)"
                        >Ettergodkjente betalinger i nettbanken</uni-radio-button
                    >
                </uni-radio-group>
            </section>
        </article>

        <article class="step" *ngIf="currentStep.value === 4" id="step">
            <h2 class="step-header">Bankoppsett</h2>
            <section class="mt-1 mb-4">
                <uni-form
                    style="font-size: 0.9rem; width: 80%"
                    [config]="{ showLabelAbove: true, autoFocus: true }"
                    [fields]="formFields$"
                    [model]="formModel$"
                    (changeEvent)="onFormChange($event)"
                >
                </uni-form>

                <label class="uni-label agreement-checkboxes">
                    <span>Marker for å ta med i avtale</span>
                    <rig-checkbox [(ngModel)]="agreementDetails.IsInbound"> Innbetalinger </rig-checkbox>
                    <rig-checkbox [(ngModel)]="agreementDetails.IsOutgoing"> Utbetalinger </rig-checkbox>
                    <rig-checkbox [(ngModel)]="agreementDetails.IsBankBalance">
                        Banksaldo + avstemming
                        <i class="material-icons" [matTooltip]="infoTextForBalance">info</i>
                    </rig-checkbox>
                </label>
            </section>
        </article>

        <article class="step" *ngIf="currentStep.value === 5 && !hasAgreements">
            <h2 class="step-header">Sikkerhetsinnstillinger</h2>
            <section class="mt-1 mb-4">
                <p>
                    Velg et passord for autobank. Dette passordet brukes for å sende betalinger.
                    <!-- Velg 2-faktor autentisering for å øke sikkerheten. Vi vil da også trenge et mobilnummer. -->
                </p>
                <i class="material-icons" style="margin-bottom: 1rem" [matTooltip]="passwordCriteriaMsg"> info </i>
                <section>
                    <label class="uni-label label-left">
                        <span>Passord</span>
                        <input type="password" autocomplete="new-password" [(ngModel)]="agreementDetails.Password" />
                    </label>
                    <label class="uni-label label-left">
                        <span>Bekreft passord</span>
                        <input
                            type="password"
                            autocomplete="new-password"
                            [(ngModel)]="agreementDetails._confirmPassword"
                        />
                    </label>
                </section>
            </section>
        </article>

        <article class="step" *ngIf="currentStep.value === 5 && hasAgreements">
            <h2 class="step-header">Sikkerhetsinnstillinger</h2>
            <section class="mt-1 mb-4">
                <span style="color: #9198aa; margin: 0 0 0.8rem 9.5rem"> Bekreft eksisterende autobankpassord </span>

                <section class="uni-html-form bank-agreement-password-form">
                    <label>
                        <span>Passord</span>
                        <input type="password" autocomplete="new-password" [(ngModel)]="agreementDetails.Password" />
                    </label>
                </section>
            </section>
        </article>

        <article class="step" *ngIf="currentStep.value === 6">
            <i class="material-icons" style="color: #7bcb45; font-size: 5rem; text-align: center">check_circle</i>
            <h2 class="step-header">Avtale opprettet</h2>
            <p class="mt-1 mb-4">
                Du har nå fullført din del av opprettelse av autobankavtale. Nå setter vi opp alt med banken. Status på
                avtalen kan du sjekke forløpende ved å trykke på linken "Mine avtaler" over knappen "Ny autobankavtale"
                i bankbilde.
            </p>
        </article>

        <rig-wizard-footer>
            <span *ngIf="errorText"> {{ errorText }}</span>
            <div>
                <button *ngIf="currentStep.value > 0 && currentStep.value !== 6" (click)="move(-1)" class="secondary">
                    Tilbake
                </button>
                <button (click)="move(1)" class="c2a" [disabled]="buttonLock">
                    {{
                        currentStep.value === 5 || (currentStep.value === 4 && serviceProvider === 4)
                            ? 'Opprett autobank'
                            : currentStep.value === 6 || noAccounts
                              ? 'Lukk'
                              : 'Fortsett'
                    }}
                </button>
            </div>
        </rig-wizard-footer>
    </rig-wizard>
</section>
