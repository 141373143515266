import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Comment } from '@uni-entities';
import { ChatBoxService } from '@app/components/layout/chat-box/chat-box.service';
import { Subscription } from 'rxjs';
import { AuthService } from '@app/authService';
import { CommentService } from '@app/services/common/commentService';

@Component({
    selector: 'uni-comments',
    templateUrl: './comments.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UniComments {
    @Input() entity: string;
    @Input() entityID: number;
    @Input() toggleMode: boolean;

    public isOpen: boolean;
    public comments: Comment[] = [];

    commentCount: number;
    subscription: Subscription;

    constructor(
        private chatBoxService: ChatBoxService,
        private commentService: CommentService,
        private authService: AuthService,
        private cdr: ChangeDetectorRef,
    ) {}

    ngOnInit() {
        this.subscription = this.commentService.commentAdded$.subscribe((comment) => {
            if (
                comment.EntityType?.toLowerCase() === this.entity?.toLowerCase() &&
                comment.EntityID === this.entityID
            ) {
                this.loadCommentCount();
            }
        });
    }

    ngOnChanges() {
        this.loadCommentCount();
    }

    ngOnDestroy() {
        this.subscription?.unsubscribe();
    }

    private loadCommentCount() {
        if (this.entity && this.entityID) {
            this.commentService.getCommentCount(this.entity, this.entityID).subscribe((count) => {
                this.commentCount = count || 0;
                this.cdr.markForCheck();
            });
        }
    }

    openChatBox() {
        this.chatBoxService.addBusinessObject({
            EntityID: this.entityID,
            EntityType: this.entity,
            CompanyKey: this.authService.getCompanyKey(),
        });
    }
}
