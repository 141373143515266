import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BizHttp } from '../../../framework/core/http/BizHttp';
import { VatType, Account, AccountVatTypeLink, AccountVatType } from '../../unientities';
import { UniHttp } from '../../../framework/core/http/http';
import { rigDate } from '@app/components/common/utils/rig-date';
import { AccountService } from '@app/services/accounting/accountService';

@Injectable({ providedIn: 'root' })
export class VatTypeService extends BizHttp<VatType> {
    constructor(
        http: UniHttp,
        private accountService: AccountService,
    ) {
        super(http);

        this.relativeURL = VatType.RelativeUrl;
        this.entityType = VatType.EntityType;
        this.DefaultOrderBy = 'VatCode';
        this.defaultExpand = ['VatTypePercentages'];

        this.cacheSettings.timeout = 60000 * 15; // 15 min
    }

    public GetVatTypesWithVatReportReferencesAndVatCodeGroup(): Observable<VatType[]> {
        return this.GetAll(null, [
            'VatCodeGroup',
            'VatReportReferences',
            'VatReportReferences.Account',
            'VatReportReferences.VatPost',
        ]);
    }

    public GetVatTypesWithDefaultVatPercent(query: string, expand: string[] = null): Observable<VatType[]> {
        return this.GetAll(query, expand).pipe(
            map((vattypes) => {
                // map vattypepercentage data to vattype to get the defaultvalue
                // based on todays date - might need to consider checking based on
                // accountingyear instead, but this really depends on the context,
                // for invoicing current date is most likely what we want
                const response: Array<VatType> = [];

                const today = rigDate(new Date());

                vattypes.forEach((vatType) => {
                    const currentPercentage = vatType.VatTypePercentages?.find(
                        (y) =>
                            (rigDate(y.ValidFrom) <= today && y.ValidTo && rigDate(y.ValidTo) >= today) ||
                            (rigDate(y.ValidFrom) <= today && !y.ValidTo),
                    );

                    if (currentPercentage) {
                        vatType.VatPercent = currentPercentage.VatPercent;
                    }

                    response.push(vatType);
                });

                return response;
            }),
        );
    }

    saveAccountVatType(accountVatType: Partial<AccountVatType>) {
        const obs = accountVatType.ID ? this.http.asPUT() : this.http.asPOST();
        const endpoint = `accountvattype/${accountVatType.ID ? accountVatType.ID : ''}`;

        return obs
            .usingBusinessDomain()
            .withEndPoint(endpoint)
            .withBody(accountVatType)
            .send()
            .pipe(map((res) => res.body));
    }

    saveAccountVatTypeLink(accountVatTypeLink: Partial<AccountVatTypeLink>) {
        const obs = accountVatTypeLink.ID ? this.http.asPUT() : this.http.asPOST();
        const endpoint = `accountvattypelink/${accountVatTypeLink.ID ? accountVatTypeLink.ID : ''}`;

        return obs
            .usingBusinessDomain()
            .withEndPoint(endpoint)
            .withBody(accountVatTypeLink)
            .send()
            .pipe(map((res) => res.body));
    }

    public getVatTypeOnAccount(accountNumber: number): Observable<VatType> {
        if (!accountNumber) {
            return of(null);
        }

        return this.accountService
            .GetAll(`filter=AccountNumber eq ${accountNumber}&top=1`, ['VatType.VatTypePercentages'])
            .pipe(
                map((accounts) => accounts[0]),
                map((account: Account) => {
                    if (!account || !account.VatType) {
                        return null;
                    }
                    return account.VatType;
                }),
            );
    }

    public getAccountVatTypesWithLinks(): Observable<AccountVatType[]> {
        return this.http
            .asGET()
            .usingBusinessDomain()
            .withEndPoint(`accountvattype?expand=AccountVatTypeLinks.Account`)
            .send()
            .pipe(map((res) => res.body));
    }

    public activatAccountVatTypeSettings() {
        return this.http
            .asPUT()
            .usingBusinessDomain()
            .withEndPoint(`accountvattype?action=activate-default-accountvattype`)
            .send()
            .pipe(map((res) => res.body));
    }

    public deactivateAccountVatTypeSettings() {
        return this.http
            .asPUT()
            .usingBusinessDomain()
            .withEndPoint(`accountvattype?action=delete-all-accountvattypes-and-links`)
            .send()
            .pipe(map((res) => res.body));
    }

    public resetAccountVatTypeSettings() {
        return this.http
            .asPUT()
            .usingBusinessDomain()
            .withEndPoint('accountvattype?action=reset-accountvattypes-and-links')
            .send()
            .pipe(map((res) => res.body));
    }
}
