<div class="steps" [class.tabs]="showStepsAsTabs">
    <wizard-tabs
        #tabs
        *ngIf="showStepsAsTabs"
        [tabs]="steps"
        [activeIndex]="activeIndex"
        (activeIndexChange)="onActiveIndexChange($event)"
    >
    </wizard-tabs>

    <ng-container *ngIf="!showStepsAsTabs">
        <div
            class="step flex"
            *ngFor="let step of steps; let idx = index"
            [class.active]="idx === activeIndex"
            [class.completed]="idx < activeIndex"
        >
            <div class="step-details flex">
                <div class="step-circle flex align-center justify-center">
                    <svg *ngIf="idx < activeIndex" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M4.66674 7.11475L10.7947 0.986084L11.7381 1.92875L4.66674 9.00008L0.424072 4.75742L1.36674 3.81475L4.66674 7.11475Z"
                            fill="currentColor"
                        />
                    </svg>

                    <span *ngIf="idx >= activeIndex">{{ idx + 1 }}</span>
                </div>

                <span class="step-label">{{ step.label }}</span>
            </div>

            <div class="divider" *ngIf="idx < steps.length - 1" aria-hidden="true"></div>
        </div>
    </ng-container>
</div>

<div class="wizard-content flex-column">
    <ng-content></ng-content>
</div>
