import { Component, EventEmitter } from '@angular/core';
import { IModalOptions, IUniModal } from '../../interfaces';
import { ElsaAgreement, ElsaProduct } from '@app/models';
import { theme } from 'src/themes/theme';
import { ErrorService } from '@app/services/common/errorService';
import { ElsaPurchaseService } from '@app/services/elsa/elsaPurchasesService';

@Component({
    selector: 'product-consent-modal',
    templateUrl: './product-consent-modal.html',
    styleUrls: ['./product-consent-modal.sass'],
})
export class ProductConsentModal implements IUniModal {
    options: IModalOptions = {};
    onClose = new EventEmitter();

    busy: boolean;

    agreement: ElsaAgreement;
    product: ElsaProduct;

    appName = theme.appName;
    step = 1;

    constructor(
        private errorService: ErrorService,
        private purchaseService: ElsaPurchaseService,
    ) {}

    ngOnInit() {
        this.product = this.options.data?.product;
        this.agreement = this.options.data?.agreement || null;
    }

    accept() {
        this.busy = true;
        this.purchaseService.activateProduct(this.product.ID).subscribe({
            next: () => {
                this.busy = false;
                this.step = 2;
            },
            error: (err) => {
                this.errorService.handle(err);
                this.busy = false;
            },
        });
    }
}
