import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

export enum AnalyticsCatagory {
    CMS = 'CMS',
    MODAL = 'Modal',
    ROUTER = 'Router',
    SEARCH = 'Search',
    SIDEBAR = 'Sidebar',
    TOOLBAR = 'Toolbar',
    NAVBAR = 'Navbar',
    CLICK = 'Click',
    TABS = 'Tabs',
}

// These are the
export enum AnalyticsEvent {
    PAGE_LOADED = 'PageLoaded',
    ELEMENT_CLICKED = 'ElementClicked',
    MODAL_OPENED_EVENT = 'ModalOpenedEvent',

    SEARCH_OPENED = 'SearchOpened',
    SEARCH_USED = 'SearchUsed',

    CMS_BANNER_SHOWN = 'CMSBannerShown',
    CMS_BANNER_CLICKED = 'CMSBannerClicked',
    CMS_BANNER_CLOSED = 'CMSBannerClosed',
    CMS_WIDGET_SHOWN = 'CMSWidgetShown',
    CMS_WIDGET_CLOSED = 'CMSWidgetLinkClosed',
    CMS_SLIDEIN_SHOWN = 'CMSSlideinShown',
    CMS_SLIDEIN_CLOSED = 'CMSSlideinClosed',
    CMS_LINK_CLICKED = 'CMSLinkClicked',
}

@Injectable({
    providedIn: 'root',
})
export class AnalyticsService {
    // These are urls that are not tracked by the analytics tool
    private autoTrackingIgnore = ['sign-up', 'prelogin-'];
    private lastTrackedUrl: string;
    private isLocalhost = location.hostname === 'localhost';
    private trackingEnabled = false; //

    constructor(private router: Router) {}

    init() {
        // lets keep running on rc where ms clarity is set up..
        this.trackingEnabled = window.location.href.includes('rc.unimicro.no');
        if (!this.isLocalhost) {
            if (this.trackingEnabled) {
                var _paq = (window._paq = window._paq || []);

                (window as any)['clarity'] =
                    (window as any)['clarity'] ||
                    function () {
                        ((window as any)['clarity'].q = (window as any)['clarity'].q || []).push(arguments);
                    };

                _paq.push(['trackPageView']);
                _paq.push(['enableLinkTracking']);

                Promise.all([
                    this.loadScript(
                        'https://dev-unimicro.containers.piwik.pro/4a97e4d7-75c1-4b44-867d-5e5e2e4b8d61.js',
                    ), // Piwik Pro
                    this.loadScript('https://www.clarity.ms/tag/hk63jmfm6m'), // MS Clarity
                ]).then(() => this.startRouteTracking());
            }
        }
    }

    loadScript(src: string): Promise<any> {
        return new Promise((resolve, reject) => {
            let script = document.createElement('script');

            script.async = true;
            script.src = src;

            script.onload = () => resolve(true);
            script.onerror = () => reject(false);

            document.head.appendChild(script);
        });
    }

    startRouteTracking() {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                const url = this.router.url;

                const shouldTrack =
                    !!url && url !== '/reload' && !this.autoTrackingIgnore.some((ignore) => url.includes(ignore));

                if (shouldTrack) {
                    this.logPageView();
                }
            }
        });
    }

    logPageView() {
        const url = this.getUrl();
        if (url !== this.lastTrackedUrl) {
            this.lastTrackedUrl = url;
            this.trackRouterEvent(AnalyticsEvent.PAGE_LOADED, location.origin + url);
        }
    }

    // Example here is modal opened:
    // catagory: AnalyticsCatagory.Modal, event: AnalyticsEvent.MODAL_OPENED_EVENT, name: MY_AWESOME_MODAL
    // Value is optional, and only supports number.
    pushAnalyticsEvent(catagory: string, event: string, name?: string, value?: any) {
        if (this.trackingEnabled) {
            window._paq = window._paq || [];
            window._paq.push(['trackEvent', catagory, event, name, value]);
        }
    }

    trackRouterEvent(event: AnalyticsEvent, name?: string, value?: number) {
        this.pushAnalyticsEvent(AnalyticsCatagory.ROUTER, event, name, value);
    }

    trackElementClick(name: string, value?: number): void {
        this.pushAnalyticsEvent(AnalyticsCatagory.CLICK, AnalyticsEvent.ELEMENT_CLICKED, name, value);
    }

    // Custom tracking for modals to see the usage
    trackModalEvent(event: AnalyticsEvent, modalName: string) {
        this.pushAnalyticsEvent(AnalyticsCatagory.MODAL, event, modalName);
    }

    trackSearchEvents(event: AnalyticsEvent, value) {
        this.pushAnalyticsEvent(AnalyticsCatagory.SEARCH, event, value);
    }

    trackCMSEvents(event: AnalyticsEvent, value: any) {
        this.pushAnalyticsEvent(AnalyticsCatagory.CMS, event, value);
    }

    trackSidebarEvent(value: string) {
        this.pushAnalyticsEvent(AnalyticsCatagory.SIDEBAR, value);
    }

    private getUrl() {
        return this.router.url
            .replace('/#/', '/')
            .split('?')[0]
            .replace(/\/\d+(\/|)/g, '/id');
    }
}
