import { Pipe, PipeTransform } from '@angular/core';
import { LicenseEntityStatus } from '@uni-entities';

@Pipe({ name: 'elsaEntityStatus' })
export class ElsaEntityStatusPipe implements PipeTransform {
    transform(licenseStatus: number): string {
        switch (licenseStatus) {
            case LicenseEntityStatus.Active:
                return 'Aktiv';
            case LicenseEntityStatus.Inactive:
                return 'Deaktivert';
            case LicenseEntityStatus.Pending:
                return 'Pending';
            case LicenseEntityStatus.Archived:
                return 'Arkivert';
            case LicenseEntityStatus.HardDeleted:
                return 'Hard Deleted';
            case LicenseEntityStatus.SoftDeleted:
                return 'Soft Deleted';
            default:
                return '';
        }
    }
}
