import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BizHttp } from '../../../framework/core/http/BizHttp';
import { ReInvoice, ReinvoiceItemsDto, StatusCodeReInvoice, SupplierGoods } from '../../unientities';
import { UniHttp } from '../../../framework/core/http/http';

@Injectable({ providedIn: 'root' })
export class ReInvoicingService extends BizHttp<ReInvoice> {
    public statusTypes: Array<any> = [
        { Code: StatusCodeReInvoice.Marked, Text: 'Markert til viderefakturering', isPrimary: true },
        { Code: StatusCodeReInvoice.Ready, Text: 'Klar til viderefakturering', isPrimary: false },
        { Code: StatusCodeReInvoice.ReInvoiced, Text: 'Viderefakturert', isPrimary: false },
        { Code: 30204, Text: 'Delvis viderefakturert', isPrimary: false },
    ];

    constructor(http: UniHttp) {
        super(http);
        this.relativeURL = ReInvoice.RelativeUrl;
        this.entityType = ReInvoice.EntityType;
    }

    public getStatusText(statusCode: number): string {
        if (!statusCode) {
            return '';
        }

        const statusType = this.statusTypes.find((x) => x.Code === statusCode);
        return statusType ? statusType.Text : 'Udefinert';
    }

    public prepareReinvoicing(supplierInvoiceID: number) {
        return this.http
            .asPOST()
            .usingBusinessDomain()
            .withEndPoint(`${this.relativeURL}?action=prepare-reinvoicing&supplierInvoiceID=${supplierInvoiceID}`)
            .send()
            .pipe(map((res) => res.body));
    }

    public resetStored(customStorageID: number) {
        return this.PutAction(null, 'reset-stored', `customStorageID=${customStorageID}`);
    }

    public updateNetPrice(supplierInvoiceID: number, price: number) {
        return this.http
            .asPUT()
            .usingBusinessDomain()
            .withEndPoint(
                `${this.relativeURL}?action=update-netprice&supplierInvoiceID=${supplierInvoiceID}&netPrice=${price}`,
            )
            .send()
            .pipe(map((res) => res.body));
    }

    public createSupplierGoods(supplierInvoiceID: number, products: SupplierGoods[]) {
        products?.forEach((p) => (p.Supplier = null));
        return this.http
            .asPOST()
            .usingBusinessDomain()
            .withBody(products)
            .withEndPoint(`${this.relativeURL}?action=create-supplier-goods&supplierInvoiceID=${supplierInvoiceID}`)
            .send()
            .pipe(map((res) => res.body));
    }

    public reinvoiceSupplierInvoiceItems(dto: ReinvoiceItemsDto) {
        return this.http
            .asPOST()
            .usingBusinessDomain()
            .withBody(dto)
            .withEndPoint(`${this.relativeURL}?action=reinvoice-items`)
            .send()
            .pipe(map((res) => res.body));
    }

    public getTransferredData(supplierInvoiceID: number) {
        return this.http
            .asGET()
            .usingBusinessDomain()
            .withEndPoint(`${this.relativeURL}?action=transferred-objects&supplierInvoiceID=${supplierInvoiceID}`)
            .send()
            .pipe(map((res) => res.body));
    }

    public reinvoiceStoredSupplierInvoice(supplierInvoiceID: number, approvalID: number = null) {
        return this.http
            .asPOST()
            .usingBusinessDomain()
            .withEndPoint(
                `${this.relativeURL}?action=reinvoice-stored-items&supplierInvoiceID=${supplierInvoiceID}` +
                    (approvalID ? `&approvalID=${approvalID}` : ''),
            )
            .send()
            .pipe(map((res) => res.body));
    }
}

export class PrepareReinvoiceDto {
    public UnknownProducts: any[]; // ProductDto[]
    public CreatedItemID: number;
    public Message: string;
}
