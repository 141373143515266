<section role="dialog" class="uni-modal">
    <article>
        <img
            src="config/dist/theme/assets/ext02-success-accountconfig.svg"
            alt="Bestill bankintegrasjon illustrasjon"
        />

        <h1>Koble sammen regnskap og bank</h1>

        <p>
            Trykker du på «Bestill» sender vi deg til DNB nettbank bedrift for å fullføre bestillingen. I
            bestillingsløpet velger du hvilke kontoer du ønsker å koble mot regnskapssystemet.
        </p>
    </article>

    <footer class="center">
        <button class="secondary" (click)="onClose.emit()">Lukk</button>

        <button class="c2a" (click)="openExternalOnboarding()">Bestill</button>
    </footer>
</section>
