<table-filters-and-buttons
    *ngIf="config?.searchable || config?.buttons"
    [tableConfig]="config"
    [columns]="columns"
    [filters]="dataService?.advancedSearchFilters"
    [quickFilters]="quickFilters"
    [optionBanner]="optionBanner"
    (filtersChange)="onFiltersChange($event)"
    (searchFieldKeydown)="checkForNavigationKey($event)"
>
    <ng-content></ng-content>
</table-filters-and-buttons>

<div *ngIf="config?.groupingEnabled" class="grouping-actions flex align-center gap-2 mt-1 mb-1">
    <grouping-column-dropdown *ngIf="!config.groupingConfig?.staticGroupByColumn" [columns]="columns">
    </grouping-column-dropdown>

    <button class="tertiary c2a" (click)="toggleAllGroups()" style="font-weight: 400">Åpne/lukk alle grupper</button>
</div>

<section
    #wrapper
    class="uni-ag-wrapper"
    *ngIf="rowModelType"
    [ngStyle]="{
        height: tableHeight + (reserveHeightForScrollbar || 0) + 'px',
        flex: config?.groupingEnabled ? '1' : 'unset',
        overflow: config?.editable ? 'unset' : 'hidden',
    }"
>
    <table-editor
        *ngIf="config?.editable"
        [agGridApi]="agGridApi"
        [config]="config"
        [columns]="columns"
        [rowHeight]="rowHeight"
        (valueChange)="onEditorChange($event)"
    >
    </table-editor>

    <grouped-table *ngIf="config.isGroupingTicker" [columns]="columns" [data]="resource"> </grouped-table>

    <ag-grid-angular
        #gridElement
        *ngIf="!config.isGroupingTicker"
        tabindex="0"
        class="ag-theme-quartz"
        [ngClass]="{
            editable: config?.editable,
            'col-menu-hidden': config && !config.columnMenuVisible,
            'has-sum-row': !!(dataService?.sumRow$ | async),
            'multi-row-select': config?.multiRowSelect,
        }"
        [style.--ag-row-height]="rowHeight + 'px'"
        [autoSizeStrategy]="autoSizeStrategy"
        [alwaysShowVerticalScroll]="domLayout !== 'autoHeight'"
        [columnDefs]="agColDefs"
        [getRowClass]="rowClassResolver"
        [suppressPropertyNamesCheck]="true"
        [rowModelType]="rowModelType"
        [domLayout]="domLayout"
        [getRowId]="getRowIdentifier"
        [defaultColDef]="defaultColDef"
        [enableCellTextSelection]="!config.rowDraggable"
        [enableBrowserTooltips]="true"
        [suppressNoRowsOverlay]="false"
        [suppressDragLeaveHidesColumns]="true"
        [headerHeight]="rowHeight"
        [rowHeight]="rowHeight"
        [rowDragManaged]="true"
        [suppressRowDrag]="!config?.rowDraggable"
        [cacheBlockSize]="cacheBlockSize"
        [animateRows]="!config.editable && !config?.groupingEnabled"
        [blockLoadDebounceMillis]="250"
        [suppressContextMenu]="true"
        [enableCellChangeFlash]="false"
        [pagination]="usePagination"
        [paginationPageSize]="config?.pageSize"
        [paginationPageSizeSelector]="false"
        (paginationChanged)="onPaginationChange($event)"
        (gridReady)="onAgGridReady($event)"
        (gridSizeChanged)="gridSizeChangeDebouncer$.next($event)"
        (columnResized)="colResizeDebouncer$.next($event)"
        (modelUpdated)="onAgModelUpdate($event)"
        (rowDragEnd)="onRowDragEnd($event)"
        (sortChanged)="onSortChange($event)"
        (keydown)="onTableKeydown($event)"
        (focus)="onTableElementFocus()"
        [rowSelection]="selectionMode"
        [rowMultiSelectWithClick]="config?.multiRowSelectOnRowClick"
        [suppressRowClickSelection]="
            (selectionMode === 'multiple' && !config?.multiRowSelectOnRowClick) || suppressRowClick
        "
        [isRowSelectable]="isRowSelectable"
        (selectionChanged)="onSelectionChange($event)"
        (rowSelected)="onRowSelected($event)"
        (rowClicked)="onRowClick($event)"
        (cellClicked)="onCellClick($event)"
        (columnMoved)="columnMoveDebouncer$.next($event)"
        [context]="context"
        [components]="gridComponents"
        [loadingOverlayComponent]="'tableLoadIndicator'"
        [localeText]="agTranslations"
    >
    </ag-grid-angular>
</section>

<!-- Note that row-count appears twice in this file, make changes in both -->
<section
    *ngIf="!usePagination && !config?.hideRowCount && (config?.showTotalRowCount || config?.multiRowSelect)"
    class="row-count flex align-center"
    [class.absolute]="!!(dataService.sumRow$ | async)"
    [ngStyle]="{ height: rowHeight + 'px', bottom: (reserveHeightForScrollbar || 0) + 'px' }"
>
    <span *ngIf="config?.showTotalRowCount && dataService?.totalRowCount$ | async"
        ><strong>Antall rader:</strong> {{ dataService?.totalRowCount$ | async }}</span
    >
    <span *ngIf="config?.multiRowSelect && sumMarkedRows"
        ><strong>Sum markerte rader:</strong> {{ sumMarkedRows }} ({{ markedRowCount }} stk)</span
    >
    <span *ngIf="config?.multiRowSelect && !sumMarkedRows"
        ><strong>Antall markerte rader:</strong> {{ markedRowCount }} stk</span
    >
</section>

<section *ngIf="usePagination" class="bottom-bar">
    <!-- Note that row-count appears twice in this file, make changes in both -->
    <section class="row-count" *ngIf="!config?.hideRowCount">
        <span *ngIf="config?.showTotalRowCount && dataService?.totalRowCount$ | async"
            ><strong>Antall rader:</strong> {{ dataService?.totalRowCount$ | async }}</span
        >
        <span *ngIf="config?.multiRowSelect && sumMarkedRows"
            ><strong>Sum markerte rader:</strong> {{ sumMarkedRows }} ({{ markedRowCount }} stk)</span
        >
        <span *ngIf="config?.multiRowSelect && !sumMarkedRows"
            ><strong>Antall markerte rader:</strong> {{ markedRowCount }} stk</span
        >
    </section>

    <section class="pagination" *ngIf="usePagination && paginationInfo?.pageCount > 1">
        <i class="material-icons" (click)="paginate('first')">first_page</i>
        <i class="material-icons" (click)="paginate('prev')">keyboard_arrow_left</i>

        <span class="pagination-info" *ngIf="paginationInfo">
            Side
            <input
                type="text"
                #pageInput
                [value]="paginationInfo.currentPage"
                (change)="paginationInputChange(pageInput.value)"
                (keydown.enter)="$event.stopPropagation()"
            />
            av {{ paginationInfo.pageCount }}
        </span>

        <i class="material-icons" (click)="paginate('next')">keyboard_arrow_right</i>
        <i class="material-icons" (click)="paginate('last')">last_page</i>
    </section>
</section>
