<section role="dialog" class="uni-modal xs">
    <header>{{ action === 'approve' || action === 'viewHistoricApproval' ? 'Godkjenning' : 'Avvis' }}</header>

    <article>
        <section *ngIf="busy" class="modal-spinner">
            <mat-spinner class="c2a"></mat-spinner>
        </section>

        <span>Her kan du se hvem som har blitt tildelt faktura og status på godkjenning.</span>
        <section *ngIf="!!approvalToDisplay" class="flex-column w-100 h-100 mt-2">
            <approval-detail [item]="approvalToDisplay"></approval-detail>
        </section>

        <label *ngIf="currentUsersApproval" class="uni-label mt-4">
            <span>Kommentar</span>
            <textarea [(ngModel)]="comment" [ngClass]="{ error: commentMissing }"></textarea>
        </label>

        <small *ngIf="commentMissing" class="color-bad"> Vennligst begrunn avvisning i kommentarfeltet. </small>
    </article>

    <footer class="end mt-1">
        <button [ngClass]="currentUsersApproval ? 'secondary' : 'c2a'" (click)="onClose.emit(false)">Lukk</button>

        <ng-container *ngIf="currentUsersApproval">
            <button *ngIf="action === 'reject'" (click)="submit()" class="bad">Avvis</button>

            <button *ngIf="action === 'approve'" (click)="submit()" class="c2a">Godkjenn</button>
        </ng-container>
    </footer>
</section>
