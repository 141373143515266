import { Injectable } from '@angular/core';
import { BizHttp } from '../../../framework/core/http/BizHttp';
import { Department } from '../../unientities';
import { UniHttp } from '../../../framework/core/http/http';
import { FieldType, UniFieldLayout } from '@uni-framework/ui/uniform';

@Injectable({ providedIn: 'root' })
export class DepartmentService extends BizHttp<Department> {
    constructor(http: UniHttp) {
        super(http);

        this.relativeURL = Department.RelativeUrl;
        this.entityType = Department.EntityType;
        this.DefaultOrderBy = null;

        this.cacheSettings.timeout = 600000; // 10 min
    }

    getSelectField(label: string, property: string, departments?: Department[]) {
        return <Partial<UniFieldLayout>>{
            FieldType: FieldType.DROPDOWN,
            Property: property,
            Label: label,
            FeaturePermission: 'ui.dimensions',
            Options: {
                source: departments || this.GetAll(undefined, undefined, false),
                valueProperty: 'ID',
                template: (department: Department) => department.Name || '',
                itemHidden: (department) => !department.Visible,
            },
        };
    }
}
