import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { UniTableColumn } from '@uni-framework/ui/unitable';

@Component({
    selector: 'status-cell',
    template: `
        <span *ngIf="text && !isSumRow" [matTooltip]="tooltip">
            <!-- Success -->
            <svg
                *ngIf="type === 'success'"
                [ngClass]="type"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M7 14A7 7 0 117 0a7 7 0 010 14zm-.698-4.2l4.95-4.95-.99-.99-3.96 3.96-1.98-1.98-.99.99 2.97 2.97z"
                    fill="currentColor"
                />
            </svg>

            <!-- Active (/queue?) -->
            <svg
                *ngIf="type === 'processing' || type === 'pending'"
                [ngClass]="type"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M7 14A7 7 0 1 0 7 0a7 7 0 0 0 0 14ZM4.172 9.464l.18-.085a3.493 3.493 0 0 0 1.713 1.071c.82.225 1.7.15 2.478-.213a3.612 3.612 0 0 0 1.756-1.761c.29-.632.386-1.327.28-2l.181-.084c.125.726.027 1.48-.287 2.161a3.806 3.806 0 0 1-1.85 1.855 3.806 3.806 0 0 1-2.61.226 3.682 3.682 0 0 1-1.84-1.17Zm6.588-3.072 1.178-.55a4.913 4.913 0 0 1-.282 3.142 5.084 5.084 0 0 1-2.506 2.554 5.084 5.084 0 0 1-3.568.279 4.912 4.912 0 0 1-2.588-1.804l1.178-.55a3.681 3.681 0 0 0 1.84 1.171 3.804 3.804 0 0 0 2.611-.225 3.805 3.805 0 0 0 1.85-1.855 3.682 3.682 0 0 0 .287-2.162ZM2.37 8.21l1.182-.551a3.494 3.494 0 0 1 .28-2 3.612 3.612 0 0 1 1.756-1.762 3.612 3.612 0 0 1 2.478-.213c.67.183 1.265.557 1.713 1.071l1.18-.55a4.724 4.724 0 0 0-2.466-1.704 4.89 4.89 0 0 0-3.432.268A4.89 4.89 0 0 0 2.65 5.227a4.724 4.724 0 0 0-.28 2.984ZM8.55 2.319a4.913 4.913 0 0 1 2.588 1.804l-.178.082a4.724 4.724 0 0 0-2.466-1.704 4.89 4.89 0 0 0-3.432.268A4.89 4.89 0 0 0 2.65 5.226a4.724 4.724 0 0 0-.28 2.985l-.177.082a4.913 4.913 0 0 1 .281-3.142 5.084 5.084 0 0 1 2.507-2.554 5.084 5.084 0 0 1 3.568-.278Z"
                    fill="currentColor"
                />
            </svg>

            <!-- Deactivated -->
            <svg
                *ngIf="type === 'deactivated'"
                [ngClass]="type"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M7 14A7 7 0 1 0 7 0a7 7 0 0 0 0 14ZM3.753 7.735h6.47V6.31h-6.47v1.425Z"
                    fill="currentColor"
                />
            </svg>

            <!-- Warning -->
            <svg
                *ngIf="type === 'warning'"
                [ngClass]="type"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M7 14A7 7 0 1 1 7 0a7 7 0 0 1 0 14Zm-.7-7.7v4.2h1.4V6.3H6.3Zm0-2.8v1.4h1.4V3.5H6.3Z"
                    fill="currentColor"
                />
            </svg>

            <!-- Error -->
            <svg
                *ngIf="type === 'error'"
                [ngClass]="type"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M7 0a7 7 0 1 1 0 14A7 7 0 0 1 7 0Zm.7 7.7V3.5H6.3v4.2h1.4Zm0 2.8V9.1H6.3v1.4h1.4Z"
                    fill="currentColor"
                />
            </svg>

            <!-- Info -->
            <svg
                *ngIf="type === 'info'"
                [ngClass]="type"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M7 14A7 7 0 1 1 7 0a7 7 0 0 1 0 14Zm-.7-7.7v4.2h1.4V6.3H6.3Zm0-2.8v1.4h1.4V3.5H6.3Z"
                    fill="currentColor"
                />
            </svg>

            {{ text }}
        </span>
    `,
    styleUrls: ['./status-cell.sass'],
})
export class StatusCellRenderer implements ICellRendererAngularComp {
    params: ICellRendererParams;
    isSumRow: boolean;

    type: 'pending' | 'processing' | 'success' | 'warning' | 'error' | 'deactivated' | 'info';
    text: string;
    tooltip: string;

    agInit(params: ICellRendererParams): void {
        if (params.node?.data?._isSumRow) {
            this.isSumRow = true;
            return;
        }

        this.params = params;

        const column: UniTableColumn = params.colDef && params.colDef['_uniTableColumn'];
        const statusMap = column?.statusMap || {};

        let status;
        if (params.value) {
            status = statusMap[params.value];
        } else if (statusMap[0]) {
            status = statusMap[0];
        }

        if (status) {
            if (typeof status === 'string') {
                this.text = status;
            } else {
                this.text = status.label;
                this.type = status.type;
                if (status.tooltip) {
                    this.tooltip = status.tooltip(params.node.data);
                }
            }
        }
    }

    refresh(): boolean {
        return false;
    }
}
