import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { IUniTab } from './tabstrip';
import { Router, NavigationEnd } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { BrowserStorageService } from '@uni-framework/core/browserStorageService';
import { theme } from 'src/themes/theme';
import { UniTranslationService } from '@app/services/common/translationService';
import { AuthService } from '@app/authService';

// The enum is numbered based on its parent app:
//      1×× - Key figures
//      2×× - Sales
//      3×× - Accounting
//      4×× - Bank
//      5×× - Salary
//      6×× - Timetracking
//      7×× - Settings
//      9×× - Discontinued
export enum UniModules {
    Dashboard = 100,
    BureauDashboard = 101,
    Settings = 102,
    Reports = 103,
    UniQuery = 104,
    UniTicker = 105,
    Assignments = 106,
    Marketplace = 107,
    Sharings = 108,
    LicenseInfo = 109,
    SubSettings = 110,

    Sales = 200,
    Customers = 201,
    Quotes = 202,
    Orders = 203,
    Invoices = 204,
    Products = 205,
    Reminders = 206,
    Contacts = 207,
    ProductGroup = 208,
    Sellers = 209,
    Projects = 210,
    CustomDimensions = 211,
    KIDSettings = 212,
    RecurringInvoice = 213,
    BatchInvoice = 214,
    SupplierGoods = 215,
    PriceDeals = 216,

    Accounting = 300,
    Transquery = 301,
    TransqueryDetails = 302,
    Accountsettings = 303,
    Vatsettings = 304,
    VatReport = 305,
    Payments = 306,
    AccountingReports = 307,
    Suppliers = 308,
    AccountQuery = 309,
    PredefinedDescription = 310,
    PostPost = 311,
    Bills = 312,
    Departments = 313,
    CurrencyExchange = 314,
    CurrencyOverride = 315,
    Budget = 316,
    CostAllocation = 317,
    Inbox = 318,
    BalanceSearch = 319,
    AnnualSettlement = 320,
    AnnualAccount = 321,
    CustomerLedger = 322,

    Bank = 450,
    Payment = 451,
    Incomming = 452,
    BankReconciliation = 453,

    Salary = 500,
    Employees = 501,
    Wagetypes = 502,
    Payrollrun = 503,
    Amelding = 504,
    Categories = 505,
    Salarybalances = 506,
    Supplements = 507,
    AnnualStatements = 508,
    AltinnOverview = 509,
    Travel = 510,
    TravelType = 511,
    SalarybalanceTemplates = 512,
    OTPExport = 513,
    VariablePayrolls = 514,
    Regulative = 515,
    IncomeReports = 516,
    Absence = 517,

    WorkProfiles = 600,
    Workers = 601,
    WorkTypes = 602,
    Timesheets = 603,
    InvoiceHours = 604,
    TimeOff = 605,
    HourTotals = 606,

    Jobs = 700,
    Translations = 701,
    Dimensions = 705,
    About = 706,
    System = 707,
    GDPRList = 708,
    Flow = 709,
    ImportCentral = 710,
    ImportCentralLog = 711,
}

const STORAGE_KEY = 'navbarTabs';

@Injectable()
export class TabService {
    tabs: IUniTab[];
    currentActiveTab: IUniTab;
    currentActiveIndex: number;

    tabs$: BehaviorSubject<IUniTab[]> = new BehaviorSubject([]);

    constructor(
        private router: Router,
        private browserStorage: BrowserStorageService,
        private titleService: Title,
        private translateService: UniTranslationService,
        private authService: AuthService,
    ) {
        this.tabs = this.browserStorage.getItem(STORAGE_KEY) || [];

        if (this.router.url === '/') {
            this.tabs.forEach((tab) => (tab.active = false));
        }

        this.tabs.forEach((tab, i) => {
            if (tab.active) {
                this.currentActiveTab = tab;
                this.currentActiveIndex = i;
            }
        });

        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.onNavigationEnd(event);
            }
        });

        // REVISIT: Should probably save tabs per company instead of discarding when changing
        this.authService.companyChange.subscribe(() => this.removeAllTabs());

        this.tabs$.next(this.tabs);
    }

    /**
     * TO FIND WHAT MODULEID YOU SHOULD USE, GO TO
     * https://unimicro.atlassian.net/wiki/pages/viewpage.action?spaceKey=AD&title=TabService
     */
    addTab(newTab: IUniTab) {
        let duplicate = false;
        const moduleCheck = { index: 0, exists: false };
        this.tabs.forEach((tab, i) => {
            tab.active = false;
            if (tab.name === newTab.name) {
                tab.active = true;
                duplicate = true;
                this.currentActiveIndex = i;
            }

            if (tab.moduleID === newTab.moduleID) {
                moduleCheck.exists = true;
                moduleCheck.index = i;
            }
        });

        if (moduleCheck.exists) {
            newTab.active = true;
            this.tabs[moduleCheck.index] = newTab;
            this.updateTabStorage();
            this.currentActiveIndex = moduleCheck.index;
            duplicate = true;
        }

        if (!duplicate) {
            newTab.active = true;
            this.tabs.push(newTab);
            this.updateTabStorage();
            this.currentActiveIndex = this.tabs.length - 1;
        }

        this.currentActiveTab = newTab;

        if (this.tabs.length > 8) {
            this.tabs.splice(0, 1);
        }

        this.tabs$.next(this.tabs);
        this.titleService.setTitle(this.translateService.translate(this.currentActiveTab.name));
    }

    updateTab(tabData: Partial<IUniTab>) {
        const tab = this.tabs.find((t) => t.name === tabData.name);
        if (tab) {
            Object.assign(tab, tabData);
            this.updateTabStorage();
            this.tabs$.next(this.tabs);
        }
    }

    activateTab(index: number): Promise<boolean> {
        if (index !== this.currentActiveIndex) {
            return this.router.navigateByUrl(this.tabs[index].url).then((navigationSuccess) => {
                if (navigationSuccess) {
                    this.tabs.forEach((tab) => (tab.active = false));
                    this.tabs[index].active = true;
                    this.currentActiveIndex = index;
                    this.currentActiveTab = this.tabs[index];

                    this.updateTabStorage();
                    this.tabs$.next(this.tabs);

                    this.titleService.setTitle(this.translateService.translate(this.currentActiveTab.name));
                }

                return navigationSuccess;
            });
        }
    }

    onNavigationEnd(event: NavigationEnd) {
        try {
            // Set all tabs to inactive if none of them has an url that looks like the url we navigated to
            const tabExists = this.tabs.some((tab) => {
                const tabUrl = tab.url
                    .split('?')[0]
                    .split(';')[0]
                    .split('/')
                    .filter((routePart) => routePart && isNaN(parseInt(routePart, 10)))
                    .join('/');

                return (event.urlAfterRedirects || event.url).includes(tabUrl);
            });

            if (!tabExists) {
                this.tabs.forEach((tab) => (tab.active = false));
                this.currentActiveIndex = undefined;
                this.currentActiveTab = undefined;
                this.tabs$.next(this.tabs);

                this.titleService.setTitle(theme.appName);
            }
        } catch (e) {
            console.error(e);
        }
    }

    closeTab(closeIndex: number = this.currentActiveIndex): void {
        const removeAndUpdate = (index) => {
            this.tabs.splice(index, 1);
            this.tabs$.next(this.tabs);
            this.updateTabStorage();
            this.currentActiveIndex = this.tabs.findIndex((t) => t.active);
        };

        // If closed tab is active then we need to first check that we can
        // navigate away from it, then actually remove the tab
        if (closeIndex === this.currentActiveIndex) {
            let navigationPromise: Promise<boolean>;

            if (this.tabs[closeIndex + 1]) {
                navigationPromise = this.activateTab(closeIndex + 1);
            } else if (this.tabs[closeIndex - 1]) {
                navigationPromise = this.activateTab(closeIndex - 1);
            } else {
                navigationPromise = this.router.navigateByUrl('/');
            }

            navigationPromise
                .then((navigationSuccess) => {
                    if (navigationSuccess) {
                        removeAndUpdate(closeIndex);
                    }
                })
                .catch(() => {});
        } else {
            // If tab is not active we don't have to check anything, just remove it
            removeAndUpdate(closeIndex);
        }
    }

    closeLeftOf(index: number) {
        if (index === 0) {
            return;
        }

        this.tabs.splice(0, index);
        this.tabs$.next(this.tabs);
        this.updateTabStorage();

        if (this.currentActiveIndex <= index) {
            this.activateTab(0);
        }
    }

    closeRightOf(index: number) {
        if (index === this.tabs.length - 1) {
            return;
        }

        this.tabs.splice(index + 1);
        this.tabs$.next(this.tabs);
        this.updateTabStorage();

        if (this.currentActiveIndex > index) {
            this.activateTab(index);
        }
    }

    closeAllOthers(index: number) {
        const tab = this.tabs[index];
        this.tabs = [tab];
        this.tabs$.next(this.tabs);
        this.updateTabStorage();

        this.activateTab(0);
    }

    removeAllTabs() {
        this.tabs = [];
        this.tabs$.next(this.tabs);
        this.updateTabStorage();
    }

    private updateTabStorage() {
        this.browserStorage.setItem(STORAGE_KEY, this.tabs);
    }
}
