import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PeriodDateFormatPipe } from './periodDateFormatPipe';
import { UniCurrencyPipe } from './uniCurrencyPipe';
import { UniNumberFormatPipe } from './uniNumberFormatPipe';
import { SkipSanitizationPipe } from './skipSanitizationPipe';
import { EntitytypeTranslationPipe } from './entitytype-translation.pipe';
import { ElsaContractTypePipe } from './elsaContractTypePipe';
import { ProductPurchaseStatusPipe } from './productPurchaseStatusPipe';
import { ProductTypePipe } from './productTypePipe';
import { UniAccountTypePipe } from './uniAccountTypePipe';
import { ElsaEntityStatusPipe } from './elsaEntityStatusPipe';
import { ElsaUserLicenseTypePipe } from './elsaUserLicenseTypePipe';
import { ToLowerCasePipe } from '@uni-framework/pipes/toLowerCasePipe';
import { ExternalProviderPipe } from './externalProviderPipe';

@NgModule({
    imports: [CommonModule],
    declarations: [
        SkipSanitizationPipe,
        PeriodDateFormatPipe,
        UniCurrencyPipe,
        UniNumberFormatPipe,
        EntitytypeTranslationPipe,
        ElsaContractTypePipe,
        ElsaEntityStatusPipe,
        ElsaUserLicenseTypePipe,
        ProductPurchaseStatusPipe,
        ProductTypePipe,
        UniAccountTypePipe,
        ExternalProviderPipe,
        ToLowerCasePipe,
    ],
    providers: [
        SkipSanitizationPipe,
        PeriodDateFormatPipe,
        UniCurrencyPipe,
        UniNumberFormatPipe,
        EntitytypeTranslationPipe,
        ElsaContractTypePipe,
        ElsaEntityStatusPipe,
        ElsaUserLicenseTypePipe,
        ProductPurchaseStatusPipe,
        ProductTypePipe,
        UniAccountTypePipe,
        ExternalProviderPipe,
        ToLowerCasePipe,
    ],
    exports: [
        SkipSanitizationPipe,
        CommonModule,
        PeriodDateFormatPipe,
        UniCurrencyPipe,
        UniNumberFormatPipe,
        EntitytypeTranslationPipe,
        ElsaContractTypePipe,
        ElsaEntityStatusPipe,
        ElsaUserLicenseTypePipe,
        ProductPurchaseStatusPipe,
        ProductTypePipe,
        UniAccountTypePipe,
        ExternalProviderPipe,
        ToLowerCasePipe,
    ],
})
export class UniPipesModule {}
