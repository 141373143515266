import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';
import { IColumnTooltip } from '@uni-framework/ui/unitable/config/unitableColumn';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
    selector: 'tooltip-cell',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <span *ngIf="params.value" class="cell-value">{{ params.value }}</span>
        <svg
            *ngIf="tooltipText && !useErrorIcon"
            [ngClass]="classes"
            [ngStyle]="styles"
            [matTooltip]="tooltipText"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
        >
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path
                d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"
            />
        </svg>
        <svg
            *ngIf="tooltipText && useErrorIcon"
            [ngClass]="classes"
            [ngStyle]="styles"
            [matTooltip]="tooltipText"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
        >
            <path
                d="M11 15h2v2h-2v-2zm0-8h2v6h-2V7zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"
            />
        </svg>
    `,
})
export class TooltipCellRenderer {
    params: ICellRendererParams;
    icon: string;
    tooltipText: string;
    useErrorIcon: boolean;

    classes: string[];
    styles: any;

    @HostBinding('class') hostClass: string;

    agInit(params: ICellRendererParams): void {
        this.params = params;
        const col = params['columnConfig'];
        const tooltipData: IColumnTooltip = col.tooltipResolver(params.node.data);
        if (tooltipData) {
            this.icon = tooltipData.type === 'warn' || tooltipData.type === 'bad' ? 'error' : 'info';
            this.hostClass = `icon-align-${tooltipData.alignment || 'right'}`;
            this.tooltipText = tooltipData.text;
            this.useErrorIcon = tooltipData.type === 'warn' || tooltipData.type === 'bad';

            this.classes = [tooltipData.type, tooltipData.class || ''];

            const size = tooltipData.size ? `${tooltipData.size}px` : '22px';
            this.styles = { width: size, height: size };
        }
    }

    refresh(): boolean {
        return false;
    }
}
