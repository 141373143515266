import { Component, Input, Output, EventEmitter, NgModule, ChangeDetectionStrategy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { switchMap } from 'rxjs/operators';
import { File as UniFile, StatusCode } from '@uni-entities';
import { FileFromInboxModal, UniModalService } from '@uni-framework/uni-modal';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { AuthService } from '@app/authService';
import { ErrorService } from '@app/services/common/errorService';
import { FileService } from '@app/services/common/fileService';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

let inputIdCounter = 1;

@Component({
    selector: 'file-input',
    templateUrl: './file-input.html',
    styleUrls: ['./file-input.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileInput {
    @Input() entityType: string;
    @Input() entityID: number;
    @Input() layout: 'large' | 'medium' | 'small' | 'button-only' = 'large';
    @Input() canUseInbox: boolean;
    @Input() fileLabel: string;
    @Input() multiple = true;
    @Input() upload = true;
    @Input() accept: string;
    @Input() files: File[];

    @Output() filesChange = new EventEmitter<File[]>();
    @Output() filesUploaded = new EventEmitter<UniFile[]>();

    isDraggingOver: boolean;
    inputId = `upload-input-${inputIdCounter++}`;

    uploading$ = new BehaviorSubject(false);

    selectFilesButtonLabel: string;

    hasInboxPermission: boolean;

    constructor(
        private modalService: UniModalService,
        private fileService: FileService,
        private errorService: ErrorService,
        private authService: AuthService,
    ) {}

    ngOnChanges() {
        // Show "Last opp fil" instead of "Velg fil" if we're just showing the buttons.
        // Even though the latter is more accurate, it might cause confusion when we don't
        // show the drag and drop text.
        if (this.layout === 'button-only') {
            this.selectFilesButtonLabel = this.multiple ? 'Last opp filer' : 'Last opp fil';
        } else {
            this.selectFilesButtonLabel = this.multiple ? 'Velg filer' : 'Velg fil';
        }
    }

    ngOnInit() {
        this.hasInboxPermission = this.authService.canActivateRoute(this.authService.currentUser, '/accounting/bills');
    }

    ngOnDestroy() {
        this.uploading$.complete();
    }

    onDragover(event: DragEvent) {
        event.preventDefault();
        event.stopPropagation();
        this.isDraggingOver = true;
    }

    onDrop(event: DragEvent) {
        event.preventDefault();
        event.stopPropagation();

        this.isDraggingOver = false;

        this.handleFileList(event.dataTransfer.files);
    }

    onFileInputChange(event) {
        const fileList = (event.target as HTMLInputElement)?.files;
        this.handleFileList(fileList);
        event.target.value = null;
    }

    removeFile(file: File, clickEvent?: MouseEvent) {
        clickEvent?.stopPropagation();
        clickEvent?.preventDefault();

        this.files = this.files.filter((f) => f !== file);
        this.filesChange.emit(this.files);
    }

    private handleFileList(fileList: FileList) {
        if (fileList.length) {
            const files = this.files || [];

            let newFiles = Array.from(fileList).filter((file) => {
                return !files.some((f) => this.isDuplicateFile(f, file));
            });

            if (this.multiple) {
                this.files = files.concat(newFiles);
            } else {
                const file = newFiles[0] || files[0];
                this.files = [file];
            }

            this.filesChange.emit(this.files);

            if (this.upload) {
                this.uploadFiles(fileList);
            }
        }
    }

    private uploadFiles(fileList: FileList) {
        const files = this.multiple ? Array.from(fileList) : [fileList.item(0)];
        if (!files.length) {
            return;
        }

        this.uploading$.next(true);
        this.fileService
            .batchUploadFiles(files, this.entityType, this.entityID)
            .pipe(
                switchMap((res) => {
                    const fileIds = res?.map((item) => item.ExternalId);
                    return this.fileService.GetAll(`filter=ID in (${fileIds})`);
                }),
            )
            .subscribe(
                (files) => this.filesUploaded.next(files),
                (err) => this.errorService.handle(err),
            )
            .add(() => this.uploading$.next(false));
    }

    private isDuplicateFile(fileA: File, fileB: File) {
        return fileA.name === fileB.name && fileA.size === fileB.size;
    }

    getFileFromInbox() {
        this.modalService
            .open(FileFromInboxModal, {
                data: { multiselect: this.multiple },
            })
            .onClose.subscribe((files: UniFile[]) => {
                if (!files?.length) return;

                if (this.entityType && this.entityID) {
                    this.linkInboxFilesToEntity(files).subscribe({
                        next: () => this.filesUploaded.next(files),
                        error: (err) => this.errorService.handle(err),
                    });
                } else {
                    this.filesUploaded.next(files);
                }
            });
    }

    private linkInboxFilesToEntity(files: UniFile[]) {
        if (!this.entityType || !this.entityID || !files?.length) {
            return;
        }

        const requests = files?.map((file) => {
            return this.fileService
                .linkFile(this.entityType, this.entityID, file.ID)
                .pipe(switchMap(() => this.fileService.tagFileStatus(file.ID, StatusCode.Completed)));
        });

        return forkJoin(requests);
    }
}

@NgModule({
    imports: [CommonModule, MatProgressSpinnerModule],
    declarations: [FileInput],
    exports: [FileInput],
})
export class FileInputModule {}
