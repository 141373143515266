import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { ErrorService } from '@app/services/common/errorService';
import { StatusHistoryItem } from '@app/services/common/statusService';
import { StatusHistoryService } from './status-history.service';
import { TaskType } from '@uni-entities';
import { TaskService } from '@app/services/assignments/taskService';
import { UniModalService } from '@uni-framework/uni-modal';
import { InvoiceApprovalModal } from '../../invoice-approval-modal/invoice-approval-modal';
import { findLast } from 'lodash-es';
import { rigDate } from '../../utils/rig-date';

@Component({
    selector: 'toolbar-status-history',
    templateUrl: './status-history.html',
    styleUrls: ['./status-history.sass'],
})
export class ToolbarStatusHistory {
    @Input() entityType: string;
    @Input() entity: any;

    statusHistory: StatusHistoryItem[];
    statusHistoryLastApprovedEntryDate: Date | string;
    busy: boolean;

    constructor(
        public historyService: StatusHistoryService,
        private errorService: ErrorService,
        private cdr: ChangeDetectorRef,
        private taskService: TaskService,
        private modalService: UniModalService,
    ) {}

    ngOnChanges() {
        this.loadStatusHistory(); // StatusHistoryService handles caching
    }

    loadStatusHistory(forceRefresh?: boolean) {
        if (this.entity && this.entityType) {
            this.busy = true;
            this.historyService
                .loadStatusHistory(this.entityType, this.entity, forceRefresh)
                .subscribe({
                    next: (history) => {
                        this.statusHistory = history;
                        if (this.entityType.toLowerCase() === 'supplierinvoice') {
                            const lastApprovedStatusDate = findLast(history ?? [], (item: StatusHistoryItem) =>
                                ['godkjent', 'avvist'].includes(item.statusText.toLowerCase()),
                            )?.date;
                            const lastAssignedStatusDate = findLast(history ?? [], (item: StatusHistoryItem) =>
                                ['tildelt', 'opprettet'].includes(item.statusText.toLowerCase()),
                            )?.date;
                            this.statusHistoryLastApprovedEntryDate = rigDate(lastApprovedStatusDate).isAfter(
                                lastAssignedStatusDate,
                            )
                                ? lastApprovedStatusDate
                                : undefined;
                        }
                    },
                    error: (err) => this.errorService.handle(err),
                })
                .add(() => {
                    this.busy = false;
                    setTimeout(() => this.cdr.markForCheck());
                });
        }
    }
    viewApprovalHistory() {
        this.taskService
            .GetAll(
                `filter=(Model.Name eq '${this.entityType}') AND (type eq ${TaskType.Approval}) AND (entityID eq ${this.entity.ID})&orderby=CreatedAt desc&top=1`,
                ['model', 'approvalplan', 'approvals'],
            )
            .subscribe({
                next: (tasks) =>
                    this.modalService.open(InvoiceApprovalModal, {
                        data: {
                            entityType: this.entityType,
                            entity: this.entity,
                            entityID: this.entity.ID,
                            task: tasks[0],
                            action: 'viewHistoricApproval',
                        },
                    }),
                error: (err) => this.errorService.handle(err),
            });
    }
}
