<section
    #tabContainer
    tabindex="0"
    class="tab-container"
    (keydown)="onKeyDown($event)"
    (mousedown)="hideFocusIndicator = true"
    (focus)="syncFocusIndexToActiveIndex()"
    (focusout)="onFocusOut()"
>
    <ng-container *ngFor="let tab of sortedTabs; let idx = index">
        <!-- Regular tab -->
        <a
            *ngIf="!useRouterLinkTabs && !tab.hidden && !tab._hiddenByUser && tab._hasPermission"
            (click)="onTabClick(tab)"
            [class.active]="activeIndex === tab._originalIndex"
            [class.focused]="!hideFocusIndicator && focusIndex === idx"
            [attr.aria-disabled]="tab.disabled"
            [attr.data-testid]="tab[labelProperty]"
            class="tab"
        >
            <uni-tab-content [tab]="tab" [labelProperty]="labelProperty" [counterProperty]="counterProperty">
            </uni-tab-content>
        </a>

        <!-- Router link tab -->
        <a
            *ngIf="useRouterLinkTabs && !tab.hidden && !tab._hiddenByUser && tab._hasPermission"
            (click)="onTabClick(tab)"
            [attr.aria-disabled]="tab.disabled"
            [routerLink]="tab.path"
            [queryParams]="tab.queryParams"
            [routerLinkActiveOptions]="{ exact: pathMatchExact }"
            routerLinkActive
            #rla="routerLinkActive"
            [class.active]="rla.isActive"
            [attr.data-testid]="tab[labelProperty]"
            class="tab"
        >
            <uni-tab-content [tab]="tab" [labelProperty]="labelProperty" [counterProperty]="counterProperty">
            </uni-tab-content>
        </a>
    </ng-container>
</section>

<section *ngIf="configStoreKey" class="user-preference-dropdown">
    <button #visiblityDropdownToggle class="icon-button">
        <i class="material-icons">settings</i>
    </button>

    <dropdown-menu [trigger]="visiblityDropdownToggle" [closeOnClick]="false" [minWidth]="200">
        <ng-template>
            <section class="dropdown-menu-header">Endre synlighet og rekkefølge på faner</section>

            <section cdkDropList (cdkDropListDropped)="onDragDropEnd($event)" class="tabs-drag-drop-list">
                <ng-container *ngFor="let tab of sortedTabs">
                    <section
                        cdkDrag
                        cdkDragBoundary=".tabs-drag-drop-list"
                        *ngIf="tab._hasPermission"
                        class="dropdown-menu-item drag-and-drop-item"
                        (click)="toggleTabVisibility(tab)"
                    >
                        <rig-checkbox [checked]="!tab._hiddenByUser" (click)="$event.preventDefault()">
                            {{ tab[labelProperty] | translate }}
                        </rig-checkbox>

                        <span cdkDragHandle class="drag-handle">
                            <i class="material-icons">reorder</i>
                        </span>
                    </section>
                </ng-container>
            </section>

            <section (click)="resetUserPreferences()" class="dropdown-menu-item reset-tab-visibility">
                Tilbake til standard
            </section>
        </ng-template>
    </dropdown-menu>
</section>

<!-- Dropdown for overflowing items -->
<ng-container *ngIf="overflowIndex >= 0">
    <a #dropdownToggle class="tab-dropdown-toggle" [class.active]="activeIndex >= overflowIndex">
        Vis mer
        <i class="material-icons">expand_more</i>
    </a>

    <dropdown-menu [trigger]="dropdownToggle">
        <ng-template>
            <ng-container *ngFor="let tab of sortedTabs | slice: overflowIndex">
                <!-- Regular tab -->
                <a
                    class="dropdown-menu-item"
                    *ngIf="!useRouterLinkTabs && !tab.hidden && !tab._hiddenByUser && tab._hasPermission"
                    [attr.aria-disabled]="tab.disabled"
                    (click)="onTabClick(tab)"
                >
                    <uni-tab-content [tab]="tab" [labelProperty]="labelProperty" [counterProperty]="counterProperty">
                    </uni-tab-content>
                </a>

                <!-- Router link tab -->
                <a
                    class="dropdown-menu-item"
                    *ngIf="useRouterLinkTabs && !tab.hidden && !tab._hiddenByUser && tab._hasPermission"
                    (click)="onTabClick(tab)"
                    [attr.aria-disabled]="tab.disabled"
                    [routerLink]="tab.path"
                >
                    <uni-tab-content [tab]="tab" [labelProperty]="labelProperty" [counterProperty]="counterProperty">
                    </uni-tab-content>
                </a>
            </ng-container>
        </ng-template>
    </dropdown-menu>
</ng-container>
