import { Injectable } from '@angular/core';
import { BizHttp } from '../../../framework/core/http/BizHttp';
import { Terms, TermsType } from '../../unientities';
import { UniHttp } from '../../../framework/core/http/http';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class TermsService extends BizHttp<Terms> {
    constructor(http: UniHttp) {
        super(http);

        this.relativeURL = Terms.RelativeUrl;
        this.entityType = Terms.EntityType;
        this.DefaultOrderBy = null;

        this.cacheSettings.timeout = 60000 * 15; // 15 minutes
    }

    getPaymentTerms() {
        return this.GetAll().pipe(
            map((terms) => (terms || []).filter((term) => term.TermsType === TermsType.PaymentTerms)),
        );
    }

    getDeliveryTerms() {
        return this.GetAll().pipe(
            map((terms) => (terms || []).filter((term) => term.TermsType === TermsType.DeliveryTerms)),
        );
    }
}
