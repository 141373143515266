import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'uni-avatar',
    styleUrls: ['./uni-avatar.sass'],
    template: `
        <div
            *ngIf="initials"
            [style.background]="'var(--avatar-' + colorIndex + ')'"
            class="avatar flex align-center justify-center"
        >
            <span>{{ initials }}</span>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UniAvatar {
    @Input() name: string;
    @Input() icon: string;
    @Input() colorIndex: number;

    initials: string;
    color: string;

    ngOnChanges() {
        if (this.name) {
            this.initials = this.getInitials(this.name);
        }

        if (!this.colorIndex && this.initials) {
            this.colorIndex = this.getColorIndex(this.name);
        }
    }

    private getInitials(name: string) {
        let initials = name.substring(0, 2);

        let split = name.split(' ');

        if (split.length < 2) {
            split = name.split('-');
        }

        if (split.length >= 2) {
            initials = split[0].charAt(0) + split[1].charAt(0);
        }

        return initials.toUpperCase();
    }

    private getColorIndex(name: string) {
        const charCodeSum = name
            .split('')
            .map((char) => char.charCodeAt(0))
            .reduce((sum, charCode) => sum + charCode, 0);

        return (charCodeSum % 8) + 1;
    }
}
