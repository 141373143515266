<section class="autocomplete-input-wrapper flex align-center" (clickOutside)="onClickOutside()">
    <section class="input-with-button w-100 align-center" (keydown.f3)="onF3Key($event)">
        <input
            type="text"
            #inputElement
            [formControl]="searchControl"
            (keydown)="onKeyDown($event)"
            [readonly]="readonly"
            (focus)="onFocus()"
            (click)="onClick()"
            [placeholder]="options?.placeholder || ''"
        />

        <!-- Search button -->
        <button
            tabindex="-1"
            type="button"
            *ngIf="!options?.showClearButton || !value"
            [disabled]="readonly"
            (click)="$event.stopPropagation(); toggle(); inputElement.focus()"
            [attr.aria-busy]="loading$ | async"
        >
            <i class="material-icons">search</i>
        </button>

        <!-- Clear value button -->
        <button
            type="button"
            *ngIf="options?.showClearButton && value && !readonly"
            (click)="onClearButtonClick($event)"
        >
            <i class="material-icons">close</i>
        </button>
    </section>
    <ng-container *ngIf="options?.showFilterCheckboxes !== false">
        <rig-checkbox
            class="flex-0-auto ml-3"
            *ngFor="let checkbox of options?.filterCheckboxes"
            [(ngModel)]="checkbox.value"
            (ngModelChange)="performLookup(searchControl.value)"
        >
            {{ checkbox.label }}
        </rig-checkbox>
    </ng-container>
</section>

<input-dropdown-menu [input]="inputElement" [visible]="(isExpanded$ | async) && !(loading$ | async)">
    <ng-template>
        <section class="result-container">
            <!-- List view -->
            <section *ngIf="!options.resultTableColumns" class="results">
                <section
                    (click)="selectAll()"
                    class="dropdown-menu-item select-all border-bottom"
                    *ngIf="options?.experimentalMultiSelect && lookupResults?.length"
                    [attr.aria-selected]="focusGroupIndex === -1"
                >
                    <rig-checkbox
                        style="pointer-events: none"
                        [checked]="isAllSelected"
                        [(ngModel)]="isAllSelected"
                    ></rig-checkbox>
                    Marker alle
                </section>

                <section *ngFor="let result of lookupResults; let groupIndex = index" #optionGroup>
                    <span class="autocomplete-menu-header {{ groupIndex === 0 ? ' first' : '' }}">{{
                        result.label
                    }}</span>
                    <section
                        class="autocomplete-menu-item"
                        *ngFor="let item of result.items; let i = index"
                        (click)="onItemClick(item)"
                        class="dropdown-menu-item"
                        [attr.aria-selected]="groupIndex === focusGroupIndex && i === focusIndex"
                    >
                        <rig-checkbox
                            *ngIf="options?.experimentalMultiSelect"
                            [(ngModel)]="item['_selected']"
                        ></rig-checkbox>

                        <uni-avatar *ngIf="includeAvatar" [name]="item.Name" class="small mr-2"></uni-avatar>
                        {{ getDisplayValue(item) }}

                        <i
                            class="material-icons item-edit-icon"
                            *ngIf="options.editHandler && item.EmployeeNumber"
                            (click)="edit(item)"
                            title="Rediger"
                            >edit</i
                        >
                    </section>
                </section>

                <section class="no-results" *ngIf="!lookupResults?.length">Ingen resultater</section>
            </section>

            <section *ngIf="options?.createHandler" class="footer" [class.empty-result]="!lookupResults.length">
                <a (click)="create()">
                    <i class="material-icons">add</i>
                    {{ options.createLabel || 'Opprett ny' }} (F3)
                </a>
            </section>
        </section>
    </ng-template>
</input-dropdown-menu>
