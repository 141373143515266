import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ColumnIcon } from '@uni-framework/ui/unitable';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
    selector: 'cell-with-icons',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <span *ngIf="params.value" class="cell-value">{{ params.value }}</span>

        <span *ngFor="let icon of icons" class="cell-icon-container">
            <span *ngIf="icon.counter" class="counter" [ngClass]="icon.counterClass">
                {{ icon.counter }}
            </span>

            <uni-icon
                [icon]="icon.icon"
                [matIconClass]="icon.materialIconsClassOverride || 'material-icons'"
                [ngClass]="icon.class"
                [uniTooltip]="icon.tooltip"
                [tooltipTemplateData]="{ row: row }"
                [style.color]="icon.color"
                [svgSize]="icon.size"
                [matIconSize]="icon.size"
            >
            </uni-icon>
        </span>
    `,
})
export class CellWithIconsRenderer {
    params: ICellRendererParams;
    icons: ColumnIcon[];
    row;

    agInit(params: ICellRendererParams): void {
        this.params = params;
        const col = params['columnConfig'];
        this.row = params.node.data;
        this.icons = (this.row && col.iconResolver(this.row)) || [];
    }

    refresh(): boolean {
        return false;
    }
}

@Component({
    selector: 'header-cell-with-icons',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <section [uniTooltip]="icon.tooltip">
            <span *ngIf="params.displayName" class="cell-value">{{ params.displayName }}</span>

            <span *ngIf="icon" class="cell-icon-container">
                <span *ngIf="icon.counter" class="counter" [ngClass]="icon.counterClass">
                    {{ icon.counter }}
                </span>

                <uni-icon
                    [icon]="icon.icon"
                    [matIconClass]="icon.materialIconsClassOverride || 'material-icons'"
                    [ngClass]="icon.class"
                    [style.color]="icon.color"
                    [svgSize]="icon.size"
                    [matIconSize]="icon.size"
                >
                </uni-icon>
            </span>
        </section>
    `,
})
export class HeaderCellWithIconsRenderer {
    params;
    icon: ColumnIcon;
    cssClass: string;
    matIconClass: string;

    agInit(params): void {
        this.params = params;
        const col = params['columnConfig'];
        this.icon = col?.headerIcon;
    }

    refresh(): boolean {
        return false;
    }
}
