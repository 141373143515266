<section class="input-with-button">
    <input
        #input
        [attr.aria-describedby]="asideGuid"
        class="nir-url-input"
        *ngIf="control"
        type="url"
        [formControl]="control"
        [readonly]="readOnly$ | async"
        [placeholder]="field?.Placeholder || ''"
        (blur)="blurHandler()"
        (focus)="focusHandler()"
        [title]="control?.value || ''"
    />

    <button type="button" (click)="openUrl()" tabindex="-1">
        <i class="material-icons">link</i>
    </button>
</section>
