import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
    selector: '[click.stopPropagation]',
})
export class ClickStopPropagation {
    @Output('click.stopPropagation') click = new EventEmitter<MouseEvent>();

    @HostListener('click', ['$event'])
    public onClick(event: MouseEvent): void {
        event.stopPropagation();
        this.click.emit(event);
    }
}
