import { Component, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FinancialYearService } from '@app/services/accounting/financialYearService';
import { Listbox } from '@uni-framework/ui/listbox/listbox';
import { rigDate, months } from '@app/components/common/utils/rig-date';

interface PresetOption {
    label: string;
    from: string;
    to: string;
    selected?: boolean;
    class?: string;
    hiddenOnPeriodOnly?: boolean;
}

@Component({
    selector: 'date-range-preset-options',
    templateUrl: './date-range-preset-options.html',
    styleUrls: ['./date-range-preset-options.sass'],
})
export class DateRangePresetOptions {
    @ViewChild(Listbox, { read: ElementRef }) listboxElement: ElementRef<HTMLElement>;

    @Input() fromDate: Date;
    @Input() toDate: Date;
    @Input() periodOnly: boolean;

    @Output() optionSelected = new EventEmitter<PresetOption>();

    options: PresetOption[];
    selectedOption: PresetOption;

    constructor(private yearService: FinancialYearService) {}

    ngOnInit() {
        this.options = this.getFilters();
        this.updateSelectedState();
    }

    ngAfterViewInit() {
        setTimeout(() => {
            if (this.periodOnly) {
                this.focus();
            }
        });
    }

    ngOnChanges(changes) {
        if (changes.periodOnly) {
            this.options = this.getFilters();
        }

        if (changes.fromDate || changes.toDate) {
            this.updateSelectedState();
        }
    }

    focus() {
        this.listboxElement?.nativeElement?.focus();
    }

    private updateSelectedState() {
        if (!this.fromDate || !this.toDate) return;

        const from = rigDate(this.fromDate).format('YYYY.MM.DD');
        const to = rigDate(this.toDate).format('YYYY.MM.DD');

        const keepSelectedOption =
            this.selectedOption?.from === from &&
            this.selectedOption?.to === to &&
            this.options.some((option) => option === this.selectedOption);

        if (!keepSelectedOption) {
            this.selectedOption = this.options.find((f) => f.from === from && f.to === to);
        }
    }

    private getFilters(): PresetOption[] {
        const activeYear = this.yearService.getActiveFinancialYear()?.Year || new Date().getFullYear();
        const dateInActiveYear = rigDate({ year: activeYear });

        return [
            {
                label: 'Hittil i år',
                from: rigDate().startOf('year').format('YYYY.MM.DD'),
                to: (this.periodOnly ? rigDate().endOf('month') : rigDate()).format('YYYY.MM.DD'),
            },
            {
                label: 'Hele året',
                from: rigDate().startOf('year').format('YYYY.MM.DD'),
                to: rigDate().endOf('year').format('YYYY.MM.DD'),
            },
            {
                label: 'Forrige år',
                from: rigDate().subtract(1, 'year').startOf('year').format('YYYY.MM.DD'),
                to: rigDate().subtract(1, 'year').endOf('year').format('YYYY.MM.DD'),
            },
            {
                label: 'Denne uken',
                from: rigDate().startOf('week').format('YYYY.MM.DD'),
                to: rigDate().endOf('week').format('YYYY.MM.DD'),
                hiddenOnPeriodOnly: true,
            },
            {
                label: 'Denne måneden',
                from: rigDate().startOf('month').format('YYYY.MM.DD'),
                to: rigDate().endOf('month').format('YYYY.MM.DD'),
            },

            // Kvartal
            {
                label: '1. kvartal',
                from: dateInActiveYear.month(0).startOf('month').format('YYYY.MM.DD'),
                to: dateInActiveYear.month(2).endOf('month').format('YYYY.MM.DD'),
                class: 'mt-1',
            },
            {
                label: '2. kvartal',
                from: dateInActiveYear.month(3).startOf('month').format('YYYY.MM.DD'),
                to: dateInActiveYear.month(5).endOf('month').format('YYYY.MM.DD'),
            },
            {
                label: '3. kvartal',
                from: dateInActiveYear.month(6).startOf('month').format('YYYY.MM.DD'),
                to: dateInActiveYear.month(8).endOf('month').format('YYYY.MM.DD'),
            },
            {
                label: '4. kvartal',
                from: dateInActiveYear.month(9).startOf('month').format('YYYY.MM.DD'),
                to: dateInActiveYear.month(11).endOf('month').format('YYYY.MM.DD'),
            },

            // Termin
            {
                label: '1. termin',
                from: dateInActiveYear.month(0).startOf('month').format('YYYY.MM.DD'),
                to: dateInActiveYear.month(1).endOf('month').format('YYYY.MM.DD'),
                class: 'mt-1',
            },
            {
                label: '2. termin',
                from: dateInActiveYear.month(2).startOf('month').format('YYYY.MM.DD'),
                to: dateInActiveYear.month(3).endOf('month').format('YYYY.MM.DD'),
            },
            {
                label: '3. termin',
                from: dateInActiveYear.month(4).startOf('month').format('YYYY.MM.DD'),
                to: dateInActiveYear.month(5).endOf('month').format('YYYY.MM.DD'),
            },
            {
                label: '4. termin',
                from: dateInActiveYear.month(6).startOf('month').format('YYYY.MM.DD'),
                to: dateInActiveYear.month(7).endOf('month').format('YYYY.MM.DD'),
            },
            {
                label: '5. termin',
                from: dateInActiveYear.month(8).startOf('month').format('YYYY.MM.DD'),
                to: dateInActiveYear.month(9).endOf('month').format('YYYY.MM.DD'),
            },
            {
                label: '6. termin',
                from: dateInActiveYear.month(10).startOf('month').format('YYYY.MM.DD'),
                to: dateInActiveYear.month(11).endOf('month').format('YYYY.MM.DD'),
            },
            ...months.map((monthName, index) => {
                return {
                    label: monthName.charAt(0).toUpperCase() + monthName.slice(1),
                    from: dateInActiveYear.month(index).startOf('month').format('YYYY.MM.DD'),
                    to: dateInActiveYear.month(index).endOf('month').format('YYYY.MM.DD'),
                    class: index === 0 ? 'mt-1' : undefined,
                    hiddenOnPeriodOnly: true,
                };
            }),
        ].filter((item) => !item.hiddenOnPeriodOnly || !this.periodOnly);
    }
}
