import { BizHttp } from '@uni-framework/core/http/BizHttp';
import { Account, AccountDimension, AccountMandatoryDimension, Dimensions, SalaryTransaction } from '@uni-entities';
import { UniHttp } from '@uni-framework/core/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StatisticsService } from '../common/statisticsService';
import { map, publishReplay, refCount } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class AccountMandatoryDimensionService extends BizHttp<AccountMandatoryDimension> {
    accountsWithMandatoryDimensionsCount: Observable<number>;
    mandatoryDimensionsCache;

    constructor(
        http: UniHttp,
        private httpClient: HttpClient,
        private statisticsService: StatisticsService,
    ) {
        super(http);
        this.relativeURL = AccountMandatoryDimension.RelativeUrl;
        this.entityType = AccountMandatoryDimension.EntityType;

        // Clear local cache when BizHttp cache is invalidated (company change etc)
        this.cacheInvalidated$.subscribe(() => {
            this.invalidateMandatoryDimensionsCache();
        });
    }

    invalidateMandatoryDimensionsCache() {
        this.accountsWithMandatoryDimensionsCount = undefined;
        this.mandatoryDimensionsCache = undefined;
    }

    getRequiredMessage(mandatoryDimensionLabels, account) {
        if (!mandatoryDimensionLabels.length) {
            return '';
        }
        return `Konto ${account} krever at dimensjonen(e) ${mandatoryDimensionLabels.join(',')} er satt`;
    }

    getWarningMessage(mandatoryDimensionLabels, account) {
        if (!mandatoryDimensionLabels.length) {
            return '';
        }
        return `Konto ${account} har forslag om a sette dimensjonene ${mandatoryDimensionLabels.join(',')}`;
    }

    loadMandatoryDimensions() {
        if (!this.mandatoryDimensionsCache) {
            return this.statisticsService
                .GetAllUnwrapped(
                    'model=accountmandatorydimension' +
                        '&select=AccountMandatoryDimension.DimensionNo as DimensionNo' +
                        ',AccountMandatoryDimension.MandatoryType as MandatoryType,' +
                        'AccountMandatoryDimension.AccountID as AccountID,' +
                        'DimensionSettings.Label as Label' +
                        '&filter=MandatoryType gt 0' +
                        '&join=accountmandatorydimension.DimensionNo eq DimensionSettings.Dimension',
                )
                .subscribe((res) => {
                    this.mandatoryDimensionsCache = res.map((item) => {
                        if (item.Label === null) {
                            if (item.DimensionNo === 1) {
                                item.Label = 'Prosjekt';
                            }
                            if (item.DimensionNo === 2) {
                                item.Label = 'Avdeling';
                            }
                        }
                        return item;
                    });
                });
        }
    }

    /**
     *  Don't use this unless you know the action is safe to run without invalidating the cache!
     *
     *  Since the actions for getting mandatory dimension reports are PUT actions they automatically
     *  clear the BizHttp cache when using the ActionWithBody(..).
     *
     *  This renders the cache useless, because every report check will invalidate the cache.
     *  Since the put actions don't actually change anything on the api it's safe to run
     *  the requests through HttpClient instead of BizHttp, leaving the cache valid.
     */
    private runPutActionWithoutInvalidatingCache(action: string, body) {
        return this.httpClient.put(`/api/biz/${this.relativeURL}?action=${action}`, body);
    }

    public getMandatoryDimensionsReports(items: any[]): Observable<any> {
        const params: AccountDimension[] = [];
        items.forEach((item) => {
            const ad = <AccountDimension>{};
            ad.AccountID = item.AccountID;
            if (item.DimensionsID && item.DimensionsID > 0) {
                ad.DimensionsID = item.DimensionsID;
            } else {
                ad.Dimensions = item.Dimensions;
            }
            params.push(ad);
        });

        return this.runPutActionWithoutInvalidatingCache('get-mandatory-dimensions-reports', params);
    }

    public getDimensionName(dimensionNo) {
        if (dimensionNo === 1) {
            return 'Project';
        }
        if (dimensionNo === 2) {
            return 'Department';
        } else {
            return 'Dimension' + dimensionNo;
        }
    }

    public getReport(account: Account, row: any) {
        if (!account) {
            return null;
        }

        const mandatoryDimensions = this.mandatoryDimensionsCache || [];

        const debitMandatoryDimensions = mandatoryDimensions.filter((md) => md.AccountID === account.ID);
        const debitWarningDimensions = debitMandatoryDimensions.filter((md) => {
            const isWarning = md.MandatoryType === 2;
            const hasValue = !!(row?.Dimensions && row?.Dimensions[this.getDimensionName(md.DimensionNo)]);
            return isWarning && !hasValue;
        });
        const debitRequiredDimensions = debitMandatoryDimensions.filter((md) => {
            const isRequired = md.MandatoryType === 1;
            const hasValue = !!(row?.Dimensions && row?.Dimensions[this.getDimensionName(md.DimensionNo)]);
            return isRequired && !hasValue;
        });
        const debitWarningDimensionsLabels = debitWarningDimensions.map((md) => md.Label);
        const debitRequiredDimensionsLabels = debitRequiredDimensions.map((md) => md.Label);
        const arrayToObject = (dimensions) => {
            const obj = {};
            dimensions.forEach((item) => {
                obj[item.DimensionNo] = item.Label;
            });
            return obj;
        };
        const report = {
            AccountID: account.ID,
            AccountNumber: account.AccountNumber,
            MissingOnlyWarningsDimensionsMessage: this.getWarningMessage(
                debitWarningDimensionsLabels,
                account.AccountNumber,
            ),
            MissingRequiredDimensions: debitRequiredDimensions.map((md) => md.DimensionNo),
            MissingRequiredDimensionsMessage: this.getRequiredMessage(
                debitRequiredDimensionsLabels,
                account.AccountNumber,
            ),
            MissingWarningDimensions: debitWarningDimensions.map((md) => md.DimensionNo),
            RequiredDimensions: arrayToObject(debitRequiredDimensions),
            WarningDimensions: arrayToObject(debitWarningDimensions),
        };
        return report;
    }

    public getMandatoryDimensionsReportsForPayroll(salaryTransactions: SalaryTransaction[]): Observable<any> {
        const params: AccountDimension[] = [];
        salaryTransactions.forEach((item) => {
            const ad = <AccountDimension>{};
            ad.AccountNumber = item.Account;
            if (item.DimensionsID && item.DimensionsID > 0) {
                ad.DimensionsID = item.DimensionsID;
            } else {
                ad.Dimensions = item.Dimensions;
            }
            if (
                !params.find(
                    (x) =>
                        x.AccountNumber === ad.AccountNumber &&
                        x.DimensionsID === ad.DimensionsID &&
                        x.Dimensions === ad.Dimensions,
                )
            ) {
                params.push(ad);
            }
        });

        return this.runPutActionWithoutInvalidatingCache('get-mandatory-dimensions-reports', params);
    }

    public getMandatoryDimensionsReportByDimension(accountID: number, dimensions: Dimensions): Observable<any> {
        return this.runPutActionWithoutInvalidatingCache(
            `get-mandatory-dimensions-report-by-dimensions&accountID=${accountID}`,
            dimensions,
        );
    }

    public getMandatoryDimensionsReport(accountID: number, dimensionsID: number): Observable<any> {
        return super.GetAction(
            null,
            `get-mandatory-dimensions-report&accountID=${accountID}&dimensionsID=${dimensionsID}`,
        );
    }

    public getCustomerMandatoryDimensionsReport(
        accountID: number,
        dimensionsID: number,
        dimensions: Dimensions = null,
    ): Observable<any> {
        if (dimensions) {
            return this.runPutActionWithoutInvalidatingCache(
                `get-customer-mandatory-dimensions-report-dimensions&customerID=${accountID}&dimensionsID=${dimensionsID}`,
                dimensions,
            );
        }

        return super.GetAction(
            null,
            `get-customer-mandatory-dimensions-report-dimensionsID&customerID=${accountID}&dimensionsID=${dimensionsID}`,
        );
    }

    public getSupplierMandatoryDimensionsReport(
        supplierID: number,
        dimensionsID?: number,
        dimensions: Dimensions = null,
    ): Observable<any> {
        if (dimensions) {
            return this.runPutActionWithoutInvalidatingCache(
                `get-supplier-mandatory-dimensions-report-dimensions&supplierID=${supplierID}&dimensionsID=${dimensionsID}`,
                dimensions,
            );
        }

        return super.GetAction(
            null,
            `get-supplier-mandatory-dimensions-report-dimensionsID&supplierID=${supplierID}&dimensionsID=${dimensionsID}`,
        );
    }

    public GetNumberOfAccountsWithMandatoryDimensions(): Observable<number> {
        if (!this.accountsWithMandatoryDimensionsCount) {
            const url = 'model=AccountMandatoryDimension&select=count(ID) as count&filter=isnull(MandatoryType,0) ne 0';
            this.accountsWithMandatoryDimensionsCount = this.statisticsService.GetAllUnwrapped(url).pipe(
                map((res) => res && res[0]?.count),
                publishReplay(1),
                refCount(),
            );
        }

        return this.accountsWithMandatoryDimensionsCount;
    }

    public checkRecurringInvoices(accountID: number): Observable<any> {
        return super.GetAction(null, `check-recurringinvoices&accountID=${accountID}`);
    }
}
