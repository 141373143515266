<section role="dialog" class="uni-modal">
    <header>Godkjenn betalinger med BankID</header>
    <article>
        <section *ngIf="busy" class="modal-spinner">
            <mat-spinner class="c2a"></mat-spinner>
        </section>

        <section *ngIf="!isRunningAsBackgroundJob">
            <span *ngIf="isAll"> Send alle betalingene til bank og godkjenn med BankID </span>

            <span *ngIf="!isAll">
                Send {{ paymentIDs?.length }} betalinger med totalsum på
                <strong>{{ options.data.amount | uninumberformat: 'money' }}</strong> til bank og godkjenn med BankID
            </span>

            <small class="small-error-text" *ngIf="errorText"> {{ errorText }} </small>
        </section>

        <span *ngIf="isRunningAsBackgroundJob">
            Det opprettes en betalingsjobb for å genere betalingene.<br />
            Avhengig av antall betaling, kan dette ta litt tid. Vennligst vent.
        </span>
    </article>

    <footer class="center">
        <button class="secondary" [disabled]="busy" (click)="close()">Avbryt</button>
        <button class="c2a" [disabled]="busy" (click)="sendPayments()">Godkjenn og betal</button>
    </footer>
</section>
