export const EN = {
    NAVBAR: {
        KPI: 'KPI',
        DASHBOARD: 'Dashboard',
        COMPANIES: 'Companies',
        APPROVALS: 'Approvals',
        OVERVIEW: 'Overview',
        RESULT_BALANCE: 'Result & balance',
        DISTRIBUTION: 'Distribution',
        EXTRACT: 'Extract',
        REPORTS: 'Reports',
        SALES: 'Sales',
        INVOICE: 'Invoice',
        RECURRING_INVOICE: 'Recurring invoice',
        ORDER: 'Order',
        QUOTE: 'Quote',
        REMINDER: 'Reminder',
        KID_SETTINGS: 'KID settings',
        CUSTOMER: 'Customers',
        PRODUCTS: 'Products',
        PRODUCT_GROUP: 'Product groups',
        SELLERS: 'Sellers',
        CURRENCY: 'Currency',
        COSTALLOCATION: 'Cost allocation',
        ACCOUNTING: 'Accounting',
        JOURNALENTRY: 'Journal entry',
        SUPPLIER_INVOICE: 'Supplier invoice',
        BUDGET: 'Budget',
        BANKPRODUCTS: 'Bankprodukter',
        OPEN_POST: 'Open posts',
        CUSTOMER_LEDGER: 'Customer accounts',
        VAT_MESSAGE: 'VAT message',
        ANNUAL_SETTLEMENT: 'Annual settlement',
        FORHAANDSFASTSETTING: 'Advance determination',
        SUBMISSION: 'Submission',
        LINKS: 'Links',
        BALANCE_SEARCH: 'Balance main ledger',
        SEARCH_JOURNALENTRY: 'Search journal entry',
        SEARCH_ACCOUNT: 'Search account',
        SUPPLIER: 'Supplier',
        ACCOUNT_PLAN: 'Account plan',
        VAT_SETTINGS: 'VAT settings',
        BANK: 'Bank',
        PROCEEDS: 'Proceeds',
        PAYMENTS: 'Payments',
        PAYMENT_BATCH: 'Payment batches',
        PAYMENT_BATCH_AUTO: 'Avtalegiro batches',
        MARKETPLACE: 'Markedsplass',
        SALARY: 'Salary',
        PAYROLL: 'Payroll',
        PAYROLLV2: 'Payroll v2',
        AMELDING: 'A-Melding',
        INCOMEREPORTS: 'Income reports',
        BALANCE: 'Balance',
        ADDITIONAL_INFORMATION: 'Additional information',
        ANNUAL_ASSIGNMENT: 'Annual assignment to income recipient',
        EMPLOYEES: 'Employees',
        WAGETYPE: 'Wage types',
        CATAGORIES: 'Catagories',
        MOVE_TEMPLATES: 'Salary balance template',
        OTP_EXPORT: 'OTP-export',
        VARIABLE_PAYROLLS: 'Variable payrolls',
        REGULATIVE: 'Regulative',
        HOURS: 'Timetracking',
        HOUR_REG: 'Registration',
        BILLING_HOURS: 'Billing hours',
        PERSONS: 'Persons',
        TYPES: 'Work types',
        ADMIN_DAYSOFF: 'Administrate holiday',
        EMPLOYMENT_TEMPLATE: 'Work profiles',
        HOUR_STRING: 'hours',
        TEMPLATES: 'Templates',
        GO_TO_TODAY: 'Go to today',
        VIEW: 'View',
        ALTINN: 'Altinn',
        SETTINGS: 'Settings',
        COMPANY: 'Company settings',
        PAYROLL_SETTINGS: 'Payroll settings',
        INTEGRATION: 'Integrations',
        INVOICEFLOW: 'Flow and assigments',
        USERS: 'Users',
        TEAMS: 'Teams',
        NUMBERSERIES: 'Number series',
        TERMS: 'Terms',
        RULES: 'Rules',
        IMPORT_CENTRAL: 'Import central',
        JOBS: 'Jobs',
        MODELS: 'Models',
        ROLES: 'Roles',
        GDPR: 'GDPR',
        ADMIN: 'Admin',
        DIMENSION: 'Dimensions',
        PROJECT: 'Project',
        DEPARTMENT: 'Department',
        DIMENSION_SETTINGS: 'Dimension settings',
        PAYMENT_AND_DELIVERY: 'Payment and delivery terms',
        SHOW_ALL_NAVBAR: 'Show complete menu',
        MODULES: 'Modules',
        WEBINAR: 'Webinar',
        PURCHASES: 'Purchases',
        ASSETS: 'Assets',
        OPENING_BALANCE: 'Opening Balance',
        PRICE_DEALS: 'Price Deals',
        FILEIMPORT: 'File import',
        PENSION: 'Pension',
        FINANCING: 'Financing',
        INSURANCE: 'Insurance',
    },
};
