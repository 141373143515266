import {
    Component,
    Input,
    Output,
    EventEmitter,
    ChangeDetectionStrategy,
    ContentChildren,
    QueryList,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { RigToggleButton } from './toggle-button';

@Component({
    selector: 'rig-toggle-button-group',
    template: `<ng-content></ng-content>`,
    styleUrls: ['./toggle-button-group.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: RigToggleButtonGroup, multi: true }],
    host: { role: 'group' },
})
export class RigToggleButtonGroup implements ControlValueAccessor {
    @ContentChildren(RigToggleButton, { descendants: true })
    toggleButtons: QueryList<RigToggleButton>;

    @Input() multiple: boolean;
    @Output() change = new EventEmitter<any | any[]>();

    value: any | any[];

    ngOnChanges(changes) {
        if (changes.value) {
            this.setButtonStateFromValue();
        }
    }

    ngAfterViewInit() {
        if (this.value) {
            this.setButtonStateFromValue();
        }
    }

    private setButtonStateFromValue() {
        if (!this.toggleButtons) return;

        const selected = Array.isArray(this.value) ? this.value : [this.value];
        this.toggleButtons.forEach((btn) => {
            btn.setChecked(selected.includes(btn.value));
        });
    }

    private setValueFromButtonState() {
        const selected = this.toggleButtons
            .toArray()
            .filter((btn) => btn.checked)
            .map((btn) => btn.value);

        this.value = this.multiple ? selected : selected[0];
        this.ngModelUpdateFn(this.value);
    }

    toggle(button: RigToggleButton) {
        if (this.multiple) {
            button.setChecked(!button.checked);
        } else if (!button.checked) {
            // REVISIT: allow deselecting in single selection mode?
            button.setChecked(true);
            this.toggleButtons.forEach((btn) => {
                if (btn.checked && btn !== button) {
                    btn.setChecked(false);
                }
            });
        }

        this.setValueFromButtonState();
        this.change.emit(this.value);
    }

    /*
        The following functions are necessary to implement the
        ControlValueAccessor interface, which adds support for [(ngModel)].
        Do not remove anything unless you know what you're doing.
    */
    private ngModelUpdateFn = (value: any | any[]) => {};

    registerOnChange(fn) {
        this.ngModelUpdateFn = fn;
    }

    writeValue(value: any | any[]) {
        this.value = value;
        this.setButtonStateFromValue();
    }

    registerOnTouched() {}
    setDisabledState() {}
}
