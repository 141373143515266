import { Component, Input } from '@angular/core';

@Component({
    selector: 'cms-list',
    templateUrl: './list.html',
    styleUrls: ['./list.sass'],
})
export class CMSList {
    @Input() list: any;

    constructor() {}
}
