import { Component, EventEmitter } from '@angular/core';
import { forkJoin, BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { UniFieldLayout } from '../../ui/uniform/index';
import { ToastService, ToastType, ToastTime } from '../../uniToast/toastService';
import { CompanySettings } from '../../../../src/app/unientities';
import { ActivationEnum } from '../../../../src/app/models/activationEnum';
import { UniModalService } from '../modalService';
import { ConfirmActions, IModalOptions, IUniModal } from '@uni-framework/uni-modal/interfaces';
import { ElsaAgreement, ElsaAgreementStatus } from '@app/models';
import { ElsaProductService } from '@app/services/elsa/elsaProductService';
import { CompanySettingsService } from '@app/services/common/companySettingsService';
import { ErrorService } from '@app/services/common/errorService';
import { UserService } from '@app/services/common/userService';

@Component({
    selector: 'uni-activate-einvoice-modal',
    template: `
        <section role="dialog" class="uni-modal">
            <header>{{ options.header || 'Aktiver Efaktura' }}</header>
            <article>
                <section *ngIf="busy" class="modal-spinner">
                    <mat-spinner class="c2a"></mat-spinner>
                </section>

                <p *ngIf="!isMissingData">
                    Opplysningene under vil oversendes Nets for aktivering av Efaktura. Vennligst se over at disse
                    opplysningene er riktige før du fortsetter. Eventuelle endringer gjøres under firmainnstillinger.
                </p>
                <p *ngIf="isMissingData" class="bad">
                    <strong>
                        Du kan ikke aktivere Efaktura på grunn av at noen av feltene under mangler informasjon.
                        Vennligst registrer disse opplysningene under firmainnstillinger og prøv igjen.
                    </strong>
                </p>
                <uni-form [config]="{ autofocus: true }" [fields]="formFields$" [model]="formModel$"> </uni-form>

                <rig-checkbox *ngIf="terms" [(ngModel)]="termsAgreed" class="d-block mt-2 mb-1">
                    Jeg har lest og forstått
                    <a (click)="$event.preventDefault(); confirmTerms()">avtalen</a>
                </rig-checkbox>

                <p>
                    <strong>NB!</strong> Dersom du har en eksisterende Efaktura-avtale anbefaler vi å ta kontakt med
                    aktuell systemleverandør og deaktivere denne.
                </p>
            </article>

            <footer>
                <button class="secondary pull-left" (click)="close()">Avbryt</button>

                <button
                    class="c2a"
                    (click)="activate()"
                    title="Betingelser må aksepteres før du kan aktivere Efaktura"
                    [disabled]="(!termsAgreed && terms) || isMissingData"
                >
                    Aktiver Efaktura
                </button>
            </footer>
        </section>
    `,
})
export class UniActivateEInvoiceModal implements IUniModal {
    options: IModalOptions = {};
    modalService: UniModalService;

    onClose = new EventEmitter();

    formModel$ = new BehaviorSubject(null);
    formFields$ = new BehaviorSubject<UniFieldLayout[]>([]);

    terms: ElsaAgreement;
    termsAgreed: boolean;
    isMissingData: boolean;
    busy: boolean = false;

    constructor(
        private companySettingsService: CompanySettingsService,
        private userService: UserService,
        private errorService: ErrorService,
        private toastService: ToastService,
        private elsaProductService: ElsaProductService,
    ) {}

    public ngOnInit() {
        this.busy = true;
        const filter = `name eq 'efakturab2c'`;
        this.elsaProductService
            .GetAll(filter)
            .pipe(
                finalize(() => {
                    this.busy = false;
                    this.initialize();
                }),
            )
            .subscribe((product) => {
                if (product[0]?.ProductAgreement?.AgreementStatus === ElsaAgreementStatus.Active) {
                    this.terms = product[0].ProductAgreement;
                }
            });
    }

    ngOnDestroy() {
        this.formModel$.complete();
        this.formFields$.complete();
    }

    initialize() {
        this.formFields$.next(this.getFormFields());
        this.initActivationModel();

        this.options.cancelValue = ActivationEnum.NOT_ACTIVATED;
    }

    public initActivationModel() {
        this.companySettingsService.getCompanySettings().subscribe(
            (companySettings: CompanySettings) => {
                const model = <any>{};
                const settings: CompanySettings = companySettings;

                model.orgnumber = settings.OrganizationNumber;
                model.orgname = settings.CompanyName;
                if (settings.DefaultEmail) {
                    model.orgemail = settings.DefaultEmail && settings.DefaultEmail.EmailAddress;
                }
                if (settings.DefaultAddress) {
                    model.postalCity = settings.DefaultAddress.City;
                    model.postalCode = settings.DefaultAddress.PostalCode;
                    model.countryCode = settings.DefaultAddress.CountryCode || 'NO';
                }

                this.isMissingData =
                    !model.orgname ||
                    !model.orgnumber ||
                    model.orgnumber.length !== 9 ||
                    !model.orgemail ||
                    !model.postalCity ||
                    !model.postalCode ||
                    !model.countryCode;

                model.settings = settings;

                this.formModel$.next(model);
            },
            (err) => {
                this.errorService.handle(err);
            },
        );
    }

    public confirmTerms() {
        this.modalService
            .confirm({
                header: this.terms.Name,
                message: this.terms.AgreementText,
                isMarkdown: true,
                class: 'medium',
                buttonLabels: {
                    accept: 'Aksepter',
                    cancel: 'Tilbake',
                },
            })
            .onClose.subscribe((response) => {
                if (response === ConfirmActions.ACCEPT) {
                    this.termsAgreed = true;
                }
            });
    }

    public activate() {
        this.busy = true;
        const model = this.formModel$.getValue();

        // activate einvoice using action - this will update companysettings and trigger jobserver activation job
        this.companySettingsService.Action(1, 'activate-einvoice').subscribe(
            (status) => {
                this.toastService.addToast(
                    'Aktivering er startet',
                    ToastType.good,
                    ToastTime.medium,
                    'Du vil få en bekreftelse på epost når aktiveringen er ferdig, eller hvis noe går galt',
                );

                this.close(<any>status);
            },
            (err) => {
                this.busy = false;
                this.errorService.handle(err);
            },
        );
    }

    public close(activationCode = ActivationEnum.NOT_ACTIVATED) {
        this.onClose.emit(activationCode);
    }

    private getFormFields(): UniFieldLayout[] {
        return [
            <any>{
                Property: 'orgnumber',
                Label: 'Organisasjonsnummer',
                ReadOnly: true,
            },
            <any>{
                Property: 'orgname',
                Label: 'Firmanavn',
                ReadOnly: true,
            },
            <any>{
                Property: 'orgemail',
                Label: 'E-post',
                ReadOnly: true,
            },
            <any>{
                Property: 'postalCode',
                Label: 'Postnr',
                ReadOnly: true,
            },
            <any>{
                Property: 'postalCity',
                Label: 'Poststed',
                ReadOnly: true,
            },
            <any>{
                Property: 'countryCode',
                Label: 'Landkode',
                ReadOnly: true,
            },
        ];
    }
}
