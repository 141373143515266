import { Component, Input, Output, EventEmitter, Injectable, NgModule, ViewChild, ElementRef } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { UniDateAdapter } from '@app/date-adapter';
import { LibraryImportsModule } from '@app/library-imports.module';
import { rigDate } from '@app/components/common/utils/rig-date';

@Injectable()
export class CustomDateAdapter extends UniDateAdapter {
    format(date: Date): string {
        let formatted = rigDate(date).format('MMMM YYYY');
        return formatted?.charAt(0)?.toUpperCase() + formatted?.slice(1);
    }
}

@Component({
    selector: 'month-picker',
    template: `
        <i class="material-icons">calendar_today</i>

        <input
            #input
            readonly
            type="text"
            class="pointer"
            (click)="datepicker.open()"
            (keydown.enter)="datepicker.open()"
            (keydown.space)="datepicker.open()"
            [(ngModel)]="value"
            [matDatepicker]="datepicker"
            [disabled]="readonly"
        />

        <mat-datepicker
            #datepicker
            startView="year"
            panelClass="month-picker"
            (monthSelected)="onMonthSelected($event, datepicker)"
        >
        </mat-datepicker>
    `,
    styleUrls: ['./month-picker.sass'],
    providers: [{ provide: DateAdapter, useClass: CustomDateAdapter }],
})
export class MonthPicker {
    @ViewChild('input') inputElement: ElementRef<HTMLInputElement>;

    @Input() readonly: boolean;
    @Input() value: Date;
    @Output() valueChange = new EventEmitter<Date>();

    onMonthSelected(value, datepicker) {
        datepicker.close();
        this.value = value;
        this.valueChange.emit(value);
    }

    focus() {
        this.inputElement?.nativeElement?.focus();
    }
}

@NgModule({
    imports: [LibraryImportsModule],

    declarations: [MonthPicker],
    exports: [MonthPicker],
})
export class MonthPickerModule {}
