import { Component, Input } from '@angular/core';
import { IUniTab } from './uni-tabs';

@Component({
    selector: 'uni-tab-content',
    styleUrls: ['./tab-content.sass'],
    template: `
        <span>{{ tab[labelProperty] | translate }}</span>

        <strong *ngIf="tab[counterProperty]" class="pull-right">
            {{ tab[counterProperty] }}
        </strong>

        <i
            *ngIf="tab.tooltip"
            class="material-icons tab-tooltip"
            [ngClass]="tab.tooltipClass"
            [matTooltip]="tab.tooltip | translate"
        >
            {{ tab.tooltipIcon || 'info_outline' }}
        </i>
    `,
})
export class TabContent {
    @Input() tab: IUniTab;
    @Input() labelProperty: string;
    @Input() counterProperty: string;
}
