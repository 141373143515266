import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { ScrollingModule } from '@angular/cdk/scrolling';
import { A11yModule } from '@angular/cdk/a11y';
import { OverlayModule } from '@angular/cdk/overlay';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { MatMenuModule } from '@angular/material/menu';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatStepperModule } from '@angular/material/stepper';

const libs = [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,

    ScrollingModule,
    A11yModule,
    OverlayModule,
    DragDropModule,

    MatTreeModule,
    MatTooltipModule,
    MatProgressBarModule,
    MatMenuModule,
    MatStepperModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatDatepickerModule,
];

@NgModule({
    imports: [...libs],
    exports: [...libs],
})
export class LibraryImportsModule {}
