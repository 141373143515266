import { Component, EventEmitter } from '@angular/core';
import { IUniModal, ConfirmModalOptions, ConfirmActions } from '@uni-framework/uni-modal/interfaces';
import { parse } from 'marked';

@Component({
    selector: 'uni-confirm-modal',
    templateUrl: './confirm-modal.html',
    styleUrls: ['./confirm-modal.sass'],
})
export class ConfirmModal implements IUniModal {
    options: ConfirmModalOptions = {};
    onClose = new EventEmitter();

    ngOnInit() {
        if (!this.options.buttonLabels) {
            this.options.buttonLabels = {
                accept: 'Ok',
                cancel: 'Avbryt',
            };
        }

        if (this.options.message && this.options.isMarkdown) {
            this.options.message = decodeURI(this.options.message);
            this.options.message = parse(this.options.message);
        }
    }

    accept() {
        this.onClose.emit(ConfirmActions.ACCEPT);
    }

    reject() {
        this.onClose.emit(ConfirmActions.REJECT);
    }

    cancel() {
        this.onClose.emit(ConfirmActions.CANCEL);
    }
}
