import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { UniIconModule } from '../icon/uni-icon';

import { RigCheckbox } from './checkbox/checkbox';
import { RigSwitch } from './switch/switch';
import { UniRadioGroup } from './radio/radio-group';
import { UniRadioButton } from './radio/radio-button';

import { RigWizard } from './wizard/wizard';
import { RigWizardFooter } from './wizard/wizard-footer';
import { RigLink } from './link';
import { RigToggleButtonGroup } from './toggle-button/toggle-button-group';
import { RigToggleButton } from './toggle-button/toggle-button';
import { RigWizardTabs } from './wizard/wizard-tabs/wizard-tabs';
import { UniIllustration } from './illustration/illustration';

const components = [
    RigCheckbox,
    RigSwitch,
    UniRadioGroup,
    UniRadioButton,
    RigWizard,
    RigWizardFooter,
    RigWizardTabs,
    RigLink,
    RigToggleButtonGroup,
    RigToggleButton,
];

@NgModule({
    imports: [CommonModule, FormsModule, RouterModule, UniIconModule, UniIllustration],
    declarations: components,
    exports: [...components, UniIllustration],
})
export class DesignSystemModule {}
