import { Injectable } from '@angular/core';
import { BizHttp } from '../../../framework/core/http/BizHttp';
import { CompanySettings, StatusCode } from '../../unientities';
import { UniHttp } from '../../../framework/core/http/http';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { CompanyTypeService } from './companyTypeService';
import { cloneDeep } from 'lodash-es';
import { AuthService } from '@app/authService';

@Injectable({ providedIn: 'root' })
export class CompanySettingsService extends BizHttp<CompanySettings> {
    inflightRequest: { expands: string[]; observable: Observable<CompanySettings> };

    fetchedExpands = [];
    companySettingsObject: CompanySettings;
    cacheTimeout;

    standardExpands = [
        'DefaultAddress',
        'DefaultEmail',
        'DefaultPhone',
        'BankAccounts.Bank',
        'BankAccounts.BankAccountSettings',
        'BankAccounts.Account',
        'CompanyBankAccount.Account',
        'CompanyBankAccount.BankAccountSettings',
        'TaxBankAccount.BankAccountSettings',
        'SalaryBankAccount.BankAccountSettings',
        'DefaultSalesAccount',
        'BaseCurrencyCode',
        'Distributions',
        'FactoringEmail',
        'FactoringSettings',
        'APOutgoing',
        'APIncomming',
    ];

    constructor(
        http: UniHttp,
        authService: AuthService,
        private companyTypeService: CompanyTypeService,
    ) {
        super(http);
        this.relativeURL = CompanySettings.RelativeUrl;
        this.entityType = CompanySettings.EntityType;
        this.DefaultOrderBy = null;

        // ON EXTERNAL UPDATE, WE NEED TO INVALIDATE THE CACHE.
        // NEED SIGNALR LISTENER HERE
        // this.invalidateCache();

        authService.companyChange.subscribe(() => {
            this.invalidateCache();
            this.getCompanySettings();
        });
    }

    Get(ID: number = 1, expands: string[] = []) {
        return this.getCompanySettings(expands);
    }

    getCompanySettings(expand: string[] = []): Observable<CompanySettings> {
        if (!this.companySettingsObject) {
            if (this.inflightRequest) {
                const hasAllExpands =
                    !expand?.length ||
                    expand.every((expand) =>
                        this.inflightRequest.expands.some((inflightExpand) => inflightExpand === expand),
                    );
                if (hasAllExpands) {
                    return this.inflightRequest.observable;
                }
            }

            expand = [...new Set(expand.concat(this.standardExpands))];
            const observable = super.Get(1, expand).pipe(
                map((companySettings: CompanySettings) => {
                    companySettings.BankAccounts = companySettings?.BankAccounts?.filter((x) => x.StatusCode !== 50001);
                    if (companySettings?.CompanyBankAccount?.StatusCode === StatusCode.InActive) {
                        companySettings.CompanyBankAccount = null;
                        companySettings.CompanyBankAccountID = null;
                    }
                    if (companySettings?.SalaryBankAccount?.StatusCode === StatusCode.InActive) {
                        companySettings.SalaryBankAccount = null;
                        companySettings.SalaryBankAccountID = null;
                    }
                    if (companySettings?.TaxBankAccount?.StatusCode === StatusCode.InActive) {
                        companySettings.TaxBankAccount = null;
                        companySettings.TaxBankAccountID = null;
                    }

                    this.setExpandedValues(expand, companySettings);
                    this.fetchedExpands = expand;
                    this.inflightRequest = undefined;
                    return cloneDeep(this.companySettingsObject);
                }),
            );

            this.inflightRequest = {
                expands: expand,
                observable: observable,
            };

            this.cacheTimeout = setTimeout(() => this.invalidateCache(), 600000); // 10 minutes

            return observable;
        }

        // Has expand, lets check if it has already been fetched
        if (expand?.length) {
            const newExpands = expand.filter((exp) => !this.fetchedExpands.some((f) => f.includes(exp)));

            if (!newExpands?.length) {
                return of(cloneDeep(this.companySettingsObject));
            } else {
                return super.Get(1, newExpands).pipe(
                    switchMap((companySettings: CompanySettings) => {
                        // Lets update the cached companysettings object and the array that keeps control of the expands
                        this.setExpandedValues(newExpands, companySettings);
                        return of(cloneDeep(this.companySettingsObject));
                    }),
                );
            }
        } else {
            return of(cloneDeep(this.companySettingsObject));
        }
    }

    invalidateCache() {
        clearTimeout(this.cacheTimeout);
        this.fetchedExpands = [];
        this.companySettingsObject = null;
        this.inflightRequest = undefined;
        super.invalidateCache();
        this.cacheTimeout = setTimeout(() => this.invalidateCache(), 600000); // 10 minutes
    }

    setExpandedValues(expand, newCompanySettings) {
        if (!this.companySettingsObject) {
            this.companySettingsObject = newCompanySettings;
            return;
        }

        expand.forEach((key) => {
            if (key.includes('.')) {
                const newKey = key.split('.')[0];
                this.companySettingsObject[newKey] = newCompanySettings[newKey];
            } else {
                this.companySettingsObject[key] = newCompanySettings[key];
            }

            if (!this.fetchedExpands.includes(key)) {
                this.fetchedExpands.push(key);
            }
        });
    }

    // Takes a string array of key-values from the company settings entity and the company settings
    // object itself.. Loops the object and creats a partial with all the values from the array and returns it
    getPartialCompanySettings(keys: string[], companysSettings: any): Partial<CompanySettings> {
        const altered: Partial<CompanySettings> = {};
        keys.forEach((key) => {
            if (key.includes('.')) {
                const newKey = key.split('.')[0];
                altered[newKey] = companysSettings[newKey];
            } else {
                altered[key] = companysSettings[key];
            }
        });

        // CompanySettings always has ID = 1
        altered.ID = 1;

        return altered;
    }

    public changeCompanySettingsPeriodSeriesSettings(periodSeriesID: number, accountYear: number) {
        super.invalidateCache();
        return this.http
            .asPOST()
            .usingBusinessDomain()
            .withEndPoint(
                `${this.relativeURL}?action=change-period-series&periodSeriesID=${periodSeriesID}&accountYear=${accountYear}`,
            )
            .send()
            .pipe(map((response) => response.body));
    }

    getCompanyType(CompanyTypeID) {
        return this.companyTypeService.Get(CompanyTypeID);
    }

    checkCompanyType(allowedTypes: string[] = []) {
        const companySettings$ = this.getCompanySettings();
        return companySettings$.pipe(
            switchMap((settings: CompanySettings) => this.getCompanyType(settings.CompanyTypeID)),
            map((type) => !!allowedTypes.find((item) => item.toUpperCase() === type.Name.toUpperCase())),
        );
    }
}
