import { Injectable } from '@angular/core';
import { BizHttp } from '../../../framework/core/http/BizHttp';
import { DimensionSettings } from '../../unientities';
import { UniHttp } from '../../../framework/core/http/http';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class DimensionSettingsService extends BizHttp<DimensionSettings> {
    constructor(http: UniHttp) {
        super(http);

        this.relativeURL = DimensionSettings.RelativeUrl;
        this.entityType = DimensionSettings.EntityType;
        this.DefaultOrderBy = null;

        // No need for timeout here. The entity rarely changes, and the cache will
        // be invalidated on any changes the user makes, just not other users' changes.
        // Might add singalR invalidation here at a later point.
        this.cacheSettings.timeout = undefined;
    }

    async getDimensionName(dimension: string | number) {
        const dimensionNumber = parseInt(dimension?.toString().replace(/\D/g, ''));
        if (!dimensionNumber) return '';

        return this.GetAll()
            .pipe(
                catchError(() => of([])),
                map((dimensions) => {
                    const dimension = dimensions.find((dim) => dim.Dimension === dimensionNumber);
                    return dimension?.Label || '';
                }),
            )
            .toPromise();
    }
}
