import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'toLowerCase',
    pure: true,
})
export class ToLowerCasePipe implements PipeTransform {
    transform(type: string) {
        return type?.toLowerCase() || type;
    }
}
