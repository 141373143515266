import { Injectable } from '@angular/core';
import { ERRORS } from './error-translations';

@Injectable({ providedIn: 'root' })
export class ErrorTranslationsService {
    constructor() {}

    translate(message) {
        const keys = Object.keys(ERRORS);
        const requiredKey = keys.find((key) => {
            if (ERRORS[key]?.isRegex) {
                return new RegExp(ERRORS[key].EN, 'g').test(message);
            } else if (message.toLowerCase().includes(ERRORS[key].EN.toLowerCase())) {
                return true;
            }
            return false;
        });

        if (!requiredKey) {
            return message;
        }

        if (ERRORS[requiredKey].isRegex) {
            // assert target str has placeholders for '{n}' & retrieve list of values for each '{n}'
            const [hasPlaceholders, values] = [
                RegExp('{([0-9]+)}').test(ERRORS[requiredKey].NO),
                message.match(ERRORS[requiredKey].EN)?.slice(1) ?? [], // slice to discard full str match-
            ];

            if (hasPlaceholders && values?.length) {
                let result = ERRORS[requiredKey].NO;

                // for each param, replace with correct value extracted from regexp
                values.forEach((newValue, index) => {
                    result = result.replace(`{${index}}`, newValue);
                });

                return result;
            }
        }
        return ERRORS[requiredKey].NO;
    }
}
