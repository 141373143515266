import {
    Component,
    Input,
    ViewChild,
    ElementRef,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    OnInit,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { get } from 'lodash-es';

export interface IAutoCompleteOptions {
    lookupFunction: (searchValue: string, rowModel?) => Observable<any> | any[];
    itemTemplate: (selectedItem: any) => string;
    groupConfig?: IGroupConfig;
    debounceTime?: number;
    showResultAsTable: boolean;
    resultTableConfig: IResultTableConfig;
    placeholder?: string;
    buttons?: {
        label: string;
        action: (searchText: string) => Promise<any>;
    }[];
}

export interface IResultTableConfig {
    fields: IResultTableField[];
}

export interface IResultTableField {
    header: string;
    key: string;
    class?: string;
    width?: string;
    isMoneyField?: boolean;
}

export interface IResultTableButton {
    buttonText: string;
    action: () => {};
    getAction: (item) => {};
    errorAction: (msg: string) => {};
}

export interface IGroupInfo {
    key: number | string; // Match value in group
    header: string; // Group header
}

export interface IGroupConfig {
    groupKey: string; // Key to value that items in group match with
    visibleValueKey?: string; // Key to a boolean value in the item that if true, item is added to a group
    groups: Array<IGroupInfo>; // All the groups with key and header
}

@Component({
    selector: 'unitable-autocomplete',
    templateUrl: './table-autocomplete.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnitableAutocomplete implements OnInit {
    @ViewChild('input', { static: true }) public inputElement: ElementRef;
    @ViewChild('dropdown') private dropdown: ElementRef;

    @Input() column: any;
    @Input() inputControl: UntypedFormControl;

    groupConfig: IGroupConfig;

    options: IAutoCompleteOptions;
    busy: boolean = false;
    expanded: boolean;
    rowModel;
    placeholder = '';

    lookupResults: any[] = [];

    currentLookupText: string;
    lastCompletedLookupText: string;

    selectedIndex: any;
    private addValuePromise: Promise<any>;
    emptySearchString: string = '';

    constructor(private cdr: ChangeDetectorRef) {}

    public ngOnInit() {
        if (this.column) {
            this.options = this.column.get('options');
            const _placeholder = this.options.placeholder || this.column.get('placeholder') || '';
            this.placeholder = _placeholder instanceof Function ? _placeholder(this.rowModel) : _placeholder;
            if (this.options['groupConfig']) {
                this.groupConfig = this.options['groupConfig'];
            }

            // If itemTemplate is not defined, use displayField or field
            if (!this.options.itemTemplate) {
                let field = this.column.get('field');
                const displayField = this.column.get('displayField');
                if (displayField) {
                    field = displayField.split('.').slice(1).join('.');
                }

                this.options.itemTemplate = (selectedItem) => {
                    return selectedItem[field];
                };
            }
        }

        this.inputControl.valueChanges
            .pipe(
                switchMap((value) => {
                    this.currentLookupText = value;
                    this.lookupResults = [];
                    this.emptySearchString = 'Søker...';
                    this.busy = true;
                    if (value) {
                        this.selectedIndex = this.groupConfig ? 1 : 0;
                    } else {
                        this.selectedIndex = -1;
                    }
                    return of(value);
                }),
                debounceTime(this.options.debounceTime || 100),
                distinctUntilChanged(),
            )
            .subscribe((query) => {
                this.performLookup(query).subscribe((results) => {
                    this.lookupResults = this.showExactMatchOnTop(results, query);
                    this.emptySearchString = this.lookupResults.length ? 'Søker' : 'Ingen treff';
                    if (this.groupConfig) {
                        this.formatGrouping();
                    }
                    this.expanded = true;

                    if (this.options.showResultAsTable) {
                        // Get all the cells in the result table
                        const cells = document.getElementsByClassName('result_td');
                        setTimeout(() => {
                            if (cells.length > 0) {
                                // Loop the cells to see if there are matches
                                for (let i = 0; i < cells.length; i++) {
                                    // Check to see if cell contains query, set both to lowercase to ignore casing
                                    if (cells[i].innerHTML.toLowerCase().includes(query.toLowerCase())) {
                                        // Find the text to replace, no matter the casing!
                                        const index = cells[i].innerHTML.toLowerCase().indexOf(query.toLowerCase());
                                        const textToReplace = cells[i].innerHTML.substr(index, query.length);

                                        // If cell contains query, hightlight it in cell!
                                        let data = cells[i].innerHTML;
                                        data = data.replace(
                                            textToReplace,
                                            '<span class="highlighed_search_hit">' + textToReplace + '</span>',
                                        );
                                        cells[i].innerHTML = data;
                                    }
                                }
                            }
                        });
                    }

                    this.lastCompletedLookupText = query;
                    this.busy = false;
                    this.cdr.markForCheck();
                });
            });
    }

    private showExactMatchOnTop(result: any[], query: string): any[] {
        if (!this.options.showResultAsTable) {
            return result;
        }
        // Attempt to find exact match and put that item first in the result table
        const results = [].concat(result);
        const key = this.options.resultTableConfig.fields[0].key;
        const exactMatchIndex = results.findIndex((item) => {
            const value = get(item, key);
            return value && value.toString().toLowerCase() === query.toLowerCase();
        });

        if (exactMatchIndex >= 0) {
            results.unshift(results.splice(exactMatchIndex, 1)[0]);
        }

        return results;
    }

    runButtonAction(button) {
        if (button?.action) {
            this.addValuePromise = button.action(this.inputControl.value);
            this.expanded = false;
            this.cdr.markForCheck();

            this.inputElement.nativeElement.focus();
        }
    }

    private formatGrouping() {
        const groupedArray = [];

        // Add subarrays with header for each group in config
        this.groupConfig.groups.forEach((group: any) => {
            group.isHeader = true;
            groupedArray.push([group]);
        });

        // Add all elements into the different groups if the groupkey matches
        this.lookupResults.forEach((item) => {
            if (this.groupConfig.visibleValueKey ? item[this.groupConfig.visibleValueKey] : true) {
                for (let i = 0; i < this.groupConfig.groups.length; i++) {
                    if (item[this.groupConfig.groupKey] === this.groupConfig.groups[i].key) {
                        groupedArray[i].push(item);
                    }
                }
            }
        });

        // Check to see that no EMPTY groups are added with just the header
        for (let groupIndex = 0; groupIndex < groupedArray.length; groupIndex++) {
            if (groupedArray[groupIndex].length === 1) {
                groupedArray.splice(groupIndex, 1);
                if (groupIndex < groupedArray.length) {
                    groupIndex--;
                }
            }
        }

        this.lookupResults = [].concat.apply([], groupedArray);
    }

    public getValue() {
        if (this.inputControl.dirty && !this.inputControl.value) {
            if (this.lookupResults && this.lookupResults[this.selectedIndex]) {
                return this.lookupResults[this.selectedIndex];
            } else {
                return null;
            }
        }

        // user is adding a value throug a promise
        if (this.addValuePromise) {
            return this.addValuePromise;
        }

        // Check if there is a search in-flight, or the user has added search text after the
        // last search completed (debouncer on valueChanges can cause timing issues here).
        // If there is we need to run an additional search to make sure we resolve the correct value.
        if (this.inputControl.value && (this.busy || this.currentLookupText !== this.lastCompletedLookupText)) {
            return this.performLookup(this.currentLookupText).pipe(
                switchMap((res) => of(this.showExactMatchOnTop(res, this.currentLookupText)[0])),
            );
        }
        return this.selectedIndex >= 0 ? this.lookupResults[this.selectedIndex] : undefined;
    }

    public toggle() {
        if (this.expanded) {
            this.expanded = false;
        } else {
            this.expand();
        }
    }

    private expand() {
        this.performLookup('').subscribe((res) => {
            this.selectedIndex = -1;
            this.lookupResults = res;
            this.emptySearchString = this.lookupResults.length ? 'Søker' : 'Ingen treff';
            if (this.groupConfig) {
                this.formatGrouping();
            }
            this.expanded = true;
            this.cdr.markForCheck();
        });
    }

    private performLookup(search: string): Observable<any> {
        const lookupResult = this.options.lookupFunction(search, this.rowModel);
        let observable: Observable<any>;

        if (Array.isArray(lookupResult)) {
            observable = of(lookupResult);
        } else {
            observable = <Observable<any>>lookupResult;
        }

        return observable;
    }

    private confirmSelection(index?: any) {
        index = index >= 0 ? index : this.selectedIndex;
        const item = this.lookupResults[index];

        if (item) {
            const displayValue = this.options.itemTemplate(item);
            this.inputControl.setValue(displayValue, { emitEvent: false });
            this.selectedIndex = index;
        }
    }

    public itemClicked(index: number, isHeader: boolean) {
        if (isHeader || !this.lookupResults || !this.lookupResults[index]) {
            return;
        }

        this.confirmSelection(index);
        this.expanded = false;
        setTimeout(() => {
            this.inputElement.nativeElement.focus();
        });
    }

    public onKeyDown(event: KeyboardEvent) {
        const key = event.key;

        // Enter, no element available and add button exists
        if (
            key === 'Enter' &&
            this.inputControl.value &&
            this.inputControl.value.length > 0 &&
            this.lookupResults.length === 0 &&
            !this.busy &&
            this.expanded &&
            this.options.buttons?.length === 1
        ) {
            this.runButtonAction(this.options.buttons[0].action);
            // Escape
        } else if (key === 'Escape' && this.expanded) {
            event.stopPropagation();
            this.expanded = false;
            this.inputElement.nativeElement.focus();
            // Space
        } else if (key === ' ' && (!this.inputControl.value || this.inputControl.value.length === 0)) {
            event.preventDefault();
            this.expand();
            // Arrow up
        } else if (key === 'ArrowUp' && this.selectedIndex > 0) {
            event.preventDefault();
            if (this.lookupResults[this.selectedIndex - 1].isHeader) {
                this.selectedIndex--;
            }
            this.selectedIndex--;
            this.scrollToListItem();
            // Arrow down
        } else if (key === 'ArrowDown') {
            event.preventDefault();
            if (event.altKey) {
                event.stopPropagation();
                this.expand();
                return;
            }

            if (this.selectedIndex < this.lookupResults.length - 1) {
                if (this.lookupResults[this.selectedIndex + 1].isHeader) {
                    this.selectedIndex++;
                }
                this.selectedIndex++;
                this.scrollToListItem();
            }
            // F4
        } else if (key === 'F4') {
            event.preventDefault();
            this.expand();
        }

        if (this.expanded && this.options.buttons?.length && ['F1', 'F2', 'F3'].includes(event.key)) {
            event.preventDefault();
            const index = ['F1', 'F2', 'F3'].indexOf(event.key);
            this.runButtonAction(this.options.buttons[index]);
        }
    }

    private scrollToListItem() {
        setTimeout(() => {
            if (this.dropdown && this.dropdown.nativeElement) {
                const activeItem: HTMLElement = this.dropdown.nativeElement.querySelector('[aria-selected=true]');
                if (activeItem) {
                    activeItem.scrollIntoView({
                        block: 'nearest',
                    });
                }
            }
        });
    }
}
