import { Injectable } from '@angular/core';
import { of, throwError, Observable } from 'rxjs';
import { catchError, refCount, publishReplay, switchMap, map, shareReplay } from 'rxjs/operators';
import { BizHttp } from '../../../framework/core/http/BizHttp';
import { UniIdentityGlobalUser, User, UserVerificationUserType } from '../../unientities';
import { UniHttp } from '../../../framework/core/http/http';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { AuthService, CurrentUserWithout2FADetails } from '@app/authService';
import { UserRoleService } from './userRoleService';
import { StatisticsService } from '@app/services/common/statisticsService';

@Injectable({ providedIn: 'root' })
export class UserService extends BizHttp<User> {
    private userObservable: Observable<User>;
    private twoFactorDetails: Observable<UniIdentityGlobalUser>;

    constructor(
        http: UniHttp,
        private commonHttp: HttpClient,
        private authService: AuthService,
        private userRoleService: UserRoleService,
        private statistics: StatisticsService,
    ) {
        super(http);
        this.relativeURL = User.RelativeUrl;
        this.entityType = User.EntityType;
        this.DefaultOrderBy = 'DisplayName';

        this.cacheSettings.timeout = 600000; // 10 min

        this.authService.authentication$.subscribe(() => {
            this.userObservable = undefined;
            this.twoFactorDetails = undefined;
        });
    }

    getCurrentUser(): Observable<CurrentUserWithout2FADetails> {
        return this.authService.getCurrentUser().pipe(
            // Re-throw errors because of issue where hot observables
            // stop emitting errors if one subscriber doesn't catch
            catchError((err) => throwError(err)),
        );
    }

    getActiveUsers(): Observable<User[]> {
        return this.GetAll().pipe(map((users) => (users || []).filter((u) => u.StatusCode === 110001)));
    }

    getAdmins(): Observable<User[]> {
        return this.GetAction(null, 'adminusers').pipe(
            map((users) => (users || []).filter((u) => u.StatusCode === 110001)),
        );
    }

    get2FADetails(): Observable<UniIdentityGlobalUser> {
        if (!this.twoFactorDetails) {
            this.twoFactorDetails = this.GetAction(null, '2fa-details').pipe(shareReplay(1));
        }

        return this.twoFactorDetails;
    }

    getUsersWithSystemUser() {
        const select =
            'User.ID as ID,User.UserName as UserName,User.DisplayName as DisplayName,User.Email as Email,User.StatusCode as StatusCode,User.Protected as Protected,User.GlobalIdentity as GlobalIdentity';
        const query = `model=User&select=${select}`;

        const hash = this.hashFnv32a(query);
        let request = this.getFromCache(hash);

        if (!request) {
            request = this.statistics.GetAll(query).pipe(shareReplay(1));
            this.storeInCache(hash, request);
        }

        return this.statistics.GetAll(query).pipe(map((response) => response.Data || []));
    }

    getUsersByRoles(roles: string[], includeSystemUser: boolean = true): Observable<any[]> {
        const select =
            'User.ID as ID,User.UserName as UserName,User.DisplayName as DisplayName,User.Email as Email,User.StatusCode as StatusCode,User.Protected as Protected';
        const activeUsersQuery = "User.StatusCode eq '110001'";
        const rolesQuery = roles.map((role) => `UserRole.SharedRoleName eq '${role}'`).join(' or ');
        const query = `model=User&select=${select}&filter=${activeUsersQuery} and (${rolesQuery})&join=User.ID eq UserRole.UserID&distinct=true`;
        return this.statistics
            .GetAll(query)
            .pipe(
                map((response) =>
                    includeSystemUser
                        ? response.Data || []
                        : (response.Data || []).filter(
                              (u) => (u?.UserName ?? u?.DisplayName).toLowerCase() !== 'systemuser',
                          ),
                ),
            );
    }

    // override bizhttp put with cache invalidation
    public Put(id: number, entity: any): Observable<any> {
        this.authService.invalidateUserCache();
        return super.Put(id, entity);
    }

    inviteUser(email: string, userVerificationUserType: UserVerificationUserType = null) {
        return this.http
            .asPOST()
            .usingBusinessDomain()
            .withEndPoint('user-verifications')
            .withBody({ Email: email, UserType: userVerificationUserType })
            .send()
            .pipe(
                switchMap(() => {
                    super.invalidateCache();
                    this.userRoleService.invalidateCache();
                    return this.GetAll().pipe(map((users) => users.find((u) => u.Email === email)));
                }),
            );
    }

    changeUserEmail(email: string) {
        return this.http
            .asPUT()
            .usingEmptyDomain()
            .withBaseUrl(environment.authority)
            .withEndPoint('api/email?action=change-email')
            .withBody({ Email: email, ClientID: environment.client_id, RedirectUrl: window.location.origin })
            .send()
            .pipe(map((response) => response.body));
    }

    changeUserPassword() {
        return this.http
            .asPUT()
            .usingEmptyDomain()
            .withBaseUrl(environment.authority)
            .withEndPoint('api/password?action=change-password')
            .withBody({ ClientID: environment.client_id })
            .send()
            .pipe(map((response) => response.body));
    }

    public changeAutobankPassword() {
        return this.http.asPUT().usingBusinessDomain().withEndPoint('users?action=self-reset-autobank-password').send();
    }

    public sendUserCodeChallenge(reference: string = '') {
        return this.http
            .asGET()
            .usingBusinessDomain()
            .withEndPoint(`users?action=user-code-challenge&reference=` + reference)
            .send();
    }

    public verifyUserCodeChallenge(code: string, reference: string = '') {
        return this.http
            .asPUT()
            .usingBusinessDomain()
            .withEndPoint(`users?action=verify-code-challenge&reference=` + reference)
            .withBody(code)
            .send();
    }

    public getBankIdVerification() {
        return this.GetAction(null, 'bankid-verification');
    }

    public getBankIdRedirectUrl(state: string, clientId: string) {
        const baseurl = window.location.href.split('/#/')[0];
        const redirecturl = `${baseurl}/#/bank/bankid`;
        const platforms = this.authService.platforms;
        return `${environment.zdataauthority}/connect/authorize?client_id=${clientId}&redirect_uri=${encodeURIComponent(redirecturl)}&response_type=code&prompt=login&scope=bankservice&acr_values=idp:softrig verify softrig:platform:${platforms}&state=${btoa(state)}`;
    }

    public getUsersByGUIDs(GUIDs: string[]): Observable<User[]> {
        if (GUIDs.length === 0) {
            return of([]);
        }
        const hasValidGuids = GUIDs.reduce((prev, curr) => prev && !!curr, true);
        if (!hasValidGuids) {
            return of([]);
        }
        const query = `filter=GlobalIdentity eq '` + GUIDs.join(`' OR GlobalIdentity eq '`) + `'`;
        return this.GetAll(query);
    }

    updateUserTwoFactorAuth(TwoFactorEnabled: boolean): Observable<any> {
        return this.commonHttp.put(environment.ELSA_SERVER_URL + `/api/Users/enable-two-factor`, null, {
            params: { enable: TwoFactorEnabled ? 'true' : 'false' },
        });
    }

    getConnectedExternalProviders() {
        return this.http
            .asGET()
            .usingEmptyDomain()
            .withBaseUrl(environment.authority)
            .withEndPoint(`External/ConnectedProviders`)
            .send()
            .pipe(map((response) => response.body));
    }

    disconnectExternalProvider(scheme: string) {
        return this.http
            .asPOST()
            .usingEmptyDomain()
            .withBaseUrl(environment.authority)
            .withEndPoint(`External/Disconnect?scheme=${scheme}`)
            .withBody(null)
            .send()
            .pipe(map((response) => response.body));
    }
}
