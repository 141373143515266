import { ChangeDetectorRef, Component, Input, ViewChild, ElementRef, ChangeDetectionStrategy } from '@angular/core';
import { Subscription } from 'rxjs';
import { File } from '@uni-entities';
import { StatisticsService } from '@app/services/common/statisticsService';

@Component({
    selector: 'uni-image-file-comment',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <section *ngIf="shouldShowDescription" class="alert info custom-scrollbar" (click)="toggleExpanded()">
            <i class="material-icons">info</i>

            <span #textEl class="flex-1" [class.ellipsis]="!isExpanded">
                {{ file.Description }}
            </span>

            <a *ngIf="isExpandable">
                {{ isExpanded ? 'Vis mindre' : 'Vis mer' }}
            </a>

            <i (click)="shouldShowDescription = false" class="material-icons close-icon">close</i>
        </section>
    `,
    styleUrls: ['./file-comment.sass'],
})
export class UniImageFileComment {
    @ViewChild('textEl') textElement: ElementRef<HTMLElement>;

    @Input() file: File;

    fileID: number;
    fileTagSubscription: Subscription;

    shouldShowDescription: boolean;

    isExpandable: boolean;
    isExpanded: boolean;

    constructor(
        private statisticsService: StatisticsService,
        private cdr: ChangeDetectorRef,
    ) {}

    ngOnChanges() {
        if (this.file?.Description) {
            if (this.file.ID !== this.fileID) {
                this.fileID = this.file.ID;
                this.shouldShowDescription = false;
                this.checkFileTags();
            }
        } else {
            this.shouldShowDescription = false;
        }
    }

    ngOnDestroy() {
        this.fileTagSubscription?.unsubscribe();
    }

    toggleExpanded() {
        // Avoid toggling if user selected text (that also triggers click event..)
        if (!this.isExpandable || document.getSelection()?.toString()) {
            return;
        }

        this.isExpanded = !this.isExpanded;
    }

    private checkFileTags() {
        this.fileTagSubscription?.unsubscribe();

        // Only show file comments for expenses for now (https://unimicro.atlassian.net/browse/RIG-2222)
        const odata = `model=FileTag&select=ID&filter=FileID eq ${this.fileID} and (TagName eq 'IncomingExpense' or TagName eq 'Upload')`;
        this.fileTagSubscription = this.statisticsService.GetAllUnwrapped(odata).subscribe(
            (res) => {
                this.shouldShowDescription = res?.length > 0;
                this.cdr.markForCheck();

                setTimeout(() => {
                    const textElement = this.textElement?.nativeElement;
                    if (textElement && textElement.scrollWidth > textElement.clientWidth) {
                        this.isExpandable = true;
                        this.isExpanded = false;
                        this.cdr.markForCheck();
                    }
                });
            },
            (err) => console.error(err),
        );
    }
}
