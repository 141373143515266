import { NgModule } from '@angular/core';
import { UniHelpComponent } from '@uni-framework/ui/help/help.component';
import { LibraryImportsModule } from '@app/library-imports.module';

@NgModule({
    imports: [LibraryImportsModule],
    declarations: [UniHelpComponent],
    exports: [UniHelpComponent],
})
export class UniHelpModule {}
