import { Component, Input } from '@angular/core';

@Component({
    selector: 'cms-text',
    template: `<p [ngClass]="item.textType" [innerHTML]="item.text"></p>`,
    styleUrls: ['./text.sass'],
})
export class CMSText {
    @Input()
    item: any;

    constructor() {}
}
