<section class="input-with-button">
    <input
        #input
        type="text"
        (focus)="onFocus()"
        (change)="parseInput()"
        (keydown)="onKeyDown($event)"
        [formControl]="control"
        [readonly]="readonly || (readOnly$ | async)"
        [placeholder]="field?.Placeholder || ''"
    />

    <!-- Hidden input for mat-datepicker, because we want custom parsing on the "text input" -->
    <input
        style="position: absolute; visibility: hidden; width: 0px; height: 2.5rem"
        [(ngModel)]="calendarDate"
        (ngModelChange)="onCalendarDateChange()"
        [matDatepicker]="picker"
        tabindex="-1"
    />

    <button type="button" tabindex="-1" [disabled]="readonly || (readOnly$ | async)" (click)="picker.open()">
        <uni-icon icon="calendar"></uni-icon>
    </button>

    <mat-datepicker #picker (closed)="focus()"></mat-datepicker>
</section>
