import { Injectable, inject } from '@angular/core';
import { AuthService } from '@app/authService';
import { rigDate } from '@app/components/common/utils/rig-date';
import { filter, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

/*
    If you need access to a function in the ApplicationInsights library please add
    a function for it in application-insights-lazy-bundle.ts and call that function
    instead of calling the ApplicationInsights function directly.

    This allows us to lazy load the library, instead of having it in the main bundle.
*/
@Injectable({ providedIn: 'root' })
export class Logger {
    private authService = inject(AuthService);

    appInsightsWrapper;

    init() {
        import('./application-insights-lazy-bundle').then((res) => {
            if (res?.AppInsightsWrapper) {
                this.appInsightsWrapper = new res.AppInsightsWrapper(environment.APP_INSIGHTS_KEY);
                this.checkPopoverSupport();
            }
        });
    }

    logError(err: any, severityLevel?: number) {
        if (this.appInsightsWrapper) {
            const error = err instanceof Error ? err : new Error(err);
            this.appInsightsWrapper.logError(error, severityLevel);
        }
    }

    /*
         Temporary logging of users without support for the Popover API.
         If you come across this in a year I probably forgot about it,
         and it should be safe to remove.
    */
    checkPopoverSupport() {
        // Wait until we have valid auth to avoid false positives from crawlers.
        this.authService.authentication$
            .pipe(
                filter((auth) => !!auth?.user),
                take(1),
            )
            .subscribe(() => {
                try {
                    if (!HTMLElement.prototype.hasOwnProperty('popover')) {
                        // Don't log more than once per week
                        const logTimestamp = localStorage.getItem('popoverapi-log-timestamp');
                        if (!logTimestamp || rigDate().diff(rigDate(+logTimestamp), 'days') > 7) {
                            this.appInsightsWrapper.logMissingPopoverSupport();
                            localStorage.setItem('popoverapi-log-timestamp', Date.now().toString());
                        }
                    }
                } catch (e) {}
            });
    }
}
