<section role="dialog" class="uni-modal">
    <article>
        <i class="material-icons check">check_circle</i>
        <h1>{{ options.header || 'Velkommen til ' + appName }}</h1>
        <h2>Hva vil du gjøre nå?</h2>

        <ul>
            <li (click)="navigate('/settings/accounting/incoming-balance/choice')">
                <i class="material-icons with-border">add</i>
                <section class="link-info">
                    <strong>Inngående balanse</strong>
                    <span>Registrer inngående balanse</span>
                </section>
            </li>
            <li (click)="navigate('/bank/ticker?code=bank_list')" *ngIf="isSrEnvironment">
                <i class="material-icons">account_balance</i>
                <section class="link-info">
                    <strong>Koble sammen bank og regnskap</strong>
                    <span>Legg til standardkontoer i firmaoppsettet og aktiver autobank</span>
                </section>
            </li>
            <li (click)="navigate('/settings/company')">
                <i class="material-icons">settings</i>
                <section class="link-info">
                    <strong>Firmainnstillinger</strong>
                    <span>Oppsett og innstillinger for ditt firma</span>
                </section>
            </li>
            <li (click)="navigate('/marketplace/modules')">
                <i class="material-icons">shopping_basket</i>
                <section class="link-info">
                    <strong>Markedsplass</strong>
                    <span>Oppdag og aktiver utvidelser og integrasjoner</span>
                </section>
            </li>
        </ul>
    </article>

    <footer class="center">
        <button class="c2a rounded" (click)="onClose.emit()">Gå til startside</button>
    </footer>
</section>
