import {
    NgModule,
    TemplateRef,
    Component,
    Input,
    ViewChild,
    ChangeDetectionStrategy,
    ContentChild,
    Output,
    EventEmitter,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayModule, OverlayRef, Overlay, OverlayConfig, ConnectedPosition } from '@angular/cdk/overlay';
import { PortalModule, CdkPortal } from '@angular/cdk/portal';
import { fromEvent, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { generateId } from '@app/components/common/utils/utils';
import { A11yModule } from '@angular/cdk/a11y';

@Component({
    selector: 'dropdown-menu',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <section
            *cdkPortal
            cdkTrapFocus
            tabindex="-1"
            [id]="dropdownId"
            role="menu"
            [attr.aria-labelledby]="triggerId"
            [attr.aria-activedescendant]="activeDescendantId"
            [class.dropdown-menu]="!unstyled"
            (click)="onClickInside()"
            (keydown)="onKeydown($event, true)"
            (mouseenter)="menuHovered = true"
            (mouseleave)="onMouseleaveMenu()"
            class="custom-scrollbar scrollbar-visible"
        >
            <ng-container *ngTemplateOutlet="content"></ng-container>
        </section>
    `,
})
export class DropdownMenu {
    @ContentChild(TemplateRef, { static: true }) content: TemplateRef<any>;
    @ViewChild(CdkPortal, { static: true }) contentTemplate: CdkPortal;

    @Input() trigger: any;
    @Input() minWidth: number | string;
    @Input() maxHeight: number | string;
    @Input() alignRight: boolean;
    @Input() closeOnClick: boolean = true;
    @Input() closeOnTab: boolean = true;
    @Input() hoverMode: boolean;
    @Input() unstyled: boolean;
    @Input() overlayPositions: ConnectedPosition[];
    @Input() stopKeydownPropagation = false;

    @Output() opened = new EventEmitter();
    @Output() closed = new EventEmitter();

    protected overlayRef: OverlayRef;

    clickSubscription: Subscription;
    keydownSubscription: Subscription;
    hoverSubscriptions: Subscription[];

    triggerHovered: boolean;
    menuHovered: boolean;
    isVisible: boolean;

    triggerElement: HTMLElement;

    triggerId: string;
    dropdownId = generateId('dropdown-menu');

    items: HTMLElement[];
    focusIndex: number;
    activeDescendantId: string;

    constructor(protected overlay: Overlay) {}

    ngOnChanges(changes) {
        if (changes['trigger'] && this.trigger) {
            try {
                this.clickSubscription?.unsubscribe();

                if (this.trigger.nodeType) {
                    this.triggerElement = this.trigger;
                } else if (this.trigger.elementRef) {
                    this.triggerElement = this.trigger.elementRef.nativeElement;
                } else {
                    this.triggerElement = undefined;
                    console.warn('Missing/invalid trigger for dropdown-menu.ts');
                }

                if (this.triggerElement) {
                    this.clickSubscription = fromEvent(this.triggerElement, 'click').subscribe(() => {
                        this.show();
                    });

                    this.keydownSubscription = fromEvent(this.triggerElement, 'keydown').subscribe(
                        (event: KeyboardEvent) => {
                            this.onKeydown(event);
                        },
                    );

                    if (this.hoverMode) {
                        this.setupHoverSubscriptions();
                    }

                    this.setAccessibilityAttributesOnTrigger(this.triggerElement);
                }
            } catch (e) {
                console.error(e);
            }
        }
    }

    ngOnDestroy() {
        this.hide();
        this.clickSubscription?.unsubscribe();
        this.keydownSubscription?.unsubscribe();
        this.hoverSubscriptions?.forEach((sub) => sub.unsubscribe());
    }

    show(wasHoverEvent?: boolean) {
        if (!this.overlayRef?.hasAttached()) {
            this.overlayRef = this.overlay.create(this.getOverlayConfig(!wasHoverEvent));
            this.overlayRef.attach(this.contentTemplate);
            this.syncWidth();

            this.overlayRef
                .backdropClick()
                .pipe(take(1))
                .subscribe(() => this.hide());
            this.overlayRef.keydownEvents().subscribe((event: KeyboardEvent) => {
                if (event.key === 'Escape') {
                    this.hide();
                }
            });

            this.isVisible = true;
            this.triggerElement?.setAttribute('aria-expanded', 'true');
            setTimeout(() => {
                const items = this.overlayRef?.overlayElement?.querySelectorAll<HTMLElement>('.dropdown-menu-item');
                this.items = items ? Array.from(items) : [];
                this.focusIndex = -1;
                if (this.items.length) {
                    this.setAccessibilityAttributesOnItems(this.items);
                }
            });

            this.opened.emit();
        }
    }

    hide() {
        if (this.overlayRef) {
            this.overlayRef.detach();
            this.overlayRef.dispose();
            this.overlayRef = undefined;
            this.isVisible = false;
            this.triggerElement?.setAttribute('aria-expanded', 'false');
            this.items = [];
            this.focusIndex = -1;
            this.closed.emit();

            if (!this.hoverMode && this.trigger) {
                let el = this.trigger.elementRef ? this.trigger.elementRef.nativeElement : this.trigger;
                el?.focus?.();
            }
        }
    }

    onMouseleaveMenu() {
        if (this.hoverMode) {
            this.menuHovered = false;
            this.afterMouseLeave();
        }
    }

    onClickInside() {
        if (this.closeOnClick) {
            this.hide();
        }
    }

    private setupHoverSubscriptions() {
        this.hoverSubscriptions?.forEach((sub) => sub.unsubscribe());
        this.hoverSubscriptions = [
            fromEvent(this.triggerElement, 'mouseenter').subscribe(() => {
                this.triggerHovered = true;
                this.show(true);
            }),
            fromEvent(this.triggerElement, 'mouseleave').subscribe(() => {
                this.triggerHovered = false;
                this.afterMouseLeave();
            }),
        ];
    }

    private afterMouseLeave() {
        // Wait 250ms then check if we should close (to avoid closing when moving cursor between trigger and menu)
        setTimeout(() => {
            if (!this.menuHovered && !this.triggerHovered) {
                this.hide();
            }
        }, 100);
    }

    protected getOverlayConfig(includeBackdrop: boolean): OverlayConfig {
        const prefXPos = this.alignRight ? 'end' : 'start';
        const altXPos = this.alignRight ? 'start' : 'end';

        let positions: ConnectedPosition[] = [
            {
                originX: prefXPos,
                originY: 'bottom',
                overlayX: prefXPos,
                overlayY: 'top',
            },
            {
                originX: altXPos,
                originY: 'bottom',
                overlayX: altXPos,
                overlayY: 'top',
            },
            {
                originX: prefXPos,
                originY: 'top',
                overlayX: prefXPos,
                overlayY: 'bottom',
            },
            {
                originX: altXPos,
                originY: 'top',
                overlayX: altXPos,
                overlayY: 'bottom',
            },
        ];

        if (this.overlayPositions) {
            positions = this.overlayPositions;
        }

        const positionStrategy = this.overlay
            .position()
            .flexibleConnectedTo(this.triggerElement)
            .withPush(false)
            .withPositions(positions);

        const scrollStrategy = this.overlay.scrollStrategies.reposition();

        return new OverlayConfig({
            positionStrategy: positionStrategy,
            scrollStrategy: scrollStrategy,
            hasBackdrop: includeBackdrop,
            backdropClass: 'cdk-overlay-transparent-backdrop',
        });
    }

    private syncWidth() {
        if (this.overlayRef) {
            const refRect = this.triggerElement.getBoundingClientRect();
            this.overlayRef.updateSize({
                minWidth: this.minWidth || refRect.width || '10rem',
                maxHeight: this.maxHeight,
            });
        }
    }

    onKeydown(event: KeyboardEvent, eventHappenedInsideDropdown?: boolean) {
        if (this.stopKeydownPropagation) {
            event.stopPropagation();
        }

        if (!eventHappenedInsideDropdown && (event.key === 'Enter' || event.key === ' ')) {
            event.preventDefault();
            if (this.isVisible) {
                const focusedItem = this.items && this.items[this.focusIndex];
                focusedItem?.click();
                if (this.closeOnClick) {
                    this.hide();
                }
            } else {
                this.show();
            }
        }

        if (this.isVisible) {
            if (event.key === 'Tab' && this.closeOnTab) {
                this.hide();
            }

            if (event.key === 'ArrowDown') {
                event.preventDefault();
                this.focusNext();
            }

            if (event.key === 'ArrowUp') {
                event.preventDefault();
                this.focusPrevious();
            }

            if (event.key === 'Escape') {
                this.hide();
            }
        }
    }

    private setAccessibilityAttributesOnTrigger(trigger: HTMLElement) {
        this.triggerId = trigger?.id || generateId('dropdown-toggle');
        trigger.id = this.triggerId;
        this.triggerElement.setAttribute('aria-haspopup', 'true');
        this.triggerElement.setAttribute('role', 'button');
        this.triggerElement.setAttribute('controls', this.dropdownId);

        if (this.triggerElement.tabIndex < 0) {
            this.triggerElement.tabIndex = 0;
        }
    }

    private setAccessibilityAttributesOnItems(items: HTMLElement[]) {
        items.forEach((item, index) => {
            item.setAttribute('role', 'menuitem');
            if (!item.id) {
                item.id = generateId('dropdown-item');
            }

            if (item.classList.contains('selected')) {
                item.scrollIntoView({ block: 'center' });
                this.focusIndex = index;
            }
        });
    }

    focusNext() {
        if (!this.items?.length) return;

        if (this.focusIndex < this.items.length - 1) {
            this.focusIndex++;
        } else {
            this.focusIndex = 0;
        }

        this.onFocusIndexChange();
    }

    focusPrevious() {
        if (!this.items?.length) return;

        if (this.focusIndex > 0) {
            this.focusIndex--;
        } else {
            this.focusIndex = this.items.length - 1;
        }

        this.onFocusIndexChange();
    }

    private onFocusIndexChange() {
        this.items?.forEach((item, index) => {
            if (index === this.focusIndex) {
                item.classList.add('selected');
                this.activeDescendantId = item.id;
                item.scrollIntoView({ block: 'nearest' });
            } else {
                item.classList.remove('selected');
            }
        });
    }
}

@NgModule({
    imports: [CommonModule, OverlayModule, PortalModule, A11yModule],
    declarations: [DropdownMenu],
    exports: [DropdownMenu],
})
export class DropdownMenuModule {}
