import { Component, ElementRef, Input, NgZone, HostBinding, input } from '@angular/core';
import { BrowserStorageService } from '@uni-framework/core/browserStorageService';
import { fromEvent } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

const LOCALSTORAGE_KEY = 'resizeable_section_widths';

@Component({
    selector: 'resizeable',
    templateUrl: './resizeable.html',
    styleUrls: ['./resizeable.sass'],
})
export class Resizeable {
    @HostBinding('style') styles: Record<string, string> = {};

    @Input() defaultWidth: string;
    @Input() maxWidth: string;
    @Input() minWidth: string;
    @Input() storageKey: string;

    collapsed = input<boolean>();

    savedWidths = this.browserStorageService.getItem(LOCALSTORAGE_KEY) || {};

    constructor(
        private ngZone: NgZone,
        private elementRef: ElementRef,
        private browserStorageService: BrowserStorageService,
    ) {}

    ngOnChanges() {
        this.applyStyles();
    }

    private applyStyles() {
        const collapsed = this.collapsed();
        this.styles = {
            width: collapsed ? '0%' : (this.savedWidths?.[this.storageKey] ?? this.defaultWidth),
            minWidth: collapsed ? 'unset' : this.minWidth,
            maxWidth: this.maxWidth,
        };
    }

    onMouseDown(event: MouseEvent) {
        const startX = event.clientX;
        this.initResize(startX, 'mousemove', 'mouseup');
    }

    onTouchStart(event: TouchEvent) {
        const startX = event.touches[0].clientX;
        this.initResize(startX, 'touchmove', 'touchend');
    }

    reset() {
        if (this.storageKey) {
            delete this.savedWidths[this.storageKey];
            this.browserStorageService.setItem(LOCALSTORAGE_KEY, this.savedWidths);
        }

        this.applyStyles();
    }

    private initResize(startingXPosition: number, moveEvent: string, endEvent: string) {
        const host: HTMLElement = this.elementRef?.nativeElement;

        if (host) {
            const startWidth = host.clientWidth;

            this.ngZone.runOutsideAngular(() => {
                fromEvent(document, moveEvent)
                    .pipe(takeUntil(fromEvent(document, endEvent).pipe(take(1))))
                    .subscribe({
                        next: (event: MouseEvent | TouchEvent) => {
                            let xPosition;
                            if (event instanceof MouseEvent) {
                                event.preventDefault();
                                xPosition = event.clientX;
                            } else {
                                xPosition = event.touches[0].clientX;
                            }

                            host.style.width = startWidth + (startingXPosition - xPosition) + 'px';
                        },
                        complete: () => {
                            if (host && host.clientWidth !== startWidth) {
                                this.onResizeEnd(host.clientWidth);
                            }
                        },
                    });
            });
        }
    }

    private onResizeEnd(newWidth: number) {
        if (this.storageKey) {
            this.savedWidths[this.storageKey] = newWidth + 'px';
            this.browserStorageService.setItem(LOCALSTORAGE_KEY, this.savedWidths);
        }

        this.applyStyles();
    }
}
