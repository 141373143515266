import { Component, ChangeDetectionStrategy, Input, HostBinding, ChangeDetectorRef } from '@angular/core';
import { NavigationEnd, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
    selector: 'rig-link',
    template: `
        <a [routerLink]="path" [queryParams]="params">
            <ng-content></ng-content>
        </a>
    `,
    styles: [
        `
            :host {
                display: inline-block;
                color: var(--link-color);
            }

            a {
                display: block;
                color: inherit;
                text-decoration: none;
                font-weight: inherit;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RigLink {
    @Input() link: string;
    @Input() class: string;
    @Input() activeClass: string;

    @HostBinding('class') hostClass: string;

    path: string;
    params: Params;

    routerSubscription: Subscription;

    constructor(
        private router: Router,
        private cdr: ChangeDetectorRef,
    ) {}

    ngOnChanges(changes) {
        if (changes.link && this.link) {
            const [path, params] = this.link.split('?');

            this.path = path;
            this.params = this.getParamsObject(params);
        }

        if (changes.activeClass) {
            this.initActiveClassListener();
        }
    }

    ngOnDestroy() {
        this.routerSubscription?.unsubscribe();
    }

    private getParamsObject(paramsString: string) {
        if (!paramsString) return;

        const params = {};

        new URLSearchParams(paramsString).forEach((value, key) => {
            params[key] = value;
        });

        return params;
    }

    private initActiveClassListener() {
        this.updateHostClass(this.router.url);

        this.routerSubscription?.unsubscribe();
        this.routerSubscription = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.updateHostClass(event.url);
            }
        });
    }

    private updateHostClass(routerUrl: string) {
        const url = routerUrl?.split('?')[0];
        const hostClass = url && url === this.path ? this.activeClass : undefined;
        if (hostClass !== this.hostClass) {
            this.hostClass = hostClass;
            this.cdr.markForCheck();
        }
    }
}
