import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { autocompleteDate } from '@app/date-adapter';
import { DateRangePicker } from '../date-range-picker';
import { rigDate } from '@app/components/common/utils/rig-date';

@Component({
    selector: 'date-range-input',
    templateUrl: './date-range-input.html',
    styleUrls: ['./date-range-input.sass'],
})
export class DateRangeInput {
    @ViewChild('input') inputElement: ElementRef<HTMLInputElement>;

    @Input() autofocus: boolean;
    @Input() label: string;
    @Input() hideLabel: boolean;
    @Input() date: Date;
    @Input() allowEmpty: boolean;
    @Output() dateChange = new EventEmitter<Date>();

    inputValue: string;

    constructor(private picker: DateRangePicker) {}

    ngOnInit() {
        this.setInputValue();
    }

    ngOnChanges() {
        this.setInputValue();
    }

    ngAfterViewInit() {
        if (this.autofocus) {
            setTimeout(() => this.focus());
        }
    }

    parseDate() {
        const date = autocompleteDate(this.inputValue);
        if (date || this.picker?.allowEmpty) {
            this.date = date;
            this.dateChange.emit(this.date);
        } else {
            this.setInputValue();
        }
    }

    focus() {
        if (this.inputElement?.nativeElement) {
            this.inputElement.nativeElement.focus();
            this.inputElement.nativeElement.select();
        }
    }

    private setInputValue() {
        this.inputValue = this.date ? rigDate(this.date).format('DD.MM.YYYY') : '';
    }
}
