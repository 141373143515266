import { Directive, ElementRef } from '@angular/core';
import SimpleBar from 'simplebar';

@Directive({ selector: '[uniScrollbar]' })
export class ScrollbarDirective {
    private scrollbar: SimpleBar;

    constructor(private elementRef: ElementRef) {}

    ngAfterViewInit() {
        if (this.elementRef.nativeElement) {
            const element: HTMLElement = this.elementRef.nativeElement;

            const parentStyles = window.getComputedStyle(element.parentElement);

            if (parentStyles.display === 'flex' && parentStyles.flexDirection === 'column') {
                element.style.height = '100%';
            }

            this.scrollbar = new SimpleBar(element, {
                autoHide: false,
            });
        }
    }

    ngOnDestroy() {
        this.scrollbar?.unMount();
    }
}
