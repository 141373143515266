import { Injectable, SimpleChange } from '@angular/core';
import { of, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { BizHttp } from '../../../framework/core/http/BizHttp';
import { BankAccount, StatusCode } from '../../unientities';
import { UniHttp } from '../../../framework/core/http/http';
import { ToastService, ToastTime, ToastType } from '../../../framework/uniToast/toastService';
import { StatisticsService } from '../common/statisticsService';

export interface CompanyBankAccountDto {
    BbanOrIban: string;
    AccountType: string;
    IsBankBalance?: boolean;
    IsBankStatement?: boolean;
    IsOutgoing?: boolean;
    IsInbound?: boolean;
    AgreementID?: number;
    MainAccountNumber?: number;
    Label: string;
    SetAsDefaultCompanyBankAccount: boolean;
}

@Injectable({ providedIn: 'root' })
export class BankAccountService extends BizHttp<BankAccount> {
    constructor(
        http: UniHttp,
        public toastr: ToastService,
        private statisticsService: StatisticsService,
    ) {
        super(http);
        this.relativeURL = 'bankaccounts';
        this.entityType = 'BankAccount';
    }

    public deleteRemovedBankAccounts(bc: SimpleChange) {
        return new Promise((resolve, reject) => {
            if (bc && Array.isArray(bc.previousValue)) {
                bc.previousValue
                    .filter((ba) => bc.currentValue.indexOf(ba) === -1)
                    .map((ba) => {
                        if (ba.ID > 0) {
                            this.Remove(ba.ID, 'BankAccount').subscribe(
                                () => {
                                    this.toastr.addToast('Account Removed', ToastType.good, ToastTime.short);
                                    resolve(true);
                                },
                                (err) => {
                                    this.toastr.addToast(
                                        'Error removing account',
                                        ToastType.bad,
                                        ToastTime.short,
                                        err.error.Message,
                                    );
                                    reject(ba);
                                },
                            );
                        } else {
                            resolve(false);
                        }
                    });
            }
        });
    }

    getBankServiceBankAccounts() {
        return this.GetAction(null, 'get-bankservice-bankaccounts');
    }

    createBankAccounts(data: any[]) {
        const action = 'create-bankaccounts-from-bankservice-bankaccounts';
        return this.ActionWithBody(null, data, action, 'post');
    }

    createCompanyBankAccounts(companyBankAccountsDto: CompanyBankAccountDto[], companyKey?: string) {
        const endpoint = 'bankaccounts?action=create-company-bankaccounts';

        return this.http
            .asPOST()
            .withBody(companyBankAccountsDto)
            .usingBusinessDomain()
            .withEndPoint(endpoint)
            .withHeader('CompanyKey', companyKey ? companyKey : '')
            .send({}, null, companyKey ? false : true)
            .pipe(map((res) => res.body));
    }

    getConnectedBankAccounts(accountID: number, skipBankAccountID: number): Observable<BankAccount[]> {
        const params = `accountID=${accountID}&skipBankAccountID=${skipBankAccountID}`;
        return this.GetAction(null, 'get-connected-bankaccounts-to-account', params);
    }

    getBankBalance(ID: number) {
        return ID ? this.GetAction(ID, 'bank-balance') : of(0);
    }

    getAllBankBalances(force: boolean = false) {
        return this.GetAll('action=get-all-bank-balances' + (force ? '&forceUpdate=true' : ''));
    }

    getAccountFromAccountNumber(accountNumber: number) {
        return this.http
            .asGET()
            .usingBusinessDomain()
            .withEndPoint(`/accounts?filter=AccountNumber eq '${accountNumber}'`)
            .send()
            .pipe(map((res) => res.body));
    }

    getCompanyBankAccountsFromSameBank(BankID: number): Observable<BankAccount[]> {
        const query = `filter=BankID eq ${BankID} and BankID ne null and CompanySettingsID gt 0`;
        return this.GetAll(query, ['BankAccountSettings']);
    }

    getRelatedCompanyBankAccount(bankAccountID: number): Observable<BankAccount[]> {
        return this.Get(bankAccountID).pipe(
            switchMap((bankAccount: BankAccount) => this.getCompanyBankAccountsFromSameBank(bankAccount?.BankID)),
        );
    }

    getSystemBankAccounts() {
        const select = [
            'ID as ID',
            'AccountID as AccountID',
            'AccountNumber as AccountNumber',
            'BankAccountType as BankAccountType',
            'Label as Label',
            'Account.AccountNumber as GlAccountNumber',
            'Account.AccountName as AccountName',
            'Bank.Name',
        ].join(',');

        return this.statisticsService.GetAllUnwrapped(
            'model=BankAccount&top=50&distinct=false' +
                `&select=${select}` +
                `&filter=CompanySettingsID gt 0 and isnull(StatusCode, 0) ne ${StatusCode.InActive}` +
                '&expand=Bank,Account' +
                '&join=BankAccount.ID eq CompanySettings.CompanyBankAccountID' +
                '&orderby=casewhen(CompanySettingsID gt 0 and id eq companysettings.companybankaccountid,0,1)',
        );
    }
}
