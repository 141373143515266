import { Component, Input, EventEmitter, Output, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { NewAccountModalV2 } from '@app/components/common/modals/new-account-modal-v2/new-account-modal-v2';
import { UniModalService } from '@uni-framework/uni-modal';
import { AccountSearchDropdown } from './account-search-dropdown/account-search-dropdown';
import { AccountSearchStore } from './account-search.store';

@Component({
    selector: 'account-search',
    template: `
        <section class="input-with-button" (clickOutside)="onClickOutside()">
            <input
                type="text"
                #inputElement
                [formControl]="inputControl"
                (keydown)="onKeydown($event)"
                [readonly]="readonly"
                (focus)="inputElement?.select()"
            />

            <button
                tabindex="-1"
                type="button"
                [disabled]="readonly"
                (click)="$event.stopPropagation(); store.toggleOpen(); inputElement.focus()"
            >
                <i *ngIf="!(store.loading$ | async)" class="material-icons">search</i>
                <mat-spinner *ngIf="store.loading$ | async" diameter="18"></mat-spinner>
            </button>
        </section>

        <account-search-dropdown
            [inputElement]="inputElement"
            (accountClick)="onAccountSelected($event, true)"
            (createAccount)="createAccount()"
            (keyDown)="onKeydown($event)"
        >
        </account-search-dropdown>
    `,
    providers: [AccountSearchStore],
})
export class AccountSearch {
    @ViewChild(AccountSearchDropdown) dropdown: AccountSearchDropdown;
    @ViewChild('inputElement') inputElement: ElementRef;

    @Input() readonly: boolean;
    @Input() account;
    @Output() accountChange = new EventEmitter();

    inputControl = new UntypedFormControl('');

    constructor(
        public store: AccountSearchStore,
        private modalService: UniModalService,
    ) {}

    ngOnInit() {
        this.store.init(this.inputControl);
    }

    ngOnChanges(changes) {
        if (changes['account']) {
            this.updateControlValue();
        }
    }

    onAccountSelected(account, refocus?: boolean) {
        this.account = account;
        this.accountChange.emit(account);
        this.close();

        if (refocus) {
            this.inputElement.nativeElement?.focus();
        }

        if (account && !account.Visible) {
            this.store.onHiddenAccountSelected(account).subscribe((updatedAccount) => {
                if (updatedAccount) {
                    this.onAccountSelected(updatedAccount);
                }
            });
        }
    }

    onClickOutside() {
        if (this.store.open$.value) {
            if (!this.inputControl.dirty && !this.inputControl.value) {
                this.onAccountSelected(null);
            } else {
                this.close();
            }
        }
    }

    createAccount() {
        this.modalService
            .open(NewAccountModalV2, {
                data: {
                    entityType: 'account',
                    hideEntityTypeSelector: true,
                    text: this.inputControl.value || '',
                },
            })
            .onClose.subscribe((account) => {
                if (account) {
                    this.onAccountSelected(account);
                }
            });
    }

    onKeydown(event: KeyboardEvent) {
        switch (event.key) {
            case 'ArrowDown':
                event.preventDefault();
                if (this.store.open$.value) {
                    this.dropdown?.focusNext();
                } else {
                    this.store.toggleOpen();
                }
                break;
            case 'ArrowUp':
                event.preventDefault();
                this.dropdown?.focusPrevious();
                break;
            case 'Enter':
            case 'Tab':
                this.handleTabOrEnter();
                break;
            case 'Escape':
                event.stopPropagation();
                this.close();
                break;
            case ' ': // space
                event.preventDefault();
                this.store.toggleOpen();
                break;
        }
    }

    private handleTabOrEnter() {
        if (!this.store.open$.value) {
            return;
        }

        const inputValue = this.inputControl.value;

        if (inputValue && this.store.loading$.value) {
            this.store.lookupAndSelectBestMatch(inputValue).subscribe((account) => {
                if (account) {
                    this.onAccountSelected(account);
                } else {
                    this.updateControlValue();
                    this.store.open$.next(false);
                }
            });
        } else {
            const accounts = this.store.accounts$.value || [];
            const focusIndex = this.store.focusIndex$.value;
            if (accounts[focusIndex]) {
                this.onAccountSelected(accounts[focusIndex]);
            } else if (this.inputControl.dirty && !inputValue) {
                this.onAccountSelected(null);
            } else {
                this.close();
            }
        }
    }

    private close() {
        this.store.open$.next(false);
        this.updateControlValue();
    }

    private updateControlValue() {
        this.inputControl.setValue(this.store.getDisplayValue(this.account), { emitEvent: false });
    }
}
