import { Injectable } from '@angular/core';
import { UniHttp } from '../../../framework/core/http/http';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BusinessRelationSearch } from '../../models/Integration/BusinessRelationSearch';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, switchMap } from 'rxjs/operators';
import { ModulusService } from './modulusService';
import { get } from 'lodash-es';

@Injectable({ providedIn: 'root' })
export class IntegrationServerCaller {
    travelTextEndpoint = environment.BASE_URL_INTEGRATION + 'api/traveltext/';

    constructor(
        protected http: UniHttp,
        private httpClient: HttpClient,
        private modulusService: ModulusService,
    ) {}

    searchBrReg(query): Observable<BusinessRelationSearch[]> {
        const trimmed = query.replace(/\ /g, '');
        let endpoint = this.modulusService.isValidOrgNr(trimmed)
            ? `https://data.brreg.no/enhetsregisteret/api/enheter/${trimmed}`
            : `https://data.brreg.no/enhetsregisteret/api/enheter?navn=${encodeURI(query)}`;

        return this.httpClient.get(endpoint).pipe(
            map((res: any) => {
                if (!res) {
                    return [];
                }

                let items = [];
                if (res['_embedded']?.enheter) {
                    items = res['_embedded'].enheter;
                } else {
                    items = Array.isArray(res) ? res : [res];
                }

                return items.map((item) => {
                    return (
                        item &&
                        <BusinessRelationSearch>{
                            Name: item.navn,
                            OrganizationNumber: item.organisasjonsnummer,
                            Streetaddress: get(item, 'forretningsadresse.addresse[0]'),
                            PostCode: get(item, 'forretningsadresse.postnummer'),
                            City: get(item, 'forretningsadresse.poststed'),
                            CountryCode: get(item, 'forretningsadresse.landkode'),
                            MuncipalNumber: get(item, 'forretningsadresse.kommunenummer'),
                            Muncipal: get(item, 'forretningsadresse.kommune'),
                        }
                    );
                });
            }),
        );
    }

    public businessRelationSearch(
        query: string,
        limit: number = 30,
        searchCompanies: boolean = true,
        searchPersons: boolean = true,
    ): Observable<BusinessRelationSearch[]> {
        if (!query) {
            return of([]);
        }

        const endpoint =
            environment.BASE_URL_INTEGRATION +
            'api/businessrelationsearch' +
            `?searchCriteria=${query}` +
            `&limit=${limit}` +
            `&searchCompanies=${searchCompanies}` +
            `&searchPersons=${searchPersons}`;

        return this.httpClient.get<BusinessRelationSearch[]>(endpoint).pipe(
            switchMap((res) => {
                if (!res?.length && searchCompanies) {
                    return this.searchBrReg(query);
                }

                return of(res);
            }),
        );
    }

    getTravelTextUsers(): Observable<any[]> {
        return this.httpClient.get<any[]>(this.travelTextEndpoint + 'persons');
    }

    registerTravelText(payload: any) {
        return this.httpClient.post(this.travelTextEndpoint + 'register', payload);
    }

    inviteUsersTravelText(payload: any) {
        return this.httpClient.put(this.travelTextEndpoint + 'persons', payload);
    }

    travelTextLogin(payload: any) {
        return this.httpClient.post(this.travelTextEndpoint + 'traveltext-login', payload);
    }

    setTravelTextIntegration(payload: any, token: string) {
        const headers = new HttpHeaders({ TravelTextAuth: 'Bearer ' + token });
        return this.httpClient.post(this.travelTextEndpoint + 'economy-integration', payload, { headers: headers });
    }

    getTravelTextUserConfig(token: string) {
        const headers = new HttpHeaders({ TravelTextAuth: 'Bearer ' + token });
        return this.httpClient.get(this.travelTextEndpoint + 'user-config', { headers: headers });
    }

    travelTextOrgNrAvailable(orgnr: string): Observable<boolean> {
        return this.httpClient.get<boolean>(this.travelTextEndpoint + 'IsOrgnumberAvailable?orgnumber=' + orgnr);
    }
}
