import {
    Component,
    Input,
    Output,
    EventEmitter,
    ViewChild,
    ElementRef,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    OnChanges,
    AfterViewInit,
    HostBinding,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, take } from 'rxjs/operators';
import { get } from 'lodash-es';
import { KeyCodes } from '@app/services/common/keyCodes';
import { SelectItemList } from './select-item-list';

export interface ISelectConfig {
    valueProperty?: string;
    displayProperty?: string;
    addEmptyValue?: boolean;
    emptyValueText?: string;
    template?: (item) => string;
    subTextTemplate?: (item) => string;
    placeholder?: string;
    searchable?: boolean;
    hideDeleteButton?: boolean;
    dropdownMaxHeight?: string;
    emitValueInsteadOfObject?: boolean;
    itemHidden?: (item: any) => boolean;
    createItemButton?: {
        label: string;
        action: () => void;
    };
    itemActionResolver?: (item: any) => {
        edit?: (item: any) => void;
        delete?: (item: any) => void;
    };
}

export type SelectItemGroup = { label: string; items: any[] };

@Component({
    selector: 'uni-select',
    templateUrl: './select.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UniSelect implements OnChanges, AfterViewInit {
    @ViewChild('searchInput') searchInput: ElementRef;
    @ViewChild('valueInput') valueInput: ElementRef;
    @ViewChild(SelectItemList) itemList: SelectItemList;

    @Input() placeholder: string;
    @Input() items: any[];
    @Input() itemGroups: SelectItemGroup[];
    @Input() source: () => Observable<any>;
    @Input() readonly: boolean;
    @Input() config: ISelectConfig;
    @Input() autoWidth: boolean;
    @Input() value: any;
    @Input() searchFn: (query: string) => Observable<any>;
    @Input() initialItemFn: () => Observable<any>;
    @Output() valueChange = new EventEmitter<any>();
    @Output() readyEvent = new EventEmitter<UniSelect>(true);

    visibleItems: any[];

    @HostBinding('style.width') get width() {
        if (this.autoWidth && this.displayValue) {
            const ch = `${this.displayValue.length}ch`;
            return `calc(${ch} + 41px)`;
        }
    }

    expanded: boolean = false;

    searchable: boolean = true;
    searchControl = new UntypedFormControl('');
    filterString: string = '';

    selectedItem: any = null;
    initialItem: any;

    displayValue: string = '';

    displayValueSubscription: Subscription;
    searchSubscription: Subscription;

    initializeItemIsRunning = false;

    constructor(
        public cd: ChangeDetectorRef,
        public el: ElementRef,
    ) {}

    ngOnInit() {
        this.initializeItem();
    }

    ngOnChanges(changes) {
        if (this.config && (this.items || this.itemGroups)) {
            this.setVisibleItems();
            this.selectedItem = this.getSelectedItem();

            this.searchable = this.config.searchable || this.config.searchable === undefined;
            this.searchControl.setValue('', { emitEvent: false });

            this.displayValue = this.getDisplayValue(this.selectedItem);
            this.cd.markForCheck();
        }
        if (this.initialItemFn && this.initialItem !== this.selectedItem) {
            this.initializeItem();
        }
    }

    ngAfterViewInit() {
        this.searchSubscription = this.searchControl.valueChanges
            .pipe(debounceTime(150), distinctUntilChanged())
            .subscribe((value) => {
                this.onSearchInputChange(value);
            });

        this.readyEvent.emit(this);
    }

    ngOnDestroy() {
        this.searchSubscription?.unsubscribe();
        this.displayValueSubscription?.unsubscribe();
    }

    private setVisibleItems() {
        if (this.config?.itemHidden) {
            this.visibleItems = this.items.filter((item) => !this.config.itemHidden(item));
        } else {
            this.visibleItems = this.items;
        }
    }

    onKeyDown(event: KeyboardEvent) {
        const key = event.key;
        const isAlphanumeric = key.length === 1 && /^[a-zæøå0-9]+$/i.test(key);

        if (isAlphanumeric) {
            if (this.searchable && !this.expanded) {
                this.toggle();
                this.searchControl.setValue(key);
                this.cd.markForCheck();
            }

            if (!this.searchable) {
                const item = this.items.find((item) => {
                    const displayValue = this.getDisplayValue(item)?.toLowerCase();
                    return displayValue?.startsWith(key.toLowerCase());
                });

                if (item) {
                    this.confirmSelection(item);
                }
            }
        }

        switch (event.keyCode) {
            case KeyCodes.F4:
                this.toggle();
                break;
            case KeyCodes.UP_ARROW:
                event.preventDefault();
                if (this.expanded) {
                    this.itemList?.focusPrevious();
                } else {
                    this.toggle();
                }
                break;
            case KeyCodes.DOWN_ARROW:
                event.preventDefault();
                if (this.expanded) {
                    this.itemList?.focusNext();
                } else {
                    this.toggle();
                }
                break;
            case KeyCodes.ENTER:
            case KeyCodes.TAB:
                if (this.expanded) {
                    event.stopPropagation();
                    this.itemList?.select();
                    this.close(true);
                }
                break;
            case KeyCodes.ESCAPE:
                event.preventDefault();
                event.stopPropagation();
                this.selectedItem = this.initialItem;
                this.close(true);
                break;
        }
    }

    private initializeItem() {
        if (this.initialItemFn && !this.initializeItemIsRunning) {
            this.initializeItemIsRunning = true;
            this.initialItemFn()
                .pipe(take(1))
                .subscribe((item) => {
                    this.initialItem = item;
                    this.selectedItem = this.initialItem;
                    this.searchable = this.config.searchable || this.config.searchable === undefined;
                    this.searchControl.setValue('', { emitEvent: false });
                    this.displayValue = this.getDisplayValue(this.selectedItem);
                    this.initializeItemIsRunning = false;
                    this.cd.markForCheck();
                });
        }
    }

    private onSearchInputChange(filterString: string) {
        if (this.searchFn) {
            this.searchFn(filterString).subscribe((items) => {
                this.items = items;
                this.setVisibleItems();
                this.cd.markForCheck();
            });
        } else {
            this.filterString = filterString;
            this.cd.markForCheck();
        }
    }

    getDisplayValue(item, showNullAsNotSelected?: boolean): string {
        if (!this.config) {
            return '';
        }

        if (!item) {
            if (this.config.emptyValueText) {
                return this.config.emptyValueText;
            }
            return showNullAsNotSelected ? 'Ikke valgt' : '';
        }

        if (typeof item === 'string') {
            return item;
        } else if (this.config.displayProperty) {
            return get(item, this.config.displayProperty, '');
        } else if (this.config.template) {
            return this.config.template(item) || '';
        } else {
            return '';
        }
    }

    confirmSelection(item, event?: MouseEvent) {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }

        this.selectedItem = item;
        this.initialItem = this.selectedItem;
        this.displayValue = this.getDisplayValue(this.selectedItem);
        if (this.selectedItem !== undefined) {
            if (this.config.emitValueInsteadOfObject && this.config.valueProperty) {
                this.valueChange.emit(get(this.selectedItem, this.config.valueProperty));
            } else {
                this.valueChange.emit(this.selectedItem);
            }
        }

        this.close();
        this.focus();
    }

    public clear(event?: MouseEvent | KeyboardEvent) {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }

        if (this.readonly || this.config.hideDeleteButton) {
            return;
        }

        this.searchControl.setValue('', { emitEvent: false });
        this.selectedItem = null;
        this.displayValue = '';
        this.initialItem = null;
        this.valueChange.emit(null);
        this.close();
        this.focus();
        this.cd.markForCheck();
    }

    public open() {
        if (this.readonly || this.expanded) {
            return;
        }
        this.searchControl.setValue('', { emitEvent: false });
        this.filterString = '';
        if (this.searchFn) {
            this.searchFn('').subscribe((items) => {
                this.items = items;
                this.setVisibleItems();
                this.expanded = true;
                this.cd.markForCheck();
            });
        } else if (typeof this.source === 'function' && this.source().subscribe && !this.items?.length) {
            this.source().subscribe((items) => {
                this.items = items;
                this.setVisibleItems();
                this.expanded = true;
                this.cd.markForCheck();
            });
        } else if (this.items || this.itemGroups) {
            this.expanded = true;
            this.cd.markForCheck();
        } else {
            this.cd.markForCheck();
        }
    }

    public close(refocus = false) {
        if (this.expanded) {
            this.expanded = false;
            this.cd.markForCheck();
        }

        if (refocus) {
            this.valueInput?.nativeElement.focus();
        }
    }

    public toggle(event?) {
        if (this.readonly) {
            return;
        }

        if (this.expanded) {
            this.close();
            this.focus();
        } else {
            this.open();
            try {
                setTimeout(() => {
                    if (this.searchInput) {
                        this.searchInput.nativeElement.focus();
                    }
                });
            } catch (e) {}
        }
    }

    public focus() {
        this.valueInput?.nativeElement?.focus();
    }

    public select() {
        this.valueInput?.nativeElement?.select();
    }

    private getSelectedItem() {
        if (this.config.valueProperty && typeof this.value !== 'object') {
            let items = [];

            if (this.items?.length) {
                items = this.items;
            }

            if (this.itemGroups?.length) {
                items = this.itemGroups.reduce((acc, group) => [...acc, ...group.items], []);
            }

            return items.find((item) => get(item, this.config.valueProperty) === this.value);
        }

        return this.value;
    }
}
