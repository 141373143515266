import { Component, EventEmitter, Input, Output } from '@angular/core';
import { QueryItem, QueryBuilderField, QueryBuilderOperator, QueryBuilderFieldType } from './query-builder';

@Component({
    selector: 'query-item',
    templateUrl: './query-item.html',
    styleUrls: ['./query-item.sass'],
})
export class QueryBuilderItem {
    @Input() item: QueryItem;
    @Input() fields: QueryBuilderField[];
    @Input() operatorResolver: (fieldType: string) => QueryBuilderOperator[];
    @Input() hideLogicalOperator: boolean;
    @Input() siblingDepth: number = 0;
    @Input() siblingMaxDepth: number = 0;

    @Output() itemChange = new EventEmitter<QueryItem>();
    @Output() remove = new EventEmitter();

    lastFieldType: QueryBuilderFieldType;
    selectedField: QueryBuilderField;
    availableOperators: QueryBuilderOperator[];
    valueFieldDisabled: boolean;

    ngOnChanges() {
        this.getSelectedField();
        this.setAvailableOperators();
        this.setValueFieldDisabledState();
    }

    private getSelectedField() {
        if (this.item && this.fields) {
            this.selectedField = this.fields.find((f) => {
                const itemField = (this.item.field || '').toLowerCase();
                const field = (f.field || '').toLowerCase();
                return itemField === field;
            });
        }
    }

    private setAvailableOperators() {
        const fieldType = this.selectedField?.type || 'text';
        this.availableOperators = this.operatorResolver?.(fieldType) || [];
    }

    onFieldChange() {
        if (this.item.field === this.selectedField.field) return;

        this.setAvailableOperators();
        this.item.field = this.selectedField.field;

        const fieldType = this.selectedField.type;

        // Reset value if field type changed
        if (this.lastFieldType !== fieldType) {
            this.lastFieldType = fieldType;

            this.item.value = undefined;

            // Reset operator if current one is not available for new field type
            if (!this.availableOperators.some((op) => op.operator === this.item.operator)) {
                this.item.operator = this.availableOperators[0].operator;
            }
        }

        this.itemChange.emit(this.item);
    }

    onOperatorChange() {
        this.setValueFieldDisabledState();
        this.itemChange.emit(this.item);
    }

    private setValueFieldDisabledState() {
        if (this.availableOperators && this.item) {
            const operator = this.availableOperators.find((op) => op.operator === this.item.operator);
            this.valueFieldDisabled = operator?.disableValueField;
        }
    }

    addSibling(logicalOperator: 'and' | 'or') {
        if (!this.item.siblings) {
            this.item.siblings = [];
        }

        this.item.siblings.push({
            logicalOperator: logicalOperator,
            field: null,
            operator: null,
        });

        this.itemChange.emit(this.item);
    }

    removeSibling(index: number) {
        this.item.siblings.splice(index, 1);
        this.itemChange.emit(this.item);
    }
}
