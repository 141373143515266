<dropdown-menu [trigger]="triggerButton" [closeOnClick]="false" [closeOnTab]="false">
    <ng-template>
        <section class="dropdown">
            <section class="calendar-section rounded mb-2">
                <date-range-calendars
                    class="flex-1"
                    [showInputs]="true"
                    [fromDate]="fromDate"
                    [toDate]="toDate"
                    (fromDateChange)="emitChange($event, toDate)"
                    (toDateChange)="emitChange(fromDate, $event)"
                >
                </date-range-calendars>
            </section>

            <ng-container *ngIf="buttons?.length">
                <strong class="text-small">Hurtigvalg</strong>

                <section class="button-grid mt-1">
                    <button
                        class="secondary small text-small"
                        *ngFor="let button of buttons"
                        (click)="emitChange(button.fromDate, button.toDate)"
                    >
                        {{ button.label }}
                    </button>
                </section>
            </ng-container>

            <span *ngIf="selectionText" class="selection-text flex align-center gap-1 text-small mt-3">
                Valgt periode: <strong class="bg-info rounded">{{ selectionText }}</strong>
            </span>
        </section>
    </ng-template>
</dropdown-menu>
