import { ChangeDetectionStrategy, Component } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';

@Component({
    selector: 'column-menu-cell',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <button (click)="onClick()" class="icon-button col-menu-toggle" aria-label="Kolonnevelger">
            <i class="material-icons">settings</i>
        </button>
    `,
})
export class ColumnMenuCell implements IHeaderAngularComp {
    showColumnMenu: () => void;

    agInit(params) {
        this.showColumnMenu = params.showMenu;
    }

    refresh() {
        return false;
    }

    onClick() {
        this.showColumnMenu?.();
    }
}
