<label
    class="uni-label"
    [ngClass]="formConfig?.showLabelAbove ? 'label-above' : 'label-left'"
    [class.checkbox-input]="field?.FieldType === 5"
    [attr.data-testid]="field?.Property"
    *ngIf="field?.FieldType !== 1"
>
    <span
        [hidden]="!isInput(field?.FieldType)"
        [title]="field?.Tooltip?.Text ? '' : field?.Label"
        [style.width]="labelWidth"
        class="uni-label-text flex align-center gap-1"
    >
        {{ field?.Label | translate }}
        {{ field?.Required ? ' *' : '' }}

        <uni-icon
            *ngIf="field?.Tooltip"
            [icon]="!field.Tooltip.Type || field.Tooltip.Type === 'info' ? 'help_circle' : 'error'"
            [svgSize]="20"
            [ngClass]="field.Tooltip.Type"
            [uniTooltip]="field.Tooltip.Component || field.Tooltip.Text"
            tooltipMaxWidth="450px"
            class="tooltip-icon"
        >
        </uni-icon>
    </span>

    <ng-container [ngSwitch]="field?.FieldType">
        <uniform-autocomplete
            #selectedComponent
            *ngSwitchCase="0"
            [field]="field"
            [model]="model"
            [asideGuid]="asideGuid"
            (readyEvent)="onReadyHandler($event)"
            (changeEvent)="onChangeHandler($event)"
            (inputEvent)="onInputHandler($event)"
            (focusEvent)="onFocusHandler($event)"
        >
        </uniform-autocomplete>

        <uni-select-input
            #selectedComponent
            *ngSwitchCase="3"
            [field]="field"
            [model]="model"
            [asideGuid]="asideGuid"
            (readyEvent)="onReadyHandler($event)"
            (changeEvent)="onChangeHandler($event)"
            (inputEvent)="onInputHandler($event)"
            (focusEvent)="onFocusHandler($event)"
        >
        </uni-select-input>

        <uni-checkbox-input
            #selectedComponent
            *ngSwitchCase="5"
            [field]="field"
            [model]="model"
            class="inline-flex align-center"
            (readyEvent)="onReadyHandler($event)"
            (changeEvent)="onChangeHandler($event)"
        >
        </uni-checkbox-input>

        <uni-numeric-input
            #selectedComponent
            *ngSwitchCase="6"
            [field]="field"
            [model]="model"
            [asideGuid]="asideGuid"
            (readyEvent)="onReadyHandler($event)"
            (changeEvent)="onChangeHandler($event)"
            (inputEvent)="onInputHandler($event)"
            (focusEvent)="onFocusHandler($event)"
        >
        </uni-numeric-input>

        <uni-checkboxgroup-input
            #selectedComponent
            *ngSwitchCase="8"
            [field]="field"
            [model]="model"
            (readyEvent)="onReadyHandler($event)"
            (changeEvent)="onChangeHandler($event)"
        >
        </uni-checkboxgroup-input>

        <uni-radiogroup-input
            #selectedComponent
            *ngSwitchCase="9"
            [field]="field"
            [model]="model"
            [asideGuid]="asideGuid"
            (readyEvent)="onReadyHandler($event)"
            (changeEvent)="onChangeHandler($event)"
            (inputEvent)="onInputHandler($event)"
            (focusEvent)="onFocusHandler($event)"
        >
        </uni-radiogroup-input>

        <uni-text-input
            #selectedComponent
            *ngSwitchDefault
            [field]="field"
            [model]="model"
            [asideGuid]="asideGuid"
            (readyEvent)="onReadyHandler($event)"
            (changeEvent)="onChangeHandler($event)"
            (inputEvent)="onInputHandler($event)"
            (focusEvent)="onFocusHandler($event)"
        >
        </uni-text-input>

        <uni-email-input
            #selectedComponent
            *ngSwitchCase="11"
            [field]="field"
            [model]="model"
            [asideGuid]="asideGuid"
            (readyEvent)="onReadyHandler($event)"
            (changeEvent)="onChangeHandler($event)"
            (inputEvent)="onInputHandler($event)"
            (focusEvent)="onFocusHandler($event)"
        >
        </uni-email-input>

        <uni-password-input
            #selectedComponent
            *ngSwitchCase="12"
            [field]="field"
            [model]="model"
            [asideGuid]="asideGuid"
            (readyEvent)="onReadyHandler($event)"
            (changeEvent)="onChangeHandler($event)"
            (inputEvent)="onInputHandler($event)"
            (focusEvent)="onFocusHandler($event)"
        >
        </uni-password-input>

        <uni-hyperlink-input
            #selectedComponent
            *ngSwitchCase="13"
            [field]="field"
            [model]="model"
            [asideGuid]="asideGuid"
            (readyEvent)="onReadyHandler($event)"
            (changeEvent)="onChangeHandler($event)"
            (inputEvent)="onInputHandler($event)"
            (focusEvent)="onFocusHandler($event)"
        >
        </uni-hyperlink-input>

        <uni-multivalue-input
            #selectedComponent
            *ngSwitchCase="14"
            [field]="field"
            [model]="model"
            [asideGuid]="asideGuid"
            (readyEvent)="onReadyHandler($event)"
            (changeEvent)="onChangeHandler($event)"
            (inputEvent)="onInputHandler($event)"
            (focusEvent)="onFocusHandler($event)"
            (moveForwardEvent)="onMultivalueMoveForward($event)"
        >
        </uni-multivalue-input>

        <uni-url-input
            #selectedComponent
            *ngSwitchCase="15"
            [field]="field"
            [model]="model"
            [asideGuid]="asideGuid"
            (readyEvent)="onReadyHandler($event)"
            (changeEvent)="onChangeHandler($event)"
            (inputEvent)="onInputHandler($event)"
            (focusEvent)="onFocusHandler($event)"
        >
        </uni-url-input>

        <uni-textarea-input
            #selectedComponent
            *ngSwitchCase="16"
            [field]="field"
            [model]="model"
            [asideGuid]="asideGuid"
            (readyEvent)="onReadyHandler($event)"
            (changeEvent)="onChangeHandler($event)"
            (inputEvent)="onInputHandler($event)"
            (focusEvent)="onFocusHandler($event)"
        >
        </uni-textarea-input>

        <localdate-picker-input
            #selectedComponent
            *ngSwitchCase="17"
            [field]="field"
            [model]="model"
            [asideGuid]="asideGuid"
            (readyEvent)="onReadyHandler($event)"
            (changeEvent)="onChangeHandler($event)"
            (inputEvent)="onInputHandler($event)"
            (focusEvent)="onFocusHandler($event)"
        >
        </localdate-picker-input>

        <uni-multi-select-input
            #selectedComponent
            *ngSwitchCase="19"
            [field]="field"
            [model]="model"
            [asideGuid]="asideGuid"
            (readyEvent)="onReadyHandler($event)"
            (changeEvent)="onChangeHandler($event)"
            (inputEvent)="onInputHandler($event)"
            (focusEvent)="onFocusHandler($event)"
            (moveForwardEvent)="onMultivalueMoveForward($event)"
        >
        </uni-multi-select-input>

        <uni-static-text-input
            #selectedComponent
            *ngSwitchCase="20"
            [field]="field"
            [model]="model"
            [asideGuid]="asideGuid"
            (readyEvent)="onReadyHandler($event)"
            (changeEvent)="onChangeHandler($event)"
            (inputEvent)="onInputHandler($event)"
            (focusEvent)="onFocusHandler($event)"
        >
        </uni-static-text-input>

        <uni-typeahead-input
            #selectedComponent
            *ngSwitchCase="21"
            [field]="field"
            [model]="model"
            [asideGuid]="asideGuid"
            (readyEvent)="onReadyHandler($event)"
            (changeEvent)="onChangeHandler($event)"
            (inputEvent)="onInputHandler($event)"
            (focusEvent)="onFocusHandler($event)"
        >
        </uni-typeahead-input>

        <month-picker-input
            #selectedComponent
            *ngSwitchCase="22"
            [field]="field"
            [model]="model"
            [asideGuid]="asideGuid"
            (readyEvent)="onReadyHandler($event)"
            (changeEvent)="onChangeHandler($event)"
            (inputEvent)="onInputHandler($event)"
            (focusEvent)="onFocusHandler($event)"
        >
        </month-picker-input>

        <uni-bankaccount-input
            #selectedComponent
            *ngSwitchCase="23"
            [field]="field"
            [model]="model"
            [asideGuid]="asideGuid"
            (readyEvent)="onReadyHandler($event)"
            (focusEvent)="onFocusHandler($event)"
            (changeEvent)="onChangeHandler($event)"
        >
        </uni-bankaccount-input>
    </ng-container>
</label>

<section
    *ngIf="$any(field?.Required)?.icon"
    class="required"
    [ngClass]="formConfig?.showLabelAbove ? 'label-above' : 'label-left'"
>
    <i class="material-icons">{{ $any(field.Required).icon }}</i>
    <span>{{ $any(field.Required).text ? $any(field.Required).text : 'Påkrevd' }}</span>
</section>

<section *ngIf="errorMessages?.length" [style.margin-left]="labelWidth" class="validation-results">
    <small *ngFor="let error of errorMessages" [ngClass]="error.isWarning ? 'warn' : 'bad'">
        {{ error | uniformErrorTemplate }}
    </small>
</section>

<uni-button-input
    #selectedComponent
    *ngIf="field?.FieldType === 1"
    [style.margin-left]="labelWidth"
    [field]="field"
    [model]="model"
    [ngClass]="field?.Classes"
    [class.offset-left]="!formConfig?.showLabelAbove"
    [class.align-vertically]="formConfig?.showLabelAbove"
    [asideGuid]="asideGuid"
    (readyEvent)="onReadyHandler($event)"
    (changeEvent)="onChangeHandler($event)"
    (inputEvent)="onInputHandler($event)"
    (focusEvent)="onFocusHandler($event)"
>
</uni-button-input>
