import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AuthService } from '@app/authService';
import { ChatBoxService } from '@app/components/layout/chat-box/chat-box.service';
import { rigDate } from '@app/components/common/utils/rig-date';
import { CommentService } from '@app/services/common/commentService';
import { StatisticsService } from '@app/services/common/statisticsService';

@Injectable()
export class TableCommentCountService {
    counts$ = new BehaviorSubject<{}>({});
    entityType: string;
    commentAddedSubscription: Subscription;

    constructor(
        private authService: AuthService,
        private chatBoxService: ChatBoxService,
        private statisticsService: StatisticsService,
        private commentService: CommentService,
    ) {}

    init(entityType: string) {
        if (entityType && entityType !== this.entityType) {
            this.entityType = entityType;
            this.loadCounts(entityType);
            this.commentAddedSubscription = this.commentService.commentAdded$.subscribe((comment) => {
                if (comment.EntityType?.toLowerCase() === this.entityType?.toLowerCase()) {
                    this.loadCounts(entityType);
                }
            });
        }
    }

    cleanup() {
        this.commentAddedSubscription?.unsubscribe();
        this.counts$.next({});
        this.entityType = undefined;
    }

    openChat(ID: number) {
        this.chatBoxService.addBusinessObject({
            CompanyKey: this.authService.getCompanyKey(),
            EntityType: this.entityType,
            EntityID: ID,
        });
    }

    private loadCounts(entityType: string) {
        if (!entityType) {
            return;
        }

        const fromDate = rigDate().subtract(1, 'year').format('YYYY-MM-DD');

        const odata =
            `model=Comment&expand=Author` +
            `&select=ID as ID,EntityID as EntityID` +
            `&filter=EntityType eq '${entityType}' and Author.UserName ne 'SystemUser' and CreatedAt gt '${fromDate}'`;

        return this.statisticsService.GetAllUnwrapped(odata).subscribe(
            (comments) => {
                const countPerID = {};
                comments?.forEach((comment) => {
                    countPerID[comment.EntityID] = (countPerID[comment.EntityID] || 0) + 1;
                });

                this.counts$.next(countPerID);
            },
            (err) => {
                console.error(err);
                this.counts$.next({});
            },
        );
    }
}
