interface CacheEntry {
    data: any;
    timeout?: number;
}

export class UniCache {
    timeoutInMs: number;
    maxEntries: number;

    cache = new Map<string, CacheEntry>();

    constructor(timeoutInMinutes: number, maxNumberOfEntries: number = 50) {
        this.timeoutInMs = timeoutInMinutes * 60000;
        this.maxEntries = maxNumberOfEntries;
    }

    get(key: string) {
        const entry = this.cache.get(key);
        if (entry) {
            if (entry.timeout && entry.timeout < performance.now()) {
                this.cache.delete(key);
            } else {
                return entry.data;
            }
        }
    }

    add(key: string, data) {
        if (this.maxEntries && this.cache.size >= this.maxEntries) {
            try {
                const randomEntry = this.keys()[0];
                this.cache.delete(randomEntry);
            } catch (e) {}
        }

        this.cache.set(key, {
            data,
            timeout: this.timeoutInMs && performance.now() + this.timeoutInMs,
        });
    }

    remove(key: string) {
        this.cache.delete(key);
    }

    clear() {
        this.cache.clear();
    }

    keys() {
        return Array.from(this.cache.keys());
    }
}
