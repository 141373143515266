import { Injectable } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { AccountService } from '@app/services/accounting/accountService';
import { ErrorService } from '@app/services/common/errorService';
import { ConfirmActions, UniModalService } from '@uni-framework/uni-modal';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { catchError, debounceTime, map, switchMap, tap } from 'rxjs/operators';

@Injectable()
export class AccountSearchStore {
    open$ = new BehaviorSubject(false);
    loading$ = new BehaviorSubject(false);
    accounts$ = new BehaviorSubject([]);
    focusIndex$ = new BehaviorSubject(-1);

    accountHelp$ = new BehaviorSubject<{ header: string; keywords: string[]; description: string }>(undefined);

    searchControl: UntypedFormControl;
    controlSubscription: Subscription;

    showHiddenAccounts: boolean;

    constructor(
        private accountService: AccountService,
        private errorService: ErrorService,
        private modalService: UniModalService,
    ) {}

    init(searchControl: UntypedFormControl) {
        this.searchControl = searchControl;
        this.controlSubscription = searchControl.valueChanges
            .pipe(
                tap(() => {
                    this.loading$.next(true);
                    this.open$.next(true);
                    this.focusIndex$.next(-1);
                }),
                debounceTime(200),
            )
            .subscribe((value) => this.lookup(value, !!value));
    }

    // Using ngOnDestroy is fine here because the store is provided by the search components instead of in a module.
    // This means it'll be destroyed whenever the search component it removed from the DOM.
    ngOnDestroy() {
        this.controlSubscription?.unsubscribe();
        this.open$.complete();
        this.loading$.complete();
        this.accounts$.complete();
        this.focusIndex$.complete();
        this.accountHelp$.complete();
    }

    toggleOpen() {
        if (this.open$.value) {
            this.open$.next(false);
        } else {
            this.open$.next(true);
            this.loading$.next(true);
            this.lookup('');
        }
    }

    toggleShowHidden() {
        this.showHiddenAccounts = !this.showHiddenAccounts;
        this.loading$.next(true);
        this.lookup(this.searchControl.value);
    }

    setFocusIndex(index: number) {
        this.focusIndex$.next(index);

        // Update account help
        const accounts = this.accounts$.value || [];
        if (index >= 0 && accounts[index]) {
            const account = accounts[index];
            this.accountHelp$.next({
                header: `${account.AccountNumber} - ${account.AccountName}`,
                keywords: account.Keywords?.length > 0 ? account.Keywords?.split(',') : [],
                description: account.Description || '',
            });
        } else {
            this.accountHelp$.next(undefined);
        }
    }

    private lookup(searchText: string, focusFirst = true) {
        this.getAccounts(searchText || '').subscribe((accounts) => {
            this.accounts$.next(accounts || []);
            this.loading$.next(false);
            if (focusFirst && accounts?.length) {
                this.setFocusIndex(0);
            } else {
                this.setFocusIndex(-1);
            }
        });
    }

    getDisplayValue(account) {
        return account && `${account.AccountNumber} - ${account.AccountName}`;
    }

    onHiddenAccountSelected(account): Observable<any> {
        const message = `
            Du har valgt en konto som er skjult i kontoplanen.
            <br><br>
            Dette kan gjøre bokføringen vanskeligere å lete opp senere, da "Søk på konto" kun lar deg søke i synlige kontoer.
            <br><br>
            Ønsker du å endre synlighet på kontoen nå?
        `;

        return this.modalService
            .confirm({
                header: 'Skjult konto',
                message: message,
                size: 'xs',
                buttonLabels: {
                    reject: 'Nei takk',
                    accept: 'Ja, gjør kontoen synlig i kontoplan',
                },
            })
            .onClose.pipe(
                switchMap((res) => {
                    if (res === ConfirmActions.ACCEPT) {
                        return this.accountService.Put(account.ID, { ID: account.ID, Visible: true }).pipe(
                            map((account) => {
                                account.Visible = true;
                                return account;
                            }),
                        );
                    }

                    return of(undefined);
                }),
            );
    }

    private getAccounts(searchText: string) {
        return this.accountService.generalLedgerAccountSearch(searchText, this.showHiddenAccounts).pipe(
            map((accounts) => {
                // Show account numbers >= 4000 first
                const indexOf4000 = accounts.findIndex((acc) => acc.AccountNumber >= 4000);
                const indexOf400000 = accounts.findIndex((acc) => acc.AccountNumber >= 400000);
                if (indexOf4000 > 0 || indexOf400000 > 0) {
                    const fixedIndex = indexOf4000 > 0 ? indexOf4000 : indexOf400000;
                    accounts = [...accounts.slice(fixedIndex), ...accounts.slice(0, fixedIndex)];
                }

                // Add a boolean indicating the account is the first in it's group so we know when to display the group header in our template
                let previousGroupNumber;
                accounts.forEach((account) => {
                    account.GroupNumber ??= '-';
                    if (account.GroupNumber !== previousGroupNumber) {
                        account['_firstInGroup'] = true;
                        previousGroupNumber = account.GroupNumber;
                    }
                });

                return accounts;
            }),
            catchError((err) => {
                this.errorService.handle(err);
                return of([]);
            }),
        );
    }

    lookupAndSelectBestMatch(searchText: string) {
        return this.getAccounts(searchText).pipe(
            map((accounts) => {
                const accountNumberMatch = accounts.find((account) => {
                    return account.AccountNumber === parseInt(searchText, 10);
                });

                if (accountNumberMatch) {
                    return accountNumberMatch;
                } else {
                    let bestMatch;
                    let bestMatchCount = 0;
                    accounts.forEach((account) => {
                        const displayValue = this.getDisplayValue(account);
                        const matchCount = this.getNumberOfMatchingCharacters(displayValue, searchText);
                        if (matchCount > bestMatchCount) {
                            bestMatch = account;
                            bestMatchCount = matchCount;
                        }
                    });

                    return bestMatch || null;
                }
            }),
        );
    }

    private getNumberOfMatchingCharacters(s1: string, s2: string) {
        s1 = s1.toLowerCase();
        s2 = s2.toLowerCase();

        const startIndex = s1.indexOf(s2);
        if (startIndex >= 0) {
            s1 = s1.slice(startIndex);
            for (let i = 0; i < s2.length; i++) {
                if (s1[i] !== s2[i]) {
                    return i;
                }
            }

            return s2.length;
        } else {
            return 0;
        }
    }
}
