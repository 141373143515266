<section
    (clickOutside)="close()"
    class="uni-multivalue-ng"
    (keyup.esc)="close()"
    (keydown)="keyDownHandler($event)"
    [class.-has-values]="rows?.length"
>
    <section class="input-with-button" (click)="toggle()">
        <input
            #mainInput
            type="text"
            class="dropdown-search"
            [ngModel]="getDisplayValue(selectedRow)"
            [placeholder]="field?.Placeholder || ''"
            (focus)="focusHandler()"
            [title]="control?.value || ''"
            (keypress)="keyPressHandler($event)"
            [attr.aria-readonly]="readOnly$ | async"
            readonly
        />

        <button type="button" tabindex="-1" style="pointer-events: none" [disabled]="readOnly$ | async">
            <i class="material-icons">{{ rows?.length ? 'expand_more' : 'add' }}</i>
        </button>
    </section>

    <input-dropdown-menu [input]="mainInput" [visible]="isOpen">
        <ng-template>
            <section class="search-wrapper">
                <input
                    type="search"
                    [formControl]="filterFormControl"
                    placeholder="Søk"
                    [(ngModel)]="filter"
                    (keydown)="keyDownHandler($event)"
                    [attr.aria-expanded]="rows?.length > 1"
                    #filterElement
                />
            </section>

            <section
                class="dropdown-menu-item"
                [attr.aria-selected]="focusedRow === emptyRow"
                (click)="clearSelection()"
                *ngIf="rows.length"
            >
                Ikke valgt
            </section>

            <section
                class="dropdown-menu-item"
                *ngFor="let row of filteredRows"
                [attr.aria-selected]="focusedRow === row"
                (click)="selectRow(row)"
                [class.deleted]="row.Deleted"
            >
                <ng-container *ngIf="!row.Deleted">
                    <span>{{ getDisplayValue(row) || '' }}</span>
                    <i
                        class="material-icons"
                        (click)="$event.stopPropagation(); edit(row)"
                        *ngIf="!field?.Options?.hideEditAndDelete"
                        >edit</i
                    >
                    <i
                        class="material-icons"
                        (click)="$event.stopPropagation(); delete(row)"
                        *ngIf="field?.Options?.allowAddValue !== false && !field?.Options?.hideEditAndDelete"
                        >delete_outline</i
                    >
                </ng-container>

                <ng-container *ngIf="row.Deleted">
                    <span>SLETTET: {{ getDisplayValue(row) }} </span>
                    <i class="material-icons" (click)="$event.stopPropagation(); regretDelete(row)">undo</i>
                </ng-container>
            </section>

            <section
                class="dropdown-menu-item add-new"
                (click)="addNew(filter)"
                *ngIf="field?.Options?.allowAddValue !== false"
            >
                <i class="material-icons">add</i>
                Opprett {{ filter ? '"' + filter + '"' : 'ny' }}
            </section>
        </ng-template>
    </input-dropdown-menu>
</section>
