import {
    Component,
    Input,
    Output,
    EventEmitter,
    ChangeDetectorRef,
    ViewEncapsulation,
    AfterViewInit,
    ChangeDetectionStrategy,
} from '@angular/core';
import { IModalOptions, IUniModal } from '@uni-framework/uni-modal/interfaces';
import { ReportFormat } from '@app/models/reportFormat';
import { ComboButtonAction } from '@uni-framework/ui/combo-button/combo-button';
import { Report } from '@app/models/reports/report';
import { ErrorService } from '@app/services/common/errorService';
import { ReportService } from '@app/services/reports/reportService';

@Component({
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'uni-preview-modal',
    template: `
        <section class="uni-modal">
            <header>{{ options.header || 'Forhåndsvisning' }}</header>

            <article>
                <section *ngIf="busy" class="report-loading">
                    <small>Laster data og genererer rapport..</small>
                    <mat-progress-bar class="mt-2" mode="determinate" [value]="reportService.progress$ | async">
                    </mat-progress-bar>
                </section>

                <!--
                    Don't remove this input!

                    The report markup generated by Stimulsoft has an <input type="password">
                    used for password protecting files.

                    Our super helpful browser will try to autofill with email/password, and since the
                    report markup only contains a password input, the browser finds the closest
                    input and adds your email there...

                    This input takes that bullet, and avoids cases where an input we actually use
                    is autofilled with your email address.
                -->
                <input tabindex="-1" style="position: absolute; width: 0; height: 0; opacity: 0;" />

                <section id="reportContainer"></section>
            </article>
            <footer *ngIf="!busy">
                <combo-button [actions]="actions"></combo-button>
            </footer>
        </section>
    `,
})
export class UniPreviewModal implements IUniModal, AfterViewInit {
    @Input() options: IModalOptions = {};
    @Output() onClose = new EventEmitter<boolean>();

    actions: ComboButtonAction[];
    modalConfig: any;
    busy: boolean = true;

    constructor(
        public reportService: ReportService,
        private cdr: ChangeDetectorRef,
        private errorService: ErrorService,
    ) {
        this.actions = [
            { label: 'Last ned PDF', action: () => this.download(ReportFormat.PDF) },
            { label: 'Last ned CSV', action: () => this.download(ReportFormat.CSV) },
            { label: 'Last ned HTML', action: () => this.download(ReportFormat.HTML) },
            { label: 'Last ned Excel', action: () => this.download(ReportFormat.Excel) },
            { label: 'Last ned Word', action: () => this.download(ReportFormat.Word) },
        ];
    }

    ngAfterViewInit() {
        if (this.options.data) {
            const reportDefinition: Report = this.options.data;
            this.reportService.reportLayoutName = reportDefinition['layout'];

            this.modalConfig = {
                title: reportDefinition.Name,
                report: null,
                reportDefinition: reportDefinition,
            };

            this.reportService.startReportProcess(reportDefinition, this.modalConfig, this.onClose).subscribe(
                () => {
                    this.busy = false;
                    this.cdr.markForCheck();
                },
                (err) => {
                    this.errorService.handle(err);
                    this.close();
                },
            );
        }
    }

    download(format) {
        this.reportService.generateReportFormat(format, this.modalConfig.reportDefinition);
    }

    close() {
        this.onClose.emit();
    }
}
