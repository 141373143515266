import { refCount, publishReplay, map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { UniHttp } from '../../../framework/core/http/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import {
    ElsaCompanyLicense,
    ElsaContract,
    ElsaUserLicense,
    ElsaContractType,
    ElsaCategory,
    BillingData,
    ElsaSupportUserDTO,
} from '@app/models';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { theme, THEMES } from 'src/themes/theme';
import { AuthService } from '@app/authService';

@Injectable({ providedIn: 'root' })
export class ElsaContractService {
    private cache: { [endpoint: string]: Observable<HttpResponse<any>> } = {};
    private customerType: number;
    ELSA_SERVER_URL = environment.ELSA_SERVER_URL;

    selectedContractID$ = new BehaviorSubject<number>(null);

    constructor(
        private authService: AuthService,
        private uniHttp: UniHttp,
        private http: HttpClient,
    ) {
        this.authService.authentication$.subscribe((auth) => {
            if (auth?.user) {
                this.customerType = auth.user.License?.CustomerInfo?.CustomerType;
            }
            this.cache = {};
        });
    }

    private requestData(endpoint: string) {
        let request = this.cache[endpoint];
        if (!request) {
            request = this.uniHttp
                .asGET()
                .usingEmptyDomain()
                .withEndPoint(endpoint)
                .send()
                .pipe(publishReplay(1), refCount());

            this.cache[endpoint] = request;
        }

        return request.pipe(map((res) => res.body));
    }

    selectContractID(contractID: number) {
        if (contractID) {
            this.selectedContractID$.next(contractID);
        }
    }

    get(id: number, select?: string, expand?: string): Observable<ElsaContract> {
        const selectClause = select ? `$select=${select}&` : '';
        const expandClause = expand ? `$expand=${expand}&` : '';
        return this.http
            .get<
                ElsaContract[]
            >(this.ELSA_SERVER_URL + `/api/contracts?${selectClause}${expandClause}$filter=id eq ${id}`)
            .pipe(map((res) => res[0]));
    }

    getAll(ignoreLicenseAdmin = false): Observable<ElsaContract[]> {
        const ignore = ignoreLicenseAdmin ? 'ignoreLicenseAdmin=true&' : '';
        return this.uniHttp
            .asGET()
            .usingElsaDomain()
            .withEndPoint('/api/contracts?' + ignore + '$expand=AgreementAcceptances,Customer')
            .send()
            .pipe(map((req) => req.body));
    }

    getContractTypes(): Observable<ElsaContractType[]> {
        return this.requestData('/api/elsa/contract-types').pipe(
            catchError((err) => {
                console.error(err);
                return of([]);
            }),
        );
    }

    getCurrentContractType(contracttype: string): Observable<ElsaContractType> {
        return this.http
            .get<
                ElsaContractType[]
            >(this.ELSA_SERVER_URL + `/api/contracttypes?$filter=contracttype eq '${contracttype}'`)
            .pipe(
                catchError((err) => {
                    console.error(err);
                    return of([]);
                }),
                map((types: ElsaContractType[]) => types[0]),
            );
    }

    getCustomContractTypes(): Observable<ElsaContractType[]> {
        return this.uniHttp
            .asGET()
            .usingElsaDomain()
            .withEndPoint(
                `/api/contracttypes?$expand=bulletpoints,productcontracttypes($expand=product;$filter=product/producttype eq 'Package')`,
            )
            .send()
            .pipe(
                map((res) =>
                    this.filterContractTypesByCustomerType(res.body || []).filter(
                        (type) => type.ContractType > 0 && type.IsActive && type.IsPublic,
                    ),
                ),
            );
    }

    getOnboardingContractTypes(): Observable<ElsaContractType[]> {
        return this.uniHttp
            .asGET()
            .usingElsaDomain()
            .withEndPoint(`/api/contracttypes/onboarding`)
            .send()
            .pipe(
                map((res) => this.filterContractTypesByCustomerType(res.body || [], 0)), // 0 = standard
            );
    }

    getContractTypesLabel(contracttype: string): Observable<string> {
        return this.http
            .get<
                ElsaContractType[]
            >(this.ELSA_SERVER_URL + `/api/contracttypes?$filter=contracttype eq '${contracttype}'&$select=label`)
            .pipe(
                catchError((err) => {
                    console.error(err);
                    return of([]);
                }),
                map((types: ElsaContractType[]) => types && types[0]?.Label),
            );
    }

    // used for comparing contract-types
    getContractTypesCategories(): Observable<ElsaCategory[]> {
        return this.http.get<ElsaCategory[]>(this.ELSA_SERVER_URL + '/api/categories');
    }

    getValidContractTypeUpgrades(): Observable<any[]> {
        const url = `/api/elsa/contracts/${this.authService.contractID}/check-upgrade`;
        return this.http.get<any[]>(url).pipe(
            catchError((err) => {
                console.error(err);
                return of([]);
            }),
            map((res) => res || []),
        );
    }

    changeContractType(contractType: number) {
        const url = `/api/elsa/contracts/${this.authService.contractID}/upgrade?contractType=${contractType}`;
        return this.http.put(url, null);
    }

    createContract(contract: ElsaContract) {
        return this.http.post(`${this.ELSA_SERVER_URL}/api/contracts`, contract);
    }

    updateContract(contractID: number, body) {
        return this.http.put(`${this.ELSA_SERVER_URL}/api/contracts/${contractID}`, body);
    }

    getActiveCompanyLicensesCount(contractID: number): Observable<number> {
        const statusFilter = `statuscode ne 'SoftDeleted' and statuscode ne 'HardDeleted' and statuscode ne 'HardDeletedNoBackup'`;
        const odata = `$filter=contractid eq ${contractID} and ${statusFilter}&$select=id`;
        return this.http
            .get<ElsaCompanyLicense[]>(this.ELSA_SERVER_URL + '/api/companylicenses?' + odata)
            .pipe(map((companies) => companies?.length || 0));
    }

    getCompanyLicenses(contractID: number): Observable<ElsaCompanyLicense[]> {
        return this.uniHttp
            .asGET()
            .usingEmptyDomain()
            .withEndPoint(`/api/elsa/contracts/${contractID}/companylicenses?isDeleted=false`)
            .send()
            .pipe(map((res) => res.body));
    }

    getDeletedCompanyLicenses(contractID: number): Observable<ElsaCompanyLicense[]> {
        return this.uniHttp
            .asGET()
            .usingEmptyDomain()
            .withEndPoint(`/api/elsa/contracts/${contractID}/companylicenses?isDeleted=true`)
            .send()
            .pipe(map((res) => res.body));
    }

    deactivateUserLicenseOnContract(contractID: number, userIdentity: string) {
        // specify json content type, otherwise the put thinks it's text/plain
        const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
        return this.http.put(`/api/elsa/contracts/${contractID}/deactivate-user`, `\"${userIdentity}\"`, {
            headers: headers,
        });
    }

    getUserLicenses(contractID: number): Observable<ElsaUserLicense[]> {
        return this.uniHttp
            .asGET()
            .usingElsaDomain()
            .withEndPoint(`/api/contracts/${contractID}/userlicense-summary`)
            .send()
            .pipe(
                map((res) => {
                    const users = res.body || [];
                    return users.filter((user) => user.UserName !== 'System User');
                }),
            );
    }

    getUserLicense(contractID: number, userIdentity: string): Observable<ElsaUserLicense> {
        return this.uniHttp
            .asGET()
            .usingElsaDomain()
            .withEndPoint(`/api/contracts/${contractID}/userlicense-summary?$filter=useridentity eq ${userIdentity}`)
            .send()
            .pipe(map((res) => res.body && res.body[0]));
    }

    activate(contractID: number, body, contractType?: number, setPendingStatus = theme.theme === THEMES.SR) {
        let endpoint = `/api/elsa/contracts/${contractID}/activate`;

        const queryParams = [];
        if (contractType) {
            queryParams.push(`contractType=${contractType}`);
        }

        if (setPendingStatus) {
            queryParams.push(`companyStatusCode=3`);
        }

        if (queryParams.length) {
            endpoint += `?${queryParams.join('&')}`;
        }

        return this.uniHttp.asPUT().usingEmptyDomain().withEndPoint(endpoint).withBody(body).send();
    }

    getSupportUsers(): Observable<ElsaSupportUserDTO[]> {
        return this.uniHttp
            .asGET()
            .usingEmptyDomain()
            .withEndPoint('/api/elsa/support-users')
            .send()
            .pipe(
                map((res) => res.body),
                catchError(() => of([])),
            );
    }

    updateTwoFactorAuthentication(contractID: number, body): Observable<ElsaContract> {
        return this.http.put(this.ELSA_SERVER_URL + `/api/contracts/${contractID}`, body).pipe(map((res) => res[0]));
    }

    getBillingEstimate(contractID: number, year: number, month: number): Observable<BillingData> {
        const endpoint = `/api/billing/contract/${contractID}` + `?year=${year}` + `&month=${month}` + `&tags=true`;
        return this.http.get<BillingData>(this.ELSA_SERVER_URL + endpoint);
    }

    getBillingHistory(contractID: number): Observable<BillingData[]> {
        return this.http.get<BillingData[]>(this.ELSA_SERVER_URL + `/api/billing/contract/${contractID}/history/data`);
    }

    downloadBillingHistory(billingHistoryID: number): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set('Accept', 'application/pdf');
        const endpoint = `/api/billing/history/${billingHistoryID}?pdf=true`;
        return this.http.get(this.ELSA_SERVER_URL + endpoint, { headers: headers, responseType: 'blob' });
    }

    sendBillingHistoryToInbox(billingHistoryID: number): Observable<any> {
        const endpoint = `/api/billing/history/${billingHistoryID}/send-ehf`;
        return this.http.post(this.ELSA_SERVER_URL + endpoint, null);
    }

    filterContractTypesByCustomerType(contractTypes: ElsaContractType[], customerType?: number): ElsaContractType[] {
        // customerType could be the number 0, which is falsey
        if (customerType === null || customerType === undefined) {
            customerType = this.customerType;
        }
        return contractTypes.filter((c) => c.CustomerType === null || c.CustomerType === customerType) || [];
    }
}
