import { Injectable } from '@angular/core';
import { BizHttp } from '../../../framework/core/http/BizHttp';
import { PostalCode } from '../../unientities';
import { UniHttp } from '../../../framework/core/http/http';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PostalCodeService extends BizHttp<PostalCode> {
    constructor(http: UniHttp) {
        super(http);

        this.relativeURL = PostalCode.RelativeUrl;
        this.entityType = PostalCode.EntityType;
        this.DefaultOrderBy = null;
    }

    getByCode(code: number | string) {
        return this.GetAll(`top=1&filter=Code eq ${code}`).pipe(map((res) => res && res[0]));
    }
}
