import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'externalProvider',
})
export class ExternalProviderPipe implements PipeTransform {
    transform(provider: string): any {
        switch (provider?.toLowerCase()) {
            case 'bankid':
                return 'BankID';
            case 'sb1bankid':
                return 'SB1 BankID';
            case 'bankiddnbsso':
                return 'DNB BankID';
            case 'microsoft':
                return 'Microsoft';
            case 'ue':
                return 'Unimicro';
        }
        return provider;
    }
}
