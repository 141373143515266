<section role="dialog" class="uni-modal">
    <header>Trenger dere innskuddspensjon og yrkesskadeforsikring?</header>

    <article>
        <section *ngIf="busy" class="modal-spinner">
            <mat-spinner class="c2a"></mat-spinner>
        </section>

        <p class="top-info">
            Bedrifter er lovpålagt å ha innskuddspensjon og yrkesskadeforsikring til sine ansatte. Vi har samlet det som
            er lovpålagt i en pakke.
        </p>

        <ul class="selling-points-list">
            <li>
                <i class="material-icons"> check </i>
                <span> Alt i en pakke </span>
            </li>

            <li>
                <i class="material-icons"> check </i>
                <span> Full oversikt i nettbanken </span>
            </li>

            <li>
                <i class="material-icons"> check </i>
                <span> Gjør pensjon og forsikring enkelt </span>
            </li>

            <li>
                <i class="material-icons"> check </i>
                <span> Få inntil 50% av sparingen i skattefradrag </span>
            </li>
        </ul>

        <rig-checkbox [(ngModel)]="dontAskAgain"> Ikke spør igjen </rig-checkbox>
    </article>

    <footer>
        <button class="tertiary" (click)="onClose.emit(dontAskAgain)">
            <i class="material-icons">close</i> Nei takk
        </button>
        <button class="c2a" (click)="sendOffer()">Send meg et tilbud</button>
    </footer>
</section>
