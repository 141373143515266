/* eslint-disable */
export const SHARED_ICONS = {
    download: `<svg viewBox="0 0 16 16" fill="currentColor"><path fill-rule="evenodd" clip-rule="evenodd" d="M7.25 10.1893L3.53033 6.46967C3.23744 6.17678 2.76256 6.17678 2.46967 6.46967C2.17678 6.76256 2.17678 7.23744 2.46967 7.53033L7.46967 12.5303C7.76256 12.8232 8.23744 12.8232 8.53033 12.5303L13.5303 7.53033C13.8232 7.23744 13.8232 6.76256 13.5303 6.46967C13.2374 6.17678 12.7626 6.17678 12.4697 6.46967L8.75 10.1893L8.75 1C8.75 0.585787 8.41421 0.25 8 0.25C7.58579 0.25 7.25 0.585787 7.25 1L7.25 10.1893ZM1.75 15.25C1.75 14.8358 2.08579 14.5 2.5 14.5L13.5 14.5C13.9142 14.5 14.25 14.8358 14.25 15.25C14.25 15.6642 13.9142 16 13.5 16L2.5 16C2.08579 16 1.75 15.6642 1.75 15.25Z" /></svg>`,
    upload: `<svg viewBox="0 0 16 16" fill="currentColor"><path fill-rule="evenodd" clip-rule="evenodd" d="M8.53033 0.46967C8.23744 0.176777 7.76256 0.176777 7.46967 0.46967L2.46967 5.46967C2.17678 5.76256 2.17678 6.23744 2.46967 6.53033C2.76256 6.82322 3.23744 6.82322 3.53033 6.53033L7.25 2.81066L7.25 12C7.25 12.4142 7.58578 12.75 8 12.75C8.41421 12.75 8.75 12.4142 8.75 12L8.75 2.81066L12.4697 6.53033C12.7626 6.82322 13.2374 6.82322 13.5303 6.53033C13.8232 6.23744 13.8232 5.76256 13.5303 5.46967L8.53033 0.46967ZM1.75 15.25C1.75 14.8358 2.08579 14.5 2.5 14.5L13.5 14.5C13.9142 14.5 14.25 14.8358 14.25 15.25C14.25 15.6642 13.9142 16 13.5 16L2.5 16C2.08579 16 1.75 15.6642 1.75 15.25Z" /></svg>`,
    bell_filled:
        '<svg viewBox="0 0 20 20"><path d="M10 1.667c4.142 0 7.5 3.369 7.5 7.525v7.474h-15V9.192c0-4.156 3.358-7.526 7.5-7.526ZM7.917 17.5h4.166a2.083 2.083 0 1 1-4.166 0Z" fill="currentColor"/></svg>',
    bell: '<svg viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15 6.667a5 5 0 00-10 0c0 5.833-2.5 7.5-2.5 7.5h15S15 12.5 15 6.667zM11.442 17.5a1.666 1.666 0 01-2.884 0" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>',
    email_sending_failed:
        '<svg viewBox="0 0 20 21" fill="none"><path d="M18.333 11.667h-1.666V6.032l-6.607 5.916-6.727-5.935v9.82H12.5V17.5h-10a.833.833 0 01-.833-.833V3.333A.833.833 0 012.5 2.5h15a.833.833 0 01.833.833v8.334zM3.76 4.167l6.292 5.551 6.2-5.551H3.76zm14.086 11.666l1.768 1.768-1.179 1.179-1.767-1.768-1.768 1.768-1.179-1.18 1.768-1.767-1.768-1.767 1.18-1.18 1.767 1.769 1.767-1.768 1.18 1.179-1.769 1.767z" fill="currentColor"/></svg>',
    email_sent:
        '<svg fill="none" viewBox="0 0 14 12"><path d="M13.75 11.004a.625.625 0 0 1-.62.621H1.87a.62.62 0 0 1-.62-.62v-.63H12.5V3.062l-5 4.5-6.25-5.625V1a.625.625 0 0 1 .625-.625h11.25A.625.625 0 0 1 13.75 1v10.004ZM2.771 1.625 7.5 5.881l4.729-4.256H2.77ZM0 7.875h5v1.25H0v-1.25ZM0 4.75h3.125V6H0V4.75Z" fill="currentColor"/></svg>',
    send: '<svg viewBox="0 0 20 21" fill="none"><path d="M18.333 1.667l-9.166 9.167M18.333 1.667L12.5 18.334l-3.333-7.5-7.5-3.334 16.666-5.833z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>',
    check: '<svg fill="none" viewBox="0 0 14 14"><path d="M7 14A7 7 0 1 1 7 0a7 7 0 0 1 0 14Zm-.698-4.2 4.95-4.95-.99-.99-3.96 3.96-1.98-1.98-.99.99 2.97 2.97Z" fill="currentColor"/></svg>',
    checkmark:
        '<svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m6.667 10.115 6.128-6.129.943.943L6.667 12 2.424 7.757l.943-.942 3.3 3.3Z" fill="currentColor"/></svg>',
    info: '<svg viewBox="0 0 14 14" fill="none"><path d="M7 14A7 7 0 1 1 7 0a7 7 0 0 1 0 14Zm-.7-7.7v4.2h1.4V6.3H6.3Zm0-2.8v1.4h1.4V3.5H6.3Z" fill="currentColor"/></svg>',
    error: '<svg fill="none" viewBox="0 0 14 15"><path d="M7 .168a7 7 0 1 1 0 14 7 7 0 0 1 0-14Zm.7 7.7v-4.2H6.3v4.2h1.4Zm0 2.8v-1.4H6.3v1.4h1.4Z" fill="currentColor"/></svg>',
    processing:
        ' <svg viewBox="0 0 14 14" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M7 14A7 7 0 1 0 7 0a7 7 0 0 0 0 14ZM4.172 9.464l.18-.085a3.493 3.493 0 0 0 1.713 1.071c.82.225 1.7.15 2.478-.213a3.612 3.612 0 0 0 1.756-1.761c.29-.632.386-1.327.28-2l.181-.084c.125.726.027 1.48-.287 2.161a3.806 3.806 0 0 1-1.85 1.855 3.806 3.806 0 0 1-2.61.226 3.682 3.682 0 0 1-1.84-1.17Zm6.588-3.072 1.178-.55a4.913 4.913 0 0 1-.282 3.142 5.084 5.084 0 0 1-2.506 2.554 5.084 5.084 0 0 1-3.568.279 4.912 4.912 0 0 1-2.588-1.804l1.178-.55a3.681 3.681 0 0 0 1.84 1.171 3.804 3.804 0 0 0 2.611-.225 3.805 3.805 0 0 0 1.85-1.855 3.682 3.682 0 0 0 .287-2.162ZM2.37 8.21l1.182-.551a3.494 3.494 0 0 1 .28-2 3.612 3.612 0 0 1 1.756-1.762 3.612 3.612 0 0 1 2.478-.213c.67.183 1.265.557 1.713 1.071l1.18-.55a4.724 4.724 0 0 0-2.466-1.704 4.89 4.89 0 0 0-3.432.268A4.89 4.89 0 0 0 2.65 5.227a4.724 4.724 0 0 0-.28 2.984ZM8.55 2.319a4.913 4.913 0 0 1 2.588 1.804l-.178.082a4.724 4.724 0 0 0-2.466-1.704 4.89 4.89 0 0 0-3.432.268A4.89 4.89 0 0 0 2.65 5.226a4.724 4.724 0 0 0-.28 2.985l-.177.082a4.913 4.913 0 0 1 .281-3.142 5.084 5.084 0 0 1 2.507-2.554 5.084 5.084 0 0 1 3.568-.278Z" fill="currentColor"/></svg>',
    deactivated:
        '<svg viewBox="0 0 14 14" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M7 14A7 7 0 1 0 7 0a7 7 0 0 0 0 14ZM3.753 7.735h6.47V6.31h-6.47v1.425Z" fill="currentColor"/></svg>',
    message:
        '<svg fill="none" viewBox="0 0 24 24"><path d="M6.455 19 2 22.5V4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H6.455Zm-.692-2H20V5H4v13.385L5.763 17ZM8 10h8v2H8v-2Z" fill="currentColor"/></svg>',
    delete: '<svg viewBox="0 0 21 21" fill="none"><path d="M14.382 5.407h4.166v1.667h-1.666v10.833a.833.833 0 0 1-.834.833H4.382a.833.833 0 0 1-.834-.833V7.074H1.882V5.407h4.166v-2.5a.833.833 0 0 1 .834-.833h6.666a.833.833 0 0 1 .834.833v2.5Zm.833 1.667h-10v10h10v-10Zm-7.5-3.334v1.667h5V3.74h-5Z" fill="currentColor"/></svg>',
    edit: '<svg viewBox="0 0 21 21" fill="none"><path d="m13.322 8.479-1.179-1.178-7.761 7.761v1.179H5.56l7.762-7.762ZM14.5 7.301l1.178-1.179L14.5 4.944l-1.178 1.178L14.5 7.301ZM6.25 17.907H2.715v-3.536L13.911 3.176a.833.833 0 0 1 1.178 0l2.358 2.357a.833.833 0 0 1 0 1.178L6.25 17.907Z" fill="currentColor"/></svg>',
    close: '<svg fill="none" viewBox="0 0 16 16"><path d="m8 7.575 3.094-3.094.883.884-3.093 3.093 3.093 3.094-.883.884L8 9.342l-3.094 3.094-.884-.884 3.094-3.094-3.094-3.093.884-.884L8 7.575Z" fill="currentColor"/></svg>',
    user: '<svg viewBox="0 0 24 24" fill="currentColor"><path d="M4 22a8 8 0 0 1 16 0h-2a6 6 0 1 0-12 0H4Zm8-9c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6Zm0-2c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4Z"/></svg>',
    incoming_mail:
        '<svg viewBox="0 0 20 20"><path d="M1.667 4.583V3.327a.833.833 0 0 1 .826-.827h15.014c.456 0 .826.37.826.828v13.345a.833.833 0 0 1-.826.827H2.493a.827.827 0 0 1-.826-.828v-.839h15v-9.75l-6.667 6-8.333-7.5ZM0 8.333h4.167V10H0V8.333ZM0 12.5h6.667v1.667H0V12.5Z" fill="currentColor"/></svg>',
    thumbs_up:
        '<svg viewBox="0 0 20 20"><path d="M1.666 7.5h2.5v10h-2.5a.833.833 0 0 1-.833-.833V8.333a.833.833 0 0 1 .833-.833Zm4.411-1.077 5.334-5.334a.417.417 0 0 1 .545-.039l.71.533a1.25 1.25 0 0 1 .461 1.309l-.96 3.775H17.5a1.667 1.667 0 0 1 1.666 1.666v1.754c0 .218-.042.433-.125.635l-2.579 6.262a.833.833 0 0 1-.77.516H6.665a.833.833 0 0 1-.833-.833V7.012c0-.221.088-.433.244-.59Z" fill="currentColor"/></svg>',
    thumbs_down:
        '<svg viewBox="0 0 24 24"><path d="M9.4 16H3a2 2 0 0 1-2-2v-2.104a2 2 0 0 1 .15-.762L4.246 3.62A1 1 0 0 1 5.17 3H22a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1h-3.482a1 1 0 0 0-.817.423l-5.453 7.726a.5.5 0 0 1-.632.159L9.802 22.4a2.5 2.5 0 0 1-1.305-2.853L9.4 16Zm7.6-2.588V5H5.84L3 11.896V14h6.4a2 2 0 0 1 1.938 2.493l-.903 3.548a.5.5 0 0 0 .261.571l.661.33 4.71-6.672c.25-.354.57-.644.933-.858ZM19 13h2V5h-2v8Z" fill="currentColor"/></svg>',
    comment:
        '<svg viewBox="0 0 20 20"><path d="M5.38 15.833 1.666 18.75V3.333A.833.833 0 0 1 2.5 2.5h15a.833.833 0 0 1 .834.833V15a.833.833 0 0 1-.834.833H5.38Zm.454-7.5V10H7.5V8.333H5.834Zm3.333 0V10h1.667V8.333H9.167Zm3.333 0V10h1.667V8.333H12.5Z" fill="currentColor"/></svg>',
    bot: '<svg viewBox="0 0 24 24"><path d="M19.638 12.749c.863 11.595-15.47 11.59-14.605 0a.494.494 0 0 1 .495-.495h13.615a.495.495 0 0 1 .495.495ZM17.66 3.584v5.75a1.57 1.57 0 0 1-1.566 1.567H8.579a1.57 1.57 0 0 1-1.567-1.568V3.584A1.585 1.585 0 0 1 8.597 2h7.478a1.586 1.586 0 0 1 1.584 1.584Zm-5.94 2.787a1.421 1.421 0 0 0-2.842 0 1.42 1.42 0 0 0 2.842 0Zm4.076 0a1.421 1.421 0 0 0-2.842 0 1.421 1.421 0 0 0 2.842 0Zm-1.421-.43a.431.431 0 0 0 0 .862.431.431 0 0 0 0-.863Zm-4.076 0a.431.431 0 0 0 0 .862.431.431 0 0 0 0-.863Z" fill="currentColor"/></svg>',
    warn_circle:
        '<svg viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0z"/><path fill="currentColor" d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-5h2v2h-2v-2zm0-8h2v6h-2V7z"/></svg>',
    info_circle:
        '<svg viewBox="0 0 24 24"><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10Zm0-2a8 8 0 1 0 0-16.001A8 8 0 0 0 12 20ZM11 7h2v2h-2V7Zm0 4h2v6h-2v-6Z" fill="currentColor"/></svg>',
    help_circle:
        '<svg viewBox="0 0 24 24" fill="currentColor"><path d="M12 22a10 10 0 1 1 0-20 10 10 0 0 1 0 20Zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16Zm-1-5h2v2h-2v-2Zm2-1.6v.6h-2v-1.5c0-.6.4-1 1-1a1.5 1.5 0 1 0-1.5-1.8l-2-.4a3.5 3.5 0 1 1 4.5 4Z"/></svg>',
    plant: '<svg viewBox="0 0 24 24" fill="none"><path d="M6 2C8.69 2 11.024 3.517 12.197 5.741C13.374 4.083 15.31 3 17.5 3H21V5.5C21 9.09 18.09 12 14.5 12H13V13H18V20C18 21.105 17.105 22 16 22H8C6.895 22 6 21.105 6 20V13H11V11H9C5.134 11 2 7.866 2 4V2H6ZM16 15H8V20H16V15ZM19 5H17.5C15.015 5 13 7.015 13 9.5V10H14.5C16.985 10 19 7.985 19 5.5V5ZM6 4H4C4 6.761 6.239 9 9 9H11C11 6.239 8.761 4 6 4Z" fill="currentColor"/></svg>',
    plant_filled:
        '<svg viewBox="0 0 24 24" fill="none"><g clip-path="url(#clip0_16_1563)"><path d="M21 3V5C21 8.866 17.866 12 14 12H13V13H18V20C18 21.105 17.105 22 16 22H8C6.895 22 6 21.105 6 20V13H11V10C11 6.134 14.134 3 18 3H21ZM5.5 2C8.029 2 10.265 3.251 11.624 5.169C10.604 6.51 10 8.185 10 10V11H9.5C5.358 11 2 7.642 2 3.5V2H5.5Z" fill="currentColor"/></g><defs><clipPath id="clip0_16_1563"><rect width="24" height="24" fill="white"/></clipPath></defs></svg>',
};
