<section class="month-picker-header flex align-center rounded mb-3">
    <strong>{{ label }}</strong>

    <button #trigger class="unstyled year-select-toggle pull-right">
        {{ selectedYear }}
        <i class="material-icons">expand_more</i>
    </button>

    <dropdown-menu [trigger]="trigger" minWidth="8rem" maxHeight="15rem">
        <ng-template>
            <div
                *ngFor="let year of yearOptions"
                (click)="setYear(year)"
                [class.selected]="year === selectedYear"
                class="dropdown-menu-item"
            >
                {{ year }}
            </div>
        </ng-template>
    </dropdown-menu>
</section>

<section #grid class="month-grid" (keydown)="onGridKeyDown($event)">
    <div class="month-grid-row" *ngFor="let row of monthGrid">
        <div
            role="button"
            class="month flex justify-center align-center rounded pointer"
            *ngFor="let month of row"
            [class.selected]="month.selected"
            (click)="selectMonth(month.number)"
            [tabindex]="month.selected ? 0 : -1"
        >
            {{ month.name }}
        </div>
    </div>
</section>
