import { Component, EventEmitter } from '@angular/core';
import { IModalOptions } from '@uni-framework/uni-modal/interfaces';
import { AuthService } from '@app/authService';
import { BrunoOnboardingService } from '@app/services/bank/BrunoOnboardingService';

enum ApprovalLocation {
    InApp = 1,
    InBank = 2,
}

@Component({
    selector: 'bruno-bankid-onboarding-modal',
    templateUrl: './bruno-bankid-onboarding-modal.html',
    styleUrls: ['./bruno-bankid-onboarding-modal.sass'],
})
export class BrunoBankIdOnboardingModal {
    options: IModalOptions = {};
    onClose = new EventEmitter();

    busy = false;
    headerText = '';
    infoText = '';
    isRedirectedFromBankID = false;
    paymentsInApp = true;
    selectedApprovalLocation = ApprovalLocation.InApp;
    approvalLocation = ApprovalLocation;
    orgNr: string;

    constructor(
        private authService: AuthService,
        private brunoOnboardingService: BrunoOnboardingService,
    ) {}

    ngOnInit() {
        this.isRedirectedFromBankID = !!this.options?.data?.isRedirectedFromBankID;
        this.orgNr = this.authService.activeCompany.OrganizationNumber;

        if (this.isRedirectedFromBankID) {
            this.busy = true;
            this.headerText = 'Regnskapssystemet kobles med banken';
            this.createAgreement();
        } else {
            this.headerText = 'Koble kontoer';
        }
    }

    startBankIdOnboarding() {
        if (this.paymentsInApp) {
            const approval = this.selectedApprovalLocation === ApprovalLocation.InApp ? 'InApp' : 'InBank';
            localStorage.setItem('bankIdFlow-Approval', `${this.orgNr},${approval}`);
        }

        localStorage.setItem('init-open-bankid-modal', `${this.orgNr}`);

        this.authService.authenticateWithIdp('bankiddnbsso');
    }

    createAgreement() {
        this.brunoOnboardingService.createAgreementBankID().subscribe(
            (agreement) => {
                if (agreement) {
                    this.setInfoText();
                    this.busy = false;
                } else {
                    this.onClose.emit();
                }
            },
            (err) => {
                this.setInfoText();
                this.busy = false;
                console.error(err);
            },
        );
    }

    setInfoText() {
        const storage = localStorage.getItem('bankid-agreement-failed')?.split(',') || [];
        const storageText = localStorage.getItem('bankid-agreement-failed-text')?.split('|') || [];

        if (!storage.length) {
            this.headerText = 'Kobling opprettet';
            this.infoText =
                'Du har nå tilgang til saldo og transaksjoner i DNB Regnskap.' +
                ' Har du bestilt utbetaling fra DNB Regnskap vil den være klar om en liten stund.';
            return;
        }

        this.headerText = 'Kommunikasjonsproblemer';
        this.infoText = 'Vi har litt kommunikasjonsproblemer nå. Prøv igjen senere. Du kan også kontakte oss på chat.';

        if (storageText.length > 0) {
            this.headerText = storageText[0];
            this.infoText = storageText[1];
        }

        localStorage.removeItem('bankid-agreement-failed');
        localStorage.removeItem('bankid-agreement-failed-text');
    }
}
