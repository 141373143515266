import {
    Component,
    Input,
    Output,
    ElementRef,
    EventEmitter,
    ChangeDetectionStrategy,
    SimpleChanges,
    OnChanges,
    ViewChildren,
    QueryList,
} from '@angular/core';
import { UniFieldLayout } from '../../interfaces';
import { BaseControl } from '../baseControl';
import { cloneDeep, get, set } from 'lodash-es';
import { RigCheckbox } from '@uni-framework/ui/design-system/checkbox/checkbox';

@Component({
    selector: 'uni-checkboxgroup-input',
    templateUrl: './checkbox-group.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UniCheckboxgroupInput extends BaseControl implements OnChanges {
    @ViewChildren(RigCheckbox) checkboxes: QueryList<RigCheckbox>;
    @Input() public field: UniFieldLayout;
    @Input() public model: any;

    @Output() public readyEvent = new EventEmitter<UniCheckboxgroupInput>(true);
    @Output() public changeEvent = new EventEmitter<SimpleChanges>();

    public items: any[] = [];
    public selectedItems: any[] = [];

    constructor(public elementRef: ElementRef) {
        super();
    }

    public focus() {
        this.checkboxes?.first?.focus();
        return this;
    }

    public ngOnChanges(changes) {
        if (changes['field']) {
            if (!this.field.Options) {
                this.items = [];
            } else if (!this.field.Options.source) {
                this.items = [];
            } else if (this.field.Options.source.constructor === Array) {
                this.items = this.field.Options.source;
            } else if (this.field.Options.source.subscribe) {
                this.field.Options.souce.subscribe((items) => (this.items = items));
            } else if (typeof this.field.Options.source === 'string') {
                // TODO: manage lookup url;
            }
            this.readOnly$.next(this.field.ReadOnly);
        }

        if (this.model && this.field) {
            this.selectedItems = get(this.model, this.field.Property, []);
        }
    }

    public getValue(item) {
        return get(item, this.field.Options.valueProperty);
    }
    public getLabel(item) {
        return get(item, this.field.Options.labelProperty);
    }

    public toggleCheckbox(item) {
        if (this.field.ReadOnly) {
            return;
        }

        const wasChecked = this.isChecked(item);

        const itemValue = get(item, this.field.Options.valueProperty);
        const previousValue = cloneDeep(get(this.model, this.field.Property));

        if (wasChecked) {
            const index = this.selectedItems.indexOf(itemValue);
            this.selectedItems.splice(index, 1);
            set(this.model, this.field.Property, this.selectedItems);
        } else {
            this.selectedItems.push(itemValue);
            set(this.model, this.field.Property, [...this.selectedItems]);
        }

        this.emitChange(previousValue, this.selectedItems);
        this.emitInstantChange(previousValue, this.selectedItems, true);
    }

    public isChecked(item) {
        const itemValue = get(item, this.field.Options.valueProperty);
        return this.selectedItems.indexOf(itemValue) >= 0;
    }
}
