<ng-container *ngFor="let filter of quickFilters">
    <section class="quick-filter-field" [ngSwitch]="filter.type" [style.flex]="'0 0 ' + filter.width || 'auto'">
        <!-- Date -->
        <uni-datepicker
            *ngSwitchCase="'date'"
            [placeholder]="filter.label"
            [(value)]="filter.value"
            (valueChange)="changeEventDebouncer$.next()"
        >
        </uni-datepicker>

        <!-- Boolean -->
        <rig-checkbox *ngSwitchCase="'checkbox'" [(ngModel)]="filter.value" (change)="changeEventDebouncer$.next()">
            {{ filter.label }}
        </rig-checkbox>

        <!-- Select -->
        <uni-select
            *ngSwitchCase="'select'"
            [(value)]="filter.value"
            (valueChange)="changeEventDebouncer$.next()"
            [items]="filter.options"
            [source]="filter.optionsResolver"
            [config]="selectConfig"
            [autoWidth]="true"
        >
        </uni-select>

        <!-- Text, number -->
        <input
            *ngSwitchDefault
            type="text"
            [placeholder]="filter.label"
            [(ngModel)]="filter.value"
            (ngModelChange)="changeEventDebouncer$.next()"
        />
    </section>
</ng-container>
