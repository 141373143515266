<mat-spinner *ngIf="busy" class="c2a" [diameter]="24"></mat-spinner>

<span *ngIf="!busy && !statusHistory?.length" class="flex mt-1"> Fant ingen statushistorikk </span>

<ul *ngIf="statusHistory?.length">
    <li *ngFor="let logEntry of statusHistory">
        <span>{{ logEntry.statusText || 'Ingen status' }}</span>
        <span>{{ logEntry.date | date: 'dd.MM.yyyy HH:mm' }}</span>
        <span class="name" *ngIf="logEntry.user"> av {{ logEntry.user }}</span>
        <a *ngIf="statusHistoryLastApprovedEntryDate === logEntry.date" class="flex" (click)="viewApprovalHistory()"
            >Full godkjenningshistorikk</a
        >
    </li>
</ul>
