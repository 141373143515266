import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ElementRef } from '@angular/core';
import { UserService } from '@app/services/common/userService';
import { IUniModal, IModalOptions, ConfirmActions } from '@uni-framework/uni-modal/interfaces';
import { AuthService } from '@app/authService';
import { ToastService, ToastType } from '@uni-framework/uniToast/toastService';

@Component({
    selector: 'confirm-twofactor-modal',
    templateUrl: './confirm-twofactor-modal.html',
    styleUrls: ['./confirm-twofactor-modal.sass'],
})
export class ConfirmTwoFactorModal implements OnInit, IUniModal {
    @ViewChild('codeElement', { static: true })
    codeElement: ElementRef;

    @Input()
    options: IModalOptions = {};

    @Output()
    onClose = new EventEmitter<any>();

    busy = true;
    msg: string = '';
    reference: string = '';
    code: string = '';

    constructor(
        private userService: UserService,
        private authService: AuthService,
        private toastService: ToastService,
    ) {}

    ngOnInit() {
        this.options.buttonLabels ??= {
            accept: 'Godkjenn',
            cancel: 'Avbryt',
        };

        this.options.header ??= 'To-faktor godkjenning';
        this.reference = this.options.data?.reference;

        this.userService.sendUserCodeChallenge(this.reference).subscribe(
            () => {
                this.busy = false;
                setTimeout(() => this.focus());
            },
            (err) => {
                this.msg = this.translateError(err, true);
                this.busy = false;
            },
        );
    }

    private translateError(err, challenge) {
        let msg = '';
        if (err.status == 403) {
            msg = 'Din bruker må være satt opp som bankbruker samt ha to-faktor påskrudd.';
        } else if (err.status == 400 && err.error.Message.indexOf('Two Factor Is Not Enabled') > 0) {
            msg = challenge
                ? 'To-faktor må være påskrudd for din bruker. Gå til brukerinnstillinger for din bruker og skru dette på.'
                : 'Ugyldig kode';
        } else {
            msg = `En feil oppstod, prøv igjen senere. (${err.error.Message})`;
        }

        return msg;
    }

    verify() {
        this.busy = true;
        this.userService.verifyUserCodeChallenge(this.code, this.reference).subscribe(
            (verification) => {
                // Reload to get updated 2FA auth time
                this.authService.refreshToken().then(
                    () => {
                        this.accept();
                    },
                    (err) => this.reject(this.translateError(err, false)),
                );
            },
            (err) => this.reject(this.translateError(err, false)),
        );
    }

    focus() {
        this.codeElement?.nativeElement?.focus();
    }

    accept() {
        this.onClose.emit(ConfirmActions.ACCEPT);
    }

    reject(msg: string) {
        this.busy = false;
        this.toastService.addToast('Godkjenning avvist', ToastType.bad, 15, msg);
        this.onClose.emit(ConfirmActions.REJECT);
    }

    cancel() {
        this.onClose.emit(ConfirmActions.CANCEL);
    }
}
