import { Component, Input } from '@angular/core';

@Component({
    selector: 'info-banner',
    styleUrls: ['./info-banner.sass'],
    template: `
        <section id="banner">
            <i class="material-icons-outlined" Id="banner-icon">info</i>
            <section Id="banner-message">
                {{ config.message }}
                <a (click)="config.action()">
                    {{ config.link }}
                    <span *ngIf="config?.link && config?.linkIcon" class="material-icons" id="link-icon">
                        {{ config.linkIcon }}
                    </span>
                </a>
            </section>
        </section>
    `,
})
export class InfoBanner {
    @Input() config: IInfoBannerConfig;
}

export interface IInfoBannerConfig {
    message: string;
    link?: string;
    linkIcon?: string;
    action?: () => void;
}
