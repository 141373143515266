<mat-spinner *ngIf="isLoadIndicator" [diameter]="26"></mat-spinner>

<i *ngIf="!isLoadIndicator" class="material-icons toast-type-icon">{{ icon }}</i>

<section class="toast-body">
    <section class="header">
        {{ toast.title | translate }}
        <small class="toast-count" *ngIf="toast.count > 1">({{ toast.count }})</small>
    </section>

    <section class="message" *ngIf="toast.message?.length" [innerHTML]="toast.message | translate"></section>

    <section *ngIf="toast.actions">
        <button
            *ngFor="let action of toast.actions"
            class="toast-action"
            [ngClass]="action.buttonClass"
            (click)="runToastAction(action)"
        >
            {{ action.label }}
        </button>
    </section>
</section>

<i *ngIf="closeable" class="material-icons toast-close-icon" (click)="close()" role="button" aria-label="Close">
    close
</i>
