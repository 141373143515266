import {
    Component,
    Input,
    Output,
    ElementRef,
    EventEmitter,
    ChangeDetectionStrategy,
    SimpleChanges,
    OnChanges,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { get, set } from 'lodash-es';
import { UniFieldLayout } from '../../interfaces';
import { BaseControl } from '../baseControl';

@Component({
    selector: 'uni-radiogroup-input',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './radio-group.html',
})
export class UniRadiogroupInput extends BaseControl implements OnChanges {
    @Input() public field: UniFieldLayout;
    @Input() public model: any;
    @Input() public control: UntypedFormControl;
    @Input() public asideGuid: string;

    @Output() public readyEvent: EventEmitter<UniRadiogroupInput> = new EventEmitter<UniRadiogroupInput>(true);
    @Output() public changeEvent: EventEmitter<SimpleChanges> = new EventEmitter<SimpleChanges>();
    @Output() public inputEvent: EventEmitter<SimpleChanges> = new EventEmitter<SimpleChanges>();
    @Output() public focusEvent: EventEmitter<UniRadiogroupInput> = new EventEmitter<UniRadiogroupInput>(true);

    public items: any[] = [];
    public initValue: any;
    public checkedValue = null;

    constructor(public elementRef: ElementRef) {
        super();
    }

    public focus() {
        this.elementRef.nativeElement.focus();
        return this;
    }

    public ngOnChanges(changes) {
        if (changes['field']) {
            this.readOnly$.next(this.field.ReadOnly);
            if (!this.field.Options) {
                this.items = [];
            } else if (!this.field.Options.source) {
                this.items = [];
            } else if (this.field.Options.source.constructor === Array) {
                this.items = this.field.Options.source;
            } else if (this.field.Options.source.subscribe) {
                this.field.Options.souce.subscribe((items) => (this.items = items));
            } else if (typeof this.field.Options.source === 'string') {
                // TODO: manage lookup url;
            }
        }

        if (this.field && this.model) {
            this.initValue = get(this.model, this.field.Property);
            this.checkedValue = this.initValue;
        }
    }

    public onChange(event) {
        this.checkedValue = event.value;
        const previousValue = get(this.model, this.field.Property);

        set(this.model, this.field.Property, event.value);

        this.emitChange(previousValue, event.value);
        this.emitInstantChange(previousValue, event.value, true);
    }

    public getRadioButtonValue(item) {
        return get(item, this.field.Options.valueProperty);
    }
    public getRadioButtonLabel(item) {
        return get(item, this.field.Options.labelProperty);
    }

    public isChecked(item) {
        const itemValue = get(item, this.field.Options.valueProperty);
        const modelValue = get(this.model, this.field.Property);
        return itemValue === modelValue;
    }
}
