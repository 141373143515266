import { Injectable } from '@angular/core';
import { Address, BusinessRelation, Customer, Email, Phone, Supplier } from '@uni-entities';
import { AutocompleteOptions } from '@uni-framework/ui/autocomplete/autocomplete';
import { GuidService } from './guidService';
import { IntegrationServerCaller } from './integrationServerCaller';

@Injectable({ providedIn: 'root' })
export class BrRegService {
    constructor(
        private integrationService: IntegrationServerCaller,
        private guidService: GuidService,
    ) {}

    findOne(orgnumber: string) {
        return this.integrationService.businessRelationSearch(orgnumber, 1, true, false);
    }

    getLookupConfig(): AutocompleteOptions {
        return {
            placeholder: 'Søk i 1880/Brønnøysundregistrene',
            autofocus: true,
            clearInputOnSelect: false,
            lookup: (query, filterCheckboxValues) => {
                return this.dataFetcher(query, filterCheckboxValues);
            },
            filterCheckboxes: [
                { label: 'Selskaper', value: true },
                { label: 'Privatpersoner', value: true },
            ],
            resultTableColumns: [
                { header: 'Navn', field: 'Name' },
                { header: 'Adresse', field: 'Streetaddress' },
                {
                    header: 'Poststed',
                    template: (item) => {
                        if (item.PostCode || item.City) {
                            return `${item.PostCode} - ${item.City}`;
                        }
                    },
                },
                { header: 'Orgnummer', field: 'OrganizationNumber' },
            ],
        };
    }

    dataFetcher(query, filterCheckboxValues) {
        return this.integrationService.businessRelationSearch(
            query,
            50,
            filterCheckboxValues[0],
            !!filterCheckboxValues.length && filterCheckboxValues[1],
        );
    }

    mapBrRegDataToCustomer(data, customer: Customer): Customer {
        if (!customer) {
            customer = <Customer>{};
        }

        return this.mapBrRegDataToEntity(data, customer, true);
    }

    mapBrRegDataToSupplier(data, supplier: Supplier): Supplier {
        if (!supplier) {
            supplier = <Supplier>{};
        }

        return this.mapBrRegDataToEntity(data, supplier, true);
    }

    mapBrRegDataToEntity(data, entity, copyToShippingAddress: boolean = false) {
        entity.OrgNumber = data.OrganizationNumber;
        entity.WebUrl = data.Url;
        entity.Info = <BusinessRelation>{ Name: data.Name };

        if (data.Streetaddress || data.PostCode || data.City) {
            const address = <Address>{
                AddressLine1: data.Streetaddress,
                PostalCode: data.PostCode,
                City: data.City,
                _createguid: this.guidService.guid(),
            };

            entity.Info.Addresses = [address];
            entity.Info.InvoiceAddress = address;

            if (copyToShippingAddress) {
                entity.Info.ShippingAddress = address;
            }
        }

        if (data.Phone) {
            const phone = <Phone>{
                Number: data.Phone,
                _createguid: this.guidService.guid(),
            };

            entity.Info.Phones = [phone];
            entity.Info.DefaultPhone = phone;
        }

        if (data.EmailAddress) {
            const email = <Email>{
                EmailAddress: data.EmailAddress,
                _createguid: this.guidService.guid(),
            };

            entity.Info.Emails = [email];
            entity.Info.DefaultEmail = email;
        }

        return entity;
    }
}
