<section role="dialog" class="uni-modal">
    <header>{{ options?.header || 'Samtykke til å hente ut data' }}</header>

    <article>
        <p class="info-text" *ngIf="options?.data?.infoTop">
            {{ options.data.infoTop }}
        </p>

        <span class="list-header-info"> Opplysningene vi ber om tillatelse til å hente ut: </span>

        <ul *ngIf="options?.data?.listOfWantedData">
            <li *ngFor="let item of options?.data?.listOfWantedData">{{ item }}</li>
        </ul>

        <p class="disclaimer">
            Som databehandler for DNB Regnskap kan vi ikke bruke disse opplysningene for eget formål uten deres
            tillatelse. Opplysningene vil kun bli brukt til å utarbeide et tilbud til dere og deretter bli slettet fra
            våre systemer. Vil du vite mer om hvordan vi behandler personopplysninger, se
            <a href="https://www.dnb.no/om-oss/personvern.html" target="_blank"> våre nettsider om personvern.</a>
        </p>

        <section class="alert info" *ngIf="options?.data?.alertMessage">
            <i class="material-icons">campaign</i>
            <span> {{ options.data.alertMessage }} </span>
        </section>
    </article>

    <footer>
        <button class="tertiary" (click)="onClose.emit(false)"><i class="material-icons">close</i> Avbryt</button>
        <button class="c2a" (click)="onClose.emit(true)">Bekreft</button>
    </footer>
</section>
