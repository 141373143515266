import { Directive, HostListener, Input, inject } from '@angular/core';
import { AnalyticsService } from './analytics.service';

@Directive({
    selector: '[trackClickEvents]',
})
export class AnalyticsClickDirective {
    @Input({ alias: 'trackClickEvents' }) event: string | { name: string; value: any };

    private analytics = inject(AnalyticsService);

    @HostListener('click', ['$event'])
    public onClick() {
        if (typeof this.event === 'string') {
            this.analytics.trackElementClick(this.event);
        } else {
            this.analytics.trackElementClick(this.event.name, this.event.value);
        }
    }
}
