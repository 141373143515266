import { refCount, publishReplay, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { UniHttp } from '@uni-framework/core/http/http';
import { Observable } from 'rxjs';
import { ContractType, ElsaAgreement } from '@app/models';
import { AuthService } from '@app/authService';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class ElsaAgreementService {
    private cache: { [endpoint: string]: Observable<HttpResponse<any>> } = {};
    ELSA_SERVER_URL = environment.ELSA_SERVER_URL;
    contractTypeEnum = ContractType;

    constructor(
        private authService: AuthService,
        private uniHttp: UniHttp,
        private http: HttpClient,
    ) {
        this.authService.authentication$.subscribe(() => (this.cache = {}));
    }

    private requestData(endpoint: string) {
        let request = this.cache[endpoint];
        if (!request) {
            request = this.uniHttp
                .asGET()
                .usingElsaDomain()
                .withEndPoint(endpoint)
                .send()
                .pipe(publishReplay(1), refCount());

            this.cache[endpoint] = request;
        }

        return request.pipe(map((res) => res.body));
    }

    getAll(filter?: string): Observable<ElsaAgreement[]> {
        return this.requestData(`/api/agreements${filter ? `?$filter=${filter}` : ''}`);
    }

    GetByProductID(productID: number): Observable<ElsaAgreement> {
        return this.requestData(
            `/api/agreements?$filter=productid eq ${productID} and agreementstatus eq 'Active'`,
        ).pipe(map((agreements) => agreements[0]));
    }

    getByType(type: string): Observable<ElsaAgreement> {
        const endpoint = `/api/agreements?$filter=agreementtype eq '${type}' and agreementstatus eq 'Active'`;
        return this.http.get<ElsaAgreement>(this.ELSA_SERVER_URL + endpoint).pipe(map((agreements) => agreements[0]));
    }

    getCustomerAgreement(): Observable<ElsaAgreement> {
        const endpoint = `/api/agreements/customer`;
        return this.requestData(endpoint);
    }

    getCustomerAgreementByContractType(contractType: number): Observable<ElsaAgreement> {
        const contractTypeString = this.contractTypeEnum[contractType];

        const filter = `agreementtype eq 'Customer' and (contracttype eq null${
            contractTypeString ? ` or contracttype eq '${contractTypeString}')` : ')'
        } and agreementstatus eq 'Active'`;

        return this.requestData(`/api/agreements?$filter=${filter}&$orderby=id desc`).pipe(
            map((agreements) => {
                if (!agreements?.length) {
                    return null;
                }

                return agreements.find((a) => a.ContractType === contractType) || agreements[0];
            }),
        );
    }

    confirmCustomerAgreement() {
        return this.uniHttp.asPOST().usingBusinessDomain().withEndPoint('users?action=accept-CustomerAgreement').send();
    }
}
