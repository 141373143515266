import { Component, EventEmitter, Input, Output } from '@angular/core';

export * from './query-item';

export interface QueryItem {
    logicalOperator?: 'and' | 'or';
    field?: string;
    operator?: string;
    value?: any;
    siblings?: QueryItem[];
}

export type QueryBuilderFieldType = 'text' | 'number' | 'date' | 'boolean' | 'select' | 'status';

export interface QueryBuilderField {
    label: string;
    field: string;
    type?: QueryBuilderFieldType;
    selectConfig?: {
        options: any[];
        valueField: string;
        displayField: string;
    };
}

export interface QueryBuilderOperator {
    label: string;
    operator: string;
    isFunction?: boolean;
    forFieldTypes?: string[];
    disableValueField?: boolean;
}

@Component({
    selector: 'query-builder',
    templateUrl: './query-builder.html',
    styleUrls: ['./query-builder.sass'],
})
export class QueryBuilder {
    @Input() disableAddButton: boolean = false;
    @Input() fields: QueryBuilderField[];
    @Input() siblingMaxDepth: number = 1;
    @Input() operatorResolver: (fieldType: string) => QueryBuilderOperator[];
    @Input() includeNotSetOperator = false;
    @Input() includeNotContainsOperator = false;

    @Input() query: QueryItem[];
    @Output() queryChange = new EventEmitter<QueryItem[]>();

    items: QueryItem[];

    ngOnChanges(changes) {
        if (changes['query']) {
            this.items = this.query || [{}];
        }
    }

    focus(index: number) {
        const queryItem = document.getElementsByTagName('query-item')?.item(index);
        if (queryItem) {
            const focusableElement: HTMLElement =
                queryItem.querySelector('select.field-select') ||
                queryItem.querySelector('input') ||
                queryItem.querySelector('select');

            focusableElement?.focus();
        }
    }

    addItem() {
        this.items.push({ logicalOperator: 'and' });
    }

    removeItem(index: number) {
        const item = this.items[index];
        if (!item) return;

        // If the item has siblings then we should only remove the item itself, and "promote" the first sibling to "main item"
        if (item.siblings?.length) {
            const firstSibling = item.siblings.shift();
            const siblings = [...item.siblings];

            this.items[index] = {
                ...firstSibling,
                siblings,
            };
        } else {
            this.items.splice(index, 1);
        }

        this.emitChange();
    }

    emitChange() {
        if (this.items && this.items[0]) {
            this.items[0].logicalOperator = undefined;
        }

        this.query = this.items;
        this.queryChange.next(this.query);
    }

    defaultOperatorResolver = (fieldType: QueryBuilderFieldType) => {
        let operators: QueryBuilderOperator[] = [
            { label: 'inneholder', operator: 'contains', isFunction: true },
            { label: 'inneholder ikke', operator: 'not contains', isFunction: true },
            { label: 'begynner på', operator: 'startswith', isFunction: true },
            { label: 'slutter på', operator: 'endswith', isFunction: true },
            { label: 'er', operator: 'eq' },
            { label: 'er ikke', operator: 'ne' },
            { label: 'mindre enn', operator: 'lt' },
            { label: 'mindre enn eller lik', operator: 'le' },
            { label: 'større enn', operator: 'gt' },
            { label: 'større enn eller lik', operator: 'ge' },
        ];

        if (fieldType === 'date') {
            operators = [
                { label: 'er', operator: 'eq' },
                { label: 'er ikke', operator: 'ne' },
                { label: 'fra og med', operator: 'ge' },
                { label: 'til og med', operator: 'le' },
            ];
        }

        if (fieldType === 'number' || fieldType === 'status' || fieldType === 'select') {
            operators = [
                { label: 'er', operator: 'eq' },
                { label: 'er ikke', operator: 'ne' },
                { label: 'mindre enn', operator: 'lt' },
                { label: 'mindre enn eller lik', operator: 'le' },
                { label: 'større enn', operator: 'gt' },
                { label: 'større enn eller lik', operator: 'ge' },
            ];
        }

        if (!this.includeNotContainsOperator) {
            operators = operators.filter((op) => op.operator !== 'not contains');
        }

        if (this.includeNotSetOperator) {
            operators.push({ label: 'er blank/ikke satt', operator: 'NOT_SET', disableValueField: true });
        }

        return operators;
    };
}
