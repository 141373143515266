import { Pipe, PipeTransform } from '@angular/core';
import { ElsaUserLicenseType } from '@app/models';

@Pipe({ name: 'elsaUserLicenseType' })
export class ElsaUserLicenseTypePipe implements PipeTransform {
    transform(userLicenseType: number): string {
        switch (userLicenseType) {
            case ElsaUserLicenseType.Standard:
                return 'Standard';
            case ElsaUserLicenseType.Accountant:
                return 'Regnskapsfører';
            case ElsaUserLicenseType.Revision:
                return 'Revisor';
            case ElsaUserLicenseType.Training:
                return 'Skole/opplæring';
            case ElsaUserLicenseType.Support:
                return 'Support';
            case ElsaUserLicenseType.Internal:
                return 'Intern';
            case ElsaUserLicenseType.Server:
                return 'Server';
            default:
                return '';
        }
    }
}
