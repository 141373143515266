import { Component, Input, Output, EventEmitter } from '@angular/core';
import { AuthService } from '@app/authService';
import { IModalOptions, IUniModal, ConfirmActions } from '@uni-framework/uni-modal/interfaces';
import { UniModalService } from '../../modalService';
import { DNBDataExtractionAgreementModal } from '../data-extraction-agreement-modal/data-extraction-agreement-modal';
import { ToastService, ToastType } from '@uni-framework/uniToast/toastService';
import { THEMES, theme } from 'src/themes/theme';
import { DistributionPlanService } from '@app/services/common/distributionService';
import { ErrorService } from '@app/services/common/errorService';

export const ASK_ABOUT_PENSION_LOCALSTORAGE_KEY = 'ASK_ABOUT_PENSION';

@Component({
    selector: 'pension-modal',
    templateUrl: './pension-modal.html',
    styleUrls: ['./pension-modal.sass'],
})
export class DNBPensionModal implements IUniModal {
    @Input() options: IModalOptions;
    @Output() onClose = new EventEmitter();

    dontAskAgain = false;
    busy = false;

    constructor(
        private distributionService: DistributionPlanService,
        private modalService: UniModalService,
        private toast: ToastService,
        private authService: AuthService,
        private errorService: ErrorService,
    ) {}

    ngOnInit() {
        if (theme.theme !== THEMES.EXT02) {
            this.onClose.emit();
        }
    }

    sendOffer() {
        const options: IModalOptions = {
            header: 'Samtykke for å hente ut informasjon om ansatte',
            data: {
                infoTop: `For å gi dere et tilbud på pensjon, trenger vi informasjon om deres ansatte. Dette kan vi hente ut fra opplysninger vi behandler for dere i ${theme.appName}`,
                listOfWantedData: ['Navn', 'Personnummer', 'Lønn', 'Arbeidsforhold'],
                alertMessage: 'Ønsker du å gjøre dette senere finner du Pensjon under Bankprodukter',
            },
            closeOnClickOutside: false,
        };
        this.busy = true;
        this.modalService.open(DNBDataExtractionAgreementModal, options).onClose.subscribe({
            next: (response: ConfirmActions) => {
                if (response) {
                    const body = {
                        ToAddresses: ['kundeservice.dnbregnskap@dnb.no'],
                        Subject: 'Forespørsel om pensjon',
                        Message: `Orgnummer: ${this.authService.activeCompany.OrganizationNumber}, Selskapsnavn: ${this.authService.activeCompany.Name}, Brukernavn: ${this.authService.currentUser.DisplayName}`,
                        Entitytype: 'Epost',
                    };

                    this.distributionService.sendNotifyInterestEmail(body).subscribe({
                        next: () => {
                            this.toast.addToast(
                                'Vi kontakter deg for et tilbud',
                                ToastType.good,
                                7,
                                'Tusen takk for din interesse.',
                            );
                            this.onClose.emit(true);
                        },
                        error: (err) => this.errorService.handle(err),
                    });
                } else {
                    this.busy = false;
                }
            },
        });
    }
}
