import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
    selector: 'uni-card',
    styleUrls: ['./card.sass'],
    template: `
        <section
            class="card-header"
            (click)="toggle()"
            [class.toggle]="collapsible"
            [ngClass]="expanded ? 'expanded' : 'collapsed'"
        >
            <ng-content select="[header]"></ng-content>

            <i
                *ngIf="collapsible"
                class="material-icons toggle-icon"
                role="button"
                aria-label="Toggle card details visibility"
            >
                expand_more
            </i>
        </section>

        <section class="card-content" [@collapse]="!expanded">
            <ng-content select="[content]"></ng-content>
        </section>
    `,
    animations: [
        trigger('collapse', [
            state('false', style({ height: '*', visibility: 'visible', padding: '*' })),
            state('true', style({ height: '0', visibility: 'hidden', padding: '0' })),
            transition('false => true', animate('250ms ease-in')),
            transition('true => false', animate('250ms ease-out')),
        ]),
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UniCard {
    @Input() collapsible: boolean;
    @Input() expanded: boolean = true;

    constructor(private cdr: ChangeDetectorRef) {}

    toggle() {
        this.expanded = !this.expanded;
        this.cdr.markForCheck();
    }

    getExpandedState() {
        return this.expanded ? 'expanded' : 'collapsed';
    }
}
