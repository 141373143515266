import { Injectable } from '@angular/core';
import { AuthService } from '@app/authService';
import { BizHttp } from '../../../framework/core/http/BizHttp';
import { UniHttp } from '../../../framework/core/http/http';
import { Approval, ApprovalStatus } from '../../unientities';

@Injectable({ providedIn: 'root' })
export class ApprovalService extends BizHttp<Approval> {
    constructor(
        http: UniHttp,
        private authService: AuthService,
    ) {
        super(http);
        this.relativeURL = Approval.RelativeUrl;
        this.entityType = Approval.EntityType;
        this.DefaultOrderBy = null;
    }

    getApprovals(filter?: {
        status?: ApprovalStatus;
        entityIDs?: number[];
        entityType?: string;
        onlyForCurrentUser?: boolean;
    }) {
        let filterString = '';

        if (filter) {
            let filters = [];

            if (filter.onlyForCurrentUser) {
                filters.push(`UserID eq ${this.authService.currentUser.ID}`);
            }

            if (filter.status) {
                filters.push(`StatusCode eq ${filter.status}`);
            }

            if (filter.entityIDs?.length) {
                const idFilters = filter.entityIDs.map((ID) => `Task.EntityID eq ${ID}`);
                filters.push(`(${idFilters.join(' or ')})`);
            }

            if (filter.entityType) {
                filters.push(`Task.Model.Name eq '${filter.entityType}'`);
            }

            if (filters.length) {
                filterString = `filter=${filters.join(' and ')}`;
            }
        }

        return super.GetAll(filterString, ['Task.Approvals', 'Task.ApprovalPlan', 'Task.Model']);
    }
}
