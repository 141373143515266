<div
    class="summary-container container-for-spinner"
    [style.height]="height"
    [ngClass]="rowClass"
    [class.chameleon]="transparent"
    [class.has-no-data]="missingDataSource && !busy"
    [class.hide-on-no-data]="missingDataSource && !busy && options?.hideOnNoData"
>
    @if (busy) {
        <section class="modal-spinner">
            <mat-spinner class="c2a"></mat-spinner>
        </section>
    }

    @if (!missingDataSource) {
        <uni-illustration *ngIf="illustration" [name]="illustration"></uni-illustration>

        <div class="summary-data-grid-container" [ngClass]="rowClass">
            @for (tile of tiles; track tile) {
                <div class="grid-tile flex-column justify-center">
                    @if (tile.header) {
                        <span class="header ellipsis" [title]="tile.header"> {{ tile.header }} </span>
                    }
                    @switch (tile?.type || 3) {
                        @case (1) {
                            <span
                                class="summary-tile-value"
                                [ngClass]="tile.class"
                                [class.clickable]="!!tile.onClick"
                                [title]="tile.title || tile.header || tile.buttonText"
                                (click)="!!tile.onClick && tile.onClick(tile)"
                            >
                                {{ data[tile.field] | uninumberformat: 'money' }}{{ tile.suffix }}
                            </span>
                        }

                        @case (2) {
                            <span
                                class="summary-tile-value"
                                [ngClass]="tile.class"
                                [class.clickable]="!!tile.onClick"
                                [title]="tile.title || tile.header || tile.buttonText"
                                (click)="!!tile.onClick && tile.onClick(tile)"
                            >
                                {{ data[tile.field] | date: tile.options?.datefilter || 'dd.MM.yyyy' }}{{ tile.suffix }}
                            </span>
                        }

                        @case (3) {
                            <span
                                class="summary-tile-value"
                                [ngClass]="tile.class"
                                [class.clickable]="!!tile.onClick"
                                [title]="tile.title || tile.header || tile.buttonText"
                                (click)="!!tile.onClick && tile.onClick(tile)"
                            >
                                {{ data[tile.field] | translate }}{{ tile.suffix }}
                            </span>
                        }

                        @case (4) {
                            <button
                                class="c2a secondary"
                                [ngClass]="tile.class"
                                [title]="tile.title || tile.header || tile.buttonText"
                                (click)="!!tile.onClick && tile.onClick(tile)"
                            >
                                {{ tile.buttonText | translate }}{{ tile.suffix }}
                            </button>
                        }

                        @case (6) {
                            <span
                                class="summary-tile-value"
                                [ngClass]="tile.class"
                                [class.clickable]="!!tile.onClick"
                                [title]="tile.title || tile.header || tile.buttonText"
                                (click)="!!tile.onClick && tile.onClick(tile)"
                            >
                                {{ data[tile.field] }}{{ tile.suffix }}
                            </span>
                        }
                    }
                </div>
            }
        </div>

        @if (options?.shortcuts) {
            <div class="summary-shortcuts">
                <span class="header-text">Snarveier</span>
                <ul>
                    @for (shortcut of options.shortcuts; track shortcut.link) {
                        <li>
                            @if (shortcut.link) {
                                <a
                                    [ngClass]="shortcut.class"
                                    [routerLink]="shortcut.link"
                                    [queryParams]="shortcut?.params || null"
                                    [title]="shortcut.title"
                                    [target]="shortcut.target || ''"
                                >
                                    {{ shortcut.label }}
                                </a>
                            }
                            @if (shortcut.onClick && !shortcut.link && !shortcut.dropdownOptions) {
                                <a [ngClass]="shortcut.class" (click)="shortcut.onClick()" [title]="shortcut.title">
                                    {{ shortcut.label }}
                                </a>
                            }
                            @if (shortcut.onClick && !shortcut.link && shortcut.dropdownOptions?.length) {
                                <a #toggle>{{ shortcut.label }}</a>
                                <dropdown-menu [trigger]="toggle" (click)="$event.stopPropagation()">
                                    <ng-template>
                                        @for (item of shortcut.dropdownOptions; track item.label) {
                                            <a class="dropdown-menu-item" (click)="shortcut.onClick(item.value)">
                                                {{ item.label }}
                                            </a>
                                        }
                                    </ng-template>
                                </dropdown-menu>
                            }
                        </li>
                    }
                </ul>
            </div>
        }
    }
    @if (missingDataSource && !busy) {
        <uni-illustration class="empty-illustration" name="empty_state"></uni-illustration>
        <span class="no-data-text"> {{ 'COMMON.NO_SUMMARY_DATA' | translate }} </span>
    }
</div>
