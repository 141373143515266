import { Component, Input } from '@angular/core';
import { UniTableColumn } from '@uni-framework/ui/unitable';
import { TableGroupingService } from '../services/table-grouping-service';

type GroupingItem = { selected: boolean; column: UniTableColumn };

@Component({
    selector: 'grouping-column-dropdown',
    template: `
        <button #toggle class="secondary small">
            Grupper på
            <i class="material-icons">expand_more</i>
        </button>

        <dropdown-menu [trigger]="toggle" [closeOnClick]="false">
            <ng-template>
                <div class="grouping-columns flex-column gap-2">
                    @for (item of groupingState; track item.column.field) {
                        @if (item.selected) {
                            <rig-checkbox [checked]="true" (change)="toggleItem(item)">
                                {{ item.column.header }}
                            </rig-checkbox>
                        }
                    }

                    @for (item of groupingState; track item.column.field) {
                        @if (!item.selected) {
                            <rig-checkbox [checked]="false" (change)="toggleItem(item)">
                                {{ item.column.header }}
                            </rig-checkbox>
                        }
                    }
                </div>
            </ng-template>
        </dropdown-menu>
    `,
    styles: `
        .grouping-columns {
            padding: 1rem;
        }
    `,
})
export class GroupingColumnDropdown {
    @Input() columns: UniTableColumn[];

    visibleColumns: UniTableColumn[];
    groupingState: GroupingItem[];

    constructor(public groupingService: TableGroupingService) {}

    ngOnChanges() {
        this.visibleColumns = this.columns.filter((col) => col.visible);
        this.updateGroupingState();
    }

    toggleItem(item: GroupingItem) {
        this.groupingService.toggleGroupByColumn(item.column);
        this.updateGroupingState();
    }

    updateGroupingState() {
        this.groupingState = this.visibleColumns?.map((column) => ({
            selected: this.groupingService.groupByColumns?.some((col) => col.field === column.field),
            column,
        }));
    }
}
