import { Injectable } from '@angular/core';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { of, throwError } from 'rxjs';
import { Account, Customer, Supplier } from '@uni-entities';
import { GuidService } from '@app/services/common/guidService';
import { AccountService } from '@app/services/accounting/accountService';
import { CustomerService } from '@app/services/sales/customerService';
import { SupplierService } from '@app/services/accounting/supplierService';
import { ErrorService } from '@app/services/common/errorService';
import { IToastOptions, ToastService, ToastTime, ToastType } from '@uni-framework/uniToast/toastService';
import { AccountGroupService } from '@app/services/accounting/accountGroupService';
import { VatTypeService } from '@app/services/accounting/vatTypeService';
import { AltinnAccountLink, AltinnAccountLinkService } from '@app/services/accounting/altinnAccountLinkService';
import { getNewGuid } from '@app/components/common/utils/utils';

@Injectable()
export class NewAccountModalV2Service {
    constructor(
        private accountService: AccountService,
        private customerService: CustomerService,
        private supplierService: SupplierService,
        private accountGroupService: AccountGroupService,
        private vatTypeService: VatTypeService,
        private altinnAccountLinkService: AltinnAccountLinkService,
        private toastService: ToastService,
        private errorService: ErrorService,
        private guidService: GuidService,
    ) {}

    saveFromCustomer(model: Customer) {
        return this.saveEntity('customer', model);
    }

    saveFromSupplier(model: Supplier) {
        return this.saveEntity('supplier', model);
    }

    private saveEntity(type: 'supplier' | 'customer', model: Supplier | Customer) {
        model._createguid = this.guidService.guid();
        const service = type === 'supplier' ? this.supplierService : this.customerService;
        const save$ = model.ID ? service.Put(model.ID, model) : service.Post(model);
        return save$.pipe(
            switchMap((entity) => {
                return this.accountService
                    .GetAll(`filter=${type}ID eq ${entity.ID}&expand=${type}.Info`)
                    .pipe(map((result) => result?.[0]));
            }),
        );
    }

    saveFromGeneralLedgerAccount(model: Account) {
        // Doing this to prevent "Foreignkey does not match parent ID" error:
        if (model.AccountGroup && model.AccountGroupID !== model.AccountGroup.ID) {
            model.AccountGroup = null;
        }

        return this.accountGroupService.assignAccountGroupIDToAccount(model).pipe(
            switchMap((account) => this.accountService.Post(account)),
            catchError((error) => {
                return this.accountService.GetAll(`filter=AccountNumber eq ${model.AccountNumber}`).pipe(
                    map((res) => {
                        const existingAccount = res && res[0];
                        if (existingAccount) {
                            const toastOptions: IToastOptions = {
                                title: 'En konto med dette kontonummeret eksisterer allerede',
                                type: ToastType.bad,
                                duration: ToastTime.medium,
                            };

                            if (!existingAccount.Visible) {
                                toastOptions.message =
                                    'Denne kontoen finnes allerede, men er satt som ikke synlig.<br/>' +
                                    '<a href="#/accounting/accountsettings">Gå til kontoplan-oversikten for å gjøre den synlig' +
                                    '<i class="material-icons">launch</i></a>';
                            } else if (existingAccount.Locked) {
                                toastOptions.message =
                                    'Denne kontoen finnes allerede, men er sperret.<br/>' +
                                    '<a href="#/accounting/accountsettings">Gå til kontoplan for å åpne for føring på kontoen' +
                                    '<i class="material-icons">launch</i></a>';
                            }

                            this.toastService.toast(toastOptions);
                        } else {
                            this.errorService.handle(error);
                        }

                        return null;
                    }),
                );
            }),
            switchMap((account) => {
                if (model['_AltinnAccountNumber']) {
                    account['_AltinnAccountNumber'] = model['_AltinnAccountNumber'];
                    return this.saveAltinnAccountLink(account);
                } else {
                    return of(account);
                }
            }),
        );
    }

    private saveAltinnAccountLink(account) {
        return this.altinnAccountLinkService.getByAccountNumber(account.AccountNumber).pipe(
            map((altinnAccountLink: any) => {
                if (!altinnAccountLink) {
                    altinnAccountLink = {
                        _createguid: getNewGuid(),
                    };
                }
                altinnAccountLink.AltinnAccountNumber = account['_AltinnAccountNumber'];
                altinnAccountLink.AccountNumber = account.AccountNumber;
                return altinnAccountLink;
            }),
            switchMap((altinnAccountLink: AltinnAccountLink) =>
                altinnAccountLink.ID > 0 ? this.altinnAccountLinkService.save(altinnAccountLink) : of(null),
            ),
            map(() => account),
        );
    }
}
