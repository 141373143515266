import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BizHttp } from '../../../framework/core/http/BizHttp';
import { JournalEntryLine } from '../../unientities';
import { UniHttp } from '../../../framework/core/http/http';
import { PeriodDates } from '../../models/accounting/PeriodDates';
import { rigDate } from '@app/components/common/utils/rig-date';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class JournalEntryLineService extends BizHttp<JournalEntryLine> {
    public StatusTypes: Array<any> = [
        { Code: 31001, Text: 'Åpen' },
        { Code: 31002, Text: 'Delvis åpen' },
        { Code: 31003, Text: 'Lukket' },
        { Code: 31004, Text: 'Kreditert' },
    ];

    constructor(http: UniHttp) {
        super(http);

        this.relativeURL = JournalEntryLine.RelativeUrl;

        this.entityType = JournalEntryLine.EntityType;

        // set this property if you want a default sort order from the API
        this.DefaultOrderBy = null;

        // caching journalentryline requests can caused undesired effects, so diable it
        super.disableCache();
    }

    public getJournalEntryLinePostPostData(
        includeOpenPosts,
        includeMarkedPosts,
        customerID,
        supplierID,
        accountID,
        pointInTime,
        skip?,
        top?,
        includePayments = true,
        includeDimensions = false,
    ) {
        let querystring: string = `&includeOpenPosts=${includeOpenPosts}&includeMarkedPosts=${includeMarkedPosts}`;

        if (customerID) {
            querystring += '&customerID=' + customerID;
        }

        if (supplierID) {
            querystring += '&supplierID=' + supplierID;
        }

        if (accountID) {
            querystring += '&accountID=' + accountID;
        }

        if (pointInTime) {
            querystring += '&pointInTime=' + pointInTime;
        }

        if (skip || skip === 0) {
            querystring += '&skip=' + skip;
        }

        if (top) {
            querystring += '&top=' + top;
        }

        if (!includePayments) {
            querystring += '&includepayments=false';
        }

        if (includeDimensions) {
            querystring += '&includeDimensionsInfo=true';
        } else {
            querystring += '&includeDimensionsInfo=false';
        }

        return this.http
            .asGET()
            .usingBusinessDomain()
            .withEndPoint(this.relativeURL + '?action=get-journal-entry-postpost-data' + querystring)
            .send()
            .pipe(map((response) => response.body));
    }

    public getStatusText = (statusCode: number) => {
        let text = '';
        this.StatusTypes.forEach((status) => {
            if (status.Code === statusCode) {
                text = status.Text;
                return;
            }
        });
        return text;
    };

    public getBalance(customerNumber: number) {
        return this.http
            .asGET()
            .usingStatisticsDomain()
            .withEndPoint(
                `?model=journalentryline&select=sum(amount) as Saldo&filter=subaccount.accountnumber eq ${customerNumber}&expand=subaccount`,
            )
            .send()
            .pipe(map((res) => res.body));
    }

    public getJournalEntryTypeBalance(
        customerNumber: number,
    ): Observable<[{ TypeID: number; TypeName: string; TypeDisplayName: string; Balance: number }]> {
        return this.http
            .asGET()
            .usingStatisticsDomain()
            .withEndPoint(
                `?model=journalentryline&select=journalentrytypeid as TypeID,journalentrytype.name as TypeName,journalentrytype.displayname as TypeDisplayName,sum(amount) as Balance&filter=subaccount.accountnumber eq ${customerNumber}&expand=subaccount,journalentrytype` +
                    '&wrap=false',
            )
            .send()
            .pipe(map((res) => res.body));
    }
}
