<button
    #button
    [attr.aria-describedby]="asideGuid"
    [ngClass]="field?.Options?.class || 'secondary'"
    [disabled]="readOnly$ | async"
    (click)="clickHandler($event)"
    (focus)="focusHandler()"
    type="button"
    [title]="field?.Label"
>
    {{ field?.Label }}
</button>
<ng-content></ng-content>
