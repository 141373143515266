<dropdown-menu [trigger]="triggerButton" [closeOnClick]="false" [closeOnTab]="false">
    <ng-template>
        <section class="dropdown flex-column" [class.period-only]="periodOnly">
            <section class="header flex align-center justify-between border-bottom">
                <section *ngIf="!periodOnly" class="flex align-center gap-2">
                    <date-range-input
                        label="Fra"
                        [autofocus]="true"
                        [hideLabel]="true"
                        [date]="fromDate"
                        (dateChange)="emitChange($event, toDate)"
                    >
                    </date-range-input>

                    <span class="divider border"></span>

                    <date-range-input
                        label="Til"
                        [hideLabel]="true"
                        [date]="toDate"
                        (dateChange)="emitChange(fromDate, $event)"
                    >
                    </date-range-input>
                </section>

                <span *ngIf="selectionText" class="selection-text flex align-center gap-1 text-small">
                    Valgt periode: <strong class="bg-info rounded">{{ selectionText }}</strong>
                </span>
            </section>

            <section class="flex overflow-hidden">
                <date-range-preset-options
                    class="border-right"
                    [fromDate]="fromDate"
                    [toDate]="toDate"
                    [periodOnly]="periodOnly"
                    (optionSelected)="emitChange($event.from, $event.to)"
                >
                </date-range-preset-options>

                <section *ngIf="periodOnly" class="month-pickers flex flex-1">
                    <date-range-month-picker
                        class="flex-1"
                        label="Fra måned"
                        [(date)]="fromDate"
                        (dateChange)="onFromMonthChange()"
                    >
                    </date-range-month-picker>

                    <date-range-month-picker
                        class="flex-1"
                        label="Til måned"
                        [(date)]="toDate"
                        (dateChange)="onToMonthChange()"
                    >
                    </date-range-month-picker>
                </section>

                <date-range-calendars
                    *ngIf="!periodOnly"
                    class="flex-1"
                    gap="2rem"
                    [fromDate]="fromDate"
                    [toDate]="toDate"
                    (fromDateChange)="emitChange($event, toDate)"
                    (toDateChange)="emitChange(fromDate, $event)"
                >
                </date-range-calendars>
            </section>
        </section>
    </ng-template>
</dropdown-menu>
