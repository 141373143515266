import {
    Component,
    Input,
    Output,
    EventEmitter,
    ChangeDetectionStrategy,
    ViewChild,
    SimpleChanges,
} from '@angular/core';
import { BaseControl } from '../baseControl';
import { UniFieldLayout } from '@uni-framework/ui/uniform/interfaces';
import { get, set } from 'lodash-es';
import { RigSwitch } from '@uni-framework/ui/design-system/switch/switch';
import { RigCheckbox } from '@uni-framework/ui/design-system/checkbox/checkbox';

@Component({
    selector: 'uni-checkbox-input',
    templateUrl: './checkbox.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UniCheckboxInput extends BaseControl {
    @ViewChild(RigSwitch) switch: RigSwitch;
    @ViewChild(RigCheckbox) checkbox: RigCheckbox;

    @Input() field: UniFieldLayout;
    @Input() model: any;

    @Output() readyEvent = new EventEmitter<UniCheckboxInput>();
    @Output() changeEvent = new EventEmitter<SimpleChanges>();

    public checked: boolean;

    constructor() {
        super();
    }

    public ngOnChanges() {
        if (this.model && this.field) {
            this.checked = !!get(this.model, this.field.Property);
        }
    }

    public focus() {
        if (this.checkbox) {
            this.checkbox.focus();
        }

        if (this.switch) {
            this.switch.focus();
        }

        return this;
    }

    public onChange() {
        set(this.model, this.field.Property, this.checked);

        this.emitChange(!this.checked, this.checked);
        this.emitInstantChange(!this.checked, this.checked, true);
    }
}
