import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PaymentBatchService } from '@app/services/accounting/paymentBatchService';
import { PaymentService } from '@app/services/accounting/paymentService';
import { ErrorService } from '@app/services/common/errorService';
import { IModalOptions, IUniModal } from '@uni-framework/uni-modal/interfaces';
import { THEMES, theme } from 'src/themes/theme';

@Component({
    selector: 'preapproved-payments-modal',
    templateUrl: './preapproved-payments-modal.html',
    styleUrls: ['./preapproved-payments-modal.sass'],
})
export class PreapprovedPaymentsModal implements OnInit, IUniModal {
    @Input() options: IModalOptions;
    @Output() onClose: EventEmitter<any> = new EventEmitter();

    paymentIDs = [];
    hash: string;
    hashFromAllPayments: string;
    isAll: boolean = false;
    busy = false;
    isRunningAsBackgroundJob = false;
    errorText: string = '';

    constructor(
        private paymentService: PaymentService,
        private paymentBatchService: PaymentBatchService,
        private errorServive: ErrorService,
    ) {}

    ngOnInit() {
        this.paymentIDs = this.options.data.paymentIDs;
        this.hash = this.options.data.hash;
        this.hashFromAllPayments = this.options.data.hashFromAllPayments;
        this.isAll = this.options.data.isAll;

        if (this.isAll && (!this.options.data.filter || !this.options.data.expand)) {
            this.close(false);
        }
    }

    sendPayments() {
        this.busy = true;
        this.errorText = '';
        const createFile = theme.theme === THEMES.EXT02; //atm only dnb uses pain001 files
        const obs = this.isAll
            ? this.paymentService.createPaymentBatchForAll(false, this.getParamsStringForAll(createFile))
            : this.paymentService.createPaymentBatchWithHash(
                  this.paymentIDs,
                  this.hash,
                  window.location.href,
                  createFile,
              );

        // Send that batch to the bank directly
        obs.subscribe(
            (result: any) => {
                if (result?.ProgressUrl) {
                    this.isRunningAsBackgroundJob = true;
                    this.paymentService.waitUntilJobCompleted(result.ID).subscribe(
                        (batchJobResponse) => {
                            if (
                                batchJobResponse &&
                                !batchJobResponse.HasError &&
                                batchJobResponse.Result &&
                                batchJobResponse.Result.ID > 0
                            ) {
                                this.paymentBatchService.redirectToBankID(batchJobResponse?.Result);
                            } else {
                                this.busy = false;
                            }
                        },
                        (err) => {
                            this.busy = false;
                        },
                    );
                } else {
                    this.paymentBatchService.redirectToBankID(result);
                }
            },
            (err) => {
                this.errorText = '* Klarte ikke opprette betalingsbunt.';
                this.errorServive.handle(err);
                this.busy = false;
            },
        );
    }

    getParamsStringForAll(createFile: boolean = false): string {
        return `&hash=${this.hashFromAllPayments}&filter=${this.options.data.filter}&expand=${this.options.data.expand}&createfile=${createFile}`;
    }

    close(value = false) {
        this.onClose.emit(value);
    }
}
