import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IModalOptions, IUniModal } from '@uni-framework/uni-modal';

@Component({
    selector: 'data-extraction-agreement-modal',
    templateUrl: './data-extraction-agreement-modal.html',
    styleUrls: ['./data-extraction-agreement-modal.sass'],
})
export class DNBDataExtractionAgreementModal implements IUniModal {
    options: IModalOptions;
    onClose = new EventEmitter();

    constructor() {}
}
