<button class="selector-button input-style inline-flex align-center" #toggle aria-label="Vis periodevelger">
    <span class="flex-1 mr-2" [class.placeholder]="!inputLabel">
        {{ inputLabel || 'Velg periode' }}
    </span>
    <uni-icon icon="calendar" [svgSize]="22" [matIconSize]="22"></uni-icon>
</button>

<date-range-dropdown-simple
    *ngIf="useSimpleLayout"
    [triggerButton]="toggle"
    [buttons]="buttons"
    [selectionText]="selectionText"
    [fromDate]="fromDate"
    [toDate]="toDate"
    (change)="setDates($event.from, $event.to, true)"
>
</date-range-dropdown-simple>

<date-range-dropdown
    *ngIf="!useSimpleLayout"
    [triggerButton]="toggle"
    [periodOnly]="periodOnly"
    [selectionText]="selectionText"
    [fromDate]="fromDate"
    [toDate]="toDate"
    (change)="setDates($event.from, $event.to, true)"
>
</date-range-dropdown>
