<section role="dialog" class="uni-redesign uni-modal uni-comment-modal" style="width: 62ch">
    <header>{{ options?.data.header || 'Skriv en kommentar' }}</header>
    <article>
        <uni-alert-component
            *ngIf="options?.data?.alert"
            [isOpen]="true"
            [type]="options?.data?.alert?.type || 'info'"
            [icon]="options?.data?.alert?.icon || 'error'"
            [title]="options?.data?.alert?.title"
            [text]="options?.data?.alert?.text"
        ></uni-alert-component>
        <p *ngIf="options?.data.description">
            {{ options?.data.description }}
        </p>
        <textarea [(ngModel)]="comment" [readonly]="readonly"></textarea>
    </article>
    <footer>
        <button class="secondary" (click)="cancel()">Avbryt</button>
        <button class="c2a" (click)="accept()" [disabled]="readonly">Legg til kommentar</button>
    </footer>
</section>
