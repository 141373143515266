<section class="item-container flex align-center gap-1">
    <section class="logical-operator">
        <select *ngIf="!hideLogicalOperator" [(ngModel)]="item.logicalOperator" (ngModelChange)="itemChange.emit(item)">
            <option value="and">og</option>
            <option value="or">eller</option>
        </select>
    </section>

    <select class="field-select" [(ngModel)]="selectedField" (ngModelChange)="onFieldChange()" placeholder="Felt">
        <option *ngFor="let field of fields" [ngValue]="field">
            {{ field.label }}
        </option>
    </select>

    <select class="operator-select" [(ngModel)]="item.operator" (ngModelChange)="onOperatorChange()">
        <option *ngFor="let operator of availableOperators" [ngValue]="operator.operator">
            {{ operator.label }}
        </option>
    </select>

    <ng-container *ngIf="!valueFieldDisabled">
        <ng-container [ngSwitch]="selectedField?.type">
            <uni-datepicker *ngSwitchCase="'date'" [(value)]="item.value" (valueChange)="itemChange.emit(item)">
            </uni-datepicker>

            <select *ngSwitchCase="'boolean'" [(ngModel)]="item.value" (ngModelChange)="itemChange.emit(item)">
                <option value="true">Sant</option>
                <option value="false">Usant</option>
            </select>

            <select *ngSwitchCase="'select'" [(ngModel)]="item.value" (ngModelChange)="itemChange.emit(item)">
                <option
                    *ngFor="let option of selectedField?.selectConfig?.options"
                    [ngValue]="option[selectedField.selectConfig.valueField]"
                >
                    {{ option[selectedField.selectConfig.displayField] }}
                </option>
            </select>

            <input
                *ngSwitchDefault
                [(ngModel)]="item.value"
                (ngModelChange)="itemChange.emit(item)"
                type="text"
                placeholder="Verdi"
            />
        </ng-container>
    </ng-container>

    <!-- User selected an operator that doesn't need a value -->
    <section *ngIf="valueFieldDisabled"></section>

    <section class="actions flex justify-end">
        <button
            class="icon-button"
            *ngIf="siblingMaxDepth && siblingDepth < siblingMaxDepth"
            (click)="addSibling('or')"
        >
            <i class="material-icons">add</i>
        </button>

        <button class="icon-button" (click)="remove.emit()">
            <i class="material-icons">delete_outline</i>
        </button>
    </section>
</section>

<query-item
    class="sibling"
    *ngFor="let sibling of item.siblings; let idx = index"
    [(item)]="item.siblings[idx]"
    [fields]="fields"
    [operatorResolver]="operatorResolver"
    [siblingDepth]="siblingDepth + 1"
    [siblingMaxDepth]="siblingMaxDepth"
    (remove)="removeSibling(idx)"
    (itemChange)="itemChange.emit(item)"
>
</query-item>
