import { Pipe, PipeTransform } from '@angular/core';
import { ContractType } from '@app/models';
import { theme, THEMES } from 'src/themes/theme';

@Pipe({ name: 'elsaContractType' })
export class ElsaContractTypePipe implements PipeTransform {
    transform(value: ContractType): string {
        switch (value) {
            case ContractType.Demo:
                return 'Demo';
            case ContractType.Internal:
                return 'Intern';
            case ContractType.Partner:
                return 'Partner';
            case ContractType.Pilot:
                return 'Pilot';
            case ContractType.Training:
                return 'Training';
            case ContractType.Standard:
                return 'Standard';
            case ContractType.Bureau:
                return 'Byrå';
            case ContractType.NonProfit:
                return 'Non-profit';
            case ContractType.BureauAnnual:
                return 'Årskunde';
            case ContractType.BureauInvoiceApproval:
                return 'Fakturagodkjenning';
            case ContractType.Level0:
                return 'Level 0';
            case ContractType.Mini:
                return theme.theme === THEMES.SR ? 'Nivå 1' : 'Mini';
            case ContractType.Plus:
                return theme.theme === THEMES.SR ? 'Nivå 2' : 'Pluss';
            case ContractType.Complete:
                return theme.theme === THEMES.SR ? 'Nivå 3' : 'Komplett';
            case ContractType.Level4:
                return 'Level 4';
            case ContractType.Level5:
                return 'Level 5';
            case ContractType.Level6:
                return 'Level 6';
            case ContractType.Level7:
                return 'Level 7';
            case ContractType.Level8:
                return 'Level 8';
            case ContractType.Level9:
                return 'Level 9';
            default:
                return '';
        }
    }
}
