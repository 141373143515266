import { Component, EventEmitter } from '@angular/core';
import { forkJoin, from, of } from 'rxjs';
import { uniqBy } from 'lodash-es';
import { IModalOptions, IUniModal } from '@uni-framework/uni-modal/interfaces';
import {
    User,
    Task,
    Approval,
    TaskApprovalPlan,
    ApprovalStatus,
    ApprovalSubstitute,
    PaymentBatch,
    ApprovalType,
} from '@app/unientities';
import { ConfirmActions, ConfirmTwoFactorModal } from '@uni-framework/uni-modal';
import { switchMap } from 'rxjs/operators';
import { SupplierInvoiceService } from '@app/services/accounting/supplierInvoiceService';
import { ApprovalService } from '@app/services/assignments/approvalService';
import { ApprovalSubstituteService } from '@app/services/common/approvalSubstituteService';
import { CommentService } from '@app/services/common/commentService';
import { ErrorService } from '@app/services/common/errorService';
import { UserService } from '@app/services/common/userService';
import { ZDataPaymentService } from '@app/services/bank/ZDataPaymentService';
import { PaymentBatchService } from '@app/services/accounting/paymentBatchService';
import { ApprovalDetailItem } from '@app/components/common/approval-detail/approval-detail';
import { rigDate } from '@app/components/common/utils/rig-date';
import { THEMES, theme } from 'src/themes/theme';

@Component({
    selector: 'invoice-approval-modal',
    templateUrl: './invoice-approval-modal.html',
})
export class InvoiceApprovalModal implements IUniModal {
    options: IModalOptions = {};
    onClose = new EventEmitter<boolean>();

    busy: boolean;
    canApprove: boolean;
    approvalStatus = ApprovalStatus; // for use in template
    useSmS2FA: boolean = false;
    useBankID2FA: boolean = false;
    isBankIDredirect: boolean = false;

    action: 'approve' | 'reject' | 'viewHistoricApproval';
    users: User[];
    entityID: number;
    entityType: string;
    task: Task;

    comment: string;
    commentMissing: boolean;
    modalService;

    approvalToDisplay: ApprovalDetailItem;
    currentUsersApproval: Approval;

    constructor(
        private errorService: ErrorService,
        private userService: UserService,
        private approvalService: ApprovalService,
        private commentService: CommentService,
        private substituteService: ApprovalSubstituteService,
        private invoiceService: SupplierInvoiceService,
        private ZDataPaymentService: ZDataPaymentService,
        private PaymentBatchService: PaymentBatchService,
    ) {}

    ngOnInit() {
        this.busy = true;
        const data = this.options.data || {};
        this.action = data.action;
        this.task = data.task;
        this.entityType = data.entityType;
        this.useBankID2FA = data.useBankID2FA;
        this.useSmS2FA = data.useSmS2FA;
        this.modalService = data.modalService;
        this.isBankIDredirect = data.isBankIDredirect;

        this.entityID = this.task.EntityID;

        const requests = [
            this.userService.getCurrentUser(),
            this.userService.getActiveUsers(),
            this.substituteService.getActiveSubstitutes(),
        ];

        if (this.entityType === 'SupplierInvoice' && this.entityID) {
            requests.push(this.invoiceService.Get(this.entityID));
        }

        forkJoin(requests).subscribe(
            (res: any[]) => {
                this.busy = false;
                const currentUser = res[0];
                this.users = res[1];

                const substitutes: ApprovalSubstitute[] = res[2] || [];
                const invoice = res[3];

                if (this.task) {
                    const approvals = this.task.Approvals || [];
                    const approvalPlans = (this.task.ApprovalPlan || [])
                        .filter((plan) => invoice && invoice.TaxInclusiveAmount >= plan.Limit)
                        .sort((a, b) => a.StepNumber - b.StepNumber);

                    // Merge approvals and approvalPlan, remove duplicates (by UserID)
                    const merged = uniqBy([...approvals, ...approvalPlans], (item) => item.UserID);

                    const steps: { [step: number]: Array<Approval | TaskApprovalPlan> } = merged.reduce(
                        (groups, approvalOrPlan) => {
                            let stepNumber = approvalOrPlan['StepNumber'];

                            if (!stepNumber) {
                                // approvalOrPlan is an Approval.
                                // Check if there is a plan with the same UserID created before the approval.
                                // If there is, use the StepNumber from the plan, otherwise use 1.
                                const plan = approvalPlans.find(
                                    (p) =>
                                        p.UserID === approvalOrPlan.UserID &&
                                        rigDate(approvalOrPlan.CreatedAt).isAfter(p.CreatedAt),
                                );
                                stepNumber = plan?.StepNumber ?? 1;
                            }

                            groups[stepNumber] ??= [];
                            groups[stepNumber].push(approvalOrPlan);
                            return groups;
                        },
                        {},
                    );

                    this.approvalToDisplay = {
                        steps: Object.values(steps).map((approvalStep, index) => ({
                            approvalType: approvalStep[0]?.ApprovalType as ApprovalType,
                            stepNumber: index + 1, // stepNumber may be wrong in reality, because of duplicates
                            users: approvalStep.map((approvalOrPlan) => {
                                const user = this.users.find((u) => u.ID === approvalOrPlan.UserID);
                                return {
                                    displayName: user ? user.DisplayName || user.Email : 'Ukjent bruker',
                                    ID: approvalOrPlan.UserID,
                                    approvalStatusCode: approvalOrPlan.StatusCode,
                                    updatedAt: approvalOrPlan.UpdatedAt,
                                };
                            }),
                        })),
                    };

                    const substituteFor = substitutes
                        .filter((sub) => sub.SubstituteUserID === currentUser.ID)
                        .map((sub) => sub.UserID);

                    this.currentUsersApproval = approvals.find((a) => {
                        if (a.StatusCode !== ApprovalStatus.Active) {
                            return false;
                        }

                        return a.UserID === currentUser.ID || substituteFor.some((id) => id === a.UserID);
                    });
                }

                if (this.isBankIDredirect) {
                    this.submit();
                }
            },
            (err) => {
                this.errorService.handle(err);
                this.onClose.emit();
            },
        );
    }

    submit() {
        this.commentMissing = this.action === 'reject' && !this.comment;
        if (!this.commentMissing && this.currentUsersApproval) {
            this.busy = true;

            const obsSms2fa =
                this.action === 'approve' && this.useSmS2FA
                    ? from(
                          this.ZDataPaymentService.redirectIfNotVerifiedWithBankId(
                              `batchID=${this.task.EntityID}&method=approve`,
                          ),
                      ).pipe(
                          switchMap(() => {
                              return this.modalService
                                  .open(ConfirmTwoFactorModal, {
                                      header: 'Godkjenn utbetaling',
                                      closeOnClickOutside: false,
                                  })
                                  .onClose.pipe(
                                      switchMap((res) => {
                                          return of(res === ConfirmActions.ACCEPT);
                                      }),
                                  );
                          }),
                      )
                    : of(true);

            if (this.action === 'approve' && this.useBankID2FA) {
                if (theme.theme === THEMES.EIKA) {
                    //check aritma bank id
                    this.ZDataPaymentService.redirectIfNotVerifiedWithBankId(
                        `batchID=${this.task.EntityID}&method=approve`,
                    )
                        .then(() => {
                            this.PaymentBatchService.Get(this.task.EntityID).subscribe((paymentBatch: PaymentBatch) => {
                                this.PaymentBatchService.redirectToBankID(paymentBatch, window.location.href);
                            });
                        })
                        .catch(() => {});
                } else {
                    this.PaymentBatchService.Get(this.task.EntityID).subscribe((paymentBatch: PaymentBatch) => {
                        this.PaymentBatchService.redirectToBankID(paymentBatch, window.location.href);
                    });
                }
            } else {
                obsSms2fa.subscribe((response) => {
                    if (response) {
                        this.approvalService.PostAction(this.currentUsersApproval.ID, this.action).subscribe(
                            () => {
                                if (this.comment) {
                                    this.commentService.post(this.entityType, this.entityID, this.comment).subscribe(
                                        () => this.onClose.emit(true),
                                        () => this.onClose.emit(true),
                                    );
                                } else {
                                    this.onClose.emit(true);
                                }
                            },
                            (err) => {
                                this.errorService.handle(err);
                                this.busy = false;
                            },
                        );
                    } else {
                        this.busy = false;
                    }
                });
            }
        }
    }
}
