<section class="approval-rule border flex-column w-full" *ngIf="item">
    <!-- COMPACT & DEFAULT VIEW  -->
    <section
        *ngIf="compactView || !!item?.name || itemActions?.length"
        class="rule-name flex flex-row w-100"
        [ngClass]="!compactView ? 'mb-2' : 'mb-0'"
    >
        <span *ngIf="!!item?.name">{{ item?.name }}</span>

        <section class="ml-auto flex flex-row" *ngIf="itemActions?.length">
            <ng-container *ngFor="let action of itemActions">
                <button class="unstyled action-item" [attr.aria-busy]="item?.busyWith?.[action?.actionIdentifier]">
                    <span
                        [class]="action.iconType"
                        [class.mb-2]="compactView"
                        (click)="emitChange(action.actionIdentifier, item)"
                    >
                        {{ action.icon }}
                    </span>
                </button>
            </ng-container>
        </section>
    </section>
    <!-- COMPACT VIEW ENDS -->

    <!-- default view -->
    <ng-container *ngIf="!compactView">
        <ng-container *ngFor="let step of item.steps">
            <section class="rule-step-container flex flex-row w-100">
                <span class="rule-step-number mr-4" *ngIf="!omitSteps">
                    <span class="flex justify-center align-center">{{ step.stepNumber }}</span>
                </span>

                <section class="rule-step-details flex flex-row w-100 text-small">
                    <section class="flex-column w-100">
                        <div class="flex flex-row" *ngIf="!omitSteps">
                            <span class="step-count">Steg {{ step.stepNumber }}:&nbsp;</span>
                            <ng-container *ngIf="!!step.limit && step.limit != 0; else StepHasNoLimit">
                                <span class="step-approvers-type whitespace-nowrap">Godkjenner ved beløp</span>
                            </ng-container>
                            <ng-template #StepHasNoLimit>
                                <span class="step-approvers-type whitespace-nowrap">{{
                                    step.approvalType === 1 ? 'Alle må godkjenne' : 'Kun en må godkjenne'
                                }}</span>
                            </ng-template>
                        </div>

                        <section class="flex mb-2" *ngIf="!approvalMode">
                            <ng-container *ngIf="step?.users?.length">
                                <span>
                                    <ng-container *ngFor="let stepUser of step.users; let isLast = last">
                                        {{ stepUser?.DisplayName || stepUser?.UserName }}{{ !isLast ? ', ' : '' }}
                                    </ng-container>
                                </span>
                            </ng-container>

                            <span class="uni-chip info ml-auto" *ngIf="step.limit !== 0">
                                >
                                {{ step.limit | uninumberformat: 'rounded' }}
                            </span>
                        </section>

                        <section class="flex-column w-100 h-100 mb-2" *ngIf="approvalMode">
                            <section
                                class="flex flex-row h-100 step-approver"
                                *ngFor="let approval of step.users; let index = index"
                            >
                                <span class="user flex-1 ellipsis" [title]="approval.displayName">{{
                                    approval.displayName
                                }}</span>

                                <span
                                    class="flex user-date ml-auto mr-1"
                                    *ngIf="
                                        approval?.approvalStatusCode === approvalStatus.Approved ||
                                        approval?.approvalStatusCode === approvalStatus.Rejected
                                    "
                                >
                                    {{ approval.updatedAt | date: 'dd.MM.yyyy HH:mm' }}
                                </span>

                                <ng-container [ngSwitch]="approval.approvalStatusCode">
                                    <span *ngSwitchCase="approvalStatus.Active" class="uni-chip warn ml-auto">
                                        <i class="material-icons-outlined">access_time</i>
                                        Venter
                                    </span>

                                    <span *ngSwitchCase="approvalStatus.Approved" class="uni-chip good ml-auto">
                                        <i class="material-icons-outlined">check_circle</i>
                                        Godkjent
                                    </span>

                                    <span *ngSwitchCase="approvalStatus.Rejected" class="uni-chip bad ml-auto">
                                        <i class="material-icons-outlined">error_outline</i>
                                        Avvist
                                    </span>

                                    <span *ngSwitchCase="approvalStatus.IndirectlyApproved"></span>

                                    <span *ngSwitchDefault class="uni-chip ml-auto">
                                        <i class="material-icons-outlined">check_circle</i>
                                        Ikke tildelt enda
                                    </span>
                                </ng-container>
                            </section>
                        </section>
                    </section>
                </section>
            </section>
        </ng-container>
    </ng-container>
</section>
