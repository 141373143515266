import { Injectable } from '@angular/core';
import { BizHttp } from '../../../framework/core/http/BizHttp';
import {
    BankIntegrationAgreement,
    Payment,
    PreApprovedBankPayments,
    StatusCodeBankIntegrationAgreement,
    StatusCodeJournalEntryLine,
} from '../../unientities';
import { UniHttp } from '../../../framework/core/http/http';
import { Observable, of } from 'rxjs';
import { THEMES, theme } from 'src/themes/theme';
import { HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { RequestMethod } from '@uni-framework/core/http';
import { StatisticsService } from '../common/statisticsService';
import { BankAgreementServiceProvider } from '@app/models/autobank-models';

@Injectable({ providedIn: 'root' })
export class PaymentService extends BizHttp<Payment> {
    constructor(
        http: UniHttp,
        private statisticsService: StatisticsService,
    ) {
        super(http);
        this.relativeURL = Payment.RelativeUrl;
        this.entityType = Payment.EntityType;
        this.DefaultOrderBy = null;
    }

    createPaymentWithTracelink(payment: Payment, journalEntryID: number) {
        return this.ActionWithBody(
            null,
            payment,
            'create-payment-with-tracelink',
            RequestMethod.Post,
            'journalEntryID=' + journalEntryID,
        );
    }

    createPaymentsFromJournalEntryIDs(body): Observable<any> {
        return this.ActionWithBody(null, body, 'create-customer-payback-payments', RequestMethod.Post);
    }

    checkForDuplicatePayments(ids: number[]): Observable<any> {
        return this.ActionWithBody(null, ids, 'check-existing-customer-payback-payments', RequestMethod.Post);
    }

    public createPaymentBatchForAll(isManual: boolean = false, hashAndFilter: string = '') {
        return super.PostAction(
            null,
            'create-payment-batch-for-all-payments',
            `acceptjob=true&isManual=${isManual}${hashAndFilter}`,
        );
    }

    public createPaymentBatch(
        paymentIDs: Array<number>,
        isManual: boolean = false,
        skipNegativePayeeValidation: boolean = false,
    ): Observable<any> {
        super.invalidateCache();
        return this.http
            .asPOST()
            .usingBusinessDomain()
            .withBody(paymentIDs)
            .withEndPoint(
                this.relativeURL +
                    `?action=create-payment-batch&isManual=${isManual}&skipNegativePayeeValidation=${skipNegativePayeeValidation}`,
            )
            .send()
            .pipe(map((response) => response.body));
    }

    public createPaymentBatchWithHash(
        paymentIDs: Array<number>,
        hash: string,
        url: string,
        createFile: boolean,
    ): Observable<any> {
        super.invalidateCache();
        return this.http
            .asPOST()
            .usingBusinessDomain()
            .withBody(paymentIDs)
            .withEndPoint(
                this.relativeURL +
                    `?action=create-payment-batch&ismanual=false&createPaymentFile=${createFile}&hash=${hash}`,
            )
            .send()
            .pipe(map((response) => response.body));
    }

    public getHashForPayments(filter: string, expand: string) {
        super.invalidateCache();

        const params = new HttpParams().set('filter', filter).set('expand', expand);

        return this.http
            .asGET()
            .usingBusinessDomain()
            .withEndPoint(this.relativeURL + `?action=create-hash-for-payments`)
            .send({}, params)
            .pipe(map((response) => response.body));
    }

    public supportsBankIDTwoFactor(agreements: BankIntegrationAgreement[]): boolean {
        return (
            !!agreements?.some(
                (a) =>
                    a.ServiceProvider === BankAgreementServiceProvider.Bruno &&
                    a.StatusCode === StatusCodeBankIntegrationAgreement.Active &&
                    (a.PreApprovedBankPayments === PreApprovedBankPayments.Active ||
                        a.PreApprovedBankPayments === PreApprovedBankPayments.WaitForBankCancel),
            ) ||
            (theme.theme === THEMES.EIKA &&
                !!agreements?.some(
                    (a) =>
                        a.ServiceProvider === BankAgreementServiceProvider.ZdataV3 &&
                        a.StatusCode === StatusCodeBankIntegrationAgreement.Active &&
                        (a.PreApprovedBankPayments === PreApprovedBankPayments.Active ||
                            a.PreApprovedBankPayments === PreApprovedBankPayments.WaitForBankCancel),
                ))
        );
    }

    public getStatusText(statusCode: number): string {
        switch (statusCode) {
            case 44001:
                return 'Opprettet';
            case 44002:
                return theme.theme === THEMES.EXT02 ? 'Mottatt av bank' : 'Overført bank';
            case 44003:
            case 44012:
                return 'Feilet';
            case 44004:
                return 'Fullført';
            case 44005:
                return 'Betalingsdata generert';
            case 44006:
                return 'Betalt';
            case 44007:
                return theme.theme === THEMES.EXT02 ? 'Sendt til bank' : 'Fil mottatt av Aritma';
            case 44008:
                return 'I bankens forfallsregister';
            case 44009:
                return 'I bankens forfallsregister, venter på godkjenning fra banken';
            case 44010:
                return 'Avvist av Aritma';
            case 44011:
                return 'Manuelt overført bank';
            case 44013:
                return 'Kommunikasjonsfeil. Vennligst dobbelsjekk i nettbanken';
            case 44014:
                return 'Kansellert';
            case 44015:
                return 'Avventer godkjenning';
            case 44016:
                return theme.theme === THEMES.EXT02 ? 'Dobbel godkjent' : 'Godkjent i nettbanken';
            case 44017:
                return 'Avvist av godkjenner';
            case 44018:
                return 'Ingen match';
            case 44019:
                return 'Slette forespørsel';
            case 44020:
                return 'Skjult';
            case 44021:
                return theme.theme === THEMES.EXT02 ? 'Avvist av BankID' : 'Ikke godkjent totrinnsbekreftelse (SMS)';
            case 44022:
                return 'Mottatt av Nets/MC';
            case 44023:
                return 'Akseptert av Nets/MC';
            case 44024:
                return 'Avvist av Nets/MC';
            case 44025:
                return theme.theme === THEMES.EXT02 ? 'Klar for sending til bank' : 'Sendes til Aritma';
            case 44026:
                return theme.theme === THEMES.EXT02 ? 'Sendes til banken' : 'Sendt til Aritma';
            case 44027:
                return 'Behandler';
            default:
                return 'Ukjent status: ' + statusCode;
        }
    }

    public cancelPaymentClaim(ids: number[]) {
        return this.http
            .asPUT()
            .usingBusinessDomain()
            .withEndPoint('/payments?action=batch-cancel-payment-claims')
            .withBody(ids)
            .send()
            .pipe(map((response) => response.body));
    }

    public updatePaymentsToIgnore(paymentIDs: number[]) {
        super.invalidateCache();
        return this.http
            .asPUT()
            .usingBusinessDomain()
            .withBody(paymentIDs)
            .withEndPoint('payments?action=update-payments-to-ignored')
            .send()
            .pipe(map((response) => response.body));
    }

    public isInTransfer(payment: Payment): boolean {
        if (payment.StatusCode === null) {
            return false;
        }

        const transferedStatuses = [44011, 44002, 44007, 44008, 44009, 44015, 44013];
        return transferedStatuses.includes(payment.StatusCode);
    }

    public isPaidManually(payment: Payment): boolean {
        if (payment.StatusCode === null) {
            return false;
        }

        return payment.StatusCode === 44004 && payment.PaymentBatchID === null;
    }

    public markPaymentsAsCredited(payments: any[]) {
        const journaledPayments = payments.filter((payment) => payment?.JournalEntryID !== null);

        if (!journaledPayments.length) {
            return of(payments);
        }

        const select = 'JournalEntryID as JournalEntryID,StatusCode as StatusCode';
        const filter = `JournalEntryID in (${journaledPayments.map((x) => x.JournalEntryID)})`;
        const query = `model=JournalEntryLine&select=${select}&filter=${filter}`;

        return this.statisticsService.GetAllUnwrapped(query).pipe(
            map((res) => {
                return payments.map((payment) => {
                    const isPaymentCredited = res.some(
                        (x) =>
                            x.JournalEntryID === payment.JournalEntryID &&
                            x.StatusCode === StatusCodeJournalEntryLine.Credited,
                    );
                    payment['_isCredited'] = isPaymentCredited;
                    return payment;
                });
            }),
        );
    }
}
