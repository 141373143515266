<!-- Has agreement permission -->
<section *ngIf="canAgreeToLicense" role="dialog" class="uni-modal has-agreement-permission">
    <section *ngIf="busy" class="modal-spinner">
        <mat-spinner class="c2a"></mat-spinner>
    </section>

    <header>
        Lisensavtale
        <a *ngIf="agreementUrl" [href]="agreementUrl" target="_blank" rel="noopener noreferrer"> Åpne i ny fane </a>
    </header>

    <object *ngIf="agreementUrl" [data]="agreementUrl" type="application/pdf">
        alt : <a [href]="agreementUrl">License.pdf</a>
    </object>

    <footer>
        <rig-checkbox class="pull-left" [(ngModel)]="hasAgreed">Godta lisensavtale</rig-checkbox>

        <button class="secondary" (click)="logout()">Logg ut og bytt selskap</button>
        <button class="c2a" (click)="confirmAgreement()" [disabled]="!hasAgreed">Bekreft</button>
    </footer>
</section>

<!-- Does not have agreement permission -->
<section *ngIf="!canAgreeToLicense" role="dialog" class="uni-modal missing-agreement-permission">
    <header>Lisensavtale</header>

    <article>
        Lisensavtale for {{ customer?.Name }} må godkjennes for at hele systemet skal låses opp. <br />Dette må gjøres
        av en lisensadministrator.

        <section *ngIf="customer?.Managers?.length === 0" style="margin-top: 2rem">
            Vi fant ingen administratorer på kundeavtalen. Vennligst kontakt
            <a [href]="supportPageUrl" target="_blank">support</a>.
        </section>

        <ul>
            <li *ngFor="let manager of customer?.Managers">
                <span>{{ manager.User?.Name || '-' }}</span>
                <a *ngIf="manager.User?.Email" href="mailto:{{ manager.User.Email }}">
                    {{ manager.User.Email }}
                </a>
            </li>
        </ul>
    </article>

    <footer>
        <button (click)="onClose.emit()" class="secondary">Lukk</button>
    </footer>
</section>
