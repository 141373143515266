import { Injectable } from '@angular/core';
import { BizHttp } from '../../../framework/core/http/BizHttp';
import { PriceDealItem, Product, VatType } from '../../unientities';
import { UniHttp } from '../../../framework/core/http/http';
import { Observable, of } from 'rxjs';
import { StatisticsService } from './statisticsService';
import { RequestMethod } from '@uni-framework/core/http';

@Injectable({ providedIn: 'root' })
export class ProductService extends BizHttp<Product> {
    defaultItemsTableExpands = [
        'Account',
        'Account.MandatoryDimensions',
        'Dimensions.Info',
        'Dimensions.Project',
        'Dimensions.Department',
    ];

    constructor(
        http: UniHttp,
        private statisticsService: StatisticsService,
    ) {
        super(http);

        this.relativeURL = Product.RelativeUrl;
        this.entityType = Product.EntityType;
        this.DefaultOrderBy = 'PartName';
    }

    public calculatePriceLocal(product: Product, vattypes: VatType[]): Product {
        if (product.VatType) {
            const vatTypeFromList = vattypes.find((item) => item.VatCode === product.VatType.VatCode);
            const vatPercent = product.VatType.VatPercent || (vatTypeFromList && vatTypeFromList.VatPercent);
            if (product.CalculateGrossPriceBasedOnNetPrice) {
                product.PriceExVat = product.PriceIncVat / ((100 + vatPercent || 100) / 100);
            } else {
                product.PriceIncVat = (product.PriceExVat * (100 + vatPercent || 100)) / 100;
            }
        } else {
            if (product.CalculateGrossPriceBasedOnNetPrice) {
                product.PriceExVat = product.PriceIncVat;
            } else {
                product.PriceIncVat = product.PriceExVat;
            }
        }

        return product;
    }

    public getNewPartname(): Observable<string> {
        return super.GetAction(null, 'getnewpartname');
    }

    public isProductUsed(id: number): Observable<boolean> {
        return super.GetAction(id, 'is-used');
    }

    public getBestPriceForProduct(customerId: number, productId: number, date = null): Observable<PriceDealItem[]> {
        return this.GetAction(
            productId,
            'get-best-price',
            `&customerid=${customerId}${date ? `&date=${date}` : ''}&expand=PriceDeal`,
        );
    }

    public getBestPriceForProducts(
        customerId: number,
        productIDs: number[],
        date: any = '',
    ): Observable<PriceDealItem[]> {
        return this.ActionWithBody(
            null,
            productIDs,
            'get-best-price',
            RequestMethod.Put,
            `customerId=${customerId}&date=${date}`,
        );
    }

    getAutocompleteConfig(valueProperty = 'ID') {
        let odata = `model=Product&select=ID as ID,Name as Name,PartName as PartName`;

        return {
            getDefaultData: (modelValue) => {
                if (modelValue) {
                    const filter = `${valueProperty} eq ${modelValue}`;
                    return this.statisticsService.GetAllUnwrapped(odata + `&filter=${filter}&top=1`);
                } else {
                    return of([]);
                }
            },
            template: (item) => item && `${item.PartName} - ${item.Name}`,
            search: (query: string) => {
                const filter = query ? `&filter=contains(Name,'${query}') or contains(PartName,'${query}')` : '';
                return this.statisticsService.GetAllUnwrapped(odata + `${filter}&top=30`);
            },
            valueProperty: valueProperty,
            debounceTime: 200,
        };
    }
}
