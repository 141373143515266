<div (clickOutside)="onClickOutside()">
    <section class="input-with-button">
        <input
            #inputElement
            type="text"
            autocomplete="false"
            [tabindex]="(readOnly$ | async) ? '-1' : '0'"
            [formControl]="control"
            [readonly]="readOnly$ | async"
            [placeholder]="field?.Placeholder || ''"
            [title]="control?.value || ''"
            (focus)="focusHandler()"
            (click)="focusAndSelect()"
        />
        <button type="button" tabIndex="-1" [disabled]="readOnly$ | async" (click)="onSearchButtonClick()">
            <i class="material-icons">search</i>
        </button>
    </section>

    <input-dropdown-menu [input]="inputElement" [visible]="isExpanded$ | async">
        <ng-template>
            <section class="result-container" #dropdown>
                <!-- List view -->
                <section class="results" *ngIf="!options?.resultTableConfig">
                    <ng-container *ngFor="let item of lookupResults; let idx = index">
                        <section class="dropdown-menu-header" *ngIf="item.isHeader">
                            {{ item.header }}
                        </section>

                        <section
                            class="dropdown-menu-item"
                            *ngIf="!item.isHeader"
                            (click)="confirmSelection(item)"
                            [attr.aria-selected]="selectedIndex === idx"
                        >
                            {{ template(item) }}
                        </section>
                    </ng-container>
                </section>

                <!-- Table view -->
                <section class="results" *ngIf="options?.resultTableConfig && lookupResults?.length">
                    <table>
                        <thead>
                            <tr>
                                <th
                                    *ngFor="let field of options.resultTableConfig.fields"
                                    [ngStyle]="{ width: field.width, textAlign: field.isMoneyField ? 'right' : 'left' }"
                                >
                                    {{ field.header }}
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr
                                *ngFor="let item of lookupResults; let idx = index"
                                [attr.aria-selected]="selectedIndex === idx"
                                (click)="confirmSelection(item)"
                            >
                                <td
                                    *ngFor="let field of options.resultTableConfig.fields"
                                    [ngStyle]="{ width: field.width, textAlign: field.isMoneyField ? 'right' : 'left' }"
                                    [ngClass]="field.class"
                                >
                                    <span *ngIf="!field.isMoneyField">
                                        {{ item[field.key] }}
                                    </span>
                                    <span *ngIf="field.isMoneyField">
                                        {{ item[field.key] | uninumberformat: 'money' }}
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </section>
            </section>
        </ng-template>
    </input-dropdown-menu>
</div>
