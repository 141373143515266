import { Component, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ColumnButton } from '../config/unitableColumn';

@Component({
    selector: 'unitable-button',
    template: `
        @if (button) {
            <input
                #inputElement
                type="button"
                [value]="button.label"
                [class]="button.class"
                (click)="button.action($event)"
                (keydown.enter)="$event.stopPropagation()"
                [style.width]="button.width"
            />
        }
    `,
    styles: `
        :host {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: calc(100% - 2px);
            margin: 0 1px;
            background: #fff;
            border-radius: var(--input-radius);
        }
    `,
})
export class UnitableButton {
    @ViewChild('inputElement') inputElement: ElementRef;

    @Input() inputControl: UntypedFormControl;
    @Input() column: any;
    @Input() rowModel: any;

    @Output() close: EventEmitter<any> = new EventEmitter();

    button: ColumnButton;

    ngOnInit() {
        const buttonResolver = this.column.get('buttonResolver');
        if (buttonResolver) {
            this.button = buttonResolver(this.rowModel);
        }
    }

    getValue() {
        return undefined;
    }

    onBlur() {
        this.close.emit(undefined);
    }
}
