import { Component, Input, Output, EventEmitter, ElementRef, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { UniTableColumn } from './config/unitableColumn';
import { UniTableConfig } from './config/unitableConfig';
import { UniTablePipe } from './unitablePipe';
import { IContextMenuItem } from './unitable';
import { FeaturePermissionService } from '@app/featurePermissionService';

export interface IRowModelChangeEvent {
    field: string;
    newValue: any;
    rowModel: Immutable.Map<string, any>;
    triggeredByOtherEvent?: boolean;
    copyEvent?: boolean;
}

@Component({
    selector: '[unitable-row]',
    template: `
        <td *ngIf="config?.multiRowSelect" tabindex="-1">
            <input
                type="checkbox"
                #rowSelector
                [checked]="rowModel.get('_rowSelected')"
                (change)="onRowSelectionChanged(rowSelector.checked)"
            />
        </td>

        <td
            #rowColumn
            *ngFor="let column of columns"
            bind-hidden="!column.get('visible')"
            bind-class="column.get('cls')"
            [ngClass]="column.get('conditionalCls') && column.get('conditionalCls')(rowModel.toJS())"
            [ngStyle]="{ 'text-align': column.get('alignment') || 'left' }"
            (focus)="onCellFocus($event, column)"
            (click)="onCellClick($event, column)"
            [tabindex]="getTabIndex(column)"
            [attr.title]="uniTablePipe.transform(rowModel, column)"
        >
            @if (column.get('buttonResolver')) {
                <unitable-button [column]="column" [rowModel]="rowModel"></unitable-button>
            } @else {
                {{ uniTablePipe.transform(rowModel, column) }}
            }
            <em
                *ngIf="column.get('tooltipResolver')"
                class="unitable-tooltip"
                role="presentation"
                [ngClass]="column | columnTooltipPipe: rowModel : 'cssClass'"
                [title]="column | columnTooltipPipe: rowModel : 'text'"
            >
            </em>
        </td>

        <td *ngIf="config?.deleteButton" tabindex="-1" class="unitable-delete-btn">
            <button
                class="table-button"
                [disabled]="config.readonly && config.deleteButton.disableOnReadonlyRows"
                (click)="onDeleteRow()"
            ></button>
        </td>

        <td *ngIf="contextMenu?.items?.length" #contextMenuCell class="contextMenu" tabindex="-1">
            <button
                #toggle
                class="table-button"
                [disabled]="config?.readonly && contextMenu.disableOnReadonlyRows"
                (click)="updateMenuItems()"
            >
                ...
            </button>

            <dropdown-menu [trigger]="toggle">
                <ng-template>
                    <section
                        class="dropdown-menu-item"
                        *ngFor="let item of menuItems"
                        (click)="runContextMenuAction(item)"
                    >
                        {{ item.label }}
                    </section>
                </ng-template>
            </dropdown-menu>
        </td>

        <td *ngIf="config?.columnMenuVisible && !contextMenu?.items?.length && !config?.deleteButton"></td>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UniTableRow {
    @Input() rowModel: Immutable.Map<any, any>;
    @Input() columns: Immutable.List<UniTableColumn>;
    @Input() config: UniTableConfig;

    @Output() rowDeleted = new EventEmitter(false);
    @Output() cellFocused = new EventEmitter(false);
    @Output() cellClicked = new EventEmitter(false);
    @Output() rowSelectionChanged = new EventEmitter(false);

    @ViewChild('contextMenuCell') contextMenuCell: ElementRef;

    public contextMenu: any;

    menuItems: IContextMenuItem[];

    constructor(
        public uniTablePipe: UniTablePipe,
        private permissionService: FeaturePermissionService,
    ) {}

    ngOnChanges(changes) {
        if (changes['config'] && this.config) {
            this.contextMenu = this.config.contextMenu;
        }
    }

    updateMenuItems() {
        if (this.config && this.rowModel) {
            this.menuItems = this.config.contextMenu.items.filter((item) => {
                const disabled = item.disabled && item.disabled(this.rowModel.toJS());
                let hasPermission = true;
                if (item.featurePermission) {
                    hasPermission = this.permissionService.canShowUiFeature(item.featurePermission);
                }

                return hasPermission && !disabled;
            });
        }
    }

    runContextMenuAction(item: IContextMenuItem) {
        item.action && item.action(this.rowModel.toJS());
    }

    public onCellFocus(event, column) {
        setTimeout(() => {
            this.cellFocused.emit({
                target: event.target,
                column: column,
                rowModel: this.rowModel,
            });
        });
    }

    public onCellClick(event, column) {
        setTimeout(() => {
            this.cellClicked.emit({
                event: event,
                column: column,
                rowModel: this.rowModel,
            });
        });
    }

    public onDeleteRow() {
        this.rowDeleted.emit({
            rowModel: this.rowModel,
        });
    }

    public onRowSelectionChanged(checked: boolean) {
        const updatedRow = this.rowModel.set('_rowSelected', checked);
        this.rowSelectionChanged.emit({
            rowModel: updatedRow,
        });
    }

    public getTabIndex(column) {
        return column.get('editable') ? 1 : -1;
    }
}
