import { NgModule } from '@angular/core';
import { UniFrameworkModule } from '@uni-framework/frameworkModule';
import { InfoBanner } from '../info-banner/info-banner';
import { UniBreadcrumbs } from './breadcrumbs';
import { StatusTrack } from './statustrack/statustrack';
import { StatustrackStatus } from './statustrack/statustrack-status/statustrack-status';
import { UniTags } from './tags';
import { UniToolbar } from './toolbar';
import { UniToolbarSearch } from './toolbarSearch';
import { UniToolbarValidation } from './toolbar-validation/toolbar-validation';
import { ToolbarSharingStatus } from './sharing-status/sharing-status';
import { ToolbarInfoSection } from './toolbar-info-section/toolbar-info-section';
import { ToolbarInfoItem } from './toolbar-info-section/toolbar-info-item/toolbar-info-item';
import { ContextSupport } from './context-support/context-support.component';
import { ContextMenu } from './context-menu/context-menu';
import { ToolbarStatusHistory } from './status-history/status-history';
import { StatusHistoryService } from './status-history/status-history.service';
import { CMSBanner } from '../dashboard/banner-ad/banner-ad';

@NgModule({
    imports: [UniFrameworkModule, CMSBanner],
    declarations: [
        UniBreadcrumbs,
        StatusTrack,
        StatustrackStatus,
        UniTags,
        UniToolbar,
        UniToolbarSearch,
        UniToolbarValidation,
        ToolbarSharingStatus,
        ToolbarInfoSection,
        ToolbarInfoItem,
        ContextSupport,
        ContextMenu,
        ToolbarStatusHistory,
        InfoBanner,
    ],
    providers: [StatusHistoryService],
    exports: [
        UniBreadcrumbs,
        StatusTrack,
        StatustrackStatus,
        UniTags,
        UniToolbar,
        UniToolbarSearch,
        UniToolbarValidation,
        ToolbarSharingStatus,
        ToolbarInfoSection,
        ToolbarInfoItem,
        ContextSupport,
        ContextMenu,
        ToolbarStatusHistory,
    ],
})
export class ToolbarModule {}
