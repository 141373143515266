import { Injectable } from '@angular/core';
import { BizHttp } from '../../../framework/core/http/BizHttp';
import { EHFLog, CompanySettings } from '../../unientities';
import { UniHttp } from '../../../framework/core/http/http';
import { RequestMethod } from '@uni-framework/core/http';
import { ActivateAP } from '@app/models/activateAP';

export const AP_NAME_EHF = 'EHF INVOICE';
export const AP_NAME_INVOICEPRINT = 'NETSPRINT';

@Injectable({ providedIn: 'root' })
export class EHFService extends BizHttp<EHFLog> {
    constructor(http: UniHttp) {
        super(http);

        this.relativeURL = EHFLog.RelativeUrl;
        this.entityType = EHFLog.EntityType;
        this.DefaultOrderBy = null;
    }

    public activate(service: string, activate: ActivateAP, direction: string) {
        super.invalidateCache();
        return this.ActionWithBody(
            null,
            {
                orgno: activate.orgnumber,
                orgname: activate.orgname,
                contactname: activate.contactname,
                contactphone: activate.contactphone,
                contactemail: activate.contactemail,
            },
            'activate',
            RequestMethod.Post,
            `service=${service}&direction=${direction}`,
        );
    }

    serviceMetadata(peppoladdress: string, entitytype: string) {
        const params = `peppoladdress=${peppoladdress}&entitytype=${entitytype}`;
        return this.GetAction(null, 'servicemetadata', params);
    }

    isInvoicePrintActivated(settings: CompanySettings): boolean {
        if (settings) {
            return (
                settings.APActivated &&
                settings.APOutgoing &&
                settings.APOutgoing.some((format) => {
                    return format.Name === AP_NAME_INVOICEPRINT;
                })
            );
        }
    }

    isEHFIncomingActivated(settings: CompanySettings): boolean {
        if (settings) {
            return settings.APActivated && this.activatedIncomming(settings, AP_NAME_EHF);
        }
    }

    activatedIncomming(settings: CompanySettings, AccessPointName: string): boolean {
        if (settings.APIncomming && settings.APIncomming.some((format) => format.Name.startsWith(AccessPointName))) {
            return true;
        }
        return false;
    }
}
