import { Component, ChangeDetectionStrategy, Input, ElementRef } from '@angular/core';

export type ToolbarInfoItemType = 'info' | 'processing' | 'success' | 'warning' | 'error' | 'deactivated';

const icons = {
    info: 'info',
    processing: 'processing',
    spinner: 'processing',
    success: 'check',
    warning: 'error',
    error: 'error',
    deactivated: 'deactivated',
};

@Component({
    selector: 'toolbar-info-item',
    templateUrl: './toolbar-info-item.html',
    styleUrls: ['./toolbar-info-item.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolbarInfoItem {
    @Input() type: ToolbarInfoItemType;
    @Input() class: string;

    icon: string;
    classList: string[];

    // ElementRef is needed when using the component as a trigger for <dropdown-menu>
    constructor(public elementRef: ElementRef) {}

    ngOnChanges() {
        this.icon = icons[this.type];

        this.classList = [this.type];
        if (this.class) {
            this.classList.push(this.class);
        }
    }
}
