<th *ngIf="config.multiRowSelect" class="select-column"></th>

<th
    *ngFor="let column of columns"
    [ngStyle]="{
        width: column.get('width'),
        'text-align': column.get('alignment') || 'left',
    }"
    [ngClass]="{
        isSortedAsc:
            (column.get('displayField') || column.get('field')) === sortInfo?.field && sortInfo?.direction === 1,
        isSortedDesc:
            (column.get('displayField') || column.get('field')) === sortInfo?.field && sortInfo?.direction === -1,
    }"
    bind-class="column.get('headerCls')"
    [hidden]="!column.get('visible')"
    (click)="sortByColumn(column)"
    [attr.title]="column.get('header')"
>
    {{ column.get('header') }}
    @if (column.get('headerIcon') && column.get('header').length === 0) {
        <uni-icon
            [icon]="column.get('headerIcon').icon"
            [matIconClass]="column.get('headerIcon').materialIconsClassOverride || 'material-icons'"
            [ngClass]="column.get('headerIcon').class"
            [style.color]="column.get('headerIcon').color"
            [svgSize]="column.get('headerIcon').size"
            [matIconSize]="column.get('headerIcon').size"
        >
        </uni-icon>
    }
</th>

<!-- Add an empty header cell if we have both delete button and context menu -->
<th *ngIf="config?.contextMenu?.items?.length && config.deleteButton" class="context-menu-column"></th>

<th
    class="context-menu-column"
    *ngIf="config?.columnMenuVisible || config?.contextMenu?.items?.length || config.deleteButton"
>
    <unitable-column-menu
        *ngIf="config?.columnMenuVisible"
        [tableConfig]="config"
        [columns]="columns"
        (columnsChange)="columnSetupChange($event)"
        (resetAll)="resetColumnSetup()"
    >
    </unitable-column-menu>
</th>
