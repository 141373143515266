<ng-select
    #input
    [formControl]="control"
    [multiple]="true"
    [items]="field?.Options?.source || []"
    [bindValue]="field?.Options?.bindValue"
    [bindLabel]="field?.Options?.bindLabel"
    [(ngModel)]="tempModel"
    [placeholder]="field?.Placeholder || ''"
    [closeOnSelect]="field?.Options?.closeOnSelect || true"
    [class.has-all-button]="field?.Options?.showAllButton"
    (focus)="focusHandler()"
    (change)="onChange($event)"
    (keydown.enter)="onEnter($event)"
    (keydown.tab)="onTab($event)"
>
    <ng-template ng-option-tmp let-item="item" *ngIf="field?.Options?.showCheckbox">
        <input type="checkbox" [checked]="tempModel.indexOf(item[field?.Options?.bindValue]) > -1" />
        {{ item[field?.Options?.bindLabel] }}
    </ng-template>
</ng-select>
<rig-checkbox
    *ngIf="field?.Options?.showAllButton"
    [disabled]="!!field?.ReadOnly"
    [checked]="cbValue"
    (change)="toggleSelectAll($event)"
    class="ml-1"
>
    Alle
</rig-checkbox>
<ng-content></ng-content>
