import { take, debounceTime } from 'rxjs/operators';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { QuickFilter, UniTableConfig } from '../unitable/config/unitableConfig';
import { UniTableColumn, UniTableColumnType } from '../unitable/config/unitableColumn';
import { UniModalService } from '../../uni-modal/modalService';
import { TableDataService } from './services/data-service';
import { TableGroupingService } from './services/table-grouping-service';
import { TableUtils } from './services/table-utils';
import { ColumnMenuNew } from './column-menu-modal';
import { EditorChangeEvent, TableEditor } from './editor/editor';
import { CellRenderer } from './cell-renderer/cell-renderer';
import { ITableFilter, ICellClickEvent, IRowChangeEvent, IOptionBanner } from './interfaces';

import { RowMenuRenderer } from './cell-renderer/row-menu';
import { StatusCellRenderer } from './cell-renderer/status-cell';
import { AttachmentCellRenderer } from './cell-renderer/attachment-cell';
import { CellWithIconsRenderer, HeaderCellWithIconsRenderer } from './cell-renderer/cell-with-icons';
import { TooltipCellRenderer } from './cell-renderer/tooltip-cell';
import { CellWithComponent } from './cell-renderer/cell-with-component';

import { Observable, Subject, Subscription } from 'rxjs';
import { TableLoadIndicator } from './table-load-indicator';
import { TableFiltersAndButtons } from './filters/filters-and-buttons';
import { TableCommentCountService } from './services/table-comment-count-service';
import { CommentCellRenderer } from './cell-renderer/comment-cell';
import {
    CellClickedEvent,
    ColDef,
    ColumnMovedEvent,
    ColumnResizedEvent,
    GridApi,
    GridReadyEvent,
    GridSizeChangedEvent,
    ICellRendererParams,
    ModelUpdatedEvent,
    PaginationChangedEvent,
    RowClickedEvent,
    RowDragEndEvent,
    IRowNode,
    RowSelectedEvent,
    SelectionChangedEvent,
    SortChangedEvent,
} from 'ag-grid-community';
import { GroupingCellRenderer } from './cell-renderer/grouping-cell/grouping-cell';
import { ColumnMenuCell } from './cell-renderer/column-menu-cell';
import { CellWithButtonRenderer } from './cell-renderer/cell-with-button';
import { LinkCellRenderer } from './cell-renderer/link-cell';

@Component({
    selector: 'ag-grid-wrapper',
    templateUrl: './ag-grid-wrapper.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [TableDataService, TableGroupingService],
})
export class AgGridWrapper {
    @ViewChild('wrapper') wrapperElement: ElementRef;
    @ViewChild('gridElement', { read: ElementRef }) gridElement: ElementRef;
    @ViewChild(TableEditor) editor: TableEditor;
    @ViewChild(TableFiltersAndButtons) filterSection: TableFiltersAndButtons;

    @Input() config: UniTableConfig;
    @Input() optionBanner: IOptionBanner;
    @Input() columnSumResolver: (params: HttpParams) => Observable<{ [field: string]: number }>;
    @Input() resource: any[] | ((params: HttpParams) => Observable<any>);
    @Input() domLayoutOverride: 'autoHeight' | 'normal';

    @Output() resourceChange = new EventEmitter(false);
    @Output() columnsChange = new EventEmitter<UniTableColumn[]>(false);
    @Output() rowClick = new EventEmitter(false);
    @Output() rowChange = new EventEmitter<IRowChangeEvent>(false);
    @Output() rowDelete = new EventEmitter(false);
    @Output() rowSelectionChange = new EventEmitter(false);
    @Output() rowSelect = new EventEmitter(false);
    @Output() filtersChange = new EventEmitter<string>(false);
    @Output() dataLoaded = new EventEmitter(false);
    @Output() cellClick = new EventEmitter<ICellClickEvent>(false);

    private configStoreKey: string;

    resizeObserver: ResizeObserver;

    autoSizeStrategy = {
        type: 'fitGridWidth',
        // type: 'fitProvidedWidth',
        // width: 1000
    };

    domLayout: 'autoHeight' | 'normal';

    defaultColDef: ColDef;

    agGridApi: GridApi;
    agColDefs: ColDef[];
    agTranslations = {
        noRowsToShow: 'Ingen rader å vise',
        loading: 'Laster data...',
    };
    rowClassResolver: (params) => string;

    rowHeight = 48;
    tableHeight: number;
    hasHorizontalScroll: boolean;
    reserveHeightForScrollbar: number;

    markedRowCount: number = 0;
    sumMarkedRows: any = 0;

    rowModelType: 'clientSide' | 'infinite';
    localData: boolean;
    cacheBlockSize: number;
    flex: string = '1';
    usePagination: boolean;
    selectionMode: string = 'single';
    paginationInfo: any;
    hasLoadedData: boolean;
    columns: UniTableColumn[];
    hasSumRow: boolean;
    loadedRowsCount: number;
    rowsCountTotal: number;
    rowsSumTotal: number;

    focusIndex = -1;

    private selectedRowNodes: { [id: string]: IRowNode } = {};

    private colResizeDebouncer$: Subject<ColumnResizedEvent> = new Subject();
    private gridSizeChangeDebouncer$: Subject<GridSizeChangedEvent> = new Subject();
    private rowSelectionDebouncer$: Subject<SelectionChangedEvent> = new Subject();
    private columnMoveDebouncer$: Subject<ColumnMovedEvent> = new Subject();
    private sumRowSubscription: Subscription;

    private isInitialLoad: boolean = true;
    public suppressRowClick: boolean = false;
    public sumColName: string = '';
    public allRowsSelected: boolean;
    public selectAllRowsDebouncer$: Subject<[allSelected: boolean, filterString: string]> = new Subject();

    // Used for custom cell renderers
    public context: any;
    public gridComponents: any = {
        columnMenu: ColumnMenuCell,
        rowMenu: RowMenuRenderer,
        statusCell: StatusCellRenderer,
        tableLoadIndicator: TableLoadIndicator,
        attachmentCell: AttachmentCellRenderer,
        cellWithIcons: CellWithIconsRenderer,
        cellWithComponent: CellWithComponent,
        headerCellWithIcons: HeaderCellWithIconsRenderer,
        tooltipCell: TooltipCellRenderer,
        commentCell: CommentCellRenderer,
    };

    isRowSelectable: (rowModel: any) => boolean;

    quickFilters: QuickFilter[];

    constructor(
        public dataService: TableDataService,
        private groupingService: TableGroupingService,
        private tableUtils: TableUtils,
        private modalService: UniModalService,
        private router: Router,
        private cdr: ChangeDetectorRef,
        private commentCountService: TableCommentCountService,
        private elementRef: ElementRef,
    ) {}

    public ngOnInit() {
        this.rowSelectionDebouncer$.pipe(debounceTime(50)).subscribe((event: SelectionChangedEvent) => {
            this.agGridApi.refreshHeader();
            const rows = this.getSelectedRows();

            if (this.allRowsSelected) {
                this.dataService.sumRow$.pipe(take(1)).subscribe((rowsSum) => {
                    this.rowsSumTotal = rowsSum[0]['Amount'];
                });

                this.sumMarkedRows = this.asMoney(this.rowsSumTotal);
                this.markedRowCount = this.rowsCountTotal;
            } else {
                this.sumMarkedRows =
                    rows && this.sumColName ? this.sumTotalInGroup(rows.map((row) => row[this.sumColName])) : 0;
                this.markedRowCount = rows ? rows.length : 0;
            }
            this.rowSelectionChange.emit(this.allRowsSelected ? [] : rows);

            if (this.config.customRowSelection?.onSelectionChange) {
                const currentSelectedNodes = event.api.getSelectedNodes();
                const changes = [];

                Object.keys(this.selectedRowNodes).forEach((nodeID) => {
                    if (!currentSelectedNodes.some((node) => node.id === nodeID)) {
                        changes.push({
                            row: this.selectedRowNodes[nodeID].data,
                            selected: false,
                        });

                        delete this.selectedRowNodes[nodeID];
                    }
                });

                currentSelectedNodes.forEach((node) => {
                    if (!this.selectedRowNodes[node.id]) {
                        changes.push({
                            row: node.data,
                            selected: true,
                        });

                        this.selectedRowNodes[node.id] = node;
                    }
                });

                if (changes.length) {
                    this.config.customRowSelection?.onSelectionChange({
                        allRowsUnchecked: !currentSelectedNodes?.length,
                        changes: changes,
                    });
                }
            }

            this.cdr.markForCheck();
        });

        this.dataService.totalRowCount$.subscribe((sum) => {
            this.rowsCountTotal = sum;
        });

        this.selectAllRowsDebouncer$.subscribe((data: [allSelected: boolean, filterString: string]) => {
            this.allRowsSelected = data[0];
            this.rowSelectionDebouncer$.next(undefined);
            if (this?.optionBanner && !data[0]) {
                this.optionBanner = null;
            }
        });

        this.columnMoveDebouncer$
            .pipe(debounceTime(1000))
            .subscribe((event: ColumnMovedEvent) => this.onColumnMove(event));

        // this.gridSizeChangeDebouncer$.pipe(
        //     debounceTime(350)
        // ).subscribe(() => {
        //     console.log('gridSizeChange');
        //     this.agGridApi.sizeColumnsToFit();
        //     this.checkForScrollbars();
        // });

        this.colResizeDebouncer$.pipe(debounceTime(250)).subscribe((event) => this.onColumnResize(event));

        this.sumRowSubscription = this.dataService.sumRow$.subscribe((sumRow) => {
            const hasSumRow = !!sumRow;
            if (this.hasSumRow !== hasSumRow) {
                this.hasSumRow = hasSumRow;
                this.calcTableHeight();
            }
        });
    }

    ngAfterViewInit() {
        this.initResizeObserver();
    }

    public ngOnDestroy() {
        this.rowSelectionDebouncer$.complete();
        this.columnMoveDebouncer$.complete();
        this.colResizeDebouncer$.complete();
        this.gridSizeChangeDebouncer$.complete();
        this.dataService.sumRow$.complete();
        this.dataService.localDataChange$.complete();
        this.sumRowSubscription?.unsubscribe();
        this.selectAllRowsDebouncer$?.unsubscribe();
        this.commentCountService.cleanup();
        this.resizeObserver?.disconnect();
    }

    public ngOnChanges(changes) {
        if (changes['config'] && this.config) {
            this.rowHeight = this.config.rowHeight || (this.config.editable ? 44 : 48);

            const sumCols = this.config.columns.filter((col) => col.markedRowsSumCol);

            if (sumCols && sumCols.length) {
                this.sumColName = sumCols[0].alias || sumCols[0].field;
            }

            this.columns = this.tableUtils.getTableColumns(this.config);
            this.agColDefs = this.getAgColDefs(this.columns);
            this.defaultColDef = {
                resizable: true,
                sortable: !this.config.editable && !this.config.rowDraggable,
            };

            this.quickFilters = this.config.quickFilters && this.initQuickFilters(this.config.quickFilters);

            if (this.agGridApi && this.config.configStoreKey !== this.configStoreKey) {
                this.agGridApi.setGridOption('columnDefs', this.agColDefs);
            }

            if (this.config.noRowsMessage) {
                this.agTranslations.noRowsToShow = this.config.noRowsMessage;
            }

            if (
                this.config.conditionalRowCls ||
                this.config.isRowReadOnly ||
                this.config.isRowSelectable ||
                this.config.groupingEnabled
            ) {
                this.rowClassResolver = (params) => {
                    const row = params.data;
                    const classes = [];

                    if (row?._isGroupHeader) {
                        return 'group-header-row';
                    }

                    if (this.config.isRowSelectable && !this.config.isRowSelectable(row)) {
                        classes.push('disabled-row');
                    }

                    if (this.config.editable && this.config.isRowReadOnly) {
                        if (this.config.isRowReadOnly(row)) {
                            classes.push('readonly-row');
                        }
                    }

                    if (this.config.conditionalRowCls && row) {
                        classes.push(this.config.conditionalRowCls(row));
                    }

                    return classes.join(' ');
                };
            }

            if (this.config.isRowSelectable || this.config.groupingEnabled) {
                this.isRowSelectable = (params) => {
                    const row = params && params.data;
                    if (row) {
                        if (row._isGroupHeader) {
                            return false;
                        }

                        return this.config.isRowSelectable?.(row);
                    }

                    return true;
                };
            }

            const commentCell = this.config?.columns?.find((col) => col.commentIndicator);
            if (commentCell) {
                this.commentCountService.init(commentCell.commentIndicator.entityType);
            }
        }

        if (changes['columnSumResolver'] && this.columnSumResolver) {
            this.dataService.columnSumResolver = this.columnSumResolver;
        }
        if (this.config && this.resource && (changes['config'] || changes['resource'])) {
            if (Array.isArray(this.resource)) {
                this.localData = true;
                this.rowModelType = 'clientSide';
                this.cacheBlockSize = undefined;
                this.usePagination =
                    this.config.pageable &&
                    !this.config.editable &&
                    !this.config.rowDraggable &&
                    !this.config.virtualScroll;
            } else {
                this.localData = false;
                this.rowModelType = 'infinite';
                this.cacheBlockSize = this.config.cacheBlockSize || 50;
                this.tableHeight = 81 + this.config.pageSize * this.rowHeight;
            }

            if (this.agGridApi) {
                this.agGridApi.deselectAll();

                const oldConfig = changes['config']?.previousValue;
                const configFiltersUpdated =
                    oldConfig &&
                    (JSON.stringify(oldConfig.filters) !== JSON.stringify(this.config.filters) ||
                        JSON.stringify(oldConfig.quickFilters) !== JSON.stringify(this.config.quickFilters));

                this.initialize(configFiltersUpdated);
            }
        }

        // Resource changed after startup (usually editable table)
        if (this.agGridApi && changes['resource'] && changes['resource'].previousValue) {
            this.dataService.initialize(this.agGridApi, this.config, this.columns, this.resource, this.quickFilters);
        }

        if (this.domLayoutOverride) {
            this.domLayout = this.domLayoutOverride;
        } else {
            this.domLayout =
                this.localData && !this.config?.groupingEnabled && !this.config?.virtualScroll
                    ? 'autoHeight'
                    : 'normal';
        }
    }

    private initResizeObserver() {
        const element: HTMLElement = this.elementRef.nativeElement;
        let width = element.clientWidth;

        let timeoutId;

        this.resizeObserver = new ResizeObserver(() => {
            if (element.clientWidth !== width) {
                width = element.clientWidth;
                clearTimeout(timeoutId);
                timeoutId = setTimeout(() => {
                    this.agGridApi?.sizeColumnsToFit();
                    this.checkForScrollbars();
                }, 50);
            }
        });

        this.resizeObserver.observe(element);
    }

    private initialize(forceReInitOnSameConfig = false) {
        // Only initialize if we have all required inputs and the config actually changed, or quickFilters have value and requires reload
        const configChanged = this.config && this.config.configStoreKey !== this.configStoreKey;

        const quickFiltersHasValue =
            this?.quickFilters?.filter((x) => !!x.filterGenerator && x.filterGenerator(x.value)).length > 0;

        if ((forceReInitOnSameConfig || configChanged || quickFiltersHasValue) && this.resource && this.agGridApi) {
            this.configStoreKey = this.config.configStoreKey;
            this.dataService.initialize(this.agGridApi, this.config, this.columns, this.resource, this.quickFilters);
        }
    }

    private initQuickFilters(quickFilters: QuickFilter[]) {
        const filterValues = this.tableUtils.getFilterState(this.config)?.quickFilterValues || {};

        const filters: QuickFilter[] = [];
        quickFilters.forEach((filter) => {
            filter.value = filterValues[filter.field] || filter.value;

            if (filter.filterGenerator) {
                filters.push(filter);
            } else {
                const column = this.columns?.find((col) => col.alias === filter.field || col.field === filter.field);
                filter.label = filter.label || column?.header;
                filter.operator = filter.operator || column?.filterOperator;

                if (!filter.type) {
                    switch (column?.type) {
                        case UniTableColumnType.Number:
                        case UniTableColumnType.Money:
                            filter.type = 'number';
                            break;
                        case UniTableColumnType.Boolean:
                            filter.type = 'checkbox';
                            break;
                        case UniTableColumnType.DateTime:
                        case UniTableColumnType.LocalDate:
                            filter.type = 'date';
                            break;
                        default:
                            filter.type = 'text';
                            break;
                    }
                }

                if (!column || column.visible || filter.ignoreColumnVisibility) {
                    filters.push(filter);
                }
            }
        });

        return filters.filter((f) => f.filterGenerator || (f.operator && f.type && f.label));
    }

    public onAgGridReady(event: GridReadyEvent) {
        this.agGridApi = event.api;
        this.agGridApi.sizeColumnsToFit();
        this.initialize();
    }

    public onAgModelUpdate(event: ModelUpdatedEvent) {
        this.hasLoadedData = true;

        const onLoadComplete = () => {
            setTimeout(() => {
                this.dataLoaded.emit();
                this.focusIndex = -1;

                if (this.isInitialLoad) {
                    if (this.config.autoselectFirstRow) {
                        this.selectRow(0);
                    }

                    if (this.config.autofocus) {
                        this.focus();
                    }

                    if (this.config.multiRowSelect && this.config.multiRowSelectDefaultValue) {
                        this.selectAll();
                    }

                    this.isInitialLoad = false;
                }

                this.updateCustomRowSelection();
                this.sizeColumnsToFit();
            });
        };

        if (this.rowModelType === 'infinite') {
            const state = event.api.getCacheBlockState();
            const loaded = Object.keys(state).every((key) => state[key].pageStatus === 'loaded');
            if (loaded) {
                this.onServerSideDataLoaded();
                onLoadComplete();
            }
        } else {
            onLoadComplete();

            if (this.config.virtualScroll) {
                this.calcTableHeight();
            }
        }
    }

    updateCustomRowSelection() {
        if (this.config.customRowSelection?.isRowSelected) {
            this.agGridApi.forEachNode((node) => {
                if (node.data) {
                    const shouldBeSelected = this.config.customRowSelection.isRowSelected(node.data);
                    if (node.isSelected() !== shouldBeSelected) {
                        node.setSelected(shouldBeSelected);

                        if (shouldBeSelected) {
                            this.selectedRowNodes[node.id] = node;
                        } else {
                            delete this.selectedRowNodes[node.id];
                        }
                    }
                }
            });
        }
    }

    public onServerSideDataLoaded() {
        if (!this.localData) {
            if (this.dataService.loadedRowCount) {
                this.loadedRowsCount = this.dataService.loadedRowCount;
            } else {
                this.agGridApi.showNoRowsOverlay();
            }

            this.calcTableHeight();

            if (this.allRowsSelected) {
                this.agGridApi.forEachNode((x) => x.setSelected(true));
            }
        }
    }

    private checkForScrollbars() {
        setTimeout(() => {
            const scrollbar: HTMLElement = this.gridElement.nativeElement?.querySelector('.ag-body-horizontal-scroll');

            this.hasHorizontalScroll =
                scrollbar &&
                !scrollbar.classList.contains('ag-hidden') &&
                !scrollbar.classList.contains('ag-scrollbar-invisible');

            this.reserveHeightForScrollbar = this.hasHorizontalScroll ? scrollbar.clientHeight || 20 : 0;
            this.cdr.markForCheck();
        });
    }

    private calcTableHeight() {
        if (!this.resource) return;

        let height: number;

        // Local data with virtual scroll
        if (this.localData && this.config?.virtualScroll) {
            height = (this.resource?.length || 1) * this.rowHeight + 3;
        }

        // Remote data (infinite scroll)
        if (this.config && !this.localData) {
            const pageSize = this.config.pageSize || 20;
            let rowCount = this.dataService.loadedRowCount || 1;

            if (rowCount < pageSize) {
                height = rowCount * this.rowHeight + 3;
            } else {
                height = pageSize * this.rowHeight;
                if (this.dataService.advancedSearchFilters && this.dataService.advancedSearchFilters.length) {
                    height -= 40;
                }
            }
        }

        if (height) {
            // Add space for header and sum row
            height += this.rowHeight;
            if (this.dataService.sumRow$.value) {
                height += this.rowHeight;
            }

            this.tableHeight = height;
            this.checkForScrollbars();
        }
    }

    toggleAllGroups() {
        this.groupingService.toggleAll();
    }

    public onRowDragEnd(event: RowDragEndEvent) {
        try {
            const originalIndex = event.node.data['_originalIndex'];
            const newIndex = event.overIndex;

            const data = this.dataService.getTableData();
            const row = data.splice(originalIndex, 1)[0];
            data.splice(newIndex, 0, row);

            this.dataService.initialize(this.agGridApi, this.config, this.columns, data, this.quickFilters);
            this.resourceChange.emit(data);
        } catch (err) {
            console.error(err);
        }
    }

    private sizeColumnsToFit() {
        if (!this.agGridApi) return;

        const wrapper = this.wrapperElement?.nativeElement;
        const viewport: HTMLElement = wrapper?.querySelector('.ag-center-cols-viewport');

        if (!viewport) {
            console.error('Unable to find grid viewport in onColumnResize(). Someone should investigate this..');
            return;
        }

        // Only size to fit if we don't have horizontal scroll,
        // or the scroll may be unintentional (less than 25 px)
        if (viewport.scrollWidth - viewport.clientWidth < 25) {
            this.agGridApi.sizeColumnsToFit();
            this.checkForScrollbars();
        }
    }

    public onColumnResize(event: ColumnResizedEvent) {
        if (event.finished && event.source === 'uiColumnResized') {
            setTimeout(() => {
                if (this.config.configStoreKey) {
                    const field = event.column.getColId();
                    const colIndex = this.columns.findIndex((col) => col.field === field);
                    if (colIndex >= 0) {
                        const colDefs = this.agGridApi.getColumns().filter((col) => col.isVisible());
                        // We need at least one colDef to flex (no width specified) to make sure the grid is always filled
                        if (!colDefs.every((col) => col.getColDef().width)) {
                            this.columns[colIndex].width = event.column.getActualWidth();
                            this.tableUtils.saveColumnSetup(this.config.configStoreKey, this.columns);
                        }
                    }
                }

                this.sizeColumnsToFit();
                this.checkForScrollbars();
            });
        }

        if (event.finished && event.source === 'autosizeColumns') {
            setTimeout(() => {
                this.sizeColumnsToFit();
                this.checkForScrollbars();
            });
        }
    }

    public onSortChange(event: SortChangedEvent) {
        const columns = event.columns || [];

        const col = columns.at(-1);
        if (col) {
            this.tableUtils.saveSortModel(this.config.configStoreKey, { colId: col.getColId(), sort: col.getSort() });
        } else {
            this.tableUtils.removeSortModel(this.config.configStoreKey);
        }

        if (this.config.groupingEnabled) {
            this.groupingService.setSort(col?.getColId(), col?.getSort());
        }
    }

    public onColumnMove(event: ColumnMovedEvent) {
        if (!event.column || !this.config || !this.config.configStoreKey) {
            return;
        }

        const colDef = event.column.getColDef();
        const column = colDef && colDef['_uniTableColumn'];
        const index = column && this.columns.findIndex((col) => col.field === column.field);
        if (index >= 0) {
            const col = this.columns.splice(index, 1)[0];

            this.columns.splice(event.toIndex, 0, col);
            this.columns = this.columns.map((c, i) => {
                c.index = i;
                return c;
            });

            this.tableUtils.saveColumnSetup(this.config.configStoreKey, this.columns);
            this.columnsChange.emit(this.columns);
            this.agColDefs = this.getAgColDefs(this.columns);
            this.cdr.markForCheck();
        }
    }

    public onRowClick(event: RowClickedEvent) {
        // Avoid emitting click/select events if the user just selected some text inside a cell
        if (document.getSelection()?.toString()) {
            return;
        }

        // Toggle selection instead of emitting rowClick event if the clicked cell is a checkbox cell.
        // It probably just means the user missed the checkbox.
        // REVISIT: should probably make a custom cell for multiselect checkboxes and remove this..
        const target = <HTMLElement>event.event?.target;

        const isCheckboxCell = target?.classList.contains('checkbox-cell') || target?.closest('.checkbox-cell');

        if (isCheckboxCell && !this.config.multiRowSelectOnRowClick) {
            event.node.setSelected(!event.node.isSelected());
            return;
        }

        const row = event && event.data;

        if (row?._isGroupHeader) {
            this.groupingService.toggleExpanded(row._guid);
            return;
        }

        if (row && !row['_isSumRow'] && !(this.config.multiRowSelect && this.config.multiRowSelectOnRowClick)) {
            this.rowClick.next(event.data);
        }

        this.focusRow(event.rowIndex);
    }

    public onCellClick(event: CellClickedEvent) {
        // Avoid emitting click/select events if the user just selected some text inside the cell
        if (document.getSelection()?.toString()) {
            return;
        }

        if (this.config.editable && this.editor) {
            const colIndex = this.columns
                .filter((col) => col.visible)
                .findIndex((col) => col.field === event.column.getColId());

            this.editor.activate(event.rowIndex, colIndex);
        }

        const column: UniTableColumn = event.colDef['_uniTableColumn'];

        if (!column) {
            return;
        }

        const row = event.data;

        if (row?._isGroupHeader) return;

        if (column.onCellClick) {
            column.onCellClick(event.data);
        }

        this.cellClick.emit({
            column: column,
            row: event.data,
        });
    }

    selectAll() {
        if (this.config?.multiRowSelect) {
            if (this.rowModelType === 'infinite') {
                this.agGridApi.forEachNode((row) => row.setSelected(true));
            } else {
                this.agGridApi.selectAll();
            }
        }
    }

    public setRowDragSuppressed(suppress: boolean) {
        if (this.agGridApi) {
            this.agGridApi.setGridOption('suppressRowDrag', suppress);
        }
    }

    public setRowClickSuppressed(suppress: boolean) {
        this.suppressRowClick = suppress;
        this.cdr.markForCheck();
    }

    public paginate(action: 'next' | 'prev' | 'first' | 'last') {
        switch (action) {
            case 'next':
                this.agGridApi.paginationGoToNextPage();
                break;
            case 'prev':
                this.agGridApi.paginationGoToPreviousPage();
                break;
            case 'first':
                this.agGridApi.paginationGoToFirstPage();
                break;
            case 'last':
                this.agGridApi.paginationGoToLastPage();
                break;
        }
    }

    public paginationInputChange(pageNumber: number) {
        this.agGridApi.paginationGoToPage(pageNumber - 1);
    }

    public onPaginationChange(event: PaginationChangedEvent) {
        this.paginationInfo = {
            currentPage: event.api.paginationGetCurrentPage() + 1,
            pageCount: event.api.paginationGetTotalPages(),
        };
    }

    public onFiltersChange(event: {
        basicSearchFilters: ITableFilter[];
        advancedSearchFilters: ITableFilter[];
        quickFilters: QuickFilter[];
    }) {
        if (this.config.multiRowSelect) {
            this.rowSelectionChange.next([]);
        }

        const shouldRefresh = !Array.isArray(this.resource) || this.config.filterLocalData;
        this.dataService.setFilters(
            event.advancedSearchFilters,
            event.basicSearchFilters,
            event.quickFilters,
            shouldRefresh,
        );

        this.filtersChange.emit(this.dataService.filterString || '');
    }

    public onEditorChange(event: EditorChangeEvent) {
        let row = event.rowModel;

        if (!this.config.changeCallback) {
            this.dataService.updateRow(row['_originalIndex'], row);
            this.emitChanges({
                rowModel: row,
                field: event.field,
                newValue: event.newValue,
                originalIndex: row['_originalIndex'],
            });

            return;
        }

        const updatedRowOrObservableOrPromise = this.config.changeCallback({
            rowModel: row,
            originalIndex: row['_originalIndex'],
            field: event.field,
            newValue: event.newValue,
        });

        (updatedRowOrObservableOrPromise instanceof Observable
            ? updatedRowOrObservableOrPromise.toPromise()
            : Promise.resolve(updatedRowOrObservableOrPromise)
        )
            .then((updatedRow) => {
                if (updatedRow) {
                    row = updatedRow;
                }

                this.dataService.updateRow(row['_originalIndex'], row);
                this.emitChanges({
                    rowModel: row,
                    field: event.field,
                    newValue: event.newValue,
                    originalIndex: row['_originalIndex'],
                });
            })
            .catch((err) => console.error(err));
    }

    private emitChanges(changeEvent: IRowChangeEvent) {
        this.resourceChange.emit(this.dataService.getTableData());
        this.rowChange.emit(changeEvent);
    }

    public onLinkClick(column: UniTableColumn, row: any) {
        if (!column) {
            return;
        }
        if (column.linkClick) {
            column.linkClick(row);
            return;
        }

        let url = column.linkResolver(row);

        if (url && url.length) {
            if (url.includes('mailto:')) {
                window.location.href = url;
            } else if (url.includes('http') || url.includes('www')) {
                if (window.confirm('Du forlater nå applikasjonen')) {
                    if (!url.includes('http')) {
                        url = 'http://' + url;
                    }
                    window.open(url, '_blank');
                }
            } else {
                this.router.navigateByUrl(url);
            }
        } else {
            console.warn('Link resolver did not return any url');
        }
    }

    private onColMenuClick() {
        this.modalService
            .open(ColumnMenuNew, {
                closeOnClickOutside: true,
                closeOnEscape: true,
                data: {
                    columns: this.columns,
                    tableConfig: this.config,
                },
            })
            .onClose.subscribe((res) => {
                if (res) {
                    let columns;
                    if (res.resetAll) {
                        this.tableUtils.removeColumnSetup(this.config.configStoreKey);
                        columns = this.tableUtils.getTableColumns(this.config);
                    } else {
                        columns = res.columns.map((col, index) => {
                            col.index = index;
                            return col;
                        });

                        this.tableUtils.saveColumnSetup(this.config.configStoreKey, columns);
                    }

                    this.columns = columns;
                    this.columnsChange.emit(this.columns);
                    this.agColDefs = this.getAgColDefs(columns);
                    this.cdr.markForCheck();

                    if (this.agGridApi) {
                        this.agGridApi.setGridOption('columnDefs', this.agColDefs);
                        setTimeout(() => this.agGridApi.sizeColumnsToFit());
                    }
                }
            });
    }

    public flashRows(indexes: number[]) {
        const rows = indexes.map((i) => this.agGridApi.getDisplayedRowAtIndex(i));

        this.agGridApi.flashCells({
            rowNodes: rows,
        });
    }

    public onDeleteRow(row) {
        if (this.editor) {
            this.editor.emitAndClose();
        }

        const deleteBtn = this.config.deleteButton;

        // Custom handler
        if (deleteBtn && typeof deleteBtn !== 'boolean') {
            deleteBtn.deleteHandler?.(row);
            return;
        }

        this.dataService.deleteRow(row);
        this.resourceChange.emit(this.dataService.getTableData());
        this.rowDelete.emit(row);
    }

    public onSelectionChange(event: SelectionChangedEvent) {
        if (this.selectionMode === 'multiple') {
            const selectedRows = this.getSelectedRows();

            if (
                this.config?.useInfobannerWhenMoreThenSelectedRowsExists &&
                selectedRows.length === this.loadedRowsCount &&
                this.rowsCountTotal > selectedRows.length
            ) {
                this.optionBanner = {
                    text: `Alle ${selectedRows.length} radene på denne siden er markert. `,
                    link: `Marker alle ${this.rowsCountTotal} radene i listen`,
                    action: () => {
                        this.selectAllRowsDebouncer$.subscribe((data: [boolean, string]) => {
                            if (this.optionBanner && data[0]) {
                                this.optionBanner = {
                                    text: `Alle ${this.rowsCountTotal} radene er markert. `,
                                    link: `Fjern markeringen`,
                                    action: () => {
                                        this.agGridApi.deselectAll();
                                        this.selectAllRowsDebouncer$.next([false, this.getFilterString()]);
                                    },
                                };
                            }
                        });
                        this.selectAllRowsDebouncer$.next([true, this.getFilterString()]);
                        if (this.allRowsSelected) {
                            this.agGridApi.forEachNode((x) => x.setSelected(true));
                        }
                    },
                };
            } else if (this.config?.useInfobannerWhenMoreThenSelectedRowsExists && this.optionBanner) {
                this.optionBanner = null;
                this.selectAllRowsDebouncer$.next([false, this.getFilterString()]);
            }

            // If we have multirow select run the events through a debouncer.
            // This is done because selecting all rows will trigger one event
            // per row, and we dont need to emit for every row
            this.rowSelectionDebouncer$.next(event);
            this.cdr.markForCheck();
        } else {
            const selectedRows = this.getSelectedRows() || [];
            if (selectedRows.length) {
                this.rowSelectionChange.next(selectedRows[0]);
            }
        }
    }

    public onRowSelected(event: RowSelectedEvent) {
        const row = event.data;

        if (row?._isGroupHeader) return;

        // Because ag-grid emits this event for both select and deselect..
        if (event.node?.isSelected()) {
            this.rowSelect.emit(row);
            this.focusIndex = event.rowIndex;
        }
    }

    private sumTotalInGroup(values) {
        const nums = values.map((value) => {
            value = value.toString().replace('\u2009', '').replace(' ', '');
            return isNaN(parseFloat(value)) ? 0 : parseFloat(value);
        });

        return this.asMoney(nums.length ? nums.reduce((total, number) => total + number) : 0);
    }

    private asMoney(value) {
        const options = {
            thousandSeparator: '\u2009',
            decimalSeparator: ',',
            decimalLength: 2,
        };

        let stringValue = value.toString().replace(',', '.');
        stringValue = parseFloat(stringValue).toFixed(options.decimalLength);

        let [integer, decimal] = stringValue.split('.');
        integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, options.thousandSeparator);

        stringValue = decimal ? integer + options.decimalSeparator + decimal : integer;

        return stringValue;
    }

    private getAgColDefs(columns: UniTableColumn[]): ColDef[] {
        if (!columns) {
            return [];
        }

        this.context = { componentParent: this };

        const colDefs = columns.map((col) => {
            let alignmentClass = col.alignment && `align-${col.alignment}`;

            if (col.alignment === 'center') {
                alignmentClass = 'text-align-center';
            }

            const agCol: ColDef = {
                headerName: col.header,
                suppressMenu: true,
                hide: !col.visible,
                headerClass: () => {
                    const cls = [col.headerCls || '', alignmentClass || ''];
                    return cls.join(' ');
                },
                cellClass: (params) => {
                    let classString = '';

                    if (col.cls) {
                        classString += ` ${col.cls}`;
                    }

                    if (col.conditionalCls) {
                        classString += ` ${col.conditionalCls(params)}`;
                    }

                    if (alignmentClass) {
                        classString += ` ${alignmentClass}`;
                    }

                    return classString;
                },
                headerTooltip: col.header,
                sortable: col.sortable,
                tooltipValueGetter: (params) => {
                    if (params?.data) {
                        return col.cellTitleResolver
                            ? col.cellTitleResolver(params.data)
                            : this.tableUtils.getColumnValue(params.data, col);
                    }
                    return '';
                },
                valueGetter: (params) => {
                    let data = params.data;
                    if (!data && this.config.groupingEnabled && col.isSumColumn) {
                        data = params.node && params.node.aggData;
                    }

                    return this.tableUtils.getColumnValue(data, col);
                },
                cellRenderer: (params: ICellRendererParams) => {
                    if (params.value) {
                        return params.value;
                    } else if (col.placeholder) {
                        const placeholderValue =
                            typeof col.placeholder === 'function' ? col.placeholder(params.node.data) : col.placeholder;

                        return `<span class="placeholder">${placeholderValue}</span>`;
                    }
                },
            };

            if (this.config && this.config.editable) {
                agCol.sortable = false;
            }

            if (!col.resizeable) {
                agCol.resizable = false;
                agCol.suppressSizeToFit = true;
                agCol.suppressAutoSize = true;
            }

            if (col.type === UniTableColumnType.LocalDate || col.type === UniTableColumnType.DateTime) {
                agCol.comparator = (value1, value2, node1, node2) => {
                    return this.tableUtils.dateComparator(node1, node2, col);
                };
            }

            if (
                col.type === UniTableColumnType.Number ||
                col.type === UniTableColumnType.Money ||
                col.type === UniTableColumnType.Percent
            ) {
                agCol.comparator = (value1, value2, node1, node2) => {
                    return this.tableUtils.numberComparator(node1, node2, col);
                };
            }

            const rendererParams = {
                columnConfig: col,
                tableConfig: this.config,
            };

            agCol.headerComponentParams = rendererParams;
            agCol.cellRendererParams = rendererParams;

            if (col.linkResolver || col.linkClick) {
                agCol.cellRenderer = LinkCellRenderer;
                agCol.cellRendererParams = {
                    linkActive: col.hasLink,
                    onLinkClick: (row: any) => this.onLinkClick(col, row),
                };
            }

            if (col.tooltipResolver) {
                agCol.cellRenderer = 'tooltipCell';
            }

            if (col.buttonResolver) {
                agCol.cellRenderer = CellWithButtonRenderer;
                agCol.cellRendererParams = { buttonResolver: col.buttonResolver };
            }

            if (col.iconResolver) {
                agCol.tooltipValueGetter = undefined;
                agCol.cellRenderer = 'cellWithIcons';
            }

            if (col.headerIcon) {
                agCol.headerTooltip = undefined;
                agCol.headerComponent = 'headerCellWithIcons';
            }

            if (col.cellComponent) {
                agCol.cellRenderer = 'cellWithComponent';
            }

            if (col.commentIndicator) {
                agCol.tooltipValueGetter = undefined;
                agCol.cellRenderer = 'commentCell';
            }

            if (col.type === UniTableColumnType.Status) {
                agCol.tooltipValueGetter = undefined;
                agCol.cellRenderer = 'statusCell';
            }

            if (col.type === UniTableColumnType.Attachment) {
                agCol.tooltipValueGetter = undefined;
                agCol.cellRenderer = 'attachmentCell';
                col['_onChange'] = (row, files) => {
                    if (this.config.editable) {
                        this.onEditorChange({
                            rowModel: row,
                            field: col.field,
                            newValue: files,
                        });
                        // this.updateRow(updatedRow['_originalIndex'], updatedRow);
                    }

                    if (col.options && col.options.onChange) {
                        col.options.onChange(row, files);
                    }
                };
            }

            // agCol.suppressSizeToFit = !!col.width;

            if (Number(col.width) >= 0) {
                agCol.width = +col.width;
            } else {
                agCol.flex = 1;
            }

            if (col.maxWidth) {
                agCol.maxWidth = col.maxWidth;
            }

            if (col.minWidth) {
                agCol.minWidth = col.minWidth;
            }

            if (!col.minWidth && (!col.width || Number(col.width) >= 100)) {
                agCol.minWidth = 100;
            }

            if (col.type === UniTableColumnType.Custom) {
                if (col.options.cellRenderer) {
                    agCol.cellRenderer = col.options.cellRenderer(col);
                }
                if (col.options.headerComponent) {
                    agCol.cellRenderer = col.options.headerComponent(col);
                }
            }

            agCol.colId = col.field;
            agCol['_uniTableColumn'] = col;
            return agCol;
        });

        if (this.config.groupingEnabled) {
            const groupingConfig = this.config.groupingConfig || {};

            colDefs.forEach((col) => {
                // Handle sorting ourselves instead of having the grid do it
                col.comparator = () => 0;
            });

            colDefs.unshift({
                colId: '_groupingField',
                headerName: groupingConfig.staticGroupByColumn?.header || 'Gruppering',
                field: '_groupingField',
                cellRenderer: GroupingCellRenderer,
                minWidth: 200,
                lockPosition: true,
                suppressAutoSize: true,
                suppressMovable: true,
                sortable: false,
            });
        }

        if (this.config.multiRowSelect) {
            colDefs.unshift({
                colId: 'checkbox_cell',
                headerName: '',
                valueGetter: () => '',
                headerComponent: CellRenderer.getHeaderCheckbox(this.config),
                checkboxSelection: true,
                width: 38,
                suppressSizeToFit: true,
                resizable: false,
                sortable: false,
                suppressMovable: true,
                lockPosition: true,
                headerClass: 'checkbox-cell',
                cellClass: 'checkbox-cell',
            });

            this.selectionMode = 'multiple';
        }

        if (this.config.columnMenuVisible || this.config.deleteButton || this.config.editButton) {
            const menuColumn: ColDef = {
                width: 40,
                pinned: 'right',
                headerClass: 'col-menu',
                cellClass: 'row-menu',
                suppressSizeToFit: true,
                suppressMovable: true,
                resizable: false,
                sortable: false,
            };

            if (this.config.columnMenuVisible) {
                menuColumn.headerComponent = 'columnMenu';
                menuColumn.headerComponentParams = { showMenu: () => this.onColMenuClick() };
            }

            const hasDeleteButton = !!this.config.deleteButton;
            const hasContextMenu =
                this.config.contextMenu && this.config.contextMenu.items && this.config.contextMenu.items.length;

            const numberOfButtons = [hasDeleteButton, hasContextMenu, this.config.editButton].filter(
                (val) => !!val,
            ).length;

            if (numberOfButtons > 0) {
                menuColumn.cellRenderer = 'rowMenu';
                menuColumn['_tableConfig'] = this.config;
            }

            if (numberOfButtons > 1) {
                menuColumn.width = 80;
            }

            colDefs.push(menuColumn);
        }

        if (this.config.rowDraggable) {
            colDefs.unshift({
                rowDrag: true,
                width: 45,
                resizable: false,
                suppressAutoSize: true,
                suppressSizeToFit: true,
                lockPosition: true,
                valueGetter: () => 'Flytt rad',
                cellClass: 'row-drag-cell',
            });
        }

        return colDefs;
    }

    private isRowReadonly(row) {
        if (!this.config.editable) {
            return true;
        }

        if (this.config.isRowReadOnly && this.config.isRowReadOnly(row)) {
            return true;
        }

        return false;
    }

    public getContextMenuItems(row): any[] {
        const contextMenu = this.config.contextMenu;
        if (contextMenu) {
            const disabled = contextMenu.disableOnReadonlyRows && this.isRowReadonly(row);
            if (!disabled) {
                return contextMenu.items;
            }
        }
    }

    public getDeleteButtonAction(row): (row) => void {
        if (this.config.deleteButton) {
            const disabled = this.config.disableDeleteOnReadonly && this.isRowReadonly(row);
            if (!disabled) {
                return this.onDeleteRow.bind(this);
            }
        }
    }

    public getRowIdentifier(row) {
        return row?.data?._guid;
    }

    // Public functions for host components
    public getTableData(filtered?: boolean) {
        if (filtered) {
            const rows = [];
            this.agGridApi.forEachNode((node) => {
                if (node.data) {
                    rows.push(node.data);
                }
            });
            return rows;
        } else {
            return this.dataService.getTableData(true);
        }
    }

    public getSelectedRows() {
        const selectedRows = [];
        this.agGridApi?.forEachNode((node) => {
            if (node.isSelected()) {
                selectedRows.push(node.data);
            }
        });

        return selectedRows;
    }

    public getRowCount() {
        return this.dataService.loadedRowCount;
    }

    getFilterString() {
        return this.dataService.filterString;
    }

    public finishEdit(): Promise<any> {
        return this.editor ? this.editor.emitAndClose() : Promise.resolve();
    }

    public getCurrentRow() {
        if (this.config.editable && this.editor) {
            return this.editor.currentRow;
        } else {
            const selected = this.getSelectedRows();
            if (selected && selected[0]) {
                return selected[0];
            }
        }
    }

    selectRow(index: number) {
        setTimeout(() => {
            const rowNode = this.agGridApi?.getDisplayedRowAtIndex(index || 0);
            if (rowNode && !rowNode.isSelected()) {
                rowNode.setSelected(true);
            }
        });
    }

    onTableKeydown(event: KeyboardEvent) {
        if (!this.config.editable) {
            this.checkForNavigationKey(event, true);
        }
    }

    checkForNavigationKey(event: KeyboardEvent, selectOnSpace = false) {
        if (event.key === 'ArrowDown') {
            event.preventDefault();
            this.focusNext();
        } else if (event.key === 'ArrowUp') {
            event.preventDefault();
            this.focusPrevious();
        } else if (event.key === 'Enter' && this.focusIndex >= 0) {
            const focusedNode = this.agGridApi.getDisplayedRowAtIndex(this.focusIndex);
            if (focusedNode) {
                if (this.config.multiRowSelect) {
                    if (event.shiftKey || event.ctrlKey) {
                        focusedNode.setSelected(!focusedNode.isSelected());
                    } else {
                        this.rowClick.next(focusedNode.data);
                    }
                } else {
                    focusedNode.setSelected(true);
                    this.rowClick.next(focusedNode.data);
                }
            }
        } else if (event.key === ' ' && selectOnSpace && this.config.multiRowSelect && this.focusIndex >= 0) {
            const focusedNode = this.agGridApi.getDisplayedRowAtIndex(this.focusIndex);
            focusedNode?.setSelected(!focusedNode.isSelected());
        }
    }

    private focusRow(rowIndex: number) {
        setTimeout(() => {
            if (this.agGridApi.isDestroyed()) return;

            if (!this.config.editable) {
                this.focusIndex = rowIndex ?? -1;

                if (this.focusIndex >= 0) {
                    try {
                        this.agGridApi.ensureIndexVisible(this.focusIndex);
                    } catch (e) {}
                }

                const tableRows: HTMLElement[] = this.wrapperElement?.nativeElement?.querySelectorAll('.ag-row') || [];
                const focusedRowID = rowIndex >= 0 && this.agGridApi.getDisplayedRowAtIndex(rowIndex)?.id;

                tableRows?.forEach((row: HTMLElement) => {
                    const id = row.getAttribute('row-id');
                    if (id === focusedRowID) {
                        row.classList.add('table-row-focused');
                    } else {
                        row.classList.remove('table-row-focused');
                    }
                });

                this.cdr.markForCheck();
            }
        });
    }

    focusNext() {
        if (this.focusIndex < this.dataService.totalRowCount$.getValue() - 1) {
            this.focusRow(this.focusIndex + 1);
        }
    }

    focusPrevious() {
        if (this.focusIndex > 0) {
            this.focusRow(this.focusIndex - 1);
        }
    }

    focus(rowIndex?: number, selectRow?: boolean) {
        setTimeout(() => {
            if (!this.agGridApi) {
                return;
            }

            if (this.config.editable) {
                if (!rowIndex || rowIndex > this.agGridApi.getDisplayedRowCount() - 1) {
                    rowIndex = 0;
                }

                const rowNode = this.agGridApi.getDisplayedRowAtIndex(rowIndex);

                if (rowNode && rowNode.data) {
                    const colIndex = this.columns
                        .filter((col) => col.visible)
                        .findIndex((col) => {
                            return typeof col.editable === 'function' ? col.editable(rowNode.data) : col.editable;
                        });

                    if (colIndex >= 0) {
                        this.editor.activate(rowIndex, colIndex);
                    }
                }
            } else {
                if (this.config.searchable) {
                    this.filterSection?.focus();
                    if (rowIndex >= 0) {
                        this.focusRow(rowIndex);
                    }
                } else {
                    this.gridElement?.nativeElement?.focus();
                    this.focusRow(rowIndex || 0);
                }

                if (selectRow && rowIndex >= 0) {
                    this.selectRow(rowIndex);
                }
            }
        });
    }

    public updateRow(originalIndex: number, row) {
        this.dataService.updateRow(originalIndex, row);
        this.resourceChange.emit(this.dataService.getTableData());
    }

    public addRow(row) {
        this.dataService.addRow(row);
        setTimeout(() => {
            this.focus(this.agGridApi.getDisplayedRowCount() - 1);
        });
    }

    public clearSelection() {
        this.agGridApi.deselectAll();
    }

    /**
     * Refreshes table data. This is only relevant for remote data tables.
     * If you have a local data table you want to use either updateRow(index, data)
     * or give the resource variable a new memory reference to trigger change detection
     * on the entire dataset.
     */
    public refreshTableData() {
        if (this.agGridApi && !this.config.editable) {
            this.dataService.refreshData();
        } else {
            console.warn(
                'No point running refresh on local data table. Use updateRow or give resource input a new reference',
            );
        }
    }

    /**
     * Returns current filters as an array of ITableFilter
     */
    public getAdvancedSearchFilters(): ITableFilter[] {
        return this.dataService.advancedSearchFilters;
    }

    /**
     * Removes all filters on a given field
     * @param {string} field
     */
    public removeFilter(field: string): void {
        this.dataService.removeFilter(field);
    }

    public async exportFromGrid() {
        this.agGridApi.exportDataAsCsv({
            fileName: 'Eksportert_data',
        });
    }

    clearLastUsedFilter() {
        if (this.config) {
            this.tableUtils.updateFilterState(this.config.configStoreKey, undefined);
        }
    }

    onTableElementFocus() {
        setTimeout(() => {
            if (this.config.editable) {
                this.focus(0);
            } else if (!this.config.searchable) {
                const index = this.focusIndex >= 0 ? this.focusIndex : 0;
                this.focusRow(index);
            }
        });
    }

    updateRows(updater: (row: any) => any) {
        this.agGridApi?.forEachNode((node) => {
            if (node.data) {
                const updatedRow = updater(node.data);
                if (updatedRow) {
                    node.setData(updatedRow);
                }
            }
        });
    }
}
