<input-dropdown-menu [input]="inputElement" [visible]="dropdownVisible$ | async" [largeShadow]="true">
    <ng-template>
        <section
            class="wrapper"
            [class.has-results]="(store.accounts$ | async).length"
            (keydown)="keyDown.emit($event)"
        >
            <section class="dropdown-header">
                <section class="header-section">
                    <span class="account-number">Kontonr.</span>
                    <span class="account-name">Kontonavn</span>
                </section>

                <section class="header-section">
                    <span>Beskrivelse</span>
                </section>
            </section>

            <section class="dropdown-content">
                <!-- Empty state -->
                <section *ngIf="!(store.accounts$ | async).length" class="no-results">
                    Søket ga ingen resultater
                </section>

                <!-- Results -->
                <ng-container *ngIf="(store.accounts$ | async).length">
                    <ul class="item-list custom-scrollbar" (scrolledToBottom)="onUserScrolledToBottom()">
                        <ng-container *ngFor="let account of displayedSearchResults; let idx = index">
                            <li class="group-header" *ngIf="account['_firstInGroup']">
                                {{ account.GroupNumber }} {{ account.GroupName }}
                            </li>

                            <li
                                #dropdownItem
                                class="dropdown-menu-item"
                                [class.selected]="idx === focusIndex"
                                (click)="accountClick.emit(account)"
                                (mousemove)="onMouseMove(idx)"
                            >
                                <span class="account-number">{{ account.AccountNumber }}</span>
                                <span class="account-name">{{ account.AccountName }}</span>
                                <span *ngIf="!account.Visible" class="uni-chip small account-hidden">Skjult</span>
                            </li>
                        </ng-container>
                    </ul>

                    <section *ngIf="store.accountHelp$ | async as accountHelp" class="account-help custom-scrollbar">
                        <section class="account-help-header">{{ accountHelp.header }}</section>

                        <section *ngIf="accountHelp.keywords.length" class="keywords">
                            <span *ngFor="let keyword of accountHelp.keywords" class="uni-chip" [ngClass]="chipClass">
                                {{ keyword }}
                            </span>
                        </section>

                        <section>{{ accountHelp.description }}</section>
                    </section>
                </ng-container>
            </section>

            <section class="dropdown-footer">
                <a *ngIf="canCreateAccounts" (click)="createAccount.emit()">
                    <i class="material-icons">add</i>
                    Opprett konto
                </a>

                <rig-switch [checked]="store.showHiddenAccounts" (change)="store.toggleShowHidden()" class="pull-right">
                    Vis skjulte kontoer
                </rig-switch>
            </section>
        </section>
    </ng-template>
</input-dropdown-menu>
