import { NgModule } from '@angular/core';
import { LibraryImportsModule } from '@app/library-imports.module';
import { InputDropdownModule } from '../input-dropdown/input-dropdown';
import { Autocomplete } from './autocomplete';
import { ClickOutsideModule } from '@uni-framework/click-outside/click-outside.module';
import { DesignSystemModule } from '../design-system/design-system.module';
import { UniAvatarModule } from '@uni-framework/avatar/uniAvatar.module';

@NgModule({
    imports: [LibraryImportsModule, DesignSystemModule, InputDropdownModule, ClickOutsideModule, UniAvatarModule],
    declarations: [Autocomplete],
    exports: [Autocomplete],
})
export class AutocompleteModule {}
