import { Component, EventEmitter } from '@angular/core';
import { Account, Customer, Supplier } from '@uni-entities';
import { IModalOptions, IUniModal } from '@uni-framework/uni-modal';
import { Observable } from 'rxjs';
import { NewAccountModalV2Service } from '@app/components/common/modals/new-account-modal-v2/new-account-modal-v2.service';
import { FeaturePermissionService } from '@app/featurePermissionService';
import { ErrorService } from '@app/services/common/errorService';

@Component({
    selector: 'new-account-modal',
    templateUrl: './new-account-modal-v2.html',
    styleUrls: ['./new-account-modal-v2.sass'],
})
export class NewAccountModalV2 implements IUniModal {
    options: IModalOptions = {};
    onClose = new EventEmitter<Account | Customer | Supplier>();

    entityLabel: string;
    entityType: 'account' | 'customer' | 'supplier' = 'account';
    hideEntityTypeSelector: boolean;
    busy: boolean;

    entity: Account | Customer | Supplier;
    isValid: boolean;
    hasPermissions: boolean = false;
    constructor(
        private newAccountModalV2Service: NewAccountModalV2Service,
        private errorService: ErrorService,
        private featurePermissionService: FeaturePermissionService,
    ) {}

    ngOnInit() {
        const data = this.options?.data || {};

        this.hideEntityTypeSelector = data.hideEntityTypeSelector;
        this.setEntityType(data.text || '', data.entityType || 'account');
        this.hasPermissions = this.featurePermissionService.canShowUiFeature('ui.accountsettings-add-and-import');
    }

    setEntityType(initText, entityType) {
        switch (entityType) {
            case 'account':
                this.entity = isNaN(parseInt(initText, 10))
                    ? <Account>{ AccountName: initText }
                    : <Account>{ AccountNumber: initText };
                this.entityLabel = 'hovedbokskonto';
                break;
            case 'customer':
                this.entityLabel = 'kunde';
                if (this.entityType === 'supplier') {
                    break;
                }
                this.entity = <Customer>{
                    Info: { Name: initText },
                };
                break;
            case 'supplier':
                this.entityLabel = 'leverandør';
                if (this.entityType === 'customer') {
                    break;
                }
                this.entity = <Supplier>{
                    Info: { Name: initText },
                };
                break;
        }
        this.entityType = entityType;
        this.isValid = false;
    }

    onEntityChange({ entity, isValid, autosave }) {
        this.entity = entity;
        this.isValid = isValid;
        if (autosave) {
            this.save();
        }
    }

    save() {
        if (!this.isValid) {
            return;
        }

        let request: Observable<Account | Customer | Supplier>;
        switch (this.entityType) {
            case 'account':
                request = this.newAccountModalV2Service.saveFromGeneralLedgerAccount(<Account>this.entity);
                break;
            case 'supplier':
                request = this.newAccountModalV2Service.saveFromSupplier(<Supplier>this.entity);
                break;
            case 'customer':
                request = this.newAccountModalV2Service.saveFromCustomer(<Customer>this.entity);
                break;
        }

        this.busy = true;
        request?.subscribe(
            (res) => this.onClose.emit(res),
            (err) => {
                if (err) {
                    this.errorService.handle(err);
                }
                this.busy = false;
            },
        );
    }
}
