import { Component, Input, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { LocalDate } from '@uni-entities';
import { autocompleteDate } from '@app/date-adapter';
import { rigDate } from '@app/components/common/utils/rig-date';
import { FinancialYearService } from '@app/services/accounting/financialYearService';

@Component({
    selector: 'unidate-picker',
    template: `
        <section class="input-with-button">
            <input
                #input
                type="text"
                [formControl]="inputControl"
                (keydown.space)="$event.preventDefault(); picker.open()"
            />

            <!-- Hidden input for mat-datepicker, because we want custom parsing on the "text input" -->
            <input
                style="position: absolute; visibility: hidden; width: 0px;"
                [(ngModel)]="calendarDate"
                (ngModelChange)="onCalendarDateChange()"
                [matDatepicker]="picker"
                tabindex="-1"
            />

            <button type="button" tabindex="-1" (click)="picker.open()">
                <uni-icon icon="calendar"></uni-icon>
            </button>

            <mat-datepicker #picker (closed)="focus()"></mat-datepicker>
        </section>
    `,
})
export class LocalDatePicker {
    @ViewChild('input', { static: true }) inputElement: ElementRef;
    @Input() inputControl: UntypedFormControl;

    column;
    calendarDate: Date;

    constructor(private yearService: FinancialYearService) {}

    ngOnInit() {
        if (this.inputControl.value) {
            this.calendarDate = autocompleteDate(this.inputControl.value);
        }
    }

    onCalendarDateChange() {
        this.inputControl.setValue(rigDate(this.calendarDate).format('L'));
        this.inputControl.markAsDirty();
    }

    focus() {
        if (this.inputElement) {
            this.inputElement.nativeElement.focus();
            this.inputElement.nativeElement.select();
        }
    }

    getValue(): LocalDate {
        if (this.inputControl.dirty) {
            const options = (this.column && this.column.get('options')) || {};
            const yearOverride = options.useFinancialYear && this.yearService.getActiveYear();

            const parsed =
                this.inputControl.value &&
                autocompleteDate(this.inputControl.value, yearOverride, options.useSmartYear);

            return parsed && new LocalDate(parsed);
        }
    }
}
