import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AccountGroupService } from '@app/services/accounting/accountGroupService';
import { AccountService } from '@app/services/accounting/accountService';
import { AltinnAccountLinkService } from '@app/services/accounting/altinnAccountLinkService';
import { VatTypeService } from '@app/services/accounting/vatTypeService';
import { Account, AccountGroup, FieldType, SaftMappingAccount, VatType } from '@uni-entities';
import { map } from 'rxjs/operators';

@Component({
    selector: 'general-ledger-account-form',
    templateUrl: './general-ledger-account-form.html',
})
export class GeneralLedgerAccountForm {
    @Input() entity: Account;
    @Output() entityChange = new EventEmitter<{ entity: Account; isValid: boolean; autosave: boolean }>();

    formFields = this.getFormFields();

    constructor(
        private accountService: AccountService,
        private accountGroupService: AccountGroupService,
        private vatTypeService: VatTypeService,
        private altinnAccountLinkService: AltinnAccountLinkService,
    ) {}

    onFormChange() {
        this.entityChange.emit({
            entity: this.entity,
            isValid: !!this.entity.AccountNumber && !!this.entity.AccountName,
            autosave: false,
        });
    }

    getFormFields() {
        return [
            {
                Property: 'AccountNumber',
                Label: 'Kontonummer',
                Required: true,
            },
            {
                Property: 'AccountName',
                Label: 'Kontonavn',
                Required: true,
            },
            {
                Property: 'VatTypeID',
                FieldType: FieldType.DROPDOWN,
                Label: 'Mvakode',
                Options: {
                    valueProperty: 'ID',
                    template: (vt: VatType) => (vt ? `${vt.VatCode}: ${vt.VatPercent}% - ${vt.Name}` : ''),
                    source: () => this.vatTypeService.GetAll(null),
                },
            },
            {
                Property: 'AccountGroupID',
                FieldType: FieldType.DROPDOWN,
                Label: 'Kontogruppe',
                Options: {
                    source: () =>
                        this.accountGroupService
                            .GetAll('orderby=GroupNumber')
                            .pipe(
                                map((groups) => groups.filter((group) => group.GroupNumber?.toString().length === 3)),
                            ),
                    template: (data: AccountGroup) => `${data.GroupNumber} - ${data.Name}`,
                    valueProperty: 'ID',
                },
            },
            {
                Property: 'SaftMappingAccountID',
                FieldType: FieldType.DROPDOWN,
                Label: 'SAF-T kobling',
                Tooltip: {
                    Text: 'Kobler kontoen til saf-t standard konto',
                },
                FeaturePermission: 'ui.accounting.advanced-account-settings',
                Options: {
                    source: () => this.accountService.getSaftMappingAccounts(),
                    template: (data: SaftMappingAccount) => `${data.AccountID} - ${data.Description}`,
                    valueProperty: 'ID',
                },
            },
            {
                Property: '_AltinnAccountNumber',
                FieldType: FieldType.DROPDOWN,
                Label: 'Kobling mot årsavslutning',
                FeaturePermission: 'ui.accounting.advanced-account-settings',
                Options: {
                    source: () => this.altinnAccountLinkService.getAltinnAccounts(),
                    valueProperty: 'AccountNumber',
                    template: (item) => (item ? `${item.AccountNumber} - ${item.AccountName}` : ''),
                },
            },
        ];
    }
}
