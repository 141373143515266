import { GridApi, ICellRendererParams, IRowNode } from 'ag-grid-community';

let config;

export class CellRenderer {
    static getHeaderCheckbox(tableConfig) {
        config = tableConfig;
        return HeaderCheckbox;
    }
}

export class HeaderCheckbox {
    gridApi: GridApi;
    element: HTMLElement;
    checkbox: HTMLInputElement;

    updateCheckboxState = () => {
        let nodes: IRowNode<any>[] = [];
        this.gridApi.forEachNode((node) => nodes.push(node));

        const allRowsSelected = nodes.length > 0 && nodes.every((node) => node.isSelected());
        this.checkbox.checked = allRowsSelected;
    };

    init(params: ICellRendererParams) {
        this.gridApi = params.api;

        const el = document.createElement('label');
        el.classList.add('header-checkbox');

        const checkbox = document.createElement('input');
        checkbox.type = 'checkbox';
        el.appendChild(checkbox);

        this.checkbox = checkbox;

        checkbox.onchange = () => {
            const nodes = [];
            params.api.forEachNode((node) => nodes.push(node));
            params.api.setNodesSelected({ nodes, newValue: checkbox.checked });

            // if (checkbox.checked) {
            //     // getRenderedNodes?
            //     params.api.forEachNode(row => {
            //         row.setSelected(true);
            //     });
            // } else {
            //     params.api.forEachNode(row => row.setSelected(false));
            // }
        };

        this.element = el;

        this.updateCheckboxState();
        this.gridApi.addEventListener('modelUpdated', this.updateCheckboxState);
    }

    getGui() {
        return this.element;
    }

    destroy() {
        this.gridApi.removeEventListener('modelUpdated', this.updateCheckboxState);
    }
}
