<section class="uni-image-header">
    <section class="name-and-actions bordered">
        <span *ngIf="!attachmentData" class="file-name">{{ file?.Name }}</span>
        <span *ngIf="attachmentData" (click)="attachmentData = undefined" class="hide-attachment">
            <i class="material-icons">keyboard_backspace</i>
            Tilbake til EHF
        </span>

        <section class="actions">
            <ng-container *ngIf="file?._ehfAttachments?.length">
                <a class="attachments-toggle" #toggle>
                    Vedlegg
                    <i class="material-icons">expand_more</i>
                </a>

                <dropdown-menu [trigger]="toggle">
                    <ng-template>
                        <ng-container *ngFor="let attachment of file?._ehfAttachments">
                            <!-- File -->
                            <section
                                *ngIf="attachment.label"
                                class="attachment-item selectable"
                                (click)="showAttachment(attachment)"
                            >
                                <span
                                    *ngIf="getFileType(attachment); let fileType"
                                    class="file-type-indicator"
                                    [ngClass]="fileType"
                                >
                                    {{ fileType }}
                                </span>

                                {{ attachment.label }}
                            </section>

                            <!-- External link -->
                            <a
                                *ngIf="attachment.externalUrl"
                                class="attachment-item selectable"
                                [href]="attachment.externalUrl"
                                target="_blank"
                            >
                                {{ attachment.label }}
                            </a>

                            <!-- Plain text -->
                            <section
                                *ngIf="attachment.plaintext"
                                class="attachment-item"
                                (click)="$event.stopPropagation()"
                            >
                                {{ attachment.label }}: {{ attachment.plaintext }}
                            </section>
                        </ng-container>
                    </ng-template>
                </dropdown-menu>
            </ng-container>

            <i *ngIf="!file.Deleted" (click)="print()" matTooltip="Skriv ut" class="material-icons">print</i>
            <i *ngIf="!file.Deleted" (click)="downloadSource()" matTooltip="Last ned fil" class="material-icons"
                >get_app</i
            >
            <i (click)="popout.emit()" matTooltip="Åpne i nytt vindu" class="material-icons fileviewer-toggle"
                >open_in_new</i
            >
            <i
                role="button"
                *ngFor="let actions of fileActions"
                matTooltip="{{ actions.infotext }}"
                class="material-icons open-file-viewer"
                (click)="actions.action()"
            >
                {{ actions.icon }}
            </i>
        </section>
    </section>
</section>

<section class="ehf-container custom-scrollbar">
    <!-- Attachment -->
    <ng-container *ngIf="attachmentData">
        <img *ngIf="isImage(attachmentData)" [src]="attachmentData.url" />

        <object *ngIf="!isImage(attachmentData)" [data]="attachmentData.url" [type]="attachmentData.mimeType"></object>
    </ng-container>

    <!-- EHF Markup -->
    <section *ngIf="!attachmentData" [innerHTML]="file?._ehfMarkup"></section>
</section>
