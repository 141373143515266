import {
    NgModule,
    TemplateRef,
    Component,
    Input,
    ViewChild,
    ChangeDetectionStrategy,
    ContentChild,
    SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayModule, OverlayRef, Overlay, OverlayConfig, OverlaySizeConfig } from '@angular/cdk/overlay';
import { PortalModule, CdkPortal } from '@angular/cdk/portal';
import { ObserversModule } from '@angular/cdk/observers';

@Component({
    selector: 'input-dropdown-menu',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <section
            *cdkPortal
            (cdkObserveContent)="onContentChange()"
            class="input-dropdown-menu"
            [class.large-shadow]="largeShadow"
            (click)="$event.stopPropagation()"
        >
            <ng-container *ngTemplateOutlet="content"></ng-container>
        </section>
    `,
})
export class InputDropdownMenu {
    @ContentChild(TemplateRef, { static: true }) content: TemplateRef<any>;
    @ViewChild(CdkPortal, { static: true }) contentTemplate: CdkPortal;
    @Input() input: HTMLElement;
    @Input() visible: boolean;
    @Input() maxHeight: string;
    @Input() largeShadow: boolean;

    protected overlayRef: OverlayRef;

    sizeConfig: OverlaySizeConfig;

    constructor(protected overlay: Overlay) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes['visible']) {
            // !! because it might not be a boolean
            const current = !!this.visible;
            const previous = !!changes['visible'].previousValue;

            if (current !== previous) {
                if (this.visible) {
                    this.show();
                } else {
                    this.hide();
                }
            }
        }
    }

    onContentChange() {
        setTimeout(() => {
            if (this.overlayRef) {
                this.overlayRef.updateSize(this.sizeConfig);
                this.overlayRef.updatePosition();
            }
        });
    }

    ngOnDestroy() {
        this.hide();
    }

    show() {
        if (this.overlayRef && this.overlayRef.dispose) {
            this.overlayRef.detach();
            this.overlayRef.dispose();
        }

        this.overlayRef = this.overlay.create(this.getOverlayConfig());
        this.overlayRef.attach(this.contentTemplate);
        const refRect = this.input.getBoundingClientRect();

        this.sizeConfig = {
            minWidth: refRect.width || '10rem',
            maxHeight: this.maxHeight || '100%',
        };

        this.overlayRef.updateSize(this.sizeConfig);
    }

    hide() {
        if (this.overlayRef) {
            this.visible = false;
            this.overlayRef.detach();
            this.overlayRef.dispose();
            this.overlayRef = undefined;
        }
    }

    protected getOverlayConfig(): OverlayConfig {
        const positionStrategy = this.overlay
            .position()
            .flexibleConnectedTo(this.input)
            .withPush(false)
            .withPositions([
                { originX: 'start', originY: 'bottom', overlayX: 'start', overlayY: 'top' },
                { originX: 'end', originY: 'bottom', overlayX: 'end', overlayY: 'top' },
                { originX: 'start', originY: 'top', overlayX: 'start', overlayY: 'bottom' },
                { originX: 'end', originY: 'top', overlayX: 'end', overlayY: 'bottom' },
            ]);

        return new OverlayConfig({
            positionStrategy: positionStrategy,
            scrollStrategy: this.overlay.scrollStrategies.reposition(),
        });
    }
}

@NgModule({
    imports: [CommonModule, OverlayModule, PortalModule, ObserversModule],
    declarations: [InputDropdownMenu],
    exports: [InputDropdownMenu],
})
export class InputDropdownModule {}
