import { Component, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { IModalOptions, IUniModal } from '@uni-framework/uni-modal/interfaces';

@Component({
    selector: 'admin-lack-of-access-modal',
    templateUrl: './admin-lack-of-access-modal.html',
    styleUrls: ['./admin-lack-of-access-modal.sass'],
})
export class AdminLackOfAccessModal implements IUniModal {
    options: IModalOptions = {};
    onClose: EventEmitter<any> = new EventEmitter();

    constructor(private router: Router) {}

    redirectToUserSettingsPage() {
        this.router.navigate(['/settings/users'], {
            queryParams: { id: this.options?.data?.currentUserId, openRoleModal: true },
        });
        this.onClose.emit();
    }

    setDontAskAgainFlag() {
        localStorage.setItem(this.options?.data?.flagKey, 'true');
        this.onClose.emit();
    }

    closeModal() {
        this.onClose.emit();
    }
}
