import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BizHttp } from '../../../framework/core/http/BizHttp';
import {
    AssignmentDetails,
    BankIntegrationAgreement,
    PaymentBatch,
    PaymentBatchApproveStatus,
    StatusCodePaymentBatch,
} from '../../unientities';
import { UniHttp } from '../../../framework/core/http/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApprovalService } from '../assignments/approvalService';
import { AuthService } from '@app/authService';
import { THEMES, theme } from 'src/themes/theme';

@Injectable({ providedIn: 'root' })
export class PaymentBatchService extends BizHttp<PaymentBatch> {
    constructor(
        http: UniHttp,
        private approvalService: ApprovalService,
        private authService: AuthService,
    ) {
        super(http);
        this.relativeURL = PaymentBatch.RelativeUrl;
        this.entityType = PaymentBatch.EntityType;
        this.DefaultOrderBy = null;
    }

    public revertPaymentBatch(
        paymentBatchID: number,
        recreatePayments: Boolean = false,
        manualRevert: Boolean = false,
    ): Observable<any> {
        return super.PutAction(
            null,
            'revert-payment-batch',
            `ID=${paymentBatchID}&recreatePayments=${recreatePayments}&manual=${manualRevert}`,
        );
    }

    public generatePaymentFile(
        paymentBatchID: number,
        skipNegativePayeeValidation: Boolean = false,
    ): Observable<PaymentBatch> {
        return super.PutAction(
            null,
            'generate-payment-file',
            `ID=${paymentBatchID}&acceptjob=true&skipNegativePayeeValidation=${skipNegativePayeeValidation}`,
        );
    }

    public assign(paymentBatchID: number, assignmentDetails: AssignmentDetails): Observable<any> {
        return this.http
            .asPOST()
            .usingBusinessDomain()
            .withEndPoint(`${this.relativeURL}/${paymentBatchID}?action=assignTo`)
            .withBody(assignmentDetails)
            .send()
            .pipe()
            .pipe(
                map((response) => {
                    this.approvalService.invalidateCache();
                    return response.body;
                }),
            );
    }

    public registerBankFile(fileID: number): Observable<any> {
        return this.http
            .asPOST()
            .usingRootDomain()
            .withEndPoint('bank/register-fileID?fileID=' + fileID)
            .send()
            .pipe(map((response) => response.body));
    }

    getFileStatuses(fileIDs: number[]) {
        return this.ActionWithBody(null, fileIDs, 'get-file-statuses-from-file-ids', 'put');
    }

    public checkAutoBankAgreement(filter: string = ''): Observable<BankIntegrationAgreement[]> {
        return this.http
            .asGET()
            .usingBusinessDomain()
            .withEndPoint('bank-agreements?expand=BankAccount.Bank,BankAccount.BankAccountSettings&filter=' + filter)
            .send()
            .pipe(map((response) => response.body));
    }

    public getAutoBankAgreement(ID: number) {
        return this.http
            .asGET()
            .usingBusinessDomain()
            .withEndPoint(`bank-agreements/${ID}?expand=BankAccount.Bank,BankAccount.BankAccountSettings`)
            .send()
            .pipe(map((response) => response.body));
    }

    public getStatusText(statusCode: number, isCustomerPayment: boolean): string {
        const word = isCustomerPayment ? 'Innbetaling' : 'Kvittering';

        if (!statusCode) {
            return '';
        } else if (statusCode === 45001) {
            return 'Opprettet';
        } else if (statusCode === 45002) {
            return 'Opprettet betalingsfil';
        } else if (statusCode === 45003) {
            return 'Sendt til bank';
        } else if (statusCode === 45004) {
            return `${word} mottatt`;
        } else if (statusCode === 45005) {
            return `${word} innlest`;
        } else if (statusCode === 45006) {
            return 'Avvist fra bank';
        } else if (statusCode === 45007) {
            return `${word} er ufullstendig`;
        } else if (statusCode === 45008) {
            return 'Delvis fullført';
        } else if (statusCode === 45009) {
            return 'Fullført';
        } else if (statusCode === 45010) {
            return 'Transit til bank';
        } else if (statusCode === 45011) {
            return 'Klar for godkjenning';
        } else if (statusCode === 45012) {
            return 'Godkjent';
        } else if (statusCode === 45013) {
            return 'Avvist';
        } else if (statusCode === 45014) {
            return 'Kommunikasjonsfeil. Vennligst dobbelsjekk i nettbanken';
        }
        return 'Ukjent status: ' + statusCode;
    }

    public sendToPayment(batchID: number, body): Observable<any> {
        super.invalidateCache();
        return this.http
            .asPUT()
            .usingBusinessDomain()
            .withBody(body)
            .withEndPoint(this.relativeURL + '?action=send-batch-to-payment&batchID=' + batchID)
            .send()
            .pipe(map((response) => response.body));
    }

    public sendAutobankPayment(body: any) {
        super.invalidateCache();
        return this.http
            .asPUT()
            .usingBusinessDomain()
            .withBody(body)
            .withEndPoint('paymentbatches?action=create-and-send-to-payment')
            .send()
            .pipe(map((response) => response.body));
    }

    public updatePaymentsToPaidAndJournalPayments(paymentIDs: number[]) {
        super.invalidateCache();
        return this.http
            .asPUT()
            .usingBusinessDomain()
            .withBody(paymentIDs)
            .withEndPoint('paymentbatches?action=update-payments-to-paid-and-journal-payments')
            .send()
            .pipe(map((response) => response.body));
    }

    public updateAllPaymentsToPaidAndJournalPayments(hashAndFilter: string) {
        super.invalidateCache();
        return this.http
            .asPUT()
            .usingBusinessDomain()
            .withEndPoint(`paymentbatches?action=update-all-payments-to-paid-and-journal-payments${hashAndFilter}`)
            .send()
            .pipe(map((response) => response.body));
    }

    public updatePaymentsToPaid(paymentIDs: number[]) {
        super.invalidateCache();
        return this.http
            .asPUT()
            .usingBusinessDomain()
            .withBody(paymentIDs)
            .withEndPoint('paymentbatches?action=update-payments-to-completed')
            .send()
            .pipe(map((response) => response.body));
    }

    public updateAllPaymentsToPaid(hashAndFilter: string) {
        super.invalidateCache();
        return this.http
            .asPUT()
            .usingBusinessDomain()
            .withEndPoint(`paymentbatches?action=update-all-payments-to-completed${hashAndFilter}`)
            .send()
            .pipe(map((response) => response.body));
    }

    public sendPasswordToTwoFactor(body: any) {
        return this.http
            .asPOST()
            .usingBusinessDomain()
            .withBody(body)
            .withEndPoint('bank-agreements?action=auth-code')
            .send()
            .pipe(map((response) => response.body));
    }

    public approveBatch(paymentBatchID: number): Observable<any> {
        return super.PostAction(paymentBatchID, 'approve', `ID=${paymentBatchID}`);
    }

    public getRemainingApprovers(paymentBatchID: number) {
        return super.GetAction(paymentBatchID, 'get-remaining-approvals');
    }

    /*
        bankIDBaseRedirectURL: Add this to function call param if your component handles the return params from BankID check. If this
        is not set, it will redirect to bank-component after BankID check, and this component handles all params
    */
    public redirectToBankID(paymentBatch: PaymentBatch, bankIDBaseRedirectURL?: string) {
        const scheme = theme.theme === THEMES.EXT02 ? 'bankiddnbsso' : 'eika'; //retrieve from som setting
        const fallBackURL = window.location.href.split('#')[0] + '#/bank/ticker?code=payment_list&filter=not_paid';
        const startSign = (bankIDBaseRedirectURL || fallBackURL).indexOf('?') >= 0 ? '&' : '?';
        const redirecturl =
            (bankIDBaseRedirectURL || fallBackURL) +
            `${startSign}hashValue=${paymentBatch.HashValue}&batchID=${paymentBatch.ID}`;
        const abortRedirecturl = `${redirecturl}&aborted=true`;
        const errorRedirecturl = `${redirecturl}&error=true`;
        const bankIDURL =
            environment.authority +
            `/External/InitSigning?scheme=${scheme}&securityHash=${paymentBatch.HashValue}&redirectUrl=${encodeURIComponent(redirecturl)}` +
            `&AbortRedirectUrl=${encodeURIComponent(abortRedirecturl)}&ErrorRedirectUrl=${encodeURIComponent(errorRedirecturl)}`;
        window.location.href = bankIDURL;
    }

    public getApprovalText(approvalStatus: PaymentBatchApproveStatus) {
        if (approvalStatus.StatusCode === StatusCodePaymentBatch.ForApproval) {
            if (approvalStatus.Message === 'verify') {
                return `Betalingsbunt er opprettet, sendt til bank, men ikke godkjent grunnet BankID ikke er verifisert, en eller flere godkjennere gjenstår`;
            } else if (approvalStatus.Message === '2fa') {
                return `Betalingsbunt er opprettet, sendt til bank, men ikke godkjent grunnet to-faktor har utløpt, en eller flere godkjennere gjenstår`;
            } else if (approvalStatus.Message || approvalStatus.ApprovalsRemaining > 0) {
                let approveMessage = approvalStatus.Message ? ` (${approvalStatus.Message})` : '';
                return (
                    `Utbetalingsbunt er sendt, men ikke godkjent og det gjenstår ${approvalStatus.ApprovalsRemaining} godkjenner(e).` +
                    approveMessage
                );
            } else {
                return `Utbetalingsbunt er sendt, men ikke godkjent, en eller flere godkjennere gjenstår`;
            }
        } else {
            return `Utbetalingsbunt er sendt og godkjent`;
        }
    }
}
