import { Pipe, PipeTransform } from '@angular/core';
import { UniFormError } from '@uni-framework/ui/uniform';
import { template } from 'lodash-es';

@Pipe({
    name: 'uniformErrorTemplate',
    pure: false,
})
export class UniformErrorTemplatePipe implements PipeTransform {
    public transform(error: UniFormError): string {
        return template(error.errorMessage)(error);
    }
}
