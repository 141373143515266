import { Injectable } from '@angular/core';
import { BizHttp } from '../../../framework/core/http/BizHttp';
import { Supplier } from '../../unientities';
import { UniHttp } from '../../../framework/core/http/http';
import { StatisticsService } from '../common/statisticsService';
import { Observable, of } from 'rxjs';
import { StatisticsResponse } from '@app/models/StatisticsResponse';
import { cloneDeep } from 'lodash-es';

@Injectable({ providedIn: 'root' })
export class SupplierService extends BizHttp<Supplier> {
    constructor(
        http: UniHttp,
        private statisticsService: StatisticsService,
    ) {
        super(http);

        // TODO: should resolve this from configuration based on type (IVatType)? Frank is working on something..
        this.relativeURL = Supplier.RelativeUrl;

        this.entityType = Supplier.EntityType;

        // set this property if you want a default sort order from the API
        this.DefaultOrderBy = null;

        // disabling this beacuse of the possibility to change supplier status (aktive/inactive/block) when creating a supplier invoice RIG-2408
        super.disableCache();
    }

    public deleteSupplier(id: any): any {
        return this.http
            .asPUT()
            .withDefaultHeaders()
            .usingBusinessDomain()
            .withEndPoint(`${this.relativeURL}?action=delete&ID=${id}`)
            .send();
    }

    public getSuppliers(organizationNumber: string): Observable<StatisticsResponse> {
        if (!organizationNumber) {
            return of(null);
        }

        const qry =
            '' +
            'model=Supplier&select=Supplier.SupplierNumber as SupplierNumber,Info.Name as Name' +
            '&filter=Supplier.OrgNumber eq ' +
            organizationNumber +
            '&expand=Info';
        return this.statisticsService.GetAll(qry);
    }

    public activateSupplier(id: any): any {
        return this.http
            .asPUT()
            .withDefaultHeaders()
            .usingBusinessDomain()
            .withEndPoint(`${this.relativeURL}?action=activate&ID=${id}`)
            .send();
    }

    public deactivateSupplier(id: any): any {
        return this.http
            .asPUT()
            .withDefaultHeaders()
            .usingBusinessDomain()
            .withEndPoint(`${this.relativeURL}?action=deactivate&ID=${id}`)
            .send();
    }

    public blockSupplier(id: number, isBlock: boolean = false) {
        const action: string = isBlock ? 'block' : 'unblock';
        return this.http
            .asPUT()
            .usingBusinessDomain()
            .withEndPoint(`${this.relativeURL}?action=${action}&ID=${id}`)
            .send();
    }

    Put(ID, entity) {
        return super.Put(ID, this.removeBusinessRelationDuplicates(cloneDeep(entity)));
    }

    Post(entity) {
        return super.Post(this.removeBusinessRelationDuplicates(cloneDeep(entity)));
    }

    private removeBusinessRelationDuplicates(supplier: Supplier) {
        if (!supplier?.Info) {
            return supplier;
        }

        supplier.Info.Addresses = supplier.Info.Addresses?.filter((address) => {
            return (
                !!address.ID ||
                (address._createguid !== supplier.Info.InvoiceAddress?._createguid &&
                    address._createguid !== supplier.Info.ShippingAddress?._createguid)
            );
        });

        supplier.Info.Phones = supplier.Info.Phones?.filter((phone) => {
            return !!phone.ID || phone._createguid !== supplier.Info.DefaultPhone?._createguid;
        });

        supplier.Info.Emails = supplier.Info.Emails?.filter((email) => {
            return !!email.ID || email._createguid !== supplier.Info.DefaultEmail?._createguid;
        });

        return supplier;
    }
}
