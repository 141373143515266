<section role="dialog" class="uni-modal" [style.width]="options?.width">
    <header>{{ (!options?.showHeaderBelowIllustration ? options.header : '') | translate }}</header>

    <article [ngClass]="options?.class" [class.horizontal]="options?.alignHorizontal">
        <uni-illustration-deprecated
            *ngIf="options.illustrationPath"
            class="illustration"
            [path]="options.illustrationPath"
        ></uni-illustration-deprecated>
        <uni-illustration
            *ngIf="options?.illustration"
            class="illustration"
            [name]="options.illustration"
        ></uni-illustration>

        <h1 class="low-header" *ngIf="options?.showHeaderBelowIllustration">
            {{ options?.header || '' | translate }}
        </h1>

        <section class="alert warn mb-0" *ngIf="options.warning">
            <i class="material-icons warn"> info</i>
            <span [innerHtml]="options.warning"></span>
        </section>

        <section [innerHtml]="options.message | translate"></section>

        <rig-checkbox *ngIf="options?.checkbox" [(ngModel)]="options.checkbox.value">
            {{ options.checkbox.label }}
        </rig-checkbox>

        <section class="alert info mb-0" *ngIf="options.info">
            <i class="material-icons info"> info</i>
            <span [innerHtml]="options.info"></span>
        </section>
    </article>

    <footer [ngClass]="options?.footerCls">
        <button
            *ngIf="options.buttonLabels.cancel"
            (click)="cancel()"
            class="secondary"
            [class.pull-left]="options.buttonLabels.reject && options.buttonLabels.accept"
        >
            {{ options.buttonLabels.cancel }}
        </button>

        <button
            *ngIf="options.buttonLabels.reject"
            [ngClass]="options.rejectButtonClass || 'secondary'"
            (click)="reject()"
        >
            {{ options.buttonLabels.reject }}
        </button>

        <button
            *ngIf="options.buttonLabels.accept"
            [ngClass]="options.submitButtonClass || 'c2a'"
            [disabled]="options?.checkbox?.mustConfirmToAccept && !options?.checkbox?.value"
            uniAutoFocus
            (click)="accept()"
        >
            {{ options.buttonLabels.accept }}
        </button>
    </footer>
</section>
