<section role="dialog" class="uni-modal" style="width: 55rem">
    <header>Avansert filtrering</header>

    <article>
        <query-builder
            [fields]="queryFields"
            [(query)]="queryItems"
            (queryChange)="onQueryChange()"
            [includeNotSetOperator]="true"
            [includeNotContainsOperator]="true"
            [siblingMaxDepth]="1"
        >
        </query-builder>
    </article>

    <footer>
        <button class="secondary pull-left" (click)="saveSearch()">
            <i class="material-icons">bookmark_border</i>
            Lagre søkefilter
        </button>

        <button class="secondary" (click)="onClose.emit()">Avbryt</button>
        <button class="c2a" (click)="onClose.emit(filters)">Aktiver søk</button>
    </footer>
</section>
