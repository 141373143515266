import { Component, Input, Output, EventEmitter, ElementRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject, fromEvent } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { FeaturePermissionService } from '@app/featurePermissionService';
import { theme } from 'src/themes/theme';
import { BrowserStorageService } from '@uni-framework/core/browserStorageService';
import { AuthService } from '@app/authService';

interface TabsUserPreferences {
    [tabName: string]: {
        hidden: boolean;
        sortIndex: number;
    };
}

export interface IUniTab {
    name: string;
    featurePermission?: string;
    routePermission?: string;
    path?: string;
    queryParams?: { [key: string]: any };
    disabled?: boolean;
    tooltip?: string;
    tooltipIcon?: string;
    tooltipClass?: string;
    value?: any;
    count?: number;
    onClick?: () => void;

    /**
        Hides the tab without altering indexes,  so for example *ngIf="activeTabIndex === 4"
        means tab 4 is active, regardless of if tab 1, 2 and 3 are visible or hidden to the user.
    */
    hidden?: boolean;

    _hasPermission?: boolean;
    _hiddenByUser?: boolean;
    _originalIndex?: number;
    _sortIndex?: number;
}

@Component({
    selector: 'uni-tabs',
    templateUrl: './uni-tabs.html',
    styleUrls: ['./uni-tabs.sass'],
})
export class UniTabs {
    @ViewChild('tabContainer', { static: true }) tabContainer: ElementRef<HTMLElement>;

    @Input() tabs: IUniTab[];
    @Input() queryParamsHandling: 'merge' | 'preserve' | '' = '';
    @Input() labelProperty: string = 'name';
    @Input() counterProperty: string = 'count';

    @Input() useRouterLinkTabs: boolean = false;
    @Input() configStoreKey: string;

    @Input() activeIndex: number;

    @Output() activeIndexChange = new EventEmitter<number>(false);
    @Output() tabClick = new EventEmitter<IUniTab>(false);

    onDestroy$ = new Subject();
    overflowIndex: number;
    pathMatchExact: boolean;

    currentTheme = theme.theme;

    // Updated in template. Allows us to only show indicator on keyboard navigation, not when clicking a tab.
    // This can be replaced by :focus-visible when safari add support for that.
    hideFocusIndicator: boolean;
    focusIndex: number;

    defaultTabVisibility: { [name: string]: boolean } = {};
    tabVisibility: { [name: string]: boolean } = {};

    defaultTabs: IUniTab[];
    userPreferences: TabsUserPreferences = {};

    displayedTabs: IUniTab[];
    sortedTabs: IUniTab[];

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private cdr: ChangeDetectorRef,
        private featurePermissionService: FeaturePermissionService,
        private browserStorageService: BrowserStorageService,
        private authService: AuthService,
    ) {}

    ngOnChanges(changes) {
        if (changes['tabs'] && this.tabs) {
            if (this.tabs.some((tab) => !!tab.path)) {
                const url = window.location.href;
                const activeIndex = this.tabs.findIndex((route) => {
                    return url.includes(route.path);
                });

                this.activeIndex = activeIndex >= 0 ? activeIndex : 0;
            }

            this.pathMatchExact = this.tabs.some((tab) => !!tab.queryParams);
            this.applyUserPreferencesAndSortTabs();

            setTimeout(() => {
                this.checkOverflow();
            }, 200);
        }
    }

    ngAfterViewInit() {
        fromEvent(window, 'resize')
            .pipe(takeUntil(this.onDestroy$), debounceTime(100))
            .subscribe(() => this.checkOverflow());
    }

    ngOnDestroy() {
        this.onDestroy$.next(undefined);
        this.onDestroy$.complete();
    }

    applyUserPreferencesAndSortTabs() {
        this.loadUserPreferences();

        this.tabs.forEach((tab, index) => {
            const prefs = this.userPreferences[tab.name];
            const user = this.authService.currentUser;
            const hasFeaturePermission =
                !tab.featurePermission || this.featurePermissionService.canShowUiFeature(tab.featurePermission);
            const hasRoutePermission =
                !tab.routePermission || this.authService.canActivateRoute(user, tab.routePermission);

            tab._hasPermission = hasFeaturePermission && hasRoutePermission;
            tab._originalIndex = index;
            tab._sortIndex = prefs?.sortIndex ?? index;
            tab._hiddenByUser = prefs?.hidden ?? false;
        });

        this.sortedTabs = [...this.tabs].sort((tab1, tab2) => tab1._sortIndex - tab2._sortIndex);
        this.syncFocusIndexToActiveIndex();
    }

    loadUserPreferences() {
        if (this.configStoreKey) {
            const allPreferenceObjects = this.browserStorageService.getItem('uni_tabs_preferences') || {};
            this.userPreferences = allPreferenceObjects[this.configStoreKey] || {};
        } else {
            this.userPreferences = {};
        }
    }

    saveAndApplyUserPreferences() {
        if (this.configStoreKey) {
            const allPreferenceObjects = this.browserStorageService.getItem('uni_tabs_preferences') || {};

            if (this.userPreferences && Object.keys(this.userPreferences).length) {
                allPreferenceObjects[this.configStoreKey] = this.userPreferences || {};
            } else {
                delete allPreferenceObjects[this.configStoreKey];
            }

            this.browserStorageService.setItem('uni_tabs_preferences', allPreferenceObjects);
            this.applyUserPreferencesAndSortTabs();
        }
    }

    resetUserPreferences() {
        this.userPreferences = {};
        this.saveAndApplyUserPreferences();
    }

    onDragDropEnd({ previousIndex, currentIndex }) {
        if (previousIndex !== currentIndex) {
            const item = this.sortedTabs.splice(previousIndex, 1)[0];
            this.sortedTabs.splice(currentIndex, 0, item);

            this.sortedTabs.forEach((tab, index) => {
                this.userPreferences[tab.name] = {
                    hidden: tab._hiddenByUser,
                    sortIndex: index,
                };
            });

            this.saveAndApplyUserPreferences();
        }
    }

    toggleTabVisibility(tab: IUniTab) {
        tab._hiddenByUser = !tab._hiddenByUser;

        this.userPreferences[tab.name] = {
            hidden: tab._hiddenByUser,
            sortIndex: tab._sortIndex,
        };

        this.saveAndApplyUserPreferences();
    }

    onKeyDown(event: KeyboardEvent) {
        if (event.key === 'ArrowRight' && this.focusIndex < this.sortedTabs.length - 1) {
            const nextTab = this.sortedTabs.slice(this.focusIndex + 1).find((tab) => !tab.hidden);
            if (nextTab) {
                this.focusIndex = this.sortedTabs.indexOf(nextTab);
                this.hideFocusIndicator = false;
            }
        }

        if (event.key === 'ArrowLeft' && this.focusIndex > 0) {
            const prevTab = this.sortedTabs
                .slice(0, this.focusIndex)
                .reverse()
                .find((tab) => !tab.hidden);
            if (prevTab) {
                this.focusIndex = this.sortedTabs.indexOf(prevTab);
                this.hideFocusIndicator = false;
            }
        }

        if (event.key === 'Enter') {
            const focusedTab = this.sortedTabs[this.focusIndex];
            if (focusedTab && focusedTab._originalIndex !== this.activeIndex) {
                this.onTabClick(this.sortedTabs[this.focusIndex]);
            }
        }
    }

    syncFocusIndexToActiveIndex() {
        this.focusIndex = this.sortedTabs.findIndex((tab) => tab._originalIndex === this.activeIndex);
    }

    onFocusOut() {
        this.hideFocusIndicator = false;
        this.focusIndex = undefined;
    }

    private checkOverflow() {
        setTimeout(() => {
            const hadDropdownBeforeCheck = this.overflowIndex >= 0;
            const tabContainer = this.tabContainer.nativeElement;
            const offsetTop = tabContainer.getBoundingClientRect().top;

            const overflowIndex = Array.from(tabContainer.children).findIndex((tab) => {
                const offset = tab.getBoundingClientRect().top - offsetTop;
                // > 5 to avoid borders and such messing with the calculation
                // The offsett will be much bigger on actual overflow
                return offset > 5;

                // return tab.getBoundingClientRect().top > offsetTop;
            });

            this.overflowIndex = overflowIndex;
            this.cdr.markForCheck();

            // If the dropdown wasn't visible before this check we need to
            // run it again after setting overflowIndex, in case the dropdown
            // toggle caused another item to overflow
            if (overflowIndex >= 0 && !hadDropdownBeforeCheck) {
                this.checkOverflow();
            }
        });
    }

    onTabClick(tab: IUniTab) {
        const focusIndex = this.sortedTabs.findIndex((t) => t === tab);

        if (!tab || tab.disabled || !(focusIndex >= 0)) {
            return;
        }

        this.activeIndex = tab._originalIndex;
        this.focusIndex = focusIndex;

        if (tab.path) {
            this.router.navigate([tab.path], {
                relativeTo: this.route,
                queryParamsHandling: this.queryParamsHandling,
            });
            this.tabClick.emit(tab);
        } else {
            this.activeIndexChange.emit(this.activeIndex);
            this.tabClick.emit(tab);
        }

        if (tab.onClick) {
            setTimeout(() => tab.onClick());
        }
    }
}
