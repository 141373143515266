import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ColumnButton } from '@uni-framework/ui/unitable';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
    selector: 'button-cell',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        @if (button && !row?._isSumRow) {
            <button (click)="onClick($event)" [ngClass]="button.class" [style.width]="button.width">
                {{ button.label }}
            </button>
        }
    `,
})
export class CellWithButtonRenderer implements ICellRendererAngularComp {
    row: any;
    button: ColumnButton;

    agInit(params) {
        this.row = params.node.data;
        this.button = params.buttonResolver(this.row);
    }

    refresh() {
        return false;
    }

    onClick(event: MouseEvent) {
        event.stopPropagation();
        this.button.action(this.row);
    }
}
