/*
    IMPORTANT: this code used in both uniImage.ts and fileviewer.html, make sure to test both if you make changes!

    Sharing data between the application and the fileviewer in a separate browser window using IndexedDB.
    We previously used localStorage, but it has a limit of 5mb, and some users were approaching that limit,
    causing the file sharing to stop working.
*/
async function openDB() {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open('fileviewer-data', 1)

        request.onsuccess = (event) => resolve(event.target.result);
        request.onerror = reject;

        request.onupgradeneeded = (event) => {
            const store = event.target.result.createObjectStore('store', { keyPath: 'key' });
            store.transaction.oncomplete = (event) => {
                resolve(event.target.db)
            }
        };
    });
}

export default {
    get: async () => {
        const db = await openDB();
        const store = db.transaction('store', 'readonly').objectStore('store');

        return new Promise((resolve, reject) => {
            const req = store.get('data');
            req.onerror = reject;
            req.onsuccess = (event) => resolve(event.target.result.value)
        });
    },
    set: async (data) => {
        const db = await openDB();
        const store = db.transaction('store', 'readwrite').objectStore('store');

        return new Promise((resolve, reject) => {
            const req = store.put({ key: 'data', value: data });
            req.onerror = reject;
            req.onsuccess = () => resolve(true);
        });
    }
}


