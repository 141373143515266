import { Component, EventEmitter } from '@angular/core';
import { IModalOptions, IUniModal } from '@uni-framework/uni-modal';

interface InputModalData {
    label?: string;
    value?: any;
    placeholder?: string;
    inputType?: string;
}

export interface InputModalOptions extends IModalOptions {
    data?: InputModalData;
}

@Component({
    selector: 'input-modal',
    template: `
        <section role="dialog" class="uni-modal" [style.width]="options?.width">
            <header>{{ options.header | translate }}</header>

            <article>
                <label *ngIf="data" class="uni-label label-above" uniAutoFocus>
                    <span>{{ data.label }}</span>

                    <input
                        *ngIf="data.inputType !== 'textarea'"
                        [type]="data.inputType || 'text'"
                        [(ngModel)]="data.value"
                        [placeholder]="data.placeholder"
                    />

                    <textarea
                        *ngIf="data.inputType === 'textarea'"
                        [(ngModel)]="data.value"
                        [placeholder]="data.placeholder"
                    >
                    </textarea>
                </label>
            </article>

            <footer>
                <button class="secondary" (click)="onClose.emit()">
                    {{ options.buttonLabels?.cancel || 'Avbryt' }}
                </button>

                <button (click)="onClose.emit(data.value)" [ngClass]="options?.submitButtonClass || 'c2a'">
                    {{ options.buttonLabels?.accept || 'Bekreft' }}
                </button>
            </footer>
        </section>
    `,
})
export class InputModal implements IUniModal {
    options: InputModalOptions = {};
    onClose = new EventEmitter();

    data: InputModalData;

    ngOnInit() {
        this.data = this.options?.data || {};
    }
}
