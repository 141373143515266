import { debounceTime } from 'rxjs/operators';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { IModalOptions, IUniModal } from '@uni-framework/uni-modal/interfaces';
import { BusinessRelationService } from '@app/services/sales/businessRelationService';
import { ErrorService } from '@app/services/common/errorService';

export interface IBrRegCompanyInfo {
    navn: string;
    orgnr: string;
    nkode1: string;
    nkode2: string;
    nkode3: string;
    forretningsadr: string;
    forradrpostnr: string;
    forradrpoststed: string;
    forradrland: string;
    postadresse: string;
    ppostnr: string;
    ppoststed: string;
    ppostland: string;
    tlf_mobil: string;
    tlf: string;
    url: string;
    forradrkommnavn: string;
    forradrkommnr: string;
    organisasjonsform: string;
}

@Component({
    selector: 'uni-brreg-modal',
    templateUrl: './brRegModal.html',
    styleUrls: ['./brRegModal.sass'],
})
export class UniBrRegModal implements IUniModal {
    @Input()
    public options: IModalOptions = {};

    @Output()
    public onClose: EventEmitter<IBrRegCompanyInfo> = new EventEmitter();

    public searchControl: UntypedFormControl = new UntypedFormControl('');
    public searchResults: IBrRegCompanyInfo[] = [];
    public selectedIndex: number;

    constructor(
        private brService: BusinessRelationService,
        private errorService: ErrorService,
    ) {
        this.searchControl.valueChanges.pipe(debounceTime(300)).subscribe((query) => {
            if (query?.length > 2) {
                this.performLookup(query);
            }
        });
    }

    private performLookup(query: string) {
        this.selectedIndex = undefined;
        this.brService.search(query).subscribe(
            (res) => (this.searchResults = res),
            (err) => this.errorService.handle(err),
        );
    }

    public selectItem(index: number) {
        this.selectedIndex = index;
    }

    public close(emitValue?: boolean) {
        const selectedItem = this.searchResults && this.searchResults[this.selectedIndex];
        if (emitValue && selectedItem) {
            this.onClose.emit(selectedItem);
        } else {
            this.onClose.emit();
        }
    }
}
