<ng-container *ngIf="statusIndicator">
    <span role="button" #toggle class="toolbar-info-item pointer">
        <i class="material-icons-outlined sharing-status-indicator-icon" [ngClass]="statusIndicator?.class">{{
            statusIndicator?.icon || 'info'
        }}</i>
        {{ statusIndicator?.label }}
    </span>

    <dropdown-menu [trigger]="toggle" [hoverMode]="true">
        <ng-template>
            <section class="sharing-status-toolbar-dropdown-container">
                <section class="sharing-item flex align-center" *ngFor="let entry of sharingHistory">
                    <span class="text ellipsis">{{ entry._text | translate }}</span>
                    <span class="date">{{
                        entry.SharingCreatedAt || entry.SharingUpdatedAt | date: 'dd.MM.yyyy HH:mm'
                    }}</span>
                    <span class="uni-chip" [ngClass]="entry._class">{{ entry._status }}</span>
                </section>
                <section class="flex">
                    <a routerLink="/sharings">Gå til utsendingsliste</a>
                    <a
                        class="tertiary c2a ml-4"
                        (click)="openAttachment()"
                        *ngIf="sharingHistory?.length && sharingHistory[0]?.attachment"
                    >
                        Se genererte faktura
                    </a>
                </section>
            </section>
        </ng-template>
    </dropdown-menu>
</ng-container>
