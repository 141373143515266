import { Injectable } from '@angular/core';
import { UniModalService } from '@uni-framework/uni-modal/modalService';
import { ConfirmActions } from '@uni-framework/uni-modal/interfaces';
import { ConfirmTwoFactorModal } from '@uni-framework/uni-modal/modals/confirmTwofactor-modal/confirm-twofactor-modal';
import { ToastService, ToastType, ToastTime } from '@uni-framework/uniToast/toastService';
import {
    ApprovalStatus,
    PaymentBatch,
    PaymentBatchApproveStatus,
    StatusCodePaymentBatch,
    Approval,
} from '@uni-entities';
import { UniNumberFormatPipe } from '@uni-framework/pipes/uniNumberFormatPipe';
import { PaymentBatchService } from '../accounting/paymentBatchService';
import { ErrorService } from '../common/errorService';
import { UserService } from '../common/userService';
import { AuthService } from '@app/authService';
import { from, of, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApprovalService } from '../assignments/approvalService';

@Injectable({ providedIn: 'root' })
export class ZDataPaymentService {
    constructor(
        private modalService: UniModalService,
        private toastService: ToastService,
        private numberformat: UniNumberFormatPipe,
        private paymentBatchService: PaymentBatchService,
        private errorService: ErrorService,
        private userService: UserService,
        private authService: AuthService,
        private approvalService: ApprovalService,
    ) {}

    // Note: ZData has changed name to Aritma

    /*
     * Check if BankID is verified, return or redirect to ZData IDS
     */

    public redirectIfNotVerifiedWithBankId(parameters: string) {
        return new Promise<void>((resolve, reject) => {
            from(this.authService.refreshToken()).subscribe((authOK) => {
                if (authOK) {
                    this.toastService.showLoadIndicator({
                        title: 'Verifiserer BankID',
                        message: 'Vennligst vent mens vi sjekker BankID hos Aritma',
                    });
                    this.userService.getBankIdVerification().subscribe(
                        (verification) => {
                            this.toastService.hideLoadIndicator();

                            if (verification.IsVerified) {
                                resolve();
                            } else {
                                this.modalService
                                    .confirm({
                                        header: 'Vi trenger en ekstra identifisering med BankID',
                                        message: `Regnskapsgodkjente betalinger krever at brukeren din verifiseres med BankID hos vår samarbeidspartner Aritma.
                                                Sammenkoblingen mot din BankID er gyldig i 90 dager. Når du har fullført identifiseringen vil du tas tilbake 
                                                til utbetalingslisten for å sende betalingen på nytt.
                                                <br><br>Gå til BankID for å fortsette identifiseringen.`,
                                        buttonLabels: {
                                            accept: 'Gå til BankID',
                                        },
                                    })
                                    .onClose.subscribe((confirm) => {
                                        if (confirm === ConfirmActions.ACCEPT) {
                                            let path = `${window.location.href.split('/#')[1]}&${parameters}`;
                                            window.location.href = this.userService.getBankIdRedirectUrl(
                                                path,
                                                verification.ClientID,
                                            );
                                        } else {
                                            reject();
                                        }
                                    });
                            }
                        },
                        (err) => {
                            this.toastService.hideLoadIndicator();
                            this.errorService.handle(err);
                            reject();
                        },
                    );
                }
            });
        });
    }

    /*
     * Approve with two factor
     */

    public approveBatchWithTwoFactor(paymentBatchId: string | number) {
        return new Promise<void>((resolve, reject) => {
            const modal = this.modalService.open(ConfirmTwoFactorModal, {
                header: 'Godkjenn utbetaling',
                closeOnClickOutside: false,
            });

            modal.onClose.subscribe((result) => {
                if (result === ConfirmActions.ACCEPT) {
                    this.approvalService
                        .getApprovals({
                            status: ApprovalStatus.Active,
                            entityIDs: [Number(paymentBatchId)],
                            onlyForCurrentUser: true,
                            entityType: 'PaymentBatch',
                        })
                        .subscribe((approvals) => {
                            if (!approvals.length) {
                                reject('Ingen godkjenninger er tildelt deg');
                            } else {
                                var approval = approvals[0];
                                this.approvalService
                                    .PostAction(approval.ID, 'approve')
                                    .pipe(
                                        map(() => ({ ID: approval.ID, success: true, err: null })),
                                        catchError((err) => of({ ID: approval.ID, success: false, err: err })),
                                    )
                                    .subscribe((result) => {
                                        if (!result.success && result.err) {
                                            this.errorService.handle(result.err);
                                        }
                                        result.success ? resolve() : reject('Godkjenning mislykkes');
                                    });
                            }
                        });
                } else if (result === ConfirmActions.REJECT) {
                    reject(null);
                }
            });
        });
    }

    /*
     * Send two factor payments
     */
    public sendPaymentWithTwoFactor(paymentBatch: PaymentBatch) {
        return new Promise((resolve, reject) => {
            this.modalService
                .open(ConfirmTwoFactorModal, {
                    header: 'Godkjenn betaling med to-faktor',
                    buttonLabels: {
                        accept: 'Godkjenn og betal',
                        cancel: 'Avbryt',
                    },
                    data: {
                        reference: paymentBatch.PaymentReferenceID,
                    },
                    closeOnClickOutside: false,
                    message: `Send ${paymentBatch.NumberOfPayments} ${paymentBatch.NumberOfPayments > 1 ? 'betalinger' : 'betaling'} med totalsum på <strong>${this.numberformat.transform(paymentBatch.TotalAmount, 'money')}</strong> til bank og godkjenn med to-faktor`,
                })
                .onClose.subscribe((result) => {
                    if (result === ConfirmActions.ACCEPT) {
                        const body = {
                            HashValue: paymentBatch.HashValue,
                        };

                        this.paymentBatchService.sendToPayment(paymentBatch.ID, body).subscribe(
                            (approvalStatus: PaymentBatchApproveStatus) => {
                                if (approvalStatus.StatusCode === StatusCodePaymentBatch.Approved) {
                                    this.toastService.addToast(
                                        'Sendt til bank',
                                        ToastType.good,
                                        8,
                                        `Betalingsbunt er opprettet, sendt til bank og godkjent`,
                                    );
                                }
                                resolve(approvalStatus);
                            },
                            (err) => {
                                this.toastService.addToast(
                                    'Kunne ikke sende til bank',
                                    ToastType.warn,
                                    15,
                                    'Betaling ble opprettet, men kunne ikke sende den til banken. Gå til Bank - Utbetalinger og send den på nytt.',
                                );
                                this.errorService.handle(err);
                                resolve(null);
                            },
                        );
                    } else {
                        reject();
                    }
                });
        });
    }
}
