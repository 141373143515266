import { Injectable } from '@angular/core';
import { BizHttp } from '@uni-framework/core/http/BizHttp';
import { Address } from '@app/unientities';
import { UniHttp } from '@uni-framework/core/http/http';

@Injectable({ providedIn: 'root' })
export class AddressService extends BizHttp<Address> {
    constructor(http: UniHttp) {
        super(http);

        this.relativeURL = 'addresses'; // TODO: missing Address.RelativeUrl;
        this.entityType = Address.EntityType;
        this.DefaultOrderBy = null;
    }

    public displayAddress(address: Address): string {
        if (address == null) {
            return '';
        }
        let displayVal = '';
        if (address.AddressLine1 && address.AddressLine1 !== '') {
            displayVal += address.AddressLine1;
            if ((address.PostalCode && address.PostalCode !== '') || (address.City && address.City !== '')) {
                displayVal += ', ';
            }
        }
        if (address.PostalCode && address.PostalCode !== '') {
            displayVal += address.PostalCode + ' ';
        }
        if (address.City && address.City !== '') {
            displayVal += address.City;
        }
        return displayVal;
    }
}
