import { Injectable } from '@angular/core';
import { UniHttp } from '@uni-framework/core/http/http';
import { Comment } from '@app/unientities';
import { of, Subject } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { StatisticsService } from './statisticsService';

@Injectable({ providedIn: 'root' })
export class CommentService {
    commentAdded$ = new Subject<Comment>();

    constructor(
        protected http: UniHttp,
        private statisticsService: StatisticsService,
    ) {}

    getAll(entity: string, entityID: number, companyKey?: string) {
        const route = `comments?filter=entitytype eq '${entity}' and entityid eq ${entityID}`;
        return this.http
            .asGET()
            .usingBusinessDomain()
            .withEndPoint(`${route}&expand=Author`)
            .withHeader('CompanyKey', companyKey ? companyKey : '')
            .send({}, null, companyKey ? false : true)
            .pipe(map((res) => res.body || []));
    }

    getCommentCount(entity: string, entityID: number) {
        const odata =
            `model=Comment` +
            `&select=count(ID) as Count` +
            `&filter=EntityType eq '${entity}' and EntityID eq ${entityID}`;

        return this.statisticsService.GetAllUnwrapped(odata).pipe(
            map((res) => res && res[0] && res[0].Count),
            catchError((err) => {
                console.error(err);
                return of(0);
            }),
        );
    }

    post(entity: string, entityID: number, message: string, companyKey?: string) {
        return this.http
            .asPOST()
            .usingBusinessDomain()
            .withEndPoint(`${Comment.RelativeUrl}/${entity}/${entityID}`)
            .withHeader('CompanyKey', companyKey ? companyKey : '')
            .withBody({ Text: message })
            .send({}, null, companyKey ? false : true)
            .pipe(
                map((res) => res.body as Comment),
                tap((res) => res && this.commentAdded$.next(res)),
            );
    }
}
