import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AltinnAccount } from '@uni-entities';
import { BizHttp, RequestMethod, UniHttp } from '@uni-framework/core/http';
import { Observable, of } from 'rxjs';
import { FinancialYearService } from './financialYearService';
import { ToastService, ToastTime, ToastType } from '@uni-framework/uniToast/toastService';
import { BrowserStorageService } from '@uni-framework/core/browserStorageService';
import { CompanySettingsService } from '../common/companySettingsService';
import { ErrorService } from '../common/errorService';

export interface AltinnAccountLink {
    _createguid?: string;
    ID?: number;
    AltinnAccountNumber: number;
    AccountNumber: number;
    Year?: number;
}

@Injectable({ providedIn: 'root' })
export class AltinnAccountLinkService extends BizHttp<AltinnAccountLink> {
    altinnAccountLinkCodes = null;
    STORAGE_KEY_PREFIX = 'AltinnAccountLinksOK';

    constructor(
        public http: UniHttp,
        private financialYearService: FinancialYearService,
        private toastService: ToastService,
        private browserStorage: BrowserStorageService,
        private companySettingsService: CompanySettingsService,
        private errorService: ErrorService,
    ) {
        super(http);
        this.relativeURL = 'AltinnAccountLinks';
        this.entityType = 'AltinnAccountLink';
        this.DefaultOrderBy = 'AccountNumber';
    }

    getByAccountNumber(accountNumber: number) {
        const year = this.financialYearService.getActiveFinancialYear().Year;
        return this.http
            .asGET()
            .withEndPoint(`altinnaccountlinks?action=get-by-accountnumber&accountNumber=${accountNumber}&year=${year}`)
            .send()
            .pipe(map((res) => res.body));
    }

    save(altinnAccountLink: AltinnAccountLink) {
        if (!altinnAccountLink.Year) {
            altinnAccountLink.Year = this.financialYearService.getActiveFinancialYear().Year;
        }

        return altinnAccountLink.ID ? this.Put(altinnAccountLink.ID, altinnAccountLink) : this.Post(altinnAccountLink);
    }

    remove(accountNumber: number) {
        return this.getByAccountNumber(accountNumber).pipe(
            switchMap((link) => {
                if (link?.ID) {
                    return this.Remove(link.ID);
                }

                return of(null);
            }),
        );
    }

    getAltinnAccounts(year?: number): Observable<AltinnAccount[]> {
        year ??= this.financialYearService.getActiveFinancialYear().Year;
        return this.GetAction(null, 'type-altinnaccounts', `year=${year}`);
    }

    getAltinnAccountLinks(year?: number): Observable<AltinnAccountLink[]> {
        year ??= this.financialYearService.getActiveFinancialYear().Year;
        return this.companySettingsService.checkCompanyType(['ENK']).pipe(
            switchMap((isENK) => {
                // ENK = 1 & AS = 2
                const invalidType = isENK ? 2 : 1;
                this.invalidateCache();
                return this.GetAll(`filter=Year eq '${year}' and AccountingType ne ${invalidType}`);
            }),
        );
    }

    updateYearlyMappings(updateOnlyMissing?: boolean) {
        const year = this.financialYearService.getActiveFinancialYear().Year;
        const params = `year=${year}` + (updateOnlyMissing ? `&updateOnlyMissing=${updateOnlyMissing}` : '');
        return this.PutAction(null, 'update-yearly-mappings', params);
    }

    getUnlinkedAccounts(year: number, outsideStandardPlan?: boolean) {
        const params = `year=${year}` + (outsideStandardPlan ? `&outsideStandardPlan=${outsideStandardPlan}` : '');
        return this.Action(null, 'get-accounts-not-linked', params, RequestMethod.Get).pipe(
            map((data) => {
                const mappedAccounts = [];
                for (const key in data) {
                    mappedAccounts.push({
                        AccountName: data[key],
                        AccountNumber: key,
                    });
                }
                return mappedAccounts;
            }),
        );
    }

    checkAltinnAccountLinksForCurrentYear(year?: number) {
        year ??= this.financialYearService.getActiveFinancialYear().Year;

        const linksOK = this.getLinksFromStorage(year);
        if (linksOK) {
            return of(true);
        }

        return this.getUnlinkedAccounts(year).pipe(
            switchMap((missingLinks) => {
                if (missingLinks.length) {
                    return this.updateYearlyMappings(true).pipe(
                        tap(() =>
                            this.toastService.addToast('Kontokoblinger ble oppdatert', ToastType.info, ToastTime.short),
                        ),
                        map(() => true),
                    );
                }
                return of(true);
            }),
            tap((res) => this.browserStorage.setItemOnCompany(`${this.STORAGE_KEY_PREFIX}${year}`, res)),
            catchError((err) => {
                this.browserStorage.setItemOnCompany(`${this.STORAGE_KEY_PREFIX}${year}`, false);
                this.errorService.handle(err);
                return of(false);
            }),
        );
    }

    private getLinksFromStorage(year: number) {
        const key = `${this.STORAGE_KEY_PREFIX}${year}`;
        return this.browserStorage.getItemFromCompany(key);
    }
}
