import { NgModule } from '@angular/core';
import { MatNativeDateModule, DateAdapter } from '@angular/material/core';
import { LibraryImportsModule } from '@app/library-imports.module';

import { UniPipesModule } from '../../pipes/pipes.module';
import { UniTranslatePipesModule } from '../../pipes/translate.module';
import { AgGridModule } from 'ag-grid-angular';
import { AgGridWrapper } from './ag-grid-wrapper';
import { ColumnMenuNew } from './column-menu-modal';
import { TableDataService } from './services/data-service';
import { TableUtils } from './services/table-utils';
import { TableEditor } from './editor/editor';
import { TableFiltersAndButtons } from './filters/filters-and-buttons';
import { AdvancedFilters } from './filters/advanced-filters/advanced-filters';

import { RowMenuRenderer } from './cell-renderer/row-menu';
import { StatusCellRenderer } from './cell-renderer/status-cell';
import { AttachmentCellRenderer } from './cell-renderer/attachment-cell';
import { CellWithIconsRenderer, HeaderCellWithIconsRenderer } from './cell-renderer/cell-with-icons';
import { TooltipCellRenderer } from './cell-renderer/tooltip-cell';
import { UniDateAdapter } from '@app/date-adapter';
import { DropdownMenuModule } from '../dropdown-menu/dropdown-menu';
import { InputDropdownModule } from '../input-dropdown/input-dropdown';
import { QuickFilters } from './filters/quick-filters/quick-filters';
import { DatepickerModule } from '../datepicker/datepicker.module';
import { TableLoadIndicator } from './table-load-indicator';
import { UniIconModule } from '../icon/uni-icon';
import { UniTooltipModule } from '../tooltip/tooltip.module';
import { UniSelectModule } from '../uni-select/select.module';
import { AccountSearchModule } from '../account-search/account-search.module';
import { CellWithComponent } from './cell-renderer/cell-with-component';
import { CommentCellRenderer } from './cell-renderer/comment-cell';
import { TableCommentCountService } from './services/table-comment-count-service';
import { QueryBuilderModule } from '../query-builder/query-builder.module';
import { DesignSystemModule } from '../design-system/design-system.module';
import { GroupingColumnDropdown } from './grouping/grouping-column-dropdown';
import { GroupingCellRenderer } from './cell-renderer/grouping-cell/grouping-cell';
import { ColumnMenuCell } from './cell-renderer/column-menu-cell';
import { CellWithButtonRenderer } from './cell-renderer/cell-with-button';
import { LinkCellRenderer } from './cell-renderer/link-cell';

@NgModule({
    imports: [
        LibraryImportsModule,
        DesignSystemModule,
        MatNativeDateModule,
        DatepickerModule,
        QueryBuilderModule,

        UniPipesModule,
        UniTranslatePipesModule,
        DropdownMenuModule,
        InputDropdownModule,
        AccountSearchModule,
        UniIconModule,
        UniTooltipModule,
        UniSelectModule,
        AgGridModule,
    ],
    declarations: [
        AgGridWrapper,
        ColumnMenuNew,
        TableEditor,
        LinkCellRenderer,
        ColumnMenuCell,
        RowMenuRenderer,
        CellWithIconsRenderer,
        CellWithButtonRenderer,
        CellWithComponent,
        HeaderCellWithIconsRenderer,
        TooltipCellRenderer,
        StatusCellRenderer,
        GroupingCellRenderer,
        TableFiltersAndButtons,
        AdvancedFilters,
        QuickFilters,
        TableLoadIndicator,
        AttachmentCellRenderer,
        CommentCellRenderer,
        GroupingColumnDropdown,
    ],
    providers: [
        TableUtils,
        TableDataService,
        TableCommentCountService,
        { provide: DateAdapter, useClass: UniDateAdapter },
    ],
    exports: [AgGridWrapper, TableFiltersAndButtons, QuickFilters, AdvancedFilters],
})
export class AgGridWrapperModule {}
