import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { UniTableColumn } from '@uni-framework/ui/unitable';
import { ICellRendererParams } from 'ag-grid-community';
import { Subscription } from 'rxjs';
import { TableCommentCountService } from '../services/table-comment-count-service';

@Component({
    selector: 'comment-cell',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <section *ngIf="count" class="cell-icon-container" (click)="onClick($event)">
            <uni-icon icon="message" style="color: var(--scale1)"></uni-icon>
            <span class="counter">{{ count }}</span>
        </section>
    `,
})
export class CommentCellRenderer {
    params: ICellRendererParams;

    id: number;
    count: number;
    subscription: Subscription;

    constructor(
        private commentCountService: TableCommentCountService,
        private cdr: ChangeDetectorRef,
    ) {}

    agInit(params: ICellRendererParams): void {
        const col: UniTableColumn = params['columnConfig'];
        const rowData = params.node.data;

        if (col.commentIndicator.idField) {
            this.id = rowData && rowData[col.commentIndicator.idField];
            this.subscription = this.commentCountService.counts$.subscribe((counts) => {
                const newCount = counts && counts[this.id];
                if (newCount !== this.count) {
                    this.count = newCount;
                    this.cdr.markForCheck();
                }
            });
        }
    }

    ngOnDestroy() {
        this.subscription?.unsubscribe();
    }

    refresh(): boolean {
        return false;
    }

    onClick(event: MouseEvent) {
        event.stopPropagation();
        this.commentCountService.openChat(this.id);
    }
}
