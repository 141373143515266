import { Injectable, EventEmitter, Output } from '@angular/core';
import { BankAccount, BankIntegrationAgreement, StatusCodeBankIntegrationAgreement } from '../../unientities';
import { BankService } from '../accounting/bankService';
import { Observable, of } from 'rxjs';
import { BankAgreementServiceProvider } from '@app/models/autobank-models';
import { AuthService } from '@app/authService';
import { ConfigBankAccountsModal } from '@uni-framework/uni-modal/modals/bank-accounts-config-modal/bank-accounts-config-modal';
import { BankAccountService } from '../accounting/bankAccountService';
import { UniModalService } from '@uni-framework/uni-modal/modalService';
import { StatisticsService } from '@app/services/common/statisticsService';
import { map, catchError, switchMap } from 'rxjs/operators';
import { BrunoBankOnboardingModal } from '@uni-framework/uni-modal/modals/bruno-bank-onboarding-modal/bruno-bank-onboarding-modal';

import { ConfirmActions } from '@uni-framework/uni-modal/interfaces';
import { NumberFormat } from '@app/services/common/numberFormatService';
import { CompanySettingsService } from '../common/companySettingsService';

@Injectable({ providedIn: 'root' })
export class BrunoOnboardingService {
    constructor(
        private bankService: BankService,
        private authService: AuthService,
        private modalService: UniModalService,
        private bankAccountService: BankAccountService,
        private statisticsService: StatisticsService,
        private numberFormat: NumberFormat,
        private companySettingsService: CompanySettingsService,
    ) {}

    @Output() agreementStatusChanged = new EventEmitter();

    public isPendingAgreement(agreement?: BankIntegrationAgreement): boolean {
        return agreement?.StatusCode === 700001;
    }

    public hasNewAccountInfo(agreement): boolean {
        return agreement?.HasNewAccountInformation;
    }

    public isActiveAgreement(agreement?: BankIntegrationAgreement): boolean {
        return agreement?.StatusCode === 700005;
    }

    hasPendingOrder(agreement: BankIntegrationAgreement): boolean {
        if (agreement?.StatusCode === StatusCodeBankIntegrationAgreement.Active) {
            return agreement.HasOrderedIntegrationChange;
        } else {
            return this.isPendingAgreement(agreement);
        }
    }

    public isFirstOnboarding(agreement: BankIntegrationAgreement): Observable<boolean> {
        return this.statisticsService
            .GetAll(
                'model=AuditLog' +
                    '&select=count(id)' +
                    `&filter=AuditLog.EntityType eq 'BankIntegrationAgreement' and AuditLog.EntityID eq ${agreement.ID} and field eq 'HasNewAccountInformation' and oldValue eq 'true'` +
                    '&top=1',
            )
            .pipe(map((data) => data.Data[0].countid < 1));
    }

    getBalanceAgreement(): Observable<BankIntegrationAgreement> {
        return this.bankService.getBalanceAgreement().pipe(
            catchError((err) => {
                console.error(err);
                return of(null);
            }),
            map((agreements) => agreements && agreements[0]),
        );
    }

    getAgreement(
        serviceProvider: BankAgreementServiceProvider = BankAgreementServiceProvider.Bruno,
    ): Observable<BankIntegrationAgreement> {
        return this.bankService.getDirectBankAgreement(serviceProvider).pipe(
            catchError((err) => {
                console.error(err);
                return of(null);
            }),
            map((agreements) =>
                agreements?.find((a: BankIntegrationAgreement) => a.IsOutgoing || a.IsInbound || a.IsBankBalance),
            ),
        );
    }

    createAgreement() {
        return new Observable((observer) => {
            this.bankService.createInitialFileAgreement().subscribe(
                (agreement) => {
                    this.authService.loadCurrentSession(true).subscribe(() => {
                        this.modalService
                            .open(BrunoBankOnboardingModal, {
                                data: agreement,
                            })
                            .onClose.subscribe((externalOnboardingOpened) => {
                                if (externalOnboardingOpened) {
                                    observer.next(agreement);
                                    observer.complete();
                                } else {
                                    // Remove the newly created agreement if the user just closed
                                    // the dialog without going to the external onboarding step.
                                    this.bankService.deleteAgreement(agreement).subscribe(
                                        () => {
                                            observer.next(null);
                                            observer.complete();
                                        },
                                        (err) => {
                                            console.error(err);
                                            observer.next(null);
                                            observer.complete();
                                        },
                                    );
                                }
                            });
                    });
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                },
            );
        });
    }

    createAgreementBankID(orgNr?: string, companykey?: string) {
        return new Observable((observer) => {
            if (!orgNr) {
                orgNr = this.authService.activeCompany?.OrganizationNumber;
            }

            const approval = localStorage.getItem('bankIdFlow-Approval')?.split(',') || [];

            if (approval[0] !== orgNr) {
                // rare scenario, should we do something about this?
                console.error('CreateBankIDAgreement - Org numbers dont match');
                observer.next(null);
                observer.complete();
            }

            const body = {
                ServiceProvider: BankAgreementServiceProvider.BrunoApi,
                IsBankBalance: true,
                IsBankStatement: true,
                IsOutgoing: approval.length > 0,
                BankApproval: approval.length > 0 && approval[1] === 'InBank',
            };

            localStorage.removeItem('bankIdFlow-Approval');

            this.bankService.createInitialAgreement(body, companykey).subscribe(
                (agreement) => {
                    if (
                        body.IsOutgoing &&
                        agreement?.PaymentIntegrationStatusCode !== 200 &&
                        agreement?.PaymentIntegrationStatusCode !== 204
                    ) {
                        localStorage.setItem('bankid-agreement-failed', `${orgNr},true`);
                        this.setBankIdAgreementFailedText(agreement.PaymentIntegrationStatusCode);
                    }

                    if (!companykey) {
                        this.companySettingsService.invalidateCache();
                        this.authService.loadCurrentSession(true).subscribe(() => {
                            observer.next(agreement);
                            observer.complete();
                        });
                    } else {
                        // can't reload current session if activeCompany isn't set
                        observer.next(agreement);
                        observer.complete();
                    }
                    this.agreementStatusChanged.emit();
                },
                (err) => {
                    localStorage.setItem('bankid-agreement-failed', `${orgNr},true`);
                    this.setBankIdAgreementFailedText(err.error?.StatusCode || err.status, true);
                    observer.error(err);
                    observer.complete();
                },
            );
        });
    }

    restartOnboarding(agreement: BankIntegrationAgreement) {
        return this.modalService.open(BrunoBankOnboardingModal, {
            data: agreement,
        }).onClose;
    }

    connectBankAccounts(isPopUp: boolean = false): Observable<boolean> {
        return this.bankAccountService.getBankServiceBankAccounts().pipe(
            switchMap((accounts) => {
                return this.modalService
                    .open(ConfigBankAccountsModal, {
                        data: {
                            accounts: accounts,
                            isPopUp: isPopUp,
                        },
                    })
                    .onClose.pipe(
                        map((configurationSaved) => {
                            if (configurationSaved) {
                                this.modalService.confirm({
                                    header: 'Integrasjonen med banken er klar!',
                                    showHeaderBelowIllustration: true,
                                    message: 'Alle bankkontoer er nå oppdatert i DNB Regnskap og er klar for bruk',
                                    buttonLabels: {
                                        accept: 'OK',
                                    },
                                    illustrationPath: 'config/dist/theme/assets/ext02-success-accountconfig.svg',
                                    width: '38rem',
                                    footerCls: 'center',
                                });
                            }

                            this.agreementStatusChanged.emit();
                            return !!configurationSaved;
                        }),
                    );
            }),
        );
    }

    public RequestBankintegrationChange(agreement: BankIntegrationAgreement) {
        return new Observable((observer) => {
            if (!agreement['HasOrderedIntegrationChange']) {
                this.bankService.setBankIntegrationChangeAgreement(true, agreement).subscribe(() => {
                    this.modalService
                        .open(BrunoBankOnboardingModal, {
                            data: agreement,
                        })
                        .onClose.subscribe((externalOnboardingOpened) => {
                            if (externalOnboardingOpened) {
                                this.authService.loadCurrentSession(true).subscribe(() => {
                                    observer.next(true);
                                    observer.complete();
                                });
                            } else {
                                // Reset the newly changed agreement if the user just closed
                                // the dialog without going to the external onboarding step.
                                this.bankService.setBankIntegrationChangeAgreement(false, agreement).subscribe(
                                    () => {
                                        observer.next();
                                        observer.complete();
                                    },
                                    (err) => {
                                        console.error(err);
                                        observer.next();
                                        observer.complete();
                                    },
                                );
                            }
                        });
                });
            } else {
                this.modalService
                    .open(BrunoBankOnboardingModal, {
                        data: agreement,
                    })
                    .onClose.subscribe((externalOnboardingOpened) => {
                        if (externalOnboardingOpened) {
                            observer.next(agreement);
                            observer.complete();
                        } else {
                            observer.next();
                            observer.complete();
                        }
                    });
            }
        });
    }

    public cancelBankIntegration(
        bankAccount: BankAccount,
        deleteAccount: boolean = false,
        modal: any,
    ): Observable<BankAccount> {
        return new Observable((observer) => {
            this.modalService
                .open(modal, {
                    data: {
                        account: bankAccount,
                        deleteAccount: deleteAccount,
                    },
                    header: 'Avslutt integrasjon med banken',
                    message: deleteAccount
                        ? `Konto ${this.numberFormat.asBankAcct(+bankAccount.AccountNumber)} vil slettes, følgende tjenester vil avsluttes: `
                        : `Jeg ønsker å avslutte disse tjenestene på konto ${this.numberFormat.asBankAcct(+bankAccount.AccountNumber)}:`,
                    buttonLabels: {
                        accept: deleteAccount ? 'Avslutt og slett konto' : 'Avslutt integrasjon',
                    },
                })
                .onClose.subscribe((cancelationData) => {
                    if (cancelationData) {
                        this.modalService
                            .confirm({
                                header: 'Bekreft avslutning av kobling mot bank',
                                showHeaderBelowIllustration: true,
                                message:
                                    'Når du bekrefter sendes melding om avslutning rett til banken. </br>' +
                                    'Det kan ta litt tid før du mottar bekreftelse på Epost fra DNB.',
                                buttonLabels: {
                                    reject: 'Avbryt',
                                    accept: 'Bekreft',
                                },
                                illustrationPath: 'config/dist/theme/assets/ext02-cancel-integration.svg',
                                width: '38rem',
                                footerCls: 'center',
                            })
                            .onClose.subscribe((results) => {
                                if (results === ConfirmActions.ACCEPT) {
                                    this.bankService
                                        .cancelBankAccountIntegration(
                                            cancelationData[0],
                                            cancelationData[1],
                                            cancelationData[2],
                                        )
                                        .subscribe((response: BankAccount) => {
                                            this.authService.loadCurrentSession(true).subscribe(() => {
                                                observer.next(response);
                                                observer.complete();
                                            });
                                        });
                                } else {
                                    observer.next();
                                    observer.complete();
                                }
                            });
                    } else {
                        observer.next();
                        observer.complete();
                    }
                });
        });
    }

    public CancelKIDIntegration(bankAccounts: BankAccount[], modal: any): Observable<boolean> {
        const countHasIncomming = bankAccounts
            .map((ba) => this.bankService.mapBankIntegrationValues(ba))
            .filter((account) => !!account?.BankAccountSettings['HasIncoming']).length;

        if (countHasIncomming === 0) {
            return of(false);
        }

        return this.modalService
            .open(modal, {
                data: {
                    hasMultipleKidAgreements: countHasIncomming > 1,
                    cancelKID: true,
                },
                header: countHasIncomming === 1 ? 'Avslutt integrasjon med banken' : 'Mer enn en innbetalingsavtale',
                message:
                    countHasIncomming === 1
                        ? `Ønsker du å avslutte innebtalingsavtale?
                <br/><br/>
                NB! dersom du har sendt ut faktura med KID, som ikke er innbetalt ennå,
                    må du be disse kundene innbetale uten å bruke KID nummeret.`
                        : 'Du har mer en innbetalingsavtale. Du må avslutte hver avtale under innstillinger > konto',
                buttonLabels: {
                    accept: countHasIncomming === 1 ? 'Avslutt integrasjon' : 'OK',
                },
            })
            .onClose.pipe(
                switchMap((modalResult) => {
                    if (!!modalResult && countHasIncomming === 1) {
                        const bankAccount = bankAccounts.find((x) => x.BankAccountSettings['HasIncoming']);
                        return this.bankService.cancelBankAccountIntegration(
                            bankAccount.ID,
                            1,
                            this.authService?.currentUser?.Email ?? '',
                        );
                    }
                    return of(false);
                }),
            );
    }

    public cancelAllBankIntegrations(modal: any): Observable<BankAccount[]> {
        return new Observable((observer) => {
            this.modalService
                .open(modal, {
                    header: 'Avslutt kobling mot bank',
                    message: 'Jeg ønsker å avslutte koblingene på alle kontoer:',
                    buttonLabels: {
                        accept: 'Avslutt kobling',
                    },
                })
                .onClose.subscribe((cancelationData) => {
                    if (cancelationData) {
                        this.modalService
                            .confirm({
                                header: 'Bekreft avslutning av kobling mot bank',
                                showHeaderBelowIllustration: true,
                                message:
                                    'Når du bekrefter sendes melding om avslutning rett til banken. </br>' +
                                    'Det kan ta litt tid før du mottar bekreftelse på Epost fra DNB.',
                                buttonLabels: {
                                    reject: 'Avbryt',
                                    accept: 'Bekreft',
                                },
                                illustrationPath: 'config/dist/theme/assets/ext02-cancel-integration.svg',
                                width: '38rem',
                                footerCls: 'center',
                            })
                            .onClose.subscribe((results) => {
                                if (results === ConfirmActions.ACCEPT) {
                                    this.bankService
                                        .cancelAllBankAccountIntegrations(cancelationData[2])
                                        .subscribe((response: BankAccount[]) => {
                                            this.authService.loadCurrentSession(true).subscribe(() => {
                                                observer.next(response);
                                                observer.complete();
                                            });
                                        });
                                } else {
                                    observer.next();
                                    observer.complete();
                                }
                            });
                    } else {
                        observer.next();
                        observer.complete();
                    }
                });
        });
    }

    setBankIdAgreementFailedText(statusCode: number, apiError = false, errorMessage?: string) {
        // default error, thrown if statuscode is 500
        let headerText = 'Kommunikasjonsproblemer';
        let infoText = 'Vi har litt kommunikasjonsproblemer nå. Prøv igjen senere. Du kan også kontakte oss på chat.';

        if (statusCode === 400) {
            headerText = 'Du har ikke riktige rettigheter';
            infoText =
                'Det ser ikke ut til at du har riktige rettigheter til organisasjonens kontoer.' +
                ' Du kan likevel bruke systemet, og koble bank og regnskap senere.' +
                ' Be administrator av Nettbank bedrift tildele deg rettigheter, eller kontakt oss på chat.';
        }

        if (statusCode === 401) {
            headerText = 'Du har ikke riktige rettigheter';
            infoText =
                'Det ser ikke ut til at du har riktige rettigheter til å bestille muligheten for utbetaling fra DNB Regnskap.' +
                ' Ta kontakt med administrator av Nettbank Bedrift slik at han/hun kan foreta denne bestillingen,' +
                ' eller be administrator gi deg riktige rettigheter. Du kan også kontakte oss på chat.';
        }

        if (statusCode === 404) {
            headerText = 'Finner ingen nettbankavtale';
            infoText = 'Det ser ikke ut som om du har egen nettbankavtale. Ta kontakt med oss på chat.';
        }

        if (statusCode === 500 && !apiError) {
            headerText = 'Bestilling av utbetaling feilet';
            infoText =
                'Det ser ikke ut til at vi klarte å bestille utbetalinger.' +
                ' Du kan likevel bruke systemet, og bestille utbetalinger senere.' +
                ' Du kan også kontakte oss på chat.';
        }

        if (errorMessage?.includes('Please make sure user is verified with bankID')) {
            headerText = 'BankID verifisering feilet';
            infoText = 'Vi klarte ikke å verifisere din BankID. Vennligst prøv på nytt eller kontakt Kundeservice.';
        }

        localStorage.setItem('bankid-agreement-failed-text', `${headerText}|${infoText}`);
    }
}
