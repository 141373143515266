<section role="dialog" class="uni-modal">
    <header>{{ options.header || 'Send e-post' }}</header>
    <article>
        <small *ngIf="isTestCompany" class="alert warn">
            Siden du er logget inn på et demoselskap vil alle eposter bli sendt til deg istedenfor til mottakeradresse.
        </small>
        <section *ngIf="busy" class="modal-spinner">
            <mat-spinner class="c2a"></mat-spinner>
        </section>

        <uni-form
            [config]="formConfig$"
            [fields]="formFields$"
            [model]="formModel$"
            (changeEvent)="onFormChange($event)"
        >
        </uni-form>
    </article>

    <footer>
        <span *ngIf="invalidEmail" class="warn" style="margin-right: 2rem"> Ugyldig e-post </span>

        <button class="secondary" [disabled]="busy" (click)="onClose.emit()">Avbryt</button>
        <button class="c2a" [disabled]="busy" (click)="sendEmail()">Send</button>
    </footer>
</section>
