<section role="dialog" class="uni-modal">
    <header></header>
    <article>
        <autocomplete [options]="autocompleteOptions" (valueChange)="select($event)"></autocomplete>
        <button class="c2a" (click)="close(selectedMunicipal)" [disabled]="!selectedMunicipal">
            {{ 'COMMON.OK' | translate }}
        </button>
    </article>
    <footer></footer>
</section>
