import { Injectable, inject } from '@angular/core';
import { AuthService } from '@app/authService';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subscription, forkJoin, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { theme, THEMES } from 'src/themes/theme';
import { NavigationEnd, Router } from '@angular/router';
import { rigDate } from '@app/components/common/utils/rig-date';
import { ContractType } from '@app/models';
import { CompanySettingsService } from '../common/companySettingsService';
import { CompanySettings } from '@uni-entities';
import { AnalyticsEvent, AnalyticsService } from '../analytics-services/analytics.service';

interface CMSCacheEntry {
    data: CMSWidgetData | CMSBannerData | CMSSlideinData[];
    timeout?: number;
}

type ItemType = 'text' | 'link' | 'list' | 'linkgroup' | 'illustration' | 'illustration_new';

export enum Experience {
    Rookie = 1, // 60 days or less
    Beginner = 2, // 60 days to 6 months
    Intermediate = 3, // 6 months to 1 year
    Experienced = 4, // 1 year to 3 years
    Expert = 5, // 3 years to 5 year
    Legend = 6, // 5 years or more
}

export enum Status {
    Inactive = 1,
    Preview = 2,
    Live = 3,
}

export interface CMSFilterableFields {
    userType?: number;
    orgNumber?: string;
    location?: string;
    experience?: Experience;
    package?: ContractType;
    active?: boolean;
    published?: boolean;
}

export interface CMSWidgetData extends CMSFilterableFields {
    _id: string;
    title: string;
    size?: 'small' | 'large';
    items: {
        type: ItemType;
        textType: 'original' | 'header' | 'subhead';
        text?: string;
        link?: string;
        icon?: string;
        mediaField?: MediaField;
        list?: {
            text: string;
            url?: string;
            isExternal?: boolean;
        }[];
        linkgroup?: {
            text: string;
            hover?: string;
            url: string;
            isExternal?: boolean;
            linkAppearance?: 'link' | 'button c2a' | 'button secondary' | 'button' | 'button warn';
        }[];
    }[];
    closeIconTitle?: string;
    mediaField?: MediaField;
    illustration?: string;
    illustration_version?: 'v1' | 'v2';
    illustrationAlignment?: 'left' | 'right';
}

export interface CMSBannerData extends CMSFilterableFields {
    _id: string;
    title: string;
    text: string;
    hover?: string;
    url?: string;
    isExternal?: boolean;
    isProduct?: boolean;
    productName?: string;
    icon?: string;
    bgColor?: string;
    borderColor?: string;
    iconColor?: string;
    buttonText?: string;
    buttonColor: string;
    textColor?: string;
    buttonTextColor?: string;
    buttonBorderColor?: string;
    bannerType?: 'custom' | 'standard' | 'green' | 'anonymous';
}

/*  Slidein  */
export interface CMSSlideinData extends CMSFilterableFields {
    _id: string;
    title: string;
    url: string;
    placement: 'bottom-right' | 'center';
    timeout: number;
    closeOnNavigation?: boolean;
    items: {
        type: ItemType;
        textType: 'original' | 'header' | 'subhead';
        text?: string;
        link?: string;
        icon?: string;
        mediaField?: MediaField;
        list?: {
            text: string;
            url?: string;
            isExternal?: boolean;
        }[];
        linkgroup?: {
            text: string;
            hover?: string;
            url: string;
            isExternal?: boolean;
            linkAppearance?: 'link' | 'button c2a' | 'button secondary' | 'button';
        }[];
    }[];
}

interface MediaField {
    _type: string;
    asset: MediaFieldAsset;
    altText?: string;
}

interface MediaFieldAsset {
    _ref: string;
    _type: string;
}

@Injectable({ providedIn: 'root' })
export class CMSService {
    private analytics = inject(AnalyticsService);
    cache = new Map<string, CMSCacheEntry>();
    timeoutInMs = 3600000;
    slideIn = new BehaviorSubject(null);
    banner = new BehaviorSubject(undefined);
    companySettings: CompanySettings;

    // We prefetch all the slideins to avoid spamming the api.
    // This needs to be revisited if there are ever a big payload returned,
    // but this shouldn't be an issue since we also filter on active, published,
    // usertype and other tags
    slideIns: CMSSlideinData[] = [];
    banners: CMSBannerData[] = [];
    widget = new BehaviorSubject(null);
    closeSlideinSubsciption: Subscription;

    closedSlideins: string[] = JSON.parse(localStorage.getItem('closedSlideins')) || [];
    closedWidgets: string[] = JSON.parse(localStorage.getItem('closedWidgets')) || [];
    closedBanners: string[] = JSON.parse(localStorage.getItem('closedBanners')) || [];

    constructor(
        private http: HttpClient,
        private authService: AuthService,
        private companySettingsService: CompanySettingsService,
        private router: Router,
    ) {
        // Clear all values when company changes
        this.authService.companyChange.subscribe({
            next: () => this.invalidateCache(),
        });
    }

    initCMSComponents() {
        const checkForRouteMatches = () => {
            let slidein: CMSSlideinData = null;
            let currentBanner: CMSBannerData = null;

            const currentUrl = this.router.url;
            const location = this.getLocation();

            slidein = this.slideIns.find((si) => currentUrl.includes(si.url));

            currentBanner = this.banners.find(
                (banner) => banner.location === location || currentUrl.includes(banner.location),
            );

            if (!!slidein) {
                setTimeout(() => {
                    if (slidein.closeOnNavigation) {
                        this.closeSlideinSubsciption = this.router.events.subscribe((event) => {
                            if (event instanceof NavigationEnd) {
                                this.closeSlideIn(false);
                                setTimeout(() => this.closeSlideinSubsciption.unsubscribe());
                            }
                        });
                    }

                    this.slideIn.next(slidein);
                    this.analytics.trackCMSEvents(AnalyticsEvent.CMS_SLIDEIN_SHOWN, slidein._id);
                }, slidein?.timeout || 0);
            }

            this.banner.next(currentBanner);
        };

        const loadCMSValues = () => {
            forkJoin([this.getCMSData('slidein'), this.getCMSData('banner')]).subscribe(([slideins, banners]) => {
                this.slideIns = slideins?.filter((sl) => !this.closedSlideins.includes(sl._id)) || [];

                // Backward compability with empty location fields showing on all dashboards..
                this.banners = (banners || [])
                    .filter((banner) => !this.closedBanners.includes(banner._id))
                    .map((banner) => {
                        if (!banner.location) {
                            banner.location = 'main';
                        }
                        return banner;
                    });

                // If there are no slideins, there is no need to listen for router events..
                if (this.slideIns?.length || this.banners?.length) {
                    this.router.events.subscribe((event) => {
                        event instanceof NavigationEnd && checkForRouteMatches();
                    });

                    checkForRouteMatches();
                }
            });
        };

        this.companySettingsService.getCompanySettings().subscribe({
            next: (companySettings) => {
                this.companySettings = companySettings;
                loadCMSValues();
            },
            error: (err) => {
                console.error(err);
                loadCMSValues();
            },
        });
    }

    closeSlideIn(addToBlacklist: boolean = true) {
        if (addToBlacklist) {
            this.closedSlideins.push(this.slideIn.getValue()._id);
            this.analytics.trackCMSEvents(AnalyticsEvent.CMS_SLIDEIN_CLOSED, this.slideIn.getValue()._id);
            localStorage.setItem('closedSlideins', JSON.stringify(this.closedSlideins));
            this.slideIns = this.slideIns.filter((slidein) => slidein._id !== this.slideIn.getValue()._id);
        }
        this.slideIn.next(null);
    }

    closeWidget() {
        this.closedWidgets.push(this.widget.getValue()._id);
        localStorage.setItem('closedWidgets', JSON.stringify(this.closedWidgets));
        this.widget.next(null);
    }

    closeBanner() {
        this.closedBanners.push(this.banner.getValue()._id);
        this.banners = this.banners.filter((banner) => banner._id !== this.banner.getValue()._id);
        localStorage.setItem('closedBanners', JSON.stringify(this.closedBanners));
        this.banner.next(null);
    }

    private getQuery(type: string, location?: string) {
        switch (theme.theme) {
            case THEMES.SOFTRIG:
            case THEMES.SOFTRIG_TRAINING:
            case THEMES.UE:
            case THEMES.EXT02:
            case THEMES.EIKA:
                return this.getSanityQuery(type, location);
            case THEMES.SR:
                // Temp fix while SB1 moves location out of query and into object for banners
                return this.getEXT01Query(type, type === 'banner' ? 'main' : location);
        }
    }

    private getSanityQuery(type: string, location) {
        return (
            '?query=' +
            encodeURIComponent(
                `*[_type == "${type}" && ${this.getFilterableFieldsQuery(location, type)} && status == ${environment.useProdMode ? Status.Live : Status.Preview}] | order(priority desc)`,
            )
        );
    }

    private getFilterableFieldsQuery(location: string, type: string) {
        const orgnumberQuery = `(!defined(group) || "${this.companySettings?.OrganizationNumber ?? ''}" in group->orgnr)`;

        return `( ( experience == "${this.getExperience()}" || !defined(experience) ) && ${orgnumberQuery} )`;
    }

    private getEXT01Query(type: string, location?: string) {
        let tagsFilter = `&duration=sb1regnskap/duration/${this.getExperience()}`;

        if (this.authService.currentUser.License?.ContractType?.TypeID) {
            tagsFilter += `&package=sb1regnskap/package/${this.authService.currentUser.License?.ContractType?.TypeID}`;
        }

        if (this.authService.currentUser.License?.UserType?.TypeID) {
            tagsFilter += `&userType=sb1regnskap/userType/${this.authService.currentUser.License?.UserType?.TypeID}`;
        }

        return `${type}?tag=sb1regnskap/${location}${tagsFilter}`;
    }

    private getLocation(): string {
        const splitted = window.location.hash.split('/');
        return splitted[1] || 'main';
    }

    getCMSData(type: 'widget'): Observable<CMSWidgetData>;
    getCMSData(type: 'banner'): Observable<CMSBannerData[]>;
    getCMSData(type: 'slidein'): Observable<CMSSlideinData[]>;
    getCMSData(type) {
        if (window.location.host.includes('playground') || window.location.host.includes('localhost')) {
            return of(undefined);
        }

        // SB1 not ready with slideins, dont query for them. Remove when they have implemented it, ETA may, 2024
        if (theme.theme === THEMES.SR && type === 'slidein') {
            return of(undefined);
        }

        const query = this.getQuery(type, this.getLocation());
        if (!query) {
            return of(undefined);
        }

        const entry = this.cache.get(query);

        if (entry) {
            if (entry.timeout && entry.timeout < performance.now()) {
                this.cache.delete(query);
            } else {
                return of(entry.data);
            }
        }

        return this.http.get(environment.ELSA_SERVER_URL + '/cms/' + query).pipe(
            map((res) => {
                const data = this.getFormattedData(res, type);
                this.cache.set(query, {
                    data,
                    timeout: performance.now() + this.timeoutInMs,
                });

                return data;
            }),
        );
    }

    private getFormattedData(rawData, type: 'slidein' | 'widget' | 'banner') {
        let cmsData;

        // SANITY FORMAT
        if (rawData?.result) {
            if (type === 'slidein' || type === 'banner') {
                return rawData?.result || [];
            }
            cmsData = this.getResultFromList(rawData.result);
            // SPAREBANK1 FORMAT
        } else {
            cmsData = rawData;
        }
        return cmsData;
    }

    private getResultFromList(items: any[]) {
        if (!items?.length) return null;

        // Prioritize item created for the current user's type (e.g accountant)
        const currentUserType = this.authService.currentUser.License?.UserType?.TypeID;
        const userTypeMatch = items.find((item) => {
            return item.userType && item.userType === currentUserType;
        });

        return userTypeMatch || items.find((item) => !item.userType);
    }

    private getExperience(): Experience {
        const startDate =
            this.authService.currentUser?.CreatedAt || this.authService.currentUser?.License?.ContractType?.StartDate;
        const daysSinceCreation = rigDate().diff(startDate, 'd');

        if (daysSinceCreation < 60) {
            return Experience.Rookie;
        } else if (daysSinceCreation > 60 && daysSinceCreation < 180) {
            return Experience.Beginner;
        } else if (daysSinceCreation > 180 && daysSinceCreation <= 365) {
            return Experience.Intermediate;
        } else if (daysSinceCreation > 365 && rigDate().diff(startDate, 'y') < 3) {
            return Experience.Experienced;
        } else if (rigDate().diff(startDate, 'y') >= 3 && rigDate().diff(startDate, 'y') < 5) {
            return Experience.Expert;
        } else {
            return Experience.Legend;
        }
    }

    invalidateCache() {
        this.cache.clear();
        this.banner.next(null);
        this.slideIn.next(null);
        this.widget.next(null);
        this.banners = [];
        this.slideIns = [];
    }
}
