import { NgModule } from '@angular/core';
import { LibraryImportsModule } from '@app/library-imports.module';
import { ClickOutsideModule } from '@uni-framework/click-outside/click-outside.module';
import { UniAvatarModule } from '@uni-framework/avatar/uniAvatar.module';
import { DesignSystemModule } from '@uni-framework/ui/design-system/design-system.module';
import { InputDropdownModule } from '@uni-framework/ui/input-dropdown/input-dropdown';
import { GroupedAutocomplete } from './grouped-autocomplete';

@NgModule({
    imports: [LibraryImportsModule, DesignSystemModule, InputDropdownModule, ClickOutsideModule, UniAvatarModule],
    declarations: [GroupedAutocomplete],
    exports: [GroupedAutocomplete],
})
export class GroupedAutocompleteModule {}
