import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { forkJoin, BehaviorSubject } from 'rxjs';
import { IModalOptions, IUniModal } from '@uni-framework/uni-modal/interfaces';
import { UniFieldLayout, FieldType } from '@uni-framework/ui/uniform';
import { AutoBankAgreementDetails, BankAgreementServiceProvider } from '@app/models/autobank-models';
import { StatusCodeBankIntegrationAgreement } from '@uni-entities';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ToastService } from '@uni-framework/uniToast/toastService';
import { CompanySettingsService } from '@app/services/common/companySettingsService';
import { ErrorService } from '@app/services/common/errorService';
import { ModulusService } from '@app/services/common/modulusService';
import { NumberFormat } from '@app/services/common/numberFormatService';
import { UserService } from '@app/services/common/userService';
import { ElsaAgreementService } from '@app/services/elsa/elsaAgreementService';
import { BankService } from '@app/services/accounting/bankService';
import { WizardStep } from '@uni-framework/ui/design-system/wizard/wizard';
import { theme } from 'src/themes/theme';

@Component({
    selector: 'uni-autobank-agreement-modal',
    templateUrl: './autobank-agreement-modal.html',
    styleUrls: ['autobank-agreement-modal.sass'],
})
export class UniAutobankAgreementModal implements IUniModal, OnInit {
    @Input() options: IModalOptions = {};
    @Output() onClose = new EventEmitter();

    private accounts: any[] = [];
    private RGBApprovedAccounts: any[] = [];
    serviceProvider: BankAgreementServiceProvider;
    agreements: any[] = [];
    busy = true;
    bankAgreementUrl: SafeResourceUrl;
    initialized = false;
    bankName = theme.appProvider;

    usedBanks: string[] = [];
    buttonLock: boolean = false;
    hasAgreements: boolean = false;
    noAccounts: boolean = false;
    companySettings;
    errorText: string;
    infoTextForBalance: string =
        'Ved aktivering av denne tjenesten vil dere motta transaksjoner fra deres bank ' +
        'for automatisk avstemming og oppdatert banksaldo i systemet.';

    header = 'Veiviser for ny autobankavtale';
    twoFactorMsg: string =
        '2-faktor bekreftelse (autentisering) er et ekstra sikkerhetsnivå for betaling. ' +
        'Med 2-faktor bekreftelse logger du inn med noe du vet (ditt passord) i tillegg til noe du får en kode på SMS.';
    passwordCriteriaMsg =
        `Passordet må være minst 10 tegn og inneholde en stor bokstav [A-Z], en liten bokstav [a-z], ett tall [0-9] og ett av ` +
        `disse tegnene: ! @ # $ % ^ & * _ - = + . : ? , ( ) [ ] { }`;

    agreementDetails: AutoBankAgreementDetails = {
        DisplayName: '',
        Phone: '',
        Email: '',
        Bank: '',
        Orgnr: '',
        BankAccountID: 0,
        BankAcceptance: true,
        IsInbound: true,
        IsOutgoing: true,
        IsBankBalance: true,
        BankApproval: true,
        IsBankStatement: true,
        Password: '',
        _confirmPassword: '',
        BankAccountNumber: 0,
        ServiceProvider: BankAgreementServiceProvider.ZData,
    };

    formModel$: BehaviorSubject<AutoBankAgreementDetails> = new BehaviorSubject(null);
    formFields$: BehaviorSubject<UniFieldLayout[]> = new BehaviorSubject([]);
    hasReadAgreement = false;

    steps: WizardStep[] = [
        { label: 'Intro', value: 0 },
        { label: 'Firmavalg', value: 1 },
        { label: 'Avtalevilkår', value: 2 },
        { label: 'Godkjenne betalinger', value: 3 },
        { label: 'Bankoppsett', value: 4 },
        { label: 'Sikkerhet', value: 5 },
        { label: 'Ferdigstilling', value: 6 },
    ];
    currentStep: WizardStep = this.steps[0];

    constructor(
        private userService: UserService,
        private errorService: ErrorService,
        private companySettingsService: CompanySettingsService,
        private bankService: BankService,
        private elsaAgreementService: ElsaAgreementService,
        private sanitizer: DomSanitizer,
        private numberFormat: NumberFormat,
        private modulusService: ModulusService,
        private toastService: ToastService,
    ) {}

    public ngOnInit() {
        if (this.options && this.options.data && this.options.data.agreements) {
            this.hasAgreements = !!this.options?.data?.agreements && this.options?.data?.agreements?.length > 0;
            this.agreements = this.options.data.agreements.filter(
                (a) =>
                    a.StatusCode === StatusCodeBankIntegrationAgreement.Active &&
                    a.ServiceProvider !== BankAgreementServiceProvider.External,
            );
        }

        if (!!this.agreements && this.agreements?.length > 0) {
            this.serviceProvider =
                this.agreements.filter((x) => x.ServiceProvider === BankAgreementServiceProvider.ZdataV3)[0]
                    ?.ServiceProvider ?? this.agreements[0].ServiceProvider;
            this.agreementDetails.ServiceProvider = this.serviceProvider;
            this.updateSteps();
        } else {
            this.bankService.getDefaultServiceProvider().subscribe((serviceProvider) => {
                this.serviceProvider = serviceProvider;
                this.agreementDetails.ServiceProvider = serviceProvider;
                this.updateSteps();
            });
        }

        forkJoin([
            this.companySettingsService.getCompanySettings(['BankAccounts.Bank', 'BankAccounts.Account']),
            this.userService.getCurrentUser(),
            this.elsaAgreementService.getByType('Bank'),
        ]).subscribe(
            ([settings, user, elsaAgreement]) => {
                this.companySettings = settings;
                this.accounts = [];

                // Filter out the accounts of banks already in use
                this.usedBanks = [];
                if (this.agreements.length) {
                    this.agreements.forEach((agr) => {
                        if (
                            agr.BankAccount?.Bank &&
                            this.usedBanks.findIndex((name) => name === agr.BankAccount.Bank.Name) < 0
                        ) {
                            this.usedBanks.push(agr.BankAccount.Bank.Name);
                        }
                    });
                }

                this.companySettings.BankAccounts.forEach((acc) => {
                    if (!this.usedBanks.filter((name) => name === acc.Bank?.Name).length) {
                        this.accounts.push(acc);

                        // All accounts that supports RGB
                        this.RGBApprovedAccounts.push(acc);
                    }
                });

                if (!this.accounts.length) {
                    this.errorText =
                        'Det ser ut som at du har autobankavtale for alle bankene registrert i firmaoppsettet. ' +
                        'Om du har en konto i ny bank, registrer konto i firmaoppsettet først.';
                    this.noAccounts = true;
                } else if (
                    !this.companySettings.OrganizationNumber ||
                    !this.modulusService.isValidOrgNr(this.companySettings.OrganizationNumber)
                ) {
                    this.errorText =
                        '*Mangler gyldig organisasjonsnummer. Du må gå til firmaoppsett og ' +
                        'registrere dette før du kan koble sammen bank og regnskap.';
                    this.buttonLock = true;
                }

                this.agreementDetails.Orgnr = settings.OrganizationNumber;
                this.agreementDetails.Phone = user.PhoneNumber;
                this.agreementDetails.Email = user.Email;

                this.bankAgreementUrl = this.sanitizer.bypassSecurityTrustResourceUrl(elsaAgreement?.DownloadUrl);

                this.formModel$.next(this.agreementDetails);
                this.formFields$.next(this.getFormFields(this.accounts));
            },
            (err) => {
                this.formModel$.next(this.agreementDetails);
                this.formFields$.next(this.getFormFields(this.accounts));
            },
        );
    }

    ngOnDestroy() {
        this.formFields$.complete();
        this.formModel$.complete();
    }

    private getFormFields(accounts): UniFieldLayout[] {
        const bankAccountField = new UniFieldLayout();
        bankAccountField.Property = 'BankAccountID';
        bankAccountField.FieldType = FieldType.DROPDOWN;
        bankAccountField.Label = 'Bankkonto';
        bankAccountField.Legend = 'Filter';
        bankAccountField.FieldSet = 0;
        bankAccountField.Placeholder = 'Bankkonto';
        bankAccountField.Options = {
            source: accounts,
            valueProperty: 'ID',
            template: (item) => {
                let returnString = item !== null ? this.numberFormat.asBankAcct(item.AccountNumber) : '';
                returnString += !!item.Bank ? ' - ' + item.Bank.Name : '';
                return returnString;
            },
            hideDeleteButton: true,
            debounceTime: 200,
        };
        return [
            <any>{
                Property: 'Bank',
                ReadOnly: true,
                Label: 'Bank',
            },
            bankAccountField,
            <any>{
                Property: 'Phone',
                Label: 'Telefon',
            },
        ];
    }

    onRGBChange(event) {
        const agreementDetails = this.formModel$.getValue();
        if (event?.value) {
            this.formFields$.next(this.getFormFields(this.accounts));
        } else {
            this.formFields$.next(this.getFormFields(this.RGBApprovedAccounts));

            // Reset selected bank values when changing the RGB value. User may have move forward and then back after selecting av Danske Bank account
            agreementDetails.BankAccountID = 0;
            agreementDetails.BankAccountNumber = 0;
            agreementDetails.Bank = null;
        }

        this.formModel$.next(agreementDetails);
    }

    move(direction: number) {
        const index = this.steps.findIndex((step) => step.value === this.currentStep.value);
        // Go backwards
        if (direction < 0) {
            this.currentStep = this.steps[index - 1];
            this.errorText = '';
            this.buttonLock = false;
            return;
        }

        if (this.currentStep.value === 6 || this.noAccounts) {
            this.close();
        }

        // Bank agreement step
        if (this.currentStep.value === 2 && !this.hasReadAgreement) {
            return;
        }

        // Full form step
        if (this.currentStep.value === 4) {
            if (!this.validateForm()) {
                return;
            }
            if (this.serviceProvider === BankAgreementServiceProvider.ZdataV3) {
                this.sendStartDataToZData();
                return;
            }
        }

        // Password step
        if (this.currentStep.value === 5 && this.hasAgreements) {
            this.bankService.validateAutobankPassword(this.agreementDetails.Password).subscribe((isCorrectPassword) => {
                if (!isCorrectPassword) {
                    this.errorText = 'Feil passord!';
                    return;
                } else {
                    this.errorText = '';
                }
                this.sendStartDataToZData();
                return;
            });
        }

        if (this.currentStep.value === 5 && !this.hasAgreements) {
            if (!this.isValidPassword(this.agreementDetails)) {
                return;
            }
            this.sendStartDataToZData();
            return;
        }

        this.currentStep = this.steps[index + 1];
    }

    public sendStartDataToZData() {
        this.buttonLock = true;
        this.agreementDetails.IsBankStatement = this.agreementDetails.IsBankBalance;
        this.bankService.createAutobankAgreement(this.agreementDetails).subscribe(
            (agreement) => {
                const failedAccounts = Object.getOwnPropertyNames(agreement.BankAccountResults || {}).filter(
                    (x) => !agreement.BankAccountResults[x],
                );
                if (failedAccounts.length > 0) {
                    this.toastService.addToast(
                        'En eller flere bankkontoer ble ikke onboardet hos Aritma. ' +
                            'Ta kontakt med support for å få de onboardet',
                    );
                }

                this.buttonLock = false;
                this.currentStep = this.steps[this.steps.length - 1];
            },
            (err) => {
                this.buttonLock = false;
                this.errorService.handle(err);
            },
        );
    }

    public close(value: boolean = true) {
        this.onClose.emit(value);
    }

    public onFormChange(event) {
        if (event.BankAccountID) {
            const account = this.accounts.filter((item) => item.ID === event.BankAccountID.currentValue);
            this.agreementDetails.BankAccountNumber = account[0].AccountNumber || null;
            if (account.length > 0 && account[0] && account[0].Bank) {
                this.agreementDetails.Bank = account[0].Bank.Name;
                this.formModel$.next(this.agreementDetails);
            } else {
                this.agreementDetails.Bank = '';
                this.formModel$.next(this.agreementDetails);
            }
        }

        if (event.Orgnr) {
            // trim whitespace
            this.agreementDetails.Orgnr = (this.agreementDetails.Orgnr || '').split(' ').join('');
            this.formModel$.next(this.agreementDetails);
        }

        this.agreementDetails = this.formModel$.getValue();
    }

    private validateForm(): boolean {
        this.errorText = '';

        if (!this.agreementDetails.Bank) {
            this.errorText = 'Mangler bank.';
            return false;
        }

        if (!this.agreementDetails.Phone || !this.isValidPhoneNumber(this.agreementDetails.Phone)) {
            this.errorText = 'Telefonnummer må være et gyldig norsk nummer';
            return false;
        }

        // trim whitespace from org.nr
        const validOrgNr =
            this.agreementDetails.Orgnr !== '' &&
            !isNaN(parseInt(this.agreementDetails.Orgnr, 10)) &&
            this.agreementDetails.Orgnr.length === 9;

        if (!validOrgNr) {
            if (this.agreementDetails.Orgnr) {
                this.errorText = 'Ugyldig org.nr';
            }

            return false;
        }

        if (!this.agreementDetails.Email || !this.agreementDetails.Email.includes('@')) {
            if (this.agreementDetails.Email) {
                this.errorText = 'Ugyldig e-post';
            }
            return false;
        }

        if (!this.agreementDetails.IsInbound && !this.agreementDetails.IsOutgoing) {
            this.errorText = 'Du må krysse av for innbetalinger, utbetalinger eller begge.';
            return false;
        }

        return true;
    }

    private isValidPhoneNumber(phone) {
        const test1 = /^\d{8}$/.test(phone);
        const test2 = /^0047\d{8}$/.test(phone);
        const test3 = /^\+47\d{8}$/.test(phone);

        if (test1 || test2 || test3) {
            return true;
        } else {
            return false;
        }
    }

    private isValidPassword(agreementDetails: AutoBankAgreementDetails): boolean {
        const password = agreementDetails.Password;
        const confirmPassword = agreementDetails._confirmPassword;

        let numberOfMetCriterias = 0;
        numberOfMetCriterias += /[a-z]/.test(password) ? 1 : 0;
        numberOfMetCriterias += /[A-Z]/.test(password) ? 1 : 0;
        numberOfMetCriterias += /[\d]/.test(password) ? 1 : 0;
        numberOfMetCriterias += /[\@\#\$\%\^\&\*\-_\\+\=\[\]\{\}\:\,\.\?\!\`\(\)\;]/.test(password) ? 1 : 0;

        const passwordValid = numberOfMetCriterias === 4 && password.length >= 10;
        let passwordConfirmed: boolean;

        if (passwordValid) {
            if (password === confirmPassword) {
                this.errorText = '';
                passwordConfirmed = true;
            } else {
                if (confirmPassword) {
                    this.errorText = 'Passordene er ikke like';
                }

                passwordConfirmed = false;
            }
        } else {
            this.errorText = 'Ugyldig passord! ' + this.passwordCriteriaMsg;
        }

        return passwordValid && passwordConfirmed;
    }

    private updateSteps(): void {
        if (this.serviceProvider === 4) {
            this.steps.splice(5, 1);
        }

        if (this.serviceProvider === 1) {
            this.steps.splice(3, 1);
        }
        this.steps = [...this.steps];
        this.busy = false;
        this.initialized = true;
    }
}
