import { Injectable } from '@angular/core';
import { UniHttp } from '../../../framework/core/http/http';
import { Observable, of } from 'rxjs';
import { catchError, map, publishReplay, refCount, take } from 'rxjs/operators';
import { cloneDeep } from 'lodash-es';
import { DimensionSettingsService } from './dimensionSettingsService';

@Injectable({ providedIn: 'root' })
export class CustomDimensionService {
    private cache: { [endpoint: string]: Observable<any> } = {};
    private companyKey: string;

    constructor(
        private http: UniHttp,
        private dimensionSettingsService: DimensionSettingsService,
    ) {
        this.http.authService.authentication$.subscribe((auth) => {
            if (auth?.activeCompany?.Key !== this.companyKey) {
                this.invalidateCache();
                this.companyKey = auth.activeCompany?.Key;
            }
        });
    }

    invalidateCache() {
        this.cache = {};
    }

    public getCustomDimensionList(dimension: number, odata?: string) {
        let endpoint = 'dimension' + dimension;
        if (odata) {
            endpoint += odata;
        }

        let request = this.cache[endpoint];
        if (!request) {
            request = this.http
                .asGET()
                .usingBusinessDomain()
                .withEndPoint(endpoint)
                .send()
                .pipe(publishReplay(1), refCount());

            this.cache[endpoint] = request;
        }

        return request.pipe(
            take(1),
            map((res) => cloneDeep(res.body)),
        );
    }

    public saveCustomDimension(dimension: number, body: any) {
        this.invalidateCache();

        if (body.ID) {
            return this.http
                .asPUT()
                .usingBusinessDomain()
                .withEndPoint('dimension' + dimension + '/' + body.ID)
                .withBody(body)
                .send()
                .pipe(map((res) => res.body));
        } else {
            return this.http
                .asPOST()
                .usingBusinessDomain()
                .withEndPoint('dimension' + dimension)
                .withBody(body)
                .send()
                .pipe(map((res) => res.body));
        }
    }

    public getMetadata() {
        return this.dimensionSettingsService.GetAll().pipe(
            catchError((err) => {
                console.error(err);
                return of([]);
            }),
        );
    }

    public checkIfUsed(dimension: number, id: number) {
        return this.http
            .asGET()
            .usingBusinessDomain()
            .withEndPoint('dimension' + dimension + '/' + id + '?action=is-used')
            .send()
            .pipe(map((res) => res.body));
    }

    public Remove(dimension: number, id: number) {
        this.invalidateCache();

        return this.http
            .asDELETE()
            .usingBusinessDomain()
            .withEndPoint('dimension' + dimension + '/' + id)
            .send()
            .pipe(map((res) => res.body));
    }

    public mapDimensionInfoToDimensionObject(dims) {
        if (dims) {
            const info = dims.Info && dims.Info[0];
            if (dims.ProjectID && !dims.Project) {
                dims.Project = {
                    ProjectNumber: info.ProjectNumber,
                    Name: info.ProjectName,
                };
            }
            if (dims.DepartmentID && !dims.Department) {
                dims.Department = {
                    DepartmentNumber: info.DepartmentNumber,
                    Name: info.DepartmentName,
                };
            }

            for (let i = 5; i <= 10; i++) {
                if (dims[`Dimension${i}ID`] && !dims[`Dimension${i}`]) {
                    dims[`Dimension${i}`] = {
                        Number: info[`Dimension${i}Number`],
                        Name: info[`Dimension${i}Name`],
                    };
                }
            }
        }

        return dims;
    }
}
