import { Component, Input } from '@angular/core';
import { StatisticsService } from '@app/services/common/statisticsService';
import { StatusCodeSharing, SharingType } from '@uni-entities';
import { UniModalService } from '@uni-framework/uni-modal';
import { ImageModal } from '../../modals/ImageModal';

@Component({
    selector: 'toolbar-sharing-status',
    templateUrl: './sharing-status.html',
    styleUrls: ['./sharing-status.sass'],
})
export class ToolbarSharingStatus {
    @Input() entityType: string;
    @Input() entityID: string;

    sharingHistory: any[];
    sharingStatusText: string;

    statusIndicator: { label: string; class?: string; icon?: string };

    constructor(
        private statisticsService: StatisticsService,
        private modalService: UniModalService,
    ) {}

    ngOnChanges() {
        this.loadStatuses();
    }

    loadStatuses() {
        this.sharingHistory = undefined;
        this.sharingStatusText = undefined;
        this.statusIndicator = undefined;

        if (this.entityType && this.entityID) {
            const previousSharingsQuery =
                `model=Sharing` +
                `&filter=EntityType eq '${this.entityType}' and EntityID eq ${this.entityID}` +
                `&join=Sharing.EntityID eq FileEntityLink.EntityID and FileEntityLink.FileID eq File.ID` +
                `&select=ID,Type,StatusCode,ExternalMessage,UpdatedAt,CreatedAt,To,sum(casewhen(FileEntityLink.EntityType eq 'CustomerInvoice' and File.StatusCode eq 50001\\,1\\,0)) as attachment`;

            this.statisticsService.GetAllUnwrapped(previousSharingsQuery).subscribe(
                (sharings) => {
                    if (sharings?.length) {
                        this.sharingHistory = sharings.map((item) => {
                            item._text = this.getSharingText(item);
                            item._status = this.getSharingStatus(item);
                            item._class = this.getSharingClass(item);
                            return item;
                        });

                        const nonPrintSharings = sharings.filter((s) => s.SharingType !== SharingType.Print);
                        if (nonPrintSharings && nonPrintSharings.length) {
                            this.statusIndicator = this.getStatusIndicator(
                                nonPrintSharings[nonPrintSharings.length - 1],
                            );
                        } else {
                            this.statusIndicator = this.getStatusIndicator(sharings[sharings.length - 1]);
                        }
                    }
                },
                (err) => console.error(err),
            );
        }
    }

    private getStatusIndicator(sharing) {
        if (sharing.SharingType === SharingType.Print) {
            return {
                label: 'Skrevet ut',
                icon: 'print',
            };
        }

        if (sharing.SharingType === SharingType.Email && sharing.SharingStatusCode === StatusCodeSharing.Completed) {
            return {
                label: 'Sendt på epost',
                icon: 'email',
                class: 'color-good',
            };
        }

        switch (sharing.SharingStatusCode) {
            case StatusCodeSharing.Pending:
            case StatusCodeSharing.InProgress:
                return {
                    label: 'I utsendingskø',
                    icon: 'timelapse',
                };
            case StatusCodeSharing.Completed:
                return {
                    label: 'Utsending fullført',
                    class: 'color-good',
                    icon: 'check_circle',
                };
            case StatusCodeSharing.Cancelled:
                return {
                    label: 'Utsending avbrutt',
                    class: 'color-warn',
                    icon: 'error_outline',
                };
            case StatusCodeSharing.Failed:
                return {
                    label: 'Utsending feilet',
                    class: 'color-bad',
                    icon: 'error_outline',
                };
        }

        return;
    }

    getSharingText(sharing) {
        switch (sharing.SharingType) {
            case SharingType.Unknown:
                return 'Sending via utsendingsplan';
            case SharingType.Print:
                return 'Skrevet ut';
            case SharingType.Email:
                return `Sendt på epost`;
            case SharingType.AP:
                return 'Sending via aksesspunkt/EHF';
            case SharingType.Vipps:
                return 'Sending via Vipps faktura';
            case SharingType.Export:
                return 'Eksportert';
            case SharingType.InvoicePrint:
                return 'DISTRIBUTION.INVOICE_PRINT';
            case SharingType.Efaktura:
                return 'Sending via eFaktura';
            case SharingType.Avtalegiro:
                return 'Sending via avtalegiro';
            case SharingType.Factoring:
                return 'Factoring';
        }
    }

    getSharingClass(sharing) {
        switch (sharing.SharingStatusCode) {
            case StatusCodeSharing.Pending:
            case StatusCodeSharing.InProgress:
                return 'info';
            case StatusCodeSharing.Completed:
                return 'good';
            case StatusCodeSharing.Cancelled:
            case StatusCodeSharing.Failed:
                return 'bad';
        }
    }

    getSharingStatus(sharing) {
        switch (sharing.SharingStatusCode) {
            case StatusCodeSharing.Pending:
            case StatusCodeSharing.InProgress:
                return 'Planlagt / i kø';
            // return 'Behandles';
            case StatusCodeSharing.Completed:
                return 'Fullført';
            case StatusCodeSharing.Cancelled:
                return 'Avbrutt';
            case StatusCodeSharing.Failed:
                return 'Feilet';
        }
    }

    openAttachment() {
        this.modalService.open(ImageModal, {
            data: {
                entity: this.entityType,
                entityID: this.entityID,
                filterOnStatusCode: 50001,
            },
        });
    }
}
