<uni-form
    class="two-column-layout"
    [config]="{ autofocus: true, showLabelAbove: true }"
    [fields]="formFields"
    [model]="entity"
    (changeEvent)="onFormChange()"
></uni-form>
<section class="alert info">
    <i class="material-icons">info</i>
    <span>Du kan legge til mer informasjon om denne kontoen senere under menyvalget Regnskap -> Kontoplan.</span>
</section>
