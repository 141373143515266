import { Component, Input, inject } from '@angular/core';
import { Router } from '@angular/router';
import { AnalyticsEvent, AnalyticsService } from '@app/services/analytics-services/analytics.service';
import { CMSSlideinData, CMSWidgetData } from '@app/services/cms/cms-service';

@Component({
    selector: 'cms-link',
    template: `<a (click)="onComponentClick()" [style.min-width]="width" [class]="item.linkAppearance">
        {{ item.text }}</a
    >`,
})
export class CMSLink {
    @Input() item;
    @Input() width;
    @Input() cmsData: CMSWidgetData | CMSSlideinData;

    analytics = inject(AnalyticsService);

    constructor(private router: Router) {}

    onComponentClick() {
        if (this.item.isExternal) {
            window.open(this.item.url);
        } else {
            this.router.navigateByUrl(this.item.url);
        }

        this.analytics.trackCMSEvents(AnalyticsEvent.CMS_LINK_CLICKED, this.getAnalyticsValue());
    }

    private getAnalyticsValue() {
        return `${this.cmsData?.title} - ${this.item.url} - ${this.cmsData?._id}`;
    }
}
