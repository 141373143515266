import { Component, ElementRef, Input } from '@angular/core';

@Component({
    selector: 'simple-table',
    styleUrls: ['./simple-table.sass'],
    template: `
        <table [style.table-layout]="tableLayout">
            <ng-content></ng-content>
        </table>
    `,
})
export class SimpleTable {
    @Input() tableLayout: string;

    constructor(private elementRef: ElementRef) {}

    scrollToIndex(index: number) {
        const rows = this.elementRef?.nativeElement?.querySelectorAll('tbody tr');
        if (rows && rows[index]) {
            rows[index].scrollIntoView({ block: 'center' });
        }
    }
}
