<ng-container *ngFor="let item of listItems">
    <div *ngIf="item.type === 'header'" class="dropdown-menu-header">
        {{ item.text }}
    </div>

    <div
        *ngIf="item.type === 'value'"
        class="dropdown-menu-item"
        [class.has-subtext]="config?.subTextTemplate"
        [id]="item.id"
        [attr.aria-selected]="item.id === focusedId"
        (click)="select(item)"
    >
        <div *ngIf="config?.subTextTemplate" class="subtext-wrapper flex flex-column">
            <span> {{ item.text }} </span>
            <span class="subtext"> {{ item.subtext }} </span>
        </div>

        <span *ngIf="!config?.subTextTemplate">{{ item.text }} </span>

        <div *ngIf="getItemActions(item) as actions" class="actions flex ml-auto">
            <button
                *ngIf="actions.edit"
                (click)="onItemActionClick($event, item, actions.edit)"
                class="icon-button small"
                aria-label="Edit"
            >
                <i class="material-icons">edit</i>
            </button>

            <button
                *ngIf="actions.delete"
                (click)="onItemActionClick($event, item, actions.delete)"
                class="icon-button small"
                aria-label="Delete"
            >
                <i class="material-icons">delete</i>
            </button>
        </div>
    </div>

    <div
        *ngIf="item.type === 'create'"
        class="dropdown-menu-item create"
        [id]="item.id"
        [attr.aria-selected]="item.id === focusedId"
        (click)="select(item)"
    >
        <svg aria-hidden="true" class="mr-1" fill="none" width="17" viewBox="0 0 17 16">
            <path
                d="M8.224 14V2m6 6h-12"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
            />
        </svg>
        {{ item.text }}
    </div>
</ng-container>
