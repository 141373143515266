import { Component, ElementRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'rig-switch',
    templateUrl: './switch.html',
    styleUrls: ['./switch.sass'],
    providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: RigSwitch, multi: true }],
})
export class RigSwitch implements ControlValueAccessor {
    @Input() checked: boolean;
    @Input() labelPosition: 'before' | 'after' = 'after';
    @Input() disabled: boolean;

    _updateControlValueAccessor = (value: any) => {};

    constructor(private el: ElementRef) {}

    onChange(value: boolean) {
        this.checked = value;
        this._updateControlValueAccessor(value);
    }

    writeValue(value): void {
        this.checked = value;
    }

    setDisabledState(disabled: boolean): void {
        this.disabled = disabled;
    }

    focus() {
        this.el?.nativeElement?.querySelector('input').focus();
    }

    registerOnChange(fn: any): void {
        this._updateControlValueAccessor = fn;
    }

    registerOnTouched(fn) {}

    // Due to how labels work (click on label triggers click on input),
    // we need to make sure only one of them bubbles up to the parent
    // in case anyone uses the click event to toggle checked state.
    preventDuplicateClickEvents(event: MouseEvent) {
        if ((event.target as HTMLElement)?.tagName !== 'INPUT') {
            event.stopPropagation();
        }
    }
}
