<section class="alert {{ type }}" *ngIf="isOpen">
    <i class="material-icons" [ngClass]="type">{{ icon || 'info' }}</i>
    <article class="info-text">
        <h2 class="title" *ngIf="title" [innerHTML]="title"></h2>
        <p *ngIf="text" [innerHTML]="text"></p>
        <p *ngFor="let line of lines"><li [innerHTML]="line"></li></p>
        <ng-content></ng-content>
    </article>
    <i class="material-icons close" *ngIf="showCloseButton" (click)="hide()">close</i>
</section>
