<section class="save-buttons">
    <button
        type="button"
        *ngIf="main"
        class="toolbar-save"
        [ngClass]="buttonClass"
        [class.has-toggle]="filteredActions?.length > 1"
        (click)="onSave(main)"
        [attr.aria-busy]="busy"
        [disabled]="main.disabled"
        data-testid="toolbar-save-button"
    >
        {{ main.label | translate }}
    </button>

    <ng-container *ngIf="filteredActions?.length > 1">
        <button #toggle class="toolbar-save-toggle" [ngClass]="buttonClass" data-testid="toolbar-save-dropdown-toggle">
            <i class="material-icons">expand_more</i>
        </button>

        <dropdown-menu [trigger]="toggle" [minWidth]="'14rem'">
            <ng-template>
                <a
                    *ngFor="let action of filteredActions"
                    class="dropdown-menu-item"
                    (click)="onSave(action)"
                    [attr.aria-disabled]="action.disabled || busy"
                >
                    <span> {{ action.label | translate }} </span>
                </a>
            </ng-template>
        </dropdown-menu>
    </ng-container>
</section>

<!-- Fixed position on the bottom of the page -->
<section *ngIf="statusMessage?.length" class="uni-save-status" role="status">
    {{ statusMessage }}
</section>
