import { NgModule } from '@angular/core';
import { LibraryImportsModule } from '@app/library-imports.module';
import { ClickOutsideModule } from '@uni-framework/click-outside/click-outside.module';
import { ScrolledToBottomModule } from '@uni-framework/scrolled-to-bottom.directive';
import { DesignSystemModule } from '../design-system/design-system.module';
import { InputDropdownModule } from '../input-dropdown/input-dropdown';
import { AccountSearch } from './account-search';
import { AccountSearchDropdown } from './account-search-dropdown/account-search-dropdown';
import { TableAccountSearch } from './table-account-search';

@NgModule({
    imports: [
        LibraryImportsModule,
        DesignSystemModule,
        InputDropdownModule,
        ClickOutsideModule,
        ScrolledToBottomModule,
    ],
    declarations: [AccountSearch, TableAccountSearch, AccountSearchDropdown],
    exports: [AccountSearch, TableAccountSearch],
})
export class AccountSearchModule {}
