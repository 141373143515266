import { NgModule } from '@angular/core';
import { LibraryImportsModule } from '@app/library-imports.module';
import { DatepickerModule } from '../datepicker/datepicker.module';
import { DropdownMenuModule } from '../dropdown-menu/dropdown-menu';
import { QueryBuilder, QueryBuilderItem } from './query-builder';

@NgModule({
    imports: [LibraryImportsModule, DatepickerModule, DropdownMenuModule],
    declarations: [QueryBuilder, QueryBuilderItem],
    exports: [QueryBuilder, QueryBuilderItem],
})
export class QueryBuilderModule {}
