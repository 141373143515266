import { Pipe, PipeTransform } from '@angular/core';
import { Period } from '@uni-entities';
import { rigDate } from '@app/components/common/utils/rig-date';

@Pipe({ name: 'periodDateFormat' })
export class PeriodDateFormatPipe implements PipeTransform {
    transform(period?: Period): string {
        try {
            if (!period) {
                return '';
            }
            const from = rigDate(period.FromDate);
            const to = rigDate(period.ToDate);
            const length = to.diff(from, 'days');
            if (length < 32) {
                // One month period
                return this.capitalize(from.format('MMMM')) + to.format(' YYYY');
            } else if (length < 366) {
                // More than one month period
                return this.capitalize(from.format('MMMM')) + to.format('-MMMM YYYY');
            } else {
                // Year period
                return from.format('YYYY');
            }
        } catch (err) {
            console.error(err);
            return <any>period;
        }
    }

    private capitalize(source: string): string {
        return source.charAt(0).toUpperCase() + source.slice(1);
    }
}
