<section class="input-with-button">
    <input
        #input
        type="text"
        [formControl]="inputControl"
        (keypress)="busy = true"
        (keydown)="onKeyDown($event)"
        role="combobox"
        [title]="placeholder"
        [placeholder]="placeholder"
    />

    <button type="button" tabIndex="-1" (click)="toggle(); input.focus()">
        <i class="material-icons">search</i>
    </button>
</section>

<input-dropdown-menu [input]="input" [visible]="expanded && (lookupResults?.length || options.buttons)">
    <ng-template>
        <section *ngIf="!(loading$ | async)" class="result-container" #dropdown>
            <!-- List view -->
            <section *ngIf="!options.showResultAsTable" class="results">
                <section
                    *ngFor="let item of lookupResults; let idx = index"
                    (click)="itemClicked(idx, item.isHeader)"
                    [ngClass]="item.isHeader ? 'dropdown-menu-header' : 'dropdown-menu-item'"
                    class="dropdown-menu-item"
                    [attr.aria-selected]="selectedIndex === idx"
                >
                    {{ item.isHeader ? item.header : options.itemTemplate(item) }}
                </section>

                <section class="no-results" *ngIf="lookupResults.length === 0">Ingen resultater</section>
            </section>

            <!-- Table view -->
            <section *ngIf="options.showResultAsTable && options.resultTableConfig" class="results">
                <table>
                    <thead>
                        <tr>
                            <th
                                *ngFor="let field of options.resultTableConfig.fields"
                                [ngStyle]="{ width: field.width, textAlign: field.isMoneyField ? 'right' : 'left' }"
                            >
                                {{ field.header }}
                            </th>
                        </tr>
                    </thead>

                    <tbody #optionContainer>
                        <tr
                            *ngFor="let item of lookupResults; let idx = index"
                            [attr.aria-selected]="selectedIndex === idx"
                            (click)="itemClicked(idx, item.isHeader)"
                        >
                            <td
                                *ngFor="let field of options.resultTableConfig.fields"
                                [ngStyle]="{ width: field.width, textAlign: field.isMoneyField ? 'right' : 'left' }"
                                [ngClass]="field.class"
                            >
                                <span *ngIf="!field.isMoneyField">
                                    {{ item[field.key] }}
                                </span>
                                <span *ngIf="field.isMoneyField">
                                    {{ item[field.key] | uninumberformat: 'money' }}
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <section class="no-results" *ngIf="lookupResults.length === 0">Ingen resultater</section>
            </section>

            <section *ngIf="!busy && options.buttons?.length" class="footer">
                <button
                    *ngFor="let button of options.buttons; let idx = index"
                    (click)="runButtonAction(button)"
                    class="unstyled"
                >
                    <span class="add-icon">
                        <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                            <path d="M9.167 9.167v-5h1.666v5h5v1.666h-5v5H9.167v-5h-5V9.167h5Z" fill="currentColor" />
                        </svg>
                    </span>
                    {{ button.label || 'Opprett ny' }}

                    <span class="keyboard-shortcut-hint" *ngIf="idx < 3"> (F{{ idx + 1 }}) </span>
                </button>
            </section>
        </section>
    </ng-template>
</input-dropdown-menu>
