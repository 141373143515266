import { Component, ElementRef, EventEmitter, HostListener, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IUniModal, IModalOptions } from '../../interfaces';
import { debounceTime, switchMap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FileInboxDto } from '@uni-entities';
import { ErrorService } from '@app/services/common/errorService';
import { FileService } from '@app/services/common/fileService';
import { UniFilesService } from '@app/services/common/uniFilesService';
import { BrowserStorageService } from '@uni-framework/core/browserStorageService';

interface InboxFileExtended extends FileInboxDto {
    _shortDescription: string;
    _source: string;
    _checked?: boolean;
}

const LOCALSTORAGE_KEY = 'inbox_modal_comments_hidden';

@Component({
    selector: 'file-from-inbox-modal',
    templateUrl: './file-from-inbox-modal.html',
    styleUrls: ['./file-from-inbox-modal.sass'],
})
export class FileFromInboxModal implements IUniModal {
    @ViewChild('fileList') fileList: ElementRef<HTMLElement>;

    options: IModalOptions = {};
    onClose = new EventEmitter<InboxFileExtended[]>();

    files: InboxFileExtended[];
    selectedFile: InboxFileExtended;
    multiselect: boolean;

    buttonLabel = 'Velg markert fil';
    sortDirection = 'desc';
    checkAll = false;
    commentsHidden = false;

    // Debounce updates to preview so we don't spam the file api when navigating with keyboard
    previewFileID: number;
    previewDebouncer = new Subject<number>();

    constructor(
        private hostElement: ElementRef,
        private http: HttpClient,
        private uniFilesService: UniFilesService,
        private browserStorageService: BrowserStorageService,
        private fileService: FileService,
        private errorService: ErrorService,
    ) {}

    ngOnInit() {
        this.multiselect = this.options.data?.multiselect;

        const url = `/api/biz/filetags/${this.fileService.inboxTagNames.join('|')}/0?action=get-supplierInvoice-inbox`;

        this.http.get<FileInboxDto[]>(url).subscribe({
            next: (files) => {
                this.files = files?.map((file) => ({
                    ...file,
                    _shortDescription: this.getShortDescription(file.Description),
                    _source: this.fileService.getInboxFileSource(file),
                }));

                this.sort();
                this.setSelectedFile(0);
                setTimeout(() => this.fileList?.nativeElement?.focus());
            },
            error: (err) => this.errorService.handle(err),
        });

        this.commentsHidden = this.browserStorageService.getItem(LOCALSTORAGE_KEY) || false;
        this.previewDebouncer.pipe(debounceTime(200)).subscribe((fileID) => (this.previewFileID = fileID));
    }

    private getShortDescription(description: string) {
        return description?.length > 200 ? description.substring(0, 200) + '...' : description;
    }

    toggleCommentsHidden() {
        this.commentsHidden = !this.commentsHidden;
        this.browserStorageService.setItem(LOCALSTORAGE_KEY, this.commentsHidden);
    }

    onCheckAllChange() {
        this.files = this.files.map((file) => {
            file._checked = this.checkAll;
            return file;
        });

        this.updateButtonLabel();
    }

    onFileCheckedChange() {
        this.checkAll = this.files.every((file) => file._checked);
        this.updateButtonLabel();
    }

    uploadFile(event) {
        const source = event.srcElement || event.target;
        if (!source.files || !source.files[0]) {
            return;
        }

        this.uniFilesService
            .upload(source.files[0], 'SupplierInvoice')
            .pipe(
                switchMap((res) => {
                    this.fileService.tag(res.ExternalId, 'Upload').subscribe(
                        () => {},
                        () => {},
                    );
                    return this.fileService.Get(res.ExternalId);
                }),
            )
            .subscribe(
                (file) => {
                    if (this.sortDirection === 'desc') {
                        this.files.unshift(file);
                        this.setSelectedFile(0);
                    } else {
                        this.files.push(file);
                        this.setSelectedFile(this.files.length - 1);
                    }
                },
                (err) => this.errorService.handle(err),
            );
    }

    private updateButtonLabel() {
        const checkedFiles = this.files.filter((file) => file._checked);
        this.buttonLabel = checkedFiles.length ? `Velg filer (${checkedFiles.length})` : 'Velg fil';
    }

    private sort() {
        this.files =
            this.sortDirection === 'asc'
                ? this.files.sort((a, b) => a.ID - b.ID)
                : this.files.sort((a, b) => b.ID - a.ID);
    }

    @HostListener('window:keydown', ['$event'])
    onWindowKeyDown(event: KeyboardEvent) {
        // Check if the event originated from outside the dialog, and refocus the file list if it did.
        // This is needed because the journalentry table behind our dialog loves to steal focus..
        const path = event.composedPath() as EventTarget[];
        if (!path.includes(this.hostElement?.nativeElement)) {
            this.fileList?.nativeElement?.focus();
            this.onKeyDown(event);
        }
    }

    onKeyDown(event: KeyboardEvent) {
        if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
            event.preventDefault();
            let index = this.files.findIndex((file) => file.ID === this.selectedFile?.ID);

            if (event.key === 'ArrowUp' && index > 0) {
                index--;
            }

            if (event.key === 'ArrowDown' && index < this.files?.length - 1) {
                index++;
            }

            this.setSelectedFile(index);
        }

        if (event.key === ' ' && this.multiselect && this.selectedFile) {
            event.preventDefault();
            this.selectedFile._checked = !this.selectedFile._checked;
            this.onFileCheckedChange();
        }

        if (event.key === 'Enter') {
            this.emitAndClose();
        }
    }

    setSelectedFile(index: number) {
        if (this.files[index]) {
            this.selectedFile = this.files[index];
            this.previewDebouncer.next(this.selectedFile.ID);

            const listElement = this.fileList?.nativeElement;
            if (listElement) {
                listElement.querySelectorAll('.file-list-item').item(index)?.scrollIntoView({ block: 'nearest' });
            }
        }
    }

    emitAndClose() {
        let selectedFiles = this.files.filter((file) => file._checked);
        if (!selectedFiles.length && this.selectedFile) {
            selectedFiles = [this.selectedFile];
        }

        this.onClose.emit(selectedFiles);
    }
}
