<label
    class="file-input-container flex-column gap-2"
    [ngClass]="layout"
    [for]="inputId"
    [class.is-dragging]="isDraggingOver"
    (dragover)="onDragover($event)"
    (dragleave)="isDraggingOver = false"
    (drop)="onDrop($event)"
>
    <div class="file-input flex align-center">
        <div *ngIf="layout !== 'button-only'" class="title flex-1 flex align-center gap-1">
            <svg fill="none" viewBox="0 0 25 25">
                <path
                    d="m16.012 13.905 6.964 4.062-2.973.85 2.125 3.681-1.732 1-2.125-3.68-2.223 2.15-.036-8.063Zm-2-7h2v2h5a1 1 0 0 1 1 1v4h-2v-3h-10v10h4v2h-5a1 1 0 0 1-1-1v-5h-2v-2h2v-5a1 1 0 0 1 1-1h5v-2Zm-10 8v2h-2v-2h2Zm0-4v2h-2v-2h2Zm0-4v2h-2v-2h2Zm0-4v2h-2v-2h2Zm4 0v2h-2v-2h2Zm4 0v2h-2v-2h2Zm4 0v2h-2v-2h2Z"
                    fill="#5C5F62"
                />
            </svg>
            Dra og slipp {{ fileLabel || (multiple ? 'filene dine' : 'filen din') }} her, eller
        </div>

        <div class="actions flex align-center gap-2" [class.has-inbox-btn]="canUseInbox && hasInboxPermission">
            <button (click)="fileInput?.click()" class="secondary small upload-btn">
                {{ selectFilesButtonLabel }}
            </button>

            <button *ngIf="canUseInbox && hasInboxPermission" (click)="getFileFromInbox()" class="secondary small">
                Hent fra innboks
            </button>
        </div>

        <input
            #fileInput
            type="file"
            class="visually-hidden"
            [accept]="accept"
            [id]="inputId"
            [multiple]="multiple"
            (change)="onFileInputChange($event)"
        />

        <section *ngIf="uploading$ | async" class="upload-spinner flex align-center justify-center gap-1">
            <mat-spinner diameter="18"></mat-spinner>
            Laster opp...
        </section>
    </div>

    <ul *ngIf="!upload && files?.length" class="file-list" (click)="$event.stopPropagation(); $event.preventDefault()">
        <li *ngFor="let file of files" class="flex align-center border rounded">
            <i class="material-icons">attach_file</i>
            <span class="flex-1 ellipsis">{{ file.name }}</span>
            <i (click)="removeFile(file, $event)" role="button" class="material-icons remove">close</i>
        </li>
    </ul>
</label>
