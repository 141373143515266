import { Component, EventEmitter } from '@angular/core';
import { forkJoin, BehaviorSubject, of } from 'rxjs';
import { UniFieldLayout, FieldType } from '../../../ui/uniform/index';
import { ToastService, ToastType } from '../../../uniToast/toastService';
import { ActivateAP } from '@app/models/activateAP';
import { ConfirmActions, IModalOptions, IUniModal } from '../../interfaces';
import { UniModalService } from '../../modalService';
import { ElsaAgreement, ElsaAgreementStatus, ActivationEnum, ElsaProduct } from '@app/models';
import { switchMap } from 'rxjs/operators';
import { BankAccountService } from '@app/services/accounting/bankAccountService';
import { CompanySettingsService } from '@app/services/common/companySettingsService';
import { EHFService } from '@app/services/common/EHFService';
import { ErrorService } from '@app/services/common/errorService';
import { UserService } from '@app/services/common/userService';
import { ElsaProductService } from '@app/services/elsa/elsaProductService';
import { ElsaPurchaseService } from '@app/services/elsa/elsaPurchasesService';

@Component({
    selector: 'uni-activate-ap-modal',
    templateUrl: './activateAPModal.html',
    styleUrls: ['./activateAPModal.sass'],
})
export class UniActivateAPModal implements IUniModal {
    options: IModalOptions = {};
    modalService: UniModalService;

    onClose = new EventEmitter();

    formModel$ = new BehaviorSubject<ActivateAP>(null);
    formFields$ = new BehaviorSubject<UniFieldLayout[]>([]);

    terms: ElsaAgreement;
    termsAgreed: boolean;
    busy: boolean = false;
    hasActivatedIncomming: boolean = false;
    invalidFormSubmitted: boolean = false;

    ehfProduct: ElsaProduct;

    forceCloseValueResolver = () => ActivationEnum.NOT_ACTIVATED;

    constructor(
        private ehfService: EHFService,
        private companySettingsService: CompanySettingsService,
        private userService: UserService,
        private errorService: ErrorService,
        private toastService: ToastService,
        private elsaProductService: ElsaProductService,
        private elsaPurchaseService: ElsaPurchaseService,
    ) {}

    ngOnInit() {
        this.busy = true;

        forkJoin([
            this.elsaProductService.GetAll(`name eq 'EHF'`),
            this.userService.getCurrentUser(),
            this.companySettingsService.getCompanySettings([
                'DefaultPhone',
                'DefaultEmail',
                'APContact.Info.DefaultPhone',
                'APContact.Info.DefaultEmail',
            ]),
        ]).subscribe(
            ([products, user, settings]) => {
                const product = products.find((p) => p.Name === 'EHF');
                this.ehfProduct = product;

                if (product?.ProductAgreement?.AgreementStatus === ElsaAgreementStatus.Active) {
                    this.terms = product.ProductAgreement;
                }

                const apContactInfo = settings?.APContact?.Info;

                this.formModel$.next(<ActivateAP>{
                    orgnumber: settings.OrganizationNumber,
                    orgname: settings.CompanyName,
                    orgphone: settings.DefaultPhone?.Number,
                    orgemail: settings.DefaultEmail?.EmailAddress,
                    contactname: apContactInfo?.Name || user.DisplayName,
                    contactemail: apContactInfo?.DefaultEmail?.EmailAddress || user.Email,
                    contactphone: apContactInfo?.DefaultPhone?.Number || user.PhoneNumber,
                    incommingInvoice: settings.APIncomming?.some((f) => f.Name.startsWith('EHF')),
                    settings: settings,
                });

                this.formFields$.next(this.getFormFields());
                this.busy = false;
            },
            (err) => {
                this.errorService.handle(err);
                this.busy = false;
            },
        );
    }

    ngOnDestroy() {
        this.formModel$.complete();
        this.formFields$.complete();
    }

    confirmTerms() {
        this.modalService
            .confirm({
                header: this.terms.Name,
                message: this.terms.AgreementText,
                class: 'medium',
                isMarkdown: true,
                buttonLabels: {
                    accept: 'Aksepter',
                    cancel: 'Tilbake',
                },
            })
            .onClose.subscribe((response) => {
                if (response === ConfirmActions.ACCEPT) {
                    this.termsAgreed = true;
                }
            });
    }

    activate() {
        const model = this.formModel$.getValue();
        this.invalidFormSubmitted = false;

        if (!this.isValidFormData()) {
            this.invalidFormSubmitted = true;
            return;
        }

        this.busy = true;

        const productPurchase = { ID: null, ProductID: this.ehfProduct.ID };
        this.elsaPurchaseService
            .massUpdate([productPurchase])
            .pipe(
                switchMap(() => this.companySettingsService.Put(model.settings.ID, model.settings)),
                switchMap(() => this.ehfService.activate('billing', model, 'in')),
            )
            .subscribe(
                (status) => {
                    if (status === ActivationEnum.ACTIVATED) {
                        this.toastService.addToast('Aktivering', ToastType.good, 3, 'EHF mottak aktivert');
                    } else if (status === ActivationEnum.EXISTING) {
                        this.ehfService.serviceMetadata(`0192:${model.orgnumber}`, 'CustomerInvoice').subscribe(
                            (serviceMetadata) => {
                                this.toastService.addToast(
                                    'Aktivering på vent',
                                    ToastType.warn,
                                    15,
                                    serviceMetadata.ServiceName
                                        ? `Org.nr. ${model.orgnumber} er allerede aktivert for mottak av faktura hos ${serviceMetadata.ServiceName} og dermed kan kun sending aktiveres. For å kunne aktivere mottak må dere først få deaktivert mottak i ELMA hos tjenesten som bruker aksesspunktet ${serviceMetadata.ServiceName}. Deretter forsøk igjen eller ta kontakt med support.`
                                        : `Org.nr. ${model.orgnumber} er allerede aktivert på et SoftRig-system eller Uni Økonomi V3 som benytter Uni Micro sitt aksesspunkt. Deaktiver løsningen som er i bruk eller ta kontakt med support for å fikse.`,
                                );
                            },
                            (err) => this.errorService.handle(err),
                        );
                    } else {
                        this.toastService.addToast(
                            'Aktivering feilet!',
                            ToastType.bad,
                            5,
                            'Noe gikk galt ved aktivering',
                        );
                    }

                    this.close(status);
                },
                (err) => {
                    this.busy = false;
                    this.errorService.handle(err);
                },
            );
    }

    public close(activationCode = ActivationEnum.NOT_ACTIVATED) {
        this.onClose.emit(activationCode);
    }

    isValidFormData(): boolean {
        const model = this.formModel$.getValue();
        return (
            model.orgnumber &&
            model.orgname &&
            model.contactname &&
            this.isValidPhoneNumber(model.contactphone) &&
            this.isValidEmailAddress(model.contactemail)
        );
    }

    isValidPhoneNumber(phone: string): boolean {
        const test1 = /^\d{8}$/.test(phone);
        const test2 = /^0047\d{8}$/.test(phone);
        const test3 = /^\+47\d{8}$/.test(phone);

        return test1 || test2 || test3;
    }

    isValidEmailAddress(email: string): boolean {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(String(email).toLowerCase());
    }

    private getFormFields(): UniFieldLayout[] {
        return [
            <any>{
                Property: 'orgnumber',
                Label: 'Organisasjonsnummer',
                Classes: 'full-width',
            },
            <any>{
                Property: 'orgname',
                Label: 'Firmanavn',
            },
            <any>{
                Property: 'contactname',
                Label: 'Kontaktnavn',
            },
            <any>{
                Property: 'contactphone',
                Label: 'Kontakttelefon',
            },
            <any>{
                Property: 'contactemail',
                FieldType: FieldType.EMAIL,
                Label: 'Kontakt-e-post',
            },
        ];
    }
}
