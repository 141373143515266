<rig-switch
    *ngIf="field?.Options?.slideToggle; else checkbox"
    [disabled]="!!field?.ReadOnly"
    [(ngModel)]="checked"
    (change)="onChange($event)"
>
</rig-switch>

<ng-template #checkbox>
    <rig-checkbox [(ngModel)]="checked" (change)="onChange()" [disabled]="!!field?.ReadOnly"> </rig-checkbox>
</ng-template>
