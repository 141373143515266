<section class="wrapper flex">
    <section *ngIf="tableConfig?.searchable" class="freetext-and-advanced-filters flex align-center">
        <section class="input-with-button">
            <input
                #searchInput
                [formControl]="searchControl"
                (keydown)="searchFieldKeydown.emit($event)"
                type="text"
                placeholder="Søk"
            />

            <button class="icon-button outline-inside" (click)="showAdvancedFilters()">
                <i class="material-icons">filter_list</i>
            </button>
        </section>

        <ng-container *ngIf="savedSearches?.length">
            <div #toggle role="button" class="flex align-center pointer input-style ml-1" tabindex="0">
                Lagrede søk
                <i class="material-icons">keyboard_arrow_down</i>
            </div>

            <dropdown-menu [trigger]="toggle" minWidth="15rem" #dropdown>
                <ng-template>
                    <section class="dropdown-menu-item" (click)="applySearch()">Ikke valgt</section>

                    <section
                        class="dropdown-menu-item"
                        *ngFor="let search of savedSearches; let idx = index"
                        (click)="applySearch(search)"
                    >
                        <span class="flex-1 mr-4">{{ search.name }}</span>

                        <section class="dropdown-button-section flex align-center">
                            <button
                                (click)="editSearch(search, $event, dropdown)"
                                aria-label="Edit"
                                class="icon-button small"
                            >
                                <uni-icon icon="edit" [svgSize]="22"></uni-icon>
                            </button>

                            <button (click)="deleteSearch(idx, $event)" aria-label="Delete" class="icon-button small">
                                <uni-icon icon="delete" [svgSize]="20"></uni-icon>
                            </button>
                        </section>
                    </section>
                </ng-template>
            </dropdown-menu>
        </ng-container>
    </section>

    <section class="ng-content-wrapper flex-1 flex align-center">
        <ng-content></ng-content>
    </section>

    <section class="quick-filters-and-buttons flex" *ngIf="quickFilters?.length || tableConfig?.buttons?.length">
        <quick-filters
            *ngIf="quickFilters?.length"
            [(quickFilters)]="quickFilters"
            (quickFiltersChange)="emitFilters()"
        >
        </quick-filters>

        <section
            *ngIf="tableConfig?.buttons?.length"
            class="button-container inline-flex align-center"
            [ngClass]="{ 'pull-right': tableConfig?.rightAlignButtons }"
        >
            <button
                *ngFor="let button of tableConfig.buttons"
                [ngClass]="button.class || 'tertiary'"
                (click)="button.action()"
            >
                <i class="material-icons" *ngIf="button.icon">{{ button.icon }}</i>
                {{ button.label }}
            </button>
        </section>

        <section *ngIf="optionBanner">
            <section style="background-color: var(--alert-warn); padding: 7px">
                <span>
                    {{ optionBanner?.text }} <a (click)="optionBanner?.action()">{{ optionBanner?.link }}</a>
                </span>
            </section>
        </section>
    </section>
</section>

<section class="active-filters-display flex align-center gap-1" *ngIf="advancedSearchFilters?.length">
    <strong>Filter:</strong>

    <section
        class="active-filter flex align-center gap-1 border rounded pointer"
        *ngFor="let filter of advancedSearchFilters; let idx = index"
    >
        <span class="ellipsis" (click)="showAdvancedFilters(idx)">{{ filter['_displayText'] }}</span>
        <button (click)="removeFilter(idx)" aria-label="Fjern filter" class="icon-button xs remove-filter">
            <uni-icon icon="close" [svgSize]="18"></uni-icon>
        </button>
    </section>

    <button
        *ngIf="advancedSearchFilters.length > 1"
        (click)="removeAll()"
        class="remove-all tertiary c2a small flex-0-auto"
    >
        Fjern alle
    </button>
</section>
