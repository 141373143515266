import { Component, Input, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Project } from '@uni-entities';
import { UniFieldLayout, FieldType } from '@uni-framework/ui/uniform';
import { IModalOptions, IUniModal } from '@uni-framework/uni-modal/interfaces';
import { AGAZoneSearchModalComponent } from '@app/components/dimensions/agazone-search-modal/agazone-search-modal.component';
import { AgaZoneService } from '@app/services/common/agaZoneService';
import { CustomDimensionService } from '@app/services/common/customDimensionService';
import { DepartmentService } from '@app/services/common/departmentService';
import { ErrorService } from '@app/services/common/errorService';
import { ProjectService } from '@app/services/common/projectService';
import { UserService } from '@app/services/common/userService';
import { UniModalService } from '@uni-framework/uni-modal/modalService';

@Component({
    selector: 'new-dimension-modal',
    templateUrl: './new-dimension-modal.html',
})
export class UniNewDimensionModal implements IUniModal {
    @Input() options: IModalOptions = {};
    @Output() onClose = new EventEmitter();

    formConfig$ = new BehaviorSubject({ autofocus: true });
    formModel$ = new BehaviorSubject(null);
    formFields$ = new BehaviorSubject([]);
    errorMessage = '';
    dimension: number = 1;
    busy = true;

    constructor(
        private projectService: ProjectService,
        private customDimensionService: CustomDimensionService,
        private departmentService: DepartmentService,
        private agaZoneService: AgaZoneService,
        private userService: UserService,
        private errorService: ErrorService,
        private modalService: UniModalService,
    ) {}

    public ngOnInit() {
        this.dimension = this.options.data?.dimension || 1;

        this.getDimensionFields().subscribe((fields) => {
            this.formModel$.next({ Visible: true });
            this.formFields$.next(fields);
            this.busy = false;
        });
    }

    ngOnDestroy() {
        this.formConfig$.complete();
        this.formModel$.complete();
        this.formFields$.complete();
    }

    close(emitValue: any = null) {
        this.onClose.emit(emitValue);
    }

    save() {
        this.busy = true;
        if (this.checkDimension()) {
            this.getSaveOption().subscribe(
                (res) => {
                    this.close(res);
                },
                (err) => {
                    this.errorService.handle(err);
                    this.busy = false;
                    this.errorMessage = 'Noe gikk galt under lagring. Sjekk forminput og prøv igjen';
                },
            );
        } else {
            this.busy = false;
        }
    }

    getSaveOption() {
        const item = this.formModel$.getValue();
        switch (this.dimension) {
            case 1:
                return this.projectService.Post(item);
            case 2:
                return this.departmentService.Post(item);
            default:
                return this.customDimensionService.saveCustomDimension(this.dimension, item);
        }
    }

    getDimensionFields() {
        let fields: UniFieldLayout[];
        switch (this.dimension) {
            case 1:
                fields = this.getProjectFields();
                break;
            case 2:
                fields = this.getDepartmentFields();
                break;
            default:
                fields = this.getDefaultFields();
                break;
        }

        if (this.dimension !== 1) {
            fields.push(<UniFieldLayout>{
                Property: 'Visible',
                Label: 'Synlig',
                FieldType: FieldType.CHECKBOX,
                Tooltip: {
                    Type: 'info',
                    Text: 'Dimensjoner som ikke er synlig vil ikke dukke opp i søkefelt og nedtrekklister i systemet',
                },
            });
        }

        return of(fields);
    }

    private getProjectFields(): UniFieldLayout[] {
        return <UniFieldLayout[]>[
            {
                Label: 'Prosjektnummer',
                Property: 'ProjectNumber',
                Placeholder: 'Prosjektnummer',
            },
            {
                Label: 'Navn',
                Property: 'Name',
            },
            {
                Label: 'Prosjektleder',
                Property: 'ProjectLeadName',
            },
            {
                FieldType: FieldType.DROPDOWN,
                Label: 'Prosjektgodkjenner',
                Property: 'ProjectApproverID',
                Options: {
                    source: this.userService.getUsersByRoles(
                        ['Approval.Accounting', 'Accounting.Admin', 'Accounting.Approval'],
                        false,
                    ),
                    valueProperty: 'ID',
                    displayProperty: 'DisplayName',
                },
            },
            {
                Label: 'Beskrivelse',
                Property: 'Description',
            },
            {
                FieldType: FieldType.DROPDOWN,
                Label: 'Arbeidsgiveravgiftssone',
                Property: 'AGAZoneID',
                Classes: 'half-width',
                Options: {
                    source: this.agaZoneService.getAgaZones(),
                    valueProperty: 'ID',
                    displayProperty: 'ZoneName',
                },
                Tooltip: {
                    Text:
                        'For å kunne få korrekt ambulerende arbeidsgiveravgiftssone fra timer til lønnspostene dine ' +
                        'kan du registrere arbeidsgiveravgiftssonen direkte på prosjektet. ' +
                        'Ved import av timer til lønn vil den ambulerende arbeidsgiveravgiftssonen komme med på lønnsposten dersom timen er registrert med prosjektnummer. ' +
                        'Det er ikke nødvendig å registrere arbeidsgiveravgiftssone dersom prosjektet ditt er i samme sone som virksomheten hvor de ansatte jobber.',
                },
            },
            {
                FieldType: FieldType.BUTTON,
                Label: 'Finn sone',
                Classes: 'half-width',
                Options: {
                    class: 'tertiary c2a',
                    click: () =>
                        this.modalService
                            .open(AGAZoneSearchModalComponent)
                            .onClose.subscribe(
                                (zoneID: number) =>
                                    zoneID &&
                                    this.formModel$.next({ ...this.formModel$.getValue(), AGAZoneID: zoneID }),
                            ),
                },
                LabelWidth: '0',
            },
        ];
    }

    private getDepartmentFields(): UniFieldLayout[] {
        return <UniFieldLayout[]>[
            {
                Label: 'Avdelingsnummer',
                Property: 'DepartmentNumber',
                Placeholder: 'Avdelingsnummer',
            },
            {
                Label: 'Navn',
                Property: 'Name',
            },
            {
                label: 'Avdelingsleder',
                Property: 'DepartmentManagerName',
            },
            {
                FieldType: FieldType.DROPDOWN,
                Label: 'Avdelingsgodkjenner',
                Property: 'DepartmentApproverID',
                Options: {
                    source: this.userService.getUsersByRoles(
                        ['Approval.Accounting', 'Accounting.Admin', 'Accounting.Approval'],
                        false,
                    ),
                    valueProperty: 'ID',
                    displayProperty: 'DisplayName',
                },
            },
            {
                Label: 'Beskrivelse',
                Property: 'Description',
            },
        ];
    }

    private getDefaultFields(): UniFieldLayout[] {
        return <UniFieldLayout[]>[
            {
                Label: 'Nummer',
                Property: 'Number',
            },
            {
                Label: 'Navn',
                Property: 'Name',
            },
            {
                Label: 'Beskrivelse',
                Property: 'Description',
            },
            {
                FieldType: FieldType.LOCAL_DATE_PICKER,
                Label: 'Opprettet',
                Property: 'CreatedAt',
                ReadOnly: true,
            },
        ];
    }

    checkDimension(): boolean {
        const dim = this.formModel$.getValue();
        this.errorMessage = '';

        switch (this.dimension) {
            case 1:
                if (!dim.Name || dim.Name === '') {
                    this.errorMessage = 'Ikke lagret! Prosjektet må ha et navn';
                    return false;
                } else if (dim.ID && (!dim.ProjectNumber || dim.ProjectNumber === '')) {
                    this.errorMessage = 'Ikke lagret! Prosjektnummer kan ikke være tomt';
                    return false;
                }
                break;
            case 2:
                if (!dim.Name || dim.Name === '') {
                    this.errorMessage = 'Ikke lagret! Avdelingen må ha et navn';
                    return false;
                } else if (dim.ID && (!dim.DepartmentNumber || dim.DepartmentNumber === '')) {
                    this.errorMessage = 'Ikke lagret! DepartmentNumber kan ikke være tomt';
                    return false;
                }
                break;
            default:
                if (!dim.Name || dim.Name === '') {
                    this.errorMessage = 'Ikke lagret! Dimensjonen må ha et navn';
                    return false;
                } else if (!dim.Number) {
                    this.errorMessage = 'Ikke lagret! Dimensjonsnummer kan ikke være tomt';
                    return false;
                }
        }

        return true;
    }
}
