import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { UniHttp } from '@uni-framework/core/http';
import { Observable, of } from 'rxjs';
import { SupportArticleMapping } from '@app/models';

@Injectable({ providedIn: 'root' })
export class ContextSupportService {
    cachedMappings: SupportArticleMapping[];

    constructor(private uniHttp: UniHttp) {}

    getSupportArticleMappings(): Observable<SupportArticleMapping[]> {
        if (this.cachedMappings) {
            return of(this.cachedMappings);
        }

        const endpoint = `/api/supportarticlemapping?$filter=StatusCode eq 'Active'`;
        return this.uniHttp
            .asGET()
            .usingElsaDomain()
            .withEndPoint(endpoint)
            .send()
            .pipe(
                map((res) => {
                    this.cachedMappings = res.body;
                    return this.cachedMappings;
                }),
            );
    }
}
