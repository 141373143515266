export enum KeyCodes {
    BACKSPACE = 8,
    TAB = 9,
    ENTER = 13,
    SHIFT = 16,
    CTRL = 17,
    ALT = 18,
    PAUSE_BREAK = 19,
    CAPS_LOCK = 20,
    ESCAPE = 27,
    SPACE = 32,
    PAGE_UP = 33,
    PAGE_DOWN = 34,
    END = 35,
    HOME = 36,
    LEFT_ARROW = 37,
    UP_ARROW = 38,
    RIGHT_ARROW = 39,
    DOWN_ARROW = 40,
    INSERT = 45,
    DELETE = 46,
    NUMBER_0 = 48,
    NUMBER_1 = 49,
    NUMBER_2 = 50,
    NUMBER_3 = 51,
    NUMBER_4 = 52,
    NUMBER_5 = 53,
    NUMBER_6 = 54,
    NUMBER_7 = 55,
    NUMBER_8 = 56,
    NUMBER_9 = 57,
    /* key codes 65 --> 90 is A-Z, but we have removed that because you need to use event
     * "keypress" instead of "keydown" and this code snippet to get the characters
     * (because of non US languages, think æøå)
     * ```
     *     const keyCode = event.which || event.keyCode;
     *     const character = String.fromCharCode(keyCode);
     * ```
     * */
    LEFT_WINDOW_KEY = 91,
    RIGHT_WINDOW_KEY = 92,
    SELECT_KEY = 93,
    NUMPAD_0 = 96,
    NUMPAD_1 = 97,
    NUMPAD_2 = 98,
    NUMPAD_3 = 99,
    NUMPAD_4 = 100,
    NUMPAD_5 = 101,
    NUMPAD_6 = 102,
    NUMPAD_7 = 103,
    NUMPAD_8 = 104,
    NUMPAD_9 = 105,
    MULTIPLY = 106,
    ADD = 107,
    SUBTRACT = 109,
    DECIMAL_POINT = 110,
    DIVIDE = 111,
    F1 = 112,
    F2 = 113,
    F3 = 114,
    F4 = 115,
    F5 = 116,
    F6 = 117,
    F7 = 118,
    F8 = 119,
    F9 = 120,
    F10 = 121,
    F11 = 122,
    F12 = 123,
    NUM_LOCK = 144,
    SCROLL_LOCK = 145,
    SEMI_COLON = 186,
    EQUAL_SIGN = 187,
    COMMA = 188,
    DASH = 189,
    PERIOD = 190,
    FORWARD_SLASH = 191,
    GRAVE_ACCENT = 192,
    OPEN_BRACKET = 219,
    BACK_SLASH = 220,
    CLOSE_BRAKET = 221,
    SINGLE_QUOTE = 222,
}
