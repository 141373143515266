<section class="input-with-button">
    <input
        #inputElement
        *ngIf="control"
        [attr.aria-describedby]="asideGuid"
        [type]="type"
        [formControl]="control"
        [readonly]="readOnly$ | async"
        [placeholder]="field?.Placeholder || ''"
        (focus)="focusHandler()"
        (blur)="blurHandler()"
    />

    <button type="button" (click)="showPassword()">
        <i class="material-icons">remove_red_eye</i>
    </button>
</section>
