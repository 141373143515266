import { EventEmitter } from '@angular/core';
import { IllustrationName } from '@uni-framework/ui/design-system/illustration/illustration';
import { Observable } from 'rxjs';

export enum ConfirmActions {
    ACCEPT,
    REJECT,
    CANCEL,
}

export interface ModalCheckboxConfig {
    mustConfirmToAccept?: boolean;
    label?: string;
    value?: boolean;
}

export interface IModalOptions {
    data?: any;
    class?: string;
    header?: string;
    message?: string;
    footerCls?: string;
    warning?: string;
    info?: string;
    buttonLabels?: {
        accept?: string;
        reject?: string;
        next?: string;
        previous?: string;
        cancel?: string;
    };
    submitButtonClass?: string;
    rejectButtonClass?: string;
    cancelValue?: any;
    modalConfig?: any;
    size?: 'xs' | 'small' | 'medium' | 'large';
    closeOnClickOutside?: boolean;
    closeOnEscape?: boolean;
    disableDragAndDrop?: boolean;
    hideCloseButton?: boolean;
    fieldType?: number;
    fieldLabel?: string;
    isMarkdown?: boolean;
    pages?: IModalOptions[];
    width?: string;
}

export interface ConfirmModalOptions extends IModalOptions {
    illustrationPath?: string;
    illustration?: IllustrationName;
    alignHorizontal?: boolean;
    showHeaderBelowIllustration?: boolean;
    checkbox?: ModalCheckboxConfig;
}

export interface IUniModal {
    onClose: EventEmitter<any>;
    options?: IModalOptions;

    /**
     * Called by modalService when force-closing a dialog (clickOutside, escape, close icon).
     * Allows you to resolve a value for the onClose emit in these cases.
     */
    forceCloseValueResolver?: () => any;

    /**
     * Called by modalService before force-closing a dialog (clickOutside, escape, close icon).
     * Allows you to specify if the closing is allowed to happen
     */
    canDeactivate?: () => boolean | Observable<boolean>;
}
