import { Directive, ElementRef } from '@angular/core';
import { MODAL_VISIBLE } from './uni-modal/modal-visible';

@Directive({ selector: '[uniAutoFocus]' })
export class AutoFocusDirective {
    constructor(private elementRef: ElementRef) {}

    ngAfterViewInit() {
        // Timeout prevents changeAfterCheck errors on inputs using FormControl
        setTimeout(() => {
            const element: HTMLElement = this.elementRef.nativeElement;
            if (MODAL_VISIBLE) {
                const overlayContainer = document.querySelector('.cdk-overlay-container');
                if (overlayContainer && !overlayContainer.contains(element)) {
                    return;
                }
            }

            this.focus(element);
        });
    }

    private focus(element: HTMLElement) {
        try {
            if (['FORM', 'UNI-FIELD', 'UNI-SELECT'].includes(element.tagName)) {
                const input = element.querySelector('input');
                input?.focus();
            } else {
                element.focus();
            }
        } catch (e) {}
    }
}
