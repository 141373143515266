export { ChangeMap } from './changeMap';
import { rigDate } from '@app/components/common/utils/rig-date';
import { environment } from 'src/environments/environment';
import { THEMES, theme } from 'src/themes/theme';
export { MD5 } from './md5';

export enum ControlTypes {
    AutocompleteInput = 0,
    ButtonInput = 1,
    DateInput = 2,
    SelectInput = 3,
    MaskedInput = 4,
    CheckboxInput = 5,
    NumericInput = 6,
    RadioInput = 7,
    CheckboxgroupInput = 8,
    RadiogroupInput = 9,
    TextInput = 10,
    EmailInput = 11,
    PasswordInput = 12,
    HyperlinkInput = 13,
    MultivalueInput = 14,
    UrlInput = 15,
    TextareaInput = 16,
    LocalDate = 17,

    BANKACCOUNT = 23,
}

export enum FieldSize {
    Normal = 0,
    Double = 1,
    Quarter = 2,
    Full = 3,
}

export function escapeHtml(value) {
    if (!value || typeof value !== 'string') return value;

    const SURROGATE_PAIR_REGEXP = /[\uD800-\uDBFF][\uDC00-\uDFFF]/g;

    return value
        .replace(SURROGATE_PAIR_REGEXP, function (value) {
            const hi = value.charCodeAt(0);
            const low = value.charCodeAt(1);
            return '&#' + ((hi - 0xd800) * 0x400 + (low - 0xdc00) + 0x10000) + ';';
        })
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;');
}

export function filterInput(v) {
    return v.replace(/[`~!@#$%^&*()_|+\=?;:'",.<>\{\}\[\]\\\/]/gi, '');
}

export function isObject(item) {
    return typeof item === 'object' && !Array.isArray(item) && item !== null;
}

export function safeInt(value: any) {
    if (value === undefined) {
        return 0;
    }
    const tmp = parseInt(value, 10);
    if (isNaN(tmp)) {
        return 0;
    }
    return tmp;
}

export function roundTo(value: number, decimals = 2): number {
    let dVal = value;
    if (typeof dVal !== 'number') {
        dVal = safeDec(value);
    }
    return Number(Math.round(Number.parseFloat(dVal + 'e' + decimals)) + 'e-' + decimals);
}

export function safeDec(value: any) {
    if (value === undefined) {
        return 0;
    }
    if (typeof value === 'number') {
        return value;
    }
    if (typeof value === 'string') {
        const ixDot = value.indexOf('.');
        const ixComma = value.indexOf(',');
        const ixSpace = value.indexOf(' ');
        if (ixSpace >= 0) {
            value = value.replace(new RegExp('[ ]', 'g'), '');
        }
        if (ixDot && ixComma && ixComma > ixDot) {
            value = value.replace(new RegExp('[.]', 'g'), '');
            value = value.replace(new RegExp('[,]', 'g'), '.');
        }
    }
    const tmp = parseFloat(value);
    if (isNaN(tmp)) {
        return 0;
    }
    return tmp;
}

export function createFormField(
    name: string,
    label: string,
    fieldType: any = ControlTypes.TextInput,
    size = FieldSize.Normal,
    hideLabel = false,
    fieldset = 0,
    legend?: string,
    options?: any,
): any {
    return {
        Property: name,
        Label: label,
        FieldType: fieldType,
        FieldSet: fieldset,
        Legend: legend,
        Combo: 0,
        Options: options,
        Classes: combineClasses(size, hideLabel),
    };
}

function combineClasses(size = FieldSize.Normal, hideLabel = false) {
    const classes = [];
    switch (size) {
        case FieldSize.Double:
            classes.push('half-width');
            break;
        case FieldSize.Quarter:
            classes.push('quarter-width');
            break;
        case FieldSize.Full:
            classes.push('max-width');
            break;
    }
    if (hideLabel) {
        classes.push('visuallyHideLabel');
    }
    return classes.length > 0 ? classes.join(' ') : undefined;
}

// <summary>
// example: setDeepValue(row, 'dimension.projectid', 123)
// </summary>
export function setDeepValue(item: any, name: string, value: any) {
    const parts = name.split('.');
    if (parts.length === 1) {
        item[name] = value.ID ? value.ID : value;
        return;
    }
    for (let i = 0; i < parts.length; i++) {
        const subName = parts[i];
        const sub = parts.join('.').substr(subName.length + 1);
        const subItem = item[subName] || {};
        if (!item[subName]) {
            item[subName] = subItem;
        }
        setDeepValue(subItem, sub, value);
    }
}

// <summary>
// example: getDeepValue(row, 'dimension.projectid')
// </summary>
export function getDeepValue(item: any, name: string) {
    const parts = name.split('.');
    if (parts.length === 1) {
        return item[name];
    }
    for (let i = 0; i < parts.length; i++) {
        const subName = parts[i];
        const sub = parts.join('.').substr(subName.length + 1);
        const subItem = item[subName] || {};
        if (!item[subName]) {
            item[subName] = subItem;
        }
        return getDeepValue(subItem, sub);
    }
}

export function parseDate(value: any, allowMacros = true): Date {
    let d = 0;
    let m = 0;
    const y = 0;

    if (value === null) {
        return rigDate().toDate();
    }

    if (typeof value === 'object') {
        if (value.getMonth) {
            return value;
        }
        if (value.toDate) {
            return value.toDate();
        }
    }

    if (allowMacros) {
        if (value === '*') {
            return rigDate().toDate();
        }
    }

    if (value.indexOf('/') > 0) {
        const parts = value.split('/');
        if (parts.length === 3) {
            return rigDate(value, 'MM/DD/YY').toDate();
        }
    }

    if (value.indexOf('.') > 0) {
        switch (value.split('.').length) {
            case 3:
                return rigDate(value, 'DD.MM.YYYY').toDate();
            case 2:
                return rigDate(value, 'DD.MM').toDate();
        }
    }

    d = parseInt(value, 2);
    if (d > 0) {
        switch (value.length) {
            case 1:
            case 2:
                break;
            case 3: // 133 = 13.3, 205 = 20.5, 305 = 30.5
                d = safeInt(value.substr(0, 1));
                if (d > 3) {
                    m = safeInt(value.substr(1));
                } else {
                    d = safeInt(value.substr(0, 2));
                    m = safeInt(value.substr(2));
                }
                break;
            case 4:
                d = safeInt(value.substr(0, 2));
                m = safeInt(value.substr(2, 2));
                break;
        }

        return dateSerial(d, m, y);
    }
}

export function toIso(date: Date, includeTime = false, nullTime = false): string {
    const value: string = rigDate(date).format();
    if (includeTime) {
        if (nullTime) {
            return value.substr(0, 10) + 'T00:00:00';
        }
        return value;
    }
    return value.substr(0, 10);
}

export function parseTime(value: string, allowMacros = true, date?: Date): Date {
    let h = 0;
    let m = 0;

    if (allowMacros) {
        if (value === '*') {
            return rigDate().toDate();
        }
    }
    if (value.indexOf(':') > 0) {
        const parts = value.split(':');
        h = safeInt(parts[0]);
        m = safeInt(parts[1]);
    } else {
        switch (value.length) {
            case 1:
                h = safeInt(value);
                break;
            case 2:
                h = safeInt(value);
                if (h > 24) {
                    h = safeInt(value.substr(0, 1));
                    m = safeInt(value.substr(1)) * 10;
                }
                break;
            case 3:
                h = safeInt(value.substr(0, 1));
                if (h > 2) {
                    m = safeInt(value.substr(1));
                } else {
                    h = safeInt(value.substr(0, 2));
                    m = safeInt(value.substr(2));
                }
                break;
            case 4:
                h = safeInt(value.substr(0, 2));
                m = safeInt(value.substr(2, 2));
                break;
        }
    }

    return timeSerial(h, m, date);
}

export function addTime(value: Date, amount: number, addType: any = 'hours') {
    return rigDate(value).add(amount, addType).toDate();
}

function timeSerial(hour: number, minute: number, date?: Date): Date {
    return rigDate(date).hour(hour).minute(minute).second(0).toDate();
}

function dateSerial(day: number, month = 0, year = 0): Date {
    const d = new Date();
    const x = rigDate()
        .date(day)
        .month(month ? month - 1 : d.getMonth())
        .year(year || d.getFullYear());
    const y = x.toDate();
    return y;
}

export function exportToFile(text: string, fileName: string, useAsciiEncoding = false, bytes?: any) {
    const link: any = document.createElement('a');
    let blob: Blob;

    if (useAsciiEncoding || bytes) {
        bytes ??= toByteArray(btoa(text)); // Buffer.from(text, "ascii");
        blob = new Blob([bytes]);
    } else {
        blob = new Blob(['\uFEFF' + text], { type: 'text/csv;charset=utf-8;' });
    }

    if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', fileName);
        link.style = 'visibility:hidden';
    }

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export function arrayToCsv(
    data: Array<any>,
    columnAsFormula: Array<string> = [],
    columnDelimiter = ';',
    lineDelimiter = '\r\n',
    includeHeader = true,
) {
    let result, ctr, keys;

    if (data === null || !data.length) {
        return null;
    }

    keys = Object.keys(data[0]);

    result = '';
    if (includeHeader) {
        keys.forEach((key: string) => {
            result += (result.length > 0 ? columnDelimiter : '') + '"' + key + '"';
        });
        result += lineDelimiter;
    }

    data.forEach(function (item) {
        let prop: string;
        ctr = 0;
        keys.forEach(function (key) {
            if (ctr > 0) {
                result += columnDelimiter;
            }
            prop = '' + item[key];
            if (prop) {
                if (prop.indexOf(columnDelimiter) > 0) {
                    prop = prop.replace(new RegExp(columnDelimiter, 'g'), '.');
                }
                if (columnAsFormula.indexOf(key) !== -1) {
                    result += '=';
                }
                result += '"' + prop + '"';
            }
            ctr++;
        });
        result += lineDelimiter;
    });

    return result;
}

export function trimLength(value: string, maxLength: number, addEllipsis: boolean = true): string {
    if (value && value.length > maxLength) {
        return value.substr(0, maxLength).trim() + (addEllipsis ? '..' : '');
    }
    return value;
}

export function capitalizeFirstLetter(value: string) {
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
}

export function createRow(cells: number, emptyValue: any, ...values: any[]) {
    const row = [];
    values.forEach((item) => {
        row.push(item);
    });
    for (let i = row.length; i < cells; i++) {
        if (typeof emptyValue === 'function') {
            row.push(emptyValue());
        } else {
            row.push(emptyValue);
        }
    }
    return row;
}

export function getNewGuid(): string {
    // eslint-disable-next-line
    return ('' + 1e7 + -1e3 + -4e3 + -8e3 + -1e11).replace(/1|0/g, function () {
        return (0 | (Math.random() * 16)).toString(16);
    });
}

let idIncrementer = 1;
export function generateId(prefix: string): string {
    if (idIncrementer > 99999) {
        idIncrementer = 1;
    }

    return `${prefix}-${idIncrementer++}`;
}

export function getHash(input: string): string {
    /* eslint-disable no-bitwise */
    let i,
        l,
        hval = 0x811c9dc5;

    for (i = 0, l = input.length; i < l; i++) {
        hval ^= input.charCodeAt(i);
        hval += (hval << 1) + (hval << 4) + (hval << 7) + (hval << 8) + (hval << 24);
    }

    return (hval >>> 0).toString();
}

export function getQueryStringParams(url) {
    const [_, query] = url.split('?');
    if (!query) return {};

    return query.split('&').reduce((params, param) => {
        const [key, value] = param.split('=');
        params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
        return params;
    }, {});
}

export function toByteArray(base64Data: string) {
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    return new Uint8Array(byteNumbers);
}

export function takeTop(arr: Array<any>, max: number) {
    if (!arr) return arr;
    if (arr.length <= max) return arr;
    const copy = [];
    for (let i = 0; i < max; i++) {
        copy.push(arr[i]);
    }
    return copy;
}

export function firstOrDefault(arr: Array<any> | undefined, defaultResponse = undefined) {
    if (!arr) return defaultResponse;
    if (!Array.isArray(arr)) return defaultResponse;
    if (arr.length > 0) return arr[0];
    return defaultResponse;
}

export function useOnboardingFlow2(flowQueryParam?: number) {
    return (
        theme.theme === THEMES.EIKA ||
        flowQueryParam === 2 ||
        (flowQueryParam !== 1 && environment.onboardingFlow === 2)
    );
}
