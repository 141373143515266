import { Injectable } from '@angular/core';
import { getNewGuid } from '@app/components/common/utils/utils';
import { UniTableColumn, UniTableColumnType, UniTableConfig } from '@uni-framework/ui/unitable';
import { GridApi } from 'ag-grid-community';
import { get, set } from 'lodash-es';
import { BehaviorSubject } from 'rxjs';
import { TableUtils } from './table-utils';
import { rigDate } from '@app/components/common/utils/rig-date';

type ExpandedGroups = { [name: string]: boolean };

interface RowGroup {
    rows: any[];
    groups?: RowGroup[];
    headerRow?: GroupHeaderData;
}

export interface GroupHeaderData {
    _guid: string;
    _groupLabel: string;
    _groupLevel: number;
    _isGroupHeader: boolean;
}

@Injectable()
export class TableGroupingService {
    private gridApi: GridApi;
    private config: UniTableConfig;
    private columns: UniTableColumn[];
    private originalData: any[];
    private groups: RowGroup[];

    sortModel: { col: UniTableColumn; direction: 'asc' | 'desc' };

    groupByColumns: UniTableColumn[] = [];

    expandedGroups$ = new BehaviorSubject<ExpandedGroups>({});

    constructor(private tableUtils: TableUtils) {}

    init(gridApi: GridApi, config: UniTableConfig, columns: UniTableColumn[], data: any[]) {
        this.gridApi = gridApi;
        this.config = config;
        this.columns = columns?.filter((c) => c.visible) || [];

        this.originalData = data;
        this.expandedGroups$.next({});

        this.gridApi.setGridOption('rowData', []);

        setTimeout(() => {
            const staticGroupByCol = this.config.groupingConfig?.staticGroupByColumn;
            if (staticGroupByCol) {
                this.groupByColumns = [staticGroupByCol];
                this.groupData();
            } else if (this.groupByColumns?.length) {
                this.groupData();
            } else {
                this.gridApi.setGridOption('rowData', data);
            }
        });
    }

    setSort(colId: string, direction: 'asc' | 'desc') {
        const col = colId && this.columns.find((col) => col.alias === colId || col.field === colId);

        if (col && direction) {
            this.sortModel = { col, direction };
        } else {
            this.sortModel = undefined;
        }

        this.groupData();
    }

    private sort(data: any[]) {
        const col = this.sortModel?.col;

        if (!col) return data;

        const isNumeric =
            col.type === UniTableColumnType.Number ||
            col.type === UniTableColumnType.Money ||
            col.type === UniTableColumnType.Percent;

        const isDate = col.type === UniTableColumnType.LocalDate || col.type === UniTableColumnType.DateTime;

        return [...this.originalData].sort((a, b) => {
            let res: number;
            if (isNumeric || isDate) {
                const valueA = get(a, col.alias || col.field);
                const valueB = get(b, col.alias || col.field);

                if (isNumeric) {
                    res = valueA - valueB;
                } else {
                    res = rigDate(valueA).isBefore(rigDate(valueB)) ? 1 : -1;
                }
            } else {
                const valueA = this.tableUtils.getColumnValue(a, col);
                const valueB = this.tableUtils.getColumnValue(b, col);

                if (Number(valueA) && Number(valueB)) {
                    res = Number(valueA) - Number(valueB);
                } else {
                    res = (valueA?.toString() || '').localeCompare(valueB?.toString() || '');
                }
            }

            return this.sortModel?.direction === 'asc' ? res : res * -1;
        });
    }

    toggleGroupByColumn(column: UniTableColumn) {
        const index = this.groupByColumns.findIndex((col) => col.field === column.field);
        if (index >= 0) {
            this.groupByColumns.splice(index, 1);
        } else {
            this.groupByColumns.push(column);
        }

        this.groupData();
    }

    private groupRecursively(groupByColumns: UniTableColumn[], data: any[], level = 0) {
        const column = groupByColumns[0];
        const groupMap = new Map<string, RowGroup>();

        for (let row of data) {
            let groupLabel = this.tableUtils.getColumnValue(row, column);
            if (!groupLabel) {
                groupLabel = 'Ingen ' + column.header.toLowerCase();
            }

            let group = groupMap.get(groupLabel);

            if (!group) {
                group = {
                    headerRow: {
                        _groupLabel: groupLabel,
                        _groupLevel: level,
                        _isGroupHeader: true,
                        _guid: getNewGuid(),
                    },
                    rows: [],
                };

                groupMap.set(groupLabel, group);
            }

            group.rows.push(row);
        }

        const groups = Array.from(groupMap.values()).map((group) => {
            group.headerRow._groupLabel += ` (${group.rows.length})`;
            this.setSumsOnHeaderRow(group);

            if (groupByColumns.length > 1) {
                group.groups = this.groupRecursively(groupByColumns.slice(1), group.rows, level + 1);
                group.rows = [];
            }

            return group;
        });

        return groups;
    }

    private groupData() {
        let sortedData = this.sort(this.originalData || []);

        if (!this.groupByColumns.length || !sortedData?.length) {
            this.groups = undefined;
            this.gridApi.setGridOption('rowData', sortedData || []);
            return;
        }

        this.groups = this.groupRecursively(this.groupByColumns, sortedData);
        this.setExpandedStateOnAllGroups(this.config.groupingConfig?.defaultExpandedState ?? true);

        this.renderGroups();
    }

    toggleExpanded(guid: string) {
        const expandedGroups = this.expandedGroups$.value;
        expandedGroups[guid] = !expandedGroups[guid];
        this.expandedGroups$.next(expandedGroups);
        this.renderGroups();
    }

    toggleAll() {
        if (this.groups?.length) {
            const currentState = Object.keys(this.expandedGroups$.value).length > 0;
            this.setExpandedStateOnAllGroups(!currentState);
            this.renderGroups();
        }
    }

    private setExpandedStateOnAllGroups(expanded: boolean) {
        const expandedGroups: ExpandedGroups = {};

        if (expanded) {
            const markGroupsAsExpanded = (groups: RowGroup[]) => {
                for (const group of groups) {
                    expandedGroups[group.headerRow._guid] = true;
                    if (group.groups) {
                        markGroupsAsExpanded(group.groups);
                    }
                }
            };

            markGroupsAsExpanded(this.groups);
        }

        this.expandedGroups$.next(expandedGroups);
    }

    private renderGroups() {
        if (!this.groups) return;

        const rows = [];
        const expandedGroups = this.expandedGroups$.value || {};

        const setRows = (groups: RowGroup[]) => {
            for (const group of groups) {
                rows.push(group.headerRow);

                if (expandedGroups[group.headerRow._guid]) {
                    if (group.groups) {
                        setRows(group.groups);
                    } else {
                        rows.push(...group.rows);
                    }
                }
            }
        };

        setRows(this.groups);
        this.gridApi.setGridOption('rowData', rows);
    }

    private setSumsOnHeaderRow(group: RowGroup) {
        const sumColumns = this.columns?.filter((c) => c.isSumColumn);

        if (!sumColumns?.length) return;

        const headerRow = group.headerRow;

        for (const col of sumColumns) {
            const field = col.alias || col.field;
            for (const row of group.rows) {
                // REVISIT: do we need to call a column's sum function in some cases?
                const newSum = get(headerRow, field, 0) + (get(row, field) || 0);
                set(headerRow, field, newSum);
            }
        }
    }
}
