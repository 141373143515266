import { LocalDate } from '@uni-entities';
import dayjs from 'dayjs';
import 'dayjs/locale/nb';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import weekday from 'dayjs/plugin/weekday';
import relativeTime from 'dayjs/plugin/relativeTime';
import weekOfYear from 'dayjs/plugin/weekOfYear';

export type RigDateInput = string | number | Date | dayjs.Dayjs | null | undefined | RigDate | RigDateInputObject;
export type InputUnitType = dayjs.UnitType;
export type InputManipulateType = dayjs.ManipulateType;

// This supports defining input format eksample: (mydate, 'YYYY.MM.DD')
dayjs.extend(customParseFormat);
// This supports week function
dayjs.extend(weekOfYear);
// This supports weekday function
dayjs.extend(weekday);
// This supports the fromNow function
dayjs.extend(relativeTime);
// This supports localized formats such as L and LT
dayjs.extend(localizedFormat);
// Set locale to norwegian. This is needed to support i18n later!
dayjs.locale('nb');

export interface RigDateInputObject {
    year?: number;
    month?: number;
    day?: number;
}

export const months = [
    'Januar',
    'Februar',
    'Mars',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Desember',
];
export const monthsShort = ['Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Des'];
export const days = ['Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag', 'Søndag'];
export const daysShort = ['Man', 'Tir', 'Ons', 'Tor', 'Fre', 'Lør', 'Søn'];

export function rigDate(input?: RigDateInput, format?: string, strict?: boolean) {
    return new RigDate(input, format, strict);
}

class RigDate {
    private value: dayjs.Dayjs;

    constructor(input?: RigDateInput, format?: string, strict?: boolean) {
        this.value = dayjs(this.parseDate(input, format, strict));
    }

    getValue() {
        return this.value;
    }

    isSame(date: number | string | Date | dayjs.Dayjs | RigDate, unit?: InputUnitType): boolean {
        return this.value.isSame(this.parseDate(date), unit);
    }

    isBefore(date: number | string | Date | dayjs.Dayjs | RigDate, unit?: InputUnitType): boolean {
        return this.value.isBefore(this.parseDate(date), unit);
    }

    isAfter(date: number | string | Date | dayjs.Dayjs | RigDate, unit?: InputUnitType): boolean {
        return this.value.isAfter(this.parseDate(date), unit);
    }

    isSameOrBefore(date: number | string | Date | dayjs.Dayjs | RigDate, unit?: InputUnitType): boolean {
        return this.isBefore(date, unit) || this.isSame(date, unit);
    }

    isSameOrAfter(date: number | string | Date | dayjs.Dayjs | RigDate, unit?: InputUnitType): boolean {
        return this.isAfter(date, unit) || this.isSame(date, unit);
    }

    isBetween(
        dateFrom: number | string | Date | dayjs.Dayjs | RigDate,
        dateTo: number | string | Date | dayjs.Dayjs | RigDate,
        unit?,
        inclusivity?: '[]' | '[)' | '(]' | '()',
    ): boolean {
        let func1 = this.isSameOrAfter;
        let func2 = this.isSameOrBefore;

        if (inclusivity && inclusivity[0] === '(') {
            func1 = this.isAfter;
        }

        if (inclusivity && inclusivity[1] === ')') {
            func2 = this.isBefore;
        }

        return func1.call(this, this.parseDate(dateFrom), unit) && func2.call(this, this.parseDate(dateTo), unit);
    }

    startOf(unit: dayjs.OpUnitType | 'quarter'): RigDate {
        if (unit === 'quarter') {
            this.value = this.value.month(this.value.month() - (this.value.month() % 3)).startOf('m');
        } else {
            this.value = this.value.startOf(unit);
        }
        return this;
    }

    endOf(unit: dayjs.OpUnitType | 'quarter'): RigDate {
        if (unit === 'quarter') {
            this.value = this.value.month(this.value.month() - (this.value.month() % 3)).endOf('m');
        } else {
            this.value = this.value.endOf(unit);
        }

        return this;
    }

    diff(date: Date | RigDate, unit?: InputUnitType, float?: boolean): number {
        return this.value.diff(this.parseDate(date), unit, float);
    }

    format(format?: string): string {
        return this.value.format(format);
    }

    fromNow() {
        return this.value.fromNow();
    }

    add(value: number, unit?: InputManipulateType): RigDate {
        this.value = this.value.add(value, unit);
        return this;
    }

    subtract(value: number, unit?: InputManipulateType): RigDate {
        this.value = this.value.subtract(value, unit);
        return this;
    }

    isValid(): boolean {
        return this.value.isValid();
    }

    year(value: number): RigDate;
    year(): number;
    year(value?: number): RigDate | number {
        if ((value || value === 0) && typeof value === 'number') {
            this.value = this.value.year(value);
            return this;
        }

        return this.value.year();
    }

    month(value: number): RigDate;
    month(): number;
    month(value?: number): RigDate | number {
        if ((value || value === 0) && typeof value === 'number') {
            this.value = this.value.month(value);
            return this;
        }

        return this.value.month();
    }

    week(value: number): RigDate;
    week(): number;
    week(value?: number): RigDate | number {
        if ((value || value === 0) && typeof value === 'number') {
            this.value = this.value.week(value);
            return this;
        }

        return this.value.week();
    }

    day(value: number): RigDate;
    day(): number;
    day(value?): RigDate | number {
        if ((value || value === 0) && typeof value === 'number') {
            this.value = this.value.day(value);
            return this;
        }

        return this.value.day();
    }

    hour(value: number): RigDate;
    hour(): number;
    hour(value?): RigDate | number {
        if ((value || value === 0) && typeof value === 'number') {
            this.value = this.value.hour(value);
            return this;
        }

        return this.value.hour();
    }

    minute(value: number): RigDate;
    minute(): number;
    minute(value?): RigDate | number {
        if ((value || value === 0) && typeof value === 'number') {
            this.value = this.value.minute(value);
            return this;
        }

        return this.value.minute();
    }

    second(value: number): RigDate;
    second(): number;
    second(value?): RigDate | number {
        if ((value || value === 0) && typeof value === 'number') {
            this.value = this.value.second(value);
            return this;
        }

        return this.value.second();
    }

    utcOffset() {
        return this.value.utcOffset();
    }

    dayOfYear(): number {
        return (
            (Date.UTC(this.value.year(), this.value.month(), this.value.date()) - Date.UTC(this.value.year(), 0, 0)) /
            24 /
            60 /
            60 /
            1000
        );
    }

    daysInMonth(): number {
        return this.value.daysInMonth();
    }

    weekday(value: number): RigDate;
    weekday(): number;
    weekday(value?): RigDate | number {
        if ((value || value === 0) && typeof value === 'number') {
            this.value = this.value.weekday(value);
            return this;
        }

        return this.value.weekday();
    }

    isoWeekday(name?: string): number {
        let index = this.value.day();

        if (name) {
            const arrayToCheck = name.length === 3 ? daysShort : days;
            const byLongNameIndex = arrayToCheck.findIndex((d) => d.toLowerCase() === name.toLowerCase());
            index = byLongNameIndex !== -1 ? byLongNameIndex + 1 : index;
        }

        return index === 0 ? 7 : index;
    }

    date(value: number): RigDate;
    date(): number;
    date(value?): number | RigDate {
        if (value && typeof value === 'number') {
            this.value = this.value.date(value);
            return this;
        }

        return this.value.date();
    }

    toDate(): Date {
        return this.value.toDate();
    }

    toISOString(): string {
        return this.value.toISOString();
    }

    toJSON() {
        return this.value.toJSON();
    }

    toString() {
        return this.value.toString();
    }

    toLocaleString(): string {
        return this.value.toLocaleString();
    }

    valueOf() {
        return this.value.valueOf();
    }

    clone(): RigDate {
        return rigDate(this.value);
    }

    now(): number {
        return this.value.unix();
    }

    from(compared, withoutSuffif: boolean) {
        return this.value.from(compared, withoutSuffif);
    }

    unix(): number {
        return this.value.unix();
    }

    parseDate(input?: RigDateInput, format?: string, strict?: boolean): Date {
        if (input === null) {
            return dayjs(null).toDate();
        } else if (!input) {
            return new Date();
        } else if (input instanceof LocalDate) {
            return input.toDate();
        } else if (input instanceof Date) {
            return new Date(input);
        } else if (dayjs.isDayjs(input)) {
            return input.toDate();
        } else if (input instanceof RigDate) {
            return input.toDate();
        } else if (typeof input === 'object' && (input.day || input.month || input.year)) {
            return getDateFromObject(input);
        } else if (typeof input === 'string') {
            return format ? dayjs(input, format, strict).toDate() : new Date(input);
        } else {
            const dayjsInstance = dayjs(input as any);
            if (dayjsInstance.isValid()) {
                return dayjsInstance.toDate();
            } else {
                throw 'Invalid input to RigDate constructor';
            }
        }
    }
}

// LIST OF POSIBLE NEEDS FOR PLUGINS

// duration

function getDateFromObject(obj: any) {
    const date = new Date();
    obj.day && date.setDate(obj.day);
    (obj.month || obj.month === 0) && date.setMonth(obj.month);
    obj.year && date.setFullYear(obj.year);

    return date;
}
