<span class="status" [ngClass]="classString">
    <!-- <i class="material-icons-outlined check" *ngIf="isActive && !singleStatus">check_circle_outline</i> -->
    <i class="material-icons info" *ngIf="singleStatus">info</i>

    <span class="status-text">{{ status.title }}</span>

    <span class="sub-status-counter" *ngIf="hasSubStatuses">
        {{ status.substatusList.length }}
    </span>
</span>

<section class="status-details-container" [ngStyle]="{ 'padding-top': singleStatus ? '3rem' : '2rem' }">
    <section class="status-details" *ngIf="hasSubStatuses || showStatusHistory">
        <section class="sub-statuses" *ngIf="status.substatusList?.length">
            <span class="header">Understatuser</span>

            <ul>
                <li
                    *ngFor="let substatus of status.substatusList"
                    (click)="subStatusClick.next([substatus, status])"
                    [ngClass]="{
                        selectable: substatus.selectable,
                        active: substatus.state === STATES.Active && status.substatusList.length > 1,
                    }"
                >
                    <span class="title">{{ substatus.title }}</span>
                    <span class="timestamp">{{ formatTime(substatus.timestamp, substatus.formatDateTime) }}</span>
                    <span class="subtitle" *ngIf="substatus.subtitle">{{ substatus.subtitle }}</span>
                </li>
            </ul>
        </section>

        <section class="history" *ngIf="showStatusHistory">
            <span class="header">Historikk</span>

            <a *ngIf="!statusHistoryLoaded" (click)="loadStatusHistory()"> Hent statushistorikk </a>

            <span *ngIf="statusHistoryLoaded && !statusHistory?.length"> Fant ingen statushistorikk </span>

            <ul *ngIf="statusHistory?.length">
                <li *ngFor="let logEntry of statusHistory">
                    <span>{{ logEntry.statusText || 'Ingen status' }}</span>
                    <span>{{ logEntry.user }}</span>
                    <span>{{ logEntry.date | date: 'dd.MM.yyyy HH:mm' }}</span>
                </li>
            </ul>
        </section>
    </section>
</section>
