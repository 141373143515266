import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UniTooltip } from './tooltip';
import { TooltipDirective, TooltipComponent } from './tooltip.new';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UniIconModule } from '../icon/uni-icon';

@NgModule({
    imports: [CommonModule, MatTooltipModule, UniIconModule],
    declarations: [UniTooltip, TooltipComponent, TooltipDirective],
    exports: [UniTooltip, TooltipDirective],
})
export class UniTooltipModule {}
