import {
    Component,
    Input,
    Output,
    ElementRef,
    EventEmitter,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    ViewChild,
    SimpleChanges,
    OnChanges,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { UniFieldLayout } from '../../interfaces';
import { fromEvent, isObservable, Observable, Subscription } from 'rxjs';
import { UniSelect } from '../../../uni-select/select';
import { BaseControl } from '../baseControl';
import { get, set } from 'lodash-es';

@Component({
    selector: 'uni-select-input',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './select-wrapper.html',
})
export class UniSelectInput extends BaseControl implements OnChanges {
    @Input() public field: UniFieldLayout;
    @Input() public model: any;
    @Input() public control: UntypedFormControl;
    @Input() public asideGuid: string;

    @Output() public readyEvent: EventEmitter<UniSelectInput> = new EventEmitter<UniSelectInput>(true);
    @Output() public changeEvent: EventEmitter<SimpleChanges> = new EventEmitter<SimpleChanges>();
    @Output() public inputEvent: EventEmitter<SimpleChanges> = new EventEmitter<SimpleChanges>();
    @Output() public focusEvent: EventEmitter<UniSelectInput> = new EventEmitter<UniSelectInput>();

    @ViewChild('uniselect', { static: true }) public uniSelect: UniSelect;

    items: any[];
    source: () => Observable<any>;
    selectedItem: any;

    focusSubscription: Subscription;

    constructor(
        public elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {
        super();
    }

    public focus() {
        this.uniSelect.focus();
        this.uniSelect.select();
        this.focusEvent.emit(this);
        return this;
    }

    public ngOnChanges(changes) {
        this.readOnly$.next(this.field.ReadOnly);
        if (this.asideGuid) {
            if (!this.field.Options) {
                this.field.Options = {};
            }
            this.field.Options.asideGuid = this.asideGuid;
        }
        if (this.model && this.field) {
            this.selectedItem = get(this.model, this.field.Property);
        }

        if (changes['field']) {
            const source = this.field?.Options?.source;
            if (Array.isArray(source)) {
                this.items = this.addEmptyValue(this.field.Options.source);
            } else if (isObservable(source) || typeof source === 'function') {
                const sourceFn = isObservable(source) ? () => source : source;
                // Lazy loading the items can only be done safely if the field also has an initial item resolver.
                // The select needs to have the selected item available in order to generate a display value,
                // and that's impossible to get if the items don't load until we open the dropdown.
                if (this.field.Options.initialItemFn) {
                    this.source = sourceFn;
                } else {
                    sourceFn().subscribe((items) => {
                        this.items = items;
                        this.cd.markForCheck();
                    });
                }
            } else {
                this.items = [];
            }

            this.cd.markForCheck();
        }
    }

    ngOnDestroy() {
        this.focusSubscription?.unsubscribe();
    }

    public createFocusListener(component: UniSelect) {
        const self = this;
        if (component.valueInput) {
            this.focusSubscription = fromEvent(component.valueInput.nativeElement, 'focus').subscribe(() => {
                self.focusEvent.emit(self);
            });
        }
    }

    public onChange(item) {
        let value;
        if (this.field.Options.valueProperty) {
            value = get(item, this.field.Options.valueProperty);
        }
        const valueIsDefined = value !== null && value !== undefined;
        const previousValue = get(this.model, this.field.Property);
        const currentValue = valueIsDefined ? value : item;

        if (previousValue === currentValue) {
            return;
        }
        this.selectedItem = currentValue;
        set(this.model, this.field.Property, currentValue);
        this.emitChange(previousValue, currentValue);
        this.emitInstantChange(previousValue, currentValue, true);
    }

    public addEmptyValue(source: any[]): any[] {
        if (this.field.Options && !this.field.Options.addEmptyValue) {
            return [].concat(source);
        }
        return [].concat(null, source);
    }
}
