import { Component, ChangeDetectionStrategy } from '@angular/core';
import { trigger, transition, animate, style, state } from '@angular/animations';
import { CMSService } from '@app/services/cms/cms-service';

@Component({
    selector: 'cms-slidein',
    templateUrl: './slidein.html',
    styleUrls: ['./slidein.sass'],
    animations: [
        trigger('slideInOut', [
            state('in', style({ opacity: 1, transform: 'translateX(0)' })),
            transition('void => *', [style({ opacity: 0, transform: 'translateX(100%)' }), animate(200)]),
            transition('* => void', [animate(200, style({ opacity: 0, transform: 'translateY(100%)' }))]),
        ]),
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CMSSlideIn {
    path: string;

    constructor(public cmsService: CMSService) {}
}
