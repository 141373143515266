import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
    selector: 'link-cell',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        @if (linkActive) {
            <span role="link" class="table-link" (click)="onLinkClick($event)">{{ text }}</span>
        } @else {
            {{ text }}
        }
    `,
})
export class LinkCellRenderer implements ICellRendererAngularComp {
    linkActive: boolean;
    text: string;

    onLinkClick: (event: MouseEvent) => void;

    agInit(params: any) {
        this.text = params.value;
        const row = params.data;

        if (this.text && (!params.linkActive || params.linkActive(row))) {
            this.linkActive = true;
            this.onLinkClick = (event) => {
                event.stopPropagation();
                params.onLinkClick(row);
            };
        }
    }

    refresh() {
        return false;
    }
}
