import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { generateId } from '@app/components/common/utils/utils';
import { get } from 'lodash-es';
import type { ISelectConfig, SelectItemGroup } from './select';

interface ListItem {
    id: string;
    type: 'header' | 'value' | 'create';
    text: string;
    subtext?: string;
    value?: any;
}

@Component({
    selector: 'uni-select-item-list',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './select-item-list.html',
    styleUrls: ['./select-item-list.sass'],
})
export class SelectItemList {
    @Input() items: any[];
    @Input() itemGroups: SelectItemGroup[];

    @Input() value: any;
    @Input() search: string;
    @Input() config: ISelectConfig;

    @Output() itemSelected = new EventEmitter<any>();
    @Output() close = new EventEmitter<void>();

    listItems: ListItem[];
    focusedId: string;

    constructor(private cdr: ChangeDetectorRef) {}

    ngOnChanges(changes) {
        if (changes.items || changes.itemGroups || changes.search) {
            this.updateListItems();
        }

        if (changes.value) {
            this.setInitialFocus();
        }
    }

    focusNext() {
        const items = this.listItems.filter((item) => item.type === 'value' || item.type === 'create') || [];
        let currentIndex = items.findIndex((item) => item.id === this.focusedId);

        if (currentIndex >= 0 && currentIndex < items.length - 1) {
            this.focusItem(items[currentIndex + 1]);
        } else {
            this.focusItem(items[0]);
        }
    }

    focusPrevious() {
        const items = this.listItems.filter((item) => item.type === 'value' || item.type === 'create') || [];
        let currentIndex = items.findIndex((item) => item.id === this.focusedId);

        if (currentIndex > 0) {
            this.focusItem(items[currentIndex - 1]);
        } else {
            this.focusItem(items[items.length - 1]);
        }
    }

    select(listItem?: ListItem) {
        listItem ??= this.listItems.find((item) => item.id === this.focusedId);

        if (!listItem) return;

        if (listItem.type === 'create') {
            this.config.createItemButton.action();
            this.close.emit();
        }

        if (listItem.type === 'value') {
            this.itemSelected.emit(listItem.value);
        }
    }

    getItemActions(item: ListItem) {
        if (this.config.itemActionResolver) {
            return this.config.itemActionResolver(item.value);
        }
    }

    onItemActionClick(event: MouseEvent, item: ListItem, action) {
        event.stopPropagation();
        action(item.value);
    }

    private updateListItems() {
        let listItems = this.getListItems();

        const addEmptyItem = !this.config.hideDeleteButton && get(this.items?.[0], this.config.valueProperty);
        if (addEmptyItem) {
            listItems.unshift({
                id: generateId('select-item'),
                type: 'value',
                text: 'Ikke valgt',
                value: null,
            });
        }

        if (this.config.createItemButton) {
            listItems.push({
                id: generateId('select-item'),
                type: 'create',
                text: this.config.createItemButton.label,
            });
        }

        this.listItems = listItems;
        setTimeout(() => this.setInitialFocus());
    }

    private getListItems() {
        let items: ListItem[] = [];

        const filterFn = (item) => {
            return (
                !this.search ||
                item.text.toLowerCase().includes(this.search.toLowerCase()) ||
                item.subtext.toLowerCase().includes(this.search.toLowerCase())
            );
        };

        if (this.items?.length) {
            items = this.items
                .map((item) => {
                    return {
                        id: generateId('select-item'),
                        type: 'value',
                        text: this.getDisplayValue(item) || '',
                        subtext: this.getSubTextValue(item) || '',
                        value: item,
                    } as ListItem;
                })
                .filter(filterFn);
        } else if (this.itemGroups?.length) {
            this.itemGroups.forEach((group) => {
                const groupItems = group.items
                    .map((item) => {
                        return {
                            id: generateId('select-item'),
                            type: 'value',
                            text: this.getDisplayValue(item) || '',
                            subtext: this.getSubTextValue(item),
                            value: item,
                        } as ListItem;
                    })
                    .filter(filterFn);

                if (groupItems.length) {
                    items.push({ id: generateId('select-header'), type: 'header', text: group.label }, ...groupItems);
                }
            });
        }

        return items;
    }

    private setInitialFocus() {
        const valueItems = this.listItems?.filter((item) => item.type === 'value') || [];

        let selectedItem = valueItems.find(
            (item) => this.getDisplayValue(item.value) === this.getDisplayValue(this.value),
        );

        if (this.search?.length) {
            // Auto focus first (unless we already have a selected item) when the user is searching
            selectedItem ??= valueItems.find((item) => item.type === 'value');

            // Avoid auto focusing the "Ikke valgt" item
            const emptyValueSelected = selectedItem && !selectedItem.value;
            if (emptyValueSelected && valueItems.length > 1) {
                selectedItem = valueItems[1];
            }
        }

        this.focusItem(selectedItem);
    }

    private focusItem(item: ListItem) {
        this.focusedId = item?.id;

        if (this.focusedId) {
            const element = document.getElementById(this.focusedId);
            element?.scrollIntoView({ block: 'nearest' });
        }

        this.cdr.markForCheck();
    }

    getDisplayValue(item): string {
        if (!this.config) {
            return '';
        }

        if (!item) {
            return 'Ikke valgt';
        }

        if (typeof item === 'string') {
            return item;
        } else if (this.config.displayProperty) {
            return get(item, this.config.displayProperty, '');
        } else if (this.config.template) {
            return this.config.template(item) || '';
        } else {
            return '';
        }
    }

    getSubTextValue(item): string {
        if (!this.config?.subTextTemplate) {
            return '';
        } else {
            return this.config.subTextTemplate(item) || '';
        }
    }
}
