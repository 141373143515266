import { Injectable, Signal, signal } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BizHttp } from '../../../framework/core/http/BizHttp';
import { File } from '../../unientities';
import { UniHttp } from '../../../framework/core/http/http';
import { forkJoin, from, fromEvent, Observable, of } from 'rxjs';
import {
    refCount,
    publishReplay,
    tap,
    switchMap,
    map,
    mergeAll,
    toArray,
    catchError,
    shareReplay,
    filter,
    startWith,
} from 'rxjs/operators';
import { ErrorService } from './errorService';
import { saveAs } from 'file-saver';
import { UniCache } from '@app/cache';
import { AuthService } from '@app/authService';
import { environment } from 'src/environments/environment';
import { StatisticsService } from './statisticsService';
import printJS from 'print-js';
import { toSignal } from '@angular/core/rxjs-interop';

@Injectable({ providedIn: 'root' })
export class FileService extends BizHttp<File> {
    private blobCache = new UniCache(0);

    batchUploadProgress = 0;
    inboxTagNames = ['IncomingMail', 'IncomingEHF', 'IncomingTravel', 'IncomingExpense', 'Upload'];

    isFileviewerOpen = toSignal(
        fromEvent(window, 'storage').pipe(
            filter((e: StorageEvent) => e.key === 'fileviewer-is-open'),
            map((e) => e.newValue === 'true'),
        ),
        { initialValue: localStorage.getItem('fileviewer-is-open') === 'true' },
    );

    constructor(
        private httpClient: HttpClient,
        private errorService: ErrorService,
        private authService: AuthService,
        private statisticsService: StatisticsService,
        http: UniHttp,
    ) {
        super(http);
        this.cacheSettings.timeout = 120000; // 2 min

        this.relativeURL = 'files';
        this.entityType = File.EntityType;
        this.DefaultOrderBy = null;
    }

    private getAuthHeaders() {
        return {
            Authorization: `Bearer ${this.authService.jwt}`,
            CompanyKey: this.authService.activeCompany.Key,
        };
    }

    public getFilesOn(entity: string, id: number, ignoreCache?: boolean): Observable<File[]> {
        return super.GetAll(
            `filter=EntityLinks.EntityType eq '${entity}' and EntityLinks.EntityID eq ${id}`,
            ['EntityLinks'],
            ignoreCache,
        );
    }

    invalidateCacheForStorageReference(storageReference: string) {
        const keys = this.blobCache.keys() || [];
        keys.forEach((key) => {
            if (key.includes(storageReference)) {
                this.blobCache.remove(key);
            }
        });
    }

    getImageBlob(imageUrl: string) {
        const cached = this.blobCache.get(imageUrl);
        if (cached) {
            return of(cached);
        } else {
            return this.httpClient
                .get(imageUrl, {
                    responseType: 'blob',
                    headers: this.getAuthHeaders(),
                })
                .pipe(tap((blob) => this.blobCache.add(imageUrl, blob)));
        }
    }

    upload(file, entityType?: string, entityID?: number) {
        const data = new FormData();
        data.append('Token', this.authService.jwt);
        data.append('Key', this.authService.getCompanyKey());
        data.append('CacheOnUpload', 'true');
        data.append('File', file);

        if (entityType) {
            data.append('EntityType', entityType);
        }

        if (entityID) {
            data.append('EntityID', entityID.toString());
        }

        return this.httpClient
            .post<any>(environment.BASE_URL_FILES + '/api/file', data, {
                observe: 'body',
                headers: this.getAuthHeaders(),
            })
            .pipe(tap(() => this.invalidateCache()));
    }

    batchUploadFiles(files: any[], entityType?: string, entityID?: number): Observable<any[]> {
        this.batchUploadProgress = 0;

        const requests = files.map((file) =>
            this.upload(file, entityType, entityID).pipe(tap(() => this.batchUploadProgress++)),
        );

        return from(requests).pipe(mergeAll(2), toArray());
    }

    tagFileInUse(fileID: number) {
        return this.PutAction(fileID, 'tag-file-in-use').pipe(
            catchError((err) => {
                console.error(err);
                return of(false);
            }),
        );
    }

    tagFilesInUse(fileIDs: number[]) {
        if (!fileIDs?.length) return of([]);

        return forkJoin(fileIDs.map((id) => this.tagFileInUse(id)));
    }

    tagFileNotInUse(fileID: number) {
        return this.PutAction(fileID, 'tag-file-not-in-use').pipe(
            catchError((err) => {
                console.error(err);
                return of(false);
            }),
        );
    }

    deleteDeletedFiles(fileIDs: number[]) {
        return forkJoin(fileIDs.map((id) => this.deleteDeletedFile(id)));
    }

    deleteDeletedFile(fileID: number) {
        return this.DeleteAction(fileID, 'force-delete-file');
    }

    tagFilesNotInUse(fileIDs: number[]) {
        if (!fileIDs?.length) return of([]);

        return forkJoin(fileIDs.map((id) => this.tagFileNotInUse(id)));
    }

    tagFileStatus(fileID: number, status: number, fallbackTagName = 'Upload') {
        return this.statisticsService
            .GetAllUnwrapped(
                'model=filetag&select=ID,TagName as TagName&top=1&orderby=ID asc&filter=isnull(Deleted,0) eq 0 and FileID eq ' +
                    fileID,
            )
            .pipe(
                switchMap((tags) => {
                    let tagName = (tags && tags[0] && tags[0].TagName) || fallbackTagName;
                    return this.tag(fileID, tagName, status);
                }),
            );
    }

    mergeFiles(newFileName: string, storageRefs: string[], tagName = 'Upload') {
        const url = environment.BASE_URL_FILES + '/api/file/merge-files';
        const body = { FileName: newFileName, FileIds: storageRefs, TagName: tagName };
        return this.httpClient
            .post<any>(url, body, {
                observe: 'body',
                headers: this.getAuthHeaders(),
            })
            .pipe(tap(() => this.invalidateCache()));
    }

    downloadFile(file: File) {
        const url = environment.BASE_URL_FILES + `/api/download?id=${file.StorageReference}`;
        this.httpClient
            .get(url, {
                responseType: 'blob',
                headers: this.getAuthHeaders(),
            })
            .subscribe(
                (blob: Blob) => {
                    if (file.ContentType) {
                        blob = new Blob([blob], { type: file.ContentType });
                    }

                    saveAs(blob, file.Name);
                },
                (err) => this.errorService.handle(err),
            );
    }

    downloadXml(fileID: number, type = 'application/xml') {
        return super.GetAction(fileID, 'download').pipe(
            switchMap((url) => {
                url = url.replace(/\"/g, '');
                return this.httpClient.get(url, {
                    responseType: 'text',
                    headers: this.getAuthHeaders(),
                });
            }),
            map((res) => new Blob([res], { type: type })),
        );
    }

    printFile(resourceUrl: string, type: 'pdf' | 'html' | 'image' | 'json' | 'raw-html') {
        this.httpClient
            .get(resourceUrl, {
                responseType: 'blob',
                headers: this.getAuthHeaders(),
            })
            .subscribe((blob) => {
                printJS({
                    printable: URL.createObjectURL(blob),
                    type: type,
                });
            });
    }

    setIsAttachment(entityType: string, entityID: number, fileID: number, isAttachment: boolean) {
        return super.PutAction(
            fileID,
            'set-is-attachment',
            `entityType=${entityType}&entityID=${entityID}&isAttachment=${isAttachment}`,
        );
    }

    linkFile(entityType: string, entityID: number, fileID: number) {
        return super.PostAction(fileID, 'link', `entityType=${entityType}&entityID=${entityID}`);
    }

    unlinkFile(entityType: string, entityID: number, fileID: number) {
        return super.PostAction(fileID, 'unlink', `entityType=${entityType}&entityID=${entityID}`);
    }

    getLinkedEntityID(fileID: number, entityType?: string) {
        if (fileID) {
            let filter = `deleted eq 0 and fileid eq ${fileID}`;
            if (entityType) {
                filter += ` and entitytype eq '${entityType}'`;
            }

            return this.getStatistics(
                `model=fileentitylink&select=entityid as entityID,entityType as entityType&filter=${filter}&orderby=entityid desc`,
            ).pipe(map((response) => response.Data));
        } else {
            return of([]);
        }
    }

    splitFile(oldFileID: number, newFileID1: number, newFileID2: number) {
        return super.PostAction(
            null,
            'split-file',
            `oldFileID=${oldFileID}&newFileID1=${newFileID1}&newFileID2=${newFileID2}`,
        );
    }

    splitFileMultiple(oldFileID: number, newFileIds: Array<number>) {
        return super.PostAction(
            null,
            'split-file-multiple',
            `oldFileID=${oldFileID}&newFileIds=${newFileIds.join(',')}`,
        );
    }

    getDeletedFiles(tagNames: string) {
        let url = `/api/biz/filetags/${tagNames}/0?action=get-supplierInvoice-deleted-files`;
        const hash = super.hashFnv32a(url);

        let req = super.getFromCache(hash);
        if (!req) {
            req = this.httpClient.get<File[]>(url).pipe(shareReplay());
            super.storeInCache(hash, req);
        }

        return req;
    }

    getInboxFileSource(file) {
        const tag = file.FileTags && file.FileTags[0];
        switch (tag?.TagName) {
            case 'IncomingMail':
                return 'E-post';
            case 'IncomingEHF':
                return file.ContentType != 'bis/forward-billing:3.0' ? 'EHF' : 'EHF FB';
            case 'IncomingTravel':
                return 'Reise';
            case 'IncomingExpense':
                return 'App';
            case 'Upload':
                return 'Opplasting';
        }
    }

    getFilesOnEntity(entityType: string, entityID: number) {
        const url = `files/${entityType}/${entityID}`;

        const hash = super.hashFnv32a(url);
        let req = super.getFromCache(hash);

        if (!req) {
            req = this.http
                .asGET()
                .usingBusinessDomain()
                .withDefaultHeaders()
                .withEndPoint(url)
                .send()
                .pipe(publishReplay(1), refCount());

            super.storeInCache(hash, req);
        }

        return req.pipe(map((res) => res.body));
    }

    deleteOnEntity(entityType: string, entityID: number, fileID: number) {
        super.invalidateCache();
        return this.http
            .asDELETE()
            .withDefaultHeaders()
            .usingBusinessDomain()
            .withEndPoint(`files/${entityType}/${entityID}/${fileID}`)
            .send();
    }

    deleteMultipleFiles(storageReferences: string[]) {
        return this.httpClient
            .delete(environment.BASE_URL_FILES + '/api/file/delete-files', {
                headers: this.getAuthHeaders(),
                body: storageReferences,
            })
            .pipe(tap(() => this.invalidateCache()));
    }

    tag(id: number, tag: string, status: number = 0) {
        return this.http
            .asPOST()
            .withDefaultHeaders()
            .usingBusinessDomain()
            .withEndPoint(`filetags`)
            .withBody({ FileID: id, TagName: tag, Status: status })
            .send()
            .pipe(map((response) => response.body));
    }

    getStatistics(query: string) {
        return this.http
            .asGET()
            .usingStatisticsDomain()
            .withEndPoint('?' + query)
            .send()
            .pipe(map((response) => response.body));
    }
}
