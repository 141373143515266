@for (data of cmsData.items; track data) {
    @switch (data.type) {
        @case ('linkgroup') {
            <section class="flex align-center gap-2">
                @for (item of data.linkgroup; track item) {
                    <cms-link [item]="item" [width]="'8rem'" [cmsData]="cmsData"></cms-link>
                }
            </section>
        }
        @case ('link') {
            <cms-link [item]="data?.link || data" [cmsData]="cmsData"></cms-link>
        }
        @case ('text') {
            <cms-text [item]="data"></cms-text>
        }
        @case ('list') {
            <cms-list [list]="data"></cms-list>
        }

        @case ('illustration') {
            <cms-illustration [item]="data"></cms-illustration>
        }

        @case ('illustration_new') {
            <cms-illustration-v2 class="vertical" [item]="data"></cms-illustration-v2>
        }
    }
}
