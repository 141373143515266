<label class="flex pointer" (click)="preventDuplicateClickEvents($event)">
    <input
        type="checkbox"
        class="visually-hidden"
        [tabindex]="tabindex || 0"
        [disabled]="disabled"
        [checked]="checked"
        (change)="onChange($event.target.checked)"
    />

    <span class="checkbox flex align-center justify-center input-border" aria-hidden>
        <svg *ngIf="checked" width="12" viewBox="0 0 10 8">
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M3.483 7.472.62 4.397a.456.456 0 0 1 0-.616l.578-.614c.16-.17.42-.17.579 0L3.773 5.32 8.223.527c.16-.17.42-.17.579 0l.578.615c.16.17.16.446 0 .614L4.062 7.472a.393.393 0 0 1-.579 0Z"
                fill="currentColor"
            />
        </svg>
    </span>

    <span class="label-text">
        <ng-content></ng-content>
    </span>
</label>
