import { Component, Input } from '@angular/core';
import { CMSSlideinData, CMSWidgetData } from '@app/services/cms/cms-service';

@Component({
    selector: 'cms-wrapper',
    templateUrl: './cms-wrapper.html',
    styleUrls: ['./cms-wrapper.sass'],
})
export class CMSWrapper {
    @Input() cmsData: CMSWidgetData | CMSSlideinData;

    constructor() {}
}
