import { Pipe, PipeTransform } from '@angular/core';
import { ErrorService } from '@app/services/common/errorService';
import { NumberFormat } from '@app/services/common/numberFormatService';
import { CompanySettingsService } from '@app/services/common/companySettingsService';

@Pipe({ name: 'uninumberformat' })
export class UniNumberFormatPipe implements PipeTransform {
    constructor(
        private numberFormat: NumberFormat,
        private errorService: ErrorService,
        private settingsService: CompanySettingsService,
    ) {}

    public transform(value: number, format: string, showNullAsZero = true): string {
        try {
            if (!value && value !== 0 && !showNullAsZero) {
                return '';
            }

            let numberFormatOptions = {
                decimalLength: this.settingsService?.companySettingsObject?.ShowNumberOfDecimals ?? 2,
            };

            switch (format) {
                case 'percentage':
                    return this.numberFormat.asPercentage(value);
                case 'percentagewithdecimal':
                    numberFormatOptions = {
                        decimalLength: 1,
                    };
                    return this.numberFormat.asPercentage(value, numberFormatOptions);
                case 'money':
                    return this.numberFormat.asMoney(value, numberFormatOptions, showNullAsZero);
                case 'orgno':
                    return this.numberFormat.asOrgNo(value);
                case 'bankacct':
                    return this.numberFormat.asBankAcct(value);
                case 'rounded':
                    return this.numberFormat.asNumber(value, { decimalLength: 0 });
                case 'abs':
                    return this.numberFormat.asNumber(Math.abs(value), { decimalLength: 0 });
                default:
                    return this.numberFormat.asNumber(value);
            }
        } catch (err) {
            this.errorService.handle(err);
        }
    }
}
