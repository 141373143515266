import {
    Component,
    Input,
    Output,
    EventEmitter,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    ViewChildren,
    QueryList,
    ElementRef,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
    selector: 'numeric-input',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./numeric-input.sass'],
    template: `
        <input
            type="text"
            #inputElement
            [formControl]="inputControl"
            [readonly]="readonly"
            (change)="emitChange()"
            (keydown)="onKeyDown($event)"
        />

        <input-dropdown-menu [input]="inputElement" [visible]="calculatorItems?.length">
            <ng-template>
                <section class="calculator-content">
                    <section class="calculator-header">
                        <i class="material-icons">calculate</i>
                        Kalkulator
                    </section>

                    <section class="calculator-body">
                        <section *ngFor="let item of calculatorItems" class="calculator-item" #calculatorItem>
                            <span>{{ item.operator === '*' ? 'x' : item.operator }}</span>
                            <span>{{ item.value | uninumberformat: 'money' : false }}</span>
                        </section>
                    </section>

                    <section class="calculator-footer">
                        <span></span>
                        <span>{{ formattedCalculatorSum }}</span>
                    </section>
                </section>
            </ng-template>
        </input-dropdown-menu>
    `,
})
export class NumericInput {
    @ViewChildren('calculatorItem') calculatorItemElements: QueryList<ElementRef>;

    @Input() value: number;
    @Input() readonly: boolean;
    @Input() showUndefinedAsZero: boolean;
    @Input() showEmptyAsZero: boolean;

    @Output() valueChange = new EventEmitter<number>();

    inputControl = new UntypedFormControl();
    controlSubscription: Subscription;

    calculatorItems: { operator?: string; value?: number; formattedValue?: string }[] = [];
    formattedCalculatorSum: string;

    constructor(private cdr: ChangeDetectorRef) {}

    ngOnChanges(changes) {
        if (changes['value']) {
            this.inputControl.setValue(this.formatValue(this.value), { emitEvent: false });
        }
    }

    ngOnInit() {
        this.controlSubscription = this.inputControl.valueChanges.subscribe((value) => {
            if (this.calculatorItems?.length) {
                const calculatorItem = this.calculatorItems[this.calculatorItems.length - 1];
                if (calculatorItem) {
                    calculatorItem.value = this.parseValue(value);
                    this.formattedCalculatorSum = this.formatValue(this.getCalculatorSum());
                }
            }
        });
    }

    ngOnDestroy() {
        this.controlSubscription?.unsubscribe();
    }

    emitChange() {
        if (this.inputControl.dirty) {
            const value = this.calculatorItems?.length
                ? this.getCalculatorSum()
                : this.parseValue(this.inputControl.value);

            this.valueChange.emit(value);
            this.calculatorItems = [];
            this.inputControl.setValue(this.formatValue(this.value), { emitEvent: false });
        }
    }

    parseValue(value: string) {
        if (!value && this.showEmptyAsZero) {
            value = '0';
        }

        const stringValue = value
            ?.toString()
            .replace(',', '.')
            .replace(/[^\d.-]/g, '');
        const parsed = stringValue && parseFloat(stringValue);
        return isNaN(parsed) ? null : parsed;
    }

    formatValue(value: number) {
        if (this.showUndefinedAsZero) {
            value ??= 0;
        }

        if (!value && value !== 0) {
            return '';
        }

        let stringValue = value.toString().replace(',', '.');
        let [integer, decimal] = stringValue.split('.');
        integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

        if (!decimal) {
            decimal = '00';
        } else if (decimal.length === 1) {
            decimal += '0';
        }

        return `${integer},${decimal}`;
    }

    onKeyDown(event: KeyboardEvent) {
        if (['+', '-', '*', '/'].includes(event.key)) {
            setTimeout(() => {
                const calculatorItem = this.calculatorItems && this.calculatorItems[this.calculatorItems.length - 1];
                // If theres only an operator (no value) in the input just change operator on the current item
                if (this.inputControl.value?.length === 1) {
                    if (calculatorItem) {
                        calculatorItem.operator = event.key;
                        this.inputControl.setValue('', { emitEvent: false });
                        this.cdr.markForCheck();
                    }
                } else {
                    let inputValue = this.parseValue(this.inputControl.value?.slice(0, -1));
                    if (inputValue) {
                        if (calculatorItem) {
                            calculatorItem.value = inputValue;
                            calculatorItem.formattedValue = this.formatValue(inputValue);
                            this.calculatorItems.push({ operator: event.key });
                        } else {
                            this.calculatorItems.push(
                                { value: inputValue, formattedValue: this.formatValue(inputValue) },
                                { operator: event.key },
                            );
                        }

                        this.inputControl.setValue('', { emitEvent: false });
                        this.formattedCalculatorSum = this.formatValue(this.getCalculatorSum());

                        this.cdr.markForCheck();

                        setTimeout(() => this.calculatorItemElements?.last?.nativeElement?.scrollIntoView());
                    }
                }
            });
        }

        if (event.key === 'Escape') {
            this.inputControl.reset(this.formatValue(this.value), { emitEvent: false });
            this.calculatorItems = [];
            this.cdr.markForCheck();
        }
    }

    private getCalculatorSum() {
        const sum = this.calculatorItems.reduce((sum, item) => {
            switch (item.operator) {
                case '+':
                    return sum + (item.value || 0);
                case '-':
                    return sum - (item.value || 0);
                case '*':
                    return sum * (item.value || 1);
                case '/':
                    return sum / (item.value || 1);
                default:
                    // The first item doesnt have an operator, treat it as +
                    return sum + (item.value || 0);
            }
        }, 0);

        return parseFloat(sum.toFixed(4));
    }
}
