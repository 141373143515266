<uni-select
    #uniselect
    [config]="field?.Options"
    [items]="items"
    [source]="source"
    [searchFn]="field?.Options?.search"
    [initialItemFn]="field?.Options?.initialItemFn"
    [value]="selectedItem"
    [readonly]="readOnly$ | async"
    (valueChange)="onChange($event)"
    (readyEvent)="createFocusListener($event)"
>
</uni-select>
<ng-content></ng-content>
