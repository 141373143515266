<section class="cms-slidein-wrapper flex-column gap-2" *ngIf="cmsService.slideIn | async as data" [@slideInOut]>
    <header class="flex align-center justify-between mb-0">
        <h1 *ngIf="data?.title">{{ data.title || '' }}</h1>
        <button class="unstyled ml-auto">
            <i class="material-icons" (click)="cmsService.closeSlideIn()">close</i>
        </button>
    </header>

    <cms-wrapper [cmsData]="data" class="gap-2"></cms-wrapper>
</section>
