import {
    Component,
    Input,
    Output,
    EventEmitter,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    ViewChild,
    ElementRef,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { rigDate } from '@app/components/common/utils/rig-date';

import { UniModalService } from '@uni-framework/uni-modal';
import { UniTableConfig, UniTableColumn, QuickFilter, UniTableColumnType } from '@uni-framework/ui/unitable';
import { AdvancedFilters } from './advanced-filters/advanced-filters';
import { ITableFilter, ISavedSearch, IOptionBanner } from '../interfaces';
import { TableUtils } from '../services/table-utils';
import { DropdownMenu } from '@uni-framework/ui/dropdown-menu/dropdown-menu';
import { isEqual } from 'lodash-es';

@Component({
    selector: 'table-filters-and-buttons',
    templateUrl: './filters-and-buttons.html',
    styleUrls: ['./filters-and-buttons.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableFiltersAndButtons {
    @ViewChild('searchInput') searchInput: ElementRef<HTMLInputElement>;

    @Input() tableConfig: UniTableConfig;
    @Input() columns: UniTableColumn[];
    @Input() filters: ITableFilter[];
    @Input() quickFilters: QuickFilter[];
    @Input() optionBanner: IOptionBanner;
    @Input() initialValue: string;

    @Output() filtersChange: EventEmitter<any> = new EventEmitter();
    @Output() searchFieldKeydown = new EventEmitter<KeyboardEvent>();

    onDestroy$: Subject<any> = new Subject();
    searchControl: UntypedFormControl = new UntypedFormControl('');

    tableName: string;
    visibleColumns: UniTableColumn[];
    savedSearches: ISavedSearch[] = [];
    advancedSearchFilters: ITableFilter[];

    constructor(
        private cdr: ChangeDetectorRef,
        private modalService: UniModalService,
        private utils: TableUtils,
    ) {}

    ngAfterViewInit() {
        this.searchControl.valueChanges
            .pipe(takeUntil(this.onDestroy$), debounceTime(250))
            .subscribe(() => this.emitFilters());
    }

    ngOnChanges(changes) {
        if (changes['initialValue']) {
            this.searchControl.setValue(this.initialValue);
        }
        if (this.filters && this.columns) {
            this.visibleColumns = this.columns.filter((col) => col.visible);
            this.advancedSearchFilters = this.setDisplayTextOnFilters(this.filters);
        }

        if (changes['tableConfig'] && this.tableConfig) {
            this.tableName = this.tableConfig.configStoreKey;
            this.getSavedSearches();

            const { searchText } = this.utils.getFilterState(this.tableConfig);
            if (searchText && !this.searchControl.value) {
                this.searchControl.setValue(searchText, { emitEvent: false });
            }
        }
    }

    ngOnDestroy() {
        this.onDestroy$.next(undefined);
        this.onDestroy$.complete();
    }

    focus() {
        this.searchInput?.nativeElement?.focus();
    }

    private getSavedSearches() {
        this.savedSearches = this.utils.getSavedSearches(this.tableName);
        this.cdr.markForCheck();
    }

    applySearch(search?) {
        if (search) {
            this.advancedSearchFilters = search.filters;
        } else {
            this.advancedSearchFilters = [];
        }

        this.emitFilters();
        this.cdr.markForCheck();
    }

    deleteSearch(index: number, event: MouseEvent) {
        event.stopPropagation();
        this.savedSearches = this.utils.removeSearch(this.tableName, this.savedSearches[index]);
        this.cdr.markForCheck();
    }

    editSearch(search: ISavedSearch, event: MouseEvent, dropdownMenu: DropdownMenu) {
        event.stopPropagation();
        dropdownMenu.hide();
        this.showAdvancedFilters(undefined, search);
    }

    showAdvancedFilters(focusIndex?: number, savedSearch?: ISavedSearch) {
        if (!savedSearch && this.savedSearches?.length && this.advancedSearchFilters?.length) {
            savedSearch = this.savedSearches.find((savedSearch) => {
                return isEqual(savedSearch.filters, this.advancedSearchFilters);
            });
        }

        this.modalService
            .open(AdvancedFilters, {
                closeOnClickOutside: true,
                data: {
                    columns: this.visibleColumns,
                    config: this.tableConfig,
                    filters: (savedSearch && savedSearch.filters) || this.advancedSearchFilters,
                    focusIndex: focusIndex,
                    searchName: savedSearch && savedSearch.name,
                },
            })
            .onClose.subscribe((filters) => {
                this.getSavedSearches();

                if (filters) {
                    this.advancedSearchFilters = this.setDisplayTextOnFilters(filters);
                    this.emitFilters();
                    this.cdr.markForCheck();
                }
            });
    }

    removeFilter(index: number) {
        this.advancedSearchFilters.splice(index, 1);
        this.emitFilters();
        this.cdr.markForCheck();
    }

    removeAll() {
        this.advancedSearchFilters = [];
        this.emitFilters();
        this.cdr.markForCheck();
    }

    getBasicSearchFilters(value) {
        return this.utils.getFiltersFromSearchText(value, this.visibleColumns);
    }

    emitFilters() {
        const quickFilterValues = <any>{};
        this.quickFilters?.forEach((filter) => (quickFilterValues[filter.field] = filter.value));
        const basicSearchFilters = this.utils.getFiltersFromSearchText(this.searchControl.value, this.visibleColumns);

        const lastUsedFilter = {
            searchText: this.searchControl.value || '',
            basicSearchFilters,
            advancedSearchFilters: this.advancedSearchFilters,
            quickFilterValues: quickFilterValues,
        };

        this.utils.updateFilterState(this.tableName, lastUsedFilter, this.tableConfig?.useUrlState);

        this.filtersChange.emit({
            basicSearchFilters,
            advancedSearchFilters: this.advancedSearchFilters,
            quickFilters: this.quickFilters,
        });
    }

    private setDisplayTextOnFilters(filters: ITableFilter[]): ITableFilter[] {
        return filters.map((filter) => {
            let header, operator, value;

            const column = this.columns && this.columns.find((col) => col.field === filter.field);
            const selectConfig = filter.selectConfig;
            header = column ? column.header : filter.field;

            operator = this.getOperatorTranslation(filter) || filter.operator;

            if (column?.type === UniTableColumnType.Boolean) {
                value = filter.value ? 'ja' : 'nei';
            }

            if (filter.isDate) {
                value = rigDate(filter.value).format('DD.MM.YYYY');
            } else {
                if (selectConfig && selectConfig.options) {
                    const item = selectConfig.options.find((option) => {
                        const optionValue = option[selectConfig.valueField];
                        return optionValue.toString() === filter.value.toString();
                    });

                    if (item) {
                        value = item[selectConfig.displayField];
                    }
                }

                if (!value) {
                    value = isNaN(+filter.value) ? `"${filter.value}"` : filter.value;
                }
            }

            if (filter.operator === 'NOT_SET') {
                filter['_displayText'] = `${header} er blank`;
            } else {
                filter['_displayText'] = `${header} ${operator} ${value}`;
            }
            return filter;
        });
    }

    private getOperatorTranslation(filter): string {
        switch (filter.operator) {
            case 'eq':
                return 'er';
            case 'ne':
                return 'er ikke';
            case 'lt':
                return filter.isDate ? 'før' : 'mindre enn';
            case 'gt':
                return filter.isDate ? 'etter' : 'større enn';
            case 'le':
                return filter.isDate ? 't.o.m.' : 'mindre enn/lik';
            case 'ge':
                return filter.isDate ? 'f.o.m.' : 'større enn/lik';
            case 'contains':
                return 'inneholder';
            case 'not contains':
                return 'inneholder ikke';
            case 'startswith':
                return 'begynner på';
            case 'endswith':
                return 'slutter på';
            default:
                return filter.operator;
        }
    }
}
