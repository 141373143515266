import { ConnectedPosition, Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ComponentRef,
    Directive,
    ElementRef,
    Input,
    TemplateRef,
    Type,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';

@Directive({
    selector: '[uniTooltip]',
})
export class TooltipDirective {
    @Input() uniTooltip: string | TemplateRef<any> | Type<any>;
    @Input() tooltipTemplateData;
    @Input() tooltipMaxWidth: string;

    private portal: ComponentPortal<TooltipComponent>;
    private overlayRef: OverlayRef;
    private tooltipInstance: TooltipComponent;

    mouseEnter: Subscription;

    constructor(
        private elementRef: ElementRef,
        private viewContainerRef: ViewContainerRef,
        private overlay: Overlay,
    ) {}

    ngOnChanges(changes) {
        if (changes['uniTooltip'] && this.uniTooltip) {
            const element: HTMLElement = this.elementRef.nativeElement;
            element.title = '';

            this.mouseEnter?.unsubscribe();
            this.mouseEnter = fromEvent(this.elementRef.nativeElement, 'mouseenter').subscribe(() => {
                if (!this.tooltipInstance) {
                    this.initOverlay();
                }
            });
        }
    }

    ngOnDestroy() {
        this.mouseEnter?.unsubscribe();

        if (this.overlayRef) {
            this.overlayRef.detach();
            this.overlayRef.dispose();
        }
    }

    initOverlay() {
        if (this.overlayRef) {
            this.overlayRef.detach();
            this.overlayRef.dispose();
        }

        const positions: ConnectedPosition[] = [
            { originX: 'start', originY: 'bottom', overlayX: 'start', overlayY: 'top' },
            { originX: 'end', originY: 'bottom', overlayX: 'end', overlayY: 'top' },
            { originX: 'start', originY: 'top', overlayX: 'start', overlayY: 'bottom' },
            { originX: 'end', originY: 'top', overlayX: 'end', overlayY: 'bottom' },
        ];

        const positionStrategy = this.overlay
            .position()
            .flexibleConnectedTo(this.elementRef)
            .withPositions(positions)
            .withPush(false);

        const scrollStrategy = this.overlay.scrollStrategies.reposition();

        const config = new OverlayConfig({
            positionStrategy: positionStrategy,
            scrollStrategy: scrollStrategy,
        });

        this.portal = new ComponentPortal(TooltipComponent, this.viewContainerRef);
        this.overlayRef = this.overlay.create(config);
        this.tooltipInstance = this.overlayRef.attach(this.portal).instance;

        this.tooltipInstance.init({
            triggerElement: this.elementRef?.nativeElement,
            content: this.uniTooltip,
            templateData: this.tooltipTemplateData,
            maxWidth: this.tooltipMaxWidth,
        });
    }
}

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <section
            class="uni-tooltip"
            [style.max-width]="maxWidth"
            [class.has-linebreaks]="contentHasLineBreaks"
            [class.tooltip-hidden]="!visible"
        >
            <ng-container *ngIf="isTemplateRef">
                <ng-container *ngTemplateOutlet="content; context: templateData"></ng-container>
            </ng-container>

            <ng-container #componentContainer></ng-container>

            <section *ngIf="!isTemplateRef && !isComponent && content" [innerHTML]="content"></section>
        </section>
    `,
    styleUrls: ['./tooltip.new.sass'],
})
export class TooltipComponent {
    @ViewChild('componentContainer', { read: ViewContainerRef, static: true }) container: ViewContainerRef;

    isTemplateRef: boolean;
    content: string | TemplateRef<any> | Type<any>;
    visible: boolean;
    triggerElement: HTMLElement;
    templateData;
    maxWidth: string;
    contentHasLineBreaks: boolean;

    triggerFocused: boolean;
    tooltipFocused: boolean;

    isComponent: boolean;
    componentRef: ComponentRef<any>;

    listeners: Subscription[] = [];

    constructor(
        private elementRef: ElementRef,
        private cdr: ChangeDetectorRef,
    ) {}

    setupListeners() {
        this.listeners?.forEach((listener) => listener.unsubscribe());
        const tooltipElement = this.elementRef.nativeElement;

        if (this.triggerElement && tooltipElement) {
            this.listeners.push(
                fromEvent(this.triggerElement, 'mouseenter').subscribe(() => {
                    this.triggerFocused = true;
                    this.open();
                }),
            );

            this.listeners.push(
                fromEvent(this.triggerElement, 'mouseleave').subscribe(() => {
                    this.triggerFocused = false;
                    this.close();
                }),
            );

            this.listeners.push(
                fromEvent(tooltipElement, 'mouseenter').subscribe(() => {
                    this.tooltipFocused = true;
                    this.open();
                }),
            );

            this.listeners.push(
                fromEvent(tooltipElement, 'mouseleave').subscribe(() => {
                    this.tooltipFocused = false;
                    this.close();
                }),
            );
        }
    }

    open() {
        if (!this.visible) {
            this.visible = true;
            this.cdr.markForCheck();
        }
    }

    close() {
        setTimeout(() => {
            if (!this.triggerFocused && !this.tooltipFocused) {
                this.visible = false;
                this.cdr.markForCheck();
            }
        }, 100);
    }

    init(options: {
        triggerElement: HTMLElement;
        content: string | TemplateRef<any> | Type<any>;
        templateData?: any;
        maxWidth?: string;
    }) {
        this.triggerElement = options.triggerElement;
        this.content = options.content;
        this.templateData = options.templateData;
        this.maxWidth = options.maxWidth || '40rem';

        if (typeof this.content === 'string') {
            this.contentHasLineBreaks = this.content.includes('\n');
        } else if (this.content instanceof TemplateRef) {
            this.isTemplateRef = true;
        } else if (this.content) {
            this.isComponent = true;
            this.loadComponent();
        }

        this.setupListeners();
        this.open();
    }

    private loadComponent() {
        try {
            this.componentRef = this.container.createComponent(this.content as Type<any>);
            this.componentRef.instance.data = this.templateData;
        } catch (e) {
            console.error(e);
        }
    }

    ngOnDestroy() {
        this.listeners?.forEach((listener) => listener.unsubscribe());
        this.componentRef?.destroy();
    }
}
