<div class="flex-column gap-1" role="tablist" aria-orientation="vertical">
    <button
        *ngFor="let tab of tabs; let idx = index"
        [attr.aria-selected]="idx === activeIndex"
        [tabindex]="idx === activeIndex ? 0 : -1"
        (keydown)="onKeydown($event, idx)"
        (click)="activateTab(idx)"
        class="unstyled"
    >
        {{ tab.label }}
    </button>
</div>
