import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input } from '@angular/core';
import { UniRadioGroup } from './radio-group';

@Component({
    selector: 'uni-radio-button',
    styleUrls: ['./radio-button.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <label class="flex align-center gap-1 pointer">
            <input
                class="visually-hidden"
                type="radio"
                [checked]="checked"
                [disabled]="disabled"
                (change)="onChange()"
                [attr.name]="group?.name"
            />

            <span class="radio flex align-center justify-center" aria-hidden="true">
                <span *ngIf="checked" class="radio-dot"></span>
            </span>

            <span class="label-content flex align-center gap-1">
                <ng-content></ng-content>
            </span>
        </label>
    `,
})
export class UniRadioButton {
    @Input() value: any;

    @HostBinding('class.checked') checked: boolean;

    @Input() disabled: boolean;

    constructor(
        public group: UniRadioGroup,
        private cdr: ChangeDetectorRef,
    ) {}

    onChange() {
        if (this.group) {
            this.group.onButtonSelected(this.value);
        }
    }

    setChecked(checked: boolean) {
        this.checked = checked;
        this.cdr.markForCheck();
    }

    setDisabled(disabled: boolean) {
        this.disabled = disabled;
        this.cdr.markForCheck();
    }
}
