<section class="numeric-input-wrapper">
    <section class="prefix" *ngIf="prefix">{{ prefix }}</section>

    <input
        #inputElement
        *ngIf="control"
        [attr.aria-describedby]="asideGuid"
        type="text"
        [formControl]="control"
        [readonly]="readOnly$ | async"
        [tabindex]="(readOnly$ | async) ? '-1' : '0'"
        [placeholder]="field?.Placeholder || ''"
        [title]="control?.value || ''"
        (blur)="blurHandler($event)"
        (focus)="focusHandler($event)"
    />
</section>
