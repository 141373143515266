import { Component, Input, Output, EventEmitter, ViewChildren, QueryList } from '@angular/core';
import { DateRange, MatCalendar } from '@angular/material/datepicker';

@Component({
    selector: 'date-range-calendars',
    templateUrl: './date-range-calendars.html',
    styleUrls: ['./date-range-calendars.sass'],
})
export class DateRangeCalendars {
    @ViewChildren(MatCalendar) calendars: QueryList<MatCalendar<Date>>;

    @Input() showInputs: boolean;
    @Input() fromDate: Date;
    @Input() toDate: Date;
    @Input() gap: string = '1rem';

    @Output() fromDateChange = new EventEmitter<Date>();
    @Output() toDateChange = new EventEmitter<Date>();

    dateRange: DateRange<Date>;

    ngOnChanges(changes) {
        if (changes.fromDate || changes.toDate) {
            this.updateCalendars();
        }
    }

    updateCalendars() {
        if (this.fromDate && this.toDate) {
            this.dateRange = new DateRange<Date>(this.fromDate, this.toDate);
        } else {
            this.dateRange = undefined;
        }

        setTimeout(() => {
            if (this.calendars.length) {
                this.calendars.first.activeDate = this.fromDate;
                this.calendars.last.activeDate = this.toDate;
            }
        });
    }

    setFromDate(date: Date) {
        this.fromDate = date;
        this.updateCalendars();
        this.fromDateChange.emit(this.fromDate);
    }

    setToDate(date: Date) {
        this.toDate = date;
        this.updateCalendars();
        this.toDateChange.emit(this.toDate);
    }
}
