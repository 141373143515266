import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { AgGridWrapper } from '@uni-framework/ui/ag-grid/ag-grid-wrapper';
import { IContextMenuItem, UniTableConfig } from '@uni-framework/ui/unitable';
import { FeaturePermissionService } from '@app/featurePermissionService';

@Component({
    selector: 'row-menu',
    template: `
        <span *ngIf="rowData" class="row-menu-container" (click)="$event.stopPropagation()">
            <i
                class="material-icons-outlined"
                *ngIf="editButtonAction"
                [class.visibility-hidden]="editButtonHidden"
                (click)="editButtonAction(rowData)"
            >
                edit
            </i>

            <i
                class="material-icons-outlined"
                *ngIf="deleteButtonAction"
                [class.close-icon]="!useBinIcon"
                (click)="deleteButtonAction(rowData)"
            >
                {{ useBinIcon ? 'delete' : 'close' }}
            </i>

            <ng-container *ngIf="contextMenuItems?.length">
                <i class="material-icons" #toggle (click)="onContextMenuToggle()"> more_horiz </i>

                <dropdown-menu [trigger]="toggle" minWidth="12rem">
                    <ng-template>
                        <a
                            class="dropdown-menu-item"
                            *ngFor="let item of filteredContextMenuItems"
                            (click)="item.action(rowData)"
                            [ngClass]="item?.class"
                        >
                            {{ item.label }}
                        </a>
                    </ng-template>
                </dropdown-menu>
            </ng-container>
        </span>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    styles: [
        `
            .row-menu-container .close-icon {
                font-size: 21px;
            }
        `,
    ],
})
export class RowMenuRenderer implements ICellRendererAngularComp {
    parentContext: AgGridWrapper;
    rowData: any;

    contextMenuItems: any[];
    filteredContextMenuItems: any[];
    deleteButtonAction: (row) => void;

    editButtonAction: (row) => void;
    editButtonHidden: boolean;

    useBinIcon: boolean;

    constructor(private permissionService: FeaturePermissionService) {}

    agInit(params: ICellRendererParams): void {
        this.buildMenuItems(params);
    }

    refresh(params: ICellRendererParams): boolean {
        this.buildMenuItems(params);
        return true;
    }

    buildMenuItems(params: ICellRendererParams) {
        const tableConfig: UniTableConfig = params.colDef['_tableConfig'];
        const rowData = params.data;

        if (tableConfig && !rowData?._isSumRow && !rowData?._isGroupHeader) {
            this.rowData = params.data;
            this.parentContext = params.context.componentParent;

            this.contextMenuItems = this.parentContext.getContextMenuItems(this.rowData);
            this.deleteButtonAction = this.parentContext.getDeleteButtonAction(this.rowData);
            this.useBinIcon = tableConfig.deleteButtonUseBinIcon;

            const editButton = tableConfig.editButton;
            if (editButton?.action) {
                this.editButtonAction = (row) => editButton.action(row);
                this.editButtonHidden = editButton.hidden?.(this.rowData);
            }
        }
    }

    public onContextMenuToggle() {
        // Filter items here instead of on init, because init runs on every row
        // and we only need to do this when the user actually opens a menu
        this.filteredContextMenuItems = this.contextMenuItems?.filter((item: IContextMenuItem) => {
            const hasUiPermission =
                !item.featurePermission || this.permissionService.canShowUiFeature(item.featurePermission);
            return hasUiPermission && (!item.disabled || !item.disabled(this.rowData));
        });
    }
}
