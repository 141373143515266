import { ConnectedPosition } from '@angular/cdk/overlay';
import { Component, Input, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { SupportArticleMapping } from '@app/models';
import { ContextSupportService } from '@app/services/common/contextSupportService';
import { parse } from 'marked';
import type { ToolbarHelpWizard } from '../toolbar';

@Component({
    selector: 'context-support',
    templateUrl: './context-support.component.html',
    styleUrls: ['./context-support.component.sass'],
})
export class ContextSupport implements OnInit {
    @Input() wizard: ToolbarHelpWizard;

    loading: boolean = true;
    supportArticle: SupportArticleMapping = null;

    dropdownPositions: ConnectedPosition[] = [
        {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top',
            offsetX: 16,
        },
    ];

    constructor(
        private contextSupportService: ContextSupportService,
        private router: Router,
        private domSanitizer: DomSanitizer,
    ) {}

    ngOnInit() {
        this.initCurrentMapping();
    }

    initCurrentMapping() {
        this.loading = true;
        this.contextSupportService.getSupportArticleMappings().subscribe(
            (supportArticleMappings) => {
                this.setCurrentMapping(supportArticleMappings);
                this.loading = false;
            },
            () => {
                /* Fail Silently */
            },
        );
    }

    onButtonClick() {
        if (this.wizard && !this.supportArticle) {
            this.wizard.open();
        }
    }

    setCurrentMapping(supportArticleMappings: SupportArticleMapping[]) {
        const currentRoute = this.router.url?.split('?')[0];

        this.supportArticle = supportArticleMappings?.find((mapping) => {
            // Check if there is a exact match
            if (mapping.ApplicationRoute === currentRoute) {
                return true;
            }

            // Check if there is a match with dynamic ids
            const applicationRouteSplitted = mapping.ApplicationRoute.split('/');
            const currentSplitted = currentRoute.split('/');

            if (applicationRouteSplitted.length !== currentSplitted.length) {
                return false;
            }

            const isMatch = applicationRouteSplitted.every((x, i) => {
                if (x === '*') {
                    const isNumeric = /^-?\d+$/.test(currentSplitted[i]);
                    return isNumeric;
                }
                return x === currentSplitted[i];
            });

            return isMatch;
        });
        if (this.supportArticle) {
            try {
                const decoded = decodeURI(this.supportArticle.Description);
                this.supportArticle.Description =
                    this.domSanitizer.sanitize(
                        SecurityContext.HTML,
                        parse(decoded).replace('<p>', '<span>').replace('</p>', '</span>'),
                    ) || '';
            } catch (e) {
                console.error(e);
            }
        }
    }
}
