export enum FieldType {
    AUTOCOMPLETE = 0,
    BUTTON = 1,
    MASKED = 4,
    DROPDOWN = 3,
    CHECKBOX = 5,
    NUMERIC = 6,
    CHECKBOXGROUP = 8,
    RADIOGROUP = 9,
    TEXT = 10,
    EMAIL = 11,
    PASSWORD = 12,
    HYPERLINK = 13,
    MULTIVALUE = 14,
    URL = 15,
    TEXTAREA = 16,
    LOCAL_DATE_PICKER = 17,
    UNI_MULTISELECT = 19,
    STATIC_TEXT = 20,
    TYPEAHEAD = 21,
    MONTH_PICKER = 22,
    BANKACCOUNT = 23,
}
