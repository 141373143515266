import { Injectable, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, ReplaySubject, of } from 'rxjs';
import { catchError, map, shareReplay, switchMap, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Company, CurrentUserSession, ContractLicenseType, User } from './unientities';
import { UserManager, WebStorageStateStore } from 'oidc-client';

import { rigDate } from '@app/components/common/utils/rig-date';
import { FeaturePermissionService } from './featurePermissionService';
import { theme, THEMES } from 'src/themes/theme';

export type CurrentUserWithout2FADetails = Omit<
    CurrentUserSession,
    'TwoFactorEnabled' | 'AuthPhoneNumber' | 'PhoneNumberConfirmed'
>;

export interface IAuthDetails {
    activeCompany: Company;
    user: CurrentUserWithout2FADetails;
    hasActiveContract: boolean;
    hasActiveUserLicense?: boolean;
    hasActiveCompanyLicense?: boolean;
    isDemo?: boolean;
}

export interface PublicWebSettings {
    BankName?: string;
    BIC?: string;
    BankCustomerUrl?: string;
    PriceListUrl?: string;
    HelpDeskUrl?: string;
    SupportPageUrl?: string;
    SystemDisplayName?: string;
    NoReplyEmailAddress?: string;
    SupportChatEnabled?: boolean;
    SupportChatEnabledForBureau?: boolean;
    AIChatBotEnabled?: boolean;
    ChatBotProvider?: number;
    FinancialInstitutionID?: string;
    BankHomePageUrl?: string;
    ValidationMethods?: ValidationMethod[];
    Departments?: { ID?: number; Identifier?: string }[];
}

export interface ValidationMethod {
    Name?: string;
}

const PUBLIC_ROOT_ROUTES = [
    'reload',
    'init',
    'bureau',
    'about',
    'assignments',
    'tickers',
    'uniqueries',
    'sharings',
    'marketplace',
    'predefined-descriptions',
    'gdpr',
    'contract-activation',
    'license-info',
    'bankproducts',
    'illustrations',
];

const PUBLIC_ROUTES = ['/settings/user'];

@Injectable()
export class AuthService {
    userManager: UserManager;

    companyChange: EventEmitter<Company> = new EventEmitter();
    onCompanyLoaded: EventEmitter<Company> = new EventEmitter();

    tryDemoEventSubject$: Subject<void> = new Subject<void>();

    authentication$ = new ReplaySubject<IAuthDetails>(1);
    token$ = new ReplaySubject<string>(1);
    publicSettingsLoaded$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    loginErrorMessage: string;

    jwt: string;
    id_token: string;
    activeCompany: Company;
    currentUser: CurrentUserWithout2FADetails;
    contractID: number;
    publicSettings: PublicWebSettings;
    platforms: string;

    private userObservable: Observable<CurrentUserWithout2FADetails>;

    // Re-implementing a subset of BrowserStorageService here to prevent circular dependencies
    private storage = {
        saveOnUser: (key, value) => {
            if (value === undefined) {
                throw new Error(
                    'Tried to marshal undefined into a JSON string, failing to prevent corrupt localStorage',
                );
            }
            localStorage.setItem(key, JSON.stringify(value));
        },
        getOnUser: (key) => {
            try {
                return JSON.parse(localStorage.getItem(key));
            } catch (e) {
                localStorage.removeItem(key);
                return null;
            }
        },
        removeOnUser: (key) => localStorage.removeItem(key),
    };

    constructor(
        private router: Router,
        private http: HttpClient,
        private featurePermissionService: FeaturePermissionService,
    ) {
        this.activeCompany = this.storage.getOnUser('activeCompany');

        this.setLoadIndicatorVisibility(true);

        this.userManager = this.getUserManager();

        this.checkUrlForLoginHints();

        this.userManager
            .signinSilent()
            .then((user) => {
                this.id_token = user.id_token;
                this.jwt = user.access_token;
                this.platforms = user.profile.platforms;
                this.token$.next(this.jwt);
                this.storage.saveOnUser('jwt', this.jwt);

                // Subscribe to userLoaded event to update variables on token renews
                this.userManager.events.addUserLoaded((newUser) => {
                    this.userManager.clearStaleState();
                    this.id_token = newUser.id_token;
                    this.jwt = newUser.access_token;
                    this.platforms = newUser.profile.platforms;
                    this.token$.next(this.jwt);
                    this.storage.saveOnUser('jwt', this.jwt);
                });

                if (this.activeCompany) {
                    this.loadCurrentSession().subscribe(
                        () => {
                            // Give the app a bit of time to initialize before we remove spinner
                            // (less visual noise on startup)
                            setTimeout(() => {
                                this.setLoadIndicatorVisibility(false);
                            }, 250);
                        },
                        () => {
                            this.activeCompany = undefined;
                            this.storage.removeOnUser('activeCompany');
                            if (!this.router.url.startsWith('/init')) {
                                this.router.navigate(['/init/login']);
                            }

                            this.setLoadIndicatorVisibility(false);
                        },
                    );
                } else {
                    if (!this.router.url.startsWith('/init')) {
                        this.router.navigate(['/init/login']);
                    }

                    this.setLoadIndicatorVisibility(false);
                }
            })
            .catch(() => {
                this.token$.next(undefined);
                this.authentication$.next({
                    activeCompany: undefined,
                    user: undefined,
                    hasActiveContract: false,
                    hasActiveUserLicense: false,
                    hasActiveCompanyLicense: false,
                });

                this.clearAuthAndGotoLogin();
                this.setLoadIndicatorVisibility(false);
            });

        this.userManager.events.addUserSignedOut(() => {
            this.token$.next(undefined);
            this.userManager.removeUser().then(() => {
                this.cleanStorageAndRedirect();
                this.setLoadIndicatorVisibility(false);
            });
        });
    }

    private checkUrlForLoginHints() {
        let acrValues: string;
        let loginHint: string;

        const paramStr = window.location.href.split('?')[1] || '';

        for (const param of paramStr.split('&')) {
            const [key, value] = param.split('=');

            if (key === 'acr_values' && value) {
                acrValues = value;
            } else if (key === 'login_hint' && value) {
                loginHint = value;
            }
        }

        if (acrValues || loginHint) {
            this.authenticate({
                acr_values: acrValues,
                login_hint: loginHint,
            });
        }
    }

    setLoadIndicatorVisibility(visible: boolean, isLogout = false) {
        const spinner = document.getElementById('app-spinner');
        if (spinner) {
            if (visible) {
                spinner.style.opacity = '1';
                spinner.style.display = 'flex';
                const textElement = document.getElementById('app-spinner-text');
                if (textElement) {
                    textElement.innerText = isLogout ? 'Logger ut' : 'Laster selskapsdata';
                }
            } else {
                let opacity = 1;
                const interval = setInterval(() => {
                    if (opacity <= 0.2) {
                        spinner.style.display = 'none';
                        clearInterval(interval);
                    } else {
                        spinner.style.opacity = opacity.toString();
                        opacity = opacity - 0.2;
                    }
                }, 50);
            }
        }

        // #chat-container is added by boost, #launcher is added by zendesk.
        // They wont show up if you do a project wide search for them. Dont remove this :)
        const chatBot = document.getElementById('chat-container') ?? document.getElementById('launcher');
        if (chatBot) {
            // if authenticated, we go by the spinners visibility, else we just hide
            this.isAuthenticated().then((isAuthenticated) => {
                if (isAuthenticated) {
                    chatBot.style.visibility = visible ? 'hidden' : 'visible';
                } else {
                    chatBot.style.visibility = 'hidden';
                }
            });
        }
    }

    private getSettings(): any {
        const baseUrl = window.location.origin;
        const responseType = environment.usePKCE ? 'code' : 'id_token token';

        const settings: any = {
            authority: environment.authority,
            client_id: environment.client_id,
            redirect_uri: baseUrl + '/assets/auth.html',
            silent_redirect_uri: baseUrl + '/assets/silent-renew.html',
            post_logout_redirect_uri: baseUrl + environment.post_logout_redirect_uri,
            response_type: responseType,
            scope: 'profile openid AppFramework',
            filterProtocolClaims: true,
            loadUserInfo: false,
            automaticSilentRenew: true, // not recommended by lib author
            accessTokenExpiringNotificationTime: 300, // 5 minute
            silentRequestTimeout: 20000, // 20 seconds
            userStore: new WebStorageStateStore({ store: window.localStorage }),
            includeIdTokenInSilentRenew: false,
        };
        return settings;
    }

    private getUserManager(): UserManager {
        const settings = this.getSettings();
        return new UserManager(settings);
    }

    public authenticate(params?: { [key: string]: any }): void {
        this.userManager.signinRedirect(params);
    }

    public async hardResetAuthenticateWithIdp(): Promise<void> {
        localStorage.setItem('redirectLoginIdp', 'bankiddnbsso');
        this.authentication$.next({
            activeCompany: undefined,
            user: undefined,
            hasActiveContract: false,
            hasActiveUserLicense: false,
            hasActiveCompanyLicense: false,
        });
        this.activeCompany = undefined;
        this.jwt = undefined;
        this.platforms = undefined;
        this.token$.next(undefined);
        const req = await this.userManager.createSignoutRequest({ id_token_hint: this.id_token });
        document.getElementById('silentLogout').setAttribute('src', req.url);
    }

    /**
     * Redirect user with preferred idp
     * @param {string} idp - Set the preferred identity provider
     * @param {boolean} forceLogin - Set to true if you want to re-authenticate the user. (usefull for testing)
     */
    public authenticateWithIdp(idp: string, forceLogin: boolean = false, contractActivation: boolean = false): void {
        const args: any = {
            acr_values: `idp:${idp}`,
            ...(forceLogin ? { prompt: 'login' } : null),
        };

        if (contractActivation) {
            args.data = {
                contractActivation: 'true',
            };
        }

        this.userManager.signinRedirect(args);
    }

    public authenticateWithoutIdp(): void {
        this.userManager.signinRedirect({ acr_values: `only_local:true` });
    }

    public authenticateNewInvite(idphint: string, inviteCode: string, email?: string): void {
        const acr_email = email ? ` email:${email}` : '';
        this.userManager.signinRedirect({
            acr_values: `idp:${idphint} sign_up:true invite:true${acr_email}`,
            data: { inviteCode },
        });
    }

    /**
     * Sets the current active company
     * @param {Object} activeCompany
     */
    public setActiveCompany(activeCompany: Company, redirectUrl?: string): void {
        let redirect = redirectUrl;
        if (!redirect) {
            redirect = this.getSafeRoute(this.router.url);
        }

        this.router
            // Do not run the component's canDeactivate function when changing company!
            // Some of our views have timing issues that caused data to be saved in the wrong company when doing this..
            .navigateByUrl('/reload?skipCanDeactivateCheck=true', { skipLocationChange: true })
            .then((navigationSuccess) => {
                if (navigationSuccess) {
                    this.setLoadIndicatorVisibility(true);
                    this.storage.saveOnUser('activeCompany', activeCompany);
                    this.storage.saveOnUser('lastActiveCompanyKey', activeCompany.Key);

                    this.activeCompany = activeCompany;
                    this.companyChange.emit(activeCompany);

                    this.loadCurrentSession(true).subscribe(
                        (authDetails) => {
                            this.loginErrorMessage = undefined;

                            const forcedRedirect = this.getForcedRedirect(authDetails);
                            if (forcedRedirect) {
                                redirect = forcedRedirect;
                            }

                            setTimeout(() => {
                                this.router.navigateByUrl(redirect || '').then((success) => {
                                    if (!success) {
                                        this.router.navigateByUrl('/');
                                    }
                                });
                                this.setLoadIndicatorVisibility(false);
                                this.onCompanyLoaded.emit(activeCompany);
                            });
                        },
                        () => {
                            this.isAuthenticated().then((isAuthenticated) => {
                                if (!isAuthenticated) {
                                    this.storage.removeOnUser('lastActiveCompanyKey');
                                    this.idsLogout();
                                } else {
                                    this.setLoadIndicatorVisibility(false);
                                    this.storage.removeOnUser('lastActiveCompanyKey');
                                    this.loginErrorMessage =
                                        'Klarte ikke hente selskapsdata for dette firma. Prøv igjen senere';
                                    this.router.navigateByUrl('/init/login');
                                }
                            });
                        },
                    );
                }
            });
    }

    private getForcedRedirect(authDetails: IAuthDetails) {
        const permissions = authDetails.user['Permissions'] || [];

        if (authDetails.user && authDetails.isDemo && !authDetails.hasActiveContract) {
            return 'contract-activation';
        }

        if (permissions.length === 1 && permissions[0] === 'ui_approval_accounting') {
            return '/assignments/approvals';
        }
    }

    private getSafeRoute(url: string): string {
        let safeUrl = url.split('?')[0];
        safeUrl = safeUrl.split(';')[0];

        const split = safeUrl.split('/').filter((part) => !!part);
        if (split.length) {
            const paramIndex = split.findIndex((part) => !isNaN(parseInt(part, 0)));
            if (paramIndex > 0) {
                safeUrl = split.slice(0, paramIndex).join('/');
            }
        }

        return safeUrl || '';
    }

    refreshToken() {
        return this.userManager
            .signinSilent()
            .then((user) => {
                this.id_token = user.id_token;
                this.jwt = user.access_token;
                this.platforms = user.profile.platforms;
                this.token$.next(this.jwt);
                this.storage.saveOnUser('jwt', this.jwt);
                return true;
            })
            .catch(() => {
                return false;
            });
    }

    invalidateUserCache() {
        this.userObservable = undefined;
    }

    getCurrentUser(): Observable<CurrentUserWithout2FADetails> {
        if (!this.userObservable) {
            const url = (environment.BASE_URL + '/api/biz/users?action=current-session&2fainfo=false').replace(
                /([^:]\/)\/+/g,
                '$1',
            );
            this.userObservable = this.http
                .get<CurrentUserWithout2FADetails>(url, {
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                        Authorization: `Bearer ${this.jwt}`,
                        CompanyKey: this.activeCompany.Key,
                    },
                })
                .pipe(shareReplay(1));
        }

        return this.userObservable;
    }

    loadCurrentSession(clearCachedUser: boolean = false): Observable<IAuthDetails> {
        if (clearCachedUser) {
            this.invalidateUserCache();
        }

        return this.getCurrentUser().pipe(
            switchMap((user: CurrentUserSession) => {
                this.currentUser = user;
                this.contractID = user?.License?.Company?.ContractID;

                const isSupportUser = user.License?.UserType?.TypeName === 'Support';
                const isAccountant =
                    user.License?.CustomerInfo?.IsRoamingUser || user.License?.UserType?.TypeName === 'Accountant';
                const contract: ContractLicenseType = user.License?.ContractType;
                const isDemo = contract?.TypeName === 'Demo';

                const hasPackageSelector =
                    (isSupportUser || isAccountant || isDemo) &&
                    (theme.theme === THEMES.EXT02 || theme.theme === THEMES.SR);

                this.featurePermissionService.activatePackage(contract.TypeName, hasPackageSelector);

                const authDetails = {
                    token: this.jwt,
                    activeCompany: this.activeCompany,
                    user: user,
                    hasActiveContract: !this.isContractExpired(contract),
                    hasActiveUserLicense: !this.isUserLicenseExpired(user),
                    hasActiveCompanyLicense: !this.isCompanyLicenseExpired(user),
                    isDemo: isDemo,
                };

                this.authentication$.next(authDetails);
                return of(authDetails);
            }),
        );
    }

    /**
     * Returns the current active companykey string
     */
    public getCompanyKey(): string {
        return this.activeCompany?.Key;
    }

    /**
     * Returns a boolean indicating whether the user is authenticated or not
     * @returns {Boolean}
     */
    public isAuthenticated(): Promise<any> {
        return new Promise((resolve) => {
            this.userManager
                .getUser()
                .then((user) => {
                    if (user && !user.expired && !!user.access_token) {
                        this.jwt = user.access_token;
                        this.platforms = user.profile.platforms;
                        resolve(user);
                    } else {
                        resolve(false);
                    }
                })
                .catch(() => resolve(false));
        });
    }

    signoutRedirect() {
        if (this.userManager) {
            this.userManager.signoutRedirect();
        }
    }

    /**
     * Removes web token from localStorage and memory, then redirects to /login
     */
    public clearAuthAndGotoLogin(): void {
        this.authentication$.pipe(take(1)).subscribe((auth) => {
            let cleanTokens: boolean = false;
            if (auth && auth.user) {
                cleanTokens = true;
                this.authentication$.next({
                    activeCompany: undefined,
                    user: undefined,
                    hasActiveContract: false,
                    hasActiveUserLicense: false,
                    hasActiveCompanyLicense: false,
                });

                this.idsLogout();
            }
            if (!cleanTokens) {
                this.cleanStorageAndRedirect();
            }
        });
    }

    idsLogout() {
        this.setLoadIndicatorVisibility(true, true);

        this.userManager.createSignoutRequest({ id_token_hint: this.id_token }).then((req) => {
            document.getElementById('silentLogout').setAttribute('src', req.url);
        });
    }

    public cleanStorageAndRedirect() {
        this.storage.removeOnUser('activeCompany');
        this.storage.removeOnUser('activeFinancialYear');
        this.activeCompany = undefined;
        this.jwt = undefined;
        this.platforms = undefined;
        this.token$.next(undefined);
        this.setLoadIndicatorVisibility(false);

        if (!this.router.url.includes('init')) {
            this.router.navigate(['/init/login']);
        }
    }

    public canActivateRoute(user: CurrentUserWithout2FADetails, url: string): boolean {
        // First check if the route is a public route
        if (PUBLIC_ROUTES.some((route) => route === url)) {
            return true;
        }

        const rootRoute = this.getRootRoute(url);
        const permissionKey: string = this.getPermissionKey(url);

        if (!this.featurePermissionService.canShowRoute(permissionKey)) {
            return false;
        }

        if (!rootRoute || PUBLIC_ROOT_ROUTES.some((route) => route === rootRoute)) {
            return true;
        }

        if (!user) {
            return false;
        }

        return this.hasUIPermission(user, permissionKey);
    }

    public hasUIPermission(user: CurrentUserWithout2FADetails, permission: string) {
        if (!user) {
            return false;
        }

        const permissions = user['Permissions'] || [];

        // Interpret no permissions as full access if PermissionHandling is set to SOFT
        if (
            !permissions.length &&
            user['PermissionHandling'] === 'SOFT' &&
            permission !== 'ui_accounting_annual-settlement'
        ) {
            return true;
        }

        permission = permission.trim();

        // Check for direct match
        let hasPermission = permissions.some((p) => p === permission);

        // Pop permission parts and check for * access
        if (!hasPermission) {
            const permissionSplit = permission.split('_');

            while (permissionSplit.length && !hasPermission) {
                const multiPermision = permissionSplit.join('_') + '_*';
                if (permissions.some((p) => p === multiPermision)) {
                    hasPermission = true;
                }

                permissionSplit.pop();
            }
        }

        return hasPermission;
    }

    private getRootRoute(url): string {
        const noParams = url.split('?')[0];
        let routeParts = noParams.split('/');
        routeParts = routeParts.filter((part) => part !== '');

        return routeParts[0];
    }

    private getPermissionKey(url: string): string {
        if (!url) {
            return '';
        }

        // Remove query params first
        let noQueryParams = url.split('?')[0];
        noQueryParams = noQueryParams.split(';')[0];

        let urlParts = noQueryParams.split('/');
        urlParts = urlParts.filter((part) => {
            // Remove empty url parts and numeric url parts (ID params)
            return part !== '' && isNaN(parseInt(part, 10));
        });

        return 'ui_' + urlParts.join('_');
    }

    private isContractExpired(contract: ContractLicenseType): boolean {
        const trialExpiration = rigDate(contract.TrialExpiration);
        if (trialExpiration.isValid() && trialExpiration.isBefore(rigDate(), 'day')) {
            return true;
        }

        return false;
    }

    private isUserLicenseExpired(user: CurrentUserWithout2FADetails): boolean {
        const userLicenseEndDate = rigDate(user.License.UserLicenseEndDate);
        if (userLicenseEndDate.isValid() && userLicenseEndDate.isBefore(rigDate(), 'day')) {
            return true;
        }

        return false;
    }

    private isCompanyLicenseExpired(user: CurrentUserWithout2FADetails): boolean {
        const companyLicenseEndDate = rigDate(user.License.Company.EndDate);
        if (companyLicenseEndDate.isValid() && companyLicenseEndDate.isBefore(rigDate(), 'day')) {
            return true;
        }

        return false;
    }

    getPublicSettings() {
        const endpoint = environment.ELSA_SERVER_URL + '/api/licenseservicesettings/public-web-settings';
        this.http
            .get<PublicWebSettings>(endpoint)
            .pipe(map((res) => res[0]))
            .subscribe({
                next: (settings) => {
                    this.publicSettings = settings;
                    this.publicSettingsLoaded$.next(true);
                },
                error: () => {},
            });
    }

    getClientIdpRestrictions(): Observable<string[]> {
        return this.http
            .get<string[]>(environment.authority + '/External/ProviderList?clientid=' + environment.client_id)
            .pipe(catchError(() => []));
    }

    signUpBankID(email?: string, isProspect = false, data?: object, bankidIdp?: string) {
        const idp = theme.theme === THEMES.SR ? 'sb1bankid' : bankidIdp;
        let acr_values = `sign_up:true idp:${idp} origin:${encodeURIComponent(window.location.origin)}`;

        if (email) {
            const formattedEmail = btoa(email.trim().replace(' ', '+'));
            acr_values += ` email:${formattedEmail}`;
        }

        if (isProspect) {
            acr_values += ` invite:true`;
        }

        this.userManager.signinRedirect({ acr_values, data });
    }

    decodedToken(): any {
        if (this.jwt) {
            const base64Url = this.jwt.split('.')[1];
            const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            const jsonPayload = decodeURIComponent(
                atob(base64)
                    .split('')
                    .map(function (c) {
                        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                    })
                    .join(''),
            );

            return JSON.parse(jsonPayload);
        }

        return null;
    }

    /**
     * Returns the global identity set on the current token
     *
     * Only use this if currentUser is unavailable, such as on /init routes
     */
    getGlobalIdentityFromToken(): string {
        return this.decodedToken()?.sub || null;
    }
}
