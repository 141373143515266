import { NgModule, Component, ChangeDetectionStrategy, Input, ElementRef, HostBinding } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'uni-illustration-deprecated',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./illustration-deprecated.sass'],
    template: ``,
})
export class IllustrationDeprecated {
    /*
        If you pass name to the component it will try to load "/config/themes/shared/<name>.svg".

        If you want to load a specific path you can use the path input instead.
        E.g path="/config/dist/themes/assets/my-illustration.svg"

        Make sure the specified path works in all themes the host component is available for.
     */
    @Input() name: string;
    @Input() path: string;

    lastLoadedPath: string;

    constructor(
        private elementRef: ElementRef,
        private httpClient: HttpClient,
    ) {}

    ngOnChanges() {
        const path = this.path || (this.name && `/config/themes/shared/${this.name}.svg`);

        if (this.elementRef?.nativeElement && path !== this.lastLoadedPath) {
            this.lastLoadedPath = path;

            this.httpClient.get(path, { responseType: 'text' }).subscribe(
                (res) => {
                    const tempWrapper = document.createElement('div');
                    tempWrapper.innerHTML = res || '';

                    const svg = tempWrapper.querySelector('svg') as SVGElement;
                    if (svg) {
                        this.elementRef.nativeElement?.appendChild(svg);
                    }
                },
                (err) => console.error(err),
            );
        }
    }
}

@NgModule({
    imports: [CommonModule],
    declarations: [IllustrationDeprecated],
    exports: [IllustrationDeprecated],
})
export class DeprecatedIllustrationModule {}
