import { Component, Input } from '@angular/core';
import { CMSWidgetData } from '@app/services/cms/cms-service';
import { theme } from 'src/themes/theme';

@Component({
    selector: 'cms-illustration-v2',
    template: `<img *ngIf="src" [alt]="item?.mediaField?.altText" [src]="src" /> `,
    styleUrls: ['./illustration-v2.sass'],
})
export class CMSIllustrationV2 {
    @Input() item: any;
    src: string = '';

    ngOnInit() {
        if (!theme.sanityImageBaseUrl || !this.item) {
            return;
        }

        this.src = this.buildUrlSrc(this.item);
    }

    buildUrlSrc(item: CMSWidgetData): string {
        const nameComponents: string[] = item?.mediaField?.asset?._ref.split('-');

        if (nameComponents?.length && nameComponents[0] === 'image') {
            nameComponents.shift();

            const fileExtension = nameComponents.pop();

            return theme.sanityImageBaseUrl + nameComponents.join('-') + `.${fileExtension}`;
        }

        return '';
    }
}
