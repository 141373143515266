<mat-radio-group [(value)]="initValue" (change)="onChange($event)" [class.horizontal]="field?.Options?.horizontal">
    <mat-radio-button
        *ngFor="let item of items"
        [value]="getRadioButtonValue(item)"
        [disabled]="readOnly$ | async"
        [checked]="getRadioButtonValue(item) === checkedValue"
        (focusin)="focusHandler()"
    >
        {{ getRadioButtonLabel(item) }}
    </mat-radio-button>
</mat-radio-group>
