<section role="dialog" class="uni-modal">
    <header>Aktiver EHF mottak</header>
    <article>
        <section *ngIf="busy" class="modal-spinner">
            <mat-spinner class="c2a"></mat-spinner>
        </section>

        <section *ngIf="ehfProduct" class="alert info">
            Vi registrer deg i ELMA-registeret slik at du automatisk kan motta elektroniske fakturaer, ferdig
            registrert, rett i regnskapssystemet ditt. Tjenesten vil være klar til bruk innen 24 timer fra aktivering.
        </section>

        <uni-form
            class="two-column-layout"
            [config]="{ showLabelAbove: true, autofocus: true }"
            [fields]="formFields$"
            [model]="formModel$"
        >
        </uni-form>

        <small *ngIf="invalidFormSubmitted" class="ehf-form-error color-bad">
            *Alle feltene er påkrevd. Sjekk at info stemmer
        </small>

        <rig-checkbox *ngIf="terms" [(ngModel)]="termsAgreed">
            Jeg har lest og godtar
            <a (click)="$event.preventDefault(); confirmTerms()">avtalen</a>.
        </rig-checkbox>
    </article>

    <footer>
        <button class="secondary" (click)="close()">Avbryt</button>

        <button class="c2a" (click)="activate()" [disabled]="!termsAgreed && terms">Aktiver EHF-mottak</button>
    </footer>
</section>
