<section role="dialog" class="uni-modal">
    <header>
        Produkttilganger
        <span *ngIf="singleProductMode && products?.length"> - {{ products[0]?.Label }}</span>
    </header>

    <article>
        <section *ngIf="busy" class="modal-spinner">
            <mat-spinner class="c2a"></mat-spinner>
        </section>

        <small>
            Når du aktiverer et produkt for en bruker vil de få en standard rolle basert på produktet.
            <br />
            Justering av tilgangsnivå gjøres i Innstillinger > Brukere, ved å klikke på "Roller og tilganger".
        </small>

        <ng-container *ngIf="singleProductMode && purchasesMatrix">
            <input type="search" [formControl]="filterControl" placeholder="Filtrer" style="width: 17rem" />

            <section class="check-all flex pointer mt-2 border-bottom">
                <rig-checkbox [(ngModel)]="checkAll" (change)="onCheckAllChange()">
                    Velg alle <strong *ngIf="purchasesMatrix?.length">({{ purchasesMatrix?.length }})</strong>
                </rig-checkbox>
            </section>

            <section class="check-list">
                <rig-checkbox
                    *ngFor="let entry of purchasesMatrix"
                    [checked]="entry.purchases[0]?._active"
                    (change)="togglePurchase(entry.purchases[0])"
                >
                    {{ entry.userDisplayName }}
                </rig-checkbox>
            </section>
        </ng-container>

        <table *ngIf="!singleProductMode && purchasesMatrix" class="purchases-matrix">
            <thead>
                <tr>
                    <th>Brukere</th>
                    <th *ngFor="let product of products">
                        {{ product.Label }}
                    </th>
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let entry of purchasesMatrix">
                    <td>{{ entry.userDisplayName }}</td>
                    <td *ngFor="let purchase of entry.purchases">
                        <i
                            class="material-icons"
                            [ngClass]="{ active: purchase['_active'] }"
                            (click)="togglePurchase(purchase)"
                        >
                            {{ purchase['_active'] ? 'check_box' : 'check_box_outline_blank' }}
                        </i>
                    </td>
                </tr>
            </tbody>
        </table>
    </article>

    <footer>
        <button class="secondary" (click)="onClose.emit()">Avbryt</button>
        <button class="c2a" (click)="save()">Lagre endringer</button>
    </footer>
</section>
