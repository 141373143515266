import { Pipe, PipeTransform } from '@angular/core';
import { memoize } from 'lodash-es';
import { IColumnTooltip } from './config/unitableColumn';

@Pipe({
    name: 'columnTooltipPipe',
    pure: true,
})
export class ColumnTooltipPipe implements PipeTransform {
    private getTooltip = memoize((rowModel, column) => {
        if (column.get('tooltipResolver')) {
            return column.get('tooltipResolver')(rowModel.toJS());
        }
    });

    public transform(column, rowModel, outputType: string) {
        const tooltip: IColumnTooltip = this.getTooltip(rowModel, column);
        if (outputType === 'cssClass') {
            return tooltip && `${tooltip.type}`;
        } else {
            return tooltip && tooltip.text;
        }
    }
}
