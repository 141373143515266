import {
    Component,
    Input,
    Output,
    EventEmitter,
    ChangeDetectionStrategy,
    SimpleChanges,
    OnChanges,
    SimpleChange,
    ViewChild,
    ElementRef,
} from '@angular/core';
import { UniFieldLayout } from '../../interfaces';
import { set, get } from 'lodash-es';
import { NumberFormat } from '@app/services/common/numberFormatService';

@Component({
    selector: 'uni-bankaccount-input',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './bankaccount.html',
})
export class UniBankAccountInput implements OnChanges {
    @Input() public field: UniFieldLayout;
    @Input() public model: any;
    @Input() public asideGuid: string;

    @Output() public changeEvent: EventEmitter<SimpleChanges> = new EventEmitter<SimpleChanges>();
    @Output() public focusEvent: EventEmitter<UniBankAccountInput> = new EventEmitter<UniBankAccountInput>(true);
    @Output() public readyEvent = new EventEmitter();

    @ViewChild('input') private inputElement: ElementRef;

    modelValue: string;
    inputValue: string;
    readOnly: boolean;

    constructor(private numberFormat: NumberFormat) {}

    ngOnChanges(changes) {
        if (changes['model'] || changes['field']) {
            this.modelValue = get(this.model, this.field.Property); // import {get} from 'lodash-es';
            this.inputValue = this.format(this.modelValue);
            this.readOnly = this.field.ReadOnly;
        }
    }

    ngAfterViewInit() {
        this.readyEvent.emit(this);
    }

    onBlur() {
        this.readOnly = this.field.ReadOnly;

        const newValue = this.parse(this.inputValue);

        if (newValue !== this.modelValue) {
            set(this.model, this.field.Property, newValue);
            const changes = {
                [this.field.Property]: new SimpleChange(this.modelValue, newValue, false),
            };
            this.changeEvent.next(changes);
        }

        this.modelValue = get(this.model, this.field.Property);
        const format = this.format(this.modelValue);

        if (format !== this.inputValue) {
            this.inputValue = format;
        }
    }

    focus() {
        const input: HTMLInputElement = this.inputElement.nativeElement;
        if (input) {
            input.focus();
            input.select();
        }
    }

    onFocus() {
        this.focusEvent.emit(this);
    }

    format(value: string): string {
        return this.numberFormat.asBankAcct(value);
    }

    parse(value: string): string {
        return value.replace(/[\s.-]/g, '');
    }
}
