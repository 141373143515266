import {
    Component,
    Input,
    Output,
    EventEmitter,
    ChangeDetectionStrategy,
    ViewChild,
    ElementRef,
    OnChanges,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { get } from 'lodash-es';
import { UniFieldLayout } from '../../interfaces';
import { BaseControl } from '../baseControl';

@Component({
    selector: 'uni-hyperlink-input',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './hyperlink.html',
})
export class UniHyperlinkInput extends BaseControl implements OnChanges {
    @Input() public field: UniFieldLayout;
    @Input() public model: any;
    @Input() public control: UntypedFormControl;
    @Input() public asideGuid: string;

    @Output() public readyEvent = new EventEmitter<UniHyperlinkInput>(true);
    @Output() public focusEvent = new EventEmitter<UniHyperlinkInput>(true);
    @ViewChild('element', { static: true }) public input: ElementRef;

    constructor() {
        super();
    }

    public ngOnChanges() {
        this.createControl();
    }

    public focus() {
        this.input.nativeElement.focus();
        return this;
    }

    public getValue() {
        const value = get(this.model, this.field.Property);
        return value || '#';
    }
}
