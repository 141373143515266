import { refCount, publishReplay, map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { UniHttp } from '../../../framework/core/http/http';
import { BizHttp } from '../../../framework/core/http/BizHttp';
import { CampaignTemplate } from '@uni-entities';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CampaignTemplateService extends BizHttp<CampaignTemplate> {
    private requestCache: { [hash: number]: Observable<any> } = {};

    constructor(http: UniHttp) {
        super(http);

        this.relativeURL = CampaignTemplate.RelativeUrl;
        this.entityType = CampaignTemplate.EntityType;
        this.DefaultOrderBy = 'ID';
    }

    getTemplateText() {
        const hash = this.hashFnv32a(this.relativeURL);

        if (!this.requestCache[hash]) {
            this.requestCache[hash] = this.http
                .asGET()
                .usingBusinessDomain()
                .withEndPoint(this.relativeURL)
                .send()
                .pipe(
                    map((res) => res.body),
                    publishReplay(1),
                    refCount(),
                );
        }
        return this.requestCache[hash];
    }

    getTemplateData(templateID: number, dataID: number) {
        return this.GetAction(templateID, 'gettemplatedata', `id=${templateID}&dataId=${dataID}`);
    }

    parseEmailTemplate(templateID: number, dataID: number) {
        return this.GetAction(templateID, 'parseemailtemplate', `id=${templateID}&dataId=${dataID}`);
    }

    public clearCache() {
        this.requestCache = {};
    }
}
