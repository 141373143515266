import { Component, EventEmitter } from '@angular/core';
import { forkJoin, BehaviorSubject } from 'rxjs';
import { take, finalize } from 'rxjs/operators';
import { UniFieldLayout, FieldType } from '../../ui/uniform/index';
import { ToastService, ToastType } from '../../uniToast/toastService';
import { CompanySettings, BankAccount } from '../../../../src/app/unientities';
import { ActivateAP } from '../../../../src/app/models/activateAP';
import { ActivationEnum } from '../../../../src/app/models/activationEnum';
import { UniModalService } from '../modalService';
import { ConfirmActions, IModalOptions, IUniModal } from '@uni-framework/uni-modal/interfaces';
import { UniBankAccountModal } from '@uni-framework/uni-modal/modals/bankAccountModal';
import { ElsaAgreement, ElsaAgreementStatus } from '@app/models';
import { BankAccountService } from '@app/services/accounting/bankAccountService';
import { CompanySettingsService } from '@app/services/common/companySettingsService';
import { EHFService } from '@app/services/common/EHFService';
import { ErrorService } from '@app/services/common/errorService';
import { UserService } from '@app/services/common/userService';
import { ElsaProductService } from '@app/services/elsa/elsaProductService';
import { CurrentUserWithout2FADetails } from '@app/authService';

@Component({
    selector: 'uni-activate-invoiceprint-modal',
    template: `
        <section role="dialog" class="uni-modal">
            <header>{{ options.header || 'Aktiver Fakturaprint' }}</header>
            <section *ngIf="busy" class="modal-spinner">
                <mat-spinner class="c2a"></mat-spinner>
            </section>
            <article>
                <uni-form [config]="{ autofocus: true }" [fields]="formFields$" [model]="formModel$"> </uni-form>

                <rig-checkbox *ngIf="terms" [(ngModel)]="termsAgreed" class="d-block mt-2">
                    Jeg har lest og forstått <a (click)="$event.preventDefault(); viewTerms()">avtalen</a>
                </rig-checkbox>
            </article>

            <footer>
                <button class="secondary" (click)="close()">Avbryt</button>

                <button class="c2a" (click)="activate()" [disabled]="!termsAgreed && terms">Aktiver</button>
            </footer>
        </section>
    `,
})
export class UniActivateInvoicePrintModal implements IUniModal {
    options: IModalOptions = {};
    modalService: UniModalService;

    onClose = new EventEmitter();

    formModel$ = new BehaviorSubject<ActivateAP>(null);
    formFields$ = new BehaviorSubject<UniFieldLayout[]>([]);

    terms: ElsaAgreement;
    termsAgreed: boolean;
    busy = false;

    constructor(
        private ehfService: EHFService,
        private companySettingsService: CompanySettingsService,
        private userService: UserService,
        private errorService: ErrorService,
        private toastService: ToastService,
        private bankaccountService: BankAccountService,
        private elsaProductService: ElsaProductService,
    ) {}

    ngOnInit() {
        this.busy = true;
        const filter = `name eq 'invoiceprint'`;
        this.elsaProductService
            .GetAll(filter)
            .pipe(
                finalize(() => {
                    this.busy = false;
                    this.initialize();
                }),
            )
            .subscribe((product) => {
                if (product[0]?.ProductAgreement?.AgreementStatus === ElsaAgreementStatus.Active) {
                    this.terms = product[0].ProductAgreement;
                }
            });
    }

    ngOnDestroy() {
        this.formModel$.complete();
        this.formFields$.complete();
    }

    initialize() {
        this.formFields$.next(this.getFormFields());
        this.extendFormConfig();
        this.initActivationModel();

        this.options.cancelValue = ActivationEnum.NOT_ACTIVATED;
    }

    public initActivationModel() {
        forkJoin([
            this.userService.getCurrentUser(),
            this.companySettingsService.getCompanySettings([
                'DefaultPhone',
                'DefaultEmail',
                'APContact.Info.DefaultPhone',
                'APContact.Info.DefaultEmail',
                'BankAccounts',
                'CompanyBankAccount',
            ]),
        ]).subscribe(
            (res) => {
                const model = new ActivateAP();

                const user: CurrentUserWithout2FADetails = res[0];
                const settings: CompanySettings = res[1];
                const apContactInfo = settings && settings.APContact && settings.APContact.Info;

                model.orgnumber = settings.OrganizationNumber;
                model.orgname = settings.CompanyName;
                model.orgphone = settings.DefaultPhone && settings.DefaultPhone.Number;
                model.orgemail = settings.DefaultEmail && settings.DefaultEmail.EmailAddress;
                model.contactname = apContactInfo ? apContactInfo.Name : user.DisplayName;

                model.contactemail =
                    apContactInfo && apContactInfo.DefaultEmail ? apContactInfo.DefaultEmail.EmailAddress : user.Email;

                model.contactphone =
                    apContactInfo && apContactInfo.DefaultPhone && apContactInfo.DefaultPhone.Number
                        ? settings.APContact.Info.DefaultPhone.Number
                        : user.PhoneNumber;

                model.outgoingInvoicePrint = true;

                model.settings = settings;

                this.formModel$.next(model);
            },
            (err) => {
                this.errorService.handle(err);
            },
        );
    }

    public viewTerms() {
        this.modalService
            .confirm({
                header: this.terms.Name,
                message: this.terms.AgreementText,
                isMarkdown: true,
                class: 'medium',
                buttonLabels: {
                    accept: 'Aksepter',
                    cancel: 'Tilbake',
                },
            })
            .onClose.subscribe((response) => {
                if (response === ConfirmActions.ACCEPT) {
                    this.termsAgreed = true;
                }
            });
    }

    public activate() {
        const model = this.formModel$.getValue();
        // Save Bankaccount settings
        this.companySettingsService.Put(model.settings.ID, model.settings).subscribe(
            () => {
                // Activate InvoicePrint
                this.ehfService.activate('invoiceprint', model, 'out').subscribe(
                    (status) => {
                        if (status === ActivationEnum.ACTIVATED) {
                            this.toastService.addToast('Aktivering', ToastType.good, 3, 'Fakturaprint aktivert');
                        } else if (status === ActivationEnum.EXISTING) {
                            this.toastService.addToast(
                                'Aktivering på vent',
                                ToastType.warn,
                                10,
                                'Org.nr. er allerede aktivert, deaktiver nåværende løsning eller kontakt support.',
                            );
                        } else {
                            this.toastService.addToast(
                                'Aktivering feilet!',
                                ToastType.bad,
                                5,
                                'Noe gikk galt ved aktivering',
                            );
                        }

                        this.close(<any>status);
                    },
                    (err) => this.errorService.handle(err),
                );
            },
            (err) => this.errorService.handle(err),
        );
    }

    public close(activationCode = ActivationEnum.NOT_ACTIVATED) {
        this.onClose.emit(activationCode);
    }

    private extendFormConfig() {
        const fields = this.formFields$.getValue();

        const companyBankAccount: UniFieldLayout = fields.find((x) => x.Property === 'settings.CompanyBankAccount');
        companyBankAccount.Options = this.getBankAccountOptions('settings.CompanyBankAccount', 'company');
    }

    private getBankAccountOptions(storeResultInProperty, bankAccountType) {
        return {
            entity: BankAccount,
            listProperty: 'settings.BankAccounts',
            displayValue: 'AccountNumber',
            linkProperty: 'ID',
            storeResultInProperty: storeResultInProperty,
            storeIdInProperty: storeResultInProperty + 'ID',
            editor: (bankaccount: BankAccount) => {
                if (!bankaccount || !bankaccount.ID) {
                    bankaccount = bankaccount || new BankAccount();
                    bankaccount['_createguid'] = this.bankaccountService.getNewGuid();
                    bankaccount.BankAccountType = bankAccountType;
                    bankaccount.CompanySettingsID = this.formModel$.getValue().settings.ID;
                    bankaccount.ID = 0;
                }

                const modal = this.modalService.open(UniBankAccountModal, {
                    data: bankaccount,
                    modalConfig: {
                        ledgerAccountVisible: true,
                    },
                    closeOnClickOutside: false,
                });

                return modal.onClose.pipe(take(1)).toPromise();
            },
        };
    }

    private getFormFields(): UniFieldLayout[] {
        return [
            <any>{
                Property: 'orgnumber',
                Label: 'Organisasjonsnummer',
            },
            <any>{
                Property: 'orgname',
                Label: 'Firmanavn',
            },
            <any>{
                Property: 'contactemail',
                FieldType: FieldType.EMAIL,
                Label: 'Kontakt-e-post',
            },
            <any>{
                Property: 'settings.CompanyBankAccount',
                FieldType: FieldType.MULTIVALUE,
                Label: 'Driftskonto',
            },
        ];
    }
}
