<ng-container *ngIf="!loading && (supportArticle || wizard)">
    <button
        #toggleBtn
        (click)="onButtonClick()"
        class="help-button-container unstyled outline-outside"
        [trackClickEvents]="{ name: 'help-btn-click' }"
    >
        <i class="material-icons">support</i> Hjelp
    </button>

    <dropdown-menu
        *ngIf="supportArticle"
        #dropdownMenu
        [trigger]="toggleBtn"
        [closeOnClick]="false"
        [unstyled]="true"
        [overlayPositions]="dropdownPositions"
    >
        <ng-template>
            <section class="help-details-container">
                <span class="triangle"></span>
                <div class="body-container flex align-center gap-2 border-bottom">
                    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 74 60">
                        <path
                            d="M17.94 59.396c1.404 0 2.543-.423 2.543-.944 0-.522-1.139-.944-2.543-.944-1.405 0-2.543.422-2.543.944 0 .521 1.138.944 2.543.944ZM24.043 59.27c1.404 0 2.543-.423 2.543-.944 0-.522-1.139-.944-2.543-.944-1.404 0-2.543.422-2.543.944 0 .521 1.139.944 2.543.944ZM32.144 43.401c2.403-1.371 3.999-3.089 3.564-3.836-.435-.746-2.736-.24-5.138 1.131-2.404 1.372-3.999 3.09-3.564 3.836.435.747 2.735.24 5.138-1.13Z"
                            fill="#2F2E41"
                        />
                        <path
                            d="M29.889 46.764c.888-5.421-2.83-10.53-8.306-11.409-5.476-.88-10.635 2.802-11.524 8.224-.888 5.42 2.83 10.53 8.306 11.409 5.476.88 10.635-2.802 11.524-8.224Z"
                            fill="#2F2E41"
                        />
                        <path
                            d="M18.448 52.976h-3.051v5.413h3.051v-5.413ZM24.552 52.976H21.5v5.413h3.052v-5.413Z"
                            fill="#2F2E41"
                        />
                        <path
                            d="M20.228 46.052c1.896 0 3.433-1.521 3.433-3.399 0-1.877-1.537-3.398-3.433-3.398s-3.433 1.521-3.433 3.398c0 1.878 1.537 3.4 3.433 3.4Z"
                            fill="#fff"
                        />
                        <path
                            d="M20.228 43.786c.632 0 1.145-.507 1.145-1.133 0-.625-.513-1.132-1.145-1.132s-1.144.507-1.144 1.133c0 .625.512 1.132 1.144 1.132Z"
                            fill="#3F3D56"
                        />
                        <path
                            d="M10.23 35.923c-.81-3.597 1.782-7.23 5.791-8.117 4.01-.886 7.917 1.31 8.728 4.907.811 3.596-1.846 4.922-5.855 5.809-4.01.886-7.852.997-8.663-2.6Z"
                            fill="#EDAF91"
                            class="icon-hat-2"
                        />
                        <path
                            d="M13.198 42.688c.373-.78-1.356-2.365-3.862-3.542-2.506-1.176-4.84-1.499-5.213-.72-.373.779 1.356 2.364 3.862 3.541 2.506 1.177 4.84 1.5 5.213.72Z"
                            fill="#2F2E41"
                        />
                        <path
                            d="M15.04 48.759c0 .973 2.531 2.895 5.34 2.895 2.809 0 5.442-2.74 5.442-3.713 0-.974-2.633.189-5.442.189s-5.34-.344-5.34.629Z"
                            fill="#fff"
                        />
                        <path
                            d="M54.998 59.396c1.405 0 2.543-.423 2.543-.944 0-.522-1.138-.944-2.543-.944-1.404 0-2.543.422-2.543.944 0 .521 1.139.944 2.543.944ZM48.895 59.27c1.405 0 2.543-.423 2.543-.944 0-.522-1.138-.944-2.543-.944-1.404 0-2.543.422-2.543.944 0 .521 1.139.944 2.543.944ZM45.932 44.532c.434-.747-1.161-2.464-3.564-3.836-2.403-1.371-4.704-1.878-5.138-1.13-.435.746 1.16 2.463 3.563 3.835 2.403 1.372 4.704 1.878 5.139 1.131Z"
                            fill="#2F2E41"
                        />
                        <path
                            d="M52.964 55.116c5.547 0 10.044-4.453 10.044-9.945s-4.497-9.945-10.044-9.945c-5.547 0-10.045 4.453-10.045 9.945s4.498 9.945 10.045 9.945Z"
                            fill="#2F2E41"
                        />
                        <path
                            d="M57.541 52.976H54.49v5.413h3.051v-5.413ZM51.438 52.976h-3.051v5.413h3.051v-5.413Z"
                            fill="#2F2E41"
                        />
                        <path
                            d="M50.61 46.052c1.897 0 3.434-1.521 3.434-3.399 0-1.877-1.537-3.398-3.433-3.398s-3.433 1.521-3.433 3.398c0 1.878 1.537 3.4 3.433 3.4Z"
                            fill="#fff"
                        />
                        <path
                            d="M49.783 42.99c.449-.444.452-1.16.007-1.601a1.152 1.152 0 0 0-1.617.005 1.124 1.124 0 0 0-.007 1.6c.445.442 1.17.44 1.617-.004Z"
                            fill="#3F3D56"
                        />
                        <path
                            d="M54.044 38.522c-4.01-.886-6.666-2.213-5.855-5.81.81-3.595 4.718-5.792 8.727-4.906 4.01.887 6.602 4.52 5.791 8.117-.81 3.596-4.654 3.485-8.663 2.599Z"
                            fill="#007272"
                            class="icon-hat-1"
                        />
                        <path
                            d="M63.467 52.752c.842-.222.947-2.553.235-5.207s-1.972-4.626-2.813-4.405c-.842.221-.947 2.552-.235 5.206.712 2.655 1.972 4.627 2.813 4.406Z"
                            fill="#2F2E41"
                        />
                        <path
                            d="M50.688 48.13c-1.844 0-3.573-1.163-3.573-.19 0 .974 1.73 3.714 3.573 3.714 1.844 0 3.506-1.922 3.506-2.895 0-.973-1.662-.63-3.506-.63Z"
                            fill="#fff"
                        />
                        <path
                            d="M37.708 28.682h-.892a3.033 3.033 0 0 1-2.13-.875 2.973 2.973 0 0 1-.884-2.109v-.741c0-1.837.351-3.374 1.044-4.57a24.07 24.07 0 0 1 2.925-3.892c.734-.73 1.4-1.525 1.99-2.373.422-.66.64-1.427.627-2.208 0-1.843-1.212-2.738-3.706-2.738-1.383.015-2.758.22-4.085.607a3.472 3.472 0 0 1-3.015-.556 3.413 3.413 0 0 1-1.007-1.2 3.377 3.377 0 0 1-.36-1.518V5.008a3.316 3.316 0 0 1 .536-1.823 3.364 3.364 0 0 1 1.453-1.238 18.37 18.37 0 0 1 7.42-1.445c2.668 0 5.078.444 7.161 1.32 2.087.876 3.736 2.114 4.9 3.676a8.797 8.797 0 0 1 1.758 5.368c0 2.073-.572 3.88-1.7 5.367-1.115 1.474-2.714 3.086-4.752 4.794a33.725 33.725 0 0 0-3.046 2.842 5.23 5.23 0 0 0-1.267 2.335 2.97 2.97 0 0 1-1.027 1.778 3.024 3.024 0 0 1-1.943.7ZM37.212 42.715h-.118c-1.481 0-2.9-.584-3.948-1.62a5.503 5.503 0 0 1-1.634-3.91c0-1.467.587-2.873 1.634-3.91a5.614 5.614 0 0 1 3.948-1.62h.118a5.592 5.592 0 0 1 3.952 1.619 5.484 5.484 0 0 1 0 7.824 5.635 5.635 0 0 1-3.952 1.619Z"
                            fill="#9E78A8"
                            class="icon-question-mark"
                        />
                        <path
                            d="M15.026 20.392a1.5 1.5 0 0 0 1.508-1.493 1.5 1.5 0 0 0-1.508-1.493 1.5 1.5 0 0 0-1.508 1.493 1.5 1.5 0 0 0 1.508 1.493Z"
                            fill="#F2F2F2"
                        />
                    </svg>
                    <div class="content-container flex-column">
                        <strong>{{ supportArticle.Title }}</strong>
                        <span [innerHtml]="supportArticle.Description"></span>
                    </div>
                </div>
                <div class="footer-container flex align-center">
                    <a *ngIf="!!supportArticle?.ArticleUrl" [href]="supportArticle?.ArticleUrl" target="_blank">
                        {{ supportArticle?.ArticleTitle || 'Les videre her' }}
                    </a>

                    <button *ngIf="wizard" class="wizard-button secondary small" (click)="wizard.open()">
                        {{ wizard.title }}
                    </button>

                    <button
                        class="close-button tertiary small"
                        [class.ml-auto]="!supportArticle?.ArticleUrl"
                        (click)="dropdownMenu?.hide()"
                    >
                        Lukk <i class="material-icons">close</i>
                    </button>
                </div>
            </section>
        </ng-template>
    </dropdown-menu>
</ng-container>
