import { NgModule } from '@angular/core';
import { UniModal } from './modals/modal';
import { UniSave } from './save/save';
import { UniImage, EHFViewer, UniImageFileComment } from './uniImage/uniImage';
import { AuthImg } from './uniImage/auth-img/auth-img';
import { UniToast } from './uniToast/toast';
import { UniToastList } from './uniToast/toastList';
import { StimulsoftReportWrapper } from './wrappers/reporting/reportWrapper';
import { UniPipesModule } from './pipes/pipes.module';
import { UniTranslatePipesModule } from './pipes/translate.module';
import { UniComments } from './comments/comments';

import { UniInfo } from './uniInfo/uniInfo';

import { ClickOutsideModule } from './click-outside/click-outside.module';
import { UniFormModule } from './ui/uniform/uniform.module';
import { UniTableModule } from './ui/unitable/unitableModule';

import { UniHttp } from './core/http/http';
import { ComponentCreator } from './core/dynamic/UniComponentCreator';
import { Logger } from './core/logger';
import { FeaturePermissionDirective } from './featurePermission.directive';

import { UniModalService, MODALS, UniShowReinvoiceStatus, ModalWrapper } from './uni-modal';

import { UniTooltipModule } from './ui/tooltip/tooltip.module';
import { DateRangePickerModule } from './ui/date-range-picker/date-range-picker.module';
import { AgGridWrapperModule } from './ui/ag-grid/ag-grid.module';
import { DropdownMenuModule } from './ui/dropdown-menu/dropdown-menu';
import { ComboButtonModule } from './ui/combo-button/combo-button';

import { NgSelectModule } from '@ng-select/ng-select';
import { AutocompleteModule } from './ui/autocomplete/autocomplete.module';
import { UniTabs, TabContent } from './uni-tabs';
import { DatepickerModule } from './ui/datepicker/datepicker.module';
import { MonthPickerModule } from './ui/month-picker/month-picker';
import { UniIconModule } from './ui/icon/uni-icon';
import { LibraryImportsModule } from '@app/library-imports.module';
import { AutoFocusDirective } from './autofocus.directive';
import { ScrollbarDirective } from './scrollbar.directive';
import { ClickStopPropagation } from './click-stop-propagation.directive';
import { SimpleTable } from './ui/simple-table/simple-table';
import { UniOptionsListComponent } from '@uni-framework/ui/options-list/options-list.component';
import { UniCustomListComponent } from '@uni-framework/ui/custom-list/custom-list.component';
import { UniCustomListItemComponent } from '@uni-framework/ui/custom-list/custom-list-item.component';
import { UniAlertModule } from '@uni-framework/ui/alert/alert.module';
import { NumericInput } from './ui/numeric-input/numeric-input';
import { UniHelpModule } from '@uni-framework/ui/help/help.module';
import { DeprecatedIllustrationModule } from './ui/illustration-deprecated/illustration-deprecated';
import { SummaryComponent } from './ui/summary/summary-component';
import { ScrolledToBottomModule } from './scrolled-to-bottom.directive';
import { Resizeable } from './resizeable/resizeable';
import { AccountSearchModule } from './ui/account-search/account-search.module';
import { UniCard } from './ui/card/card';
import { FileInputModule } from './ui/file-input/file-input';
import { InputDropdownModule } from './ui/input-dropdown/input-dropdown';
import { QueryBuilderModule } from './ui/query-builder/query-builder.module';
import { CMSModule } from '@app/components/common/cms-components/cms-module';
import { DesignSystemModule } from './ui/design-system/design-system.module';
import { UniAvatarModule } from './avatar/uniAvatar.module';
import { GroupedAutocompleteModule } from './ui/autocomplete/groupedAutocomplete.ts/grouped-autocomplete.module';
import { AnalyticsClickDirective } from '@app/services/analytics-services/analytics.directive';

@NgModule({
    imports: [
        LibraryImportsModule,
        DesignSystemModule,
        ClickOutsideModule,
        UniPipesModule,
        UniTranslatePipesModule,
        UniTooltipModule,
        DateRangePickerModule,
        UniFormModule,
        UniTableModule,
        AgGridWrapperModule,
        NgSelectModule,
        DropdownMenuModule,
        InputDropdownModule,
        ComboButtonModule,
        AutocompleteModule,
        DatepickerModule,
        UniIconModule,
        UniAlertModule,
        UniHelpModule,
        AccountSearchModule,
        ScrolledToBottomModule,
        FileInputModule,
        MonthPickerModule,
        QueryBuilderModule,
        UniAvatarModule,
        GroupedAutocompleteModule,
        DeprecatedIllustrationModule,
        CMSModule,
    ],
    declarations: [
        UniModal,
        UniSave,
        UniImage,
        UniImageFileComment,
        AuthImg,
        EHFViewer,
        UniToast,
        UniToastList,
        UniComments,
        UniInfo,
        UniShowReinvoiceStatus,
        UniTabs,
        TabContent,
        FeaturePermissionDirective,
        AutoFocusDirective,
        ScrollbarDirective,
        ClickStopPropagation,
        AnalyticsClickDirective,
        SimpleTable,
        UniOptionsListComponent,
        UniCustomListComponent,
        UniCustomListItemComponent,
        ModalWrapper,
        NumericInput,
        SummaryComponent,
        Resizeable,
        UniCard,
        ...MODALS,
    ],
    providers: [UniModalService, UniHttp, ComponentCreator, Logger, StimulsoftReportWrapper],
    exports: [
        LibraryImportsModule,
        DesignSystemModule,
        UniPipesModule,
        UniTranslatePipesModule,
        UniFormModule,
        UniTableModule,
        UniTooltipModule,
        DateRangePickerModule,
        AgGridWrapperModule,
        ClickOutsideModule,
        NgSelectModule,
        DropdownMenuModule,
        ComboButtonModule,
        AutocompleteModule,
        DatepickerModule,
        MonthPickerModule,
        UniAlertModule,
        UniHelpModule,
        AccountSearchModule,
        ScrolledToBottomModule,
        FileInputModule,
        QueryBuilderModule,
        UniAvatarModule,
        GroupedAutocompleteModule,

        UniModal,
        UniSave,
        UniImage,
        AuthImg,
        EHFViewer,
        UniToast,
        UniToastList,
        UniComments,
        UniInfo,
        UniShowReinvoiceStatus,
        UniTabs,
        UniIconModule,
        FeaturePermissionDirective,
        AutoFocusDirective,
        ScrollbarDirective,
        AnalyticsClickDirective,
        ClickStopPropagation,
        SimpleTable,
        UniOptionsListComponent,
        UniCustomListComponent,
        UniCustomListItemComponent,
        NumericInput,
        DeprecatedIllustrationModule,
        SummaryComponent,
        Resizeable,
        UniCard,
        CMSModule,
    ],
})
export class UniFrameworkModule {}
