<label class="flex pointer" [ngClass]="'label-' + labelPosition" (click)="preventDuplicateClickEvents($event)">
    <input
        type="checkbox"
        class="visually-hidden"
        [disabled]="disabled"
        [checked]="checked"
        (change)="onChange($event.target.checked)"
    />

    <span class="switch flex-0-auto flex align-center" aria-hidden="true">
        <span class="handle"></span>
    </span>

    <span class="label-text">
        <ng-content></ng-content>
    </span>
</label>
