export const ERRORS = {
    OBJECT_REFERENCE_NOT_SET: {
        EN: 'Object reference not set to an instance of an object.',
        NO: 'Det mangler data i et felt som er påkrevd for å kunne gå videre.',
    },
    CANT_CREATE_PAYROLL: {
        EN: "Can't create/change payroll. You need to synchronize wagetypes first",
        NO: 'Du må synkronisere lønnsartene før du kan opprette første lønnskjøringen for året.',
    },
    ALTINN_LOGIN: {
        EN: "Feil oppstod ved sendig til Skatteeten: 'Forbiden : Forbidden'",
        NO: 'Sjekk at du har riktige rettigheter i Altinn',
    },
    ALTINN_CONFLICT: {
        EN: "Feil oppstod ved sending til Skatteetaten: 'Conflict : Conflict'",
        NO: 'Feil ved sending til Skatteetaten. Sjekk om den allerede er levert',
    },
    JOURNALENTRYLINE_NO_ACCOUNT_SET: {
        EN: 'Cannot book create journalentryline, no account is set',
        NO: 'Kan ikke bokføre, kostnadskonto mangler.',
    },
    PAYMENTS_FROM_DIFFERENT_BANK: {
        EN: 'Cannot have mixed banks for the same payment file.',
        NO: 'En betalingsfil kan ikke inneholde betalinger som skal betales fra forskjellige banker.',
    },
    SUPPLIERINVOICE_RESTRICTED_RELATION_TO_PAYMENT: {
        isRegex: true,
        EN: 'Cannot delete SupplierInvoice with ID (\\d+) because of restricted relation to Payment',
        NO: 'Kan ikke slette leverandørfaktura nummer {0} da den er koblet mot en betaling.',
    },
    CANNOT_RUN_RUN_TRANSITION: {
        EN: 'Cannot run transition because there exists an active approval task',
        NO: 'Kan ikke utføre handling da det allerede ligger en godkjenningsoppgave tilknyttet',
    },
    ACCOUNT_IS_REQUIRED: {
        EN: 'Account is required',
        NO: 'Mangler konto',
    },
    MAIN_ACCOUNT_NOT_PROVIDED: {
        EN: 'Main account not provided',
        NO: 'Mangler hovedbokskonto',
    },
    DEBT_COLLECTION_NOTIFICATION: {
        EN: 'A debt collection notification rule has to be specified if a debt collection rule exists',
        NO: 'Purreflyt med inkasso aktivert må ha minst et inkassovarsel før inkasso',
    },
    CANNOT_CREATE_INVOICE_ON_BLOCKED_OR_DELETED_SUPPLIER: {
        EN: 'Cannot create invoice on a blocked or deleted supplier',
        NO: 'Kan ikke opprette faktura på en blokkert eller slettet leverandør.',
    },
    COULD_NOT_SAVE_NOT_BALANCE_JOURNALENTRY: {
        EN: 'Could not save journalentry - the journalentry does not balance',
        NO: 'Bilaget balanserer ikke.',
    },
    ACCOUNT_IS_LOCKED: {
        EN: 'Not allowed to use a locked account on entity',
        NO: 'Det er ikke tillatt å bokføre på en sperret konto.',
    },
    COST_ALLOCATION_RESTRICTED_RELATION_TO_ACCOUNT: {
        isRegex: true,
        EN: 'Cannot delete CostAllocation with ID \\d+ because of restricted relation to Account',
        NO: 'Kan ikke slette fordelingsnøkkel da den har en kobling mot hovedbokskonto.',
    },
    COST_ALLOCATION_RESTRICTED_RELATION_TO_SUPPLIER: {
        isRegex: true,
        EN: 'Cannot delete CostAllocation with ID \\d+ because of restricted relation to Supplier',
        NO: 'Kan ikke slette fordelingsnøkkel da den har en kobling mot leverandør.',
    },
    COST_ALLOCATION_RESTRICTED_RELATION_TO_JOURNALENTRYLINE: {
        isRegex: true,
        EN: 'Cannot delete CostAllocation with ID \\d+ because of restricted relation to JournalEntryLine',
        NO: 'Kan ikke slette fordelingsnøkkel da den har en kobling mot en bilagsføring.',
    },
    AUTOASSIGN_INACTIVE_SUPPLIER: {
        EN: 'Inactive supplier; Ignoring this document (manual handling)',
        NO: 'Leverandører er ikke aktiv.',
    },
    AUTOASSIGN_BLOCKED_SUPPLIER: {
        EN: 'Blocked supplier; Ignoring this document (manual handling)',
        NO: 'Leverandører er blokkert.',
    },
    AUTOASSIGN_NO_ORDER_FOUND: {
        EN: 'Automation found no order of a seller to assign the invoice to',
        NO: 'Systemet fant ingen ordre.',
    },
    AUTOASSIGN_NO_SELLER_FOUND: {
        EN: 'Automation found no seller to assign the invoice to',
        NO: 'Systemet fant ingen brukere å tildele fakturaen til.',
    },
    AUTOASSIGN_NO_USER_FOUND: {
        EN: 'Automation found no users/persons to assign the invoice to',
        NO: 'Systemet fant ingen bruker å tildele fakturaen til.',
    },
    DEACTIVATED_PROJECT_ON_INVOICE_ITEM: {
        EN: 'Cannot create invoice with deactivated project on an item',
        NO: 'Kan ikke lagre faktura med deaktivert prosjekt i varelinjen.',
    },
    DEACTIVATED_PROJECT_ON_ORDER_ITEM: {
        EN: 'Cannot create order with deactivated project on an item',
        NO: 'Kan ikke lagre ordre med deaktivert prosjekt i varelinjen.',
    },
    DEACTIVATED_PROJECT_ON_QUOTE_ITEM: {
        EN: 'Cannot create quote with deactivated project on an item',
        NO: 'Kan ikke lagre tilbud med deaktivert prosjekt i varelinjen.',
    },
    DEACTIVATED_PROJECT_ON_JOURNAL_ENTRY: {
        EN: 'It is not allowed to use deactivated Project on JournalEntry',
        NO: 'Det er ikke tillatt å bruke et deaktivert prosjekt.',
    },
    JOURNALENTRY_CANNOT_CREDIT_DUE_TO_ACCOUNTING_LOCKED_DATE: {
        EN: 'Cannot credit journalentry due to it has financialdates within AccountingLockedDate',
        NO: 'Kan ikke endre bilaget på grunn av låst regnskapsperiode. Lås opp regnskapsperioden og forsøk på nytt.',
    },
    CANNOT_RUN_JOURNAL_TRANSITION_NO_JOURNALENTRY_EXISTS: {
        EN: 'Cannot run Journal transition for supplier invoice - no journalentry exists',
        NO: 'Fakturaen mangler kostnadskonto.',
    },
    FINANCIAL_DATE_INSIDE_LOCKED_PERIOD_NOT_ALLOWED: {
        EN: 'Not allowed with a financial date inside a locked period',
        NO: 'Bilaget kan ikke registreres i en låst periode.',
    },
    INVALID_EMAIL_ADDRESS: {
        EN: `Invalid E-mail address: `,
        NO: 'Ugyldig e-postadresse.',
    },
    DIFFERENT_FINANCIAL_YEAR_ON_JOURNALENTRY: {
        EN: 'Not allowed to use different FinancialYears on the same JournalEntry',
        NO: 'Ikke tillatt med forskjellig regnskapsår på samme bilag.',
    },
    BANKACCOUNT_MISSING_ON_PAYMENT: {
        EN: 'A bankaccount is missing on entity Payment',
        NO: 'Selskapet har ikke satt opp en bankkonto for utbetaling.',
    },
    CANNOT_DELETE_FILE_WITH_LINK: {
        EN: 'Cant delete file that is linked to other entities',
        NO: 'Kan ikke slette fil som er linket til andre entiteter.',
    },
    AMOUNT_MISMATCH_ON_ALREADY_APPROVED_ENTITY: {
        EN: 'Amount on entity has been increased since last approval',
        NO: 'Det er utført endringer på fakturaen som krever at den tildeles til samme bruker som godkjenningsregel tilsier og godkjennes på nytt.',
    },
    NO_READ_PERMISSION_FOR_ENTITY: {
        EN: 'No read permission for entity',
        NO: 'Du mangler riktig rolle for å utføre denne handlingen.',
    },
    NO_WRITE_PERMISSION_FOR_ENTITY: {
        EN: 'No write permission for entity',
        NO: 'Du mangler riktig rolle for å utføre denne handlingen.',
    },
    ALTINN_SETTINGS_NOT_FOUND: {
        EN: 'Altinnsettings not found',
        NO: 'Du har ikke satt opp kobling mot altinn. Gå til altinn i menyen og innstillinger for å sette dette opp.',
    },
    NIF_IMPORT_INVALID_COLUMN: {
        isRegex: true,
        EN: "Column '(.*?)' does not belong to table MemberArchive",
        NO: 'Filen mangler {0}, dette er et påkrevd felt.',
    },
    CANNOT_CREDIT_JOURNALENTRY_BELONGS_TO_DEPRECIATIONLINE: {
        EN: 'Cannot credit journalentry directly, journalentry belongs to a depreciationline',
        NO: 'Bilag tilknyttet eiendeler kan kun krediteres via Eiendeler. Dette kan du gjøre via funksjonen reverser avskrivninger inne på en eiendel.',
    },
    CANNOT_INSERT_DUPLICATE_KEY_ROW_IN_OBJECT: {
        EN: "Cannot insert duplicate key row in object 'dbo.Employee' with unique index 'IX_Employee_EmployeeNu",
        NO: 'Du forsøker å lagre den ansatte på et ansattnummer som er benyttet tidligere. Skriv inn nytt ansattnummer.',
    },
    CANNOT_INSERT_DUPLICATE_KEY_SOCIALSECURITYNUMBER: {
        EN: 'There has already been registered an employee with this social security number on entity Employee',
        NO: 'Det finnes allerede en ansatt med fødselsnummer.',
    },
    MISSING_PAYMENTINFOTYPE_TO_GENERATE_PAYMENTID: {
        EN: 'Missing paymentinfotype to generate paymentID',
        NO: 'KID-oppsett mangler. Gå inn på innstillinger Salg, for å endre dette under KID-innstillinger',
    },
    UNABLE_TO_ASSIGN_INVOICE_WITH_ID: {
        isRegex: true,
        EN: "Unable to assign invoice with ID:\\d+ to rule with ID:\\d+. '(.*?)' is not defined",
        NO: 'Faktura kunne ikke bli tildelt med valgt regel. Fakturaen mangler prosjekt/avdeling eller valgt prosjekt/avdeling mangler en godkjenner.',
    },
    AUTOMATION_STOPPED_INCOMINGEHF_NO_EHF: {
        EN: 'Automation stopped, the configured filetypes does not include any definitions for filetype EHF',
        NO: 'Flyten er ikke konfigurert for å behandle EHF filer.',
    },
    DUPLICATE_INVOICE_NUMBER_RECEIVED: {
        isRegex: true,
        EN: 'DUPLICATE INVOICE \\(No.(\\d+)\\) received from ([^s]+)',
        NO: 'DUPLIKAT FAKTURA Nr.{0} mottatt fra {1}',
    },
    UNPARSABLE_FILE_OCR_DISABLED: {
        isRegex: true,
        EN: 'Automation is unable to parse files of this filetype;(.*?): invoice interpretation is not enabled \\(OCR-SCAN\\)',
        NO: 'Fakturatolkning er ikke aktivert for filtypen brukt i {0}',
    },
    ALTINN_COMMUNICATION_FAILED: {
        EN: 'Communication with Altinn failed. Check your settings.',
        NO: 'Kommunikasjon med Altinn feilet. Sjekk dine innstillinger.',
    },
    ANNUAL_SETTLEMENT_NOT_ALLOWED_EARLIER_THAN_2020: {
        EN: 'Year can not be earlier than 2020.',
        NO: 'Det er ikke tillatt å opprette årsoppgjør tidligere enn året 2020.',
    },
    ANNUAL_SETTLEMENT_NOT_ALLOWED_IN_CURRENT_CALENDAR_YEAR: {
        EN: 'Year can not be same as current calendar year.',
        NO: 'Det er ikke tillatt å opprette årsoppgjør med år likt gjeldende kalenderår.',
    },
    ANNUAL_SETTLEMENT_ACCOUNTS_MISSING_LINKS: {
        EN: 'One or more accounts are not connected to Annual Settlement (missing Saf-t or Altinn link).',
        NO: 'En eller flere konti mangler kobling mot årsavslutning (mangler SAF-T eller altinn kobling).',
    },
    ASSET_TAXBASED_IB_NOT_FOUND: {
        isRegex: true,
        EN: 'Could not find asset with ID (\\d+)',
        NO: 'Fant ikke eiendel med ID {0}.',
    },
    ASSET_MISSING_ID_OR_GROUP_CODE: {
        EN: 'Missing asset ID or group code.',
        NO: 'Verken ID eller gruppe er oppgitt for beregning av endring i år.',
    },
    ALLOWED_CAR_TYPES: {
        isRegex: true,
        EN: "('(.*?)') is not an allowed car type.",
        NO: '{0} er ikke en gyldig biltype.',
    },
    NEGATIVE_DIVIDEND_AMOUNT: {
        EN: 'Dividend amount can not be negative.',
        NO: 'Beløp for utbytte skal alltid være positivt.',
    },
    DIVIDEND_AMOUNT_HIGHER_THAN_SURPLUS: {
        EN: 'Du kan ikke oppgi høyere utbytte enn overskuddet enn maksimaltall.',
        NO: 'Dividend amount can not be higher than surplus amount.',
    },
    RECONCILE_IS_ALREADY_IN_PROGRESS_OR_COMPLETED: {
        EN: 'Reconcile is already in progress or completed.',
        NO: 'Avstemmingen har ikke en gyldig status for begynne avstemming',
    },
    RECONCILE_IS_NOT_IN_PROGRESS: {
        EN: 'Reconcile is not in progress.',
        NO: 'Avstemmingen har ikke en gyldig status for å fullføre avstemming.',
    },
    RECONCILE_HAS_NOT_BEEN_COMPLETED: {
        EN: 'Reconcile has not been completed.',
        NO: 'Avstemmingen har ikke en gyldig status for å reåpne avstemming.',
    },
    RECONCILE_INVALID_PERIOD: {
        EN: 'Invalid period for this reconcile type.',
        NO: 'Ugyldig periode er angitt.',
    },
    RECONCILE_ALREADY_EXISTS: {
        EN: 'Reconcile already exists for this period.',
        NO: 'Det er allerede opprettet en avstemming for nåværende periode.',
    },
    RECONCILE_ACCOUNT_ERROR: {
        EN: 'There are still unapproved accounts, accounts with additional transactions or accounts with uncommented discrepancies.',
        NO: 'Det finnes fremdeles kontoer som ikke er godkjent, har transaksjoner som ikke er lagt til eller kontoer med avvik i avstemming.',
    },
    RECONCILE_BALANCE_DIFFERS: {
        EN: 'Reconciled balance differs from account balance.',
        NO: 'Kontoen har ikke samsvar mellom avstemmet balanse og balanse i regnskapet.',
    },
    RECONCILE_MISSING_ACCOUNT: {
        EN: 'Account is missing from reconcile.',
        NO: 'Finner ikke denne kontoen i avstemmingen.',
    },
    FORHAANDSFASTSETTING_NOT_ALLOWED_EARLIER_THAN_2024: {
        EN: 'Year can not be earlier than 2024.',
        NO: 'Det er ikke tillatt å gjøre årsavslutning med forhåndsfastsetting for år tidligere enn 2024.',
    },
    FORHAANDSFASTSETTING_NOT_ALLOWED_LATER_THAN_CURRENT_CALENDAR_YEAR: {
        EN: 'Year can not be later than current calendar year.',
        NO: 'Det er ikke tillatt å gjøre årsavslutning med forhåndsfastsetting for år senere enn gjeldende kalenderår.',
    },
    BANKSTMT_NOT_ALL_ENTRIES_ARE_CLOSED: {
        EN: 'Not all entries are closed',
        NO: 'Det finnes poster i kontoutskriften som ikke er avstemt',
    },
    CREDIT_AND_BOOK_JED_OUTGOING_INVOICE_ERROR: {
        EN: 'Cannot credit and book JournalEntry (\\d+)-(\\d+). (.*?)',
        NO: 'Kan ikke korrigere bilag {0}-{1}, knyttet til utgående faktura. Det er ikke lov å endre kundereskontro, beløp på linjen med kundereskontro eller totalt utgående mva på bilaget',
        isRegex: true,
    },
    VATTYPE_SETTINGS_OVERLAPPING_DEDUCTION_DATES: {
        EN: 'Overlapping deduction dates not allowed on entity VatDeduction',
        NO: 'Det er ikke tillatt å legge inn overlappende perioder innenfor en gruppe, men den siste perioden kan stå uten en sluttdato',
    },
    VATTYPE_SETTINGS_DEDUCTION_PERCENTAGE_NOT_VALID: {
        EN: 'Deduction percentage not valid',
        NO: 'Ugyldig fradragsprosent oppgitt',
    },
};
