import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy, ViewChild, ElementRef } from '@angular/core';

interface Month {
    name: string;
    number: number;
    selected?: boolean;
    disabled?: boolean;
}

@Component({
    selector: 'date-range-month-picker',
    templateUrl: './date-range-month-picker.html',
    styleUrls: ['./date-range-month-picker.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateRangeMonthPicker {
    @ViewChild('grid') gridElement: ElementRef<HTMLElement>;

    @Input() label: string;
    @Input() date: Date;
    @Output() dateChange = new EventEmitter<Date>();

    selectedYear: number;
    yearOptions = this.getYearOptions();

    monthGrid: Month[][];

    ngOnChanges() {
        this.selectedYear = this.date.getFullYear();
        this.setMonthGrid();
    }

    selectMonth(month: number) {
        this.date = new Date(this.selectedYear, month, 1);
        this.dateChange.emit(this.date);
    }

    setYear(year: number) {
        this.selectedYear = year;
        this.setMonthGrid();
    }

    focusSelectedCell() {
        const grid = this.gridElement?.nativeElement;
        if (!grid) return;

        let rowIndex = 0;
        let cellIndex = 0;

        this.monthGrid.forEach((row, rIndex) => {
            row.forEach((month, mIndex) => {
                if (month.selected) {
                    rowIndex = rIndex;
                    cellIndex = mIndex;
                }
            });
        });

        const cell = grid.children.item(rowIndex)?.children.item(cellIndex) as HTMLElement;
        cell?.focus();
    }

    private focusCell(rowIndex: number, cellIndex: number) {
        const grid = this.gridElement?.nativeElement;
        const cell = grid?.children.item(rowIndex)?.children.item(cellIndex) as HTMLElement;
        cell?.focus();
    }

    private getRowAndCellIndex(cell: HTMLElement) {
        const row = cell?.parentElement;
        const grid = row?.parentElement;

        if (!row?.children || !grid?.children) return;

        let rowIndex = Array.from(grid.children).findIndex((r) => r === row);
        let cellIndex = Array.from(row.children).findIndex((c) => c === cell);

        return [rowIndex, cellIndex];
    }

    onGridKeyDown(event: KeyboardEvent) {
        let cell = event.target as HTMLElement;

        if (!cell?.classList.contains('month')) return;

        if (event.key === 'Enter') {
            const [rowIndex, cellIndex] = this.getRowAndCellIndex(cell);
            cell.click();
            setTimeout(() => this.focusCell(rowIndex, cellIndex));
        }

        const isNavigationKey = ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(event.key);
        if (isNavigationKey) {
            let [rowIndex, cellIndex] = this.getRowAndCellIndex(cell);

            if (event.key === 'ArrowUp') rowIndex--;
            if (event.key === 'ArrowDown') rowIndex++;
            if (event.key === 'ArrowLeft') cellIndex--;
            if (event.key === 'ArrowRight') cellIndex++;

            this.focusCell(rowIndex, cellIndex);
        }
    }

    private getYearOptions() {
        const currentYear = new Date().getFullYear();
        const options: number[] = [];
        for (let i = currentYear - 10; i < currentYear + 5; i++) {
            options.push(i);
        }

        return options;
    }

    private setMonthGrid() {
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Des'].map(
            (name, index) => {
                return {
                    name,
                    number: index,
                    selected: index == this.date.getMonth() && this.selectedYear === this.date.getFullYear(),
                };
            },
        );

        this.monthGrid = [months.splice(0, 3), months.splice(0, 3), months.splice(0, 3), months.splice(0, 3)];
    }
}
