<section role="dialog" class="uni-modal medium">
    <header>Innstillinger for viderefakturering</header>
    <article>
        <uni-form [config]="{ autofocus: true }" [model]="model" [fields]="layout"></uni-form>
    </article>
    <footer>
        <button (click)="close()" class="secondary">Avbryt</button>
        <button (click)="save()" class="c2a">Lagre</button>
    </footer>
</section>
