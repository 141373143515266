import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IUniModal, IModalOptions, ConfirmActions } from '@uni-framework/uni-modal/interfaces';
import { BehaviorSubject } from 'rxjs';
import { CompanySettings, Period } from '@uni-entities';
import { FieldType } from '@uni-framework/ui/uniform/index';
import { switchMap } from 'rxjs/operators';
import { FinancialYearService } from '@app/services/accounting/financialYearService';
import { PeriodService } from '@app/services/accounting/periodService';
import { CompanySettingsService } from '@app/services/common/companySettingsService';
import { WizardStep } from '@uni-framework/ui/design-system/wizard/wizard';

interface ITaxMandatoryModalResults {
    settings: CompanySettings;
    updateAccounts: boolean;
    updateProducts: boolean;
}

enum STEP_VALUES {
    FirstVatPeriod = 'firstVatPeriod',
    UpdateAccounts = 'updateAccounts',
    UpdateProducts = 'updateProducts',
    PostInvoicing = 'post-invoicing',
    Retrospective = 'retrospective',
}

@Component({
    selector: 'uni-tax-mandatory-modal',
    templateUrl: './tax-mandatory-modal.html',
    styleUrls: ['./tax-mandatory-modal.sass'],
})
export class TaxMandatoryModal implements IUniModal {
    @Input()
    options: IModalOptions = {};

    @Output()
    onClose = new EventEmitter();

    busy = false;
    currentStep = 0;
    stepValues = STEP_VALUES;
    firstStep = STEP_VALUES.FirstVatPeriod;
    lastStep = STEP_VALUES.Retrospective;
    companySettings: CompanySettings;
    periods: Period[];
    updateAccounts = true;
    updateProducts = false;

    fields$: BehaviorSubject<any[]> = new BehaviorSubject([]);
    accountSettings$: BehaviorSubject<CompanySettings> = new BehaviorSubject(null);

    results: ITaxMandatoryModalResults = {
        settings: null,
        updateAccounts: null,
        updateProducts: null,
    };

    steps: WizardStep[] = [
        { label: 'Første mva-termin', value: STEP_VALUES.FirstVatPeriod },
        { label: 'Oppdater kontoplan', value: STEP_VALUES.UpdateAccounts },
        { label: 'Oppdater produkter', value: STEP_VALUES.UpdateProducts },
        { label: 'Etterfakturering av mva', value: STEP_VALUES.PostInvoicing },
        { label: 'Tilbakegående avgiftsoppgjør', value: STEP_VALUES.Retrospective },
    ];

    activeStep: string | number = STEP_VALUES.FirstVatPeriod;

    constructor(
        private companySettingsService: CompanySettingsService,
        private periodService: PeriodService,
        private financialYearService: FinancialYearService,
    ) {}

    ngOnInit() {
        this.busy = true;
        this.companySettingsService.invalidateCache();
        this.companySettingsService
            .getCompanySettings()
            .pipe(
                switchMap((settings) => {
                    this.companySettings = settings;
                    this.accountSettings$.next(settings);
                    return this.periodService.GetAll(
                        `filter=AccountYear eq ${this.financialYearService.getActiveYear()} and periodseriesid eq ${settings.PeriodSeriesVatID}`,
                        ['PeriodSeries'],
                    );
                }),
            )
            .subscribe((periods) => {
                this.busy = false;
                this.periods = periods;
                this.fields$.next(this.getFields());
            });
    }

    previous() {
        this.currentStep--;
        this.activeStep = this.steps[this.currentStep].value;
    }

    next() {
        this.currentStep++;
        this.activeStep = this.steps[this.currentStep].value;
    }

    done() {
        this.results.settings = this.accountSettings$.getValue();
        this.results.updateAccounts = this.updateAccounts;
        this.results.updateProducts = this.updateProducts;
        this.onClose.emit({ action: ConfirmActions.ACCEPT, results: this.results });
    }

    cancel() {
        this.onClose.emit({ action: ConfirmActions.CANCEL });
    }

    getFields() {
        return [
            {
                EntityType: 'CompanySettings',
                Property: 'TaxableFromDate',
                FieldType: FieldType.DROPDOWN,
                Label: 'Første periode for innsendingen av mva',
                Options: {
                    source: this.periods,
                    valueProperty: 'FromDate',
                    template: (period: any) => (period ? this.getPeriodTemplate(period) : ''),
                },
            },
        ];
    }

    getPeriodTemplate(period: Period) {
        if (period.PeriodSeriesID === 1) {
            switch (period.Name) {
                case '1. termin ':
                    return 'Jan-feb';
                case '2. termin ':
                    return 'Mar-apr';
                case '3. termin ':
                    return 'Mai-jun';
                case '4. termin ':
                    return 'Jul-aug';
                case '5. termin ':
                    return 'Sep-okt';
                case '6. termin ':
                    return 'Nov-des';
                default:
                    return '';
            }
        } else {
            return period.Name;
        }
    }
}
