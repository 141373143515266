import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Listbox, ListboxItem } from './listbox';

@NgModule({
    imports: [CommonModule],
    declarations: [Listbox, ListboxItem],
    exports: [Listbox, ListboxItem],
})
export class ListboxModule {}
