import {
    Component,
    Input,
    Output,
    EventEmitter,
    ElementRef,
    ViewChild,
    ChangeDetectorRef,
    ViewChildren,
    QueryList,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { INumberFormat } from '../config/unitableColumn';

@Component({
    selector: 'unitable-numberinput',
    template: `
        <input-dropdown-menu [input]="inputElement" [visible]="calculatorItems?.length">
            <ng-template>
                <section class="calculator-content" #calculatorContent>
                    <section class="calculator-header">
                        <i class="material-icons">calculate</i>
                        Kalkulator
                    </section>

                    <section class="calculator-body">
                        <section *ngFor="let item of calculatorItems" class="calculator-item" #calculatorItem>
                            <span>{{ item.operator === '*' ? 'x' : item.operator }}</span>
                            <span>{{ item.value | uninumberformat: 'money' : numberFormat : false }}</span>
                        </section>
                    </section>

                    <section class="calculator-footer">
                        <span></span>
                        <span>{{ calculatorSum | uninumberformat: 'money' : numberFormat }}</span>
                    </section>
                </section>
            </ng-template>
        </input-dropdown-menu>

        <input
            type="text"
            #inputElement
            (keydown)="onKeyDown($event)"
            [formControl]="inputControl"
            [ngStyle]="{ 'text-align': column?.get('alignment') || 'right' }"
        />
    `,
    styleUrls: ['./number.sass'],
})
export class UnitableNumberInput {
    @ViewChild('inputElement', { static: true }) inputElement: ElementRef;
    @ViewChildren('calculatorItem') calculatorItemElements: QueryList<ElementRef>;

    @Input() inputControl: UntypedFormControl;
    @Input() column: any;
    @Output() close: EventEmitter<any> = new EventEmitter();

    calculatorModeActive = false;
    calculatorItems: { operator?: string; value?: number }[] = [];
    calculatorSum: number;

    inputSubscription: Subscription;
    numberFormat: INumberFormat;

    constructor(private cdr: ChangeDetectorRef) {}

    ngOnInit() {
        this.numberFormat = this.column.get('numberFormat') || {
            decimalLength: 2,
        };

        this.inputSubscription = this.inputControl.valueChanges.subscribe((value) => {
            if (this.calculatorModeActive) {
                const currentItem = this.calculatorItems[this.calculatorItems.length - 1];
                if (currentItem) {
                    currentItem.value = this.getNumericValue(value);
                    this.calculatorSum = this.getCalculatorSum();
                }
            }
        });
    }

    ngOnDestroy() {
        this.inputSubscription?.unsubscribe();
    }

    onKeyDown(event: KeyboardEvent) {
        if (this.isCalculatorKey(event.key)) {
            setTimeout(() => {
                const currentItem = this.calculatorItems && this.calculatorItems[this.calculatorItems.length - 1];

                // If theres only an operator (no value) in the input just change operator on the current item
                if (this.inputControl.value?.length === 1) {
                    if (this.calculatorModeActive && currentItem) {
                        currentItem.operator = event.key;
                        this.inputControl.setValue('', { emitEvent: false });
                        this.cdr.markForCheck();
                    }
                } else {
                    let inputValue = this.getNumericValue(this.inputControl.value?.slice(0, -1));
                    if (inputValue) {
                        if (currentItem) {
                            currentItem.value = inputValue;
                            this.calculatorItems.push({ operator: event.key });
                        } else {
                            this.calculatorItems.push({ value: inputValue }, { operator: event.key });
                        }

                        this.inputControl.setValue('', { emitEvent: false });
                        this.calculatorModeActive = true;
                        this.calculatorSum = this.getCalculatorSum();

                        this.cdr.markForCheck();

                        setTimeout(() => {
                            this.calculatorItemElements?.last?.nativeElement?.scrollIntoView();
                        });
                    }
                }
            });
        }
    }

    private getCalculatorSum() {
        const sum = this.calculatorItems.reduce((sum, item) => {
            switch (item.operator) {
                case '+':
                    return sum + (item.value || 0);
                case '-':
                    return sum - (item.value || 0);
                case '*':
                    return sum * (item.value || 1);
                case '/':
                    return sum / (item.value || 1);
                default:
                    // The first item doesnt have an operator, treat it as +
                    return sum + (item.value || 0);
            }
        }, 0);

        return parseFloat(sum.toFixed(this.numberFormat?.decimalLength || 2));
    }

    private isCalculatorKey(key: string) {
        return ['+', '-', '*', '/'].includes(key);
    }

    private getNumericValue(value: string) {
        if (value) {
            const parsed = parseFloat(value.replace(',', '.').replace(/[^\d.-]/g, ''));
            if (this.numberFormat?.roundDecimals ?? true) {
                return parseFloat(parsed.toFixed(this.numberFormat?.decimalLength || 2));
            } else {
                return parsed;
            }
        }
    }

    getValue() {
        if (this.inputControl.dirty) {
            const value = this.calculatorModeActive
                ? this.getCalculatorSum()
                : this.getNumericValue(this.inputControl.value);

            return isNaN(value) ? null : value;
        } else {
            return undefined;
        }
    }
}
