<section (clickOutside)="onClickOutside()">
    <input
        #inputElement
        [formControl]="control"
        [readonly]="readOnly$ | async"
        [placeholder]="field?.Placeholder || ''"
        (focus)="focusHandler()"
        (blur)="emitChanges()"
        role="combobox"
        type="text"
    />
</section>

<input-dropdown-menu #dropdown [input]="inputElement" [visible]="isExpanded && lookupResults?.length">
    <ng-template>
        <section
            *ngFor="let item of lookupResults; let idx = index"
            class="dropdown-menu-item"
            (mouseover)="selectedIndex = idx"
            (click)="select(idx)"
            [attr.aria-selected]="selectedIndex === idx"
        >
            {{ template(item) }}
        </section>
    </ng-template>
</input-dropdown-menu>
