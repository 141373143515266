import { Component, Input, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
    selector: 'unitable-datepicker',
    template: `
        <section class="input-with-button">
            <input #input type="text" [formControl]="inputControl" [matDatepicker]="picker" />

            <button type="button" tabindex="-1" (click)="picker.open()">
                <uni-icon icon="calendar"></uni-icon>
            </button>

            <mat-datepicker #picker (closed)="focus()"></mat-datepicker>
        </section>
    `,
})
export class UnitableDateTimepicker {
    @ViewChild('input', { static: true }) inputElement: ElementRef;
    @Input() inputControl: UntypedFormControl;

    focus() {
        if (this.inputElement) {
            this.inputElement.nativeElement.focus();
            this.inputElement.nativeElement.select();
        }
    }

    getValue() {
        if (this.inputControl.dirty) {
            return this.inputControl.value;
        }
    }
}
