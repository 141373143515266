import { NgModule } from '@angular/core';
import { LibraryImportsModule } from '@app/library-imports.module';
import { UniPipesModule } from '../../pipes/pipes.module';
import { UniTranslatePipesModule } from '../../pipes/translate.module';

import { UniTable } from './unitable';
import { UniTableUtils } from './unitableUtils';
import { UniTableRow } from './unitableRow';
import { UnitableEditor } from './editor/editor';
import { UniTableColumnMenu } from './columnMenu/columnMenu';
import { UniTablePagination } from './pagination/pagination';
import { UniTableColumnConfigEditor } from './columnMenu/columnConfigEditor';
import { UnitableTextInput } from './controls/text';
import { UnitableAutocomplete } from './controls/table-autocomplete';
import { UnitableTypeahead } from './controls/typeahead';
import { UnitableNumberInput } from './controls/number';
import { UnitableDateTimepicker } from './controls/dateTimePicker/dateTimePicker';
import { UnitableSelect } from './controls/select';
import { UnitableButton } from './controls/table-button';
import { LocalDatePicker } from './controls/localDatePicker/LocalDatePicker';

import { ColumnMenuModal } from './columnMenu/columnMenuModal';
import { ColumnTooltipPipe } from './columnTooltipPipe';
import { UniTableHeader } from './header/unitable-header';

import { DateAdapter } from '@angular/material/core';
import { InputDropdownModule } from '../input-dropdown/input-dropdown';
import { DropdownMenuModule } from '../dropdown-menu/dropdown-menu';

import { UniDateAdapter } from '@app/date-adapter';
import { UniIconModule } from '../icon/uni-icon';
import { DesignSystemModule } from '../design-system/design-system.module';

@NgModule({
    imports: [
        DesignSystemModule,
        LibraryImportsModule,
        UniPipesModule,
        UniTranslatePipesModule,
        DropdownMenuModule,
        InputDropdownModule,
        UniIconModule,
    ],
    declarations: [
        UniTable,
        UniTableRow,
        UnitableEditor,
        UniTableColumnMenu,
        UniTablePagination,
        UniTableColumnConfigEditor,
        UnitableTextInput,
        UnitableAutocomplete,
        UnitableTypeahead,
        UnitableNumberInput,
        UnitableDateTimepicker,
        UnitableSelect,
        UnitableButton,
        LocalDatePicker,

        ColumnTooltipPipe,
        ColumnMenuModal,

        UniTableHeader,
    ],
    providers: [UniTableUtils, { provide: DateAdapter, useClass: UniDateAdapter }],
    exports: [
        UniTable,
        UniTableRow,
        UnitableEditor,
        UniTableColumnMenu,
        UniTablePagination,
        UniTableColumnConfigEditor,
        UnitableTextInput,
        UnitableAutocomplete,
        UnitableTypeahead,
        UnitableNumberInput,
        UnitableDateTimepicker,
        UnitableSelect,
        UnitableButton,
        LocalDatePicker,

        ColumnTooltipPipe,
    ],
})
export class UniTableModule {}
