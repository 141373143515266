import {
    Component,
    ElementRef,
    Input,
    Output,
    QueryList,
    ViewChildren,
    EventEmitter,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
} from '@angular/core';
import { FeaturePermissionService } from '@app/featurePermissionService';
import { Account } from '@uni-entities';
import { combineLatest, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { theme, THEMES } from 'src/themes/theme';
import { AccountSearchStore } from '../account-search.store';

@Component({
    selector: 'account-search-dropdown',
    templateUrl: './account-search-dropdown.html',
    styleUrls: ['./account-search-dropdown.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountSearchDropdown {
    @ViewChildren('dropdownItem') dropdownItems: QueryList<ElementRef<HTMLElement>>;

    @Input() inputElement: ElementRef;

    @Output() accountClick = new EventEmitter();
    @Output() createAccount = new EventEmitter();
    @Output() keyDown = new EventEmitter();

    onDestroy$ = new Subject();
    hoverIndex: number;
    canCreateAccounts: boolean;
    focusIndex: number;

    chipClass = theme.theme === THEMES.SR ? 'info' : '';

    visibleRowCount = 100;

    displayedSearchResults: Partial<Account>[];

    dropdownVisible$ = combineLatest([this.store.open$, this.store.loading$, this.store.accounts$]).pipe(
        takeUntil(this.onDestroy$),
        map(([open, loading, accounts]) => {
            return open && (!loading || accounts?.length);
        }),
    );

    constructor(
        private cdr: ChangeDetectorRef,
        private store: AccountSearchStore,
        private permissionService: FeaturePermissionService,
    ) {}

    ngOnInit() {
        this.canCreateAccounts = this.permissionService.canShowUiFeature('ui.accountsettings-add-and-import');

        // Store focusindex in a variable for use in the template.
        // This is done to avoid hundreds of subscriptions through the async pipe.
        this.store.focusIndex$.pipe(takeUntil(this.onDestroy$)).subscribe((index) => {
            this.focusIndex = index;
            this.cdr.markForCheck();
        });

        this.store.accounts$.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
            this.visibleRowCount = 100;
            this.updateDisplayedSearchResults();
        });
    }

    ngOnDestroy() {
        this.onDestroy$.next(undefined);
        this.onDestroy$.complete();
    }

    updateDisplayedSearchResults() {
        const accounts = this.store.accounts$.value || [];
        this.displayedSearchResults = accounts.slice(0, this.visibleRowCount || 100);
        this.cdr.markForCheck();
    }

    onUserScrolledToBottom() {
        if (this.visibleRowCount < this.store.accounts$.value?.length) {
            this.visibleRowCount += 100;
            this.updateDisplayedSearchResults();
        }
    }

    onMouseMove(index: number) {
        if (index !== this.hoverIndex && index !== this.store.focusIndex$.value) {
            this.hoverIndex = index;
            this.store.setFocusIndex(index);
        }
    }

    focusNext() {
        const index = this.store.focusIndex$.value;
        const accounts = this.store.accounts$.value;
        if (index < accounts.length - 1) {
            this.store.setFocusIndex(index + 1);
            this.scrollToIndex();
        }
    }

    focusPrevious() {
        const index = this.store.focusIndex$.value;
        if (index > 0) {
            this.store.setFocusIndex(index - 1);
            this.scrollToIndex();
        }
    }

    scrollToIndex() {
        const index = this.store.focusIndex$.value;
        if (index >= 0) {
            const element = this.dropdownItems?.get(index)?.nativeElement;
            element?.scrollIntoView({ block: 'nearest' });
        }
    }
}
