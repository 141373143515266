<table
    *ngIf="tableData && config?.columns"
    class="unitable-main-table"
    [ngClass]="{ 'editable-table': config.editable }"
    (keydown)="onKeyDown($event)"
>
    <unitable-editor (valueChange)="onEditorChange($event)" (copyFromAbove)="copyFromCellAbove()"> </unitable-editor>

    <thead>
        <tr
            unitable-header
            *ngIf="config && tableColumns"
            [config]="config"
            [columns]="tableColumns"
            [(sortInfo)]="sortInfo"
            (sortInfoChange)="filterAndSortTable()"
            (columnsChange)="onColumnSetupChange($event)"
        ></tr>
    </thead>

    <tbody #tbody>
        <tr
            unitable-row
            *ngFor="let row of getPageData()"
            [ngClass]="config.conditionalRowCls && config.conditionalRowCls(row.toJS())"
            [attr.aria-readonly]="config.isRowReadOnly && config.isRowReadOnly(row.toJS())"
            [attr.aria-selected]="
                (config.multiRowSelect && row.get('_rowSelected')) ||
                (!config.multiRowSelect && row == lastFocusedRowModel)
            "
            [columns]="tableColumns"
            [rowModel]="row"
            [config]="config"
            (rowDeleted)="onDeleteRow($event)"
            (cellFocused)="onCellFocused($event)"
            (cellClicked)="onCellClicked($event)"
            (rowSelectionChanged)="onRowSelected($event)"
        ></tr>
    </tbody>
</table>

<unitable-pagination
    #pager
    *ngIf="config?.pageable && rowCount > config?.pageSize"
    [pageSize]="config.pageSize"
    [rowCount]="rowCount"
    (pageChange)="onPageChange($event)"
>
</unitable-pagination>
