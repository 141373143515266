import { catchError, switchMap, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BizHttp } from '../../../framework/core/http/BizHttp';
import { Customer, InvoiceAndReminderStatus, PriceDeal } from '../../unientities';
import { UniHttp } from '../../../framework/core/http/http';
import { StatisticsService } from '../common/statisticsService';
import { Observable, of } from 'rxjs';
import { StatisticsResponse } from '@app/models/StatisticsResponse';
import { cloneDeep } from 'lodash-es';
import { HttpParams } from '@angular/common/http';
import { InvoicesAndReminderStatus } from '@app/components/sales/reminders/reminders';

export interface ReminderPlanCustomer {
    CustomerNumber: number;
    Name: string;
    ID: string;
    CalculateInterestOnReminders: boolean;
    DontSendReminders: boolean;
    ReminderFeeType: number;
}

export interface ReminderFees {
    Title: string;
    ReminderFeeType: number;
    Fee: number;
    CurrencyCodeID: number;
    CurrencyCode: string;
}

@Injectable({ providedIn: 'root' })
export class CustomerService extends BizHttp<Customer> {
    KIDCache = {};

    constructor(
        http: UniHttp,
        private statisticsService: StatisticsService,
    ) {
        super(http);

        this.relativeURL = Customer.RelativeUrl;

        this.entityType = Customer.EntityType;

        this.DefaultOrderBy = 'Info.Name';

        this.defaultExpand = [
            'Info',
            'CustomerInvoiceReminderSettings.CustomerInvoiceReminderRules',
            'CustomerInvoiceReminderSettings.DebtCollectionSettings',
        ];
    }

    public deleteCustomer(id: any): any {
        return this.http.asDELETE().usingBusinessDomain().withEndPoint(`${this.relativeURL}/${id}`).send();
    }

    getCustomerDistributions(customerID: number) {
        return this.http
            .asGET()
            .usingBusinessDomain()
            .withEndPoint(`distributions?action=get-valid-distributions-for-customer&customerId=${customerID}`)
            .send()
            .pipe(map((res) => res.body));
    }

    checkAvtalegiro() {
        return this.http
            .asGET()
            .usingRootDomain()
            .withEndPoint('avtaleGiro/agreementIDForCompany')
            .send()
            .pipe(map((response) => response.body));
    }

    public activateCustomer(id: any): any {
        return this.http
            .asPUT()
            .withDefaultHeaders()
            .usingBusinessDomain()
            .withEndPoint(`${this.relativeURL}?action=activate&ID=${id}`)
            .send();
    }

    public deactivateCustomer(id: any): any {
        return this.http
            .asPUT()
            .withDefaultHeaders()
            .usingBusinessDomain()
            .withEndPoint(`${this.relativeURL}?action=deactivate&ID=${id}`)
            .send();
    }

    Put(ID, entity) {
        return super.Put(ID, this.removeBusinessRelationDuplicates(cloneDeep(entity)));
    }

    Post(entity) {
        return super.Post(this.removeBusinessRelationDuplicates(cloneDeep(entity)));
    }

    private removeBusinessRelationDuplicates(customer: Customer) {
        if (!customer?.Info) {
            return customer;
        }

        customer.Info.Addresses = customer.Info.Addresses?.filter((address) => {
            return (
                !!address.ID ||
                (address._createguid !== customer.Info.InvoiceAddress?._createguid &&
                    address._createguid !== customer.Info.ShippingAddress?._createguid)
            );
        });

        customer.Info.Phones = customer.Info.Phones?.filter((phone) => {
            return !!phone.ID || phone._createguid !== customer.Info.DefaultPhone?._createguid;
        });

        customer.Info.Emails = customer.Info.Emails?.filter((email) => {
            return !!email.ID || email._createguid !== customer.Info.DefaultEmail?._createguid;
        });

        return customer;
    }

    getAutocompleteConfig(valueProperty = 'ID', ignoreInactives: boolean = false) {
        const select = ['ID as ID', 'CustomerNumber as CustomerNumber', 'Info.Name as Name'].join(',');

        const odata = `model=Customer&select=${select}&expand=Info`;

        const ignoreDeletedAndInactiveFilter = '(StatusCode ne 90001 and StatusCode ne 50001)';

        return {
            getDefaultData: (modelValue) => {
                if (modelValue) {
                    const filter = `${valueProperty} eq ${modelValue}`;
                    return this.statisticsService.GetAllUnwrapped(odata + `&filter=${filter}&top=1`);
                } else {
                    return of([]);
                }
            },
            template: (item) => item && `${item.CustomerNumber} - ${item.Name}`,
            search: (query: string) => {
                let filter = query
                    ? `&filter=( contains(Info.Name,'${query}') or startswith(CustomerNumber,'${query}') )`
                    : '';
                if (ignoreInactives) {
                    filter += (filter ? ' and ' : '&filter=') + ignoreDeletedAndInactiveFilter;
                }
                return this.statisticsService.GetAllUnwrapped(odata + `${filter}&top=30`);
            },
            valueProperty: valueProperty,
            displayProperty: 'Name',
            debounceTime: 200,
        };
    }

    public getCustomers(organizationNumber: string): Observable<StatisticsResponse> {
        const query =
            'model=Customer&select=Customer.CustomerNumber as CustomerNumber,Info.Name as Name' +
            '&filter=Customer.OrgNumber eq ' +
            organizationNumber.replace(/ /g, '') +
            '&expand=Info';

        return this.statisticsService.GetAll(query);
    }

    public getCustomersWithRemindersDisabledCount(): Observable<any[]> {
        let query = new HttpParams();
        query = query.set('model', 'Customer');
        query = query.set('select', 'count(ID) as count');
        query = query.set('filter', 'DontSendReminders eq 1');

        return this.statisticsService.GetAllUnwrapped(query.toString());
    }

    public getCustomersWithInterestCalculatedOnReminders(): Observable<any[]> {
        let query = new HttpParams();
        query = query.set('model', 'Customer');
        query = query.set('select', 'count(ID) as count');
        query = query.set('filter', 'CalculateInterestOnReminders eq 1');

        return this.statisticsService.GetAllUnwrapped(query.toString());
    }

    public bulkSaveCustomers(customers: any[]): Observable<void> {
        this.invalidateCache();
        return this.ActionWithBody(null, customers, 'bulk-save', 'put');
    }

    public getCustomerOrderStatistics(customerID: number): Observable<CustomerStatisticsData> {
        return this.statisticsService
            .GetAll(
                'model=Customer&expand=CustomerOrders' +
                    `&filter=Customer.ID eq ${customerID} and isnull(CustomerOrders.Deleted\,'false') eq 'false'` +
                    '&select=Customer.ID as CustomerID,' +
                    `sum(casewhen(CustomerOrders.StatusCode eq 41002 or CustomerOrders.StatusCode` +
                    ` eq 41003\\,CustomerOrders.TaxExclusiveAmount\\,0)) as SumOpenOrdersExVat`,
            )
            .pipe(
                map((response: any) => {
                    const orderData = response && response.Data ? response.Data[0] : null;

                    const customerStatistics = new CustomerStatisticsData();

                    if (orderData) {
                        customerStatistics.SumOpenOrdersExVat = orderData.SumOpenOrdersExVat;
                    }

                    return customerStatistics;
                }),
            );
    }

    public getCustomerInvoiceStatistics(customerID: number): Observable<CustomerStatisticsData> {
        return this.statisticsService
            .GetAll(
                'model=Customer&expand=CustomerInvoices' +
                    `&filter=Customer.ID eq ${customerID} and isnull(CustomerInvoices.Deleted\,'false') eq 'false'` +
                    '&select=Customer.ID as CustomerID,' +
                    `sum(casewhen(getdate() gt CustomerInvoices.PaymentDueDate and ` +
                    `CustomerInvoices.StatusCode ne 42004\\,1\\,0)) as NumberOfDueInvoices,` +
                    `sum(casewhen(getdate() gt CustomerInvoices.PaymentDueDate and CustomerInvoices.StatusCode ne 42004\\,` +
                    `CustomerInvoices.RestAmount\\,0)) as SumDueInvoicesRestAmount,` +
                    `sum(casewhen(CustomerInvoices.StatusCode eq 42002 or CustomerInvoices.StatusCode eq 42003 or ` +
                    `CustomerInvoices.StatusCode eq 42004\\,CustomerInvoices.TaxExclusiveAmount\\,0)) as SumInvoicedExVat`,
            )
            .pipe(
                map((response) => {
                    const invoiceData = response && response.Data ? response.Data[0] : null;
                    const customerStatistics = new CustomerStatisticsData();

                    if (invoiceData) {
                        customerStatistics.NumberOfDueInvoices = invoiceData.NumberOfDueInvoices;
                        customerStatistics.SumDueInvoicesRestAmount = invoiceData.SumDueInvoicesRestAmount;
                        customerStatistics.SumInvoicedExVat = invoiceData.SumInvoicedExVat;
                    }

                    return customerStatistics;
                }),
            );
    }

    public getAllPriceDealsCustomer(customerId: number): Observable<PriceDeal[]> {
        return this.GetAction(customerId, 'get-price-deals');
    }

    validateKID(value: string) {
        if (this.KIDCache[value]) {
            return of(this.KIDCache[value]);
        }
        return this.http
            .asGET()
            .withDefaultHeaders()
            .usingBusinessDomain()
            .withEndPoint(`${this.relativeURL}?action=validate-customer-KID-Alias&customerKidAlias=${value}`)
            .send()
            .pipe(
                switchMap((res) => {
                    this.KIDCache[value] = res;
                    return of(res);
                }),
                catchError((res) => {
                    this.KIDCache[value] = res;
                    return of(res);
                }),
            );
    }

    getInvoicesWithReminders(customerID: number): Observable<InvoicesAndReminderStatus[]> {
        return this.GetAction(customerID, 'get-invoices-with-reminders');
    }
}

export class CustomerStatisticsData {
    public CustomerID: number;
    public NumberOfDueInvoices: number;
    public SumDueInvoicesRestAmount: number;
    public SumInvoicedExVat: number;
    public SumOpenOrdersExVat: number;
}
