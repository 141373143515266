<section role="dialog" class="uni-modal">
    <header>{{ headerText }}</header>
    <ng-container *ngIf="!isRedirectedFromBankID">
        <article>
            <section class="mb-1">
                Når du logger på med BankID vil du få tilgang til alle dine kontoer og banktransaksjoner rett inn i
                bankavstemming
            </section>

            <section class="mb-1">
                <strong>Ønsker du også å gjennomføre betalinger rett fra DNB Regnskap?</strong>
            </section>

            <label class="uni-label mb-15">
                <span style="font-size: 18px; margin-bottom: 8px">Betalinger</span>
                <rig-checkbox [(ngModel)]="paymentsInApp">Ja takk, det vil jeg</rig-checkbox>
            </label>

            <ng-container *ngIf="paymentsInApp">
                <section class="mb-15">
                    <label class="uni-label">
                        <span style="font-size: 18px; margin-bottom: 8px">Godkjenning av betalinger</span>
                        <mat-radio-group class="vertical" [(ngModel)]="selectedApprovalLocation">
                            <mat-radio-button [value]="approvalLocation.InApp" style="margin-bottom: 0.5rem">
                                Jeg vil godkjenne i regnskapssystemet
                            </mat-radio-button>

                            <mat-radio-button [value]="approvalLocation.InBank">
                                Jeg vil godkjenne i nettbanken
                            </mat-radio-button>
                        </mat-radio-group>
                    </label>
                </section>

                <section class="alert warn">
                    <span>
                        <strong style="font-size: 1rem">Viktig ved bruk av regnskapsgodkjente betalinger</strong>
                        <br />Den som godkjenner betalinger i regnskapet, må være bruker i den integrerte nettbanken og
                        ha rettigheter på kontoene det betales fra.
                    </span>
                </section>
            </ng-container>
        </article>

        <footer class="center">
            <button class="secondary" (click)="onClose.emit()">Lukk</button>

            <button class="c2a" (click)="startBankIdOnboarding()">Koble kontoer med BankID</button>
        </footer>
    </ng-container>

    <ng-container *ngIf="isRedirectedFromBankID">
        <article>
            <section *ngIf="busy" class="modal-spinner-no-opacity">
                <mat-spinner class="c2a"></mat-spinner>
            </section>

            <section *ngIf="!busy">
                {{ infoText }}
            </section>
        </article>

        <footer class="center" *ngIf="!busy">
            <button class="c2a" (click)="onClose.emit()">OK</button>
        </footer>
    </ng-container>
</section>
