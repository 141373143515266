import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { DateRangeButton } from '../date-range-picker';

@Component({
    selector: 'date-range-dropdown-simple',
    templateUrl: './date-range-dropdown-simple.html',
    styleUrls: ['./date-range-dropdown-simple.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateRangeDropdownSimple {
    @Input() triggerButton: HTMLButtonElement;
    @Input() buttons: DateRangeButton[];
    @Input() selectionText: string;
    @Input() fromDate: Date;
    @Input() toDate: Date;

    @Output() change = new EventEmitter<{ from: Date; to: Date }>();

    emitChange(from: Date, to: Date) {
        this.change.emit({ from, to });
    }
}
