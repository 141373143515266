import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { Subscription } from 'rxjs';
import { TableGroupingService } from '../../services/table-grouping-service';

@Component({
    selector: 'grouping-cell',
    styleUrl: './grouping-cell.sass',
    template: `
        <span *ngIf="isGroupHeader" class="grouping-cell flex align-center" [style.padding-left]="1.5 * level + 'rem'">
            <span class="icon inline-flex align-center justify-center border rounded mr-1">
                <i class="material-icons">{{ isExpanded ? 'expand_more' : 'chevron_right' }}</i>
            </span>
            <span>{{ label }}</span>
        </span>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupingCellRenderer implements ICellRendererAngularComp {
    isGroupHeader: boolean;
    isExpanded: boolean;
    label: string;
    level: number;

    visibilitySubscription: Subscription;

    constructor(
        private cdr: ChangeDetectorRef,
        private groupingService: TableGroupingService,
    ) {}

    agInit(params: ICellRendererParams) {
        this.setState(params.node?.data);
    }

    ngOnDestroy() {
        this.visibilitySubscription?.unsubscribe();
    }

    private setState(row) {
        if (row) {
            this.isGroupHeader = row._isGroupHeader;
            // this.isExpanded = row._isExpanded;
            this.label = row._groupLabel;
            this.level = row._groupLevel;

            this.groupingService.expandedGroups$.subscribe((groups) => {
                this.isExpanded = groups[row._guid];
                this.cdr.markForCheck();
            });
        }
    }

    refresh(params: ICellRendererParams) {
        this.setState(params.node?.data);
        return true;
    }
}
