<section role="dialog" class="uni-modal" style="width: 60rem">
    <header>{{ options.header || 'Link mellom modeller' }}</header>

    <article>
        <section *ngIf="busy" class="modal-spinner">
            <mat-spinner class="c2a"></mat-spinner>
        </section>

        <a (click)="onNavigate(sourceDestinationURL)" class="source-link-text" *ngIf="sourceEntityText">
            {{ sourceEntityText | translate }}
        </a>

        <ul *ngIf="items.length" class="tracelink-list">
            <li *ngFor="let item of items">
                <a (click)="onNavigate(item._url)" [title]="item._linkText | translate">
                    {{ item._linkText | translate }}
                </a>
                <span class="name" [title]="item.Name"> {{ item.Name }} </span>
                <span class="total" [title]="item.Sum"> {{ item.Sum | uninumberformat: 'money' }} (ink. mva) </span>
                <span class="uni-chip info" [title]="item._statusCodeText"> {{ item._statusCodeText }} </span>
            </li>
        </ul>

        <section *ngIf="!items?.length && !busy" class="emtpy-section">
            <img [src]="emptyDataImageUrl" alt="Illustration indicating missing data" />
            <span>Fant ingen opprettende ordre eller tilbud basert på denne</span>
        </section>
    </article>

    <footer class="center">
        <button class="c2a secondary" (click)="onClose.emit()">Lukk</button>
        <button class="c2a" (click)="onNavigate(detailsURL)" *ifFeaturePermission="'ui.accounting.reinvoice'">
            Se detaljer
        </button>
    </footer>
</section>
