import { Component, EventEmitter, ViewChild } from '@angular/core';
import { cloneDeep } from 'lodash-es';

import { IModalOptions, UniModalService } from '@uni-framework/uni-modal';
import { UniTableColumn, UniTableConfig, UniTableColumnType } from '@uni-framework/ui/unitable';
import { QueryBuilder, QueryBuilderField, QueryItem } from '@uni-framework/ui/query-builder/query-builder';
import { ITableFilter } from '../../interfaces';
import { TableUtils } from '../../services/table-utils';

@Component({
    selector: 'advanced-filters',
    templateUrl: './advanced-filters.html',
    styleUrls: ['./advanced-filters.sass'],
})
export class AdvancedFilters {
    @ViewChild(QueryBuilder) queryBuilder: QueryBuilder;

    options: IModalOptions = {};
    onClose: EventEmitter<ITableFilter[]> = new EventEmitter();

    config: UniTableConfig;
    columns: UniTableColumn[];
    filters: ITableFilter[];

    tableName: string;
    searchName: string = '';

    queryFields: QueryBuilderField[];
    queryItems: QueryItem[];

    constructor(
        private utils: TableUtils,
        private modalService: UniModalService,
    ) {}

    ngOnInit() {
        const data = this.options.data || {};

        this.config = data.config;
        this.columns = data.columns?.filter((col: UniTableColumn) => col.advancedFilteringEnabled);
        this.filters = cloneDeep(data.filters || []);

        this.tableName = this.config.configStoreKey;
        this.searchName = data.searchName || '';

        this.queryFields = this.getQueryBuilderFields(this.columns);
        this.queryItems = this.filtersToQueryItems(this.filters);

        setTimeout(() => this.queryBuilder.focus(data.focusIndex || 0));
    }

    private filtersToQueryItems(filters: ITableFilter[]) {
        if (!filters?.length) {
            return [{ field: '', operator: '', value: '' }];
        }

        const groupIndexMap = new Map<number, number>();
        const queryItems: QueryItem[] = [];

        filters.forEach((filter) => {
            const item: QueryItem = {
                field: filter.field,
                operator: filter.operator,
                value: filter.value,
                logicalOperator: filter.logicalOperator,
                siblings: [],
            };

            if (filter.group > 0) {
                const groupIndex = groupIndexMap.get(filter.group);
                if (groupIndex >= 0) {
                    queryItems[groupIndex].siblings.push(item);
                } else {
                    groupIndexMap.set(filter.group, queryItems.length);
                    queryItems.push(item);
                }
            } else {
                queryItems.push(item);
            }
        });

        return queryItems;
    }

    onQueryChange() {
        const filters: ITableFilter[] = [];

        const addFilter = (item: QueryItem, group?: number) => {
            const field = this.queryFields.find((f) => f.field === item.field);
            filters.push({
                field: item.field,
                operator: item.operator,
                value: item.value,
                isDate: field?.type === 'date',
                logicalOperator: item.logicalOperator,
                group: group || 0,
                selectConfig: field?.selectConfig,
            });
        };

        let groupNumber = 1;

        this.queryItems
            .filter((item) => {
                // Use nullish coalescing to avoid filtering out falsy values like 0 or false
                const hasValue = item.value !== '' && (item.value ?? undefined) !== undefined;
                return item.field && item.operator && (hasValue || item.operator === 'NOT_SET');
            })
            .forEach((item) => {
                if (item.siblings?.length) {
                    [item, ...item.siblings].forEach((x) => addFilter(x, groupNumber));
                    groupNumber++;
                } else {
                    addFilter(item);
                }
            });

        this.filters = filters;
    }

    saveSearch() {
        this.modalService
            .showInputModal({
                header: 'Lagre søkefilter',
                buttonLabels: { accept: 'Lagre og aktiver filter' },
                class: 'xs',
                data: {
                    label: 'Lagre som',
                    placeholder: 'Gi søkefilteret et navn',
                    value: this.searchName || '',
                },
            })
            .onClose.subscribe((value) => {
                if (!value) return;

                this.utils.saveSearch(this.tableName, {
                    name: value.trim(),
                    filters: this.filters,
                });

                this.onClose.emit(this.filters);
            });
    }

    getQueryBuilderFields(columns: UniTableColumn[]): QueryBuilderField[] {
        return columns.map((column) => {
            const field: QueryBuilderField = {
                label: column.header,
                field: column.field,
                type: 'text',
            };

            const isDateColumn =
                column.type === UniTableColumnType.DateTime || column.type === UniTableColumnType.LocalDate;

            if (isDateColumn) {
                field.type = 'date';
            }

            const isNumberColumn =
                column.type === UniTableColumnType.Number ||
                column.type === UniTableColumnType.Money ||
                column.type === UniTableColumnType.Percent;

            if (isNumberColumn) {
                field.type = 'number';
            }

            if (column.filterSelectConfig) {
                field.type = 'select';
                field.selectConfig = column.filterSelectConfig;
            }

            return field;
        });
    }
}
