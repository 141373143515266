import { refCount, publishReplay, map, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UniHttp } from '@uni-framework/core/http/http';
import { Observable } from 'rxjs';
import { AccountingAgency, ContractType, ElsaProduct, ElsaProductBillingLevel, ElsaProductPrice } from '@app/models';
import { environment } from 'src/environments/environment';
import { AuthService } from '@app/authService';
import { cloneDeep } from 'lodash-es';
import { theme, THEMES } from 'src/themes/theme';
import { rigDate } from '@app/components/common/utils/rig-date';

@Injectable({ providedIn: 'root' })
export class ElsaProductService {
    private cache: { [endpoint: string]: Observable<any> } = {};
    ELSA_SERVER_URL = environment.ELSA_SERVER_URL;

    constructor(
        private authService: AuthService,
        private uniHttp: UniHttp,
        private http: HttpClient,
    ) {
        this.authService.authentication$.subscribe(() => (this.cache = {}));
    }

    public invalidateCache() {
        this.cache = {};
    }

    private requestData(endpoint: string) {
        let request = this.cache[endpoint];
        if (!request) {
            request = this.uniHttp
                .asGET()
                .usingElsaDomain()
                .withEndPoint(endpoint)
                .send()
                .pipe(map((res) => res.body))
                .pipe(publishReplay(1), refCount());

            this.cache[endpoint] = request;
        }

        return request.pipe(
            take(1),
            map((data) => cloneDeep(data)), // avoid components mutating cache
        );
    }

    public Get(id: number): Observable<ElsaProduct> {
        return this.requestData(`/api/products/${id}`);
    }

    public GetAll(filter?: string): Observable<ElsaProduct[]> {
        const url = '/api/products' + (filter ? '?$filter=' + filter : '');
        return this.requestData(url);
    }

    public FindProductByName(name: string): Observable<ElsaProduct> {
        return this.requestData('/api/products').pipe(
            map((products) => {
                return products.find((product) => product.Name === name);
            }),
        );
    }

    getProductsOnContractType() {
        const typeID = this.uniHttp.authService.currentUser.License?.ContractType?.TypeID;
        const url = '/api/contracttypes?$expand=productcontracttypes($expand=product)';

        if (!this.cache[url]) {
            this.cache[url] = this.uniHttp
                .asGET()
                .usingElsaDomain()
                .withEndPoint(url)
                .send()
                .pipe(
                    map((res) => {
                        const contractTypes = res?.body || [];
                        const contractType = contractTypes.find((type) => type.ContractType === typeID);
                        if (contractType) {
                            return (contractType.ProductContractTypes || []).map((x) => x.Product);
                        }

                        return [];
                    }),
                )
                .pipe(publishReplay(1), refCount());
        }

        return this.cache[url].pipe(
            take(1),
            map((data) => cloneDeep(data)), // avoid components mutating cache
        );
    }

    // this does almost the same as the method above, and might replace it
    getProductsOnContractTypes(id: number, filter?: string, select?: string): Observable<ElsaProduct[]> {
        const filterClause = filter ? `$filter=${filter}` : '';
        const selectClause = select ? `$select=${select}` : '';
        const endpoint = `/api/contracttypes/${id}/products?${filterClause}&${selectClause}`;
        return this.http.get<ElsaProduct[]>(this.ELSA_SERVER_URL + endpoint);
    }

    public findContractTypeProductPrice(prices: ElsaProductPrice[], contractType?: ContractType) {
        // undefined or null since 0 is a valid type (demo)
        if (contractType === undefined || contractType === null) {
            contractType = this.authService.currentUser.License.ContractType.TypeID;
        }

        // dont show special-/step prices if Unimicro and contracttype bureau
        if (theme.theme === THEMES.UE && contractType === ContractType.Bureau) {
            return [];
        }

        const today = rigDate(new Date());
        const price = prices?.find(
            (p) =>
                p.ContractType === contractType &&
                rigDate(p.FromDate).isSameOrBefore(today) &&
                (p.ToDate === null || rigDate(p.ToDate).isSameOrAfter(today)),
        );
        return price ? [price] : [];
    }

    public mapPrice(product: ElsaProduct) {
        if (product.ProductPrices?.length > 0) {
            return product.ProductPrices[0].Price;
        }
        return product.Price;
    }

    public ProductTypeToPriceText(product: ElsaProduct): string {
        if (product.BillingLevel === ElsaProductBillingLevel.PerTransaction) {
            return 'per transaksjon';
        }
        const text = [];
        if (product.BillingLevel === ElsaProductBillingLevel.PerUser) {
            text.push('per bruker');
        }
        if (product.IsMonthly) {
            text.push('per måned');
        }
        if (product.IsPerAnnum) {
            text.push('per år');
        }
        return text.join(' ');
    }

    public getAgencies(searchText?: string): Observable<AccountingAgency[]> {
        const endpoint = `/api/elsa/agencies/${encodeURIComponent(searchText)}`;
        return this.uniHttp
            .asGET()
            .usingEmptyDomain()
            .withEndPoint(endpoint)
            .send()
            .pipe(map((res) => res.body?.Result || []));
    }
}
