import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    Optional,
    Output,
} from '@angular/core';
import { RigToggleButtonGroup } from './toggle-button-group';

@Component({
    selector: 'rig-toggle-button',
    template: `
        <button class="toggle-button unstyled flex align-center" [attr.aria-pressed]="checked" (click)="toggle()">
            <uni-icon aria-hidden="true" icon="checkmark" [class.visible]="checked" [svgSize]="16"> </uni-icon>

            <ng-content></ng-content>
        </button>
    `,
    styleUrls: ['./toggle-button.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RigToggleButton {
    @Input() value: any;
    @Input() checked: boolean;
    @Output() checkedChange = new EventEmitter<boolean>();

    constructor(
        @Optional() private group: RigToggleButtonGroup,
        private cdr: ChangeDetectorRef,
    ) {}

    toggle() {
        if (this.group) {
            this.group.toggle(this);
        } else {
            this.setChecked(!this.checked);
        }
    }

    setChecked(checked: boolean) {
        this.checked = checked;
        this.checkedChange.emit(checked);
        this.cdr.markForCheck();
    }
}
