import { Directive, ElementRef, EventEmitter, NgModule, Output } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Directive({ selector: '[scrolledToBottom]' })
export class ScrolledToBottomDirective {
    @Output() scrolledToBottom = new EventEmitter();

    scrollSubscription: Subscription;

    constructor(private elementRef: ElementRef) {}

    ngAfterViewInit() {
        const element: HTMLElement = this.elementRef?.nativeElement;
        if (element) {
            this.scrollSubscription = fromEvent(element, 'scroll')
                .pipe(debounceTime(100))
                .subscribe(() => {
                    const numberOfPixelsFromBottom = element.scrollHeight - element.scrollTop - element.clientHeight;
                    if (Math.abs(numberOfPixelsFromBottom) < 5) {
                        this.scrolledToBottom.emit(true);
                    }
                });
        }
    }

    ngOnDestroy() {
        this.scrollSubscription?.unsubscribe();
    }
}

@NgModule({
    declarations: [ScrolledToBottomDirective],
    exports: [ScrolledToBottomDirective],
})
export class ScrolledToBottomModule {}
